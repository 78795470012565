import { Container, Stack, Typography } from "@mui/material";
import { ServiceTypeStruct } from "@snubes/snubes-types";
import { FC } from "react";
import { array, type } from "superstruct";
import { ControlledServiceTypesField } from "../../Form/views/ControlledServiceTypesField";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  services: array(ServiceTypeStruct),
});

export const ProviderWizardServicesForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      services: provider?.services || [],
    }),
    toDoc: (formValues) => {
      return {
        services: formValues?.services,
      };
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("ProviderWizardServicesForm_Title_Services")}
        </Typography>
        <Typography variant="body1" color="text.500" mb={4}>
          {t("ProviderWizardServicesForm_Paragraph_Services")}
        </Typography>
        <Stack spacing={3}>
          <ControlledServiceTypesField control={control} name="services" />
        </Stack>
      </Container>
    </form>
  );
};
