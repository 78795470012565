import {
  CallFlaggingConfig,
  isCallFlaggingConfig,
  Project,
} from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { v4 } from "uuid";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { ProjectCallFlaggingConfigsFormValues } from "../types/ProjectCallFlaggingConfigsFormValues";

export function useProjectCallFlaggingConfigsClipboard(project: Project) {
  const t = useT();

  const setProjectCallFlaggingConfigsToClipboard = useCallback(async () => {
    if (!project.callFlaggingConfigs?.length) {
      return;
    }

    const projectCallFlaggingConfigsClipboard: ProjectCallFlaggingConfigsFormValues["configs"] =
      project.callFlaggingConfigs.map((config) => ({
        ...config,
        issueCategoryId: null,
        reviewerUserId: null,
        parameters: config.parameters.map((parameter) => {
          let questionId;

          if (parameter.type === "QUESTION_ANSWER") {
            // set/use the question text as question id to compare when pasting the clipboard
            questionId = project.questions?.find(
              (question) => question.id === parameter.questionId,
            )?.text;
          }

          // set/use the category name as value to compare when pasting the clipboard
          const categoryName =
            parameter.type === "CATEGORY" &&
            project.callCategories?.find(
              (category) => category.id === parameter.value,
            )?.name;

          return {
            ...parameter,
            ...(questionId && { questionId }),
            ...(categoryName && { value: categoryName }),
          };
        }),
      }));

    try {
      await navigator.clipboard.writeText(
        JSON.stringify({ configs: projectCallFlaggingConfigsClipboard }),
      );

      toast.success(
        t(
          "ProjectCallFlaggingConfigsFormHeaderView_Toast_CopiedCallFlaggingConfigsToClipboard",
        ),
      );
    } catch (error) {
      handleError(error).log();
    }
  }, [
    project.callCategories,
    project.callFlaggingConfigs,
    project.questions,
    t,
  ]);

  const getProjectCallFlaggingConfigsFromClipboard = useCallback(async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      const incompatiblePasteValueToastMessage = t(
        "getProjectCallFlaggingConfigsFromClipboard_Toast_IncompatiblePasteValue",
      );

      let parsedClipboard;

      try {
        parsedClipboard = JSON.parse(
          clipboardText,
        ) as ProjectCallFlaggingConfigsFormValues;
      } catch (error) {
        toast.error(incompatiblePasteValueToastMessage);
        return;
      }

      if (!parsedClipboard || !parsedClipboard.configs) {
        toast.error(incompatiblePasteValueToastMessage);
        return;
      }

      const projectCallFlaggingConfigsFormValues: ProjectCallFlaggingConfigsFormValues =
        {
          configs:
            project.callFlaggingConfigs?.map((config) => ({
              id: config.id,
              condition: config.condition,
              parameters: config.parameters,
              issueCategoryId: null,
              reviewerUserId: null,
            })) || [],
          isAppliedToHistoricData: false,
          checkExistingCallsFromDate: DateTime.utc()
            .startOf("day")
            .minus({ days: 7 })
            .toISO(),
        };

      for (const config of parsedClipboard.configs) {
        if (!isCallFlaggingConfig(config)) {
          toast.error(incompatiblePasteValueToastMessage);
          return;
        }

        const newConfig: CallFlaggingConfig = { ...config, parameters: [] };

        for (const parameter of config.parameters) {
          let questionId;

          if (parameter.type === "QUESTION_ANSWER") {
            // get the existing project question id
            questionId = project.questions?.find(
              (question) => question.text === parameter.questionId,
            )?.id;
          }

          const categoryId = project.callCategories?.find(
            // get the existing project category id
            (category) => category.name === parameter.value,
          )?.id;

          if (
            (parameter.type === "CATEGORY" && !categoryId) ||
            (parameter.type === "QUESTION_ANSWER" && !questionId)
          ) {
            toast.error(
              t(
                "getProjectCallFlaggingConfigsFromClipboard_Toast_MissingParameter",
              ),
            );
            return;
          }

          newConfig.parameters.push({
            ...parameter,
            ...(categoryId && { value: categoryId }),
            ...(questionId && { questionId }),
            id: v4(),
          });
        }

        projectCallFlaggingConfigsFormValues.configs.push({
          ...newConfig,
          id: v4(),
          issueCategoryId: null,
          reviewerUserId: null,
        });
      }

      return projectCallFlaggingConfigsFormValues;
    } catch (error) {
      toast.error(
        t(
          "getProjectCallFlaggingConfigsFromClipboard_Toast_IncompatiblePasteValue",
        ),
      );
      handleError(error).log();
    }
  }, [
    project.callCategories,
    project.callFlaggingConfigs,
    project.questions,
    t,
  ]);

  return {
    setProjectCallFlaggingConfigsToClipboard,
    getProjectCallFlaggingConfigsFromClipboard,
  };
}
