import { ConnectorConfigStatus } from "@snubes/snubes-types/types/ConnectorConfigStatus";
import { FC } from "react";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";
import { CONNECTOR_CONFIG_STATUS_RECORD } from "../consts/CONNECTOR_CONFIG_STATUS_RECORD";

interface Props {
  status: ConnectorConfigStatus;
  isLong?: boolean;
}

export const ConnectorConfigStatusChip: FC<Props> = (props) => {
  const { status, isLong } = props;
  const t = useT();
  const {
    Icon,
    color: variant,
    labelLong,
    labelShort,
  } = CONNECTOR_CONFIG_STATUS_RECORD[status];
  return (
    <StatusChip
      Icon={Icon}
      label={t(isLong ? labelLong : labelShort)}
      color={variant}
    />
  );
};
