import { ConnectorConfig, Project } from "@snubes/snubes-types";

export function isProjectMappable(
  project: Project,
  connectorConfig?: ConnectorConfig,
): boolean {
  if (!project.connectorConfigId) return true;

  if (!connectorConfig) return false;

  const usedProjectIds = connectorConfig.projectMappings.map(
    (m) => m.projectId,
  );

  return usedProjectIds.includes(project.id);
}
