import { isOrganization } from "@snubes/snubes-types";
import { getDoc } from "firebase/firestore";
import { getDataFromDocumentSnapshot } from "../../Firebase/helpers/getDataFromDocumentSnapshot";
import { getDocRef } from "../../Firebase/helpers/getDocRef";

interface Args {
  organizationId: string;
}

export async function getOrganization(args: Args) {
  const snapshot = await getDoc(
    getDocRef("organizations", args.organizationId),
  );
  return getDataFromDocumentSnapshot(isOrganization, snapshot);
}
