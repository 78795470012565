import { Container, Stack, Typography } from "@mui/material";
import { IndustryStruct } from "@snubes/snubes-types";
import { FC } from "react";
import { nullable, type } from "superstruct";
import { ControlledIndustriesAutocomplete } from "../../Form/views/ControlledIndustriesAutocomplete";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  organizationIndustry: nullable(IndustryStruct),
});

export const ProviderWizardOrganizationIndustryForm: FC<WizardFormProps> = (
  props,
) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      organizationIndustry: provider?.organizationIndustry || null,
    }),
    toDoc: (formValues) => ({
      organizationIndustry: formValues.organizationIndustry || undefined,
    }),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("ProviderWizardOrganizationIndustryForm_Title")}
        </Typography>
        <Typography variant="body1" color="text.500" mb={4}>
          {t("ProviderWizardOrganizationIndustryForm_Label")}
        </Typography>
        <Stack spacing={3}>
          <ControlledIndustriesAutocomplete
            control={control}
            name="organizationIndustry"
            label={t(
              "ProviderWizardOrganizationIndustryForm_Label_IndustriesAutocomplete",
            )}
            fullWidth
            required
          />
        </Stack>
      </Container>
    </form>
  );
};
