import { FirebaseFilter, isUser, User } from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import {
  FirebaseTableFilterContextProvider,
  FirebaseTableFilterRecord,
} from "../../Firebase/hooks/useFirebaseTableFilters";
import { CollectionTableColumn } from "../../Firebase/types/CollectionTableColumn";
import { CollectionTableView } from "../../Firebase/views/CollectionTableView";
import { useT } from "../../Translation/hooks/useT";
import { UserRoleChip } from "../../Users/views/UserRoleChip";
import { AdminUsersCollectionTableActionsView } from "./AdminUsersCollectionTableActionsView";

interface Props {
  filters?: FirebaseFilter<User>[];
}

export const AdminUsersCollectionTableView: FC<Props> = (props) => {
  const t = useT();

  const columns = useMemo<CollectionTableColumn<User>[]>(
    () => [
      {
        title: t("UsersView_TableCell_LastName"),
        field: "lastName",
      },
      {
        title: t("UsersView_TableCell_FirstName"),
        field: "firstName",
      },
      {
        title: t("UsersView_TableCell_Email"),
        field: "email",
      },
      {
        title: t("UsersView_TableCell_Role"),
        renderCell: (user) => <UserRoleChip user={user} />,
      },
      {
        title: t("UsersView_TableCell_Organizations"),
        renderCell: (user) => user.organizationIds.length,
      },
      {
        title: t("UsersView_TableCell_UpdatedAt"),
        field: "updatedAt",
        align: "right",
      },
      {
        title: t("UsersView_TableCell_CreatedAt"),
        field: "createdAt",
        align: "right",
      },
      {
        title: "",
        renderCell: (user) => (
          <AdminUsersCollectionTableActionsView user={user} />
        ),
      },
    ],
    [t],
  );

  const userFilters = useMemo<FirebaseTableFilterRecord<User>>(
    () => ({
      lastName: {
        isSearch: true,
        label: t("FirebaseTableFilterView_Label_Search"),
        type: "text",
        operator: "search",
        value: null,
      },
    }),
    [t],
  );

  return (
    <FirebaseTableFilterContextProvider filters={userFilters}>
      <CollectionTableView
        collectionName="users"
        filters={props.filters}
        orderBy={["lastName", "asc"]}
        keyField="id"
        isT={isUser}
        getTo={(user) => `/users/${user.id}`}
        columns={columns}
      />
    </FirebaseTableFilterContextProvider>
  );
};
