import { Tender } from "@snubes/snubes-types";
import { DateTime } from "luxon";

export function isTenderBeforeApplicationDeadline(tender: Tender) {
  if (!tender.timetable?.applicationDeadlineAt) {
    return false;
  }
  return (
    DateTime.fromISO(tender.timetable.applicationDeadlineAt) >
    DateTime.utc().startOf("day")
  );
}
