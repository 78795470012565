import { useTheme } from "@mui/material/styles";
import { useCallback } from "react";

export function useGetCallSentimentColor() {
  const { palette } = useTheme();

  return useCallback(
    (sentiment: number) => {
      if (sentiment <= -0.5) return palette.error.main;
      if (sentiment >= 0.5) return palette.secondary.main;

      return palette.warning.main;
    },
    [palette.error.main, palette.secondary.main, palette.warning.main],
  );
}
