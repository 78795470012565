import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import { Button, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";
import { HomePageInfoPanelCard } from "./HomePageInfoPanelCard";

interface Props {
  titleText: TranslationKey;
  subtitleText: TranslationKey;
  buttonText: TranslationKey;
}

export const HomeLaunchCardView: FC<Props> = (props) => {
  const { titleText, subtitleText, buttonText } = props;
  const t = useT();
  return (
    <HomePageInfoPanelCard isOutlined>
      <Typography variant="subtitle2" color="text.main">
        {t(subtitleText)}
      </Typography>
      <Stack spacing={2}>
        <Typography variant="h3" color="text.secondary">
          {t(titleText)}
        </Typography>
      </Stack>
      <Stack
        spacing={3}
        color="success.main"
        direction="row"
        justifyContent={"flex-start "}
      >
        <Button
          variant="contained"
          sx={{
            color: "background.paper",
            backgroundColor: "secondary.main",
          }}
          size="small"
          color="success"
          startIcon={<ElectricBoltIcon />}
        >
          {t(buttonText)}
        </Button>
      </Stack>
    </HomePageInfoPanelCard>
  );
};
