import { Unstable_Grid2 as Grid } from "@mui/material";
import { Member } from "@snubes/snubes-types";
import { FC } from "react";
import { useIsFeatureFlagEnabled } from "../../Admin/hooks/useIsFeatureFlagEnabled";
import { FullHeightStack } from "../../Common/views/FullHeightStack";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useSetUserType } from "../../Users/hooks/useSetUserType";
import { HomeApplicationCardView } from "./HomeApplicationCardView";
import { HomeGetStartedCardView } from "./HomeGetStartedCardView";
import { HomeGetStartedView } from "./HomeGetStartedView";
import { HomeInviteMembersCardView } from "./HomeInviteMembersCardView";
import { HomeKeyFeaturesView } from "./HomeKeyFeaturesView";
import { HomeLaunchCardView } from "./HomeLaunchCardView";
import { HomeOtherApplicationsView } from "./HomeOtherApplicationsView";
import { HomeWelcomeCardView } from "./HomeWelcomeCardView";

interface Props {
  member: Member;
}

export const ProviderHomeOnboardingView: FC<Props> = (props) => {
  const { member } = props;
  const [setUserType] = useSetUserType();
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled();
  const isOnboardingDemosEnabled = isFeatureFlagEnabled("ONBOARDING_DEMOS");
  const organizationId = useSelectedOrganization((state) => state?.id);

  const isTeamMemberInvited = !!member?.tasks?.isTeamMemberInvited;
  const isProviderProfileComplete = !!member?.tasks?.isProviderProfileComplete;

  return (
    <FullHeightStack>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <HomeGetStartedView>
        <Grid sm={12} md={12} lg={4}>
          <HomeWelcomeCardView
            titleText="ProviderHomePage_Started_Headline"
            subtitleText="ProviderHomePage_Started_Subheadline"
            steps={[
              {
                isChecked: true,
                text: "ProviderHomePage_Step_CreateAccount",
              },
              {
                isChecked: isTeamMemberInvited,
                text: "ProviderHomePage_Step_InviteTeam",
              },
              {
                isChecked: isProviderProfileComplete,
                text: "ProviderHomePage_Step_CreateProfile",
              },
            ]}
          />
        </Grid>
        <Grid sm={12} md={6} lg={4}>
          <HomeGetStartedCardView
            titleText="ProviderHomePage_Card_Profile_Title"
            subtitleText1="ProviderHomePage_Card_Profile_Subtitle1"
            subtitleText2="ProviderHomePage_Card_Profile_Subtitle2"
            buttonText="ProviderHomePage_Card_Profile_Button"
            buttonHref={organizationId && `/providers/${organizationId}/create`}
            buttonDisabled={isProviderProfileComplete}
          />
        </Grid>
        <Grid sm={12} md={6} lg={4}>
          <HomeInviteMembersCardView
            member={member}
            titleText="ProviderHomePage_Card_Team_Title"
            subtitleText1="ProviderHomePage_Card_Team_Subtitle1"
            subtitleText2="ProviderHomePage_Card_Team_Subtitle2"
            buttonTextPrimary="ProviderHomePage_Card_Team_Button1"
            buttonTextSecondary="ProviderHomePage_Card_Team_Button2"
          />
        </Grid>
      </HomeGetStartedView>

      {isOnboardingDemosEnabled && (
        <HomeKeyFeaturesView>
          <HomeLaunchCardView
            titleText="ProviderHomePage_Card_Demo_Project_Title"
            subtitleText="ProviderHomePage_Card_Demo_Project_Subtitle"
            buttonText="ProviderHomePage_Card_Demo_Project_Button"
          />
        </HomeKeyFeaturesView>
      )}

      <HomeOtherApplicationsView sx={{ flex: 1 }}>
        <HomeApplicationCardView
          bgcolor="primary.50"
          subtitleText="ProviderHomePage_Subtitle_ForClients"
          titleText="ProviderHomePage_Heading_LookingToFindCallCenter"
          buttonText="ProviderHomePage_Button_LearnMore"
          buttonHref="/home/client"
          buttonOnClick={() => setUserType("CLIENT")}
          imgSrc="/assets/home-client.png"
        />
        <HomeApplicationCardView
          bgcolor="secondary.50"
          subtitleText="ProviderHomePage_Subtitle_ForQualityMonitoring"
          titleText="ProviderHomePage_Heading_AnalyzeGetInsights"
          buttonText="ProviderHomePage_Button_LearnMore"
          buttonHref="/home/qm"
          buttonOnClick={() => setUserType("QM")}
          imgSrc="/assets/home-qm.png"
          imgBgcolor="error.50"
        />
      </HomeOtherApplicationsView>
    </FullHeightStack>
  );
};
