import { FormControlProps, SxProps } from "@mui/material";
import { ProjectIssueCategory } from "@snubes/snubes-types";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { ControlledAutocomplete } from "./ControlledAutocomplete";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues & Record<TName, string | null>>;
  name: TName;
  issueCategories: ProjectIssueCategory[];
  required?: boolean;
  label?: string;
  fullWidth?: boolean;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  size?: FormControlProps["size"];
  sx?: SxProps;
  multiple?: boolean;
  organizationId?: string;
}

export function ControlledIssueCategoryIdAutocomplete<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const issueCategories = props.issueCategories.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  return (
    <ControlledAutocomplete
      control={props.control}
      name={props.name}
      label={props.label}
      options={issueCategories.map((issueCategory) => issueCategory.id)}
      multiple={props.multiple}
      getOptionLabel={(issueCategoryId) =>
        issueCategories.find((c) => c.id === issueCategoryId)?.name || ""
      }
      required={props.required}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      placeholder={props.placeholder}
      helperText={props.helperText}
      size={props.size}
      sx={props.sx}
    />
  );
}
