import { Project } from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import { toast } from "react-hot-toast";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { updateProjectAllCallable } from "../callables/updateProjectAllCallable";
import { updateProjectLimitedCallable } from "../callables/updateProjectLimitedCallable";
import { ProjectForm, ProjectFormValues } from "./ProjectForm";

interface Props {
  project: Project;
}

export const ProjectUpdateView: FC<Props> = (props) => {
  const { project } = props;
  const hasPermission = useHasPermission();
  const isAllFieldsEnabled = hasPermission("CAN_UPDATE_PROJECT_ALL", {
    organizationId: project.providerOrganizationId,
  });
  const t = useT();

  const onSubmit = useCallback(
    async (formValues: ProjectFormValues) => {
      const {
        name,
        tier,
        clientOrganizationId,
        projectManagerUserId,
        isLookerAnalyticsEnabled,
        callSamplingRate,
      } = formValues;

      try {
        if (isAllFieldsEnabled) {
          await updateProjectAllCallable({
            projectId: project.id,
            project: {
              name,
              tier,
              clientOrganizationId,
              projectManagerUserId,
              isLookerAnalyticsEnabled,
              callSamplingRate,
              voicefileLanguage: formValues.voicefileLanguage || null,
              responseLanguage: formValues.responseLanguage || null,
            },
          });
        } else {
          await updateProjectLimitedCallable({
            projectId: project.id,
            project: {
              name,
              callSamplingRate,
            },
          });
        }
        toast.success(t("ProjectForm_Success_Message"));
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [isAllFieldsEnabled, project.id, t],
  );

  return <ProjectForm onSubmit={onSubmit} />;
};
