import { ConnectorStatusLogStatus } from "@snubes/snubes-types";
import { FC } from "react";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";
import { CONNECTOR_STATUS_LOG_STATUS_RECORD } from "../consts/CONNECTOR_STATUS_LOG_STATUS_RECORD";

interface Props {
  status: ConnectorStatusLogStatus;
}

export const ConnectorConfigStatusLogStatusChip: FC<Props> = (props) => {
  const { status } = props;
  const t = useT();
  const {
    Icon,
    color: variant,
    label,
  } = CONNECTOR_STATUS_LOG_STATUS_RECORD[status];

  return <StatusChip Icon={Icon} label={t(label)} color={variant} />;
};
