import { Alert } from "@mui/material";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LoadingView } from "../../Common/views/LoadingView";
import { useProjectContext } from "../hooks/useProjectContext";
import { useProjectLookerUrl } from "../hooks/useProjectLookerUrl";

export const ProjectAnalyticsPage = () => {
  const { project } = useProjectContext();

  const [lookerUrl, isLoading, error] = useProjectLookerUrl(project);

  return (
    <FullHeightContainer maxWidth={false} disableGutters>
      {error && (
        <Alert sx={{ m: 3 }} severity="error">
          {error.message}
        </Alert>
      )}
      {project && !project.isLookerAnalyticsEnabled && (
        <Alert sx={{ m: 3 }} severity="warning">
          {"Looker is not enabled for this project."}
        </Alert>
      )}
      {isLoading && <LoadingView />}
      {lookerUrl && (
        <iframe
          title="Project Analytics"
          src={lookerUrl}
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      )}
    </FullHeightContainer>
  );
};
