import { Stack, Typography } from "@mui/material";
import { isOrganizationLog } from "@snubes/snubes-types";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ENVIRONMENT } from "../../Application/consts/ENVIRONMENT";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { ExternalLinkView } from "../../Common/views/ExternalLinkView";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LinkView } from "../../Common/views/LinkView";
import { RelativeTimestampView } from "../../Common/views/RelativeTimestampView";
import { LogListItemViewForOrganization } from "../../Logs/views/LogListItemViewForOrganization";
import { LogsButtonView } from "../../Logs/views/LogsButtonView";
import { useT } from "../../Translation/hooks/useT";
import { T } from "../../Translation/views/T";
import { useOrganizationContext } from "../hooks/useOrganizationContext";
import { OrganizationEditForm } from "./OrganizationEditForm/OrganizationEditForm";

export const OrganizationDetailsPage: FC = () => {
  const t = useT();
  const { organizationId } = useParams<"organizationId">();
  const hasPermission = useHasPermission();
  const { organization } = useOrganizationContext();
  const canWriteHubspot = hasPermission("CAN_WRITE_HUBSPOT");

  if (!organization) return null;

  return (
    <FullHeightContainer>
      <Stack py={5} spacing={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h2">{organization.name}</Typography>

          {/* TODO: make this row responsive */}
          <Stack direction="row" spacing={1} alignItems="center">
            {organization.hubspotCompanyId && canWriteHubspot && (
              <ExternalLinkView
                variant="button"
                href={`${ENVIRONMENT.hubspot.companyBaseUrl}/${organization.hubspotCompanyId}`}
                tooltip={t("OrganizationDetailsPage_Tooltip_ViewInHubspot", {
                  id: organization.hubspotCompanyId,
                })}
              >
                {t("OrganizationDetailsPage_Button_ViewInHubspot")}
              </ExternalLinkView>
            )}
            {organization.types?.includes("PROVIDER") && (
              <LinkView variant="button" to={`/providers/${organization.id}`}>
                {t("OrganizationDetailsPage_Link_ProviderProfile")}
              </LinkView>
            )}
            {hasPermission("CAN_READ_LOGS") && (
              <LogsButtonView
                collectionName={`organizations/${organization.id}/logs`}
                LogListItemView={LogListItemViewForOrganization}
                isT={isOrganizationLog}
              />
            )}
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="body1">
            {organization.createdAt && (
              <>
                <T k="OrganizationView_Created" />:{" "}
                <RelativeTimestampView
                  timestamp={organization.createdAt}
                  shouldBeInThePast
                  translation
                />
              </>
            )}
          </Typography>
          <Typography variant="body1">
            {organization.updatedAt && (
              <>
                <T k="OrganizationView_Updated" />:{" "}
                <RelativeTimestampView
                  timestamp={organization.updatedAt}
                  shouldBeInThePast
                  translation
                />
              </>
            )}
          </Typography>
        </Stack>
        <OrganizationEditForm
          organization={organization}
          isEditEnabled={hasPermission("CAN_WRITE_ORGANIZATION", {
            organizationId,
          })}
        />
      </Stack>
    </FullHeightContainer>
  );
};
