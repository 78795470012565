import { Stack, Typography } from "@mui/material";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { useT } from "../../Translation/hooks/useT";
import { testConnectorConfigDialfireCallable } from "../callables/testConnectorConfigDialfireCallable";
import { testConnectorConfigInoplaCallable } from "../callables/testConnectorConfigInoplaCallable";
import { testConnectorConfigSftpCallable } from "../callables/testConnectorConfigSftpCallable";
import { testConnectorConfigVccLiveCallable } from "../callables/testConnectorConfigVccLiveCallable";
import { useConnectorConfigContext } from "../hooks/useConnectorConfigContext";
import { ConnectorConfigMultipleProjectMappingsEditForm } from "./ConnectorConfigMultipleProjectMappingsEditForm";
import { ConnectorConfigSingleProjectMappingsEditForm } from "./ConnectorConfigSingleProjectMappingsEditForm";

export const ConnectorConfigProjectMappingsEditPage = () => {
  const t = useT();
  const { connectorConfig } = useConnectorConfigContext();

  if (!connectorConfig) return null;

  return (
    <FullHeightContainer maxWidth="md">
      <Stack py={5} spacing={3}>
        <Typography variant="h2">
          {t("ConnectorConfigProjectMappingsEditPage_Heading_ProjectMappings")}
        </Typography>

        {connectorConfig.platform === "VCC_LIVE" && (
          <ConnectorConfigMultipleProjectMappingsEditForm
            connectorConfig={connectorConfig}
            testConnectorConfig={async () => {
              const { externalProjects } =
                await testConnectorConfigVccLiveCallable({
                  ...connectorConfig,
                  platform: "VCC_LIVE",
                  connectorConfigId: connectorConfig.id,
                });
              return externalProjects;
            }}
          />
        )}
        {connectorConfig.platform === "SFTP" && (
          <ConnectorConfigMultipleProjectMappingsEditForm
            connectorConfig={connectorConfig}
            testConnectorConfig={async () => {
              const { externalProjects } =
                await testConnectorConfigSftpCallable({
                  ...connectorConfig,
                  platform: "SFTP",
                  connectorConfigId: connectorConfig.id,
                  ...(connectorConfig.port && { port: connectorConfig.port }),
                });
              return externalProjects;
            }}
          />
        )}
        {connectorConfig.platform === "DIALFIRE" && (
          <ConnectorConfigSingleProjectMappingsEditForm
            connectorConfig={connectorConfig}
            testConnectorConfig={async () => {
              const { externalProjects } =
                await testConnectorConfigDialfireCallable({
                  ...connectorConfig,
                  platform: "DIALFIRE",
                  connectorConfigId: connectorConfig.id,
                });
              return externalProjects;
            }}
          />
        )}
        {connectorConfig.platform === "INOPLA" && (
          <ConnectorConfigMultipleProjectMappingsEditForm
            connectorConfig={connectorConfig}
            testConnectorConfig={async () => {
              const { externalProjects } =
                await testConnectorConfigInoplaCallable({
                  ...connectorConfig,
                  platform: "INOPLA",
                  connectorConfigId: connectorConfig.id,
                });
              return externalProjects;
            }}
          />
        )}
        {connectorConfig.platform === "GENERIC_WEBHOOK" && (
          <ConnectorConfigSingleProjectMappingsEditForm
            connectorConfig={connectorConfig}
          />
        )}
      </Stack>
    </FullHeightContainer>
  );
};
