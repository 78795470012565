import { Link, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FC } from "react";

interface Props {
  label: string;
  value: string;
  href?: string;
}

export const CallSidebarInfoView: FC<Props> = (props) => {
  const { label, value, href } = props;
  const { palette } = useTheme();

  return (
    <Stack
      spacing={1}
      p={3}
      sx={{
        ":not(:last-child)": {
          borderBottom: `1px solid ${palette.divider}}`,
        },
      }}
    >
      <Typography variant="body1" color="text.secondary">
        {label}
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          variant="body2"
          component={href ? Link : "p"}
          href={href}
          sx={{
            textDecoration: "none",
            ...(href && {
              "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
              },
            }),
          }}
        >
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};
