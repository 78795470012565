import {
  hasPermission,
  MemberRoles,
  PermissionAuthData,
  PermissionDocData,
} from "@snubes/snubes-types";
import { Permission } from "@snubes/snubes-types";
import { useCallback, useMemo } from "react";
import { useMyMember } from "../../Members/hooks/useMyMember";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useMyUser } from "../../Users/hooks/useMyUser";

export function useHasPermission() {
  const userRole = useMyUser((state) => state.user?.role);
  const memberRole = useMyMember((state) => state.member?.role);
  const userId = useMyUser((state) => state.user?.id);
  const selectedOrganizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );

  const memberRoles = useMemo<MemberRoles | undefined>(() => {
    if (selectedOrganizationId && memberRole) {
      return {
        [selectedOrganizationId]: memberRole,
      };
    }
  }, [memberRole, selectedOrganizationId]);

  const authData = useMemo<PermissionAuthData>(
    () => ({
      userId,
      userRole,
      memberRoles,
    }),
    [memberRoles, userId, userRole],
  );

  return useCallback(
    (permission: Permission, docData: PermissionDocData = {}) =>
      hasPermission({ permission, authData, docData }),
    [authData],
  );
}
