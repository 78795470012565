import {
  DefaultIndustry,
  Industry,
  LegacyIndustry,
} from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface OrganizationIndustryValue {
  label: TranslationKey;
}

export const DEFAULT_ORGANIZATION_INDUSTRY_RECORD: Record<
  DefaultIndustry,
  OrganizationIndustryValue
> = {
  ADMINISTRATION_AND_OFFICE_WORK: {
    label: "Industry_ADMINISTRATION_AND_OFFICE_WORK",
  },
  ARCHITECTURE_AND_PLANNING: { label: "Industry_ARCHITECTURE_AND_PLANNING" },
  AUTOMOTIVE: { label: "Industry_AUTOMOTIVE" },
  BANKING_AND_FINANCIAL_SERVICES: {
    label: "Industry_BANKING_AND_FINANCIAL_SERVICES",
  },
  CONSULTING: { label: "Industry_CONSULTING" },
  CHEMISTRY: { label: "Industry_CHEMISTRY" },
  SERVICES_INDUSTRY: { label: "Industry_SERVICES_INDUSTRY" },
  E_COMMERCE: { label: "Industry_E_COMMERCE" },
  ELECTRICAL_ENGINEERING: { label: "Industry_ELECTRICAL_ENGINEERING" },
  ENERGY: { label: "Industry_ENERGY" },
  EDUCATION_AND_SCIENCE: { label: "Industry_EDUCATION_AND_SCIENCE" },
  CRAFT_INDUSTRY: { label: "Industry_CRAFT_INDUSTRY" },
  HEALTHCARE: { label: "Industry_HEALTHCARE" },
  REAL_ESTATE_AND_FACILITY_MANAGEMENT: {
    label: "Industry_REAL_ESTATE_AND_FACILITY_MANAGEMENT",
  },
  INDUSTRY_AND_MECHANICAL_ENGINEERING: {
    label: "Industry_INDUSTRY_AND_MECHANICAL_ENGINEERING",
  },
  INTERNET_AND_MULTIMEDIA: { label: "Industry_INTERNET_AND_MULTIMEDIA" },
  IT_SERVICES: { label: "Industry_IT_SERVICES" },
  CONSUMER_GOODS_AND_RETAIL: { label: "Industry_CONSUMER_GOODS_AND_RETAIL" },
  ART_CULTURE_AND_ENTERTAINMENT: {
    label: "Industry_ART_CULTURE_AND_ENTERTAINMENT",
  },
  AGRICULTURE: { label: "Industry_AGRICULTURE" },
  MARKETING_ADVERTISING_AND_PR: {
    label: "Industry_MARKETING_ADVERTISING_AND_PR",
  },
  MARKET_RESEARCH: { label: "Industry_MARKET_RESEARCH" },
  MEDIA_AND_PUBLISHING: { label: "Industry_MEDIA_AND_PUBLISHING" },
  MEDICAL_TECHNOLOGY: { label: "Industry_MEDICAL_TECHNOLOGY" },
  FOOD_INDUSTRY: { label: "Industry_FOOD_INDUSTRY" },
  PUBLIC_ADMINISTRATION: { label: "Industry_PUBLIC_ADMINISTRATION" },
  HR_SERVICES: { label: "Industry_HR_SERVICES" },
  PHARMA: { label: "Industry_PHARMA" },
  SOCIAL_SERVICES: { label: "Industry_SOCIAL_SERVICES" },
  SPORT_FITNESS_AND_BEAUTY: { label: "Industry_SPORT_FITNESS_AND_BEAUTY" },
  TELECOMMUNICATION: { label: "Industry_TELECOMMUNICATION" },
  TEXTILE_INDUSTRY: { label: "Industry_TEXTILE_INDUSTRY" },
  TOURISM_HOTEL_AND_GASTRONOMY: {
    label: "Industry_TOURISM_HOTEL_AND_GASTRONOMY",
  },
  TRANSPORT_AND_LOGISTICS: { label: "Industry_TRANSPORT_AND_LOGISTICS" },
  ASSOCIATIONS_ORGANIZATIONS_AND_INSTITUTIONS: {
    label: "Industry_ASSOCIATIONS_ORGANIZATIONS_AND_INSTITUTIONS",
  },
  INSURANCES: { label: "Industry_INSURANCES" },
  AUDITING_TAXES_AND_LEGAL_ADVISE: {
    label: "Industry_AUDITING_TAXES_AND_LEGAL_ADVISE",
  },
  OTHER: { label: "Industry_OTHER" },
};

interface LegacyOrganizationIndustryValue extends OrganizationIndustryValue {
  defaultValue: DefaultIndustry;
}

export const LEGACY_ORGANIZATION_INDUSTRY_RECORD: Record<
  LegacyIndustry,
  LegacyOrganizationIndustryValue
> = {
  /**
   * @deprecated Only for backwards compatibility with ORGANIZATION_INDUSTRY. Use `ADMINISTRATION_AND_OFFICE_WORK` instead.
   */
  Administration: {
    label: "Industry_ADMINISTRATION_AND_OFFICE_WORK",
    defaultValue: "ADMINISTRATION_AND_OFFICE_WORK",
  },
  /**
   * @deprecated Only for backwards compatibility with ORGANIZATION_INDUSTRY. Use `ARCHITECTURE_AND_PLANNING` instead.
   */
  Architecture: {
    label: "Industry_ARCHITECTURE_AND_PLANNING",
    defaultValue: "ARCHITECTURE_AND_PLANNING",
  },
  /**
   * @deprecated Only for backwards compatibility with ORGANIZATION_INDUSTRY. Use `AUTOMOTIVE` instead.
   */
  Automotive: {
    label: "Industry_AUTOMOTIVE",
    defaultValue: "AUTOMOTIVE",
  },
  /**
   * @deprecated Only for backwards compatibility with ORGANIZATION_INDUSTRY. Use `BANKING_AND_FINANCIAL_SERVICES` instead.
   */
  Banking: {
    label: "Industry_BANKING_AND_FINANCIAL_SERVICES",
    defaultValue: "BANKING_AND_FINANCIAL_SERVICES",
  },
  /**
   * @deprecated Only for backwards compatibility with ORGANIZATION_INDUSTRY. Use `CONSULTING` instead.
   */
  Consulting: {
    label: "Industry_CONSULTING",
    defaultValue: "CONSULTING",
  },
};

export const ORGANIZATION_INDUSTRY_RECORD: Record<
  Industry,
  OrganizationIndustryValue
> = {
  ...LEGACY_ORGANIZATION_INDUSTRY_RECORD,
  ...DEFAULT_ORGANIZATION_INDUSTRY_RECORD,
};
