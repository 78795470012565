import { useEffect, useRef } from "react";
import type { RefHook } from "react-firebase-hooks/firestore/dist/util";

/**
 * TODO: Remove once https://github.com/CSFrequency/react-firebase-hooks/pull/281/files is merged.
 */
export const useComparatorRef = <T>(
  value: T | null | undefined,
  isEqual: (v1: T | null | undefined, v2: T | null | undefined) => boolean,
  onChange?: () => void,
): RefHook<T | null | undefined> => {
  const ref = useRef(value);
  useEffect(() => {
    if (!isEqual(value, ref.current)) {
      ref.current = value;
      if (onChange) {
        onChange();
      }
    }
  });
  return ref;
};
