import {
  Badge,
  Container,
  FormControl,
  FormGroup,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  PROVIDER_TECHNOLOGY_CATEGORIES,
  ProviderTechnology,
  ProviderTechnologyCategory,
  ProviderTechnologyStruct,
} from "@snubes/snubes-types";
import { FC, useCallback, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { array, type } from "superstruct";
import { toggleArray } from "../../Common/helpers/toggleArray";
import { CheckboxButton } from "../../Form/views/CheckboxButton";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { PROVIDER_TECHNOLOGY } from "../consts/PROVIDER_TECHNOLOGY";
import {
  PROVIDER_TECHNOLOGY_BY_CATEGORY,
  PROVIDER_TECHNOLOGY_CATEGORIES_BY_TECHNOLOGY,
} from "../consts/PROVIDER_TECHNOLOGY_BY_CATEGORY";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  toolsAndTechnologies: array(ProviderTechnologyStruct),
});

export const ProviderWizardToolsAndTechnologiesForm: FC<WizardFormProps> = (
  props,
) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      toolsAndTechnologies: provider?.toolsAndTechnologies || [],
    }),
    toDoc: (formValues) => {
      return {
        toolsAndTechnologies: formValues?.toolsAndTechnologies,
      };
    },
  });

  const [category, setCategory] =
    useState<ProviderTechnologyCategory>("TECHNOLOGIES");

  const technologies = useMemo(
    () =>
      PROVIDER_TECHNOLOGY_BY_CATEGORY.get(category)
        ?.map((type) => ({
          value: type,
          label: PROVIDER_TECHNOLOGY[type].label,
          caption: PROVIDER_TECHNOLOGY[type].caption,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [category],
  );

  const getSelectedTechnologiesByCategory = useCallback(
    (technologies: ProviderTechnology[]) => {
      return technologies.reduce(
        (result, technology) => {
          const category =
            PROVIDER_TECHNOLOGY_CATEGORIES_BY_TECHNOLOGY.get(technology);
          if (!category) return result;

          const technologiesByCategory = result[category] || [];
          return {
            ...result,
            [category]: [...technologiesByCategory, technology],
          };
        },
        {} as Record<ProviderTechnologyCategory, ProviderTechnology[]>,
      );
    },
    [],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t(
            "ProviderWizardToolsAndTechnologiesForm_Title_ToolsAndTechnologies",
          )}
        </Typography>
        <Typography variant="body1" color="text.500" mb={4}>
          {t(
            "ProviderWizardToolsAndTechnologiesForm_Paragraph_ToolsAndTechnologies",
          )}
        </Typography>
        <Stack spacing={3}>
          <Controller
            name="toolsAndTechnologies"
            control={control}
            render={({ field, fieldState }) => {
              const currentValues: ProviderTechnology[] = field.value;
              const selectedTechnologiesByCategory =
                getSelectedTechnologiesByCategory(currentValues);

              return (
                <Stack>
                  <Tabs
                    value={category}
                    onChange={(_, nextValue: ProviderTechnologyCategory) =>
                      setCategory(nextValue)
                    }
                  >
                    {PROVIDER_TECHNOLOGY_CATEGORIES.map((category) => (
                      <Tab
                        key={category}
                        value={category}
                        label={
                          <Badge
                            badgeContent={
                              selectedTechnologiesByCategory[category]
                                ?.length ?? 0
                            }
                            color="primary"
                            sx={{ ".MuiBadge-badge": { right: -5 } }}
                          >
                            {t(
                              `ProviderWizardToolsAndTechnologiesForm_Category_${category}`,
                            )}
                          </Badge>
                        }
                      />
                    ))}
                  </Tabs>
                  <Grid container spacing={2} mt={3}>
                    <FormControl fullWidth error={!!fieldState.error}>
                      <FormGroup>
                        <Grid container spacing={4}>
                          {technologies?.map((option) => (
                            <Grid item key={option.value} xs={12} sm={6}>
                              <CheckboxButton
                                {...option}
                                checked={(field.value as unknown[]).includes(
                                  option.value,
                                )}
                                onChange={() =>
                                  field.onChange(
                                    toggleArray(option.value, field.value),
                                  )
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Stack>
              );
            }}
          />
        </Stack>
      </Container>
    </form>
  );
};
