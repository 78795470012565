import { WizardStep } from "../types/WizardStep";
import { WizardStore } from "../types/WizardStore";

export function getFirstIncompleteWizardStepPath<T>(
  steps: WizardStep<T>[],
  store: WizardStore<T>,
): string {
  return (
    findFirstIncompleteWizardStepPath(steps, store, "") ||
    steps[steps.length - 1].pathSegment
  );
}

function findFirstIncompleteWizardStepPath<T>(
  steps: WizardStep<T>[],
  store: WizardStore<T>,
  previousPath: string,
): string | undefined {
  for (const step of steps) {
    if (step.steps) {
      const subStep = findFirstIncompleteWizardStepPath(
        step.steps,
        store,
        `${previousPath}${step.pathSegment}/`,
      );
      if (subStep) {
        return subStep;
      }
    }
    if (
      step.pathSegment &&
      step.progressSelector &&
      step.progressSelector(store.getState()) < 1
    ) {
      return `${previousPath}${step.pathSegment}`;
    }
  }
}
