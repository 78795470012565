import CheckIcon from "@mui/icons-material/Check";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { Organization, OrganizationType, UserType } from "@snubes/snubes-types";
import { FC, SyntheticEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LinkView } from "../../Common/views/LinkView";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { USER_TYPE_RECORD } from "../../Users/consts/USER_TYPE_RECORD";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { useSetUserType } from "../../Users/hooks/useSetUserType";

interface Props {
  organizations: Organization[];
  closeMenu: () => void;
  onSetOrganizationId: (organizationId: string) => Promise<void>;
}

export const HeaderOrganizationMenuItemsView: FC<Props> = (props) => {
  const { organizations } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const selectedOrganization = useSelectedOrganization();
  const [setUserType] = useSetUserType();
  const t = useT();

  const onClickSelectOrganization = async (organization: Organization) => {
    await props.onSetOrganizationId(organization.id);

    // If the user has a user type that is not included in the organization, set first organization type
    // as the user type. That way when navigating to home, the user will be redirected to the correct home page.
    const currentUserType = useMyUser.getState().user?.userType;
    let nextUserType: UserType | undefined;
    if (
      currentUserType &&
      currentUserType !== "ADMIN" &&
      organization.types?.length &&
      !organization.types.includes(currentUserType as OrganizationType)
    ) {
      nextUserType = organization.types[0];
      await setUserType(nextUserType);
    }
    // Only navigate to the organization page if there is only one organization.
    if (organizations.length === 1) {
      navigate(`/organizations/${organization.id}`);
      return;
    }
    // If the user type was changed and we're currently on the home page, navigate to the correct home page.
    if (nextUserType && location.pathname.startsWith("/home/")) {
      const { homeScreenPath } = USER_TYPE_RECORD[nextUserType];
      navigate(homeScreenPath);
      return;
    }
  };

  const onClickOrganizationLink = (event: SyntheticEvent) => {
    event.stopPropagation();
    props.closeMenu();
  };

  return organizations?.map((organization) => (
    <MenuItem
      key={organization.id}
      onClick={() => onClickSelectOrganization(organization)}
    >
      {organizations.length > 1 &&
        selectedOrganization?.id === organization.id && (
          <ListItemIcon>
            <CheckIcon fontSize="small" />
          </ListItemIcon>
        )}
      <ListItemText
        primaryTypographyProps={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        inset={selectedOrganization?.id !== organization.id}
      >
        {organization.name}
      </ListItemText>
      {/* If there is more than 1 organization, show a link to go to the organization page */}
      {organizations.length > 1 && (
        <ListItemIcon sx={{ pl: 2 }}>
          <LinkView
            to={`/organizations/${organization.id}`}
            onClick={onClickOrganizationLink}
          >
            {t("HeaderUserMenuView_Label_OpenOrganization")}
          </LinkView>
        </ListItemIcon>
      )}
    </MenuItem>
  ));
};
