import { isProject, Path, Project } from "@snubes/snubes-types";
import { limit, query, where } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

interface Args {
  providerOrganizationId: string;
}

export function useHasProjects(args: Args) {
  const [projects, isLoading, error] = useValidCollectionData(
    query(
      getCollectionRef("projects"),
      where(
        "providerOrganizationId" satisfies Path<Project>,
        "==",
        args.providerOrganizationId,
      ),
      where("isDeleted", "==", false),
      where("isArchived", "==", false),
      limit(1),
    ),
    isProject,
  );
  return [projects.length > 0, isLoading, error] as const;
}
