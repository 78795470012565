import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TuneIcon from "@mui/icons-material/Tune";
import { Container, Stack, Typography } from "@mui/material";
import { WeekdayPeriodStruct } from "@snubes/snubes-types";
import { FC } from "react";
import { array, boolean, nullable, optional, string, type } from "superstruct";
import { ControlledAutocompleteTimeZone } from "../../Form/views/ControlledAutocompleteTimeZone";
import { ControlledRadioButtons } from "../../Form/views/ControlledRadioButtons";
import { ControlledWeekdayPeriods } from "../../Form/views/ControlledWeekdayPeriods";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { BROWSER_TIME_ZONE_NAME } from "../consts/BROWSER_TIME_ZONE_NAME";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  isOperatingAroundTheClock: nullable(boolean()),
  operatingHours: array(WeekdayPeriodStruct),
  operatingTimeZone: optional(string()),
});

export const TenderWizardOperatingHoursForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit, watch } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => {
      return {
        isOperatingAroundTheClock:
          tender?.isOperatingAroundTheClock !== undefined
            ? tender.isOperatingAroundTheClock
            : null,
        operatingHours: tender?.operatingHours || [],
        operatingTimeZone: tender?.operatingTimeZone || BROWSER_TIME_ZONE_NAME,
      };
    },
    toDoc: (formValues, tender) => ({
      isOperatingAroundTheClock:
        typeof formValues.isOperatingAroundTheClock === "boolean"
          ? formValues.isOperatingAroundTheClock
          : undefined,
      operatingHours: formValues.isOperatingAroundTheClock
        ? undefined
        : formValues.operatingHours,
      operatingTimeZone: formValues.isOperatingAroundTheClock
        ? undefined
        : formValues.operatingTimeZone,
      isReviewed: {
        ...tender?.isReviewed,
        basicDetails: false,
      },
    }),
  });

  const isOperatingAroundTheClock = watch("isOperatingAroundTheClock");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardOperatingHoursForm_Title_OperatingHours")}
        </Typography>
        <Typography variant="body2" color="text.500" mb={4}>
          {t("TenderWizardOperatingHoursForm_Description_OperatingHours")}
        </Typography>
        <Stack spacing={4}>
          <ControlledRadioButtons
            control={control}
            name="isOperatingAroundTheClock"
            options={[
              {
                Icon: AccessTimeIcon,
                label: "TenderWizardOperatingHoursForm_Label_AroundTheClock",
                caption:
                  "TenderWizardOperatingHoursForm_Caption_AroundTheClock",
                value: true,
              },
              {
                Icon: TuneIcon,
                label: "TenderWizardOperatingHoursForm_Label_CustomTimings",
                caption: "TenderWizardOperatingHoursForm_Caption_CustomTimings",
                value: false,
              },
            ]}
            gridSpacing={4}
            gridItemXs={6}
          />
          {isOperatingAroundTheClock === false && (
            <Stack flex={1} spacing={4}>
              <ControlledAutocompleteTimeZone
                control={control}
                name="operatingTimeZone"
                required
              />
              <ControlledWeekdayPeriods
                control={control}
                name="operatingHours"
                required
              />
            </Stack>
          )}
        </Stack>
      </Container>
    </form>
  );
};
