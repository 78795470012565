import { Container, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { boolean, Infer, type } from "superstruct";
import { ControlledWizardReviewButton } from "../../Form/views/ControlledWizardReviewButton";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { WizardReviewStepView } from "../../Wizard/views/WizardReviewStepView";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";
import { ProviderWizardStep } from "../types/ProviderWizardStep";

const FormValuesStruct = type({
  basicDetails: boolean(),
  businessDetails: boolean(),
  serviceDetails: boolean(),
  operationalDetails: boolean(),
});

const FORM_KEYS: (keyof Infer<typeof FormValuesStruct>)[] = [
  "basicDetails",
  "businessDetails",
  "serviceDetails",
  "operationalDetails",
];

type Props = WizardFormProps<{
  getSteps: () => ProviderWizardStep[];
}>;

export const ProviderWizardReviewForm: FC<Props> = (props) => {
  const t = useT();
  const steps = props.formProps.getSteps();

  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => {
      const isReviewed = provider?.isReviewed;

      return {
        basicDetails: !!isReviewed?.basicDetails,
        businessDetails: !!isReviewed?.businessDetails,
        serviceDetails: !!isReviewed?.serviceDetails,
        operationalDetails: !!isReviewed?.operationalDetails,
      };
    },
    toDoc: (formValues) => ({
      isReviewed: {
        basicDetails: !!formValues.basicDetails,
        businessDetails: !!formValues.businessDetails,
        serviceDetails: !!formValues.serviceDetails,
        operationalDetails: !!formValues.operationalDetails,
      },
    }),
  });

  // We remove the last step, which is the review step itself.
  const stepsToReview = steps.slice(0, -1);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderReviewForm_Title_Main")}
        </Typography>
        <Stack mx={-3}>
          {stepsToReview.map((step, index) => (
            <WizardReviewStepView
              key={step.pathSegment}
              step={step}
              store={useProviderWizardStore}
              button={
                <ControlledWizardReviewButton
                  control={control}
                  name={FORM_KEYS[index]}
                  store={useProviderWizardStore}
                  step={step}
                />
              }
            />
          ))}
        </Stack>
      </Container>
    </form>
  );
};
