import { useEffect, useState } from "react";

/**
 * TODO: Remove once https://github.com/CSFrequency/react-firebase-hooks/pull/281/files is merged.
 */
export function useIsMounted() {
  const [isMounted, setIsMounted] = useState(true);
  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);
  return isMounted;
}
