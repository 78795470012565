import { FC } from "react";
import { Outlet } from "react-router-dom";
import { NavTab } from "../../Common/types/NavTab";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";
import {
  ADMIN_TENDERS_ROUTE_RECORD,
  ADMIN_TENDERS_ROUTES,
} from "../consts/ADMIN_TENDERS_ROUTE_RECORD";

const TABS = ADMIN_TENDERS_ROUTES.map<NavTab>((route) => ({
  label: ADMIN_TENDERS_ROUTE_RECORD[route].label,
  route,
}));

export const AdminTendersLayoutView: FC = () => {
  return (
    <>
      <SecondaryHeaderView pathPrefix="/admin/tenders" tabs={TABS} />
      <Outlet />
    </>
  );
};
