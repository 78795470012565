import { FC } from "react";
import { Outlet } from "react-router-dom";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";
import { NOTIFICATION_NAV_TABS } from "../consts/NOTIFICATION_NAV_TABS";

export const NotificationsLayoutView: FC = () => {
  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <SecondaryHeaderView
        tabs={NOTIFICATION_NAV_TABS}
        pathPrefix="/notifications"
      />
      <Outlet />
    </>
  );
};
