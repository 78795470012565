import { Grid } from "@mui/material";
import { ProjectReportFrame } from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC } from "react";
import { toLocaleMinutes } from "../../Common/helpers/toLocaleMinutes";
import { useT } from "../../Translation/hooks/useT";
import { ProjectChartGridItem } from "./ProjectChartGridItem";

interface Props {
  projectReportFrames: ProjectReportFrame[];
}

export const ProjectChartGrid: FC<Props> = (props) => {
  const frames = props.projectReportFrames;
  const t = useT();

  return (
    <Grid container spacing={4}>
      <ProjectChartGridItem
        title={t("ProjectChartGrid_Title_NumberOfCalls")}
        data={frames.map((f) => ({
          key: DateTime.fromMillis(f.date.toMillis()).toISODate(),
          value: f.totalCallsCount,
        }))}
      />
      <ProjectChartGridItem
        title={t("ProjectChartGrid_Title_CallsAnswered")}
        data={frames.map((f) => ({
          key: DateTime.fromMillis(f.date.toMillis()).toISODate(),
          value: f.answeredCallsCount,
        }))}
      />
      <ProjectChartGridItem
        title={t("ProjectChartGrid_Title_Reachability")}
        data={frames.map((f) => ({
          key: DateTime.fromMillis(f.date.toMillis()).toISODate(),
          value: f.reachabilityPercentage,
        }))}
        formatter={(value) =>
          value.toLocaleString(undefined, {
            maximumFractionDigits: 1,
            style: "percent",
          })
        }
      />
      <ProjectChartGridItem
        title={t("ProjectChartGrid_Title_AverageHandlingTime")}
        data={frames.map((f) => ({
          key: DateTime.fromMillis(f.date.toMillis()).toISODate(),
          value: f.averageHandlingTimeInSeconds,
        }))}
        formatter={(value) =>
          t("Common_Mins", {
            minutes: toLocaleMinutes(value * 1000),
          })
        }
      />
      <ProjectChartGridItem
        title={t("ProjectChartGrid_Title_TotalHandlingTime")}
        data={frames.map((f) => ({
          key: DateTime.fromMillis(f.date.toMillis()).toISODate(),
          value: f.totalHandlingTimeInSeconds,
        }))}
        formatter={(value) =>
          t("Common_Mins", {
            minutes: toLocaleMinutes(value * 1000),
          })
        }
      />
      <ProjectChartGridItem
        title={t("ProjectChartGrid_Title_ServiceLevel")}
        data={frames.map((f) => ({
          key: DateTime.fromMillis(f.date.toMillis()).toISODate(),
          value: f.serviceLevelPercentage,
        }))}
        formatter={(value) =>
          value.toLocaleString(undefined, {
            maximumFractionDigits: 1,
            style: "percent",
          })
        }
      />
      {/* TODO: Implement average waiting time! */}
      {/* <ProjectChartGridItem
        title={t("ProjectChartGrid_Title_AverageWaitingTime")}
        data={frames.map((f) => ({
          key: DateTime.fromMillis(f.date.toMillis()).toISODate(),
          value: f.totalCallsCount,
        }))}
      /> */}
    </Grid>
  );
};
