import { ProviderPricingModel } from "@snubes/snubes-types/types/ProviderPricingModel";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface PricingModelType {
  label: TranslationKey;
}

export const PROVIDER_PRICING_MODEL: Record<
  ProviderPricingModel,
  PricingModelType
> = {
  PER_TRANSACTION: {
    label: "ProviderPricingModel_Label_PerTransaction",
  },
  PER_LEAD: {
    label: "ProviderPricingModel_Label_PerLead",
  },
  PER_CALL: {
    label: "ProviderPricingModel_Label_PerCall",
  },
  PER_MINUTE: {
    label: "ProviderPricingModel_Label_PerMinute",
  },
  PER_HOUR: {
    label: "ProviderPricingModel_Label_PerHour",
  },
  PER_MONTH: {
    label: "ProviderPricingModel_Label_PerMonth",
  },
  PER_ORDER: {
    label: "ProviderPricingModel_Label_PerOrder",
  },
  PER_PROJECT: {
    label: "ProviderPricingModel_Label_PerProject",
  },
  PER_NET_CONTACT: {
    label: "ProviderPricingModel_Label_PerNetContact",
  },
  PER_EMAIL: {
    label: "ProviderPricingModel_Label_PerEmail",
  },
  PER_MAIL: {
    label: "ProviderPricingModel_Label_PerMail",
  },
  PER_CHAT: {
    label: "ProviderPricingModel_Label_PerChat",
  },
  PER_FAX: {
    label: "ProviderPricingModel_Label_PerFax",
  },
};
