import {
  Currency,
  ORGANIZATION_REVENUE_VALUES,
  OrganizationRevenueValue,
} from "@snubes/snubes-types";
import { useCallback, useMemo } from "react";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { PROVIDER_REVENUE_RANGE_RECORD } from "../../Providers/consts/PROVIDER_REVENUE_RANGE_RECORD";
import { useT } from "../../Translation/hooks/useT";

export function useRevenueOptions(currency: Currency) {
  const t = useT();

  const getRevenueOptionLabel = useCallback(
    (revenue: OrganizationRevenueValue) => {
      const { label, labelValue } = PROVIDER_REVENUE_RANGE_RECORD[revenue];
      const value = toLocaleMoney({
        amount: labelValue,
        currency,
        fractionDigits: 0,
      });
      return { label, value };
    },
    [currency],
  );

  return useMemo(() => {
    return ORGANIZATION_REVENUE_VALUES.slice(1).map((value, i, values) => {
      const { label: labelFrom, value: valueFrom } = getRevenueOptionLabel(
        values[i - 1] || 0,
      );
      const { label: labelTo, value: valueTo } = getRevenueOptionLabel(value);
      const textFrom = t(labelFrom, {
        value: valueFrom,
      });
      const textTo = t(labelTo, { value: valueTo });

      if (i === 0) {
        return {
          value: i + 1,
          label: t(PROVIDER_REVENUE_RANGE_RECORD[0].label, {
            value:
              PROVIDER_REVENUE_RANGE_RECORD[ORGANIZATION_REVENUE_VALUES[1]]
                .labelValue,
          }),
        };
      }
      if (i === values.length - 1) {
        return { value: i + 1, label: textFrom };
      }

      return { value: i + 1, label: `${textFrom} - ${textTo}` };
    });
  }, [getRevenueOptionLabel, t]);
}
