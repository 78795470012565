import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { Project } from "@snubes/snubes-types";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { archiveProjectCallable } from "../callables/archiveProjectCallable";

interface Props {
  project?: Project;
  isOpen: boolean;
  isNavigating?: boolean;
  close: () => void;
}

export const ProjectArchiveDialog: FC<Props> = (props) => {
  const t = useT();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const archiveProject = useCallback(async () => {
    if (!props.project) return;

    try {
      setIsSubmitting(true);

      await archiveProjectCallable({
        projectId: props.project.id,
      });
      if (props.isNavigating) {
        navigate("/admin/projects/all");
      }
      toast.success(t("ProjectArchiveDialog_Toast_SuccessMessage"));
    } catch (error) {
      handleError(error).logAnd().toast();
      setIsSubmitting(false);
    }
  }, [props.project, props.isNavigating, t, navigate]);

  return (
    <Dialog open={props.isOpen} onClose={props.close} fullWidth>
      <DialogTitle>
        {t("ProjectArchiveDialog_Title_ArchiveProject")}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          {t("ProjectArchiveDialog_Content_ArchiveProject")}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          disabled={isSubmitting}
          onClick={(event) => {
            event.stopPropagation();
            props.close();
          }}
        >
          {t("ProjectArchiveDialog_Button_Cancel")}
        </Button>
        <Button
          color="primary"
          variant="outlined"
          disabled={isSubmitting}
          onClick={(event) => {
            event.stopPropagation();
            void archiveProject();
          }}
        >
          {t("ProjectArchiveDialog_Button_Archive")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
