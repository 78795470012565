import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { TenderApplicationStatus } from "@snubes/snubes-types";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { handleError } from "../../Common/helpers/handleError";
import { LoadingView } from "../../Common/views/LoadingView";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { createOrUpdateTenderApplicationCallable } from "../callables/createOrUpdateTenderApplicationCallable";
import { getTenderApplicationAllowedTransitions } from "../helpers/getTenderApplicationAllowedTransitions";
import { useTenderContext } from "../hooks/useTenderContext";
import { useTenderStatus } from "../hooks/useTenderStatus";
import { TenderApplicationRejectDialog } from "./TenderApplicationRejectDialog";
import { TenderDetailsView } from "./TenderDetailsView";
import { TenderStatusSelectView } from "./TenderStatusSelectView";

export const TenderDetailsPage: FC = () => {
  const t = useT();
  const navigate = useNavigate();
  const userType = useMyUser((state) => state.user?.userType || null);
  const { tender, isLoading, error } = useTenderContext();
  const hasPermission = useHasPermission();
  const selectedOrganizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );
  const [isUpdating, setIsUpdating] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const hasTenderEnded = useTenderStatus(tender) === "ENDED" ? true : false;

  const providerApplication = selectedOrganizationId
    ? tender?.providerApplications?.[selectedOrganizationId]
    : undefined;

  const applicationAllowedTransitions = getTenderApplicationAllowedTransitions({
    userType,
    tender,
    tenderApplication: providerApplication,
  });

  const handleChangeApplicationStatus = async (
    status: TenderApplicationStatus,
  ) => {
    if (!tender?.id || !selectedOrganizationId) {
      return;
    }
    try {
      setIsUpdating(true);
      await createOrUpdateTenderApplicationCallable({
        tenderId: tender.id,
        providerId: selectedOrganizationId,
        tenderApplication: {
          status,
        },
      });
      navigate(`/tenders/${tender.id}/my-application`);
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      {isLoading && <LoadingView />}
      {error && <Alert severity="error">{error.message}</Alert>}
      {tender && (
        <Stack spacing={3}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h3">
              {t("TenderDetailsLayoutView_SecondaryHeaderLabel_Details")}
            </Typography>

            <Stack direction="row" spacing={3}>
              {hasPermission("CAN_UPDATE_TENDER_STATUS") && (
                <TenderStatusSelectView tender={tender} />
              )}
              {applicationAllowedTransitions.REJECT_BY_PROVIDER && (
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => setIsRejectDialogOpen(true)}
                  startIcon={<ContentPasteOffIcon />}
                  disabled={isUpdating || hasTenderEnded}
                  sx={{ minWidth: 130 }}
                >
                  {t("Common_Reject")}
                </Button>
              )}
              {(applicationAllowedTransitions.EDIT ||
                applicationAllowedTransitions.PUBLISH) && (
                <Button
                  variant="contained"
                  startIcon={<PostAddIcon />}
                  href={`/tenders/${tender.id}/my-application`}
                  disabled={isUpdating || hasTenderEnded}
                  sx={{ minWidth: 200 }}
                >
                  {t("TenderDetailsPage_Button_ApplyForTender")}
                </Button>
              )}
              {applicationAllowedTransitions.REAPPLY && (
                <Button
                  variant="contained"
                  endIcon={<PostAddIcon />}
                  disabled={isUpdating || hasTenderEnded}
                  onClick={() => handleChangeApplicationStatus("DRAFT")}
                >
                  {t("TenderDetailsPage_Button_Reapply")}
                </Button>
              )}
            </Stack>
          </Stack>

          <TenderDetailsView tender={tender} />
          {selectedOrganizationId && isRejectDialogOpen && (
            <TenderApplicationRejectDialog
              tenderId={tender.id}
              providerId={selectedOrganizationId}
              close={() => setIsRejectDialogOpen(false)}
            />
          )}
        </Stack>
      )}
    </>
  );
};
