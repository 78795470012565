import { Timestamp } from "@snubes/snubes-types";
import { DateTime, DateTimeFormatOptions } from "luxon";

type FormatOptionsKey = keyof Pick<
  DateTimeFormatOptions,
  "day" | "month" | "year" | "hour" | "minute"
>;

type FormatOptions = Partial<Record<FormatOptionsKey, boolean>>;

type Variant = "date" | "dateTime" | FormatOptions;

export function toLocaleDateTimeString(
  date?: DateTime | Timestamp | string | null,
  variant: Variant = "dateTime",
) {
  if (!date) return "";

  const dateTime =
    typeof date === "string"
      ? DateTime.fromISO(date)
      : DateTime.fromMillis(date.toMillis());

  return dateTime.toLocaleString(getOptions(variant));
}

function getOptions(variant: Variant): DateTimeFormatOptions {
  if (typeof variant === "object") {
    return {
      ...(variant.day && { day: "2-digit" }),
      ...(variant.month && { month: "2-digit" }),
      ...(variant.year && { year: "numeric" }),
      ...(variant.hour && { hour: "numeric" }),
      ...(variant.minute && { minute: "numeric" }),
    };
  } else {
    return {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      ...(variant === "dateTime" && {
        hour: "numeric",
        minute: "numeric",
      }),
    };
  }
}
