import {
  BACK_OFFICE_PRICING_MODELS,
  INBOUND_PRICING_MODELS,
  OUTBOUND_PRICING_MODELS,
  ProviderPricingModel,
  ServiceCategory,
} from "@snubes/snubes-types";

export const PROVIDER_PRICING_MODELS_BY_CATEGORY = new Map<
  ServiceCategory,
  ReadonlyArray<ProviderPricingModel>
>([
  ["INBOUND", INBOUND_PRICING_MODELS],
  ["OUTBOUND", OUTBOUND_PRICING_MODELS],
  ["BACK_OFFICE_SUPPORT", BACK_OFFICE_PRICING_MODELS],
]);
