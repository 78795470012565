import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Button } from "@mui/material";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { updateNotificationHasBeenReadAllCallable } from "../callables/updateNotificationHasBeenReadAllCallable";

interface BaseProps {
  isDisabled?: boolean;
}

interface PropsWithOrganizationId extends BaseProps {
  organizationId: string;
  isAssigned: boolean;
  isTask?: boolean;
}

interface PropsWithUserId extends BaseProps {
  userId: string;
}

type Props = PropsWithOrganizationId | PropsWithUserId;

export const NotificationsMarkAllAsReadButton: FC<Props> = (props) => {
  const t = useT();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onUpdateAllNotificationHasBeenReadCallable = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await updateNotificationHasBeenReadAllCallable(
        "organizationId" in props
          ? {
              organizationId: props.organizationId,
              isAssigned: props.isAssigned,
              ...(typeof props.isTask === "boolean" && {
                isTask: props.isTask,
              }),
            }
          : {
              userId: props.userId,
            },
      );

      toast.success(t("NotificationsMarkAllAsReadButton_Toast_MarkedAsRead"));
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsSubmitting(false);
    }
  }, [props, t]);

  return (
    <Button
      variant="text"
      color="primary"
      size="small"
      disabled={isSubmitting || props.isDisabled}
      startIcon={<DoneAllIcon />}
      onClick={onUpdateAllNotificationHasBeenReadCallable}
    >
      {t("NotificationsMarkAllAsReadButton_Button_MarkAllAsRead")}
    </Button>
  );
};
