import { Box, Drawer, List, Stack } from "@mui/material";
import { FC, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { HEADER_HEIGHT } from "../../Header/consts/HEADER_HEIGHT";
import { useAppNavigationItems } from "../hooks/useAppNavigationItems";
import { useAppSidebar } from "../hooks/useAppSidebar";
import { AppSidebarNavLink } from "./AppSidebarNavLink";

export const AppSidebarView: FC = () => {
  const appSidebar = useAppSidebar();
  const items = useAppNavigationItems();

  return (
    <Drawer
      variant="temporary"
      open={appSidebar.isMobileOpen}
      onClose={() => appSidebar.setIsMobileOpen(false)}
    >
      <Stack
        height={HEADER_HEIGHT - 1}
        alignItems="center"
        justifyContent="center"
      >
        <NavLink to="/">
          <img src="/assets/logo.svg" alt="logo" />
        </NavLink>
      </Stack>
      <List sx={{ pt: 0 }}>
        {items
          .filter((i) => !i.isHidden)
          .map((item) => (
            <Fragment key={item.title}>
              <AppSidebarNavLink {...item} />
              <Box ml={5}>
                {item.items?.map((i) => (
                  <AppSidebarNavLink {...i} key={i.title} />
                ))}
              </Box>
            </Fragment>
          ))}
      </List>
    </Drawer>
  );
};
