import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import EditIcon from "@mui/icons-material/Edit";
import {
  AccordionDetails,
  AccordionSummary,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
import { isValidElement, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "zustand";
import { StatusChip } from "../../Common/views/StatusChip";
import { useWizardStepProgress } from "../hooks/useWizardStepProgress";
import { WizardStep } from "../types/WizardStep";
import { WizardStore } from "../types/WizardStore";

interface Props<TState> {
  step: WizardStep<TState>;
  store: WizardStore<TState>;
  button?: ReactNode;
}

export function WizardReviewStepView<TState>(props: Props<TState>) {
  const { step, store, button } = props;
  const progress = useWizardStepProgress(step, store);
  const navigate = useNavigate();
  const value = useStore(store, step.valueSelector || (() => undefined));
  const isCompleted = progress >= 1;

  if (!step.steps) {
    return (
      <Stack
        onClick={() => navigate(step.path || "/")}
        p={3}
        mx={-3}
        sx={{
          "&:hover": {
            backgroundColor: "grey.50",
            cursor: "pointer",
          },
        }}
      >
        <Typography variant="body1" color="text.secondary" gutterBottom>
          {step.title}
        </Typography>
        {isCompleted &&
          (isValidElement(value) ? (
            value
          ) : (
            <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
              {value}
            </Typography>
          ))}
        {!isCompleted && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
            flex={1}
          >
            <StatusChip label="Incomplete" color="error" />
            <Stack>
              <IconButton color="error" size="small">
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Stack>
          </Stack>
        )}
      </Stack>
    );
  }

  return (
    <SAccordion isCompleted={isCompleted} disableGutters elevation={0} square>
      <SAccordionSummary
        isCompleted={isCompleted}
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: 14 }} />}
      >
        <Typography variant="subtitle1">{step.title}</Typography>
        {button}
      </SAccordionSummary>
      <Stack mx={3}>
        <Divider />
      </Stack>
      <SAccordionDetails>
        <Stack mx={3}>
          {/* A parent step can also have a form. In this case, we also want to be able to edit it. */}
          {step.Form && (
            <Stack mb={3}>
              <WizardReviewStepView
                store={store}
                step={{
                  ...step,
                  steps: undefined,
                  progressSelector: () => (step.steps?.length ? 1 : 0),
                }}
              />
            </Stack>
          )}
          {step.steps?.map((s) => (
            <WizardReviewStepView key={s.pathSegment} step={s} store={store} />
          ))}
        </Stack>
      </SAccordionDetails>
    </SAccordion>
  );
}

export const SAccordion = styled(MuiAccordion, {
  shouldForwardProp: (name) => name !== "isCompleted",
})<{
  isCompleted: boolean;
}>(({ theme, isCompleted }) => ({
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: isCompleted ? theme.palette.divider : theme.palette.error.main,
  marginBottom: theme.spacing(3),
  "&:last-child": {
    marginBottom: 0,
  },
  "::before": {
    display: "none",
  },
}));

export const SAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

export const SAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (name) => name !== "isCompleted",
})<{
  isCompleted: boolean;
}>(({ theme, isCompleted }) => ({
  backgroundColor: isCompleted ? theme.palette.background.default : "#FFF9F9",
  flexDirection: "row-reverse",
  padding: theme.spacing(3),
  color: isCompleted ? theme.palette.text.primary : theme.palette.error.main,
  "&.Mui-expanded": {
    backgroundColor: theme.palette.background.paper,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: isCompleted ? theme.palette.text.primary : theme.palette.error.main,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: isCompleted ? theme.palette.text.primary : theme.palette.error.main,
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    marginLeft: theme.spacing(3),
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    color: isCompleted ? theme.palette.text.primary : theme.palette.error.main,
  },
}));
