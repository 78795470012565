import { Stack } from "@mui/material";
import { isTenderLog } from "@snubes/snubes-types";
import { FC } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { useIsFeatureFlagEnabled } from "../../Admin/hooks/useIsFeatureFlagEnabled";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { useSidebarWidth } from "../../Common/hooks/useSidebarWidth";
import { NavTab } from "../../Common/types/NavTab";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";
import { LogListItemViewForTender } from "../../Logs/views/LogListItemViewForTender";
import { LogsButtonView } from "../../Logs/views/LogsButtonView";
import { useUpdateNotificationHasBeenRead } from "../../Notifications/hooks/useUpdateNotificationHasBeenRead";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { getTenderApplications } from "../helpers/getTenderApplications";
import { useTender } from "../hooks/useTender";
import { TenderContextProvider } from "../hooks/useTenderContext";
import { TenderDetailsSidebarView } from "./TenderDetailsSidebarView";

export const TenderDetailsLayoutView: FC = () => {
  const t = useT();
  const { tenderId } = useParams<"tenderId">();
  const userId = useMyUser((state) => state.user?.id || null);
  const [tender, isLoading, error] = useTender(tenderId);
  const sidebarWidth = useSidebarWidth();
  const hasPermission = useHasPermission();
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled();

  // Mark any tender related notification as read.
  useUpdateNotificationHasBeenRead({
    organizationId: tender?.organizationId || null,
    userId,
    filters: tenderId ? [["tenderId", "==", tenderId]] : [],
  });

  const selectedOrganizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );

  const isOwnTender = tender?.organizationId === selectedOrganizationId;
  const tenderApplications =
    (!!tender &&
      getTenderApplications(
        tender,
        "PUBLISHED",
        "ACCEPTED",
        "REJECTED",
        "REJECTED_FOR_RESUBMISSION",
      )) ||
    [];
  const newTenderApplications = tenderApplications.filter(
    (application) => application.status === "PUBLISHED",
  );
  const hasProviderApplications = tenderApplications.length > 0;

  const tenderHasMyApplication =
    !!selectedOrganizationId &&
    !!tender?.providerApplications &&
    !!tender.providerApplications[selectedOrganizationId]?.status;

  const tabs: NavTab[] = [
    {
      label: "TenderDetailsLayoutView_SecondaryHeaderLabel_Details",
      route: "details",
    },
    {
      label: "TenderDetailsLayoutView_SecondaryHeaderLabel_Applications",
      route: "applications",
      badgeContent: newTenderApplications.length,
      isHidden: !isLoading && !isOwnTender,
      isDisabled: isLoading || !hasProviderApplications,
    },
    {
      label: "TenderDetailsLayoutView_SecondaryHeaderLabel_MyApplication",
      route: "my-application",
      isHidden: !isLoading && isOwnTender,
      isDisabled: isLoading || !tenderHasMyApplication,
    },
    {
      label: "TenderDetailsLayoutView_SecondaryHeaderLabel_Providers",
      route: "providers",
      isHidden:
        !isLoading ||
        !isOwnTender ||
        !isFeatureFlagEnabled("MATCHING_PROVIDERS"),
    },
  ];

  // We redirect back to `/tenders`, if tender cannot be found.
  if (!tender && !isLoading && !error) {
    return <Navigate replace to="/tenders" />;
  }
  if (!tenderId) return null;

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <SecondaryHeaderView
        tabs={tabs}
        pathPrefix={`/tenders/${tenderId}`}
        backButton={{
          to: "/tenders", // TODO: should redirect back to my-tenders or browse-tenders
          label: t("TenderDetailsLayoutView_Button_BackToTenders"),
        }}
      >
        {hasPermission("CAN_READ_LOGS") && (
          <LogsButtonView
            sx={{ position: "absolute", right: 0, mr: 4 }}
            collectionName={`tenders/${tenderId}/logs`}
            LogListItemView={LogListItemViewForTender}
            isT={isTenderLog}
          />
        )}
      </SecondaryHeaderView>
      {Boolean(sidebarWidth) && tender && (
        <TenderDetailsSidebarView tender={tender} />
      )}
      <Stack pl={`${sidebarWidth}px`} pt={3}>
        <FullHeightContainer>
          <TenderContextProvider
            value={{ tenderId, tender, isOwnTender, isLoading, error }}
          >
            <Outlet />
          </TenderContextProvider>
        </FullHeightContainer>
      </Stack>
    </>
  );
};
