import CheckIcon from "@mui/icons-material/Check";
import { Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { Cell, Pie, PieChart } from "recharts";
import { WIZARD_PROGRESS_SIZE } from "../consts/WIZARD_PROGRESS_SIZE";
import { useWizardStepProgress } from "../hooks/useWizardStepProgress";
import { WizardStep } from "../types/WizardStep";
import { WizardStore } from "../types/WizardStore";

interface Props<TState> {
  step: WizardStep<TState>;
  store: WizardStore<TState>;
}

export function WizardProgressView<TState>(props: Props<TState>) {
  const { step, store } = props;
  const { palette } = useTheme();
  const progress = useWizardStepProgress(step, store);
  const data = useMemo(() => {
    return [{ value: progress }, { value: 1 - progress }];
  }, [progress]);

  return (
    <Stack position="relative">
      <PieChart width={WIZARD_PROGRESS_SIZE} height={WIZARD_PROGRESS_SIZE}>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          stroke=""
          startAngle={90}
          endAngle={-270}
          isAnimationActive={false}
          innerRadius={WIZARD_PROGRESS_SIZE / 3.7}
          outerRadius={WIZARD_PROGRESS_SIZE / 2}
        >
          {data.map((_, index) => (
            <Cell
              key={index}
              fill={
                index === 0
                  ? progress === 1
                    ? palette.secondary.main
                    : palette.warning.light
                  : palette.grey[300]
              }
            />
          ))}
        </Pie>
      </PieChart>
      {progress === 1 && (
        <Stack
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          alignItems="center"
          justifyContent="center"
        >
          <CheckIcon
            sx={{ fontSize: WIZARD_PROGRESS_SIZE * 0.4 }}
            color="secondary"
          />
        </Stack>
      )}
    </Stack>
  );
}
