import { Alert, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { Fragment, ReactNode } from "react";
import { DEFAULT_COLLECTION_ROWS_PER_PAGE } from "../consts/DEFAULT_COLLECTION_ROWS_PER_PAGE";
import { usePaginatedCollection } from "../hooks/usePaginatedCollection";
import { CollectionViewProps } from "../types/CollectionViewProps";
import { CollectionEmptyView } from "./CollectionEmptyView";
import { FirebasePaginationView } from "./FirebasePaginationView";

type Props<T> = CollectionViewProps<
  T,
  {
    renderListItem: (item: T) => ReactNode;
  }
>;

export function CollectionListView<T>(props: Props<T>) {
  const rowsPerPage =
    props.rowsPerPageOptions?.[0] || DEFAULT_COLLECTION_ROWS_PER_PAGE;

  const { rows, isLoading, error, pagination } = usePaginatedCollection({
    ...props,
    rowsPerPage,
  });

  const isEmpty = !isLoading && !error && rows.length === 0;

  return (
    <Stack>
      {isLoading && !rows.length && (
        <Stack p={4} alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {error && <Alert severity="error">{error.message}</Alert>}
      {isEmpty && <CollectionEmptyView />}
      {rows.length > 0 && (
        <>
          <Stack sx={{ opacity: isLoading ? 0.3 : 1 }}>
            {rows.map((row) => (
              <Fragment key={String(row[props.keyField])}>
                {props.renderListItem(row)}
              </Fragment>
            ))}
          </Stack>
          <FirebasePaginationView
            p={3}
            justifyContent="flex-end"
            pagination={pagination}
            rowsPerPageOptions={props.rowsPerPageOptions}
          />
        </>
      )}
    </Stack>
  );
}
