import { Alert, Stack, Typography } from "@mui/material";
import { isMemberLog } from "@snubes/snubes-types";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LoadingView } from "../../Common/views/LoadingView";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { LogListItemViewForMember } from "../../Logs/views/LogListItemViewForMember";
import { LogsButtonView } from "../../Logs/views/LogsButtonView";
import { getUserName } from "../../Users/helpers/getUserName";
import { useMember } from "../hooks/useMember";
import { MemberEditForm } from "./MemberEditForm";

export const MemberPage: FC = () => {
  const { organizationId, userId } = useParams<"organizationId" | "userId">();
  const hasPermission = useHasPermission();
  const [member, isLoading, error] = useMember(organizationId, userId);

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer maxWidth="md">
        <Stack py={3} spacing={3}>
          {isLoading && <LoadingView />}
          {error && <Alert severity="error">{error.message}</Alert>}
          {member && (
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h2">{getUserName(member)}</Typography>
              {hasPermission("CAN_READ_LOGS") && organizationId && userId && (
                <LogsButtonView
                  collectionName={`organizations/${organizationId}/members/${userId}/logs`}
                  LogListItemView={LogListItemViewForMember}
                  isT={isMemberLog}
                />
              )}
            </Stack>
          )}
          {member && <MemberEditForm member={member} />}
        </Stack>
      </FullHeightContainer>
    </>
  );
};
