import { Paper, Stack } from "@mui/material";
import {
  CollectionGroupName,
  CollectionName,
  FirebaseFilter,
  isNotification,
  Notification,
} from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import { CollectionListView } from "../../Firebase/views/CollectionListView";
import { NotificationListItemSwitchView } from "./NotificationListItemSwitchView";

interface BaseProps {
  filters?: FirebaseFilter<Notification>[];
}

interface PropsWithOrganizationId extends BaseProps {
  organizationId: string;
  userId: string | null;
}

interface PropsWithUserId extends BaseProps {
  userId: string;
}

type Props = PropsWithOrganizationId | PropsWithUserId;

export const NotificationsCollectionListView: FC<Props> = (props) => {
  const collectionListViewProps = useMemo(() => {
    if ("organizationId" in props) {
      return {
        collectionName:
          `organizations/${props.organizationId}/notifications` as const satisfies CollectionName,
        filters: [
          ["userId", "==", props.userId] satisfies FirebaseFilter<Notification>,
          ...(props.filters || []),
        ],
      };
    } else {
      return {
        collectionGroupName:
          "notifications" as const satisfies CollectionGroupName,
        filters: [
          ["userId", "==", props.userId] satisfies FirebaseFilter<Notification>,
          ...(props.filters || []),
        ],
      };
    }
  }, [props]);

  return (
    <CollectionListView
      {...collectionListViewProps}
      orderBy={["createdAt", "desc"]}
      keyField="id"
      isT={isNotification}
      renderListItem={(notification) => (
        <Stack component={Paper} variant="outlined" mb={2}>
          <NotificationListItemSwitchView notification={notification} />
        </Stack>
      )}
    />
  );
};
