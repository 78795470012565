import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import PlaceIcon from "@mui/icons-material/Place";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import {
  ProviderOperationCenter,
  ProviderOperationCenterStruct,
} from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC, useCallback } from "react";
import { useFieldArray } from "react-hook-form";
import { array, optional, type } from "superstruct";
import { ControlledCountriesAutocomplete } from "../../Form/views/ControlledCountriesAutocomplete";
import { ControlledDatePicker } from "../../Form/views/ControlledDatePicker";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const DEFAULT_OPERATION_CENTER: Required<ProviderOperationCenter> = {
  location: {
    city: "",
    country: "",
  },
  employees: "" as unknown as number,
  startingYear: "" as unknown as number,
};

const FormValuesStruct = type({
  operationCenters: optional(array(ProviderOperationCenterStruct)),
});

export const ProviderWizardOperationCentersForm: FC<WizardFormProps> = (
  props,
) => {
  const t = useT();
  const { control } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      operationCenters: provider?.operationCenters?.length
        ? provider.operationCenters.map((center) => ({
            ...center,
            location: {
              ...center.location,
              city: center.location?.city || "",
              country: center.location?.country || "",
            },
            employees: center.employees || ("" as unknown as number),
            startingYear: center.startingYear || ("" as unknown as number),
          }))
        : [DEFAULT_OPERATION_CENTER],
    }),
    toDoc: (formValues) => {
      return {
        ...(formValues?.operationCenters && {
          operationCenters: formValues.operationCenters.map(
            (value): ProviderOperationCenter => ({
              ...((value.location?.city || value.location?.country) && {
                location: {
                  ...(value.location?.city && {
                    city: value.location.city,
                  }),
                  ...(value.location?.country && {
                    country: value.location.country,
                  }),
                },
              }),
              ...(typeof value.employees === "number" && {
                employees: value.employees,
              }),
              ...(typeof value.startingYear === "number" && {
                startingYear: value.startingYear,
              }),
            }),
          ),
        }),
      };
    },
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "operationCenters",
    rules: {
      minLength: 1,
    },
  });

  const onClickAddOperationCenter = useCallback(() => {
    append(DEFAULT_OPERATION_CENTER);
  }, [append]);

  return (
    <Container sx={{ pb: 3 }} maxWidth="md">
      <Typography variant="h1" mb={4}>
        {t("ProviderWizardOperationCentersForm_Title_OperationCenters")}
      </Typography>
      <Stack
        mb={4}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body2" color="text.500">
          {t("ProviderWizardOperationCentersForm_Paragraph_OperationCenters")}
        </Typography>
        <Button variant="contained" onClick={onClickAddOperationCenter}>
          {t("ProviderWizardOperationCentersForm_Button_AddOperationCenter")}
        </Button>
      </Stack>
      <Stack spacing={3}>
        {fields.map((center, index) => {
          return (
            <StyledAccordion
              key={center.id}
              disableGutters={true}
              defaultExpanded={true}
            >
              <AccordionSummary>
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" alignItems="flex-start" spacing={2}>
                    <ArrowForwardIosSharpIcon
                      className="expandIcon"
                      sx={{
                        fontSize: "inherit",
                        marginTop: "0.2em",
                      }}
                    />
                    <Stack>
                      <Typography
                        fontSize="inherit"
                        fontWeight="inherit"
                        color="inherit"
                        alignItems="center"
                      >
                        {t(
                          "ProviderWizardOperationCentersForm_Accordion_Heading",
                        )}
                      </Typography>

                      {center?.location?.city && (
                        <Typography
                          mt={2}
                          color="text.primary"
                          className="locationPreview"
                        >
                          {[center.location.city, center.location.country]
                            .filter(Boolean)
                            .join(", ")}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>

                  <Box>
                    <IconButton color="error" onClick={() => remove(index)}>
                      <DeleteForeverOutlinedIcon />
                    </IconButton>
                  </Box>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={3}>
                  <Divider />

                  <ControlledTextField
                    control={control}
                    name={`operationCenters.${index}.location.city`}
                    label={t("ProviderWizardOperationCentersForm_Label_City")}
                    startAdornment={
                      <InputAdornment position="start">
                        <PlaceIcon />
                      </InputAdornment>
                    }
                  />

                  <ControlledCountriesAutocomplete
                    control={control}
                    name={`operationCenters.${index}.location.country`}
                    label={t(
                      "ProviderWizardOperationCentersForm_Label_Country",
                    )}
                  />

                  <ControlledTextField
                    control={control}
                    type="number"
                    name={`operationCenters.${index}.employees`}
                    label={t(
                      "ProviderWizardOperationCentersForm_Label_NumberOfEmployees",
                    )}
                  />

                  <ControlledDatePicker
                    required
                    fullWidth
                    name={`operationCenters.${index}.startingYear`}
                    label={t(
                      "ProviderWizardOperationCentersForm_Label_StartingYear",
                    )}
                    control={control}
                    disableFuture
                    view="year"
                    autoFocus
                    minDate={DateTime.fromISO("1800-01-01")}
                  />
                </Stack>
              </AccordionDetails>
            </StyledAccordion>
          );
        })}
      </Stack>
    </Container>
  );
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  padding: 8,
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: "none",
  "& .MuiAccordionSummary-root": {
    minHeight: "initial",
  },
  "& .MuiAccordionSummary-content": {
    margin: "8px 0",
    "&:not(.Mui-expanded)": {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
    "&.Mui-expanded": {
      margin: 0,
      color: theme.palette.primary.main,
      fontWeight: "bold",
      fontSize: 14,
      "& .expandIcon": {
        transform: "rotate(90deg)",
      },
      "& .locationPreview": {
        display: "none",
      },
    },
  },
}));
