import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { Tender } from "@snubes/snubes-types";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { deleteTenderCallable } from "../callables/deleteTenderCallable";

interface Props {
  tender?: Tender;
  isOpen: boolean;
  close: () => void;
}

export const TenderDeleteDialog: FC<Props> = (props) => {
  const t = useT();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const deleteTender = useCallback(async () => {
    if (!props.tender) return;

    try {
      setIsSubmitting(true);

      await deleteTenderCallable({
        tenderId: props.tender.id,
      });
      props.close();
      toast.success(t("TenderDeleteDialog_Toast_SuccessMessage"));
    } catch (error) {
      handleError(error).logAnd().toast();
      setIsSubmitting(false);
    }
  }, [props, t]);

  return (
    <Dialog open={props.isOpen} onClose={props.close} fullWidth>
      <DialogTitle>{t("TenderDeleteDialog_Title_DeleteTender")}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          {t("TenderDeleteDialog_Content_DeleteTender")}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          disabled={isSubmitting}
          onClick={(event) => {
            event.stopPropagation();
            props.close();
          }}
        >
          {t("TenderDeleteDialog_Button_Cancel")}
        </Button>
        <Button
          color="error"
          variant="contained"
          disabled={isSubmitting}
          onClick={(event) => {
            event.stopPropagation();
            void deleteTender();
          }}
        >
          {t("TenderDeleteDialog_Button_Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
