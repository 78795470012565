import { Paper, Stack, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../Common/helpers/handleError";
import { createOrganizationCallable } from "../../Organizations/callables/createOrganizationCallable";
import { updateUserCallable } from "../../Users/callables/updateUserCallable";
import {
  OrganizationCreateForm,
  OrganizationCreateFormOnSubmitFormValues,
} from "../../Organizations/views/OrganizationCreateForm";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";

interface Props {
  nextHomeScreenPath: string;
}

export const OnboardingOrganizationCreateForm: FC<Props> = (props) => {
  const t = useT();
  const navigate = useNavigate();
  const userId = useMyUser((state) => state.user?.id);
  console.log("rendered OnboardingOrganizationCreateForm");
  console.log("nextHomeScreenPath: " + props.nextHomeScreenPath);

  const handleCreateOrganization = useCallback(
    async (formValues: OrganizationCreateFormOnSubmitFormValues) => {
      try {
        const result = await createOrganizationCallable({
          organization: {
            name: formValues.name,
            domain: formValues.domain,
            website: formValues.website,
            ...(typeof formValues.isHubspotEnabled !== "undefined" && {
              isHubspotEnabled: formValues.isHubspotEnabled,
              ...(formValues.hubspotCompanyId && {
                hubspotCompanyId: formValues.hubspotCompanyId,
              }),
            }),
          },
        });

        if (!userId) {
          throw new Error("userId is undefined");
        }

        await updateUserCallable({
          userId: userId,
          user: {
            selectedOrganizationId: result.organizationId,
          },
        });

        console.log("here is the organizationId:" + result.organizationId);
        console.log("navigating to /projects");
        navigate(props.nextHomeScreenPath ? props.nextHomeScreenPath : "/");
      } catch (error) {
        console.log(error);
        handleError(error).logAnd().toast();
      }
    },
    [navigate, props.nextHomeScreenPath],
  );

  return (
    <Stack p={5} spacing={4} component={Paper} variant="outlined">
      <Typography variant="h3">
        {t("OnboardingOrganizationCreateForm_Headline_NotFindingTeam")}
      </Typography>
      <Typography color="text.secondary" variant="subtitle2">
        {t("OnboardingOrganizationCreateForm_SubTitle_CreateOrganization")}
      </Typography>

      <OrganizationCreateForm onSubmit={handleCreateOrganization} />
    </Stack>
  );
};
