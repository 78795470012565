import {
  HEADER_HEIGHT,
  SECONDARY_HEADER_HEIGHT,
} from "../../Header/consts/HEADER_HEIGHT";
import { useHeaderConfig } from "../../Header/hooks/useHeaderConfig";

export function useHeaderHeight() {
  const hasSecondaryHeader = useHeaderConfig(
    (state) => state.hasSecondaryHeader,
  );
  const secondaryHeaderHeight = hasSecondaryHeader
    ? SECONDARY_HEADER_HEIGHT
    : 0;
  return HEADER_HEIGHT + secondaryHeaderHeight;
}
