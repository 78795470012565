import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CurrencyFrancIcon from "@mui/icons-material/CurrencyFranc";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import EuroIcon from "@mui/icons-material/Euro";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Currency } from "@snubes/snubes-types";

interface CurrencyRecordValue {
  label: string;
  Icon: OverridableComponent<SvgIconTypeMap>;
}

export const CURRENCY_RECORD: Record<Currency, CurrencyRecordValue> = {
  CHF: {
    label: "₣",
    Icon: CurrencyFrancIcon,
  },
  EUR: {
    label: "€",
    Icon: EuroIcon,
  },
  GBP: {
    label: "£",
    Icon: CurrencyPoundIcon,
  },
  USD: {
    label: "$",
    Icon: AttachMoneyIcon,
  },
};
