import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { FormControlProps, IconButton, InputAdornment } from "@mui/material";
import { NEW_PROJECT_ID_PLACEHOLDER, Project } from "@snubes/snubes-types";
import { useMemo } from "react";
import { Control, FieldPath, FieldValues, useWatch } from "react-hook-form";
import { useProjects } from "../../Projects/hooks/useProjects";
import { useT } from "../../Translation/hooks/useT";
import { ControlledAutocomplete } from "./ControlledAutocomplete";
import { ControlledTextField } from "./ControlledTextField";

// TODO: consider pagination for this component
const PROJECT_ROWS_PER_PAGE = 1000;

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues & Record<TName, string | null>>;
  name: TName;
  projectNameFieldName: TName;
  required?: boolean;
  label?: string;
  fullWidth?: boolean;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  size?: FormControlProps["size"];
  multiple?: boolean;
  organizationId: string;
  filter?: (project: Project) => boolean | undefined;
}

export function ControlledProjectIdAutocomplete<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const t = useT();

  const {
    rows: projects,
    isLoading,
    error,
  } = useProjects({
    organizationType: "PROVIDER",
    organizationId: props.organizationId,
    rowsPerPage: PROJECT_ROWS_PER_PAGE,
  });

  const rows: Project[] = useMemo(
    () => [
      {
        id: NEW_PROJECT_ID_PLACEHOLDER,
        name: t("ControlledProjectIdAutocomplete_Label_CreateNewProject"),
      } as Project,
      ...projects,
    ],
    [projects, t],
  );
  const filteredRows = props.filter ? rows.filter(props.filter) : rows;

  const selectedValue: string = useWatch({
    control: props.control,
    name: props.name,
    defaultValue: "" as TFieldValues[TName],
  });

  const isNewProjectSelected = selectedValue === NEW_PROJECT_ID_PLACEHOLDER;

  return (
    <>
      <ControlledAutocomplete
        control={props.control}
        name={props.name}
        label={props.label}
        options={filteredRows.map((p) => p.id)}
        multiple={props.multiple}
        getOptionLabel={(projectId) =>
          rows.find((project) => project.id === projectId)?.name || ""
        }
        required={props.required}
        disabled={props.disabled || isLoading}
        fullWidth={props.fullWidth}
        placeholder={props.placeholder}
        helperText={props.helperText}
        size={props.size}
        error={error}
        InputProps={
          selectedValue && !isNewProjectSelected
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      sx={{ color: "primary.main" }}
                      href={`/projects/${selectedValue}`}
                    >
                      <OpenInNewIcon color="primary" />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : undefined
        }
      />
      {isNewProjectSelected && (
        <ControlledTextField
          required
          autoComplete="off"
          fullWidth
          name={props.projectNameFieldName}
          label={t("ControlledProjectIdAutocomplete_Label_NewProjectName")}
          control={props.control}
          disabled={props.disabled || isLoading}
        />
      )}
    </>
  );
}
