import { TenderAgentType } from "@snubes/snubes-types/types/TenderAgentType";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface TenderAgentTypeRecordValue {
  label: TranslationKey;
}

export const TENDER_AGENT_TYPE_RECORD: Record<
  TenderAgentType,
  TenderAgentTypeRecordValue
> = {
  REGULAR: {
    label: "TenderAgentTypeRecord_Label_Regular",
  },
  TEAM_LEAD: {
    label: "TenderAgentTypeRecord_Label_TeamLead",
  },
};
