import { superstructResolver } from "@hookform/resolvers/superstruct";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import PostAddIcon from "@mui/icons-material/PostAdd";
import SendIcon from "@mui/icons-material/Send";
import {
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  message,
  StorageFile,
  StorageFileStruct,
  Tender,
  TenderApplicationStatus,
} from "@snubes/snubes-types";
import {
  FC,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { array, Infer, size, string, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { UploadState } from "../../Common/types/UploadState";
import { FileListView } from "../../Common/views/FileListView";
import { FileUploadView } from "../../Common/views/FileUploadView";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { createOrUpdateTenderApplicationCallable } from "../callables/createOrUpdateTenderApplicationCallable";
import { deleteTenderApplicationDocumentCallable } from "../callables/deleteTenderApplicationDocumentCallable";
import { getTenderApplicationAllowedTransitions } from "../helpers/getTenderApplicationAllowedTransitions";
import { mergeTenderApplicationDescription } from "../helpers/mergeTenderApplicationDescription";
import { splitTenderApplicationDescription } from "../helpers/splitTenderApplicationDescription";
import { useTenderStatus } from "../hooks/useTenderStatus";
import { TenderApplicationRejectDialog } from "./TenderApplicationRejectDialog";
import { TenderMyApplicationAlert } from "./TenderMyApplicationAlert";

interface Props {
  tender: Tender;
}

export const TenderMyApplicationForm: FC<Props> = (props) => {
  const { tender } = props;
  const t = useT();
  const selectedOrganizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );

  const tenderApplication = selectedOrganizationId
    ? tender?.providerApplications?.[selectedOrganizationId]
    : undefined;
  const tenderApplicationStatus = tenderApplication?.status;
  const tenderApplicationFiles = useMemo(
    () => Object.values(tenderApplication?.documents || {}),
    [tenderApplication?.documents],
  );
  const hasTenderEnded = useTenderStatus(tender) === "ENDED" ? true : false;

  const userId = useMyUser((state) => state.user?.id);
  const userType = useMyUser((state) => state.user?.userType || null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [uploadState, setUploadState] = useState<UploadState>();
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);

  const applicationAllowedTransitions = getTenderApplicationAllowedTransitions({
    userType,
    tender,
    tenderApplication,
  });

  const FormValuesStruct = useMemo(() => {
    const minDescriptionLength = 10;
    return type({
      description: message(
        size(string(), minDescriptionLength, Infinity),
        t("TenderMyApplicationPage_Error_Description", {
          count: minDescriptionLength,
        }),
      ),
      priceOffer: message(
        size(string(), 1, Infinity),
        t("TenderMyApplicationPage_Error_PriceOffer"),
      ),
      documents: message(
        size(array(StorageFileStruct), 1, Infinity),
        t("TenderMyApplicationPage_Error_Documents"),
      ),
    });
  }, [t]);

  type FormValues = Infer<typeof FormValuesStruct>;

  const splitDescription =
    tenderApplication && splitTenderApplicationDescription(tenderApplication);

  const {
    control,
    handleSubmit,
    getValues: getFormValues,
    setValue: setFormValue,
    reset: resetForm,
    formState: {
      errors: formErrors,
      isDirty: isFormDirty,
      isSubmitting: isFormSubmitting,
    },
  } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      description: splitDescription?.description || "",
      priceOffer: splitDescription?.priceOffer || "",
      documents: tenderApplicationFiles,
    },
  });

  const formRef = useRef<HTMLFormElement>(null);
  const isSubmitting = isUpdating || isFormSubmitting;
  const showEditTenderApplication =
    (tenderApplicationStatus === "DRAFT" ||
      tenderApplicationStatus === "PENDING") &&
    !hasTenderEnded;

  useEffect(() => {
    setFormValue("documents", tenderApplicationFiles);
  }, [setFormValue, tenderApplicationFiles]);

  const onSubmitForm = async (formValues: FormValues) => {
    if (!tender?.id || !selectedOrganizationId || isSubmitting) {
      return;
    }
    try {
      await createOrUpdateTenderApplicationCallable({
        tenderId: tender.id,
        providerId: selectedOrganizationId,
        tenderApplication: {
          status: "PUBLISHED",
          description: mergeTenderApplicationDescription(
            formValues.description,
            formValues.priceOffer,
          ),
        },
      });
    } catch (error) {
      handleError(error).logAnd().toast();
    }
    resetForm(formValues);
  };

  const onFormBlur = async (e: FocusEvent<HTMLFormElement, Element>) => {
    if (
      !tender?.id ||
      !selectedOrganizationId ||
      !isFormDirty ||
      isSubmitting
    ) {
      return;
    }

    // If the submit button was clicked, we want to submit the form
    if (e.relatedTarget?.getAttribute("type") === "submit") {
      formRef.current?.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true }),
      );
      return;
    }

    setIsUpdating(true);
    const formValues = getFormValues();
    try {
      await createOrUpdateTenderApplicationCallable({
        tenderId: tender.id,
        providerId: selectedOrganizationId,
        tenderApplication: {
          description: mergeTenderApplicationDescription(
            formValues.description,
            formValues.priceOffer,
          ),
        },
      });
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsUpdating(false);
    }
  };

  const handleChangeApplicationStatus = async (
    status: TenderApplicationStatus,
  ) => {
    if (!tender?.id || !selectedOrganizationId) {
      return;
    }
    try {
      setIsUpdating(true);
      await createOrUpdateTenderApplicationCallable({
        tenderId: tender.id,
        providerId: selectedOrganizationId,
        tenderApplication: {
          status,
        },
      });
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDeleteDocument = async (doc: StorageFile) => {
    if (!tender?.id || !selectedOrganizationId) {
      return;
    }
    try {
      setIsUpdating(true);
      await deleteTenderApplicationDocumentCallable({
        tenderId: tender.id,
        providerId: selectedOrganizationId,
        fileHash: doc.fileHash,
      });
      toast.success(
        t("TenderMyApplicationPage_Toast_DeletedFile", { name: doc.fileName }),
      );
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsUpdating(false);
    }
  };

  const onUploadStateChanged = useCallback((state: UploadState) => {
    if (state.status === "started" || state.status === "running") {
      setUploadState(state);
    } else {
      setUploadState(undefined);
    }
  }, []);

  return (
    <>
      <Stack
        ref={formRef}
        spacing={5}
        sx={{ pb: 4 }}
        component="form"
        onBlur={onFormBlur}
        onSubmit={handleSubmit(onSubmitForm)}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3">
            {t("TenderMyApplicationPage_Title_MyApplication")}
          </Typography>

          {selectedOrganizationId && userType && tenderApplication && (
            <Stack direction="row" spacing={3}>
              {applicationAllowedTransitions.REJECT_BY_PROVIDER && (
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => setIsRejectDialogOpen(true)}
                  startIcon={<ContentPasteOffIcon />}
                  sx={{ minWidth: 130 }}
                  disabled={isSubmitting || hasTenderEnded}
                >
                  {t("Common_Reject")}
                </Button>
              )}
              {(applicationAllowedTransitions.EDIT ||
                applicationAllowedTransitions.PUBLISH) && (
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={!isSubmitting && <SendIcon />}
                  disabled={isSubmitting || hasTenderEnded}
                  sx={{
                    minWidth: 210,
                  }}
                >
                  {!isSubmitting &&
                    t("TenderMyApplicationPage_Button_SubmitApplication")}
                  {isSubmitting && (
                    <CircularProgress color="inherit" size={20} />
                  )}
                </Button>
              )}
              {applicationAllowedTransitions.WITHDRAW && (
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<CancelOutlinedIcon />}
                  onClick={() => handleChangeApplicationStatus("DRAFT")}
                  disabled={isSubmitting || hasTenderEnded}
                >
                  {t("TenderMyApplicationPage_Button_WithdrawApplication")}
                </Button>
              )}
              {applicationAllowedTransitions.REAPPLY && (
                <Button
                  variant="contained"
                  endIcon={<PostAddIcon />}
                  onClick={() => handleChangeApplicationStatus("DRAFT")}
                  disabled={isSubmitting}
                >
                  {t("TenderMyApplicationPage_Button_Reapply")}
                </Button>
              )}
            </Stack>
          )}
        </Stack>

        {tenderApplicationStatus && (
          <Stack spacing={3}>
            {tenderApplication && (
              <>
                <TenderMyApplicationAlert
                  status={hasTenderEnded ? "ENDED" : tenderApplication.status}
                  rejectedByUserType={tenderApplication.rejectedByUserType}
                  rejectionReason={tenderApplication.rejectionReason}
                />
                <Divider />
              </>
            )}

            {!showEditTenderApplication && splitDescription && (
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  {t("TenderMyApplicationPage_Title_Description")}
                </Typography>
                <Typography variant="body1" whiteSpace="pre-line">
                  {splitDescription.description +
                    "\n\n" +
                    splitDescription.priceOffer}
                </Typography>
              </Paper>
            )}
          </Stack>
        )}

        {showEditTenderApplication && (
          <Stack spacing={3}>
            <Typography variant="subtitle1">
              {t("TenderMyApplicationPage_Title_Description")} <sup>*</sup>
            </Typography>
            <ControlledTextField
              required
              fullWidth
              name="description"
              placeholder={t("TenderMyApplicationPage_Placeholder_Description")}
              inputSx={{
                backgroundColor: "background.paper",
              }}
              control={control}
              minRows={3}
              multiline
            />

            <Typography variant="subtitle1">
              {t("TenderMyApplicationPage_Title_PriceOffer")} <sup>*</sup>
            </Typography>
            <ControlledTextField
              required
              fullWidth
              name="priceOffer"
              placeholder={t("TenderMyApplicationPage_Placeholder_PriceOffer")}
              inputSx={{ backgroundColor: "background.paper" }}
              control={control}
            />
          </Stack>
        )}

        {showEditTenderApplication &&
          userId &&
          tender.id &&
          selectedOrganizationId && (
            <Stack spacing={3}>
              <Typography variant="subtitle1">
                {t("TenderMyApplicationPage_Title_UploadProposalDocument")}{" "}
                <sup>*</sup>
              </Typography>
              <FileUploadView
                error={!!formErrors.documents}
                onUploadStateChanged={onUploadStateChanged}
                storagePath={`/user-uploads/tender-applications/${tender.id}/${selectedOrganizationId}`}
                storageFileMetadata={{
                  userId,
                  tenderId: tender.id,
                  providerId: selectedOrganizationId,
                }}
                allowMultiple
                acceptedFileTypes={{
                  "application/pdf": [".pdf"],
                }}
              />
              {formErrors.documents && (
                <FormHelperText error>
                  {formErrors.documents.message}
                </FormHelperText>
              )}
            </Stack>
          )}

        {(tenderApplicationFiles.length > 0 || uploadState) && (
          <Paper
            elevation={0}
            sx={{
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "divider",
            }}
          >
            <FileListView
              uploadState={uploadState}
              files={tenderApplicationFiles}
              onClickDeleteFile={handleDeleteDocument}
              showDeleteAction={showEditTenderApplication}
              disabled={isSubmitting}
            />
          </Paper>
        )}
      </Stack>
      {selectedOrganizationId && isRejectDialogOpen && (
        <TenderApplicationRejectDialog
          tenderId={tender.id}
          providerId={selectedOrganizationId}
          close={() => setIsRejectDialogOpen(false)}
        />
      )}
    </>
  );
};
