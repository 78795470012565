import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useTenderContext } from "../hooks/useTenderContext";

export const TenderIndexPage: FC = () => {
  const { tender, isLoading, error } = useTenderContext();

  if (tender && tender.status === "DRAFT") {
    return <Navigate replace to="next-step" />;
  }

  if (tender) {
    return <Navigate replace to="details" />;
  }

  // We redirect back to `/tenders`, if tender cannot be found.
  if (!tender && !isLoading && !error) {
    return <Navigate replace to="/tenders" />;
  }

  return null;
};
