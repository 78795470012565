import InfoIcon from "@mui/icons-material/Info";
import { Button, Stack, Typography } from "@mui/material";
import { FC } from "react";

interface Props {
  label: string;
  title: string;
  subTitle: string;
  button?: {
    href: string;
    label: string;
    disabled?: boolean;
  };
}

export const NoDataView: FC<Props> = (props) => {
  const { label, title, subTitle, button } = props;
  return (
    <Stack spacing={4} alignItems="center" justifyContent="center" flex={1}>
      <InfoIcon sx={{ fontSize: 48, color: "text.secondary" }} />
      <Typography variant="h5" color="warning.main">
        {label}
      </Typography>
      <Typography variant="h2" textAlign="center">
        {title}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" textAlign="center">
        {subTitle}
      </Typography>

      {button && (
        <Button
          variant="contained"
          sx={{ minWidth: 320 }}
          href={button.href}
          disabled={button.disabled}
        >
          {button.label}
        </Button>
      )}
    </Stack>
  );
};
