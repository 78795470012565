import { Unstable_Grid2 as Grid } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { MaxWidthContainer } from "../../Common/views/MaxWidthContainer";

export const HomeGetStartedView: FC<PropsWithChildren> = (props) => {
  return (
    <MaxWidthContainer px={5} py={6}>
      <Grid container spacing={5}>
        {props.children}
      </Grid>
    </MaxWidthContainer>
  );
};
