import { useEffect, useRef } from "react";
import type { RefHook } from "react-firebase-hooks/firestore/dist/util";

/**
 * TODO: Remove once https://github.com/CSFrequency/react-firebase-hooks/pull/281/files is merged.
 */
export const useComparatorRefs = <T>(
  values: T[] | null | undefined,
  isEqual: (v1: T | null | undefined, v2: T | null | undefined) => boolean,
  onChange?: () => void,
): RefHook<T[] | null | undefined> => {
  const ref = useRef(values);
  useEffect(() => {
    if (!values) {
      if (onChange && ref.current?.length) {
        onChange();
        return;
      }

      return;
    }

    if (values.length !== ref.current?.length) {
      ref.current = values;
      if (onChange) {
        onChange();
        return;
      }
    }
    for (let i = 0; i < values.length; i++) {
      if (!isEqual(values[i], ref.current[i])) {
        ref.current = values;
        if (onChange) {
          onChange();
          return;
        }
      }
    }
  });

  return ref;
};
