import { Box, CircularProgress } from "@mui/material";
import { FC } from "react";
import { HEADER_HEIGHT } from "../../Header/consts/HEADER_HEIGHT";

export const LoadingView: FC = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={`calc(100% - ${HEADER_HEIGHT}px)`}
    >
      <CircularProgress />
    </Box>
  );
};
