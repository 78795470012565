import { isUser } from "@snubes/snubes-types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useUser(userId?: string) {
  return useValidDocumentData(
    userId ? getDocRef("users", userId) : null,
    isUser,
  );
}
