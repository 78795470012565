import {
  Badge,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import {
  SERVICE_CATEGORIES,
  ServiceCategory,
  ServiceType,
} from "@snubes/snubes-types";
import { useMemo, useState } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { toggleArray } from "../../Common/helpers/toggleArray";
import { TENDER_SERVICES_BY_CATEGORY } from "../../Tenders/consts/TENDER_SERVICE_CATEGORIES";
import { getServiceTypesByCategory } from "../../Tenders/helpers/getServiceTypesByCategory";
import { useT } from "../../Translation/hooks/useT";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues & Record<TName, ServiceType[]>>;
  name: TName;
  required?: boolean;
  disabled?: boolean;
}

export function ControlledServiceTypesField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const t = useT();
  const [serviceCategory, setServiceCategory] =
    useState<ServiceCategory>("INBOUND");
  const serviceTypes = useMemo(
    () =>
      TENDER_SERVICES_BY_CATEGORY.get(serviceCategory)
        ?.map((type) => ({
          label: t(`ServiceType_${type}`),
          value: type,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [serviceCategory, t],
  );

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field, fieldState }) => {
        const currentValues: ServiceType[] = field.value;
        const selectedServicesByCategory =
          getServiceTypesByCategory(currentValues);

        return (
          <Stack>
            <Tabs
              value={serviceCategory}
              onChange={(_, nextValue: ServiceCategory) =>
                setServiceCategory(nextValue)
              }
            >
              {SERVICE_CATEGORIES.map((category) => (
                <Tab
                  key={category}
                  value={category}
                  label={
                    <Badge
                      badgeContent={
                        selectedServicesByCategory[category]?.length || 0
                      }
                      color="primary"
                      sx={{ ".MuiBadge-badge": { right: -5 } }}
                    >
                      {t(`ServiceCategory_${category}`)}
                    </Badge>
                  }
                />
              ))}
            </Tabs>
            <Grid container spacing={2} mt={3}>
              {serviceTypes?.map((serviceType) => (
                <Grid key={serviceType.value} item xs={12} md={6} lg={4}>
                  <FormControlLabel
                    label={serviceType.label}
                    control={
                      <Checkbox
                        checked={currentValues.includes(serviceType.value)}
                        onChange={() =>
                          field.onChange(
                            toggleArray(serviceType.value, currentValues),
                          )
                        }
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
            {fieldState.error && (
              <FormHelperText error>
                {t("ControlledServiceTypesField_ErrorMessage")}
              </FormHelperText>
            )}
          </Stack>
        );
      }}
    />
  );
}
