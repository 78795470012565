import { Project } from "@snubes/snubes-types";
import { FC } from "react";
import { StatusColor } from "../../Common/types/StatusColor";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface Props {
  project: Project;
}

interface StatusRecordValue {
  color: StatusColor;
  label: TranslationKey;
}

type Status = "ACTIVE" | "NO_DATA";

const STATUS_RECORD: Record<Status, StatusRecordValue> = {
  ACTIVE: {
    color: "secondary",
    label: "ProjectReportFramesStatusChip_Label_Active",
  },
  NO_DATA: {
    color: "default",
    label: "ProjectReportFramesStatusChip_Label_NoData",
  },
};

export const ProjectReportFramesStatusChip: FC<Props> = (props) => {
  const { project } = props;
  const t = useT();
  const status: Status = project.reportFramesByDay?.length
    ? "ACTIVE"
    : "NO_DATA";
  const { color, label } = STATUS_RECORD[status];

  return <StatusChip color={color} label={t(label)} />;
};
