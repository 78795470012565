import { Tender } from "@snubes/snubes-types";
import { Channel } from "@snubes/snubes-types/types/Channel";
import { useCallback } from "react";
import { toLocaleWeekdayPeriod } from "../../Common/helpers/toLocaleWeekdayPeriod";
import { useT } from "../../Translation/hooks/useT";

export function useGetTenderOperatingHoursValue() {
  const t = useT();

  return useCallback(
    (tender?: Partial<Tender>, channel?: Channel) => {
      if (channel?.operatingHoursType === "DEFAULT" || !channel) {
        if (tender?.isOperatingAroundTheClock) {
          return t("useGetTenderOperatingHoursValue_AroundTheClock");
        }

        if (tender?.operatingHours) {
          return tender.operatingHours.map(toLocaleWeekdayPeriod).join("\n");
        }
      }

      if (channel?.operatingHoursType === "AROUND_THE_CLOCK") {
        return t("useGetTenderOperatingHoursValue_AroundTheClock");
      }

      if (channel?.operatingHoursType === "CUSTOM" && channel.operatingHours) {
        return channel.operatingHours.map(toLocaleWeekdayPeriod).join("\n");
      }

      return "-";
    },
    [t],
  );
}
