import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LoadingView } from "../../Common/views/LoadingView";
import { MaxWidthContainer } from "../../Common/views/MaxWidthContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useMyInvitations } from "../../Invitations/hooks/useMyInvitations";
import { useT } from "../../Translation/hooks/useT";
import { USER_TYPE_RECORD } from "../../Users/consts/USER_TYPE_RECORD";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { OnboardingInvitationsView } from "./OnboardingInvitationsView";
import { OnboardingOrganizationCreateForm } from "./OnboardingOrganizationCreateForm";
import { OnboardingUserTypeView } from "./OnboardingUserTypeView";

export const OnboardingOrganizationPage: FC = () => {
  const t = useT();
  const [invitations, isLoading, error] = useMyInvitations();
  const userType = useMyUser((state) => state.user?.userType);

  const nextHomeScreenPath = userType
    ? USER_TYPE_RECORD[userType].homeScreenPath
    : "/projects";

  const [areInvitationsVisible, setAreInvitationsVisible] = useState(false);

  useEffect(() => {
    setAreInvitationsVisible(!!invitations.length);
  }, [invitations]);

  const isLoaded = !isLoading && !error;

  return (
    <>
      <HeaderConfig variant="WITH_CENTERED_LOGO" />
      {isLoading && (
        <FullHeightContainer maxWidth="sm">
          <LoadingView />
        </FullHeightContainer>
      )}
      {error && (
        <FullHeightContainer maxWidth="sm">
          <Alert sx={{ m: 3 }} severity="error">
            {error.message}
          </Alert>
        </FullHeightContainer>
      )}
      {!userType && isLoaded && (
        <MaxWidthContainer>
          <OnboardingUserTypeView />
        </MaxWidthContainer>
      )}
      {areInvitationsVisible && isLoaded && (
        <FullHeightContainer maxWidth="sm">
          <OnboardingInvitationsView
            hideInvitations={setAreInvitationsVisible}
            invitations={invitations}
          />
        </FullHeightContainer>
      )}
      {userType && !areInvitationsVisible && isLoaded && (
        <FullHeightContainer maxWidth="sm">
          <Stack textAlign="center" py={5} spacing={3}>
            {!!invitations.length && (
              <Button
                variant="regular"
                startIcon={<ArrowBackIcon />}
                onClick={() => setAreInvitationsVisible(true)}
                sx={{
                  textTransform: "uppercase",
                  color: "primary.main",
                  fontWeight: 500,
                }}
              >
                {t("OnboardingOrganizationPage_Button_BackToInvitations")}
              </Button>
            )}
            <Typography variant="h2">
              {t("OnboardingOrganizationPage_Headline_CreateOrganization")}
            </Typography>
            {!!invitations.length && (
              <Typography pb={3} color="text.500" variant="subtitle1">
                {t("OnboardingOrganizationPage_Subtitle_InvitedToOrganization")}
              </Typography>
            )}
            <OnboardingOrganizationCreateForm
              nextHomeScreenPath={nextHomeScreenPath}
            />
          </Stack>
        </FullHeightContainer>
      )}
    </>
  );
};
