import {
  DocumentData,
  FirestoreError,
  Query,
  QuerySnapshot,
} from "firebase/firestore";
import { useMemo } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { getDataFromCollectionSnapshot } from "../../Firebase/helpers/getDataFromQuerySnapshot";

export function useValidCollectionData<T>(
  query: Query<DocumentData> | null,
  isT: (obj: unknown) => obj is T,
  compareFn?: (a: T, b: T) => number,
): [
  docs: T[],
  isLoading: boolean,
  error: FirestoreError | undefined,
  snapshot: QuerySnapshot<DocumentData> | undefined,
] {
  const [snapshot, isLoading, error] = useCollection(query);

  const docs = useMemo(() => {
    const items = getDataFromCollectionSnapshot(isT, snapshot);

    return compareFn ? items.sort(compareFn) : items;
  }, [compareFn, isT, snapshot]);

  return [docs, isLoading, error, snapshot];
}
