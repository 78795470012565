import { SxProps } from "@mui/material";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { CheckboxButtons, CheckboxOption } from "./CheckboxButtons";

interface Props<TFields extends FieldValues, TData extends CheckboxOption> {
  control: Control<TFields>;
  name: FieldPath<TFields>;
  label?: string;
  options: TData[];
  gridItemXs?: number;
  gridItemSm?: number;
  gridItemMd?: number;
  gridItemLg?: number;
  gridItemXl?: number;
  gridSpacing?: number;
  sx?: SxProps;
  disabled?: boolean;
  required?: boolean;
}

export function ControlledCheckboxButtons<
  TFields extends FieldValues,
  TData extends CheckboxOption,
>(props: Props<TFields, TData>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field, fieldState }) => (
        <CheckboxButtons
          label={props.label}
          options={props.options}
          disabled={props.disabled}
          error={!!fieldState.error}
          value={field.value}
          onChange={field.onChange}
          gridItemXs={props.gridItemXs}
          gridItemSm={props.gridItemSm}
          gridItemMd={props.gridItemMd}
          gridItemLg={props.gridItemLg}
          gridItemXl={props.gridItemXl}
          gridSpacing={props.gridSpacing}
          sx={props.sx}
        />
      )}
    />
  );
}
