import { Tooltip } from "@mui/material";
import { Tender } from "@snubes/snubes-types";
import { FC } from "react";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  tender: Tender;
}

export const TenderMatchedProvidersStatusChipView: FC<Props> = (props) => {
  const { tender } = props;
  const t = useT();

  const count = Object.keys(tender.providerApplications || {}).length;
  return (
    <Tooltip
      title={t("TenderMatchedProvidersStatusChipView_Tooltip_Providers", {
        count,
      })}
    >
      <StatusChip color={count ? "primary" : "default"} label={count} />
    </Tooltip>
  );
};
