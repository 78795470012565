import { Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC } from "react";
import { useT } from "../../Translation/hooks/useT";
import { InvitationsCollectionTableView } from "./InvitationsCollectionTableView";

interface Props {
  organizationId: string;
}

export const InvitationsView: FC<Props> = (props) => {
  const t = useT();
  return (
    <Stack spacing={4}>
      <Typography variant="h4">
        {t("InvitationsView_Heading_PendingInvitation")}
      </Typography>
      <Paper>
        <InvitationsCollectionTableView
          filters={[["organizationId", "==", props.organizationId]]}
        />
      </Paper>
    </Stack>
  );
};
