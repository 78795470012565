import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import PeopleIcon from "@mui/icons-material/People";
import PowerOutlinedIcon from "@mui/icons-material/PowerOutlined";
import AdminIcon from "@mui/icons-material/SecurityOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import { useMemo } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { useT } from "../../Translation/hooks/useT";
import { AppNavigationItem } from "../types/AppNavigationItem";

export const useAppNavigationItems = () => {
  const t = useT();
  const hasPermission = useHasPermission();

  return useMemo<AppNavigationItem[]>(
    () => [
      {
        IconComponent: HomeOutlinedIcon,
        title: t("AppSidebarView_NavLink_Home"),
        to: "/home",
      },
      {
        IconComponent: LayersOutlinedIcon,
        title: t("AppSidebarView_NavLink_Tenders"),
        to: "/tenders",
      },
      {
        IconComponent: WorkOutlineIcon,
        title: t("AppSidebarView_NavLink_Projects"),
        to: "/projects",
      },
      {
        IconComponent: HelpOutlineIcon,
        title: t("AppSidebarView_NavLink_HelpCenter"),
        to: "/help-center",
      },
      {
        IconComponent: AdminIcon,
        title: t("AppSidebarView_NavLink_Admin"),
        isHidden: !hasPermission("CAN_SEE_ADMIN_PANEL"),
        items: [
          {
            IconComponent: PeopleIcon,
            title: t("AppSidebarView_NavLink_Users"),
            to: "/admin/users",
          },
          {
            IconComponent: GroupsIcon,
            title: t("AppSidebarView_NavLink_Organizations"),
            to: "/admin/organizations",
          },
          {
            IconComponent: LayersOutlinedIcon,
            title: t("AppSidebarView_NavLink_Tenders"),
            to: "/admin/tenders",
          },
          {
            IconComponent: ContactPageOutlinedIcon,
            title: t("AppSidebarView_NavLink_Providers"),
            to: "/admin/providers",
          },
          {
            IconComponent: WorkOutlineIcon,
            title: t("AppSidebarView_NavLink_Projects"),
            to: "/admin/projects",
          },
          {
            IconComponent: PowerOutlinedIcon,
            title: t("AppSidebarView_NavLink_ConnectorConfigs"),
            to: "/admin/connector-configs",
          },
          {
            IconComponent: FlagOutlinedIcon,
            title: t("AppSidebarView_NavLink_FeatureFlags"),
            to: "/admin/feature-flags",
          },
        ],
      },
    ],
    [t, hasPermission],
  );
};
