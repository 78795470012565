import {
  FilterState,
  FirebaseTableFilterOfType,
  FirebaseTableFilterType,
} from "../hooks/useFirebaseTableFilters";
import { isFirebaseTableFilterType } from "./isFirebaseTableFilterType";

export function selectFirebaseTableFilter<T extends FirebaseTableFilterType>(
  filterType: T,
) {
  return <U>(
    filterName: string,
    selector: (filter: FirebaseTableFilterOfType<T>) => U,
  ) => {
    return (state: FilterState) => {
      const filter = state.filters[filterName];
      if (!isFirebaseTableFilterType(filter, filterType)) {
        throw new Error(
          `Need filter ${filterName} of type ${filterType} but got ${filter.type}`,
        );
      }
      return selector(filter);
    };
  };
}
