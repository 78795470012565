import { FC, PropsWithChildren } from "react";
import { useMembers } from "../../Members/hooks/useMembers";
import { useOrganization } from "../../Organizations/hooks/useOrganization";
import { useT } from "../../Translation/hooks/useT";
import { HomeCardActionButton } from "./HomeCardActionButton";
import { HomeCardCountView } from "./HomeCardCountView";
import { HomeCardView } from "./HomeCardView";

interface Props extends PropsWithChildren {
  organizationId?: string;
}

export const HomeCardOrganizationView: FC<Props> = (props) => {
  const { organizationId } = props;

  const t = useT();
  const [organization] = useOrganization(organizationId);
  const [members] = useMembers(organizationId);
  const isProvider = organization?.types?.includes("PROVIDER");

  return (
    <HomeCardView
      title={organization?.name}
      actions={
        isProvider ? (
          <HomeCardActionButton
            title={t("HomeCardOrganizationView_Button_EditProviderProfile")}
            href={`/providers/${organizationId}/create`}
          />
        ) : (
          <HomeCardActionButton
            title={t("HomeCardOrganizationView_Button_EditOrganizationProfile")}
            href={`/organizations/${organizationId}/details`}
          />
        )
      }
    >
      <HomeCardCountView count={members.length} />{" "}
      {t("HomeCardOrganizationView_Label_Members")}
    </HomeCardView>
  );
};
