import { ServiceCategory } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface TenderServiceCategoryRecordValue {
  label: TranslationKey;
}

export const TENDER_SERVICE_CATEGORY_RECORD: Readonly<
  Record<ServiceCategory, TenderServiceCategoryRecordValue>
> = {
  INBOUND: {
    label: `ServiceCategory_INBOUND`,
  },
  OUTBOUND: {
    label: `ServiceCategory_OUTBOUND`,
  },
  BACK_OFFICE_SUPPORT: {
    label: `ServiceCategory_BACK_OFFICE_SUPPORT`,
  },
};
