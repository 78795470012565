import { Call, CallTranscriptSource } from "@snubes/snubes-types";
import { getCallTranscripts } from "./getCallTranscripts";

/**
 * Returns true if the call has any transcript items with a sentiment value other than 0.
 */
export function hasSentiments(
  call: Call,
  source?: CallTranscriptSource,
): boolean {
  const transcripts = getCallTranscripts(call, source);
  return !!transcripts?.some((t) => t.sentiment !== 0);
}
