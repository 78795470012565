import { Stack, Typography } from "@mui/material";
import { Project } from "@snubes/snubes-types";
import { FC } from "react";
import { ProjectController } from "../hooks/useProjectController";
import { ProjectControllerView } from "./ProjectControllerView";

interface Props {
  project: Project;
  projectController: ProjectController;
}

export const ProjectDetailsPageHeaderView: FC<Props> = (props) => {
  const { project, projectController } = props;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h3">{project.name}</Typography>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        {project.connectorConfigId && (
          <ProjectControllerView projectController={projectController} />
        )}
      </Stack>
    </Stack>
  );
};
