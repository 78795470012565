import { superstructResolver } from "@hookform/resolvers/superstruct";
import AddCommentIcon from "@mui/icons-material/AddComment";
import CommentsDisabledIcon from "@mui/icons-material/CommentsDisabled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Call, CallScorecard } from "@snubes/snubes-types";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  array,
  boolean,
  Infer,
  nullable,
  number,
  string,
  type,
} from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { AvatarView } from "../../Common/views/AvatarView";
import { ControlledScorecardRadioButtons } from "../../Form/views/ControlledScorecardRadioButtons";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { createCallScorecardCallable } from "../callables/createCallScorecardCallable";
import { CallProjectScorecardDialog } from "./CallProjectScorecardDialog";

const FormValuesStruct = type({
  scorecardItems: array(
    type({
      question: string(),
      score: nullable(number()),
      remark: string(),
      isOpen: boolean(),
    }),
  ),
});

const ResolverStruct = type({
  scorecardItems: array(
    type({
      question: string(),
      score: number(),
      remark: string(),
      isOpen: boolean(),
    }),
  ),
});

type FormValues = Infer<typeof FormValuesStruct>;

interface Props {
  call: Call;
  scorecardQuestions: string[];
}

export const CallProjectScorecardView: FC<Props> = (props) => {
  const { call, scorecardQuestions } = props;
  const t = useT();
  const scorecards = call?.scorecards ? Object.values(call.scorecards) : [];
  const [isCollapsed, setIsCollapsed] = useState(
    scorecardQuestions.length > COLLAPSED_COUNT,
  );
  const [selectedScorecard, setSelectedScorecard] = useState<
    CallScorecard | undefined
  >();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
    reset,
  } = useForm<FormValues>({
    resolver: superstructResolver(ResolverStruct),
    defaultValues: {
      scorecardItems: scorecardQuestions.map((question) => {
        return { question, score: null, remark: "", isOpen: false };
      }),
    },
  });

  const { fields, update } = useFieldArray({
    control,
    name: "scorecardItems",
  });

  const scorecardItems = watch("scorecardItems");
  const hasItemWithScore = !!scorecardItems.find((i) => i.score !== null);

  useEffect(() => {
    if (hasItemWithScore) {
      setIsCollapsed(false);
    }
  }, [hasItemWithScore]);

  const onClickToggleRemark = useCallback(
    (index: number) => {
      update(index, {
        ...scorecardItems[index],
        isOpen: !scorecardItems[index].isOpen,
      });
    },
    [update, scorecardItems],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        await createCallScorecardCallable({
          callId: call.id,
          callScorecard: {
            items: formValues.scorecardItems.map((item) => ({
              question: item.question,
              maxScore: 10,
              score: item.score === null ? 0 : item.score,
              ...(item.remark && item.isOpen && { remark: item.remark }),
            })),
          },
        });
        reset();
        toast.success(t("CallProjectScorecardView_Toast_Success"));
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [call.id, reset, t],
  );

  const collapsedFields = isCollapsed
    ? fields.slice(0, COLLAPSED_COUNT)
    : fields;

  return (
    <>
      <Paper
        component="form"
        variant="outlined"
        onSubmit={handleSubmit(onSubmit)}
      >
        {collapsedFields.map((field, index) => (
          <Fragment key={field.id}>
            <Stack p={3} direction="row" spacing={2}>
              <Stack spacing={3} flex={1}>
                <ControlledScorecardRadioButtons
                  control={control}
                  label={`Q${index + 1}: ${field.question}`}
                  required
                  name={`scorecardItems.${index}.score`}
                />
                {field.isOpen && (
                  <ControlledTextField
                    fullWidth
                    name={`scorecardItems.${index}.remark`}
                    label={t("CallProjectScorecardView_Label_Remark")}
                    control={control}
                    disabled={isSubmitting}
                    multiline
                  />
                )}
              </Stack>
              <Stack pt="36px">
                <IconButton
                  size="large"
                  onClick={() => {
                    onClickToggleRemark(index);
                  }}
                >
                  {field.isOpen ? <CommentsDisabledIcon /> : <AddCommentIcon />}
                </IconButton>
              </Stack>
            </Stack>
            <Divider />
          </Fragment>
        ))}
        <Stack p={3} spacing={2} direction="row" justifyContent="flex-end">
          {isCollapsed && (
            <Button
              type="submit"
              size="small"
              variant="outlined"
              onClick={() => setIsCollapsed(false)}
              disabled={isSubmitting}
              endIcon={<ExpandMoreIcon />}
            >
              {t("CallProjectScorecardView_Button_ViewAll")}
            </Button>
          )}
          <Button
            type="submit"
            size="small"
            variant="contained"
            disabled={isSubmitting}
          >
            {t("CallProjectScorecardView_Button_SubmitScores")}
          </Button>
        </Stack>
      </Paper>
      {!!scorecards.length && (
        <Paper variant="outlined">
          <Stack p={3} spacing={3}>
            <Typography variant="h4">
              {t("CallProjectScorecardView_Title_Scorecards")}
            </Typography>
            <Grid container gap={2}>
              {scorecards.map((scorecard) => (
                <Grid
                  key={scorecard.id}
                  item
                  xs={12 / 2}
                  sm={12 / 4}
                  md={12 / 6}
                  lg={12 / 8}
                >
                  <ListItemButton
                    onClick={() => setSelectedScorecard(scorecard)}
                    sx={{
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderColor: "divider",
                      borderRadius: "4px",
                      px: 2,
                    }}
                  >
                    <Stack spacing={1} alignItems="center" width="100%">
                      <Typography variant="caption1" color="text.600">
                        {toLocaleDateTimeString(scorecard.createdAt, "date")}
                      </Typography>

                      <Typography variant="h3">
                        {scorecard.totalScore}/{scorecard.maxScore}
                      </Typography>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        maxWidth="100%"
                      >
                        <AvatarView name={scorecard.reviewerName} size={24} />
                        <Typography
                          variant="caption1"
                          color="text.600"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          whiteSpace="nowrap"
                        >
                          {scorecard.reviewerName}
                        </Typography>
                      </Stack>
                    </Stack>
                  </ListItemButton>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Paper>
      )}
      <CallProjectScorecardDialog
        scorecard={selectedScorecard}
        isOpen={Boolean(selectedScorecard)}
        onClose={() => setSelectedScorecard(undefined)}
      />
    </>
  );
};

const COLLAPSED_COUNT = 3;
