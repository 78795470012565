import { Stack, Tooltip } from "@mui/material";
import { Call, FirebaseFilter, isCall } from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC, useCallback, useMemo } from "react";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { toLocaleMinutes } from "../../Common/helpers/toLocaleMinutes";
import { CollectionTableColumn } from "../../Firebase/types/CollectionTableColumn";
import { CollectionTableView } from "../../Firebase/views/CollectionTableView";
import { useT } from "../../Translation/hooks/useT";
import { CALL_DIRECTION_RECORD } from "../consts/CALL_DIRECTION_RECORD";
import { getNormalizedCall } from "../helpers/getNormalizedCall";
import { CallFlagView } from "./CallFlagView";
import { CallsCollectionTableCellTooltip } from "./CallsCollectionTableCellTooltip";
import { CallTaskStatusChip } from "./CallTaskStatusChip";
import { CallTranscriptsProcessStatusChip } from "./CallTranscriptsProcessStatusChip";
import { CallVoicefileProcessStatusChip } from "./CallVoicefileProcessStatusChip";

interface Props {
  projectId: string;
  projectReportFrameId?: string;
  hasIssues?: boolean;
  callDirection?: Call["direction"];
  from?: Date;
  to?: Date;
}

export const CallsCollectionTableView: FC<Props> = (props) => {
  const {
    projectId,
    callDirection,
    from,
    to,
    projectReportFrameId,
    hasIssues,
  } = props;
  const t = useT();

  const getCallTo = useCallback(
    (call: Call) =>
      projectReportFrameId
        ? `/projects/${projectId}/report-frames/${projectReportFrameId}/calls/${call.id}`
        : `/projects/${projectId}/calls/${call.id}`,
    [projectId, projectReportFrameId],
  );

  const columns = useMemo<CollectionTableColumn<Call>[]>(
    () => [
      {
        title: t("CallsCollectionTableView_Title_Time"),
        renderCell: (row) => {
          const value = row.direction && CALL_DIRECTION_RECORD[row.direction];
          const timestamp = toLocaleDateTimeString(row.startTime, {
            day: true,
            month: true,
            hour: true,
            minute: true,
          }).replace(",", "");

          return (
            <>
              <Stack direction="row" spacing={2}>
                {value && (
                  <Tooltip placement="right" title={t(value.label)} arrow>
                    <value.Icon sx={{ color: value.color }} />
                  </Tooltip>
                )}
                <Stack justifyContent="center">{timestamp}</Stack>
              </Stack>
            </>
          );
        },
      },
      {
        title: t("CallsCollectionTableView_Title_CallId"),
        field: "externalCallId",
        sx: { maxWidth: 0 },
        renderCell: (row) => (
          <CallsCollectionTableCellTooltip title={row.externalCallId} />
        ),
      },
      {
        title: t("CallsCollectionTableView_Title_AgentName"),
        field: "agentName",
        sx: { maxWidth: 0 },
        renderCell: (row) => (
          <CallsCollectionTableCellTooltip title={row.agentName} />
        ),
      },
      {
        title: t("CallsCollectionTableView_Title_CallDuration"),
        renderCell: (row) =>
          row.durationInSeconds
            ? toLocaleMinutes(row.durationInSeconds * 1000)
            : "-",
      },
      {
        title: t("CallsCollectionTableView_Title_PhoneNumber"),
        sx: { maxWidth: 0 },
        renderCell: (call) => {
          const number =
            call.direction === "INBOUND"
              ? call.sourcePhoneNumber
              : call.direction === "OUTBOUND"
              ? call.destinationPhoneNumber
              : call.sourcePhoneNumber || call.destinationPhoneNumber;
          return <CallsCollectionTableCellTooltip title={number} />;
        },
      },
      {
        title: t("CallsCollectionTableView_Title_WaitingTime"),
        renderCell: (row) =>
          row.waitingTimeInSeconds
            ? toLocaleMinutes(row.waitingTimeInSeconds * 1000)
            : "-",
      },
      {
        title: t("CallsCollectionTableView_Title_HoldTime"),
        renderCell: (row) =>
          row.holdTimeInSeconds
            ? toLocaleMinutes(row.holdTimeInSeconds * 1000)
            : "-",
      },
      {
        title: t("CallsCollectionTableView_Title_Voicefile"),
        renderCell: (call) => <CallVoicefileProcessStatusChip call={call} />,
      },
      {
        title: t("CallsCollectionTableView_Title_Transcript"),
        renderCell: (row) =>
          row.transcripts?.length ? (
            // TODO: Replace as soon as we have real sentiment data!
            // <CallSentimentSummaryView call={row} />
            <CallTranscriptsProcessStatusChip call={row} />
          ) : (
            <CallTranscriptsProcessStatusChip call={row} />
          ),
      },
      {
        title: t("CallsCollectionTableView_Title_TaskStatus"),
        renderCell: (call) => (
          <Stack direction="row" alignItems="center" spacing={2}>
            <CallFlagView call={call} to={getCallTo(call)} />
            <CallTaskStatusChip call={call} />
          </Stack>
        ),
      },
    ],
    [getCallTo, t],
  );

  return (
    <CollectionTableView
      collectionName={`projects/${projectId}/calls`}
      keyField="id"
      orderBy={["startTime", "desc"]}
      filterDirection="desc"
      filters={[
        ...(hasIssues
          ? [["hasIssues", "==", true] satisfies FirebaseFilter<Call>]
          : []),
        ...(callDirection
          ? [["direction", "==", callDirection] satisfies FirebaseFilter<Call>]
          : []),
        ...(from ? [["date", ">=", from] satisfies FirebaseFilter<Call>] : []),
        ...(to ? [["date", "<=", to] satisfies FirebaseFilter<Call>] : []),
      ]}
      getTo={getCallTo}
      getGroupTitle={(call) =>
        DateTime.fromMillis(call.startTime.toMillis()).toFormat("dd LLLL yyyy")
      }
      isT={isCall}
      getNormalizedRow={getNormalizedCall}
      columns={columns}
    />
  );
};
