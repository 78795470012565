import { FC, useLayoutEffect } from "react";
import { useHeaderConfig } from "../hooks/useHeaderConfig";
import { HeaderConfigVariant } from "../types/HeaderConfigVariant";

export interface Props {
  variant: HeaderConfigVariant;
  children?: React.ReactNode;
}

export const HeaderConfig: FC<Props> = (props) => {
  const { variant, children } = props;

  useLayoutEffect(() => {
    useHeaderConfig.setState({ variant, children });
  }, [variant, children]);

  return null;
};
