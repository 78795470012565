import { Stack } from "@mui/material";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { useProjectContext } from "../hooks/useProjectContext";
import { ProjectCallCategoriesForm } from "./ProjectCallCategoriesForm";

export const ProjectCallCategoriesPage = () => {
  const { project } = useProjectContext();

  if (!project) return null;

  return (
    <FullHeightContainer maxWidth="md">
      <Stack py={5} spacing={3}>
        <ProjectCallCategoriesForm project={project} />
      </Stack>
    </FullHeightContainer>
  );
};
