import { isProject } from "@snubes/snubes-types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useProject(projectId?: string) {
  return useValidDocumentData(
    projectId ? getDocRef("projects", projectId) : null,
    isProject,
  );
}
