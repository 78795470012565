import { Tender, TenderApplicationStatus } from "@snubes/snubes-types";

export function getTenderApplications(
  tender: Tender,
  ...status: TenderApplicationStatus[]
) {
  if (!tender.providerApplications) {
    return [];
  }
  return Object.values(tender.providerApplications).filter(
    (application) => !status || status.includes(application.status),
  );
}
