import { FC } from "react";
import { NavTab } from "../../Common/types/NavTab";
import { InvitationsPage } from "../../Invitations/views/InvitationsPage";
import { NotificationsAllPage } from "../views/NotificationsAllPage";
import { NotificationsMyTasksPage } from "../views/NotificationsMyTasksPage";
import { NotificationsUnassignedTasksPage } from "../views/NotificationsUnassignedTasksPage";

interface NotificationNavTab extends NavTab {
  Page: FC;
}

export const NOTIFICATION_NAV_TABS: NotificationNavTab[] = [
  {
    label: "NotificationsLayoutView_TabLabel_AllNotifications",
    route: "all",
    Page: NotificationsAllPage,
  },
  {
    label: "NotificationsLayoutView_TabLabel_MyTasks",
    route: "my-tasks",
    Page: NotificationsMyTasksPage,
  },
  {
    label: "NotificationsLayoutView_TabLabel_UnassignedTasks",
    route: "unassigned-tasks",
    Page: NotificationsUnassignedTasksPage,
  },
  {
    label: "NotificationsLayoutView_TabLabel_MyInvitations",
    route: "my-invitations",
    Page: InvitationsPage,
  },
];
