import { isMember, Member, Path } from "@snubes/snubes-types";
import { query, where } from "firebase/firestore";
import { getCollectionGroupRef } from "../../Firebase/helpers/getCollectionGroupRef";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export function useMembers(organizationId: string | string[] | undefined) {
  const q = Array.isArray(organizationId)
    ? query(
        getCollectionGroupRef("members"),
        where("organizationId" satisfies Path<Member>, "in", organizationId),
      )
    : organizationId
    ? query(getCollectionRef(`organizations/${organizationId}/members`))
    : null;
  return useValidCollectionData(q, isMember);
}
