import { CallTranscriptSource } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface CallTranscriptSourceRecordValue {
  label: TranslationKey;
}

export const CALL_TRANSCRIPT_SOURCE_RECORD: Readonly<
  Record<CallTranscriptSource, CallTranscriptSourceRecordValue>
> = {
  AWS_TRANSCRIBE: {
    label: "CallTranscriptSourceRecord_Label_AwsTranscribe",
  },
  WHISPER: {
    label: "CallTranscriptSourceRecord_Label_Whisper",
  },
  WHISPERX: {
    label: "CallTranscriptSourceRecord_Label_Whisperx",
  },
};
