import { create } from "zustand";

interface AuthEmailState {
  email: string;
  update: (email: string) => void;
  reset: () => void;
}

export const useAuthEmail = create<AuthEmailState>((set) => ({
  email: "",
  update: (email: string) => set({ email }),
  reset: () => set({ email: "" }),
}));
