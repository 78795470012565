import { Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";
import { useT } from "../../Translation/hooks/useT";
import { ProjectCreateView } from "./ProjectCreateView";

export const ProjectCreatePage: FC = () => {
  const t = useT();
  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <SecondaryHeaderView
        backButton={{
          to: "/admin/projects",
          label: t("ProjectCreatePage_Label_BackToProjects"),
        }}
        title={t("ProjectCreatePage_Label_NewProject")}
      />
      <FullHeightContainer maxWidth="sm">
        <Stack py={5} spacing={3}>
          <Stack component={Paper} variant="outlined" p={3} spacing={3}>
            <Typography variant="h2">
              {t("ProjectCreatePage_Label_NewProject")}
            </Typography>
            <ProjectCreateView />
          </Stack>
        </Stack>
      </FullHeightContainer>
    </>
  );
};
