import { safeDivide } from "../../Common/helpers/safeDivide";
import { ReportFrameByDay } from "../types/ReportFrameByDay";

export const aggregateReportFrames = (
  reportFrames: ReportFrameByDay[],
): ReportFrameByDay => {
  const sumValues = reportFrames.reduce(
    (result, frame) => ({
      totalCallsCount: result.totalCallsCount + frame.totalCallsCount,
      answeredCallsCount: result.answeredCallsCount + frame.answeredCallsCount,
      sumReachabililtyPercentage:
        result.sumReachabililtyPercentage +
        frame.reachabilityPercentage * frame.totalCallsCount,
      sumAverageHandlingTimeInSeconds:
        result.sumAverageHandlingTimeInSeconds +
        frame.averageHandlingTimeInSeconds * frame.totalCallsCount,
      totalHandlingTimeInSeconds:
        result.totalHandlingTimeInSeconds + frame.totalHandlingTimeInSeconds,
      sumServiceLevelPercentage:
        result.sumServiceLevelPercentage +
        frame.serviceLevelPercentage * frame.totalCallsCount,
    }),
    {
      totalCallsCount: 0,
      answeredCallsCount: 0,
      sumReachabililtyPercentage: 0,
      sumAverageHandlingTimeInSeconds: 0,
      totalHandlingTimeInSeconds: 0,
      sumServiceLevelPercentage: 0,
    },
  );

  return {
    totalCallsCount: sumValues.totalCallsCount,
    answeredCallsCount: sumValues.answeredCallsCount,
    reachabilityPercentage: safeDivide(
      sumValues.sumReachabililtyPercentage,
      sumValues.totalCallsCount,
    ),
    averageHandlingTimeInSeconds: safeDivide(
      sumValues.sumAverageHandlingTimeInSeconds,
      sumValues.totalCallsCount,
    ),
    totalHandlingTimeInSeconds: sumValues.totalHandlingTimeInSeconds,
    serviceLevelPercentage: safeDivide(
      sumValues.sumServiceLevelPercentage,
      sumValues.totalCallsCount,
    ),
  };
};
