import { superstructResolver } from "@hookform/resolvers/superstruct";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button, Divider, IconButton, Stack } from "@mui/material";
import { Project } from "@snubes/snubes-types";
import { FC, Fragment, useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { array, Infer, string, type } from "superstruct";
import { v4 } from "uuid";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { updateProjectScorecardQuestionsCallable } from "../callables/updateProjectScorecardQuestionsCallable";
import { getProjectOrganizationIds } from "../helpers/getProjectOrganizationIds";

const FormValuesStruct = type({
  questions: array(
    type({
      id: string(), // used by the form only
      text: string(),
    }),
  ),
});

type FormValues = Infer<typeof FormValuesStruct>;

interface Props {
  project: Project;
}

export const ProjectScorecardForm: FC<Props> = (props) => {
  const t = useT();
  const hasPermission = useHasPermission();
  const canUpdateProjectScorecardQuestions = hasPermission(
    "CAN_UPDATE_PROJECT_SCORECARD_QUESTIONS",
    { organizationIds: getProjectOrganizationIds(props.project) },
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      questions:
        props.project.scorecardQuestions?.map((question) => ({
          id: v4(),
          text: question,
        })) || [],
    },
  });
  const isFormDisabled = isSubmitting || !canUpdateProjectScorecardQuestions;

  const {
    fields: questions,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "questions",
  });

  const addQuestion = useCallback(
    () => append({ id: v4(), text: "" }),
    [append],
  );

  const removeQuestion = useCallback(
    (index: number) => () => remove(index),
    [remove],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      if (isSubmitting) return;

      try {
        const questions = formValues.questions
          .map((question) => question.text)
          .filter(Boolean);

        await updateProjectScorecardQuestionsCallable({
          projectId: props.project.id,
          scorecardQuestions: questions.length ? questions : null,
        });

        toast.success(t("QuestionsForm_Toast_SavedQuestions"));
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [isSubmitting, props.project.id, t],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} p={3}>
        {questions.map((question, index) => (
          <Fragment key={question.id}>
            <Stack direction="row" spacing={3} alignItems="center">
              <ControlledTextField
                control={control}
                name={`questions.${index}.text`}
                label={t("QuestionsForm_Label_Question")}
                disabled={isFormDisabled}
                required
                fullWidth
              />
              <IconButton
                size="large"
                sx={{ color: "error.main" }}
                onClick={removeQuestion(index)}
                disabled={isFormDisabled}
              >
                <DeleteForeverIcon />
              </IconButton>
            </Stack>
            <Divider />
          </Fragment>
        ))}

        <Stack direction="row" bgcolor="grey.50" borderColor="divider">
          <Button
            variant="text"
            onClick={addQuestion}
            startIcon={<AddCircleIcon />}
            disabled={isFormDisabled}
            fullWidth
            sx={{
              fontSize: 14,
              fontWeight: "bold",
              justifyContent: "flex-start",
              pl: 3,
            }}
          >
            {t("QuestionsForm_Button_NewQuestion")}
          </Button>
        </Stack>

        <Divider />

        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={isFormDisabled}
        >
          {t("Common_Save")}
        </Button>
      </Stack>
    </form>
  );
};
