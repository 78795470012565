import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Timestamp } from "@snubes/snubes-types";
import { FC, useCallback, useEffect, useState } from "react";
import { useT } from "../../Translation/hooks/useT";
import { getDiffInSeconds } from "../helpers/getDiffInSeconds";
import { getDiffTranslation } from "../helpers/getDiffTranslation";
import { TimeDiffTranslationFormat } from "../types/TimeDiffTranslationFormat";
import { DateTimeView } from "./DateTimeView";
import { PaperTooltipView } from "./PaperTooltipView";

interface Props {
  timestamp: Timestamp | string;
  translation?: `${string}{diff}${string}` | boolean;
  format?: TimeDiffTranslationFormat;
  shouldBeInThePast: boolean;
  hasTooltip?: boolean;
}

export const RelativeTimestampView: FC<Props> = (props) => {
  const t = useT();

  const getValue = useCallback(() => {
    const diffTranslation = getDiffTranslation({
      seconds: getDiffInSeconds(props.timestamp, props.shouldBeInThePast),
      format: props.format,
    });
    if (props.translation == true) {
      return t("RelativeTimestampView_Label_TimeAgo", {
        time: diffTranslation,
      });
    }
    if (props.translation && typeof props.translation == "string") {
      return props.translation.replace("{diff}", diffTranslation);
    }
    return diffTranslation;
  }, [
    props.format,
    props.shouldBeInThePast,
    props.timestamp,
    props.translation,
    t,
  ]);

  const [value, setValue] = useState(getValue());

  useEffect(() => {
    const interval = setInterval(() => setValue(getValue()), 1000);

    return () => clearInterval(interval);
  }, [getValue]);

  return props.hasTooltip ? (
    <PaperTooltipView
      title={
        <Typography p={1} color="text.600" variant="h5">
          <DateTimeView date={props.timestamp} />
        </Typography>
      }
    >
      <TabularNumView>{value}</TabularNumView>
    </PaperTooltipView>
  ) : (
    <TabularNumView>{value}</TabularNumView>
  );
};

const TabularNumView = styled("span")(() => ({
  fontVariantNumeric: "tabular-nums",
}));
