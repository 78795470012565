import i18nLanguages from "@cospired/i18n-iso-languages";
import { useMemo } from "react";
import { getLanguageName } from "../../Common/helpers/getLanguageName";
import { TranslationKey } from "../../Translation/types/TranslationKey";
import { FormOption } from "../types/FormOption";

interface Args {
  asOptions?: boolean;
}

/**
 * @returns Sorted list of language codes by localized translation of languages names.
 */
export function useLanguageOptions(): string[];
export function useLanguageOptions(args: { asOptions: true }): FormOption[];
export function useLanguageOptions(args: Args): string[];
export function useLanguageOptions(args?: Args): string[] | FormOption[] {
  return useMemo(() => {
    const values = Object.keys(i18nLanguages.getAlpha2Codes())
      .map((code) => {
        const name = getLanguageName(code);
        return { name, code };
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    if (args?.asOptions) {
      return values.map(({ code }) => ({
        // This is OK because t() will return the raw string if it doesn't find a translation
        label: getLanguageName(code) as TranslationKey,
        value: code,
      }));
    } else {
      return values.map(({ code }) => code);
    }
  }, [args?.asOptions]);
}
