import { Locale } from "@snubes/snubes-types";
import { useCallback, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { updateUserCallable } from "../callables/updateUserCallable";
import { useMyUser } from "./useMyUser";

export function useSetUserSelectedLocale() {
  const isInitializing = useMyUser((state) => state.isInitializing);
  const userId = useMyUser((state) => state.user?.id);
  const currentSelectedLocale = useMyUser.getState().user?.selectedLocale;
  const [isSubmitting, setIsSubmitting] = useState(false);

  return useCallback(
    async (selectedLocale: Locale) => {
      if (isSubmitting || isInitializing || !userId) return;
      if (selectedLocale === currentSelectedLocale) return;

      try {
        setIsSubmitting(true);
        await updateUserCallable({ userId, user: { selectedLocale } });
      } catch (error) {
        handleError(error).logAnd().toast();
      } finally {
        setIsSubmitting(false);
      }
    },
    [currentSelectedLocale, isInitializing, isSubmitting, userId],
  );
}
