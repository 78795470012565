import { Timestamp } from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC, useMemo } from "react";

interface Props {
  date: Timestamp;
}

export const DateView: FC<Props> = (props) => {
  const element = useMemo(
    () =>
      DateTime.fromJSDate(props.date.toDate()).toLocaleString({
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
    [props.date],
  );
  return <>{element}</>;
};
