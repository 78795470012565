import { ConnectorPlatform } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface ConnectorPlatformRecordValue {
  label: TranslationKey;
}

export const CONNECTOR_PLATFORM_RECORD: Record<
  ConnectorPlatform,
  ConnectorPlatformRecordValue
> = {
  INOPLA: {
    label: "ConnectorPlatformRecord_Label_Inopla",
  },
  DIALFIRE: {
    label: "ConnectorPlatformRecord_Label_Dialfire",
  },
  VCC_LIVE: {
    label: "ConnectorPlatformRecord_Label_VccLive",
  },
  SFTP: { label: "ConnectorPlatformRecord_Label_Sftp" },
  GENERIC_WEBHOOK: {
    label: "ConnectorPlatformRecord_Label_GenericWebhook",
  },
};
