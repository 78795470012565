import { OrganizationType } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface OrganizationTypeRecordValue {
  label: TranslationKey;
}

export const ORGANIZATION_TYPE_RECORD: Readonly<
  Record<OrganizationType, OrganizationTypeRecordValue>
> = {
  PROVIDER: {
    label: "OrganizationTypeRecord_Label_Provider",
  },
  CLIENT: {
    label: "OrganizationTypeRecord_Label_Client",
  },
};
