import { TargetGroupAudience } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

export const TENDER_TARGET_GROUP_AUDIENCE: Record<
  TargetGroupAudience,
  TranslationKey
> = {
  MANAGEMENT: "TenderTargetGroupAudience_Management",
  MARKETING: "TenderTargetGroupAudience_Marketing",
  SALES: "TenderTargetGroupAudience_Sales",
  IT: "TenderTargetGroupAudience_IT",
  HR: "TenderTargetGroupAudience_HR",
  FINANCE: "TenderTargetGroupAudience_Finance",
  PROCUREMENT: "TenderTargetGroupAudience_Procurement",
  LEGAL: "TenderTargetGroupAudience_Legal",
  OPERATIONS: "TenderTargetGroupAudience_Operations",
  OTHER: "TenderTargetGroupAudience_Other",
};
