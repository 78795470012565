import { Alert } from "@mui/material";
import { T } from "../../Translation/views/T";

export function OrganizationNotFoundView() {
  return (
    <Alert sx={{ m: 3 }} severity="error">
      <T k="OrganizationNotFoundView_Error" />
    </Alert>
  );
}
