import CampaignIcon from "@mui/icons-material/Campaign";
import { Button, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";
import { HomePageInfoPanelCard } from "./HomePageInfoPanelCard";

interface Props {
  titleText: TranslationKey;
  subtitleText1: TranslationKey;
  subtitleText2: TranslationKey;
  buttonText: TranslationKey;
  buttonHref?: string;
  buttonDisabled?: boolean;
}

export const HomeGetStartedCardView: FC<Props> = (props) => {
  const {
    subtitleText1,
    subtitleText2,
    titleText,
    buttonText,
    buttonHref,
    buttonDisabled,
  } = props;
  const t = useT();
  return (
    <HomePageInfoPanelCard isOutlined>
      <CampaignIcon sx={{ color: "text.500", fontSize: 45 }} />
      <Typography variant="subtitle2" color="text.main">
        {t(subtitleText2)}
      </Typography>
      <Stack spacing={2}>
        <Typography variant="h3" color="text.main">
          {t(titleText)}
        </Typography>
        <Typography variant="subtitle1" color="text.main">
          {t(subtitleText1)}
        </Typography>
      </Stack>
      <Stack alignItems="flex-start">
        <Button
          variant="contained"
          size="small"
          color="primary"
          href={buttonHref}
          disabled={buttonDisabled}
        >
          {t(buttonText)}
        </Button>
      </Stack>
    </HomePageInfoPanelCard>
  );
};
