import { Container, Stack, Typography } from "@mui/material";
import {
  ChannelType,
  ServiceType,
  TEAM_MODELS,
  TeamModelStruct,
} from "@snubes/snubes-types";
import { FC } from "react";
import { optional, type } from "superstruct";
import { ControlledRadioButtons } from "../../Form/views/ControlledRadioButtons";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { TENDER_TEAM_MODEL } from "../consts/TENDER_TEAM_MODEL";
import { findTenderServiceChannel } from "../helpers/findTenderServiceChannel";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  teamModel: optional(TeamModelStruct),
});

type Props = WizardFormProps<{
  serviceType: ServiceType;
  channelType: ChannelType;
}>;

const TEAM_MODEL_OPTIONS = TEAM_MODELS.map((teamModel) => ({
  ...TENDER_TEAM_MODEL[teamModel],
  value: teamModel,
}));

export const TenderWizardChannelTeamModelForm: FC<Props> = (props) => {
  const { serviceType, channelType } = props.formProps;
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => ({
      teamModel: findTenderServiceChannel(serviceType, channelType, tender)
        ?.teamModel,
    }),
    toDoc: (formValues, tender) => {
      const channel = findTenderServiceChannel(
        serviceType,
        channelType,
        tender,
      );

      if (channel) {
        channel.teamModel = formValues.teamModel;
      }

      return {
        services: tender?.services,
        isReviewed: {
          ...tender?.isReviewed,
          services: false,
        },
      };
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardChannelTeamModelForm_Title_TeamModel")}
        </Typography>
        <Stack spacing={3}>
          <ControlledRadioButtons
            control={control}
            name="teamModel"
            options={TEAM_MODEL_OPTIONS}
            gridSpacing={4}
            gridItemXs={6}
          />
        </Stack>
      </Container>
    </form>
  );
};
