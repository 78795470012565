import { Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../Common/helpers/handleError";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";
import { createOrganizationCallable } from "../../Organizations/callables/createOrganizationCallable";
import {
  OrganizationCreateForm,
  OrganizationCreateFormOnSubmitFormValues,
} from "../../Organizations/views/OrganizationCreateForm";
import { useT } from "../../Translation/hooks/useT";
import { T } from "../../Translation/views/T";

export const AdminOrganizationCreatePage: FC = () => {
  const t = useT();
  const navigate = useNavigate();

  const onSubmit = async (
    formValues: OrganizationCreateFormOnSubmitFormValues,
  ) => {
    try {
      const { organizationId } = await createOrganizationCallable({
        organization: {
          name: formValues.name,
          domain: formValues.domain,
          website: formValues.website,
          ...(typeof formValues.isHubspotEnabled !== "undefined" && {
            isHubspotEnabled: formValues.isHubspotEnabled,
            ...(formValues.hubspotCompanyId && {
              hubspotCompanyId: formValues.hubspotCompanyId,
            }),
          }),
        },
      });

      navigate(`/organizations/${organizationId}`);
    } catch (error) {
      handleError(error).logAnd().toast();
    }
  };

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <SecondaryHeaderView
        pathPrefix="/admin/organizations/create"
        backButton={{
          to: "/admin/organizations",
          label: t(
            "AdminOrganizationCreatePage_ButtonLabel_BackToOrganizations",
          ),
        }}
      />

      <FullHeightContainer maxWidth="sm" sx={{ pt: 5 }}>
        <Stack component={Paper} p={5} spacing={5}>
          <Typography variant="h2">
            <T k="AdminOrganizationCreatePage_Headline" />
          </Typography>

          <OrganizationCreateForm onSubmit={onSubmit} />
        </Stack>
      </FullHeightContainer>
    </>
  );
};
