import { Process, ProcessStatus, Timestamp } from "@snubes/snubes-types";
import { isCallProcessOverdue } from "../helpers/isCallProcessOverdue";

interface Args {
  process?: Process;
  isProcessed: boolean;
  isProcessable: boolean;
  previousProcessEndedAt: Timestamp;
}

export function getCallProcessStatus(args: Args): ProcessStatus {
  const { process, isProcessed, isProcessable, previousProcessEndedAt } = args;
  const isProcessing =
    !!process && ["PROCESSING", "REQUESTED"].includes(process.status);
  const isOverdue = isCallProcessOverdue(process, previousProcessEndedAt);

  if (isProcessed && !isProcessing) return "SUCCEEDED";
  if (!isProcessable) return "ON_HOLD";
  if (isOverdue) return "FAILED";
  if (process?.status) return process.status;

  return "PROCESSING";
}
