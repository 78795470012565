import { Paper, Stack } from "@mui/material";
import { StorageFile } from "@snubes/snubes-types";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { handleError } from "../../Common/helpers/handleError";
import { UploadState } from "../../Common/types/UploadState";
import { FileListView } from "../../Common/views/FileListView";
import { FileUploadView } from "../../Common/views/FileUploadView";
import { useT } from "../../Translation/hooks/useT";
import { deleteOrganizationLogoCallable } from "../callables/deleteOrganizationLogoCallable";

interface Props {
  userId: string;
  organizationId: string;
  organizationLogo?: StorageFile;
}

export const OrganizationLogoUploadView: FC<Props> = (props) => {
  const { userId, organizationId, organizationLogo } = props;
  const t = useT();

  const [isUpdating, setIsUpdating] = useState(false);
  const [uploadState, setUploadState] = useState<UploadState>();

  const handleDeleteLogo = async (doc: StorageFile) => {
    try {
      setIsUpdating(true);
      await deleteOrganizationLogoCallable({
        organizationId,
        fileHash: doc.fileHash,
      });
      toast.success(
        t("OrganizationLogoUploadView_Toast_DeletedLogo", {
          name: doc.fileName,
        }),
      );
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsUpdating(false);
    }
  };

  const onUploadStateChanged = useCallback((state: UploadState) => {
    if (state.status === "started" || state.status === "running") {
      setUploadState(state);
    } else {
      setUploadState(undefined);
    }
  }, []);

  const logoFileList = organizationLogo ? [organizationLogo] : [];

  return (
    <Stack>
      {logoFileList.length === 0 && (
        <FileUploadView
          onUploadStateChanged={onUploadStateChanged}
          storagePath={`/user-uploads/organization-images/${organizationId}`}
          storageFileMetadata={{
            userId,
            organizationId,
          }}
          acceptedFileTypes={{
            "image/png": [".png"],
          }}
        />
      )}

      {(logoFileList || uploadState) && (
        <Paper
          elevation={0}
          sx={{
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "divider",
          }}
        >
          <FileListView
            uploadState={uploadState}
            files={logoFileList}
            onClickDeleteFile={handleDeleteLogo}
            showDeleteAction={logoFileList.length > 0}
            disabled={isUpdating}
          />
        </Paper>
      )}
    </Stack>
  );
};
