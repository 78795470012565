import { Menu, MenuItem } from "@mui/material";
import { useParams } from "next/navigation";
import { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { getLanguageName } from "../../Common/helpers/getLanguageName";
import { I18N_SETTINGS } from "../../Translation/consts/I18N_SETTINGS";
import { LOCALE_RECORD } from "../../Translation/consts/LOCALE_RECORD";
import { parseLocale } from "../../Translation/helpers/parseLocale";

interface Props {
  anchorEl?: HTMLElement;
  closeMenu: () => void;
}

export const HeaderLanguageMenuView: FC<Props> = (props) => {
  const params = useParams();
  const { pathname } = useLocation();

  const items = useMemo(() => {
    const locale = parseLocale(params?.locale);
    const path = pathname.replace(`/${locale}`, "");

    return I18N_SETTINGS.languages.map((code) => ({
      code,
      path: `/${code}${path}`,
      text: `${LOCALE_RECORD[code].flag} ${getLanguageName(code)}`,
    }));
  }, [pathname, params?.locale]);

  return (
    <Menu
      anchorEl={props.anchorEl}
      open={!!props.anchorEl}
      onClick={props.closeMenu}
      onClose={props.closeMenu}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      keepMounted={false}
    >
      {items.map(({ code, path, text }) => (
        // We need to use <a> instead of <Link> to achieve a full page reload.
        // This is necessary because we're using react-router inside of a Next.js app.
        <MenuItem key={code} sx={{ py: 3 }} href={path} component="a">
          {text}
        </MenuItem>
      ))}
    </Menu>
  );
};
