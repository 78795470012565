import { isProjectReportFrame, ProjectReportFrame } from "@snubes/snubes-types";
import { limit, orderBy, query } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

interface Args {
  projectId: string | null;
}

export function useLastProjectReportFrame(args: Args) {
  const { projectId } = args;

  const [reportFrames, isLoading, error] = useValidCollectionData(
    projectId
      ? query(
          getCollectionRef(`projects/${args.projectId}/reportFramesByDay`),
          orderBy("date", "desc"),
          limit(1),
        )
      : null,
    isProjectReportFrame,
  );

  const lastReportFrame = reportFrames[0] as ProjectReportFrame | undefined;

  return [lastReportFrame, isLoading, error] as const;
}
