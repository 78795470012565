import { Alert, AlertTitle, Stack, Typography } from "@mui/material";
import { UserType } from "@snubes/snubes-types";
import { TenderApplicationRejectionReason } from "@snubes/snubes-types/types/TenderApplicationRejectionReason";
import { FC } from "react";
import { useT } from "../../Translation/hooks/useT";
import { TENDER_APPLICATION_REJECTION_REASON_RECORD } from "../consts/TENDER_APPLICATION_REJECTION_REASON_RECORD";
import { TENDER_APPLICATION_STATUS_RECORD } from "../consts/TENDER_APPLICATION_STATUS_RECORD";

interface Props {
  status: keyof typeof TENDER_APPLICATION_STATUS_RECORD;
  rejectedByUserType?: UserType | null;
  rejectionReason?: TenderApplicationRejectionReason | null;
}

export const TenderMyApplicationAlert: FC<Props> = (props) => {
  const { status, rejectedByUserType, rejectionReason } = props;
  const t = useT();

  return (
    <Alert severity={TENDER_APPLICATION_STATUS_RECORD[status].alertColor}>
      <AlertTitle
        sx={(theme) => ({
          fontSize: theme.typography.subtitle1.fontSize,
        })}
      >
        {t(TENDER_APPLICATION_STATUS_RECORD[status].alertTitle)}
      </AlertTitle>
      {status === "REJECTED" && rejectedByUserType === "PROVIDER" ? (
        <Typography variant="subtitle2">
          {t("TenderApplicationStatusRecord_AlertText_Rejected_By_Provider")}
        </Typography>
      ) : (
        <Typography variant="subtitle2">
          {t(TENDER_APPLICATION_STATUS_RECORD[status].alertText)}
        </Typography>
      )}
      {rejectionReason && (
        <Stack mt={3}>
          <AlertTitle
            sx={(theme) => ({
              fontSize: theme.typography.subtitle1.fontSize,
            })}
          >
            {t("TenderApplicationsPage_Title_RejectionReason")}
          </AlertTitle>
          <Typography variant="subtitle2">
            {t(
              TENDER_APPLICATION_REJECTION_REASON_RECORD[rejectionReason].label,
            )}
          </Typography>
        </Stack>
      )}
    </Alert>
  );
};
