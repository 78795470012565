import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Stack, Typography } from "@mui/material";
import { FC, PropsWithChildren, ReactElement, useLayoutEffect } from "react";
import { NavTab } from "../../Common/types/NavTab";
import { NavTabs } from "../../Common/views/NavTabs";
import {
  HEADER_HEIGHT,
  SECONDARY_HEADER_HEIGHT,
} from "../consts/HEADER_HEIGHT";
import { useHeaderConfig } from "../hooks/useHeaderConfig";

interface Props extends PropsWithChildren {
  title?: ReactElement | string;
  tabs?: NavTab[];
  pathPrefix?: string;
  backButton?: {
    label: string;
    to: string;
  };
}

export const SecondaryHeaderView: FC<Props> = (props) => {
  const { tabs, backButton, title, pathPrefix } = props;

  useLayoutEffect(() => {
    const { hasSecondaryHeader } = useHeaderConfig.getState();

    // If the secondary header is already shown, we don't want to set again.
    // But more importantly, we don't want to hide it when unmounting.
    // This is mainly a problem when using a secondary header in a layout view.
    if (hasSecondaryHeader) return;

    useHeaderConfig.setState({ hasSecondaryHeader: true });
    return () => useHeaderConfig.setState({ hasSecondaryHeader: false });
  }, []);

  return (
    <Stack
      position="fixed"
      direction="row"
      top={HEADER_HEIGHT}
      height={SECONDARY_HEADER_HEIGHT}
      justifyContent="center"
      alignItems="center"
      left={0}
      right={0}
      bgcolor="background.paper"
      px={3}
      zIndex={5}
      borderBottom={1}
      borderColor="divider"
    >
      {backButton && (
        <Button
          size="small"
          href={backButton.to}
          variant="regular"
          startIcon={<ArrowBackIcon />}
          sx={(theme) => ({
            ...theme.typography.body1,
            position: "absolute",
            left: 0,
            ml: 4,
          })}
        >
          {backButton.label}
        </Button>
      )}
      {title && <Typography variant="h4">{title}</Typography>}
      <Stack alignItems="flex-end">
        {tabs && pathPrefix && (
          <NavTabs
            tabs={tabs}
            pathPrefix={pathPrefix}
            sx={{
              height: SECONDARY_HEADER_HEIGHT,
              display: "flex",
              alignItems: "flex-end",
            }}
          />
        )}
      </Stack>
      {props.children}
    </Stack>
  );
};
