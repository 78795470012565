import { superstructResolver } from "@hookform/resolvers/superstruct";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  ConnectorConfig,
  CreateConnectionRequest,
  ExternalProject,
} from "@snubes/snubes-types";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  array,
  Infer,
  nullable,
  object,
  optional,
  string,
  type,
} from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { isProjectMappable } from "../../Common/helpers/isProjectMappable";
import { ControlledProjectIdAutocomplete } from "../../Form/views/ControlledProjectIdAutocomplete";
import { useT } from "../../Translation/hooks/useT";
import { updateConnectorConfigCallable } from "../callables/updateConnectorConfigCallable";

interface Props {
  connectorConfig: ConnectorConfig;
  testConnectorConfig?: () => Promise<ExternalProject[]>;
}

const FormValuesStruct = type({
  projectMappings: array(
    object({
      projectId: nullable(string()),
      externalProjectId: nullable(string()),
      projectName: optional(string()),
    }),
  ),
});

type FormValues = Infer<typeof FormValuesStruct>;

export const ConnectorConfigSingleProjectMappingsEditForm: FC<Props> = (
  props,
) => {
  const { connectorConfig, testConnectorConfig } = props;
  const t = useT();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>();
  const hasStartedConnectorConfigTestRef = useRef(false);
  const [isConnectorConfigTested, setIsConnectorConfigTested] = useState(
    !testConnectorConfig,
  );

  const { control, formState, handleSubmit } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      projectMappings: connectorConfig.projectMappings,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "projectMappings",
  });

  useEffect(() => {
    const startAsyncEffect = async () => {
      try {
        if (hasStartedConnectorConfigTestRef.current) return;
        if (!testConnectorConfig) return;
        hasStartedConnectorConfigTestRef.current = true;
        await testConnectorConfig();
        setIsConnectorConfigTested(true);
        setErrorMessage(undefined);
      } catch (error) {
        setErrorMessage(error instanceof Error ? error.message : String(error));
      }
    };

    void startAsyncEffect();
  }, [testConnectorConfig]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!isConnectorConfigTested) {
          throw new Error("Connector config has not been tested yet.");
        }

        const projectMappings = formValues.projectMappings.reduce<
          CreateConnectionRequest["projectMappings"]
        >((result, mapping) => {
          const { projectId, externalProjectId, projectName } = mapping;
          if (projectId && externalProjectId) {
            result.push({
              projectId,
              externalProjectId,
              ...(projectName && { projectName }),
            });
          }
          return result;
        }, []);

        await updateConnectorConfigCallable({
          connectorConfigId: connectorConfig.id,
          organizationId: connectorConfig.organizationId,
          connectorConfig: {
            projectMappings,
          },
        });
        navigate(
          `/organizations/${connectorConfig.organizationId}/connector-configs`,
        );
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [
      isConnectorConfigTested,
      connectorConfig.id,
      connectorConfig.organizationId,
      navigate,
    ],
  );

  const { isSubmitting } = formState;

  const isLoading = !errorMessage && !isConnectorConfigTested;

  return (
    <Stack spacing={5}>
      <Paper variant="outlined">
        <Stack p={4} spacing={5}>
          {!!errorMessage && (
            <Stack color="error.main" spacing={3}>
              <Stack spacing={3} alignItems="center" direction="row">
                <InfoIcon />
                <Typography variant="subtitle2">
                  {t(
                    "ConnectorConfigSingleProjectMappingsEditForm_SubHeading_ConnectedError",
                  )}
                </Typography>
              </Stack>
              <Stack
                p={5}
                bgcolor="background.default"
                justifyContent="center"
                alignContent="center"
              >
                {errorMessage}
              </Stack>
              <Divider />
            </Stack>
          )}
          {isLoading && (
            <Stack spacing={3} alignItems="center" direction="row">
              <CircularProgress size={20} />
              <Typography variant="subtitle2">
                {t(
                  "ConnectorConfigSingleProjectMappingsEditForm_SubHeading_CheckingUpdating",
                )}
              </Typography>
            </Stack>
          )}
          {!errorMessage && (
            <>
              <Typography variant="h3">
                {t(
                  "ConnectorConfigSingleProjectMappingsEditForm_Heading_UpdateMappings",
                )}
              </Typography>
              <Typography textTransform="uppercase" variant="subtitle1">
                {t(
                  "ConnectorConfigSingleProjectMappingsEditForm_SubHeading_ChooseProject",
                )}
              </Typography>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  {fields.map((item, index) => {
                    return (
                      <Stack
                        key={item.id}
                        justifyContent="space-between"
                        alignItems="flex-start"
                        direction="row"
                        spacing={3}
                        flex={1}
                      >
                        {connectorConfig.organizationId && (
                          <Stack spacing={3} flex={1}>
                            <ControlledProjectIdAutocomplete
                              fullWidth
                              required
                              disabled={isSubmitting || isLoading}
                              filter={(project) =>
                                isProjectMappable(project, connectorConfig)
                              }
                              name={`projectMappings.${index}.projectId`}
                              projectNameFieldName={`projectMappings.${index}.projectName`}
                              control={control}
                              label={t(
                                "ConnectorConfigSingleProjectMappingsEditForm_SelectLabel_SnubesProject",
                              )}
                              organizationId={connectorConfig.organizationId}
                            />
                          </Stack>
                        )}
                      </Stack>
                    );
                  })}

                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    {t("Common_Update")}
                  </Button>
                </Stack>
              </form>
            </>
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};
