import { isProvider } from "@snubes/snubes-types";
import { getOrganization } from "../../Organizations/helpers/getOrganization";
import { createWizardStore } from "../../Wizard/helpers/createWizardStore";
import { submitProviderCallable } from "../callables/submitProviderCallable";
import { createOrUpdateProvider } from "../helpers/createOrUpdateProvider";

export const useProviderWizardStore = createWizardStore({
  collectionName: "providers",
  isT: isProvider,
  createOrUpdateDoc: createOrUpdateProvider,
  submitDoc: async (providerId) => {
    await submitProviderCallable({ providerId });
  },
  onInitialize: async (providerId, provider) => {
    const organization = await getOrganization({
      organizationId: providerId,
    });
    // Initialize certain fields from the organization.
    return {
      ...provider,
      organizationId: providerId,
      ...(organization?.name && { organizationName: organization.name }),
      ...(organization?.email && { email: organization.email }),
      ...(organization?.phone && { phone: organization.phone }),
      ...(organization?.website && { website: organization.website }),
      ...(organization?.street && { street: organization.street }),
      ...(organization?.city && { city: organization.city }),
      ...(organization?.zipCode && { zipCode: organization.zipCode }),
      ...(organization?.country && { country: organization.country }),
      ...(organization?.description && {
        description: organization.description,
      }),
      ...(organization?.foundedInYear && {
        organizationFoundedInYear: organization.foundedInYear,
      }),
      ...(organization?.numberOfEmployees && {
        numberOfEmployees: organization.numberOfEmployees,
      }),
      ...(organization?.industry && {
        organizationIndustry: organization.industry,
      }),
      ...(organization?.clientFocus && {
        clientFocus: organization.clientFocus,
      }),
      ...(organization?.revenuePerYear && {
        revenuePerYear: organization.revenuePerYear,
      }),
      ...(organization?.socialMediaFacebook && {
        socialMediaFacebook: organization.socialMediaFacebook,
      }),
      ...(organization?.socialMediaTwitter && {
        socialMediaTwitter: organization.socialMediaTwitter,
      }),
      ...(organization?.socialMediaLinkedIn && {
        socialMediaLinkedIn: organization.socialMediaLinkedIn,
      }),
      ...(organization?.socialMediaInstagram && {
        socialMediaInstagram: organization.socialMediaInstagram,
      }),
      ...(organization?.socialMediaYoutube && {
        socialMediaYoutube: organization.socialMediaYoutube,
      }),
    };
  },
});
