import { superstructResolver } from "@hookform/resolvers/superstruct";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { EmailStruct } from "@snubes/snubes-types";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Infer, type } from "superstruct";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useT } from "../../Translation/hooks/useT";
import { useAuthEmail } from "../hooks/useAuthEmail";
import { AuthGoogleSsoButton } from "./AuthGoogleSsoButton";
import { AuthLayoutView } from "./AuthLayoutView";

const FormValuesStruct = type({
  email: EmailStruct,
});

type FormValues = Infer<typeof FormValuesStruct>;

export const AuthSignupPage: FC = () => {
  const t = useT();
  const navigate = useNavigate();

  const { control, handleSubmit, watch } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      email: useAuthEmail.getState().email,
    },
  });

  useEffect(() => {
    const subscription = watch(
      ({ email }) => email && useAuthEmail.getState().update(email),
    );
    return subscription.unsubscribe;
  }, [watch]);

  return (
    <AuthLayoutView>
      <HeaderConfig variant="NONE" />
      <Stack spacing={2}>
        <Box p={3} bgcolor="background.paper" border={1} borderColor="divider">
          <AuthGoogleSsoButton />
        </Box>
        <Stack
          spacing={3}
          p={4}
          bgcolor="background.paper"
          border={1}
          borderColor="divider"
        >
          <Typography variant="h3">
            {t("AuthSignupView_Headline_CreateAccount")}
          </Typography>

          {/* Signup */}
          <form onSubmit={handleSubmit(() => navigate("./user"))}>
            <Stack spacing={3}>
              <ControlledTextField
                required
                fullWidth
                name="email"
                type="email"
                label={t("AuthSignupView_Label_Email")}
                control={control}
              />
              <Button type="submit" variant="contained">
                {t("AuthSignupView_Button_CreateAccount")}
              </Button>
            </Stack>
          </form>

          <Divider sx={{ color: "grey.500" }}>OR</Divider>

          {/* Login */}
          <Typography variant="h3">
            {t("AuthSignupView_Subline_Question")}
          </Typography>
          <Button variant="outlined" href="/login">
            {t("AuthSignupView_Subline_Cta")}
          </Button>
        </Stack>
      </Stack>
    </AuthLayoutView>
  );
};
