import HistoryIcon from "@mui/icons-material/History";
import { IconButton, SxProps, Theme } from "@mui/material";
import { CollectionName, Log } from "@snubes/snubes-types";
import { FC, useState } from "react";
import { LogListItem } from "../types/LogListItem";
import { LogsDialog } from "./LogsDialog";

interface Props<T extends Log> {
  collectionName: CollectionName;
  LogListItemView: FC<LogListItem<T>>;
  isT: (obj: unknown) => obj is T;
  sx?: SxProps<Theme>;
}

export function LogsButtonView<T extends Log>(props: Props<T>) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <IconButton
        size="large"
        sx={props.sx}
        onClick={() => setIsDialogOpen(true)}
      >
        <HistoryIcon />
      </IconButton>
      {isDialogOpen && (
        <LogsDialog {...props} close={() => setIsDialogOpen(false)} />
      )}
    </>
  );
}
