import { WizardStep } from "../types/WizardStep";

export function getWizardStepProgressSelector<TState>(
  step: WizardStep<TState>,
): (state: TState) => number {
  if (step.progressSelector) {
    return step.progressSelector;
  }

  if (!step.steps?.length) {
    return () => 0;
  }

  const selectors = step.steps.map(getWizardStepProgressSelector);

  return (state: TState) => {
    const total = selectors.reduce(
      (result, selector) => result + selector(state),
      0,
    );

    return total / selectors.length;
  };
}
