import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Button } from "@mui/material";
import { FC, PropsWithChildren, ReactNode } from "react";

interface Props extends PropsWithChildren {
  title: ReactNode;
  href: string;
}

export const HomeCardActionButton: FC<Props> = (props) => {
  const { title, href } = props;
  return (
    <Button size="small" href={href} endIcon={<ArrowOutwardIcon />}>
      {title}
    </Button>
  );
};
