import { Box, Stack, Typography } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { useMediaQueryUp } from "../../Common/hooks/useMediaQueryUp";
import { useT } from "../../Translation/hooks/useT";
import { AuthLayoutFooter } from "./AuthLayoutFooter";

interface Props extends PropsWithChildren {
  title?: string;
  subtitle?: string;
}

export const AuthLayoutView: FC<Props> = (props) => {
  const t = useT();
  const isUpMd = useMediaQueryUp("md");

  return (
    <Stack height="100%" bgcolor="grey.100">
      <Stack flex={1} direction="row" px={2}>
        {isUpMd && (
          <Stack
            flex={1}
            flexBasis="55%"
            justifyContent="center"
            alignItems="center"
            sx={{ textAlign: "center" }}
            px={4}
          >
            <img src="/logo180.png" alt="Snubes logo" width={80} />
            <Typography variant="h2" sx={{ maxWidth: 580 }}>
              {props.title || t("AuthSignupView_Headline_Hero")}
            </Typography>
            <Typography variant="body1" mt={3} sx={{ maxWidth: 350 }}>
              {props.subtitle || t("AuthSignupView_Subline_Hero")}
            </Typography>
          </Stack>
        )}
        <Stack
          flex={1}
          flexBasis="45%"
          justifyContent="center"
          alignItems="center"
        >
          <Box maxWidth={420} width="100%">
            {props.children}
          </Box>
        </Stack>
      </Stack>

      <AuthLayoutFooter />
    </Stack>
  );
};
