import { ConnectorStatusLogAction } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface ConnectorStatusLogActionsRecordValue {
  label: TranslationKey;
}

export const CONNECTOR_STATUS_LOG_ACTIONS_RECORD: Record<
  ConnectorStatusLogAction,
  ConnectorStatusLogActionsRecordValue
> = {
  TEST_CONNECTION: {
    label: "ConnectorStatusLogActions_Label_TestConnection",
  },
  FETCH_CDRS: {
    label: "ConnectorStatusLogActions_Label_FetchCdrs",
  },
  START_TRANSCRIPT_VOICEFILE_AWS: {
    label: "ConnectorStatusLogActions_Label_StartTranscriptVoiceFileAws",
  },
  COMPLETE_TRANSCRIPT_VOICEFILE_AWS: {
    label: "ConnectorStatusLogActions_Label_CompleteTranscriptVoiceFileAws",
  },
  COMPLETE_DOWNLOAD_VOICEFILE: {
    label: "ConnectorStatusLogActions_Label_CompleteDownLoadVoiceFile",
  },
};
