import { CHANNEL_TYPES, ChannelType, ServiceType } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface TenderServiceTypeRecordValue {
  channelTypes: ReadonlyArray<ChannelType>;
  translationKey: Readonly<TranslationKey>;
}

export const TENDER_SERVICE_TYPE_RECORD: Record<
  ServiceType,
  TenderServiceTypeRecordValue
> = {
  // INBOUND
  TELEPHONE_ANSWERING: {
    translationKey: "ServiceType_TELEPHONE_ANSWERING",
    channelTypes: ["PHONE"],
  },
  APPOINTMENT_MANAGEMENT: {
    translationKey: "ServiceType_APPOINTMENT_MANAGEMENT",
    channelTypes: CHANNEL_TYPES,
  },
  OVERFLOW_AFTER_HOURS_SUPPORT: {
    translationKey: "ServiceType_OVERFLOW_AFTER_HOURS_SUPPORT",
    channelTypes: CHANNEL_TYPES,
  },
  CUSTOMER_SERVICE: {
    translationKey: "ServiceType_CUSTOMER_SERVICE",
    channelTypes: CHANNEL_TYPES,
  },
  DISASTER_RESPONSE: {
    translationKey: "ServiceType_DISASTER_RESPONSE",
    channelTypes: CHANNEL_TYPES,
  },
  TECHNICAL_SUPPORT: {
    translationKey: "ServiceType_TECHNICAL_SUPPORT",
    channelTypes: CHANNEL_TYPES,
  },
  EMERGENCY_HOTLINE: {
    translationKey: "ServiceType_EMERGENCY_HOTLINE",
    channelTypes: ["PHONE"],
  },
  HELP_DESK: {
    translationKey: "ServiceType_HELP_DESK",
    channelTypes: CHANNEL_TYPES,
  },
  HOTLINE: { translationKey: "ServiceType_HOTLINE", channelTypes: ["PHONE"] },
  LIVE_CHAT: {
    translationKey: "ServiceType_LIVE_CHAT",
    channelTypes: ["WEB_CHAT"],
  },
  LOYALTY_PROGRAMS: {
    translationKey: "ServiceType_LOYALTY_PROGRAMS",
    channelTypes: CHANNEL_TYPES,
  },
  ORDER_PROCESSING: {
    translationKey: "ServiceType_ORDER_PROCESSING",
    channelTypes: CHANNEL_TYPES,
  },
  RECEPTIONIST: {
    translationKey: "ServiceType_RECEPTIONIST",
    channelTypes: CHANNEL_TYPES,
  },
  WHISTLEBLOWER_HOTLINE: {
    translationKey: "ServiceType_WHISTLEBLOWER_HOTLINE",
    channelTypes: ["PHONE"],
  },
  VIDEO_IDENT: {
    translationKey: "ServiceType_VIDEO_IDENT",
    channelTypes: ["PHONE"],
  },
  SERVICE_DESK: {
    translationKey: "ServiceType_SERVICE_DESK",
    channelTypes: CHANNEL_TYPES,
  },
  FIRST_LEVEL_SUPPORT: {
    translationKey: "ServiceType_FIRST_LEVEL_SUPPORT",
    channelTypes: CHANNEL_TYPES,
  },
  VIDEOCHAT_SERVICE: {
    translationKey: "ServiceType_VIDEOCHAT_SERVICE",
    channelTypes: ["PHONE"],
  },
  COMPLAINT_MANAGEMENT: {
    translationKey: "ServiceType_COMPLAINT_MANAGEMENT",
    channelTypes: CHANNEL_TYPES,
  },
  DONATION_HOTLINE: {
    translationKey: "ServiceType_DONATION_HOTLINE",
    channelTypes: ["PHONE"],
  },
  // OUTBOUND
  LEAD_GENERATION: {
    translationKey: "ServiceType_LEAD_GENERATION",
    channelTypes: ["PHONE"],
  },
  APPOINTMENT_SETTING: {
    translationKey: "ServiceType_APPOINTMENT_SETTING",
    channelTypes: ["PHONE"],
  },
  MARKET_RESEARCH: {
    translationKey: "ServiceType_MARKET_RESEARCH",
    channelTypes: ["PHONE"],
  },
  SUBSCRIPTION_MARKETING: {
    translationKey: "ServiceType_SUBSCRIPTION_MARKETING",
    channelTypes: ["PHONE"],
  },
  COLLECTION_REMINDERS: {
    translationKey: "ServiceType_COLLECTION_REMINDERS",
    channelTypes: ["PHONE"],
  },
  INSURANCE_SALES: {
    translationKey: "ServiceType_INSURANCE_SALES",
    channelTypes: ["PHONE"],
  },
  MYSTERY_CALLS: {
    translationKey: "ServiceType_MYSTERY_CALLS",
    channelTypes: ["PHONE"],
  },
  EVENT_REGISTRATION: {
    translationKey: "ServiceType_EVENT_REGISTRATION",
    channelTypes: ["PHONE"],
  },
  TELESALES: {
    translationKey: "ServiceType_TELESALES",
    channelTypes: ["PHONE"],
  },
  TELEMARKETING: {
    translationKey: "ServiceType_TELEMARKETING",
    channelTypes: ["PHONE"],
  },
  SERVICE_CALLS: {
    translationKey: "ServiceType_SERVICE_CALLS",
    channelTypes: ["PHONE"],
  },
  TELEPHONE_SURVEYS: {
    translationKey: "ServiceType_TELEPHONE_SURVEYS",
    channelTypes: ["PHONE"],
  },
  // BACK_OFFICE_SUPPORT – TODO: Find out what channel types we need here!
  DATA_MAINTENANCE: {
    translationKey: "ServiceType_DATA_MAINTENANCE",
    channelTypes: CHANNEL_TYPES,
  },
  DATA_ANALYTICS: {
    translationKey: "ServiceType_DATA_ANALYTICS",
    channelTypes: CHANNEL_TYPES,
  },
  EMAIL_SERVICE: {
    translationKey: "ServiceType_EMAIL_SERVICE",
    channelTypes: CHANNEL_TYPES,
  },
  PAYROLL_ACCOUNTING: {
    translationKey: "ServiceType_PAYROLL_ACCOUNTING",
    channelTypes: CHANNEL_TYPES,
  },
  FULFILLMENT: {
    translationKey: "ServiceType_FULFILLMENT",
    channelTypes: CHANNEL_TYPES,
  },
  SECRETARIAL_SERVICES: {
    translationKey: "ServiceType_SECRETARIAL_SERVICES",
    channelTypes: CHANNEL_TYPES,
  },
  CUSTOMER_FEEDBACK_HANDLING: {
    translationKey: "ServiceType_CUSTOMER_FEEDBACK_HANDLING",
    channelTypes: CHANNEL_TYPES,
  },
  DEBT_COLLECTION_AND_DUNNING: {
    translationKey: "ServiceType_DEBT_COLLECTION_AND_DUNNING",
    channelTypes: CHANNEL_TYPES,
  },
  MAIL_PROCESSING: {
    translationKey: "ServiceType_MAIL_PROCESSING",
    channelTypes: CHANNEL_TYPES,
  },
};
