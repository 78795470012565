import {
  FirebaseFilter,
  Organization,
  OrganizationType,
} from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

export const ADMIN_ORGANIZATIONS_ROUTES = [
  "all",
  "clients",
  "providers",
  "deleted",
] as const;

export type AdminOrganizationRoute =
  (typeof ADMIN_ORGANIZATIONS_ROUTES)[number];

interface RouteRecordValue {
  label: TranslationKey;
  filters: FirebaseFilter<Organization>[];
  hasCreateButton?: boolean;
}

export const ADMIN_ORGANIZATIONS_ROUTE_RECORD: Record<
  AdminOrganizationRoute,
  RouteRecordValue
> = {
  all: {
    label: "AdminOrganizationsRouteRecord_Label_All",
    filters: [["isDeleted", "==", false]],
    hasCreateButton: true,
  },
  clients: {
    label: "AdminOrganizationsRouteRecord_Label_Clients",
    filters: [
      ["isDeleted", "==", false],
      ["types", "array-contains", "CLIENT" satisfies OrganizationType],
    ],
    hasCreateButton: true,
  },
  providers: {
    label: "AdminOrganizationsRouteRecord_Label_Providers",
    filters: [
      ["isDeleted", "==", false],
      ["types", "array-contains", "PROVIDER" satisfies OrganizationType],
    ],
    hasCreateButton: true,
  },
  deleted: {
    label: "AdminOrganizationsRouteRecord_Label_Deleted",
    filters: [["isDeleted", "==", true]],
  },
};
