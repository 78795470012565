import { Divider, Paper, Stack, Typography } from "@mui/material";
import { FirebaseFilter, Tender, TenderStatus } from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import {
  FirebaseTableFilterContextProvider,
  FirebaseTableFilterRecord,
} from "../../Firebase/hooks/useFirebaseTableFilters";
import { useT } from "../../Translation/hooks/useT";
import { TendersCollectionTableView } from "./TendersCollectionTableView";

interface Props {
  organizationId: string;
}

export const TendersEndedView: FC<Props> = (props) => {
  const t = useT();

  const filters = useMemo<FirebaseTableFilterRecord<Tender>>(
    () => ({
      name: {
        isSearch: true,
        label: t("FirebaseTableFilterView_Label_Search"),
        type: "text",
        operator: "search",
        value: null,
      },
    }),
    [t],
  );

  const tableFilters = useMemo<FirebaseFilter<Tender>[]>(() => {
    return [
      ["isDeleted", "==", false],
      ["organizationId", "==", props.organizationId],
      ["status", "==", "ENDED" satisfies TenderStatus],
    ];
  }, [props.organizationId]);

  return (
    <Stack py={5} spacing={3}>
      <Typography
        variant="h2"
        minHeight={53}
        display="flex"
        alignItems="center"
      >
        {t("TendersEndedView_Headline_TendersEnded")}
      </Typography>

      <Divider />
      <Paper>
        <FirebaseTableFilterContextProvider filters={filters}>
          <TendersCollectionTableView
            showStatus
            showActions
            filters={tableFilters}
          />
        </FirebaseTableFilterContextProvider>
      </Paper>
    </Stack>
  );
};
