import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Button, Link, Stack, SxProps, Theme, Tooltip } from "@mui/material";
import { FC, ReactNode, SyntheticEvent } from "react";

interface Props {
  href: string;
  tooltip?: string;
  children: ReactNode;
  variant: "button" | "link";
  sx?: Exclude<SxProps<Theme>, ReadonlyArray<unknown>>;
}

export const ExternalLinkView: FC<Props> = (props) => {
  return (
    <Tooltip title={props.tooltip}>
      <Stack>
        {props.variant === "link" && (
          <Link
            href={props.href}
            target="_blank"
            rel="noreferrer"
            onClick={onClickLink}
            variant="body2"
            display="inline-flex"
            sx={[
              {
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              },
              props.sx || {},
            ]}
          >
            {props.children}
            <ArrowOutwardIcon color="primary" />
          </Link>
        )}
        {props.variant === "button" && (
          <Button
            href={props.href}
            target="_blank"
            rel="noreferrer"
            onClick={onClickLink}
            size="small"
            variant="text"
            endIcon={<ArrowOutwardIcon color="primary" />}
            sx={props.sx}
          >
            {props.children}
          </Button>
        )}
      </Stack>
    </Tooltip>
  );
};

const onClickLink = (e: SyntheticEvent) => {
  e.stopPropagation();
};
