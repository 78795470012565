import { isMember } from "@snubes/snubes-types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useMember(organizationId?: string, userId?: string) {
  return useValidDocumentData(
    organizationId && userId
      ? getDocRef(`organizations/${organizationId}/members`, userId)
      : null,
    isMember,
  );
}
