import AddIcon from "@mui/icons-material/Add";
import { Button, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { useT } from "../../Translation/hooks/useT";
import { useProjectsController } from "../hooks/useProjectsController";
import { ProjectsControllerView } from "./ProjectsControllerView";

export const ProjectsPageHeaderView: FC = () => {
  const t = useT();
  const controller = useProjectsController();
  const hasPermission = useHasPermission();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h2">
        {t("ProjectsView_Headline_Projects")}
      </Typography>

      <Stack direction="row" spacing={3}>
        <ProjectsControllerView controller={controller} />

        {hasPermission("CAN_CREATE_PROJECT") && (
          <Button
            href="/projects/create"
            variant="contained"
            startIcon={<AddIcon />}
          >
            {t("ProjectsPage_Button_CreateProject")}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
