import { FormControl, FormLabel, Stack, SxProps } from "@mui/material";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { TranslationKey } from "../../Translation/types/TranslationKey";
import { RadioButton } from "./RadioButton";

const OPTIONS = new Array(11).fill(0).map((_, value) => {
  return { label: String(value) as unknown as TranslationKey, value };
});

interface Props<TFields extends FieldValues> {
  control: Control<TFields>;
  name: FieldPath<TFields>;
  label?: string;
  sx?: SxProps;
  disabled?: boolean;
  required?: boolean;
}

export function ControlledScorecardRadioButtons<TFields extends FieldValues>(
  props: Props<TFields>,
) {
  return (
    <Controller
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      control={props.control}
      name={props.name}
      render={({ field, fieldState }) => (
        <FormControl
          fullWidth
          error={!!fieldState.error}
          disabled={props.disabled}
        >
          <FormLabel component="legend" sx={{ mb: 2 }}>
            {props.label}
          </FormLabel>
          <Stack direction="row" spacing={2}>
            {OPTIONS.map((option) => (
              <RadioButton
                {...option}
                sx={{ py: 3, px: 0, minWidth: "unset" }}
                key={option.value}
                disabled={props.disabled}
                checked={field.value === option.value}
                onChange={() => field.onChange(option.value)}
              />
            ))}
          </Stack>
        </FormControl>
      )}
    />
  );
}
