import { FirebaseFilter, Notification } from "@snubes/snubes-types";
import { useEffect } from "react";
import { updateNotificationHasBeenReadCallable } from "../callables/updateNotificationHasBeenReadCallable";
import { useNotifications } from "./useNotifications";

interface Args {
  organizationId: string | null;
  userId: string | null;
  filters: FirebaseFilter<Notification>[];
}

export function useUpdateNotificationHasBeenRead(args: Args) {
  const { organizationId, userId, filters } = args;

  const [notifications] = useNotifications({
    organizationId,
    userId,
    filters: filters.length
      ? [...filters, ["hasBeenRead", "==", false]]
      : undefined,
    limit: 1,
  });

  const notificationId = notifications.length ? notifications[0].id : undefined;

  useEffect(() => {
    if (!notificationId) return;

    const timeout = setTimeout(async () => {
      await updateNotificationHasBeenReadCallable({
        notificationId,
        hasBeenRead: true,
      });
    }, TIMEOUT_IN_MS);

    return () => clearTimeout(timeout);
  }, [notificationId]);
}

const TIMEOUT_IN_MS = 2000;
