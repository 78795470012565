import { TenderAgentTrainingPriceModel } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface TenderAgentTrainingPriceModelRecordValue {
  label: TranslationKey;
}
export const TENDER_AGENT_TRAINING_PRICE_MODEL_RECORD: Record<
  TenderAgentTrainingPriceModel,
  TenderAgentTrainingPriceModelRecordValue
> = {
  FLATRATE: {
    label: "TenderAgentTrainingPriceModelRecord_Label_Flatrate",
  },
  PER_HOUR: {
    label: "TenderAgentTrainingPriceModelRecord_Label_PerHour",
  },
};
