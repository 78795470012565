import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import { AlertColor, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import {
  TenderApplication,
  TenderApplicationStatus,
  Timestamp,
} from "@snubes/snubes-types";
import { StatusColor } from "../../Common/types/StatusColor";
import { TranslationKey } from "../../Translation/types/TranslationKey";

type TimestampFields<T> = NonNullable<
  {
    [K in keyof T]: T[K] extends Timestamp | undefined ? K : never;
  }[keyof T]
>;

interface TenderApplicationStatusRecordValue {
  label: TranslationKey;
  alertTitle: TranslationKey;
  alertText: TranslationKey;
  alertColor: AlertColor;
  color: StatusColor;
  variant?: "filled" | "outlined";
  Icon: OverridableComponent<SvgIconTypeMap>;
  timestampKey: TimestampFields<TenderApplication>;
}

export const TENDER_APPLICATION_STATUS_RECORD: Readonly<
  Record<TenderApplicationStatus | "ENDED", TenderApplicationStatusRecordValue>
> = {
  PENDING: {
    label: "TenderApplicationStatusRecord_Label_Pending",
    alertTitle: "TenderApplicationStatusRecord_AlertTitle_Pending",
    alertText: "TenderApplicationStatusRecord_AlertText_Pending",
    alertColor: "info",
    color: "default",
    variant: "outlined",
    Icon: PendingOutlinedIcon,
    timestampKey: "createdAt",
  },
  DRAFT: {
    label: "TenderApplicationStatusRecord_Label_Draft",
    alertTitle: "TenderApplicationStatusRecord_AlertTitle_Draft",
    alertText: "TenderApplicationStatusRecord_AlertText_Draft",
    alertColor: "info",
    color: "default",
    Icon: EditOutlinedIcon,
    timestampKey: "createdAt",
  },
  PUBLISHED: {
    label: "TenderApplicationStatusRecord_Label_Published",
    alertTitle: "TenderApplicationStatusRecord_AlertTitle_Published",
    alertText: "TenderApplicationStatusRecord_AlertText_Published",
    alertColor: "info",
    color: "primary",
    Icon: CheckCircleOutlineOutlinedIcon,
    timestampKey: "publishedAt",
  },
  ACCEPTED: {
    label: "TenderApplicationStatusRecord_Label_Accepted",
    alertTitle: "TenderApplicationStatusRecord_AlertTitle_Accepted",
    alertText: "TenderApplicationStatusRecord_AlertText_Accepted",
    alertColor: "success",
    color: "secondary",
    Icon: CheckCircleIcon,
    timestampKey: "acceptedAt",
  },
  REJECTED: {
    label: "TenderApplicationStatusRecord_Label_Rejected",
    alertTitle: "TenderApplicationStatusRecord_AlertTitle_Rejected",
    alertText: "TenderApplicationStatusRecord_AlertText_Rejected",
    alertColor: "error",
    color: "error",
    Icon: CancelIcon,
    timestampKey: "rejectedAt",
  },
  REJECTED_WITHOUT_APPLICATION: {
    label: "TenderApplicationStatusRecord_Label_Rejected",
    alertTitle: "TenderApplicationStatusRecord_AlertTitle_Rejected",
    alertText: "TenderApplicationStatusRecord_AlertText_Rejected",
    alertColor: "error",
    color: "error",
    Icon: CancelIcon,
    timestampKey: "rejectedAt",
  },
  REJECTED_FOR_RESUBMISSION: {
    label: "TenderApplicationStatusRecord_Label_RejectedForResubmission",
    alertTitle:
      "TenderApplicationStatusRecord_AlertTitle_RejectedForResubmission",
    alertText:
      "TenderApplicationStatusRecord_AlertText_RejectedForResubmission",
    alertColor: "error",
    color: "error",
    Icon: CancelIcon,
    timestampKey: "rejectedAt",
  },
  ENDED: {
    label: "TenderApplicationStatusRecord_Label_Ended",
    alertTitle: "TenderApplicationStatusRecord_AlertTitle_Ended",
    alertText: "TenderApplicationStatusRecord_AlertText_Ended",
    alertColor: "info",
    color: "default",
    Icon: CancelIcon,
    timestampKey: "createdAt",
  },
};
