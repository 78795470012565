import { UserType } from "@snubes/snubes-types";
import { USER_TYPE_RECORD } from "../../Users/consts/USER_TYPE_RECORD";
import { useMyUser } from "../../Users/hooks/useMyUser";

const DEFAULT_USER_TYPE: UserType = "QM";

export function useHomeScreenPath() {
  const isInitializing = useMyUser((state) => state.isInitializing);
  const userType = useMyUser.getState().user?.userType;

  return [
    USER_TYPE_RECORD[userType || DEFAULT_USER_TYPE].homeScreenPath,
    isInitializing,
  ] as const;
}
