import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Call,
  CallParticipantRole,
  CallTranscript,
} from "@snubes/snubes-types";
import { FC } from "react";
import { useT } from "../../Translation/hooks/useT";
import { CALL_PARTICIPANT_ROLE_RECORD } from "../consts/CALL_PARTICIPANT_ROLE_RECORD";
import { getCallSentimentValue } from "../helpers/getCallSentimentValue";
import { getCallTranscripts } from "../helpers/getCallTranscripts";
import { useGetCallSentimentColor } from "../hooks/useGetCallSentimentColor";
import { CallSentimentIconView } from "./CallSentimentIconView";

interface Props {
  participantRole: CallParticipantRole;
  call: Call;
  voicefileDurationInSeconds: number;
  activeTranscriptId?: string;
  setActiveTranscript: (transcript?: CallTranscript) => void;
}

export const CallSentimentView: FC<Props> = (props) => {
  const {
    participantRole,
    call,
    setActiveTranscript,
    activeTranscriptId,
    voicefileDurationInSeconds: totalSeconds,
  } = props;

  const t = useT();
  const { palette } = useTheme();
  const getCallSentimentColor = useGetCallSentimentColor();
  const sentiment = call.sentiment?.[participantRole] || 0;
  const callTranscripts = getCallTranscripts(call);
  const inactiveTranscripts =
    callTranscripts?.filter(
      (t) =>
        t.participantRole === participantRole && t.id !== activeTranscriptId,
    ) || [];
  const activeTranscript = callTranscripts?.find(
    (t) => t.id === activeTranscriptId && t.participantRole === participantRole,
  );

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="caption1">
          {t(CALL_PARTICIPANT_ROLE_RECORD[participantRole].sentiment)}
        </Typography>
        <Stack spacing={2} direction="row" alignItems="center">
          <CallSentimentIconView sentiment={sentiment} fontSize={16} />
          <Typography
            variant="caption1"
            color={getCallSentimentColor(sentiment)}
          >
            {getCallSentimentValue(sentiment)}
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height={`${HEIGHT + BORDER * 4}px`}
        >
          <rect
            width="100%"
            y={BORDER * 2}
            height={8}
            fill={palette.divider}
            rx={HEIGHT / 2}
          />
          {inactiveTranscripts.map((transcript) => (
            <rect
              key={transcript.id}
              display="block"
              cursor="pointer"
              x={`${getX(totalSeconds, transcript)}%`}
              y={BORDER * 2}
              width={`${getWidth(totalSeconds, transcript)}%`}
              height={HEIGHT}
              rx={HEIGHT / 2}
              fill={getCallSentimentColor(transcript.sentiment)}
              onClick={() => setActiveTranscript(transcript)}
            />
          ))}
          {activeTranscript && (
            <>
              <rect
                display="block"
                cursor="pointer"
                x={`calc(${getX(totalSeconds, activeTranscript)}% - ${
                  BORDER * 2
                }px)`}
                y={0}
                width={`calc(${getWidth(totalSeconds, activeTranscript)}% + ${
                  BORDER * 4
                }px)`}
                height={HEIGHT + BORDER * 4}
                rx={(HEIGHT + BORDER * 4) / 2}
                fill={palette.primary.main}
                onClick={() => setActiveTranscript(activeTranscript)}
              />
              <rect
                display="block"
                cursor="pointer"
                x={`calc(${getX(
                  totalSeconds,
                  activeTranscript,
                )}% - ${BORDER}px)`}
                y={BORDER}
                width={`calc(${getWidth(totalSeconds, activeTranscript)}% + ${
                  BORDER * 2
                }px)`}
                height={HEIGHT + BORDER * 2}
                rx={(HEIGHT + BORDER * 2) / 2}
                fill={palette.background.paper}
                onClick={() => setActiveTranscript(activeTranscript)}
              />
              <rect
                key={activeTranscript.id}
                display="block"
                cursor="pointer"
                x={`${getX(totalSeconds, activeTranscript)}%`}
                y={BORDER * 2}
                width={`${getWidth(totalSeconds, activeTranscript)}%`}
                height={HEIGHT}
                rx={HEIGHT / 2}
                fill={getCallSentimentColor(activeTranscript.sentiment)}
                onClick={() => setActiveTranscript(activeTranscript)}
              />
            </>
          )}
        </svg>
      </Stack>
    </Stack>
  );
};

const HEIGHT = 8;
const BORDER = 2;

function getX(totalSeconds: number, transcript: CallTranscript) {
  if (!totalSeconds) return 0;

  const start = transcript.startOffsetMilliseconds / 1000;

  return (start * 100) / totalSeconds;
}

function getWidth(totalSeconds: number, transcript: CallTranscript) {
  if (!totalSeconds) return 0;

  const start = transcript.startOffsetMilliseconds / 1000;
  const end = transcript.endOffsetMilliseconds / 1000;

  return ((end - start) * 100) / totalSeconds;
}
