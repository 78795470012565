import { OctopusTypesConfig } from "@snubes/snubes-types";
import { ENVIRONMENT } from "../Application/consts/ENVIRONMENT";
import { handleError } from "../Common/helpers/handleError";

OctopusTypesConfig.logError = (error) => {
  console.error(error);

  if (ENVIRONMENT.nodeEnv === "production") {
    handleError(error).log();
  } else {
    handleError(error).toast();
  }
};
