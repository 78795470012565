import { Stack, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import {
  FirebaseFilter,
  Member,
  Tender,
  TenderApplicationStatus,
  TenderStatus,
  User,
} from "@snubes/snubes-types";
import { FC, useCallback, useMemo } from "react";
import { FullHeightStack } from "../../Common/views/FullHeightStack";
import { MaxWidthContainer } from "../../Common/views/MaxWidthContainer";
import { CollectionSortBy } from "../../Firebase/types/CollectionSortBy";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { getUserName } from "../../Users/helpers/getUserName";
import { useSetUserType } from "../../Users/hooks/useSetUserType";
import { getTimeOfDayGreeting } from "../helpers/getTimeOfDayGreeting";
import { HomeApplicationCardView } from "./HomeApplicationCardView";
import { HomeCardNotificationsView } from "./HomeCardNotificationsView";
import { HomeCardOrganizationView } from "./HomeCardOrganizationView";
import { HomeCardProjectsView } from "./HomeCardProjectsView";
import { HomeCardTendersView } from "./HomeCardTendersView";
import { HomeOtherApplicationsView } from "./HomeOtherApplicationsView";

interface Props {
  user: User;
  member?: Member;
}

export const ProviderHomeView: FC<Props> = (props) => {
  const { user } = props;
  const t = useT();

  const organizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );
  const [setUserType] = useSetUserType();
  const tenderFilters = useMemo<FirebaseFilter<Tender>[]>(
    () => [
      ["status", "==", "PUBLISHED" satisfies TenderStatus],
      [
        `providerApplications.${organizationId}.status`,
        "in",
        [
          "PENDING",
          "DRAFT",
          "PUBLISHED",
          "ACCEPTED",
          "REJECTED",
          "REJECTED_FOR_RESUBMISSION",
        ] satisfies TenderApplicationStatus[],
      ],
      ["isDeleted", "==", false],
    ],
    [organizationId],
  );

  const tendersSortBy = useCallback<CollectionSortBy<Tender>>(
    (a, b) => b.updatedAt.toMillis() - a.updatedAt.toMillis(),
    [],
  );

  return (
    <FullHeightStack>
      <MaxWidthContainer>
        <Stack p={5} spacing={4}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h2">
              {t(getTimeOfDayGreeting())}, {getUserName(user)}
            </Typography>
          </Stack>

          <Grid container spacing={3}>
            <Grid xs={12} sm={6} md={4}>
              <HomeCardOrganizationView organizationId={organizationId} />
            </Grid>

            <Grid xs={12} sm={6} md={4}>
              <HomeCardNotificationsView userId={user.id} />
            </Grid>

            <Grid xs={12} sm={6} md={4}>
              <HomeCardProjectsView organizationId={organizationId} />
            </Grid>

            <Grid xs={12} sm={12} md={8}>
              <HomeCardTendersView
                filters={tenderFilters}
                sortBy={tendersSortBy}
                variant="PROVIDER"
                maxCount={3}
              />
            </Grid>
          </Grid>
        </Stack>
      </MaxWidthContainer>

      <HomeOtherApplicationsView sx={{ flex: 1 }}>
        <HomeApplicationCardView
          bgcolor="primary.50"
          subtitleText="ProviderHomePage_Subtitle_ForClients"
          titleText="ProviderHomePage_Heading_LookingToFindCallCenter"
          buttonText="ProviderHomePage_Button_LearnMore"
          buttonHref="/home/client"
          buttonOnClick={() => setUserType("CLIENT")}
          imgSrc="/assets/home-client.png"
        />
        <HomeApplicationCardView
          bgcolor="secondary.50"
          subtitleText="ProviderHomePage_Subtitle_ForQualityMonitoring"
          titleText="ProviderHomePage_Heading_AnalyzeGetInsights"
          buttonText="ProviderHomePage_Button_LearnMore"
          buttonHref="/home/qm"
          buttonOnClick={() => setUserType("QM")}
          imgSrc="/assets/home-qm.png"
          imgBgcolor="error.50"
        />
      </HomeOtherApplicationsView>
    </FullHeightStack>
  );
};
