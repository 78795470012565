import { Stack, Typography } from "@mui/material";
import {
  FirebaseFilter,
  FirebaseOrderBy,
  isTender,
  Tender,
} from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import { CollectionTableColumn } from "../../Firebase/types/CollectionTableColumn";
import { CollectionTableView } from "../../Firebase/views/CollectionTableView";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { TendersController } from "../hooks/useTendersController";
import { TenderApplicationsDeadlineView } from "./TenderApplicationsDeadlineView";
import { TenderApplicationsStatusChipsView } from "./TenderApplicationsStatusChipsView";
import { TenderApplicationStatusChip } from "./TenderApplicationStatusChip";
import { TenderMatchedProvidersStatusChipView } from "./TenderMatchedProvidersStatusChipView";
import { TendersCollectionTableActionsView } from "./TendersCollectionTableActionsView";
import { TenderStatusChip } from "./TenderStatusChip";

interface Props {
  controller?: TendersController;
  filters?: FirebaseFilter<Tender>[];
  showStatus?: boolean;
  showMatches?: boolean;
  showActions?: boolean;
}

export const TendersCollectionTableView: FC<Props> = (props) => {
  const { controller } = props;
  const t = useT();
  const selectedOrganizationId =
    useSelectedOrganization((organization) => organization?.id) ?? "";
  const userType = useMyUser((state) => state.user?.userType);

  const orderBy = useMemo<FirebaseOrderBy<Tender> | undefined>(() => {
    switch (controller?.orderBy) {
      case "BUDGET":
        return ["budget.amount", "desc"];
      case "PUBLISHED_AT":
        return ["publishedAt", "desc"];
      case "UPDATED_AT":
        return ["updatedAt", "desc"];
      case "CREATED_AT":
        return ["createdAt", "desc"];
      default:
        return undefined;
    }
  }, [controller?.orderBy]);

  const columns = useMemo<CollectionTableColumn<Tender>[]>(() => {
    return [
      ...(props.showStatus
        ? [
            {
              title: t("TendersCollectionTableView_TableCell_Status"),
              renderCell: (tender) => {
                const tenderApplication = selectedOrganizationId
                  ? tender?.providerApplications?.[selectedOrganizationId]
                  : undefined;

                return userType === "PROVIDER" ? (
                  <TenderApplicationStatusChip
                    tenderApplication={tenderApplication}
                  />
                ) : (
                  <TenderStatusChip status={tender.status} />
                );
              },
            } satisfies CollectionTableColumn<Tender>,
          ]
        : []),
      {
        title: t("TendersCollectionTableView_TableCell_Name"),
        field: "name",
        sx: { maxWidth: 300 },
        renderCell: (tender) => (
          <Stack spacing={1}>
            <Typography
              variant="caption1"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="break-spaces"
              sx={{
                hyphens: "auto",
              }}
            >
              {tender.name || <em>-</em>}
            </Typography>
            <Typography
              variant="caption2"
              color="text.secondary"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {tender.organizationName}
            </Typography>
          </Stack>
        ),
      },
      {
        title: t("TendersCollectionTableView_TableCell_Services"),
        sx: { maxWidth: 300 },
        renderCell: (tender) => (
          <>
            {!!tender.services?.length && (
              <Stack spacing={1}>
                <Typography
                  variant="caption1"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="break-spaces"
                  sx={{
                    hyphens: "auto",
                  }}
                >
                  {`${tender.services
                    .slice(0, 3)
                    .map((service) => t(`ServiceType_${service.type}`))
                    .join(", ")}${tender.services.length > 3 ? "..." : ""}`}
                </Typography>
                <Typography variant="caption2" color="text.secondary">
                  {t("TendersCollectionTableView_Label_Services", {
                    amount: tender.services.length,
                  })}
                </Typography>
              </Stack>
            )}
            {!tender.services?.length && <em>-</em>}
          </>
        ),
      },
      ...(props.showMatches
        ? [
            {
              title: t("TendersCollectionTableView_TableCell_Matches"),
              align: "center",
              renderCell: (tender) => {
                return <TenderMatchedProvidersStatusChipView tender={tender} />;
              },
            } satisfies CollectionTableColumn<Tender>,
          ]
        : []),
      {
        title: t("TendersCollectionTableView_TableCell_Applications"),
        align: "center",
        sx: { textAlign: "center" },
        renderCell: (tender) => {
          return <TenderApplicationsStatusChipsView tender={tender} />;
        },
      },
      {
        title: t("TendersCollectionTableView_TableCell_Deadline"),
        align: "right",
        renderCell: (tender) => (
          <TenderApplicationsDeadlineView tender={tender} />
        ),
      },
      {
        title: t("Common_UpdatedAt"),
        field: "updatedAt",
        align: "right",
      },
      ...(props.showActions
        ? [
            {
              title: "",
              align: "right",
              headSx: {
                width: 72,
              },
              renderCell: (tender) => (
                <TendersCollectionTableActionsView tender={tender} />
              ),
            } satisfies CollectionTableColumn<Tender>,
          ]
        : []),
    ];
  }, [
    props.showActions,
    props.showMatches,
    props.showStatus,
    selectedOrganizationId,
    t,
    userType,
  ]);

  return (
    <CollectionTableView
      collectionName="tenders"
      orderBy={orderBy}
      keyField="id"
      isT={isTender}
      filters={props.filters}
      getTo={(tender) => `/tenders/${tender.id}`}
      columns={columns}
    />
  );
};
