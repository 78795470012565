import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IconButton } from "@mui/material";
import { FC, useState } from "react";
import { ConnectorConfigDeleteDialog } from "./ConnectorConfigDeleteDialog";

interface Props {
  organizationId: string;
  connectorConfigId: string;
}

export const ConnectorConfigDeleteButton: FC<Props> = (props) => {
  const [
    isConnectorConfigDeleteDialogOpen,
    setIsConnectorConfigDeleteDialogOpen,
  ] = useState(false);

  return (
    <>
      <IconButton
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setIsConnectorConfigDeleteDialogOpen(true);
        }}
      >
        <DeleteForeverIcon sx={{ color: "error.main" }} />
      </IconButton>
      <ConnectorConfigDeleteDialog
        {...props}
        isOpen={isConnectorConfigDeleteDialogOpen}
        close={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setIsConnectorConfigDeleteDialogOpen(false);
        }}
      />
    </>
  );
};
