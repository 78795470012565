import { OrganizationType } from "@snubes/snubes-types";
import { FC } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { NoDataView } from "../../Common/views/NoDataView";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  organizationId: string;
}

export const ProjectsSetupView: FC<Props> = (props) => {
  const t = useT();
  const hasPermission = useHasPermission();
  const organizationType: OrganizationType = useSelectedOrganization(
    (organization) =>
      organization?.types?.includes("PROVIDER") ||
      hasPermission("CAN_WRITE_ORGANIZATION_CONNECTOR_CONFIG", {
        organizationId: organization?.id,
      })
        ? "PROVIDER"
        : "CLIENT",
  );

  return (
    <FullHeightContainer maxWidth="sm" sx={{ display: "flex" }}>
      <NoDataView
        label={t("ProjectsSetupView_Label_MissingProjects")}
        title={t(`ProjectsSetupView_Title_Main_${organizationType}`)}
        subTitle={t(`ProjectsSetupView_Title_Secondary_${organizationType}`)}
        button={
          organizationType === "PROVIDER"
            ? {
                href: `/organizations/${props.organizationId}/connector-configs/create`,
                label: t("ProjectsSetupView_Button_ConnectPhoneSystem"),
              }
            : undefined
        }
      />
    </FullHeightContainer>
  );
};
