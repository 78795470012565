import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  IconButton,
  ListItem,
  ListItemProps,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { StorageFile, StorageFilePath } from "@snubes/snubes-types";
import { FC, MouseEventHandler, useCallback } from "react";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { RelativeTimestampView } from "../../Common/views/RelativeTimestampView";
import { getStorageRef } from "../../Firebase/helpers/getStorageRef";
import { useT } from "../../Translation/hooks/useT";

interface Props extends Omit<ListItemProps, "secondaryAction"> {
  file: StorageFile;
  disabled?: boolean;
  showDeleteAction?: boolean;
  onClickDeleteFile?: (file: StorageFile) => Promise<void> | void;
}

export const FileListItemView: FC<Props> = (props) => {
  const { file, disabled, showDeleteAction, onClickDeleteFile, ...restProps } =
    props;

  const t = useT();
  const [downloadUrl = "", isLoading, error] = useDownloadURL(
    getStorageRef(file.filePath as StorageFilePath),
  );

  const onClickDelete = useCallback(() => {
    void onClickDeleteFile?.(file);
  }, [file, onClickDeleteFile]);

  return (
    <ListItem
      {...restProps}
      secondaryAction={
        <>
          {error && (
            <Typography color="error" variant="caption1">
              {error.message}
            </Typography>
          )}
          <Tooltip title={t("FileListView_Tooltip_Download")}>
            <span>
              <IconButton
                aria-label="delete"
                color="primary"
                edge={showDeleteAction ? "start" : "end"}
                disabled={disabled || isLoading || !!error}
                href={downloadUrl}
                onClick={stopPropagationOnClick}
                download={file.fileName}
                target="_blank"
              >
                <DownloadIcon />
              </IconButton>
            </span>
          </Tooltip>
          {showDeleteAction && (
            <Tooltip title={t("FileListView_Tooltip_Delete")}>
              <span>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  color="error"
                  disabled={disabled}
                  onClick={onClickDelete}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </>
      }
    >
      {downloadUrl && file.fileType === "image/png" && (
        <Box mr={3}>
          <img src={downloadUrl} alt="File Thumbnail" width={50} />
        </Box>
      )}
      <ListItemText
        primaryTypographyProps={{
          gutterBottom: true,
          variant: "subtitle1",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        secondaryTypographyProps={{ variant: "body1" }}
        primary={file.fileName}
        secondary={
          <>
            {(file.fileSizeBytes / 1024).toFixed(2) + " KB"}
            {" • "}
            <RelativeTimestampView
              timestamp={file.createdAt}
              shouldBeInThePast
              hasTooltip
              translation
            />
          </>
        }
      />
    </ListItem>
  );
};

const stopPropagationOnClick: MouseEventHandler<HTMLAnchorElement> = (
  event,
) => {
  event.stopPropagation();
};
