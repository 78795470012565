import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { FC } from "react";

interface Props extends Omit<CircularProgressProps, "variant"> {
  value: number;
  typographyVariant?: TypographyProps["variant"];
}

export const CircularProgressView: FC<Props> = (props) => {
  const { value, typographyVariant, ...restProps } = props;
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" value={value} {...restProps} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant={typographyVariant}
          component="div"
          color="text.secondary"
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};
