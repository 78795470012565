import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { TenderStatus } from "@snubes/snubes-types";
import { StatusColor } from "../../Common/types/StatusColor";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface TenderStatusRecordValue {
  label: TranslationKey;
  color: StatusColor;
  Icon: OverridableComponent<SvgIconTypeMap>;
}

export const TENDER_STATUS_RECORD: Readonly<
  Record<TenderStatus, TenderStatusRecordValue>
> = {
  DRAFT: {
    label: "TenderStatusRecord_Label_Draft",
    color: "default",
    Icon: EditOutlinedIcon,
  },
  WAITING_FOR_REVIEW: {
    label: "TenderStatusRecord_Label_WaitingForReview",
    color: "warning",
    Icon: VisibilityOutlinedIcon,
  },
  PUBLISHED: {
    label: "TenderStatusRecord_Label_Published",
    color: "secondary",
    Icon: TimerOutlinedIcon,
  },
  ENDED: {
    label: "TenderStatusRecord_Label_Ended",
    color: "default",
    Icon: StopCircleOutlinedIcon,
  },
};
