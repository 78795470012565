import { Grid, Paper, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DateTime } from "luxon";
import { FC } from "react";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";

interface Props {
  title: string;
  data: { key: string; value: number }[];
  formatter?: (value: number) => string;
}

export const ProjectChartGridItem: FC<Props> = (props) => {
  const { formatter, title } = props;
  const { palette } = useTheme();

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper>
        <Stack p={3}>{title}</Stack>
        <Stack
          bgcolor="grey.50"
          pt={2}
          sx={{ borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }}
        >
          <ResponsiveContainer width="100%" height={225}>
            <LineChart data={props.data}>
              <Line
                type="monotone"
                dataKey="value"
                stroke={palette.primary.main}
                strokeWidth={3}
                dot={false}
              />
              <XAxis
                dataKey="key"
                axisLine={false}
                tick={{ fontSize: 12, fill: palette.text.primary }}
                ticks={props.data
                  .map((i) => i.key)
                  .filter((key) => key.endsWith("-01"))}
                tickFormatter={(key) => {
                  if (typeof key !== "string") return "";
                  if (!key.endsWith("-01")) return "";
                  return DateTime.fromISO(key).toFormat("LLL");
                }}
              />
              <Tooltip
                content={({ payload, label }) => {
                  const value = payload?.[0]?.value;
                  if (typeof label !== "string") return null;
                  if (typeof value !== "number") return null;
                  return (
                    <Paper>
                      <Stack p={2} spacing={1}>
                        <Typography>
                          {DateTime.fromISO(label).toFormat("dd LLLL yyyy")}
                        </Typography>
                        <Typography color="primary.main">
                          {formatter?.(value) || value}
                        </Typography>
                      </Stack>
                    </Paper>
                  );
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Stack>
      </Paper>
    </Grid>
  );
};
