import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { isProjectQuestion, ProjectQuestion } from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import toast from "react-hot-toast";
import { v4 } from "uuid";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";

const CSV_SEPARATOR = ",";

interface Props {
  questions: ProjectQuestion[];
  onSetProjectQuestions: (questions: ProjectQuestion[]) => void;
}

export const ProjectQuestionsFormHeaderView: FC<Props> = (props) => {
  const t = useT();
  const { questions, onSetProjectQuestions } = props;

  const onClickCopyQuestionsToClipboard = useCallback(async () => {
    if (!questions.length) return;

    console.log(questions);

    const csv = questions
      ?.map((question) =>
        [question.text, question.type]
          .map((value) => JSON.stringify(value))
          .join(CSV_SEPARATOR),
      )
      .join("\n");

    try {
      await navigator.clipboard.writeText(csv);
      toast.success(
        t("ProjectQuestionsFormHeaderView_Toast_CopiedQuestionsToClipboard"),
      );
    } catch (error) {
      handleError(error).log();
    }
  }, [questions, t]);

  const onClickPasteQuestionsFromClipboard = useCallback(async () => {
    try {
      const csv = await navigator.clipboard.readText();
      const parsedQuestions: ProjectQuestion[] = csv
        .trim()
        .split("\n")
        .map((question) => question.trim().split(CSV_SEPARATOR))
        .flatMap(([text, type]) => {
          if (!text || !type) {
            return [];
          }
          const value = {
            id: v4(),
            text: JSON.parse(text) as string,
            type: JSON.parse(type) as string,
          };
          if (isProjectQuestion(value)) {
            return [value];
          }
          return [];
        });

      const uniqueNewQuestions = parsedQuestions.filter((parsedQuestion) =>
        questions.every((question) => question.text !== parsedQuestion.text),
      );

      onSetProjectQuestions([...questions, ...uniqueNewQuestions]);
    } catch (error) {
      handleError(error).log();
    }
  }, [questions, onSetProjectQuestions]);

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between">
      <Typography variant="h3">
        {t("ProjectQuestionsFormHeaderView_Heading_QuestionHeading_Questions")}
      </Typography>

      <Stack direction="row" spacing={1}>
        <Tooltip
          title={t(
            "ProjectQuestionsFormHeaderView_Tooltip_PasteQuestionsFromClipboard",
          )}
        >
          <IconButton onClick={onClickPasteQuestionsFromClipboard}>
            <ContentPasteGoIcon />
          </IconButton>
        </Tooltip>

        {!!questions.length && (
          <Tooltip
            title={t(
              "ProjectQuestionsFormHeaderView_Tooltip_CopyQuestionsToClipboard",
            )}
          >
            <IconButton onClick={onClickCopyQuestionsToClipboard}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};
