import { Call } from "@snubes/snubes-types";
import { useCallback, useEffect, useState } from "react";
import { getVoicefileCallable } from "../callables/getVoicefileCallable";

export function useVoicefileUrl(
  call: Call,
): [
  voicefileUrl: string | undefined,
  isLoading: boolean,
  error: Error | undefined,
] {
  const [isLoading, setIsLoading] = useState(!!call.isVoicefileAvailable);
  const [voicefileUrl, setVoicefileUrl] = useState<string>();
  const [error, setError] = useState<Error>();

  const getVoicefileUrl = useCallback(async () => {
    try {
      if (!call.isVoicefileAvailable) return;
      setIsLoading(true);

      const { url } = await getVoicefileCallable({
        callId: call.id,
      });

      setVoicefileUrl(url);
      setError(undefined);
    } catch (e) {
      const error = e instanceof Error ? e : new Error(String(e));
      setVoicefileUrl(undefined);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [call.isVoicefileAvailable, call.id]);

  useEffect(() => {
    void getVoicefileUrl();
  }, [getVoicefileUrl]);

  return [voicefileUrl, isLoading, error];
}
