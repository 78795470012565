import { useCallback } from "react";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { FEATURE_FLAG_DEFAULTS } from "../consts/FEATURE_FLAG_DEFAULTS";
import { AdminFeatureFlag } from "../types/AdminFeatureFlag";

export function useIsFeatureFlagEnabled() {
  const userRole = useMyUser((state) => state.user?.role);
  const featureFlags = useLocalStorage((state) => state.data.featureFlags);

  return useCallback(
    (featureFlag: AdminFeatureFlag): boolean => {
      if (!FEATURE_FLAG_DEFAULTS[featureFlag] && userRole !== "USER_ADMIN") {
        return false;
      }

      return featureFlags[featureFlag] ?? FEATURE_FLAG_DEFAULTS[featureFlag];
    },
    [featureFlags, userRole],
  );
}
