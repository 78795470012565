import i18nLanguages from "@cospired/i18n-iso-languages";
import { Language, LANGUAGES } from "@snubes/snubes-types";
import { ReactNode, useMemo } from "react";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { LANGUAGE_RECORD } from "../../Common/consts/LANGUAGE_RECORD";
import { getCurrentLocale } from "../../Translation/helpers/getCurrentLocale";
import { ControlledSelect } from "./ControlledSelect";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues & Record<TName, Language | "">>;
  name: TName;
  label?: ReactNode;
  required?: boolean;
  emptyLabel?: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

export function ControlledLanguageSelect<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const currentLanguage = getCurrentLocale();

  const items = useMemo(
    () => [
      ...LANGUAGES.map((language) => {
        const translation =
          i18nLanguages.getName(language, currentLanguage) || language;

        return {
          value: language,
          label: `${LANGUAGE_RECORD[language].emoji} ${translation}`,
          sortLabel: translation,
        };
      }).sort((a, b) => a.sortLabel.localeCompare(b.sortLabel)),
    ],
    [currentLanguage],
  );

  return (
    <ControlledSelect
      label={props.label}
      control={props.control}
      name={props.name}
      items={items}
      renderItem={(item) => item.label}
      emptyLabel={props.emptyLabel}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
    />
  );
}
