import { Timestamp } from "@snubes/snubes-types";
import { DateTime } from "luxon";

export function getDiffInSeconds(
  timestamp: Timestamp | string,
  shouldBeInThePast: boolean,
) {
  const dateTime =
    typeof timestamp === "string"
      ? DateTime.fromISO(timestamp)
      : DateTime.fromMillis(timestamp.toMillis());
  const seconds = Math.floor(dateTime.diffNow("seconds").seconds);

  return shouldBeInThePast
    ? Math.abs(Math.min(seconds, 0))
    : Math.max(seconds, 0);
}
