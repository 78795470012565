import { Badge, SxProps, Tab, Tabs, TabsProps } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { useLocation } from "react-router-dom";
import { useT } from "../../Translation/hooks/useT";
import { NavTab } from "../types/NavTab";

interface Props extends PropsWithChildren {
  tabs: NavTab[];
  pathPrefix: string;
  sx?: SxProps;
  orientation?: TabsProps["orientation"];
}

export const NavTabs: FC<Props> = (props) => {
  const { tabs, pathPrefix, sx, orientation } = props;
  const { pathname } = useLocation();
  const t = useT();

  const activeTab = tabs?.find((t) =>
    pathname.startsWith(`${pathPrefix}/${t.route}`),
  );

  if (!activeTab) return null;

  return (
    <Tabs sx={sx} value={activeTab.route} orientation={orientation}>
      {tabs
        .filter((t) => !t.isHidden)
        .map((tab) => (
          <Tab
            key={tab.route}
            sx={{
              color: "text.secondary",
              textTransform: "none",
              alignItems: "flex-start",
              maxWidth: "initial",
            }}
            href={`${pathPrefix}/${tab.route}`}
            value={tab.route}
            label={
              <span>
                {t(tab.label)}
                {!!tab.badgeContent && (
                  <Badge
                    badgeContent={tab.badgeContent}
                    color="primary"
                    sx={{ position: "fixed", mt: -1, ml: 2 }}
                  />
                )}
              </span>
            }
            disabled={tab.isDisabled}
          />
        ))}
    </Tabs>
  );
};
