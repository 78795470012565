import { TextFieldProps } from "@mui/material";
import { DateField } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { FC } from "react";

interface Props {
  label: string;
  value: DateTime | null;
  onChange: (date: DateTime) => void;
  align: "startOfDay" | "endOfDay";
  shouldDisableDate?: (date: DateTime) => boolean;
  required?: TextFieldProps["required"];
  autoFocus?: TextFieldProps["autoFocus"];
}

export const ProjectDatePicker: FC<Props> = (props) => {
  return (
    <DateField
      label={props.label}
      value={props.value}
      shouldDisableDate={props.shouldDisableDate}
      onChange={(date) => {
        if (date?.isValid) {
          props.onChange(
            props.align === "startOfDay"
              ? date.startOf("day").toUTC()
              : date.endOf("day").toUTC(),
          );
        }
      }}
      slotProps={{
        textField: {
          sx: {
            maxWidth: 120,
          },
          variant: "standard",
          required: props.required,
          autoFocus: props.autoFocus,
          autoComplete: "off",
        },
      }}
    />
  );
};
