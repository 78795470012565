import {
  Chip,
  ChipProps,
  CircularProgress,
  Stack,
  SvgIconTypeMap,
  SxProps,
  Theme,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC, forwardRef, ReactNode } from "react";
import { STATUS_COLOR_RECORD } from "../consts/STATUS_COLOR_RECORD";
import { StatusColor } from "../types/StatusColor";

interface Props {
  color?: StatusColor;
  label: ReactNode;
  Icon?: OverridableComponent<SvgIconTypeMap>;
  isLoading?: boolean;
  size?: ChipProps["size"];
  sx?: Exclude<SxProps<Theme>, ReadonlyArray<unknown>>;
}

export const StatusChip: FC<Props> = forwardRef<HTMLDivElement, Props>(
  function StatusChip(props, ref) {
    const {
      color = "default",
      label,
      Icon,
      isLoading,
      size = "small",
      sx,
      ...restProps
    } = props;

    return (
      <Chip
        ref={ref}
        {...restProps}
        size={size}
        icon={
          isLoading ? (
            <Stack pl={0.5} pr={1}>
              <CircularProgress size={12} />
            </Stack>
          ) : (
            Icon && <Icon />
          )
        }
        label={label}
        sx={[
          () => ({
            color: STATUS_COLOR_RECORD[color].color,
            backgroundColor: STATUS_COLOR_RECORD[color].backgroundColor,
            padding: 1.5, // TODO: use theme instead of absolute values
            fontSize: 12,
            fontWeight: 700,
            height: 26,
            borderRadius: 60,
            cursor: "inherit",
            "& .MuiChip-icon": {
              color: "inherit",
            },
          }),
          sx || {},
        ]}
      />
    );
  },
);

StatusChip.displayName = "StatusChip";
