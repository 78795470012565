import { Container, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { array, string, type } from "superstruct";
import { ControlledLanguagesAutocomplete } from "../../Form/views/ControlledLanguagesAutocomplete";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  languages: array(string()),
});

export const TenderWizardLanguagesForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => ({
      languages: tender?.languages || [],
    }),
    toDoc: (formValues, tender) => ({
      languages: formValues.languages?.length
        ? formValues.languages
        : undefined,
      isReviewed: {
        ...tender?.isReviewed,
        basicDetails: false,
      },
    }),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="sm">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardLanguagesForm_Title_Languages")}
        </Typography>
        <Typography variant="body1" color="text.500" mb={4}>
          {t("TenderWizardLanguagesForm_Paragraph_Languages")}
        </Typography>
        <Stack spacing={3}>
          <ControlledLanguagesAutocomplete
            control={control}
            name="languages"
            label={t("TenderWizardLanguagesForm_Label_Languages")}
            multiple
            required
            fullWidth
          />
        </Stack>
      </Container>
    </form>
  );
};
