import PersonIcon from "@mui/icons-material/Person";
import { Avatar, AvatarGroup, Button, Stack, Typography } from "@mui/material";
import { Member } from "@snubes/snubes-types";
import { FC, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { updateMemberCallable } from "../../Members/callables/updateMemberCallable";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";
import { HomePageInfoPanelCard } from "./HomePageInfoPanelCard";

interface Props {
  titleText: TranslationKey;
  subtitleText1: TranslationKey;
  subtitleText2: TranslationKey;
  buttonTextPrimary: TranslationKey;
  buttonTextSecondary: TranslationKey;
  member: Member;
}

export const HomeInviteMembersCardView: FC<Props> = (props) => {
  const {
    subtitleText1,
    subtitleText2,
    titleText,
    buttonTextPrimary,
    buttonTextSecondary,
    member,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const t = useT();

  const organizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );

  const onClickCompleteInvitationStep = async () => {
    try {
      setIsSubmitting(true);
      if (!organizationId) {
        return false;
      }
      if (!member?.id) {
        return false;
      }
      await updateMemberCallable({
        organizationId,
        memberId: member?.id,
        member: { tasks: { isTeamMemberInvited: true } },
      });
    } catch (error) {
      handleError(error).logAnd().toast();
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <HomePageInfoPanelCard isOutlined>
      <Stack alignItems="flex-start">
        <AvatarGroup
          spacing="medium"
          max={3}
          sx={(theme) => ({
            "& .MuiAvatar-root": {
              backgroundColor: theme.palette.text["500"],
            },
          })}
        >
          {new Array(5).fill(null).map((_, i) => (
            <Avatar key={i}>
              <PersonIcon />
            </Avatar>
          ))}
        </AvatarGroup>
      </Stack>
      <Typography variant="subtitle2" color="text.main">
        {t(subtitleText2)}
      </Typography>
      <Stack spacing={2}>
        <Typography variant="h3" color="text.main">
          {t(titleText)}
        </Typography>
        <Typography variant="subtitle1" color="text.main">
          {t(subtitleText1)}
        </Typography>
      </Stack>
      <Stack spacing={3} direction="row" justifyContent={"flex-start "}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          href={`/organizations/${organizationId}/members`}
        >
          {t(buttonTextPrimary)}
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={onClickCompleteInvitationStep}
          disabled={isSubmitting || member?.tasks?.isTeamMemberInvited}
          color="primary"
        >
          {t(buttonTextSecondary)}
        </Button>
      </Stack>
    </HomePageInfoPanelCard>
  );
};
