import { Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { T } from "../../Translation/views/T";
import { AdminUsersCollectionTableView } from "./AdminUsersCollectionTableView";

export const AdminUsersPage: FC = () => {
  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer>
        <Stack py={5} spacing={3}>
          <Typography variant="h2">
            <T k="AdminUsersPage_Headline" />
          </Typography>
          <Paper>
            <AdminUsersCollectionTableView
              filters={[["isDeleted", "==", false]]}
            />
          </Paper>
        </Stack>
      </FullHeightContainer>
    </>
  );
};
