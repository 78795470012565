import { MemberRole } from "@snubes/snubes-types";
import { FC } from "react";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";
import { MEMBER_ROLE_RECORD } from "../consts/MEMBER_ROLE_RECORD";

interface Props {
  role: MemberRole;
}

export const MemberRoleChip: FC<Props> = (props: Props) => {
  const { role } = props;
  const t = useT();
  const { color, Icon, label } = MEMBER_ROLE_RECORD[role];

  return <StatusChip color={color} label={t(label)} Icon={Icon} />;
};
