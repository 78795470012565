import {
  Box,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Provider, ServiceCategory } from "@snubes/snubes-types";
import { FC } from "react";
import { TENDER_SERVICE_CATEGORY_RECORD } from "../../Tenders/consts/TENDER_SERVICE_CATEGORY_RECORD";
import { useT } from "../../Translation/hooks/useT";
import { PROVIDER_PRICING_MODEL } from "../consts/PROVIDER_PRICING_MODEL";

interface Props {
  provider: Partial<Provider>;
}

export const ProviderPricingModelsView: FC<Props> = (props) => {
  const { provider } = props;
  const t = useT();

  if (!provider.pricingModelsByServiceCategory) {
    return null;
  }
  return (
    <Stack>
      {Object.entries(provider.pricingModelsByServiceCategory).map(
        ([category, models]) => (
          <Box key={category}>
            <Typography variant="body2">
              {t(
                TENDER_SERVICE_CATEGORY_RECORD[category as ServiceCategory]
                  .label,
              )}
            </Typography>
            <List>
              {models.map((model) => (
                <ListItem disablePadding key={model}>
                  <ListItemText disableTypography>
                    <Typography variant="body1">
                      {t(PROVIDER_PRICING_MODEL[model].label)}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        ),
      )}
    </Stack>
  );
};
