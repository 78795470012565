import { Timestamp } from "firebase/firestore";
import { ReactNode } from "react";
import { RelativeTimestampView } from "../../Common/views/RelativeTimestampView";

interface Props<T> {
  row: T;
  renderCell?: (row: T) => ReactNode;
  field?: keyof T;
}

export function CollectionTableCellContentView<T>(props: Props<T>) {
  if (props.renderCell) {
    return <>{props.renderCell(props.row)}</>;
  }

  if (props.field) {
    const value = props.row[props.field];

    if (value instanceof Timestamp) {
      return (
        <RelativeTimestampView
          timestamp={value}
          shouldBeInThePast
          translation
        />
      );
    }

    return <>{value !== undefined && value !== null ? String(value) : "-"}</>;
  }

  return null;
}
