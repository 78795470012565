import { FC, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../Firebase/consts/FIREBASE";
import { setUser } from "../../Firebase/helpers/setUser";
import { useMyMember } from "../../Members/hooks/useMyMember";
import { getSelectedOrganizationId } from "../../Organizations/helpers/getSelectedOrganizationId";
import { useMyOrganizations } from "../../Organizations/hooks/useMyOrganizations";
import { useSelectedLocale } from "../../Translation/hooks/useSelectedLocale";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { useSetUserSelectedLocale } from "../../Users/hooks/useSetUserSelectedLocale";
import { useAuthInit } from "../hooks/useAuthInit";

export const AuthInitView: FC = () => {
  const [authState, isInitializingAuthState] = useAuthState(auth);
  const isInitializingMyOrganization = useMyOrganizations(
    (state) => state.isInitializing,
  );
  const isInitializingMyMember = useMyMember((state) => state.isInitializing);
  const userId = authState?.uid;
  const isInitializingMyUser = useMyUser((state) => state.isInitializing);
  const organizationIdsString = useMyUser(
    (state) => state.user?.organizationIds.join(","),
  );
  const selectedOrganizationId = useMyUser((state) =>
    getSelectedOrganizationId(state.user),
  );
  const selectedLocale = useSelectedLocale();
  const setUserSelectedLocale = useSetUserSelectedLocale();

  // User ID
  useEffect(() => {
    if (userId) {
      useMyUser.getState().subscribe(userId);
      setUser({ userId });
    }

    return () => {
      useMyUser.getState().unsubscribe?.();
    };
  }, [userId]);

  // Organization IDs
  useEffect(() => {
    if (organizationIdsString) {
      const organizationIds = [
        ...new Set([
          ...organizationIdsString.split(","),
          ...(selectedOrganizationId ? [selectedOrganizationId] : []),
        ]),
      ];
      useMyOrganizations.getState().subscribe(organizationIds);
    }

    return () => {
      useMyOrganizations.getState().unsubscribe?.();
    };
  }, [organizationIdsString, selectedOrganizationId]);

  // Selected Organization ID and User ID
  useEffect(() => {
    if (selectedOrganizationId && userId) {
      useMyMember.getState().subscribe({
        organizationId: selectedOrganizationId,
        userId: userId,
      });
    }
    return () => {
      useMyMember.getState().unsubscribe?.();
    };
  }, [selectedOrganizationId, userId]);

  // Set selected user language
  useEffect(() => {
    if (selectedLocale && userId) {
      void setUserSelectedLocale(selectedLocale);
    }
  }, [selectedLocale, setUserSelectedLocale, userId]);

  // Is Initializing
  const isInitializing =
    isInitializingAuthState ||
    (userId ? isInitializingMyUser : false) ||
    (organizationIdsString?.length ? isInitializingMyOrganization : false) ||
    (selectedOrganizationId && userId)
      ? isInitializingMyMember
      : false;

  useEffect(() => {
    useAuthInit.getState().setIsInitializing(isInitializing);
  }, [isInitializing]);

  return null;
};
