import { Language } from "@snubes/snubes-types";

interface LanguageRecordValue {
  emoji: string;
}

export const LANGUAGE_RECORD: Record<Language, LanguageRecordValue> = {
  de: {
    emoji: "🇩🇪",
  },
  en: {
    emoji: "🇬🇧",
  },
  es: {
    emoji: "🇪🇸",
  },
  fr: {
    emoji: "🇫🇷",
  },
  it: {
    emoji: "🇮🇹",
  },
  nl: {
    emoji: "🇳🇱",
  },
  sv: {
    emoji: "🇸🇪",
  },
};
