import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ProviderStatus } from "@snubes/snubes-types";
import { StatusColor } from "../../Common/types/StatusColor";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface ProviderStatusRecordValue {
  label: TranslationKey;
  color: StatusColor;
  Icon: OverridableComponent<SvgIconTypeMap>;
}

export const PROVIDER_STATUS_RECORD: Readonly<
  Record<ProviderStatus, ProviderStatusRecordValue>
> = {
  DRAFT: {
    label: "ProviderStatusRecord_Label_Draft",
    color: "default",
    Icon: EditOutlinedIcon,
  },
  PUBLISHED: {
    label: "ProviderStatusRecord_Label_Published",
    color: "secondary",
    Icon: CheckCircleOutlineOutlinedIcon,
  },
};
