import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { UserType } from "@snubes/snubes-types";
import { FC, MouseEvent, useCallback } from "react";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { useSetUserType } from "../../Users/hooks/useSetUserType";

interface Props {}

export const NotificationsAdminToggleButton: FC<Props> = () => {
  const currentUserType = useMyUser((state) => state.user?.userType || "");
  const organizationType = useSelectedOrganization(
    (state) => state?.types?.[0] || "",
  );
  const [setSelectedUserType, isLoadingSelectedUserType] = useSetUserType();

  const onChangeUserType = useCallback(
    (_: MouseEvent<HTMLElement>, selectedUserType: UserType | "" | null) => {
      if (!isLoadingSelectedUserType && selectedUserType) {
        void setSelectedUserType(selectedUserType);
      }
    },
    [isLoadingSelectedUserType, setSelectedUserType],
  );

  return (
    <ToggleButtonGroup
      color="primary"
      size="small"
      value={currentUserType}
      exclusive
      onChange={onChangeUserType}
    >
      <ToggleButton value="ADMIN" sx={{ textTransform: "inherit" }}>
        Global notifications
      </ToggleButton>
      <ToggleButton value={organizationType} sx={{ textTransform: "inherit" }}>
        Selected organization
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
