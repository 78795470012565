import { FormControlProps } from "@mui/material";
import isoCountries from "i18n-iso-countries";
import { ReactNode, useMemo } from "react";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { getCountryName } from "../../Common/helpers/getCountryName";
import { ControlledAutocomplete } from "./ControlledAutocomplete";

// register english and german as we support both on our platform

interface Props<TFields extends FieldValues> {
  control: Control<TFields>;
  name: FieldPath<TFields>;
  label?: ReactNode;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  size?: FormControlProps["size"];
  multiple?: boolean;
}

export function ControlledCountriesAutocomplete<TFields extends FieldValues>(
  props: Props<TFields>,
) {
  // sorted list of country codes by localized translation of country names
  const countryOptions = useMemo(() => {
    return Object.keys(isoCountries.getAlpha2Codes())
      .map((code) => {
        const name = getCountryName(code);
        return { name, code };
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ code }) => code);
  }, []);

  return (
    <ControlledAutocomplete
      control={props.control}
      name={props.name}
      label={props.label}
      options={countryOptions}
      getOptionLabel={(option) => getCountryName(option)}
      multiple={props.multiple}
      required={props.required}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      placeholder={props.placeholder}
      helperText={props.helperText}
      size={props.size}
    />
  );
}
