import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import {
  CALL_FLAGGING_PARAMETER_OPERATORS,
  CallFlaggingConfigParameterOperator,
  Project,
} from "@snubes/snubes-types";
import {
  CallFlaggingConfigParameter,
  CallFlaggingConfigQuestionAnswerParameter,
} from "@snubes/snubes-types/types/CallFlaggingConfigParameter";
import {
  CALL_FLAGGING_PARAMETER_TYPES,
  CallFlaggingConfigParameterType,
} from "@snubes/snubes-types/types/CallFlaggingConfigParameterType";
import { useCallback, useMemo } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { CALL_FLAGGING_CONFIG_PARAMETER_OPERATOR_RECORD } from "../../Calls/consts/CALL_FLAGGING_CONFIG_PARAMETER_OPERATOR_RECORD";
import { CALL_FLAGGING_CONFIG_PARAMETER_TYPE_RECORD } from "../../Calls/consts/CALL_FLAGGING_CONFIG_PARAMETER_TYPE_RECORD";
import { CallFlaggingConfigParameterValueFieldType } from "../../Calls/types/CallFlaggingConfigParameterValueFieldType";
import { useT } from "../../Translation/hooks/useT";
import { PROJECT_QUESTION_RESPONSE_TYPE_RECORD } from "../consts/PROJECT_QUESTION_RESPONSE_TYPE_RECORD";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues & Record<TName, CallFlaggingConfigParameter[]>>;
  name: TName;
  disabled?: boolean;
  remove: () => void;
  project: Project;
}

export function ControlledCallFlaggingConfigParameter<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const t = useT();

  const parameterOptions = useMemo(() => {
    return CALL_FLAGGING_PARAMETER_TYPES.filter((type) => {
      if (type === "QUESTION_ANSWER") {
        return !!props.project.questions?.length;
      }
      if (type === "CATEGORY") {
        return !!props.project.callCategories?.length;
      }
      return true;
    }).map((parameter) => ({
      label: CALL_FLAGGING_CONFIG_PARAMETER_TYPE_RECORD[parameter].label,
      value: parameter,
    }));
  }, [props.project.questions?.length, props.project.callCategories?.length]);

  const getCallQuestionAnswerTypeRecordValue = useCallback(
    (parameter: CallFlaggingConfigQuestionAnswerParameter) => {
      const type = props.project.questions?.find(
        (question) => question.id === parameter.questionId,
      )?.type;
      if (type) {
        return PROJECT_QUESTION_RESPONSE_TYPE_RECORD[type];
      }
    },
    [props.project.questions],
  );

  const getAvailableOperators = useCallback(
    (
      parameter: CallFlaggingConfigParameter,
    ): CallFlaggingConfigParameterOperator[] => {
      if (parameter.type === "QUESTION_ANSWER") {
        const operators =
          getCallQuestionAnswerTypeRecordValue(parameter)
            ?.callFlaggingParameterOperators;
        if (operators) return operators;
      }
      return (
        CALL_FLAGGING_CONFIG_PARAMETER_TYPE_RECORD[parameter.type]
          ?.availableOperators || CALL_FLAGGING_PARAMETER_OPERATORS
      );
    },
    [getCallQuestionAnswerTypeRecordValue],
  );

  const getAvailableValues = useCallback(
    (parameter: CallFlaggingConfigParameter) => {
      if (parameter.type === "CATEGORY") {
        return (
          props.project.callCategories?.map((category) => ({
            value: category.id,
            label: category.name,
          })) || []
        );
      }

      let availableValues;
      if (parameter.type === "QUESTION_ANSWER") {
        availableValues =
          getCallQuestionAnswerTypeRecordValue(parameter)
            ?.callFlaggingParameterAvailableValues;
      } else {
        availableValues =
          CALL_FLAGGING_CONFIG_PARAMETER_TYPE_RECORD[parameter.type]
            ?.availableValues;
      }
      return (availableValues || []).map((item) => ({
        value: item.value,
        label: t(item.label),
      }));
    },
    [props.project.callCategories, getCallQuestionAnswerTypeRecordValue, t],
  );

  const getValueFieldType = useCallback(
    (
      parameter: CallFlaggingConfigParameter,
    ): CallFlaggingConfigParameterValueFieldType => {
      if (parameter.type === "QUESTION_ANSWER") {
        const type =
          getCallQuestionAnswerTypeRecordValue(parameter)
            ?.callFlaggingParameterValueFieldType;
        if (type) return type;
      }
      // For new form items, parameter.type can be an empty string.
      return (
        CALL_FLAGGING_CONFIG_PARAMETER_TYPE_RECORD[parameter.type]
          ?.valueFieldType || "none"
      );
    },
    [getCallQuestionAnswerTypeRecordValue],
  );

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => {
        const parameter = field.value as CallFlaggingConfigParameter;
        const unitLabel =
          CALL_FLAGGING_CONFIG_PARAMETER_TYPE_RECORD[parameter.type]?.unitLabel;

        const operatorOptions = getAvailableOperators(parameter).map(
          (operator) => ({
            label:
              CALL_FLAGGING_CONFIG_PARAMETER_OPERATOR_RECORD[operator].label,
            value: operator,
          }),
        );
        const valueFieldType = getValueFieldType(parameter);
        const valueOptions = getAvailableValues(parameter).map((item) => ({
          label: item.label,
          value: item.value,
        }));

        return (
          <Stack direction="row" spacing={3}>
            <FormControl sx={{ flex: 1 }} required>
              <InputLabel id={`parameter-select-label-${props.name}`}>
                {t("ProjectCallFlaggingConfigsForm_Label_Parameter")}
              </InputLabel>
              <Select
                label={t("ProjectCallFlaggingConfigsForm_Label_Parameter")}
                labelId={`parameter-select-label-${props.name}`}
                value={parameter.type}
                onChange={(e) => {
                  const type = e.target
                    .value as CallFlaggingConfigParameterType;
                  const operator = getAvailableOperators(parameter).includes(
                    parameter.operator,
                  )
                    ? parameter.operator
                    : "";

                  field.onChange({
                    ...parameter,
                    type,
                    operator,
                    value: "",
                  });
                }}
                disabled={!!props.disabled}
              >
                {parameterOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {t(label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {parameter.type === "QUESTION_ANSWER" &&
              props.project.questions && (
                <FormControl sx={{ flex: 1 }} required>
                  <InputLabel id={`question-select-label-${props.name}`}>
                    {t("ProjectCallFlaggingConfigsForm_Label_Question")}
                  </InputLabel>
                  <Select
                    label={t("ProjectCallFlaggingConfigsForm_Label_Question")}
                    labelId={`question-select-label-${props.name}`}
                    value={parameter.questionId || ""}
                    onChange={(e) => {
                      const question = props.project.questions?.find(
                        (q) => q.id === e.target.value,
                      );
                      // This check is just for type safety. The question should always exist.
                      if (!question) {
                        return;
                      }
                      field.onChange({
                        ...parameter,
                        questionId: question.id,
                        questionRef: question.ref,
                      } satisfies CallFlaggingConfigQuestionAnswerParameter);
                    }}
                    disabled={!!props.disabled}
                  >
                    {props.project.questions.map((question) => (
                      <MenuItem key={question.id} value={question.id}>
                        {question.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

            <FormControl sx={{ flex: 1 }} required>
              <InputLabel id={`operator-select-label-${props.name}`}>
                {t("ProjectCallFlaggingConfigsForm_Label_Operator")}
              </InputLabel>
              <Select
                label={t("ProjectCallFlaggingConfigsForm_Label_Operator")}
                labelId={`operator-select-label-${props.name}`}
                value={parameter.operator}
                onChange={(e) => {
                  const operator = e.target
                    .value as CallFlaggingConfigParameterType;
                  field.onChange({ ...parameter, operator });
                }}
                disabled={!!props.disabled}
              >
                {operatorOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {t(label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {valueFieldType === "select" && (
              <FormControl sx={{ flex: 1 }} required>
                <InputLabel id={`value-select-label-${props.name}`}>
                  {t("ProjectCallFlaggingConfigsForm_Label_Value")}
                </InputLabel>
                <Select
                  label={t("ProjectCallFlaggingConfigsForm_Label_Value")}
                  labelId={`value-select-label-${props.name}`}
                  value={parameter.value}
                  onChange={(e) => {
                    const value = e.target.value as string;
                    field.onChange({ ...parameter, value });
                  }}
                  disabled={!!props.disabled}
                >
                  {valueOptions.map(({ label, value }) => (
                    <MenuItem key={String(value)} value={value as string}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {["text", "number"].includes(valueFieldType) && (
              <TextField
                label={t("ProjectCallFlaggingConfigsForm_Label_Value")}
                type={valueFieldType}
                value={parameter.value}
                onChange={(e) => {
                  const value =
                    valueFieldType === "number"
                      ? e.target.value === ""
                        ? ""
                        : Number(e.target.value)
                      : e.target.value;

                  field.onChange({ ...parameter, value });
                }}
                InputProps={{
                  ...(unitLabel && {
                    endAdornment: (
                      <InputAdornment position="end">
                        {t(unitLabel)}
                      </InputAdornment>
                    ),
                  }),
                }}
                sx={{ flex: 1 }}
                disabled={!!props.disabled}
                required
              />
            )}

            {["none", undefined].includes(valueFieldType) && <Stack flex={1} />}

            <Stack justifyContent="center">
              <IconButton
                color="error"
                disabled={!!props.disabled}
                onClick={props.remove}
              >
                <DeleteForeverIcon />
              </IconButton>
            </Stack>
          </Stack>
        );
      }}
    />
  );
}
