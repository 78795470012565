import { isStruct } from "@snubes/snubes-types";
import { Infer, string, Struct } from "superstruct";

export const UrlOrDomainStruct = new Struct({
  ...string(),
  type: "UrlOrDomain",
  validator: (value) => {
    return !!parseUrlOrDomain(value);
  },
  coercer: (value) => {
    const url = parseUrlOrDomain(value);
    if (url) {
      return url.origin;
    }
    return null;
  },
});

function parseUrlOrDomain(value: unknown): URL | null {
  if (typeof value !== "string" || !value.trim()) {
    return null;
  }
  const [, , , domainName = "", tld = ""] =
    /^(https?:\/\/)?(www\.)?([^\s/:]+)(\.[a-z]+)$/.exec(
      value.trim().toLowerCase(),
    ) || [];
  if (domainName === "www") {
    return null;
  }
  try {
    return new URL(`https://${domainName}${tld}`);
  } catch {
    return null;
  }
}

export type UrlOrDomain = Infer<typeof UrlOrDomainStruct>;

export function isUrlOrDomain(obj: unknown): obj is UrlOrDomain {
  return isStruct(obj, UrlOrDomainStruct, "UrlOrDomain");
}
