import { CallFlaggingConfigCondition } from "@snubes/snubes-types/types/CallFlaggingConfigCondition";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface ProjectCallFlaggingConditionTypeRecordValue {
  label: TranslationKey;
  operatorLabel: TranslationKey;
}

export const PROJECT_CALL_FLAGGING_CONDITION_RECORD: Record<
  CallFlaggingConfigCondition,
  ProjectCallFlaggingConditionTypeRecordValue
> = {
  ALL: {
    label: "ProjectCallFlaggingConfigsForm_ConditionAll_Label",
    operatorLabel: "ProjectCallFlaggingConfigsForm_ConditionAll_OperatorLabel",
  },
  ANY: {
    label: "ProjectCallFlaggingConfigsForm_ConditionAny_Label",
    operatorLabel: "ProjectCallFlaggingConfigsForm_ConditionAny_OperatorLabel",
  },
};
