import { ConnectorConfig } from "@snubes/snubes-types";
import { createContext, useContext } from "react";

interface ConnectorConfigContext {
  connectorConfig?: ConnectorConfig;
}
const connectorConfigContextContext = createContext<ConnectorConfigContext>({});

export const ConnectorConfigContextProvider =
  connectorConfigContextContext.Provider;

export const useConnectorConfigContext = () => {
  return useContext(connectorConfigContextContext);
};
