import { Timestamp } from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC, useMemo } from "react";

interface Props {
  date: Timestamp | string;
  variant?: "date" | "dateTime";
}

export const DateTimeView: FC<Props> = (props) => {
  const { date, variant = "dateTime" } = props;
  const element = useMemo(() => {
    const dateTime =
      typeof date === "string"
        ? DateTime.fromISO(date)
        : DateTime.fromJSDate(date.toDate());

    return dateTime.toLocaleString({
      day: "numeric",
      month: "short",
      year: "numeric",
      ...(variant === "dateTime" && {
        hour: "numeric",
        minute: "numeric",
      }),
    });
  }, [date, variant]);
  return <>{element}</>;
};
