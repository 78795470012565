import {
  FirebaseFilter,
  isOrganization,
  Organization,
} from "@snubes/snubes-types";
import { query, where } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { toQueryWhereArgs } from "../../Firebase/helpers/toQueryWhereArgs";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export function useOrganizations(filters: FirebaseFilter<Organization>[]) {
  return useValidCollectionData(
    query(
      getCollectionRef("organizations"),
      ...filters.map((filter) => where(...toQueryWhereArgs(filter))),
    ),
    isOrganization,
  );
}
