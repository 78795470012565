import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, Box, Hidden, IconButton, ListItemButton } from "@mui/material";
import { Stack } from "@mui/system";
import { FC, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useAppNavigationItems } from "../../Application/hooks/useAppNavigationItems";
import { useAppSidebar } from "../../Application/hooks/useAppSidebar";
import { AppSidebarView } from "../../Application/views/AppSidebarView";
import { useMediaQueryUp } from "../../Common/hooks/useMediaQueryUp";
import { useMyInvitations } from "../../Invitations/hooks/useMyInvitations";
import { useNotifications } from "../../Notifications/hooks/useNotifications";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { useSetUserSelectedOrganizationId } from "../../Users/hooks/useSetUserSelectedOrganizationId";
import { useSetUserType } from "../../Users/hooks/useSetUserType";
import { HEADER_HEIGHT } from "../consts/HEADER_HEIGHT";
import { HeaderLanguageMenuButton } from "./HeaderLanguageMenuButton";
import { HeaderLanguageMenuView } from "./HeaderLanguageMenuView";
import { HeaderNavLink } from "./HeaderNavLink";
import { HeaderNotificationMenuView } from "./HeaderNotificationMenuView";
import { HeaderOrganizationLogoView } from "./HeaderOrganizationLogoView";
import { HeaderOrganizationMenuButton } from "./HeaderOrganizationMenuButton";
import { HeaderOrganizationMenuView } from "./HeaderOrganizationMenuView";
import { HeaderSpacerView } from "./HeaderSpacerView";
import { HeaderUserMenuButton } from "./HeaderUserMenuButton";
import { HeaderUserMenuView } from "./HeaderUserMenuView";

export const HeaderWithNavigationView: FC = () => {
  const isUpMd = useMediaQueryUp("md");
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] =
    useState<HTMLElement>();
  const [organizationMenuAnchorEl, setOrganizationMenuAnchorEl] =
    useState<HTMLElement>();
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<HTMLElement>();
  const [notificationMenuAnchorEl, setNotificationMenuAnchorEl] =
    useState<HTMLElement>();
  const userId = useMyUser((state) => state.user?.id || null);
  const userType = useMyUser((state) => state.user?.userType || null);
  const organizationId = useMyUser(
    (state) => state.user?.selectedOrganizationId || null,
  );
  const items = useAppNavigationItems();

  const [invitations, , , invitationsCount] = useMyInvitations({
    limit: 10,
    withCount: true,
  });

  const notificationsArgs = useMemo(() => {
    if (userType === "ADMIN") {
      return {
        userId,
      };
    } else {
      return {
        organizationId,
        userId,
      };
    }
  }, [organizationId, userId, userType]);

  const [notifications, , , unreadNotificationsCount] = useNotifications({
    ...notificationsArgs,
    filters: [["hasBeenRead", "==", false]],
    limit: 10,
    withCount: true,
  });

  const totalCount = invitationsCount + unreadNotificationsCount;
  const notificationsCount =
    totalCount === 0 ? undefined : totalCount > 99 ? "99+" : String(totalCount);

  const [setUserSelectedOrganizationId, isLoadingUserSelectedOrganizationId] =
    useSetUserSelectedOrganizationId();

  const [setSelectedUserType, isLoadingSelectedUserType] = useSetUserType();

  return (
    <>
      <Stack
        position="fixed"
        direction="row"
        alignItems="center"
        top={0}
        left={0}
        right={0}
        height={HEADER_HEIGHT}
        bgcolor="background.paper"
        pl={3}
        zIndex={5}
        borderBottom={1}
        borderColor="divider"
      >
        {!isUpMd && (
          <IconButton
            color="inherit"
            sx={{ mr: 2 }}
            onClick={() =>
              useAppSidebar
                .getState()
                .setIsMobileOpen(!useAppSidebar.getState().isMobileOpen)
            }
          >
            <MenuIcon />
          </IconButton>
        )}
        {isUpMd && (
          <Stack direction="row" flex={1} alignItems="center">
            <Box mr={3} mt="1px" component={Link} display="flex" to="/">
              <HeaderOrganizationLogoView />
            </Box>

            {items
              .filter((i) => !i.isHidden)
              .map((item) => (
                <HeaderNavLink {...item} key={`${item.title}-header`} />
              ))}
          </Stack>
        )}
        {!isUpMd && <Stack flex={1} />}
        <Stack
          direction="row"
          alignSelf="stretch"
          sx={{
            "> .MuiButtonBase-root": {
              borderLeft: 1,
              borderColor: "divider",
            },
          }}
        >
          <HeaderLanguageMenuButton
            onSetLanguageMenuAnchorEl={setLanguageMenuAnchorEl}
          />
          <Hidden smDown>
            <HeaderOrganizationMenuButton
              onSetOrganizationMenuAnchorEl={setOrganizationMenuAnchorEl}
              isLoading={
                isLoadingUserSelectedOrganizationId || isLoadingSelectedUserType
              }
            />
          </Hidden>

          <ListItemButton
            sx={{ px: 4 }}
            onClick={(event) =>
              setNotificationMenuAnchorEl(event.currentTarget)
            }
          >
            <Badge color="error" badgeContent={notificationsCount}>
              <NotificationsIcon sx={{ color: "text.secondary" }} />
            </Badge>
          </ListItemButton>

          <HeaderUserMenuButton onSetUserMenuAnchorEl={setUserMenuAnchorEl} />
        </Stack>

        <HeaderLanguageMenuView
          anchorEl={languageMenuAnchorEl}
          closeMenu={() => setLanguageMenuAnchorEl(undefined)}
        />
        <HeaderOrganizationMenuView
          anchorEl={organizationMenuAnchorEl}
          closeMenu={() => setOrganizationMenuAnchorEl(undefined)}
          onSetOrganizationId={setUserSelectedOrganizationId}
          onSetUserType={setSelectedUserType}
        />
        <HeaderNotificationMenuView
          anchorEl={notificationMenuAnchorEl}
          closeMenu={() => setNotificationMenuAnchorEl(undefined)}
          invitations={invitations}
          notifications={notifications}
        />
        <HeaderUserMenuView
          anchorEl={userMenuAnchorEl}
          closeMenu={() => setUserMenuAnchorEl(undefined)}
          onSetOrganizationId={setUserSelectedOrganizationId}
          onSetUserType={setSelectedUserType}
        />
      </Stack>
      <HeaderSpacerView />
      <AppSidebarView />
    </>
  );
};
