import { Button, Stack, SxProps, Theme, Typography } from "@mui/material";
import { USER_TYPES, UserType } from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { USER_TYPE_RECORD } from "../../Users/consts/USER_TYPE_RECORD";
import { useMyUser } from "../../Users/hooks/useMyUser";

interface Props {
  sx?: Exclude<SxProps<Theme>, ReadonlyArray<unknown>>;
  onSetUserType: (userType: UserType) => Promise<void>;
}

export const HeaderWorkspacesView: FC<Props> = (props) => {
  const { onSetUserType } = props;

  const t = useT();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const organizationTypes = useSelectedOrganization(
    (state) => state?.types || [],
  );
  const userType = useMyUser((state) => state.user?.userType || null);
  const userRole = useMyUser((state) => state.user?.role || null);
  const otherUserTypes = USER_TYPES.filter((type) => {
    if (type === userType) {
      return false;
    }
    if (type === "ADMIN") {
      return userRole === "USER_ADMIN";
    }
    return true;
  }).sort((a, b) => a.localeCompare(b));

  const onClickSwitchToUserType = useCallback(
    async (selectedType: UserType) => {
      await onSetUserType(selectedType);
      // Never change the route if the user is an admin.
      if (selectedType === "ADMIN") {
        return;
      }
      const isOnHomeScreen =
        userType && pathname === USER_TYPE_RECORD[userType].homeScreenPath;
      const isOnTendersScreen =
        userType && pathname === USER_TYPE_RECORD[userType].tendersScreenPath;

      if (isOnTendersScreen) {
        navigate(USER_TYPE_RECORD[selectedType].tendersScreenPath);
      } else if (
        // For the QM user type and if the user is currently on the home screen, always change the route.
        selectedType === "QM" ||
        isOnHomeScreen ||
        // Otherwise only change the route if the organization types don't yet include the selected user type.
        !organizationTypes.includes(selectedType)
      ) {
        navigate(USER_TYPE_RECORD[selectedType].homeScreenPath);
      }
    },
    [navigate, onSetUserType, organizationTypes, pathname, userType],
  );

  return (
    <Stack spacing={3} alignContent="center" textAlign="center" sx={props.sx}>
      {userType && (
        <Typography variant="h4" color="primary">
          {t("HeaderWorkspacesView_Title_CurrentWorkspace", {
            workspaceName: t(USER_TYPE_RECORD[userType].workspaceName),
          })}
        </Typography>
      )}

      {otherUserTypes.map((type) => (
        <Button
          key={type}
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => onClickSwitchToUserType(type)}
        >
          {t("HeaderWorkspacesView_Button_Switch_Workspace", {
            name: t(USER_TYPE_RECORD[type].workspaceName),
          })}
        </Button>
      ))}
    </Stack>
  );
};
