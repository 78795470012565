import { create } from "zustand";

interface AppSidebarState {
  isMobileOpen: boolean;
  setIsMobileOpen: (isMobileOpen: boolean) => void;
}

export const useAppSidebar = create<AppSidebarState>((set) => ({
  isMobileOpen: false,
  setIsMobileOpen: (isMobileOpen) => set({ isMobileOpen }),
}));
