import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  BUDGET_TYPES,
  BudgetType,
  BudgetTypeStruct,
  MoneyStruct,
  TENDER_AGENT_TRAINING_PRICE_MODELS,
  TENDER_AGENT_TYPES,
  TenderAgentTrainingModel,
  TenderAgentTrainingPriceModelStruct,
  TenderAgentTypeStruct,
} from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import { useFieldArray } from "react-hook-form";
import { array, literal, nullable, number, type, union } from "superstruct";
import { getValidMoney } from "../../Common/helpers/getValidMoney";
import { ControlledMoneyField } from "../../Form/views/ControlledMoneyField";
import { ControlledRadioButtons } from "../../Form/views/ControlledRadioButtons";
import { ControlledSelect } from "../../Form/views/ControlledSelect";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { TENDER_AGENT_TRAINING_BUDGET_TYPE_RECORD } from "../consts/TENDER_AGENT_TRAINING_BUDGET_TYPE_RECORD";
import { TENDER_AGENT_TRAINING_PRICE_MODEL_RECORD } from "../consts/TENDER_AGENT_TRAINING_PRICE_MODEL_RECORD";
import { TENDER_AGENT_TYPE_RECORD } from "../consts/TENDER_AGENT_TYPE_RECORD";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const DEFAULT_AGENT_TRAINING_MODEL: Required<TenderAgentTrainingModel> = {
  agentType: "REGULAR",
  initialHoursPerAgent: "" as unknown as number,
  agentTrainingPriceModel: "FLATRATE",
};

const FormValuesStruct = type({
  agentTrainingBudget: nullable(MoneyStruct),
  agentTrainingBudgetType: nullable(BudgetTypeStruct),
  agentTrainingModels: array(
    type({
      agentType: TenderAgentTypeStruct,
      initialHoursPerAgent: union([number(), literal("")]),
      agentTrainingPriceModel: TenderAgentTrainingPriceModelStruct,
    }),
  ),
});

const AGENT_TYPE_OPTIONS = TENDER_AGENT_TYPES.map((agentType) => ({
  ...TENDER_AGENT_TYPE_RECORD[agentType],
  value: agentType,
}));

const AGENT_TRAINING_PRICE_MODEL_OPTIONS =
  TENDER_AGENT_TRAINING_PRICE_MODELS.map((priceModel) => ({
    ...TENDER_AGENT_TRAINING_PRICE_MODEL_RECORD[priceModel],
    value: priceModel,
  }));

export const TenderWizardAgentTrainingForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, watch } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => {
      // set "FIXED" if budget is already set for backwards compatibility
      const isFixed =
        !!tender?.agentTrainingBudget ||
        tender?.agentTrainingBudgetType == "FIXED";
      const agentTrainingBudgetType = isFixed
        ? ("FIXED" as BudgetType)
        : tender?.agentTrainingBudgetType;

      return {
        agentTrainingBudget: tender?.agentTrainingBudget || null,
        agentTrainingBudgetType: agentTrainingBudgetType || null,
        agentTrainingModels: tender?.agentTrainingModels?.length
          ? tender.agentTrainingModels
          : [DEFAULT_AGENT_TRAINING_MODEL],
      };
    },
    toDoc: (formValues, tender) => {
      const {
        agentTrainingBudget,
        agentTrainingBudgetType,
        agentTrainingModels,
      } = formValues;
      const validBudget = getValidMoney(agentTrainingBudget);
      const isFixed = agentTrainingBudgetType === "FIXED";
      const validTrainingModels = agentTrainingModels?.filter(
        (model): model is TenderAgentTrainingModel =>
          model.initialHoursPerAgent !== "",
      );

      return {
        agentTrainingBudgetType: agentTrainingBudgetType || undefined,
        agentTrainingBudget: isFixed && validBudget ? validBudget : undefined,
        agentTrainingModels: validTrainingModels?.length
          ? validTrainingModels
          : undefined,
        isReviewed: {
          ...tender?.isReviewed,
          operationalDetails: false,
        },
      };
    },
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "agentTrainingModels",
    keyName: "fieldId", // We need to explicitly use react-hook-form's fieldId because otherwise the id field from the data will be overwritten
    rules: {
      minLength: 1,
    },
  });

  const modelCount = watch("agentTrainingModels")?.length || 0;

  const onClickAddAgentTrainingModel = useCallback(() => {
    append(DEFAULT_AGENT_TRAINING_MODEL);
  }, [append]);

  const BUDGET_TYPE_OPTIONS = BUDGET_TYPES.map((budgetType) => ({
    ...TENDER_AGENT_TRAINING_BUDGET_TYPE_RECORD[budgetType],
    value: budgetType,
  }));

  const isFixedBudget = watch("agentTrainingBudgetType") === "FIXED";

  return (
    <Container sx={{ pb: 3 }} maxWidth="md">
      <Typography variant="h1" mb={4}>
        {t("TenderWizardAgentTrainingForm_Title")}
      </Typography>
      <Typography variant="body2" color="text.500" mb={4}>
        {t("TenderWizardAgentTrainingBudgetForm_Label")}
      </Typography>
      <Stack spacing={3} mb={4}>
        <ControlledRadioButtons
          control={control}
          name="agentTrainingBudgetType"
          options={BUDGET_TYPE_OPTIONS}
          gridSpacing={4}
          gridItemXs={6}
        />
      </Stack>

      {isFixedBudget && (
        <Stack spacing={3} mb={4}>
          <Typography variant="body2" color="text.500">
            {t("TenderWizardAgentTrainingForm_Paragraph_TotalTrainingBudget")}
          </Typography>
          <ControlledMoneyField
            control={control}
            name="agentTrainingBudget"
            label={t("TenderWizardAgentTrainingForm_Label_MoneyFieldAmount")}
            required
            min={0}
          />
        </Stack>
      )}

      <Stack spacing={3} mb={4} width="100%">
        <Typography variant="body2" color="text.500">
          {t("TenderWizardAgentTrainingForm_Paragraph_WhichPricingModels")}
        </Typography>
        {fields.map((model, index) => {
          return (
            <Stack
              direction="row"
              key={model.fieldId}
              spacing={3}
              alignItems="center"
            >
              <ControlledSelect
                control={control}
                name={`agentTrainingModels.${index}.agentType`}
                label={t("TenderWizardAgentTrainingForm_Label_Type")}
                items={AGENT_TYPE_OPTIONS}
                renderItem={(item) => t(item.label)}
                sx={{ width: 180 }}
                required
              />
              <ControlledTextField
                required
                name={`agentTrainingModels.${index}.initialHoursPerAgent`}
                autoComplete="off"
                label={t("TenderWizardAgentTrainingForm_Label_InitialHours")}
                control={control}
                type={"number"}
                min={0}
                sx={{ flex: 1 }}
              />
              <ControlledSelect
                control={control}
                name={`agentTrainingModels.${index}.agentTrainingPriceModel`}
                label={t("TenderWizardAgentTrainingForm_Label_PriceModel")}
                items={AGENT_TRAINING_PRICE_MODEL_OPTIONS}
                renderItem={(item) => t(item.label)}
                sx={{ width: 180 }}
                required
              />
              <Box>
                <IconButton
                  color="error"
                  onClick={() => {
                    if (modelCount > 1) {
                      remove(index);
                    }
                  }}
                >
                  <DeleteForeverOutlinedIcon />
                </IconButton>
              </Box>
            </Stack>
          );
        })}
      </Stack>

      <Button variant="contained" onClick={onClickAddAgentTrainingModel}>
        {t("TenderWizardAgentTrainingForm_Button_AddAgentTrainingModel")}
      </Button>
    </Container>
  );
};
