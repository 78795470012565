import { Paper, Stack } from "@mui/material";
import { isInvitation } from "@snubes/snubes-types";
import { FC } from "react";
import { CollectionListView } from "../../Firebase/views/CollectionListView";
import { NotificationListItemForInvitationView } from "../../Notifications/views/NotificationListItemForInvitationView";

interface Props {
  email: string;
}

export const InvitationsCollectionListView: FC<Props> = (props) => {
  return (
    <CollectionListView
      collectionName="invitations"
      filters={[
        ["email", "==", props.email],
        ["acceptedAt", "==", null],
      ]}
      orderBy={["createdAt", "desc"]}
      keyField="id"
      isT={isInvitation}
      renderListItem={(invitation) => (
        <Stack component={Paper} variant="outlined" mb={2}>
          <NotificationListItemForInvitationView invitation={invitation} />
        </Stack>
      )}
    />
  );
};
