import { Divider, Menu, Stack, Typography } from "@mui/material";
import { UserType } from "@snubes/snubes-types";
import { FC } from "react";
import { useMyOrganizations } from "../../Organizations/hooks/useMyOrganizations";
import { useT } from "../../Translation/hooks/useT";
import { HeaderOrganizationMenuItemsView } from "./HeaderOrganizationMenuItemsView";
import { HeaderWorkspacesView } from "./HeaderWorkspacesView";

interface Props {
  anchorEl?: HTMLElement;
  closeMenu: () => void;
  onSetOrganizationId: (organizationId: string) => Promise<void>;
  onSetUserType: (userType: UserType) => Promise<void>;
}

export const HeaderOrganizationMenuView: FC<Props> = (props) => {
  const t = useT();
  const organizations = useMyOrganizations(
    (state) => state.organizations || [],
  );

  return (
    <Menu
      anchorEl={props.anchorEl}
      open={!!props.anchorEl}
      onClick={props.closeMenu}
      onClose={props.closeMenu}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      keepMounted={false}
    >
      <Stack
        spacing={3}
        px={3}
        pt={1}
        pb={2}
        alignContent="center"
        textAlign="center"
      >
        <Divider>
          <Typography variant="caption1" color="text.secondary" px={1}>
            {t("HeaderOrganizationMenuView_Title_SwitchWorkspaces")}
          </Typography>
        </Divider>

        <HeaderWorkspacesView onSetUserType={props.onSetUserType} />

        <Divider>
          <Typography variant="caption1" color="text.secondary" px={1}>
            {t("HeaderOrganizationMenuView_Title_YourOrganizations", {
              count: organizations.length,
            })}
          </Typography>
        </Divider>
      </Stack>

      <HeaderOrganizationMenuItemsView
        organizations={organizations}
        closeMenu={props.closeMenu}
        onSetOrganizationId={props.onSetOrganizationId}
      />
    </Menu>
  );
};
