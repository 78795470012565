import { Stack, Typography } from "@mui/material";
import { Tender, TenderApplication } from "@snubes/snubes-types";
import { FC } from "react";
import { DetailsAccordionContainerView } from "../../Common/views/DetailsAccordionContainerView";
import { DetailsAccordionContentView } from "../../Common/views/DetailsAccordionContentView";
import { FileListView } from "../../Common/views/FileListView";
import { RelativeTimestampView } from "../../Common/views/RelativeTimestampView";
import { useT } from "../../Translation/hooks/useT";
import { TENDER_APPLICATION_REJECTION_REASON_RECORD } from "../consts/TENDER_APPLICATION_REJECTION_REASON_RECORD";
import { TENDER_APPLICATION_STATUS_RECORD } from "../consts/TENDER_APPLICATION_STATUS_RECORD";
import { TenderApplicationActionsView } from "./TenderApplicationActionsView";

interface Props {
  tender: Tender;
  title: string;
  application: TenderApplication;
}

export const TenderApplicationView: FC<Props> = (props) => {
  const { tender, title, application } = props;
  const t = useT();

  const { label, Icon, color, timestampKey } =
    TENDER_APPLICATION_STATUS_RECORD[application.status];
  const timestamp = application[timestampKey];

  return (
    <DetailsAccordionContainerView
      title={
        <Typography
          variant="subtitle1"
          component={Stack}
          direction="row"
          width="100%"
          justifyContent="space-between"
        >
          {title}
          {timestamp && (
            <Stack direction="row">
              <Typography
                component="span"
                variant="inherit"
                textTransform="lowercase"
              >
                {t(
                  application.status !== "PUBLISHED"
                    ? label
                    : "TenderApplicationStatusRecord_Label_Received",
                )}
              </Typography>
              &nbsp;
              <RelativeTimestampView
                timestamp={timestamp}
                shouldBeInThePast
                translation
                hasTooltip
              />
              {application.status !== "PUBLISHED" && (
                <Icon
                  color={color === "default" ? "inherit" : color}
                  sx={{ ml: 2 }}
                />
              )}
            </Stack>
          )}
        </Typography>
      }
    >
      {application.description && (
        <DetailsAccordionContentView
          title={t("TenderApplicationsPage_Title_Description")}
        >
          {application.description}
        </DetailsAccordionContentView>
      )}
      {application.documents && (
        <DetailsAccordionContentView
          title={t("TenderApplicationsPage_Title_Documents")}
        >
          <FileListView files={Object.values(application.documents)} />
        </DetailsAccordionContentView>
      )}
      {application.rejectionReason && (
        <DetailsAccordionContentView
          title={t("TenderApplicationsPage_Title_RejectionReason")}
        >
          {t(
            TENDER_APPLICATION_REJECTION_REASON_RECORD[
              application.rejectionReason
            ].label,
          )}
        </DetailsAccordionContentView>
      )}
      <TenderApplicationActionsView tender={tender} application={application} />
    </DetailsAccordionContainerView>
  );
};
