import { Project } from "@snubes/snubes-types";
import { createContext, useContext } from "react";

interface ProjectContext {
  project?: Project;
}
const projectContext = createContext<ProjectContext>({});

export const ProjectContextProvider = projectContext.Provider;

export const useProjectContext = () => {
  return useContext(projectContext);
};
