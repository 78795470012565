import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Project } from "@snubes/snubes-types";
import { FC, MouseEvent, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { handleError } from "../../Common/helpers/handleError";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { restoreProjectCallable } from "../../Projects/callables/restoreProjectCallable";
import { ProjectDeleteDialog } from "../../Projects/views/ProjectDeleteDialog";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  project: Project;
}

export const AdminProjectsCollectionTableActionsView: FC<Props> = (props) => {
  const t = useT();
  const { project } = props;
  const hasPermission = useHasPermission();
  const organizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const restoreProject = useCallback(async () => {
    if (!project?.id) return;
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);

      await restoreProjectCallable({
        projectId: project.id,
      });

      toast.success(
        t("AdminProjectsCollectionTableActionsView_Toast_RestoredProject"),
      );
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsSubmitting(false);
    }
  }, [isSubmitting, project.id, t]);

  return (
    <>
      <IconButton
        disabled={isSubmitting}
        onClick={(event) => {
          event.preventDefault();
          setMenuAnchor(event.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!menuAnchor}
        onClose={(event: MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          setMenuAnchor(null);
        }}
      >
        {project.isDeleted && hasPermission("CAN_RESTORE_PROJECT") && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              void restoreProject();
            }}
          >
            {t("AdminProjectsCollectionTableActionsView_Button_RestoreProject")}
          </MenuItem>
        )}
        {!project.isDeleted &&
          hasPermission("CAN_DELETE_TENDER", { organizationId }) && (
            <MenuItem
              onClick={(event) => {
                event.stopPropagation();
                setIsDeleteDialogOpen(true);
              }}
            >
              {t(
                "AdminProjectsCollectionTableActionsView_Button_DeleteProject",
              )}
            </MenuItem>
          )}
      </Menu>
      {isDeleteDialogOpen && (
        <ProjectDeleteDialog
          close={() => setIsDeleteDialogOpen(false)}
          project={project}
          isOpen
        />
      )}
    </>
  );
};
