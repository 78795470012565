import { superstructResolver } from "@hookform/resolvers/superstruct";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  Paper,
  Stack,
} from "@mui/material";
import {
  CALL_QUESTION_ANSWER_TYPES,
  MAX_PROJECT_QUESTIONS_COUNT,
  Project,
  ProjectQuestion,
  ProjectQuestionStruct,
} from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC, Fragment, useCallback, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { array, boolean, Infer, string, type } from "superstruct";
import { v4 } from "uuid";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledSelect } from "../../Form/views/ControlledSelect";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { updateProjectQuestionCallable } from "../callables/updateProjectQuestionCallable";
import { PROJECT_QUESTION_RESPONSE_TYPE_RECORD } from "../consts/PROJECT_QUESTION_RESPONSE_TYPE_RECORD";
import { ProjectQuestionsFormConfirmationDialog } from "./ProjectQuestionsFormConfirmationDialog";
import { ProjectQuestionsFormHeaderView } from "./ProjectQuestionsFormHeaderView";

const ProjectQuestionsFormValuesStruct = type({
  questions: array(ProjectQuestionStruct),
  isAppliedToHistoricData: boolean(),
  checkExistingCallsFromDate: string(),
});

export type ProjectQuestionsFormValues = Infer<
  typeof ProjectQuestionsFormValuesStruct
>;

interface Props {
  project: Project;
}

export const ProjectQuestionsForm: FC<Props> = (props) => {
  const t = useT();
  const { project } = props;

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    resetField,
    formState: { isSubmitting },
  } = useForm<ProjectQuestionsFormValues>({
    resolver: superstructResolver(ProjectQuestionsFormValuesStruct),
    defaultValues: {
      questions: project.questions || [],
      isAppliedToHistoricData: false,
      checkExistingCallsFromDate: DateTime.utc()
        .startOf("day")
        .minus({ days: 7 })
        .toISO(),
    },
  });
  const options = CALL_QUESTION_ANSWER_TYPES.map((type) => {
    return {
      value: type,
      label: t(PROJECT_QUESTION_RESPONSE_TYPE_RECORD[type].label),
    };
  });

  const { append, remove } = useFieldArray({
    control,
    keyName: "fieldId", // TODO: remove in next major version of react-hook-form
    name: "questions",
  });
  const questions = watch("questions");

  const isMaxQuestionsReached = questions.length >= MAX_PROJECT_QUESTIONS_COUNT;

  const addQuestion = useCallback(
    () => append({ id: v4(), ref: v4(), text: "", type: "TEXT" }),
    [append],
  );

  const removeQuestion = (index: number) => remove(index);

  const getIsQuestionUsedInFlaggingConfig = useCallback(
    (questionRef: string) => {
      return project.callFlaggingConfigs?.some((config) => {
        return config.parameters.some((parameter) => {
          return (
            parameter.type === "QUESTION_ANSWER" &&
            parameter.questionRef === questionRef
          );
        });
      });
    },
    [project.callFlaggingConfigs],
  );

  const getUpdatedQuestionsRefs = useCallback(
    (formValues: ProjectQuestionsFormValues) => {
      return project.questions?.flatMap((currentQuestion) => {
        const nextQuestion = formValues.questions?.find(
          (q) => q.ref === currentQuestion.ref,
        );

        if (!nextQuestion) {
          return [currentQuestion.ref];
        }

        const hasTextChanged = nextQuestion.text !== currentQuestion.text;
        const hasTypeChanged = nextQuestion.type !== currentQuestion.type;

        if (hasTextChanged || hasTypeChanged) {
          return [nextQuestion.ref];
        }

        return [];
      });
    },
    [project.questions],
  );

  const onSubmit = useCallback(
    async (formValues: ProjectQuestionsFormValues) => {
      const updatedQuestionsRefs = getUpdatedQuestionsRefs(formValues);

      const areAnyUpdatedQuestionsInCallFlaggingConfig =
        updatedQuestionsRefs?.some((ref) =>
          getIsQuestionUsedInFlaggingConfig(ref),
        );

      const newQuestions = formValues.questions.map((question) => {
        if (question.ref && updatedQuestionsRefs?.includes(question.ref)) {
          // We're creating a new id because the question has changed and we're now treating it
          // as an entirely new question. The ref id however will stay the same.
          return { ...question, id: v4() };
        }

        return question;
      });

      // If any questions of the call flagging config were updated we need to show the confirmation dialog instead.
      // Otherwise, or if the dialog is already open and was confirmed, continue submitting the form.
      if (
        !isConfirmationDialogOpen &&
        areAnyUpdatedQuestionsInCallFlaggingConfig
      ) {
        setIsConfirmationDialogOpen(true);
        return;
      }

      try {
        if (isSubmitting) return;

        await updateProjectQuestionCallable({
          projectId: project.id,
          questions: newQuestions,
          ...(formValues.isAppliedToHistoricData
            ? {
                checkExistingCallsFromDate:
                  formValues.checkExistingCallsFromDate,
              }
            : {}),
        });

        reset(formValues);
        setIsConfirmationDialogOpen(false);
        toast.success(t("ProjectQuestionsForm_Toast_SavedQuestions"));
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [
      getUpdatedQuestionsRefs,
      getIsQuestionUsedInFlaggingConfig,
      isConfirmationDialogOpen,
      isSubmitting,
      project.id,
      reset,
      t,
    ],
  );

  const onSetProjectQuestions = useCallback(
    (newQuestions: ProjectQuestion[]) => {
      setValue("questions", newQuestions);
    },
    [setValue],
  );

  // This will be passed to the confirmation dialog
  const isAppliedToHistoricData = watch("isAppliedToHistoricData");
  const isCallsUpdateInProgress =
    !isAppliedToHistoricData &&
    (props.project.flaggingConfigsCallsProcessingCount || 0) > 0;

  // This will be passed to the confirmation dialog
  const onClickedCancelConfirmation = () => setIsConfirmationDialogOpen(false);
  const onExitedConfirmationDialog = useCallback(() => {
    resetField("isAppliedToHistoricData");
    resetField("checkExistingCallsFromDate");
  }, [resetField]);

  return (
    <>
      <ProjectQuestionsFormHeaderView
        questions={questions}
        onSetProjectQuestions={onSetProjectQuestions}
      />
      <Paper
        variant="outlined"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack spacing={3} p={3}>
          {questions.map((question, index) => {
            const isQuestionUsedInFlaggingConfig = Boolean(
              question.ref && getIsQuestionUsedInFlaggingConfig(question.ref),
            );
            return (
              <Fragment key={question.id}>
                <Stack direction="row" spacing={3} alignItems="center">
                  <Stack flex={2}>
                    <ControlledTextField
                      control={control}
                      name={`questions.${index}.text`}
                      label={t("ProjectQuestionsForm_Label_Question")}
                      disabled={isSubmitting}
                      required
                      fullWidth
                    />
                  </Stack>
                  <Stack flex={1}>
                    <ControlledSelect
                      label={t("ProjectQuestionsForm_Label_ResponseType")}
                      control={control}
                      name={`questions.${index}.type`}
                      items={options}
                      renderItem={(item) => item.label}
                      disabled={isSubmitting || isQuestionUsedInFlaggingConfig}
                      fullWidth
                    />
                  </Stack>
                  <IconButton
                    size="large"
                    color="error"
                    onClick={() => removeQuestion(index)}
                    disabled={isSubmitting}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Stack>
                {isQuestionUsedInFlaggingConfig && (
                  <FormHelperText>
                    {t(
                      "ProjectQuestionsForm_Label_QuestionIsUsedInFlaggingConfig",
                    )}
                  </FormHelperText>
                )}
                <Divider />
              </Fragment>
            );
          })}

          <Box bgcolor="grey.50" borderColor="divider">
            <Button
              variant="text"
              onClick={addQuestion}
              startIcon={<AddCircleIcon />}
              disabled={isSubmitting || isMaxQuestionsReached}
              fullWidth
              sx={{
                fontSize: 14,
                fontWeight: "bold",
                justifyContent: "flex-start",
                pl: 3,
              }}
            >
              <span>{t("ProjectQuestionsForm_Button_NewQuestion")}</span>
              {isMaxQuestionsReached && (
                <span style={{ flex: 1, textAlign: "right" }}>
                  (
                  {t("ProjectQuestionsForm_Alert_MaxQuestionsReached", {
                    count: MAX_PROJECT_QUESTIONS_COUNT,
                  })}
                  )
                </span>
              )}
            </Button>
          </Box>

          <Divider />

          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
          >
            {t("Common_Save")}
          </Button>
        </Stack>

        <ProjectQuestionsFormConfirmationDialog
          close={onClickedCancelConfirmation}
          isOpen={isConfirmationDialogOpen}
          isCallsUpdateInProgress={isCallsUpdateInProgress}
          control={control}
          isAppliedToHistoricData={isAppliedToHistoricData}
          isDisabled={isSubmitting}
          onExited={onExitedConfirmationDialog}
        />
      </Paper>
    </>
  );
};
