import { Stack, Typography } from "@mui/material";
import { isConnectorConfigLog } from "@snubes/snubes-types";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LogListItemViewForConnectorConfig } from "../../Logs/views/LogListItemViewForConnectorConfig";
import { LogsButtonView } from "../../Logs/views/LogsButtonView";
import { useT } from "../../Translation/hooks/useT";
import { useConnectorConfigContext } from "../hooks/useConnectorConfigContext";
import { ConnectorConfigDialfireEditForm } from "./ConnectorConfigDialfireEditForm";
import { ConnectorConfigGenricWebhookEditForm } from "./ConnectorConfigGenricWebhookEditForm";
import { ConnectorConfigInoplaEditForm } from "./ConnectorConfigInoplaEditForm";
import { ConnectorConfigSftpEditForm } from "./ConnectorConfigSftpEditForm";
import { ConnectorConfigStatusChip } from "./ConnectorConfigStatusChip";
import { ConnectorConfigVccLiveEditForm } from "./ConnectorConfigVccLiveEditForm";

export const ConnectorConfigEditPage = () => {
  const t = useT();
  const { connectorConfig } = useConnectorConfigContext();
  const hasPermission = useHasPermission();

  if (!connectorConfig) return null;

  return (
    <FullHeightContainer maxWidth="md">
      <Stack py={5} spacing={3}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack flex={1}>
            <Typography variant="h2">
              {t("ConnectorConfigEditPage_Heading_ConnectionDetails")}
            </Typography>
          </Stack>
          {hasPermission("CAN_READ_LOGS") && (
            <LogsButtonView
              collectionName={`organizations/${connectorConfig.organizationId}/connectorConfigs/${connectorConfig.id}/logs`}
              LogListItemView={LogListItemViewForConnectorConfig}
              isT={isConnectorConfigLog}
            />
          )}
          <ConnectorConfigStatusChip isLong status={connectorConfig.status} />
        </Stack>
        {connectorConfig.platform === "VCC_LIVE" && (
          <ConnectorConfigVccLiveEditForm connectorConfig={connectorConfig} />
        )}
        {connectorConfig.platform === "INOPLA" && (
          <ConnectorConfigInoplaEditForm connectorConfig={connectorConfig} />
        )}
        {connectorConfig.platform === "DIALFIRE" && (
          <ConnectorConfigDialfireEditForm connectorConfig={connectorConfig} />
        )}
        {connectorConfig.platform === "SFTP" && (
          <ConnectorConfigSftpEditForm connectorConfig={connectorConfig} />
        )}
        {connectorConfig.platform === "GENERIC_WEBHOOK" && (
          <ConnectorConfigGenricWebhookEditForm
            connectorConfig={connectorConfig}
          />
        )}
      </Stack>
    </FullHeightContainer>
  );
};
