import { Stack, Typography } from "@mui/material";
import { isMember, Member } from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { AvatarView } from "../../Common/views/AvatarView";
import { CollectionTableColumn } from "../../Firebase/types/CollectionTableColumn";
import { CollectionTableCopyToClipboardCell } from "../../Firebase/views/CollectionTableCopyToClipboardCell";
import { CollectionTableView } from "../../Firebase/views/CollectionTableView";
import { useT } from "../../Translation/hooks/useT";
import { getUserName } from "../../Users/helpers/getUserName";
import { MemberRoleChip } from "./MemberRoleChip";
import { RemoveMemberButton } from "./RemoveMemberButton";

interface Props {
  organizationId: string;
}

export const MembersCollectionTableView: FC<Props> = (props) => {
  const { organizationId } = props;
  const t = useT();
  const hasPermission = useHasPermission();
  const canDeleteMember = hasPermission("CAN_WRITE_ORGANIZATION_MEMBER", {
    organizationId,
  });

  const columns = useMemo<CollectionTableColumn<Member>[]>(
    () => [
      {
        title: t("UsersView_TableCell_Member"),
        renderCell: (member) => {
          const userName = getUserName(member);
          return (
            <Stack direction="row" alignItems="center" spacing={2}>
              <AvatarView size={30} name={userName}></AvatarView>
              <Typography variant="subtitle2">{userName}</Typography>
            </Stack>
          );
        },
      },

      {
        title: t("UsersView_TableCell_Email"),
        field: "email",
        renderCell: (member) =>
          member.email && (
            <CollectionTableCopyToClipboardCell text={member.email} />
          ),
      },
      {
        title: t("UsersView_TableCell_Role"),
        field: "role",
        renderCell: (member) => <MemberRoleChip role={member.role} />,
      },
      {
        title: t("UsersView_TableCell_CreatedAt"),
        field: "createdAt",
      },
      {
        title: t("UsersView_TableCell_UpdatedAt"),
        field: "updatedAt",
      },

      {
        title: t("UsersView_TableCell_Actions"),
        sx: { py: 0, textAlign: "right" },
        align: "right",
        renderCell: (user) => {
          return (
            !!canDeleteMember && (
              <RemoveMemberButton
                memberId={user.id}
                organizationId={organizationId}
              />
            )
          );
        },
      },
    ],
    [canDeleteMember, organizationId, t],
  );

  return (
    <CollectionTableView
      collectionName={`organizations/${organizationId}/members`}
      orderBy={["id", "asc"]}
      keyField="id"
      isT={isMember}
      getTo={(member) =>
        `/organizations/${organizationId}/members/${member.id}`
      }
      columns={columns}
    />
  );
};
