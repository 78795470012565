import { Avatar, Box, Card, Link, Stack, Typography } from "@mui/material";
import { FC } from "react";

interface Props {
  imageUrl: string;
  name: string;
  title: string;
  phones: string[];
  email: string;
}

export const HelpCenterAgentCard: FC<Props> = (props) => {
  return (
    <Card
      sx={{
        width: 340,
        marginX: "auto",
      }}
    >
      <Stack justifyContent="space-between" height="100%">
        <Stack p={3} alignItems="center">
          <Avatar
            alt="AgentProfile"
            src={props.imageUrl}
            sx={{ width: 120, height: 120 }}
          />
        </Stack>
        <Typography variant="body2" textAlign="center" pt={2} pb={1}>
          {props.name}
        </Typography>
        <Typography variant="body1" textAlign="center" pb={2}>
          {props.title}
        </Typography>
        {props.phones.map((phone) => (
          <Link
            href={`tel:${phone.replace(/\s/g, "")}`}
            key={phone}
            textAlign="center"
            pb={1}
            color="primary.main"
            sx={{
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {phone}
          </Link>
        ))}
        <Box bgcolor="grey.100" p={3} mt={4}>
          <Typography color="primary.main" textAlign="center">
            <Link
              href={`mailto:${props.email}`}
              rel="noopener noreferrer"
              sx={{
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              {props.email}
            </Link>
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};
