/* eslint-disable react-hooks/exhaustive-deps */
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { ChangeEvent, FC, useCallback } from "react";
import { useStore } from "zustand";
import { FIREBASE_TABLE_FILTER_NUMBER_OPERATORS } from "../consts/FIREBASE_TABLE_FILTER_NUMBER_OPERATORS";
import { selectFirebaseTableFilter } from "../helpers/selectFirebaseTableFilter";
import { useFirebaseTableFilterStore } from "../hooks/useFirebaseTableFilters";
import { FirebaseTableFilterNumberOperator } from "../types/FirebaseTableFilterNumberOperator";

interface Props extends Omit<TextFieldProps<"outlined">, "variant"> {
  filterName: string;
}

export const FirebaseTableFilterNumberView: FC<Props> = (props) => {
  const { filterName } = props;

  const filterStore = useFirebaseTableFilterStore();
  const filterLabel = useStore(
    filterStore,
    useCallback(
      selectFilter(filterName, (filter) => filter.label),
      [filterName],
    ),
  );
  const value = useStore(
    filterStore,
    useCallback(
      selectFilter(filterName, (filter) => filter.value || ""),
      [filterName],
    ),
  );
  const operator = useStore(
    filterStore,
    useCallback(
      selectFilter(filterName, (filter) => filter.operator),
      [filterName],
    ),
  );

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    filterStore
      .getState()
      .setFilterValue("number", filterName, safeParseInt(event.target.value));
  };

  const onChangeOperator = (
    event: SelectChangeEvent<FirebaseTableFilterNumberOperator>,
  ) => {
    filterStore
      .getState()
      .setFilterOperator(
        "number",
        filterName,
        event.target.value as FirebaseTableFilterNumberOperator,
      );
  };

  return (
    <Stack direction="row" spacing={2}>
      <Select
        id={`select-${filterName}`}
        value={operator}
        onChange={onChangeOperator}
        sx={{ minWidth: 70 }}
      >
        {FIREBASE_TABLE_FILTER_NUMBER_OPERATORS.map((op) => (
          <MenuItem key={op} value={op}>
            {op}
          </MenuItem>
        ))}
      </Select>

      <TextField
        type="number"
        variant="outlined"
        size={props.size}
        fullWidth={props.fullWidth}
        placeholder={props.placeholder}
        label={props.placeholder ? undefined : filterLabel}
        value={value}
        onChange={onChangeValue}
      />
    </Stack>
  );
};

function safeParseInt(value: string | null) {
  const parsed = parseInt(value || "", 10);
  return isNaN(parsed) ? null : parsed;
}

const selectFilter = selectFirebaseTableFilter("number");
