import { Container, Stack, Typography } from "@mui/material";
import {
  PROVIDER_CLIENT_TYPES,
  ProviderClientTypeStruct,
} from "@snubes/snubes-types/types/ProviderClientType";
import { FC } from "react";
import { array, type } from "superstruct";
import { ControlledCheckboxButtons } from "../../Form/views/ControlledCheckboxButtons";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { PROVIDER_CLIENT_TYPE } from "../consts/PROVIDER_CLIENT_TYPE";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  clientTypes: array(ProviderClientTypeStruct),
});

const PROVIDER_CLIENT_TYPE_OPTIONS = PROVIDER_CLIENT_TYPES.map(
  (clientType) => ({
    ...PROVIDER_CLIENT_TYPE[clientType],
    value: clientType,
  }),
);

export const ProviderWizardClientTypesForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      clientTypes: provider?.clientTypes || [],
    }),
    toDoc: (formValues) => {
      return {
        clientTypes: formValues?.clientTypes,
      };
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("ProviderWizardClientTypesForm_Title_ClientTypes")}
        </Typography>
        <Typography variant="body1" color="text.500" mb={4}>
          {t("ProviderWizardClientTypesForm_Paragraph_ClientTypes")}
        </Typography>
        <Stack spacing={3}>
          <ControlledCheckboxButtons
            control={control}
            name="clientTypes"
            options={PROVIDER_CLIENT_TYPE_OPTIONS}
            required
            gridSpacing={4}
            gridItemXs={12}
            gridItemSm={4}
          />
        </Stack>
      </Container>
    </form>
  );
};
