import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SelectableButton = styled(Button)<{ checked: boolean }>(
  ({ theme, checked }) => ({
    textTransform: "none",
    height: "100%",
    borderRadius: 4,
    whiteSpace: "normal",
    ...(!checked && {
      color: theme.palette.text.secondary,
      borderWidth: 2,
      borderColor: theme.palette.text.disabled,
      "&:hover": {
        borderWidth: 2,
        borderColor: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[50],
      },
    }),
    ...(checked && {
      color: theme.palette.primary.main,
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.info[50],
      "&:hover": {
        borderWidth: 2,
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.info[100],
      },
    }),
    padding: "32px 16px",
  }),
);
