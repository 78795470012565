import { isProjectReportFrame, ProjectReportFrame } from "@snubes/snubes-types";
import { DateTime, Duration } from "luxon";
import { FC, useMemo } from "react";
import { DateView } from "../../Common/views/DateView";
import { RelativeTimestampView } from "../../Common/views/RelativeTimestampView";
import { CollectionTableColumn } from "../../Firebase/types/CollectionTableColumn";
import { CollectionTableView } from "../../Firebase/views/CollectionTableView";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  projectId: string;
  fromDate: DateTime | null;
  toDate: DateTime | null;
}

export const ProjectReportFramesCollectionTableView: FC<Props> = (props) => {
  const { projectId, fromDate, toDate } = props;
  const t = useT();

  const columns = useMemo<CollectionTableColumn<ProjectReportFrame>[]>(
    () => [
      {
        title: t("Common_Date"),
        field: "date",
        renderCell: (row) => <DateView date={row.date} />,
      },
      {
        title: t("ProjectReportFramesCollectionTableView_Title_NumberOfCalls"),
        field: "totalCallsCount",
      },
      {
        title: t("ProjectReportFramesCollectionTableView_Title_CallsAnswered"),
        field: "answeredCallsCount",
      },
      {
        title: t("ProjectReportFramesCollectionTableView_Title_Reachability"),
        field: "reachabilityPercentage",
        renderCell: (row) =>
          row.reachabilityPercentage.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            style: "percent",
          }),
      },
      {
        title: t("ProjectReportFramesCollectionTableView_Title_Aht"),
        field: "averageHandlingTimeInSeconds",
        renderCell: (row) =>
          Duration.fromObject({
            seconds: Math.round(row.averageHandlingTimeInSeconds),
          }).toFormat("hh:mm:ss"),
      },
      {
        title: t("ProjectReportFramesCollectionTableView_Title_Tht"),
        field: "totalHandlingTimeInSeconds",
        renderCell: (row) =>
          Duration.fromObject({
            seconds: Math.round(row.totalHandlingTimeInSeconds),
          }).toFormat("hh:mm:ss"),
      },
      {
        title: t("ProjectReportFramesCollectionTableView_Title_Sl"),
        field: "serviceLevelPercentage",
        renderCell: (row) =>
          row.serviceLevelPercentage.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            style: "percent",
          }),
      },
      {
        title: t("ProjectReportFramesCollectionTableView_Title_UpdatedAt"),
        field: "updatedAt",
        renderCell: (row) => {
          return (
            <RelativeTimestampView
              timestamp={row.updatedAt}
              shouldBeInThePast
              translation
              hasTooltip
            />
          );
        },
      },
    ],
    [t],
  );
  return (
    <CollectionTableView
      collectionName={`projects/${projectId}/reportFramesByDay`}
      keyField="date"
      getTo={(frame) =>
        `/projects/${projectId}/report-frames/${frame.id}/calls/insights`
      }
      isT={isProjectReportFrame}
      columns={columns}
      orderBy={["date", "desc"]}
      filters={[
        ["date", ">=", fromDate?.toJSDate() || null],
        ["date", "<=", toDate?.toJSDate() || null],
      ]}
    />
  );
};
