import { Alert } from "@mui/material";
import { FC } from "react";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LoadingView } from "../../Common/views/LoadingView";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useProvider } from "../../Providers/hooks/useProvider";
import { TendersBrowseSetupView } from "./TendersBrowseSetupView";
import { TendersBrowseView } from "./TendersBrowseView";

export const TendersBrowsePage: FC = () => {
  const selectedOrganizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );
  const [provider, isLoading, error] = useProvider(selectedOrganizationId);
  const isProviderProfilePublished = provider?.status === "PUBLISHED";

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer>
        {isLoading && <LoadingView />}
        {error && <Alert severity="error">{error.message}</Alert>}
        {!isLoading && selectedOrganizationId && isProviderProfilePublished && (
          <TendersBrowseView organizationId={selectedOrganizationId} />
        )}
        {!isLoading && !isProviderProfilePublished && (
          <TendersBrowseSetupView />
        )}
      </FullHeightContainer>
    </>
  );
};
