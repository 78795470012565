import { Container, Stack, Typography } from "@mui/material";
import { TEAM_MODELS, TeamModelStruct } from "@snubes/snubes-types";
import { FC } from "react";
import { array, type } from "superstruct";
import { ControlledCheckboxButtons } from "../../Form/views/ControlledCheckboxButtons";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { PROVIDER_TEAM_MODEL } from "../consts/PROVIDER_TEAM_MODEL";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  teamModels: array(TeamModelStruct),
});

const TEAM_MODEL_OPTIONS = TEAM_MODELS.map((teamModel) => ({
  ...PROVIDER_TEAM_MODEL[teamModel],
  value: teamModel,
}));

export const ProviderWizardTeamModelForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      teamModels: provider?.teamModels || [],
    }),
    toDoc: (formValues) => ({
      teamModels: formValues?.teamModels,
    }),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("ProviderWizardTeamModelForm_Title_TeamModel")}
        </Typography>
        <Typography variant="body2" color="text.500" mb={4}>
          {t("ProviderWizardTeamModelForm_Paragraph_TeamModel")}
        </Typography>
        <Stack spacing={3}>
          <ControlledCheckboxButtons
            control={control}
            name="teamModels"
            options={TEAM_MODEL_OPTIONS}
            required
            gridSpacing={4}
            gridItemXs={12}
            gridItemSm={6}
          />
        </Stack>
      </Container>
    </form>
  );
};
