import { DateRange } from "@snubes/daterange-picker";
import { DateTime } from "luxon";
import { useCallback, useMemo, useRef, useState } from "react";
import { getNormalizedDate } from "../../Common/helpers/getNormalizedDate";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";

export interface ProjectController {
  fromDate: DateTime;
  toDate: DateTime;
  dateRange: Required<DateRange>;
  isDateRangeSelectedByUser: boolean;
  setFromDate: (date: DateTime) => void;
  setToDate: (date: DateTime) => void;
  setDateRange: (range: DateRange, isDateRangeSelectedByUser?: boolean) => void;
  shouldDisableFromDate: (date: DateTime) => boolean;
  shouldDisableToDate: (date: DateTime) => boolean;
}

export function useProjectController(): ProjectController {
  const now = DateTime.utc().startOf("day");
  const storage = useLocalStorage.getState().data;
  const from = storage.filterProjectStartDate;
  const to = storage.filterProjectEndDate;
  const isDateRangeSelectedByUser = useRef(false);

  // We reset the date every day to always select the newest day by default.
  const isUsingLocalStorage = storage.filterProjectSaveDate === now.toISODate();

  const [dateRange, setDateRangeInternal] = useState<Required<DateRange>>({
    startDate:
      isUsingLocalStorage && from
        ? DateTime.fromISO(from).startOf("day").toJSDate()
        : now.minus({ month: 1 }).toJSDate(),
    endDate:
      isUsingLocalStorage && to
        ? DateTime.fromISO(to).startOf("day").toJSDate()
        : now.toJSDate(),
  });

  const setDateRange = useCallback(
    (range: DateRange, selectedByUser = true) => {
      isDateRangeSelectedByUser.current = selectedByUser;

      const storage = useLocalStorage.getState();
      const now = DateTime.utc().startOf("day");

      storage.setData({
        filterProjectStartDate: range.startDate?.toISOString(),
        filterProjectEndDate: range.endDate?.toISOString(),
        filterProjectSaveDate: now.toISODate(),
      });
      setDateRangeInternal((state) => ({
        ...state,
        ...range,
      }));
    },
    [],
  );

  const fromDate = useMemo(
    () => getNormalizedDate(dateRange.startDate),
    [dateRange.startDate],
  );
  const toDate = useMemo(
    () => getNormalizedDate(dateRange.endDate),
    [dateRange.endDate],
  );

  const updateFromDate = useCallback(
    (dateTime: DateTime) => {
      isDateRangeSelectedByUser.current = true;

      if (dateTime > toDate) {
        return;
      }
      if (dateTime.toMillis() < 0) {
        // Firestore does not allow very small timestamps
        return;
      }
      setDateRangeInternal(({ endDate }) => ({
        startDate: dateTime.toJSDate(),
        endDate,
      }));
      const storage = useLocalStorage.getState();
      const now = DateTime.utc().startOf("day");

      storage.setData({
        filterProjectStartDate: dateTime?.toISODate(),
        filterProjectSaveDate: now.toISODate(),
      });
    },
    [toDate],
  );

  const updateToDate = useCallback(
    (dateTime: DateTime) => {
      isDateRangeSelectedByUser.current = true;

      if (dateTime < fromDate) {
        return;
      }
      if (dateTime.toMillis() < 0) {
        // Firestore does not allow very small timestamps
        return;
      }
      setDateRangeInternal(({ startDate }) => ({
        startDate,
        endDate: dateTime.toJSDate(),
      }));
      const storage = useLocalStorage.getState();
      const now = DateTime.utc().startOf("day");
      storage.setData({
        filterProjectEndDate: dateTime?.toISODate(),
        filterProjectSaveDate: now.toISODate(),
      });
    },
    [fromDate],
  );

  const shouldDisableFromDate = useCallback(
    (date: DateTime) => {
      return date > toDate;
    },
    [toDate],
  );

  const shouldDisableToDate = useCallback(
    (date: DateTime) => {
      return date < fromDate || date > now.endOf("day");
    },
    [fromDate, now],
  );

  return {
    fromDate,
    toDate,
    dateRange,
    isDateRangeSelectedByUser: isDateRangeSelectedByUser.current,
    setFromDate: updateFromDate,
    setToDate: updateToDate,
    setDateRange,
    shouldDisableFromDate,
    shouldDisableToDate,
  };
}
