import {
  CallFlaggingConfigConditionStruct,
  CallFlaggingConfigParameterOperatorStruct,
  CallFlaggingConfigParameterTypeStruct,
  CallFlaggingConfigParameterValueStruct,
} from "@snubes/snubes-types";
import {
  array,
  boolean,
  Infer,
  nullable,
  string,
  type,
  union,
} from "superstruct";

const ProjectCallFlaggingConfigsFormValuesParameterStruct = type({
  id: string(),
  type: union([
    CallFlaggingConfigParameterTypeStruct,
    string(), // allows empty strings in the form
  ]),
  operator: union([
    CallFlaggingConfigParameterOperatorStruct,
    string(), // allows empty strings in the form
  ]),
  value: CallFlaggingConfigParameterValueStruct,
});

export type ProjectCallFlaggingConfigsFormValuesParameter = Infer<
  typeof ProjectCallFlaggingConfigsFormValuesParameterStruct
>;

export const ProjectCallFlaggingConfigsFormValuesStruct = type({
  configs: array(
    type({
      id: string(),
      condition: CallFlaggingConfigConditionStruct,
      reviewerUserId: nullable(string()),
      issueCategoryId: nullable(string()),
      parameters: array(ProjectCallFlaggingConfigsFormValuesParameterStruct),
    }),
  ),
  isAppliedToHistoricData: boolean(),
  checkExistingCallsFromDate: string(),
});

export type ProjectCallFlaggingConfigsFormValues = Infer<
  typeof ProjectCallFlaggingConfigsFormValuesStruct
>;
