import { UserType } from "@snubes/snubes-types";
import { useCallback, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { updateUserCallable } from "../../Users/callables/updateUserCallable";
import { useMyUser } from "../../Users/hooks/useMyUser";

export function useSetUserType() {
  const isInitializing = useMyUser((state) => state.isInitializing);
  const userId = useMyUser((state) => state.user?.id);
  const currentUserType = useMyUser.getState().user?.userType;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setUserType = useCallback(
    async (userType: UserType) => {
      if (isSubmitting || isInitializing || !userId) return;
      if (userType === currentUserType) return;

      try {
        setIsSubmitting(true);
        await updateUserCallable({ userId, user: { userType } });
      } catch (error) {
        handleError(error).logAnd().toast();
      } finally {
        setIsSubmitting(false);
      }
    },
    [currentUserType, isInitializing, isSubmitting, userId],
  );

  return [setUserType, isSubmitting] as const;
}
