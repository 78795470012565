import { Log } from "@snubes/snubes-types";
import { RelativeTimestampView } from "../../Common/views/RelativeTimestampView";
import { useT } from "../../Translation/hooks/useT";
import { LogListItem } from "../types/LogListItem";

export function useLogListItemProps<T extends Log>(props: LogListItem<T>) {
  const t = useT();
  return {
    createdAt: props.log.createdAt,
    createdByName: props.log.updatedBy.name,
    message: (
      <RelativeTimestampView
        timestamp={props.log.createdAt}
        translation={
          t("LogListItemView_Label_TimeAgoBy", {
            name: props.log.updatedBy.name,
          }) as `${string}{diff}${string}`
        }
        shouldBeInThePast
      />
    ),
    isFirstItem: props.isFirstItem,
    isLastItem: props.isLastItem,
    onClick: props.onClick,
  };
}
