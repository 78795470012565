import { DefinedRange } from "@snubes/daterange-picker";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useT } from "../../Translation/hooks/useT";

export function useDefaultDateRanges() {
  const t = useT();

  return useMemo<DefinedRange[]>(() => {
    const todayStart = DateTime.now().startOf("day");
    const todayEnd = DateTime.now().endOf("day");
    return [
      {
        label: t("useDefaultDateRanges_Label_Today"),
        startDate: todayStart.toJSDate(),
        endDate: todayEnd.toJSDate(),
      },
      {
        label: t("useDefaultDateRanges_Label_Yesterday"),
        startDate: todayStart.minus({ days: 1 }).toJSDate(),
        endDate: todayEnd.minus({ days: 1 }).toJSDate(),
      },
      {
        label: t("useDefaultDateRanges_Label_This_week"),
        startDate: DateTime.now().startOf("week").toJSDate(),
        endDate: todayEnd.toJSDate(),
      },
      {
        label: t("useDefaultDateRanges_Label_Last_week"),
        startDate: DateTime.now()
          .startOf("week")
          .minus({ weeks: 1 })
          .toJSDate(),
        endDate: DateTime.now().endOf("week").minus({ weeks: 1 }).toJSDate(),
      },
      {
        label: t("useDefaultDateRanges_Label_Last_n_days", { count: 7 }),
        startDate: todayStart.minus({ days: 8 }).toJSDate(),
        endDate: todayEnd.minus({ days: 1 }).toJSDate(),
      },
      {
        label: t("useDefaultDateRanges_Label_This_month"),
        startDate: DateTime.now().startOf("month").toJSDate(),
        endDate: todayEnd.toJSDate(),
      },
      {
        label: t("useDefaultDateRanges_Label_Last_month"),
        startDate: DateTime.now()
          .startOf("month")
          .minus({ months: 1 })
          .toJSDate(),
        endDate: DateTime.now().endOf("month").minus({ months: 1 }).toJSDate(),
      },
      {
        label: t("useDefaultDateRanges_Label_This_year"),
        startDate: DateTime.now().startOf("year").toJSDate(),
        endDate: todayEnd.toJSDate(),
      },
    ];
  }, [t]);
}
