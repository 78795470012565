import { CallFlaggingConfigParameterOperator } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface CallFlaggingConfigParameterOperatorRecordValue {
  label: TranslationKey;
}

export const CALL_FLAGGING_CONFIG_PARAMETER_OPERATOR_RECORD: Record<
  CallFlaggingConfigParameterOperator,
  CallFlaggingConfigParameterOperatorRecordValue
> = {
  EQUALS: { label: "CallFlaggingParameter_Operator_Equals" },
  NOT_EQUALS: { label: "CallFlaggingParameter_Operator_NotEquals" },
  GREATER_THAN: { label: "CallFlaggingParameter_Operator_GreaterThan" },
  LESS_THAN: { label: "CallFlaggingParameter_Operator_LessThan" },
  CONTAINS: { label: "CallFlaggingParameter_Operator_Contains" },
  EXISTS: { label: "CallFlaggingParameter_Operator_Exists" },
};
