import { Stack } from "@mui/material";
import { FC } from "react";
import {
  HEADER_HEIGHT,
  SECONDARY_HEADER_HEIGHT,
} from "../consts/HEADER_HEIGHT";
import { useHeaderConfig } from "../hooks/useHeaderConfig";

export const HeaderSpacerView: FC = () => {
  const secondaryHeaderHeight = useHeaderConfig((state) =>
    state.hasSecondaryHeader ? SECONDARY_HEADER_HEIGHT : 0,
  );

  return <Stack height={HEADER_HEIGHT + secondaryHeaderHeight} />;
};
