import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import {
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { CollapseExpandView } from "../../Common/views/CollapseExpandView";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";
import { SFTP_CSV_FIELDS } from "../consts/SFTP_CSV_FIELDS";
interface Props {
  title: string;
  csvPath?: string;
}

export const ConnectorConfigSftpHelpView: FC<Props> = (props) => {
  const t = useT();

  return (
    <Paper variant="outlined">
      <CollapseExpandView
        defaultExpanded
        headerContent={
          <Stack
            color="primary.main"
            direction="row"
            spacing={2}
            alignItems="center"
          >
            <TipsAndUpdatesIcon />
            <Typography variant="h5">{props.title}</Typography>
          </Stack>
        }
        sx={{
          bgcolor: "background.default",
        }}
      >
        <Stack p={3} spacing={3}>
          <Typography variant="subtitle2">
            {t("ConnectorConfigSftpHelpView_Subtitle_NeedCsvFile")}
          </Typography>
          <Typography variant="h5">
            {t("ConnectorConfigSftpHelpView_Heading_CsvPath")}
          </Typography>
          <Stack spacing={2} direction="row">
            <StatusChip label={props.csvPath || "/index.csv"} color="primary" />
          </Stack>

          <Divider />

          <Typography variant="h5">
            {t("ConnectorConfigSftpHelpView_Heading_CsvFileStructure")}
          </Typography>
          <TableContainer component={Paper} variant="outlined">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h4">
                      {t("ConnectorConfigSftpHelpView_Heading_Name")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h4">
                      {t("ConnectorConfigSftpHelpView_Heading_Example")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h4">
                      {t("ConnectorConfigSftpHelpView_Heading_Description")}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {SFTP_CSV_FIELDS.map((field, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="caption1">{field.name}</Typography>
                        {!field.isMandatory && (
                          <Typography color="text.600" variant="caption1">
                            {t("ConnectorConfigSftpHelpView_Column_Optional")}
                          </Typography>
                        )}
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption1">
                        {field.example}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="caption1">
                        {t(field.description)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </CollapseExpandView>
    </Paper>
  );
};
