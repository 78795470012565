import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { ProjectReportFrameLog } from "@snubes/snubes-types";
import { useLogListItemProps } from "../helpers/useLogListItemProps";
import { LogListItem } from "../types/LogListItem";
import { LogListItemView } from "./LogListItemView";

export function LogListItemViewForProjectReportFrame<
  T extends ProjectReportFrameLog,
>(props: LogListItem<T>) {
  const restProps = useLogListItemProps(props);

  if (props.log.update.id) {
    return (
      <LogListItemView
        {...restProps}
        title="Report frame created"
        iconColor="secondary.main"
        iconComponent={AddCircleIcon}
      />
    );
  }

  return (
    <LogListItemView
      {...restProps}
      title="Report frame edited"
      iconColor="primary.main"
      iconComponent={ChangeCircleIcon}
    />
  );
}
