import {
  Alert,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import {
  FirebaseFilter,
  FirebaseOrderBy,
  isTender,
  Tender,
  UserType,
} from "@snubes/snubes-types";
import { FC, PropsWithChildren } from "react";
import { usePaginatedCollection } from "../../Firebase/hooks/usePaginatedCollection";
import { CollectionSortBy } from "../../Firebase/types/CollectionSortBy";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { TenderApplicationsDeadlineRowView } from "../../Tenders/views/TenderApplicationsDeadlineRowView";
import { TenderApplicationsStatusChipsView } from "../../Tenders/views/TenderApplicationsStatusChipsView";
import { TenderApplicationStatusChip } from "../../Tenders/views/TenderApplicationStatusChip";
import { TenderStatusChip } from "../../Tenders/views/TenderStatusChip";
import { useT } from "../../Translation/hooks/useT";
import { HomeCardActionButton } from "./HomeCardActionButton";
import { HomeCardCountView } from "./HomeCardCountView";
import { HomeCardView } from "./HomeCardView";

interface Props extends PropsWithChildren {
  filters: FirebaseFilter<Tender>[];
  orderBy?: FirebaseOrderBy<Tender>;
  sortBy?: CollectionSortBy<Tender>;
  variant: Exclude<UserType, "QM">;
  maxCount: number;
}

export const HomeCardTendersView: FC<Props> = (props) => {
  const { filters, orderBy, sortBy, variant, maxCount } = props;
  const t = useT();

  // If sortBy is set, we need to fetch more tenders to be able to sort them on the client-side.
  // We always need to fetch +1 item to determine wether to show the "+" character or not.
  const rowsPerPage = sortBy ? 10 * maxCount + 1 : maxCount + 1;

  const { rows: tenders, error } = usePaginatedCollection({
    collectionName: "tenders",
    isT: isTender,
    orderBy,
    sortBy,
    filters,
    rowsPerPage,
  });
  const selectedOrganizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );

  return (
    <HomeCardView
      title={
        <Stack direction="row" spacing={2}>
          <Typography variant="h4">
            {t("HomeCardTendersView_Title_Tenders")}
          </Typography>
          <HomeCardCountView count={tenders.length} maxCount={maxCount} />
        </Stack>
      }
      actions={
        <HomeCardActionButton
          title={t("HomeCardTendersView_Button_SeeAllTenders")}
          href="/tenders"
        />
      }
    >
      <List disablePadding>
        {error && <Alert severity="error">{error.message}</Alert>}
        {tenders?.slice(0, maxCount).map((tender, i, all) => {
          const tenderApplication = selectedOrganizationId
            ? tender?.providerApplications?.[selectedOrganizationId]
            : undefined;

          return (
            <ListItem
              key={tender.id}
              disableGutters
              divider={i + 1 < all.length}
              component={Stack}
              spacing={3}
              direction="row"
            >
              <ListItemText>
                <Link
                  href={tender && `/tenders/${tender.id}`}
                  sx={{
                    display: "list-item",
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                    textOverflow: "ellipsis",
                    color: "inherit",
                    textDecoration: "none",
                    "&:hover": {
                      backgroundColor: "grey.50",
                    },
                  }}
                >
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item flex={1}>
                      {variant === "PROVIDER" ? (
                        <TenderApplicationStatusChip
                          tenderApplication={tenderApplication}
                        />
                      ) : (
                        <TenderStatusChip status={tender.status} />
                      )}
                    </Grid>

                    <Grid item flex={1}>
                      <Typography>{tender.name}</Typography>
                    </Grid>

                    {variant === "CLIENT" && (
                      <Grid item flex={1}>
                        <TenderApplicationsStatusChipsView tender={tender} />
                      </Grid>
                    )}

                    <Grid item flex={1}>
                      {tender.timetable?.applicationDeadlineAt && (
                        <TenderApplicationsDeadlineRowView
                          date={tender.timetable.applicationDeadlineAt}
                          translationKeyLabel="TenderApplicationsDeadlineView_ApplicationDeadlineAt"
                        />
                      )}
                      {tender.timetable?.estimatedProjectStartAt && (
                        <TenderApplicationsDeadlineRowView
                          date={tender.timetable.estimatedProjectStartAt}
                          translationKeyLabel="TenderApplicationsDeadlineView_EstimatedProjectStartAt"
                        />
                      )}
                    </Grid>
                  </Grid>
                </Link>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </HomeCardView>
  );
};
