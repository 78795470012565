import { Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useT } from "../../Translation/hooks/useT";
import { AdminConnectorConfigsCollectionTableView } from "./AdminConnectorConfigsCollectionTableView";

export const AdminConnectorConfigsPage: FC = () => {
  const t = useT();
  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer>
        <Stack py={5} spacing={3}>
          <Typography variant="h2">
            {t("AdminConnectorConfigsPage_Headline_Connections")}
          </Typography>
          <Paper>
            <AdminConnectorConfigsCollectionTableView />
          </Paper>
        </Stack>
      </FullHeightContainer>
    </>
  );
};
