import { createContext, FC, PropsWithChildren, useContext } from "react";

const FirebaseTableRowSelectionContext = createContext<string[] | null>(null);

interface Props extends PropsWithChildren {
  selectedRowIds?: string[];
}

export const FirebaseTableRowSelectionProvider: FC<Props> = (props) => {
  return (
    <FirebaseTableRowSelectionContext.Provider
      value={props.selectedRowIds || DEFAULT_ROW_IDS}
    >
      {props.children}
    </FirebaseTableRowSelectionContext.Provider>
  );
};

export function useFirebaseTableRowSelectionContext() {
  const context = useContext(FirebaseTableRowSelectionContext);
  if (!context) {
    throw new Error("No FirebaseTableRowSelectionContext provided.");
  }
  return context;
}

const DEFAULT_ROW_IDS: string[] = [];
