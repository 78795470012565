import { Paper, Stack } from "@mui/material";
import { FC, ReactNode } from "react";

interface GenericCardProps {
  children: ReactNode;
  bgcolor?: string;
  elevation?: number;
  isOutlined?: boolean;
}

export const HomePageInfoPanelCard: FC<GenericCardProps> = (props) => {
  const { bgcolor, children, isOutlined, elevation = 0 } = props;
  return (
    <Stack
      bgcolor={bgcolor}
      component={Paper}
      elevation={elevation}
      variant={isOutlined ? "outlined" : "elevation"}
      justifyContent="space-around"
      height="100%"
      p={5}
      spacing={3}
    >
      {children}
    </Stack>
  );
};
