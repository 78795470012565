import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import {
  Avatar,
  Button,
  CircularProgress,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Invitation } from "@snubes/snubes-types";
import { FC, useCallback, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { getAvatarName } from "../../Common/helpers/getAvatarName";
import { handleError } from "../../Common/helpers/handleError";
import { auth } from "../../Firebase/consts/FIREBASE";
import { replyInvitationCallable } from "../../Invitations/callables/replyInvitationCallable";
import { useT } from "../../Translation/hooks/useT";
import { T } from "../../Translation/views/T";
import { USER_TYPE_RECORD } from "../../Users/consts/USER_TYPE_RECORD";
import { useMyUser } from "../../Users/hooks/useMyUser";

interface Props {
  invitations: Invitation[];
  hideInvitations: (value: boolean) => void;
}

export const OnboardingInvitationsView: FC<Props> = (props) => {
  const { invitations, hideInvitations } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [authState] = useAuthState(auth);
  const userType = useMyUser((state) => state.user?.userType);
  const navigate = useNavigate();
  const t = useT();
  // TODO: enable HOME and remove feature flag

  const handleAcceptInvitation = useCallback(
    async (invitationId: string) => {
      try {
        setIsSubmitting(true);
        await replyInvitationCallable({
          invitationId,
          reply: "accept",
        });
        navigate(
          userType ? `${USER_TYPE_RECORD[userType].homeScreenPath}` : "/",
        );
      } catch (error) {
        handleError(error).logAnd().toast();
      } finally {
        setIsSubmitting(false);
      }
    },
    [navigate, userType],
  );

  return (
    <Stack spacing={3} pt={4}>
      <Stack textAlign="center" spacing={4} pb={2}>
        <Typography variant="h2">
          {t("OnboardingOrganizationInviteView_Headline_Accept")}
        </Typography>
        <Typography color="text.500" variant="subtitle1">
          {t("OnboardingOrganizationInviteView_Subtitle_Join")}
        </Typography>
      </Stack>
      {invitations.map((invitation) => (
        <Stack
          key={invitation.id}
          spacing={4}
          p={5}
          component={Paper}
          variant="outlined"
        >
          <Stack
            spacing={2}
            color="text.500"
            alignItems="center"
            direction="row"
            justifyContent="center"
          >
            <MarkEmailUnreadIcon />
            <InvitationRecievedTypography variant="subtitle2">
              <T
                k="OnboardingOrganizationInviteView_Subtitle_InvitaionRecieved"
                options={{ email: authState?.email }}
              />
            </InvitationRecievedTypography>
          </Stack>
          <Stack spacing={3} alignItems="center">
            <Avatar sx={{ bgcolor: "warning.main" }}>
              {getAvatarName(invitation.organizationName)}
            </Avatar>
            <Typography variant="h4">{invitation.organizationName}</Typography>
          </Stack>
          <Button
            variant="contained"
            fullWidth
            onClick={() => handleAcceptInvitation(invitation.id)}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={24} />
            ) : (
              <span>
                {t("OnboardingOrganizationInviteView_Button_Invitation", {
                  organizationName: invitation.organizationName,
                })}
              </span>
            )}
          </Button>
        </Stack>
      ))}
      <Stack
        textAlign="center"
        spacing={3}
        p={3}
        component={Paper}
        variant="outlined"
      >
        <Typography variant="subtitle2">
          {t("OnboardingOrganizationInviteView_Subtitle_CreateOrganization")}
        </Typography>
        <CreateOrganizationButton
          onClick={() => hideInvitations(false)}
          variant="outlined"
        >
          {t("OnboardingOrganizationInviteView_Button_CreateOrganization")}
        </CreateOrganizationButton>
      </Stack>
    </Stack>
  );
};

const CreateOrganizationButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderColor: theme.palette.text.primary,
  textTransform: "uppercase",
  "&:hover": { borderColor: theme.palette.text.primary },
}));

const InvitationRecievedTypography = styled(Typography)(({ theme }) => ({
  "& strong": { color: theme.palette.text.primary },
}));
