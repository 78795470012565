import { Box, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { useStore } from "zustand";
import { useFirebaseTableFilterStore } from "../hooks/useFirebaseTableFilters";
import { FirebaseTableFilterMenuAutocompleteView } from "./FirebaseTableFilterAutoCompleteView";
import { FirebaseTableFilterNumberView } from "./FirebaseTableFilterNumberView";
import { FirebaseTableFilterSelectView } from "./FirebaseTableFilterSelectView";
import { FirebaseTableFilterTextView } from "./FirebaseTableFilterTextView";

interface Props {
  filterName: string;
}

export const FirebaseTableFilterMenuSelectedFilterView: FC<Props> = (props) => {
  const { filterName } = props;

  const filterStore = useFirebaseTableFilterStore();
  const { filterType, filterPlaceholder } = useStore(
    filterStore,
    useCallback(
      (state) => {
        const filter = state.filters[filterName];
        const filterType = filter?.type;
        const filterPlaceholder =
          filterType === "text" ? filter?.placeholder : undefined;
        return { filterType, filterPlaceholder };
      },
      [filterName],
    ),
  );

  switch (filterType) {
    case "autocomplete":
      return (
        <Box pt={5} width={300}>
          <FirebaseTableFilterMenuAutocompleteView filterName={filterName} />
        </Box>
      );
    case "select":
      return (
        <Box pt={5} width={300}>
          <FirebaseTableFilterSelectView filterName={filterName} />
        </Box>
      );
    case "text":
      return (
        <Box pt={5} width={300}>
          <FirebaseTableFilterTextView
            fullWidth
            filterName={filterName}
            placeholder={filterPlaceholder}
          />
        </Box>
      );
    case "number":
      return (
        <Box pt={5} width={300}>
          <FirebaseTableFilterNumberView
            fullWidth
            filterName={filterName}
            placeholder={filterPlaceholder}
          />
        </Box>
      );
    default:
      return (
        <Typography>
          Not implemented: {filterName} {filterType}
        </Typography>
      );
  }
};
