import { superstructResolver } from "@hookform/resolvers/superstruct";
import { Button, Paper, Stack, Typography } from "@mui/material";
import { ConnectorConfig } from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Infer, number, optional, string, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { updateConnectorConfigCallable } from "../callables/updateConnectorConfigCallable";
import { DEFAULT_BACKFILL_DAYS } from "../consts/DEFAULT_BACKFILL_DAYS";
import { SFTP_INTERRUPTION_REASONS } from "../consts/SFTP_INTERRUPTION_REASONS";
import { getBackfillDate } from "../helpers/getBackfillDate";
import { getDaysToBackfill } from "../helpers/getDaysToBackfill";
import { ConnectorConfigBackfillDaysFormFieldsView } from "./ConnectorConfigBackfillDaysFormFieldsView";
import { ConnectorConfigErrorView } from "./ConnectorConfigErrorView";
import { ConnectorConfigSftpFormHeaderView } from "./ConnectorConfigSftpFormHeaderView";
import { ConnectorConfigSftpHelpView } from "./ConnectorConfigSftpHelpView";

interface Props {
  connectorConfig: ConnectorConfig & { platform: "SFTP" };
}

const ConnectorConfigSftpEditFormValuesStruct = type({
  password: string(),
  name: string(),
  host: string(),
  user: string(),
  csvPath: string(),
  port: optional(number()),
  backfillDate: string(),
  backfillDays: number(),
});

export type ConnectorConfigSftpEditFormValues = Infer<
  typeof ConnectorConfigSftpEditFormValuesStruct
>;

export const ConnectorConfigSftpEditForm: FC<Props> = (props) => {
  const { connectorConfig } = props;
  const t = useT();
  const { organizationId, connectorConfigId } = useParams<
    "organizationId" | "connectorConfigId"
  >();

  const form = useForm<ConnectorConfigSftpEditFormValues>({
    resolver: superstructResolver(ConnectorConfigSftpEditFormValuesStruct),
    defaultValues: {
      name: connectorConfig.name,
      password: connectorConfig.password,
      host: connectorConfig.host,
      user: connectorConfig.user,
      csvPath: connectorConfig.csvPath,
      port: connectorConfig.port,
      backfillDate: getBackfillDate(DEFAULT_BACKFILL_DAYS),
      backfillDays: DEFAULT_BACKFILL_DAYS,
    },
  });
  const { control, formState, handleSubmit, watch, getValues, reset } = form;

  const onSubmit = useCallback(
    async (formValues: ConnectorConfigSftpEditFormValues) => {
      try {
        if (!organizationId) {
          throw new Error("No organization selected");
        }
        if (!connectorConfigId) {
          throw new Error("No connectorConfig selected");
        }
        await updateConnectorConfigCallable({
          connectorConfigId,
          organizationId,
          connectorConfig: {
            name: formValues.name,
            host: formValues.host,
            user: formValues.user,
            csvPath: formValues.csvPath,
            password: formValues.password,
            projectMappings: connectorConfig.projectMappings,
            backfillDays: getDaysToBackfill(formValues.backfillDate),
            ...(formValues.port && { port: formValues.port }),
          },
        });
        toast.success(t("ConnectorConfigForm_Toast_Update_Success"));
        reset(formValues);
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [
      connectorConfig.projectMappings,
      connectorConfigId,
      organizationId,
      reset,
      t,
    ],
  );

  const csvpath = watch("csvPath");
  const { isSubmitting } = formState;

  return (
    <>
      {connectorConfig.status === "INTERRUPTED" &&
        organizationId &&
        connectorConfig.statusErrorMessage && (
          <ConnectorConfigErrorView
            statusErrorMessage={connectorConfig.statusErrorMessage}
            pathPrefix={`/organizations/${organizationId}/connector-configs/${connectorConfig.id}`}
            title={t("ConnectorConfigSftpEditForm_Title_ConnectionInterrupted")}
            interruptionReasons={SFTP_INTERRUPTION_REASONS}
          />
        )}

      <Stack component={Paper} variant="outlined" p={4} spacing={5}>
        <ConnectorConfigSftpFormHeaderView
          isFormDirty={formState.isDirty}
          connectorConfigSftpGetValues={getValues}
        />
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <ControlledTextField
                autoComplete="off"
                required
                fullWidth
                name="name"
                label={t("ConnectorConfigSftpEditForm_InputLabel_Name")}
                control={control}
                disabled={isSubmitting}
              />
              <ConnectorConfigSftpHelpView
                title={t("ConnectorConfigSftpEditForm_Title_HavingTrouble")}
                csvPath={csvpath || connectorConfig.csvPath}
              />

              <Typography textTransform="uppercase" variant="subtitle1">
                {t("ConnectorConfigSftpEditForm_SubHeading_Credentials")}
              </Typography>
              <ControlledTextField
                required
                fullWidth
                autoComplete="off"
                name="host"
                label={t("ConnectorConfigSftpEditForm_InputLabel_Host")}
                control={control}
                disabled={isSubmitting}
              />
              <ControlledTextField
                required
                fullWidth
                autoComplete="off"
                name="user"
                label={t("ConnectorConfigSftpEditForm_InputLabel_User")}
                control={control}
                disabled={isSubmitting}
              />

              <ControlledTextField
                required
                autoComplete="off"
                fullWidth
                name="password"
                type="password"
                label={t("ConnectorConfigSftpEditForm_InputLabel_Password")}
                control={control}
              />
              <ControlledTextField
                required
                fullWidth
                autoComplete="off"
                name="csvPath"
                label={t("ConnectorConfigSftpEditForm_InputLabel_CsvPath")}
                control={control}
                disabled={isSubmitting}
              />
              <ControlledTextField
                fullWidth
                autoComplete="off"
                type="number"
                name="port"
                label={t("ConnectorConfigSftpEditForm_InputLabel_Port")}
                control={control}
                disabled={isSubmitting}
              />

              <ConnectorConfigBackfillDaysFormFieldsView />

              <Button type="submit" variant="contained" disabled={isSubmitting}>
                {t("Common_Update")}
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </>
  );
};
