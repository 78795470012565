import isoCountries, { LocaleData } from "i18n-iso-countries";
import de from "i18n-iso-countries/langs/de.json";
import en from "i18n-iso-countries/langs/en.json";
import { getCurrentLocale } from "../../Translation/helpers/getCurrentLocale";

// register english and german as we support both on our platform
isoCountries.registerLocale(de as LocaleData);
isoCountries.registerLocale(en as LocaleData);

export function getCountryName(code: string) {
  const currentLanguage = getCurrentLocale();
  return isoCountries.getName(code, currentLanguage) || code;
}
