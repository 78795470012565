import { superstructResolver } from "@hookform/resolvers/superstruct";
import { Mark } from "@mui/base";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import {
  LanguageStruct,
  PROJECT_TIERS,
  ProjectTierStruct,
} from "@snubes/snubes-types";
import { FC } from "react";
import { useForm } from "react-hook-form";
import {
  boolean,
  Infer,
  integer,
  literal,
  nullable,
  string,
  type,
  union,
} from "superstruct";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { ControlledLanguageSelect } from "../../Form/views/ControlledLanguageSelect";
import { ControlledOrganizationIdAutocomplete } from "../../Form/views/ControlledOrganizationIdAutocomplete";
import { ControlledSelect } from "../../Form/views/ControlledSelect";
import { ControlledSlider } from "../../Form/views/ControlledSlider";
import { ControlledSwitch } from "../../Form/views/ControlledSwitch";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { ControlledUserIdAutocomplete } from "../../Form/views/ControlledUserIdAutocomplete";
import { useT } from "../../Translation/hooks/useT";
import { PROJECT_TIER_RECORD } from "../consts/PROJECT_TIER_RECORD";
import { getProjectOrganizationIds } from "../helpers/getProjectOrganizationIds";
import { useProjectContext } from "../hooks/useProjectContext";

interface Props {
  onSubmit: (values: ProjectFormValues) => void;
}

const ProjectFormValuesStruct = type({
  name: string(),
  providerOrganizationId: nullable(string()),
  clientOrganizationId: nullable(string()),
  tier: ProjectTierStruct,
  projectManagerUserId: nullable(string()),
  isLookerAnalyticsEnabled: boolean(),
  voicefileLanguage: union([LanguageStruct, literal("")]),
  responseLanguage: LanguageStruct,
  callSamplingRate: integer(),
});

export type ProjectFormValues = Infer<typeof ProjectFormValuesStruct>;

export const ProjectForm: FC<Props> = (props) => {
  const { onSubmit } = props;
  const { project } = useProjectContext();
  const t = useT();
  const hasPermission = useHasPermission();
  const canUpdateProjectAll = hasPermission("CAN_UPDATE_PROJECT_ALL", {
    organizationId: project?.providerOrganizationId,
  });
  const canUpdateProjectLimited = hasPermission("CAN_UPDATE_PROJECT_LIMITED", {
    organizationIds: getProjectOrganizationIds(project),
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ProjectFormValues>({
    resolver: superstructResolver(ProjectFormValuesStruct),
    defaultValues: {
      name: project?.name || "",
      providerOrganizationId: project?.providerOrganizationId || null,
      clientOrganizationId: project?.clientOrganizationId || null,
      projectManagerUserId: project?.projectManagerUserId || null,
      tier: project?.tier || "PLATFORM",
      isLookerAnalyticsEnabled: !!project?.isLookerAnalyticsEnabled,
      voicefileLanguage: project?.voicefileLanguage || "",
      responseLanguage: project?.responseLanguage || DEFAULT_RESPONSE_LANGUAGE,
      callSamplingRate: project?.callSamplingRate || 100,
    },
  });

  const tierOptions = PROJECT_TIERS.map((tier) => {
    return {
      value: tier,
      label: t(PROJECT_TIER_RECORD[tier].label),
    };
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <ControlledTextField
          required
          fullWidth
          name="name"
          label={t("ProjectForm_Label_ProjectTitle")}
          control={control}
          disabled={isSubmitting || !canUpdateProjectLimited}
          autoComplete="off"
        />
        {canUpdateProjectAll && !project && (
          <ControlledOrganizationIdAutocomplete
            fullWidth
            required
            name="providerOrganizationId"
            control={control}
            label={t("ProjectForm_Label_OwnerOrganization")}
            disabled={isSubmitting}
          />
        )}
        {canUpdateProjectAll && (
          <ControlledOrganizationIdAutocomplete
            fullWidth
            name="clientOrganizationId"
            control={control}
            label={t("ProjectForm_Label_ViewerOrganization")}
            disabled={isSubmitting}
          />
        )}
        {canUpdateProjectAll && (
          <ControlledUserIdAutocomplete
            fullWidth
            name="projectManagerUserId"
            control={control}
            userRoles={["USER_ADMIN"]}
            label={t("ProjectForm_Label_SelectProjectManagerOptional")}
            disabled={isSubmitting}
          />
        )}
        {canUpdateProjectAll && (
          <ControlledSelect
            label={t("ProjectForm_Label_Tier")}
            control={control}
            name="tier"
            items={tierOptions}
            renderItem={(item) => item.label}
            disabled={isSubmitting}
            fullWidth
          />
        )}
        {canUpdateProjectAll && (
          <>
            <Divider />
            <Stack direction="row">
              <Stack flex={1} spacing={2}>
                <Typography variant="h4">
                  {t("ProjectForm_Title_Analytics")}
                </Typography>
                <Typography variant="subtitle2">
                  {t("ProjectForm_Label_Analytics")}
                </Typography>
              </Stack>
              <Stack justifyContent="center">
                <ControlledSwitch
                  fullWidth
                  name="isLookerAnalyticsEnabled"
                  labelPlacement="start"
                  control={control}
                  disabled={isSubmitting}
                />
              </Stack>
            </Stack>
          </>
        )}

        <Divider />
        <Stack spacing={2}>
          <Typography variant="h4">
            {t("ProjectForm_Title_Languages")}
          </Typography>
          <Typography variant="subtitle2">
            {t("ProjectForm_Label_Languages")}
          </Typography>
        </Stack>
        <ControlledLanguageSelect
          label={t("ProjectForm_Label_VoicefileLanguage")}
          control={control}
          name="voicefileLanguage"
          emptyLabel={t("ProjectForm_EmptyLabel_VoicefileLanguage")}
          disabled={isSubmitting || !canUpdateProjectLimited}
          fullWidth
        />
        <ControlledLanguageSelect
          label={t("ProjectForm_Label_ResponseLanguage")}
          control={control}
          name="responseLanguage"
          disabled={isSubmitting || !canUpdateProjectLimited}
          fullWidth
        />

        <Divider />
        <Stack spacing={2}>
          <Typography variant="h4">
            {t("ProjectForm_Title_CallSamplingRate")}
          </Typography>
          <Typography variant="subtitle2">
            {t("ProjectForm_Label_CallSamplingRate")}
          </Typography>
        </Stack>
        <Box py={2} px={3}>
          <ControlledSlider
            control={control}
            name="callSamplingRate"
            disabled={isSubmitting || !canUpdateProjectLimited}
            min={0}
            max={100}
            marks={CALL_SAMPLING_RATE_MARKS}
            step={1}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${value}%`}
            sx={(theme) => ({
              "& .MuiSlider-markLabel": {
                fontSize: theme.typography.body1.fontSize,
              },
            })}
          />
        </Box>

        <Stack direction="row">
          <Button
            fullWidth
            type="submit"
            variant="contained"
            disabled={
              isSubmitting || !(canUpdateProjectAll || canUpdateProjectLimited)
            }
          >
            {!project && t("ProjectForm_Button_AddProject")}
            {project && t("Common_Save")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

/**
 * @deprecated
 * TODO: get this from organization instead!
 */
const DEFAULT_RESPONSE_LANGUAGE = "en";

const CALL_SAMPLING_RATE_MARKS: Mark[] = [
  { label: "0%", value: 0 },
  { label: "10%", value: 10 },
  { label: "20%", value: 20 },
  { label: "30%", value: 30 },
  { label: "40%", value: 40 },
  { label: "50%", value: 50 },
  { label: "60%", value: 60 },
  { label: "70%", value: 70 },
  { label: "80%", value: 80 },
  { label: "90%", value: 90 },
  { label: "100%", value: 100 },
];
