import { TranslationKey } from "../../Translation/types/TranslationKey";
import { TIME_OF_DAY_GREETING_RECORD } from "../consts/TIME_OF_DAY_GREETING_RECORD";
import { TimeOfDay } from "../types/TimeOfDay";

export function getTimeOfDayGreeting(): TranslationKey {
  return TIME_OF_DAY_GREETING_RECORD[getTimeOfDay()].label;
}

function getTimeOfDay(): TimeOfDay {
  const currentHour = new Date().getHours();
  if (currentHour >= 5 && currentHour < 12) {
    return "MORNING";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "AFTERNOON";
  } else if (currentHour >= 18 && currentHour < 22) {
    return "EVENING";
  } else {
    return "OTHER";
  }
}
