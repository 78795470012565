import { superstructResolver } from "@hookform/resolvers/superstruct";
import { Button, Grid, Stack } from "@mui/material";
import {
  Member,
  MEMBER_ROLES,
  MemberRole,
  UpdateMemberRequest,
} from "@snubes/snubes-types";
import { FC } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Infer, string, Struct, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledSelect } from "../../Form/views/ControlledSelect";
import { PaperWithTitleView } from "../../Organizations/views/OrganizationEditForm/PaperWithTitleView";
import { useT } from "../../Translation/hooks/useT";
import { updateMemberCallable } from "../callables/updateMemberCallable";
import { MEMBER_ROLE_RECORD } from "../consts/MEMBER_ROLE_RECORD";

const FormValuesStruct = type({
  role: string() as Struct<MemberRole>,
});

type FormValues = Infer<typeof FormValuesStruct>;

interface Props {
  member: Member;
}

export const MemberEditForm: FC<Props> = (props) => {
  const params = useParams<"organizationId">();
  const { member } = props;
  const t = useT();

  const roleOptions = MEMBER_ROLES.map((role) => {
    return {
      value: role,
      label: t(MEMBER_ROLE_RECORD[role].label),
    };
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      role: member.role || "MEMBER_REGULAR",
    },
  });

  const onSubmit = async (formValues: FormValues) => {
    try {
      const memberUpdate: UpdateMemberRequest["member"] = {
        ...formValues,
      };

      if (!params.organizationId) {
        throw new Error(t("MemberEditForm_Error_OrganizationIdMissing"));
      }

      await updateMemberCallable({
        organizationId: params.organizationId,
        memberId: member.id,
        member: memberUpdate,
      });

      toast.success(t("MemberEditForm_Toast_SuccessMessage"));
    } catch (error) {
      handleError(error).logAnd().toast();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} my={3}>
        <Grid item xs={12}>
          <PaperWithTitleView
            title={t("MemberEditForm_Title_BasicInformation")}
          >
            <Stack direction="row" spacing={3}>
              <ControlledSelect
                label={t("MemberEditForm_Select_RoleTitle")}
                control={control}
                name="role"
                items={roleOptions}
                renderItem={(item) => item.label}
                fullWidth
              />
              <Button type="submit" variant="contained" disabled={isSubmitting}>
                {t("Common_Save")}
              </Button>
            </Stack>
          </PaperWithTitleView>
        </Grid>
      </Grid>
    </form>
  );
};
