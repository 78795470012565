import { SxProps } from "@mui/material";
import { FC } from "react";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { NoDataView } from "../../Common/views/NoDataView";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  sx?: SxProps;
}

export const TendersBrowseSetupView: FC<Props> = (props) => {
  const t = useT();
  const organizationId = useSelectedOrganization((state) => state?.id);

  return (
    <FullHeightContainer maxWidth="sm" sx={{ display: "flex", ...props.sx }}>
      <NoDataView
        label={t("TendersBrowseSetupView_Label_MissingProviderProfile")}
        title={t(`TendersBrowseSetupView_Title_Main`)}
        subTitle={t(`TendersBrowseSetupView_Title_Secondary`)}
        button={{
          href: `/providers/${organizationId}/create`,
          label: t("TendersBrowseSetupView_Button_CreateProfile"),
          disabled: !organizationId,
        }}
      />
    </FullHeightContainer>
  );
};
