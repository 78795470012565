import { superstructResolver } from "@hookform/resolvers/superstruct";
import { Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { ConnectorConfig } from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Infer, number, string, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { updateConnectorConfigCallable } from "../callables/updateConnectorConfigCallable";
import { DEFAULT_BACKFILL_DAYS } from "../consts/DEFAULT_BACKFILL_DAYS";
import { getBackfillDate } from "../helpers/getBackfillDate";
import { getDaysToBackfill } from "../helpers/getDaysToBackfill";
import { ConnectorConfigBackfillDaysFormFieldsView } from "./ConnectorConfigBackfillDaysFormFieldsView";
import { ConnectorConfigErrorView } from "./ConnectorConfigErrorView";

interface Props {
  connectorConfig: ConnectorConfig & { platform: "VCC_LIVE" };
}

const FormValuesStruct = type({
  password: string(),
  name: string(),
  backfillDate: string(),
  backfillDays: number(),
});

type FormValues = Infer<typeof FormValuesStruct>;

export const ConnectorConfigVccLiveEditForm: FC<Props> = (props) => {
  const { connectorConfig } = props;
  const t = useT();
  const { organizationId, connectorConfigId } = useParams<
    "organizationId" | "connectorConfigId"
  >();

  const form = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      name: connectorConfig.name,
      password: connectorConfig.password,
      backfillDate: getBackfillDate(DEFAULT_BACKFILL_DAYS),
      backfillDays: DEFAULT_BACKFILL_DAYS,
    },
  });
  const { control, formState, handleSubmit } = form;

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!organizationId) {
          throw new Error("No organization selected");
        }
        if (!connectorConfigId) {
          throw new Error("No connectorConfig selected");
        }
        await updateConnectorConfigCallable({
          connectorConfigId,
          organizationId,
          connectorConfig: {
            name: formValues.name,
            password: formValues.password,
            backfillDays: getDaysToBackfill(formValues.backfillDate),
            projectMappings: connectorConfig.projectMappings,
          },
        });
        toast.success(t("ConnectorConfigForm_Toast_Update_Success"));
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [connectorConfig.projectMappings, connectorConfigId, organizationId, t],
  );

  const { isSubmitting } = formState;

  return (
    <>
      {connectorConfig.status === "INTERRUPTED" &&
        connectorConfig.statusErrorMessage && (
          <ConnectorConfigErrorView
            statusErrorMessage={connectorConfig.statusErrorMessage}
            title={t(
              "ConnectorConfigVccLiveEditForm_Title_ConnectionInterrupted",
            )}
          />
        )}
      <Stack component={Paper} variant="outlined" p={4} spacing={5}>
        <Typography variant="h3">
          {t("ConnectorConfigVccLiveEditForm_Heading_UpdateConnection")}
        </Typography>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={5}>
              <ControlledTextField
                autoComplete="off"
                required
                fullWidth
                name="name"
                label={t("ConnectorConfigVccLiveEditForm_InputLabel_Name")}
                control={control}
                disabled={isSubmitting}
              />

              <Divider />

              <Typography textTransform="uppercase" variant="subtitle1">
                {t("ConnectorConfigVccLiveEditForm_SubHeading_Credentials")}
              </Typography>

              <ControlledTextField
                required
                autoComplete="off"
                fullWidth
                name="password"
                type="password"
                label={t("ConnectorConfigVccLiveEditForm_InputLabel_Password")}
                control={control}
              />

              <ConnectorConfigBackfillDaysFormFieldsView />

              <Button type="submit" variant="contained" disabled={isSubmitting}>
                {t("ConnectorConfigVccLiveEditForm_Button_Update")}
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </>
  );
};
