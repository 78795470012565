interface NumberOfEmployeesRecordValue {
  index: number;
  value: number;
  tooltipLabel: string;
  valueLabel?: string;
}

export const NUMBER_OF_EMPLOYEES_OPTIONS_TYPE_RECORD: Record<
  number,
  NumberOfEmployeesRecordValue
> = {
  // TODO: use toLocaleString with locale value based on language picker
  0: { index: 0, value: 0, tooltipLabel: "0" },
  10: { index: 1, value: 10, tooltipLabel: "1–10" },
  50: { index: 2, value: 50, tooltipLabel: "11-50" },
  200: { index: 3, value: 200, tooltipLabel: "51-200" },
  500: { index: 4, value: 500, tooltipLabel: "201-500" },
  1000: {
    index: 5,
    value: 1000,
    valueLabel: "1.000",
    tooltipLabel: "501-1.000",
  },
  5000: {
    index: 6,
    value: 5000,
    valueLabel: "5.000",
    tooltipLabel: "1.001-5.000",
  },
  10000: {
    index: 7,
    value: 10000,
    valueLabel: "10.000",
    tooltipLabel: "5.001-10.000",
  },
  10001: {
    index: 8,
    value: 10001,
    valueLabel: "> 10.000",
    tooltipLabel: "> 10.000",
  },
};

export const NUMBER_OF_EMPLOYEES_OPTIONS_VALUES = Object.values(
  NUMBER_OF_EMPLOYEES_OPTIONS_TYPE_RECORD,
);
