import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import VerifiedIcon from "@mui/icons-material/Verified";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { TenderNotificationType } from "@snubes/snubes-types";
import { StatusColor } from "../../Common/types/StatusColor";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface TenderNotificationRecordValue {
  caption: TranslationKey;
  title: TranslationKey;
  Icon: OverridableComponent<SvgIconTypeMap>;
  color: StatusColor;
  path: string;
}

export const TENDER_NOTIFICATION_RECORD: Record<
  TenderNotificationType,
  TenderNotificationRecordValue
> = {
  TENDER_SUBMITTED: {
    caption: "TenderNotificationRecord_Caption_TenderSubmitted",
    title: "TenderNotificationRecord_Title_TenderSubmitted",
    Icon: VisibilityIcon,
    color: "primary",
    path: "/details",
  },
  TENDER_PUBLISHED: {
    caption: "TenderNotificationRecord_Caption_TenderPublished",
    title: "TenderNotificationRecord_Title_TenderPublished",
    Icon: VerifiedIcon,
    color: "primary",
    path: "/details",
  },
  TENDER_MATCHED: {
    caption: "TenderNotificationRecord_Caption_TenderMatched",
    title: "TenderNotificationRecord_Title_TenderMatched",
    Icon: AssignmentTurnedInIcon,
    color: "primary",
    path: "/details",
  },
  TENDER_ENDED: {
    caption: "TenderNotificationRecord_Caption_TenderEnded",
    title: "TenderNotificationRecord_Title_TenderEnded",
    Icon: ThumbDownIcon,
    color: "error",
    path: "/my-application",
  },
  TENDER_ENDED_ADMIN: {
    caption: "TenderNotificationRecord_Caption_TenderEnded",
    title: "TenderNotificationRecord_Title_TenderEnded",
    Icon: ThumbDownIcon,
    color: "error",
    path: "/details",
  },
  TENDER_APPLICATION_RECEIVED: {
    caption: "TenderNotificationRecord_Caption_TenderApplicationReceived",
    title: "TenderNotificationRecord_Title_TenderApplicationReceived",
    Icon: NewReleasesIcon,
    color: "success",
    path: "/applications",
  },
  TENDER_APPLICATION_ACCEPTED: {
    caption: "TenderNotificationRecord_Caption_TenderApplicationAccepted",
    title: "TenderNotificationRecord_Title_TenderApplicationAccepted",
    Icon: ThumbUpIcon,
    color: "success",
    path: "/my-application",
  },
  TENDER_APPLICATION_REJECTED: {
    caption: "TenderNotificationRecord_Caption_TenderApplicationRejected",
    title: "TenderNotificationRecord_Title_TenderApplicationRejected",
    Icon: ThumbDownIcon,
    color: "error",
    path: "/my-application",
  },
  TENDER_APPLICATIONS_COMPLETED_ADMIN: {
    caption:
      "TenderNotificationRecord_Caption_TenderApplicationsCompletedAdmin",
    title: "TenderNotificationRecord_Title_TenderApplicationsCompletedAdmin",
    Icon: ThumbUpIcon,
    color: "success",
    path: "/details",
  },
};
