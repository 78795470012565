import { Divider, Paper, Stack, Typography } from "@mui/material";
import {
  FirebaseFilter,
  Tender,
  TenderApplicationStatus,
  TenderStatus,
} from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import {
  FirebaseTableFilterContextProvider,
  FirebaseTableFilterRecord,
} from "../../Firebase/hooks/useFirebaseTableFilters";
import { useT } from "../../Translation/hooks/useT";
import { TendersCollectionTableView } from "./TendersCollectionTableView";

interface Props {
  organizationId: string;
}

export const TendersBrowseView: FC<Props> = (props) => {
  const { organizationId } = props;

  const t = useT();

  const filters = useMemo<FirebaseTableFilterRecord<Tender>>(
    () => ({
      name: {
        isSearch: true,
        label: t("FirebaseTableFilterView_Label_Search"),
        type: "text",
        operator: "search",
        value: null,
      },
    }),
    [t],
  );

  const tableFilters = useMemo<FirebaseFilter<Tender>[]>(() => {
    return [
      ["status", "==", "PUBLISHED" satisfies TenderStatus],
      ["isDeleted", "==", false],
      [
        `providerApplications.${organizationId}.status`,
        "in",
        [
          "PENDING" satisfies TenderApplicationStatus,
          "DRAFT" satisfies TenderApplicationStatus,
          "PUBLISHED" satisfies TenderApplicationStatus,
          "REJECTED_FOR_RESUBMISSION" satisfies TenderApplicationStatus,
        ],
      ],
    ];
  }, [organizationId]);

  return (
    <Stack py={5} spacing={3}>
      <Typography variant="h2">
        {t("TendersBrowseView_Headline_BrowseTenders")}
      </Typography>
      <Divider />
      <Paper>
        <FirebaseTableFilterContextProvider filters={filters}>
          <TendersCollectionTableView showStatus filters={tableFilters} />
        </FirebaseTableFilterContextProvider>
      </Paper>
    </Stack>
  );
};
