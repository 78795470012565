import { Project } from "@snubes/snubes-types";
import { DateTime } from "luxon";

export function getProjectReportFramesFromLastSevenDays(project: Project) {
  const now = DateTime.utc().startOf("day");
  const frames: Project["reportFramesByDay"] = [];

  // We start with 1 because today is excluded from the result.
  for (let i = 1; i < 8; i++) {
    const frame = project.reportFramesByDay?.find(
      (r) => r.id === now.minus({ days: i }).toISODate(),
    );

    if (frame) {
      frames.push(frame);
    }
  }

  return frames;
}
