import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useSidebarWidth } from "../../Common/hooks/useSidebarWidth";
import { NavTab } from "../../Common/types/NavTab";
import { NavTabs } from "../../Common/views/NavTabs";
import { SidebarView } from "../../Common/views/SidebarView";
import { useSelectedLocale } from "../../Translation/hooks/useSelectedLocale";
import { useT } from "../../Translation/hooks/useT";

export const ProjectSettingsLayoutView: FC = () => {
  const { projectId } = useParams<"projectId">();
  const sidebarWidth = useSidebarWidth(SIDEBAR_WIDTH);
  const t = useT();
  const selectedLocale = useSelectedLocale();

  if (!projectId) return null;

  const tabs: NavTab[] = [
    {
      label: "ProjectSettingsLayoutView_TabLabel_Basic",
      route: "basic",
    },
    {
      label: "ProjectSettingsLayoutView_TabLabel_Questions",
      route: "questions",
    },
    {
      label: "ProjectSettingsLayoutView_TabLabel_Scorecard",
      route: "scorecard",
    },
    {
      label: "ProjectSettingsLayoutView_TabLabel_CallCategories",
      route: "call-categories",
    },
    {
      label: "ProjectSettingsLayoutView_TabLabel_IssueCategories",
      route: "issue-categories",
    },
    {
      label: "ProjectSettingsLayoutView_TabLabel_CallFlaggingConfig",
      route: "flagging-rules",
    },
  ];

  return (
    <>
      {!!sidebarWidth && (
        <SidebarView width={sidebarWidth}>
          <Stack bgcolor="background.paper" flex={1}>
            <Stack p={3} mb={2}>
              <Typography
                variant="h2"
                lang={selectedLocale}
                sx={{ wordBreak: "break-word", hyphens: "auto" }}
              >
                {t("ProjectSettingsLayoutView_Headline_ProjectSettings")}
              </Typography>
            </Stack>
            <NavTabs
              tabs={tabs}
              orientation="vertical"
              pathPrefix={`/projects/${projectId}/settings`}
            />
          </Stack>
        </SidebarView>
      )}
      <Stack pl={`${sidebarWidth}px`} pt={3}>
        <Outlet />
      </Stack>
    </>
  );
};

const SIDEBAR_WIDTH = 315;
