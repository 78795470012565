import { FC } from "react";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LoadingView } from "../../Common/views/LoadingView";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useMyMember } from "../../Members/hooks/useMyMember";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { QmHomeOnboardingView } from "./QmHomeOnboardingView";
import { QmHomeView } from "./QmHomeView";

export const QmHomePage: FC = () => {
  const { user, isInitializing: userIsInitializing } = useMyUser();
  const { member, isInitializing: memberIsInitializing } = useMyMember();

  const isTeamMemberInvited = !!member?.tasks?.isTeamMemberInvited;
  const isPhoneSystemConnected = !!member?.tasks?.isPhoneSystemConnected;
  const areMemberTasksComplete =
    (isPhoneSystemConnected && isTeamMemberInvited) || !member;
  const isInitializing = userIsInitializing || memberIsInitializing;

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      {isInitializing && (
        <FullHeightContainer>
          <LoadingView />
        </FullHeightContainer>
      )}
      {!isInitializing && !areMemberTasksComplete && member && (
        <QmHomeOnboardingView member={member} />
      )}
      {!isInitializing && areMemberTasksComplete && user && (
        <QmHomeView user={user} member={member} />
      )}
    </>
  );
};
