import { BudgetDurationType } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

export const TENDER_BUDGET_DURATION_TYPE: Record<
  BudgetDurationType,
  TranslationKey
> = {
  TOTAL: "TenderBudgetDurationType_Total",
  MONTHLY: "TenderBudgetDurationType_Month",
  YEARLY: "TenderBudgetDurationType_Year",
};
