import {
  Checkbox,
  CheckboxProps,
  Stack,
  SvgIconTypeMap,
  Typography,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC } from "react";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";
import { SelectableButton } from "./SelectableButton";

interface Props extends CheckboxProps {
  Icon?: OverridableComponent<SvgIconTypeMap>;
  label: TranslationKey;
  caption?: TranslationKey;
  onChange?: () => void;
}

export const CheckboxButton: FC<Props> = (props) => {
  const t = useT();

  return (
    <SelectableButton
      color="inherit"
      variant="outlined"
      disabled={props.disabled}
      onClick={props.onChange}
      checked={!!props.checked}
      sx={props.sx}
      fullWidth
    >
      <Checkbox
        checked={props.checked}
        onChange={props.onChange}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
      <Stack alignItems="center" spacing={3}>
        {props.Icon && <props.Icon sx={{ fontSize: "48px" }} />}
        <Typography color="inherit" variant="body3">
          {t(props.label)}
        </Typography>
        {props.caption && (
          <Typography
            color={props.checked ? "text.primary" : "text.secondary"}
            variant="caption1"
          >
            {t(props.caption)}
          </Typography>
        )}
      </Stack>
    </SelectableButton>
  );
};
