import { List, ListItem, ListItemText, Typography } from "@mui/material";
import {
  BudgetType,
  Money,
  TenderAgentTrainingModel,
} from "@snubes/snubes-types";
import { FC } from "react";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { useT } from "../../Translation/hooks/useT";
import { TENDER_AGENT_TRAINING_BUDGET_TYPE_RECORD } from "../consts/TENDER_AGENT_TRAINING_BUDGET_TYPE_RECORD";
import { TENDER_AGENT_TRAINING_PRICE_MODEL_RECORD } from "../consts/TENDER_AGENT_TRAINING_PRICE_MODEL_RECORD";
import { TENDER_AGENT_TYPE_RECORD } from "../consts/TENDER_AGENT_TYPE_RECORD";

interface Props {
  agentTrainingBudgetType?: BudgetType;
  agentTrainingBudget?: Money;
  agentTrainingModels?: TenderAgentTrainingModel[];
  /**
   * @deprecated
   */
  trainingHours?: number;
}

export const TenderAgentTrainingView: FC<Props> = (props) => {
  const t = useT();
  const localeMoney = toLocaleMoney(props.agentTrainingBudget);

  return (
    <>
      <List disablePadding>
        {props.trainingHours && (
          <ListItemText disableTypography>
            <Typography variant="body2">
              {t("TenderDetailsView_Title_TrainingHoursPerAgent")}
              {`: ${props.trainingHours}`}
            </Typography>
          </ListItemText>
        )}
        {props.agentTrainingBudgetType == "FIXED" ? (
          props.agentTrainingBudget && (
            <ListItemText disableTypography>
              <Typography variant="body2">
                {t("TenderWizardAgentTrainingView_Label_TotalBudget")}
                {`: ${localeMoney}`}
              </Typography>
            </ListItemText>
          )
        ) : (
          <ListItemText disableTypography>
            <Typography variant="body2">
              {t(TENDER_AGENT_TRAINING_BUDGET_TYPE_RECORD["FLEXIBLE"].label)}
            </Typography>
          </ListItemText>
        )}
        {props.agentTrainingModels?.map((value) => (
          <ListItem
            disablePadding
            key={[
              value.agentType,
              value.initialHoursPerAgent,
              value.agentTrainingPriceModel,
            ].join("")}
          >
            {value.agentType &&
              value.agentTrainingPriceModel &&
              value.initialHoursPerAgent && (
                <ListItemText disableTypography>
                  <Typography variant="body2">
                    {t(TENDER_AGENT_TYPE_RECORD[value.agentType].label)} (
                    {t("TenderWizardAgentTrainingForm_Label_InitialHours")}:{" "}
                    {value.initialHoursPerAgent},{" "}
                    {t("TenderWizardAgentTrainingForm_Label_PriceModel")}:{" "}
                    {t(
                      TENDER_AGENT_TRAINING_PRICE_MODEL_RECORD[
                        value.agentTrainingPriceModel
                      ].label,
                    )}
                    )
                  </Typography>
                </ListItemText>
              )}
          </ListItem>
        ))}
      </List>
    </>
  );
};
