import { Stack } from "@mui/material";
import { FC } from "react";
import { HEADER_HEIGHT } from "../consts/HEADER_HEIGHT";
import { useHeaderConfig } from "../hooks/useHeaderConfig";
import { HeaderSpacerView } from "./HeaderSpacerView";

export const HeaderWithCustomChildren: FC = () => {
  const children = useHeaderConfig((state) => state.children);

  return (
    <>
      <Stack
        position="fixed"
        direction="row"
        alignItems="center"
        justifyContent="center"
        top={0}
        left={0}
        right={0}
        height={HEADER_HEIGHT}
        bgcolor="background.paper"
        px={3}
        zIndex={5}
        borderBottom={1}
        borderColor="divider"
        spacing={3}
      >
        {children}
      </Stack>
      <HeaderSpacerView />
    </>
  );
};
