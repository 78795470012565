import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { TenderApplicationRejectionReason } from "@snubes/snubes-types/types/TenderApplicationRejectionReason";
import { FC, useCallback, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { T } from "../../Translation/views/T";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { rejectTenderApplicationCallable } from "../callables/rejectTenderApplicationCallable";
import { TenderApplicationRejectionReasonSelect } from "./TenderApplicationRejectionReasonSelect";

interface Props {
  tenderId: string;
  providerId: string;
  close: () => void;
}

export const TenderApplicationRejectDialog: FC<Props> = (props) => {
  const { tenderId, providerId, close } = props;
  const userType = useMyUser((state) => state.user?.userType || null);
  const [isRejecting, setIsRejecting] = useState(false);
  const [isRejectForResubmission, setIsRejectForResubmission] = useState(false);
  const [rejectionReason, setRejectionReason] =
    useState<TenderApplicationRejectionReason>("OTHER");
  const t = useT();

  const onClickRejectApplication = useCallback(async () => {
    if (isRejecting) {
      return;
    }

    try {
      setIsRejecting(true);
      await rejectTenderApplicationCallable({
        tenderId,
        providerId,
        rejectionReason,
        isRejectForResubmission,
      });
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsRejecting(false);
      close();
    }
  }, [
    close,
    isRejectForResubmission,
    isRejecting,
    providerId,
    rejectionReason,
    tenderId,
  ]);

  if (!userType) {
    return (
      <Dialog onClose={close} open fullWidth>
        <DialogTitle>
          <T k="TenderApplicationRejectDialog_MissingUser" />
        </DialogTitle>
        <Divider />
        <DialogActions>
          <Button variant="contained" onClick={close}>
            {t("Common_Close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={close} open fullWidth>
      <DialogTitle>
        <T k="TenderApplicationRejectDialog_Title" />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText mb={3}>
          {t("TenderApplicationRejectDialog_Subtitle")}
        </DialogContentText>
        <TenderApplicationRejectionReasonSelect
          userType={userType}
          rejectionReason={rejectionReason}
          onSelectRejectionReason={setRejectionReason}
        />
        {["CLIENT", "ADMIN"].includes(userType) && (
          <FormGroup>
            <FormControlLabel
              label={t("TenderApplicationRejectDialog_CanApplyAgain_CheckBox")}
              sx={{ mt: 2 }}
              control={
                <Checkbox
                  value={isRejectForResubmission}
                  onChange={(e) => setIsRejectForResubmission(e.target.checked)}
                />
              }
            />
          </FormGroup>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" disabled={isRejecting} onClick={close}>
          {t("Common_Cancel")}
        </Button>
        <Button
          color="error"
          variant="contained"
          disabled={isRejecting}
          onClick={onClickRejectApplication}
        >
          {t("Common_Reject")}
          {isRejecting && <CircularProgress size={20} color="inherit" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
