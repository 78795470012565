import HelpIcon from "@mui/icons-material/Help";
import { Stack, Typography } from "@mui/material";
import { Project } from "@snubes/snubes-types";
import { FC } from "react";
import { LinkView } from "../../Common/views/LinkView";
import { PaperTooltipView } from "../../Common/views/PaperTooltipView";
import { RoundButton } from "../../Common/views/RoundButton";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  project: Project;
}

export const CallQuestionAnswersOutdatedButton: FC<Props> = (props) => {
  const t = useT();

  return (
    <PaperTooltipView
      placement="bottom-end"
      title={
        <Stack p={2} spacing={3}>
          <Stack direction="row" spacing={2} alignItems="center">
            <HelpIcon color="primary" />
            <Typography color="primary" variant="h5">
              {t("CallQuestionAnswersOutdatedButton_Title_AnswersAreOutdated")}
            </Typography>
          </Stack>
          <Typography variant="subtitle2">
            {t(
              "CallQuestionAnswersOutdatedButton_Description_AnswersAreOutdated",
            )}
          </Typography>
          <LinkView to={`/projects/${props.project.id}/settings/questions`}>
            {t("CallQuestionAnswersOutdatedButton_Button_GoToProjectQuestions")}
          </LinkView>
        </Stack>
      }
    >
      <RoundButton
        size="tiny"
        color="warning"
        endIcon={<HelpIcon sx={{ fontSize: 16 }} />}
      >
        {t("CallQuestionAnswersOutdatedButton_Button_AnswersAreOutdated")}
      </RoundButton>
    </PaperTooltipView>
  );
};
