import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TuneIcon from "@mui/icons-material/Tune";
import { Container, Stack, Typography } from "@mui/material";
import { WeekdayPeriodStruct } from "@snubes/snubes-types";
import { FC } from "react";
import { array, boolean, nullable, type } from "superstruct";
import { ControlledRadioButtons } from "../../Form/views/ControlledRadioButtons";
import { ControlledWeekdayPeriods } from "../../Form/views/ControlledWeekdayPeriods";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  isWorkingAroundTheClock: nullable(boolean()),
  workingHours: array(WeekdayPeriodStruct),
});

export const ProviderWizardWorkingHoursForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit, watch } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => {
      return {
        isWorkingAroundTheClock:
          provider?.isWorkingAroundTheClock !== undefined
            ? provider.isWorkingAroundTheClock
            : null,
        workingHours: provider?.workingHours || [],
      };
    },
    toDoc: (formValues) => ({
      isWorkingAroundTheClock:
        typeof formValues.isWorkingAroundTheClock === "boolean"
          ? formValues.isWorkingAroundTheClock
          : undefined,
      workingHours: formValues.isWorkingAroundTheClock
        ? undefined
        : formValues.workingHours,
    }),
  });

  const isWorkingAroundTheClock = watch("isWorkingAroundTheClock");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("ProviderWizardWorkingHoursForm_Title_WorkingHours")}
        </Typography>
        <Typography variant="body2" color="text.500" mb={4}>
          {t("ProviderWizardWorkingHoursForm_Paragraph_WorkingHours")}
        </Typography>
        <Stack spacing={4}>
          <ControlledRadioButtons
            control={control}
            name="isWorkingAroundTheClock"
            options={[
              {
                Icon: AccessTimeIcon,
                label: "ProviderWizardWorkingHoursForm_Label_AroundTheClock",
                caption:
                  "ProviderWizardWorkingHoursForm_Caption_AroundTheClock",
                value: true,
              },
              {
                Icon: TuneIcon,
                label: "ProviderWizardWorkingHoursForm_Label_CustomTimings",
                caption: "ProviderWizardWorkingHoursForm_Caption_CustomTimings",
                value: false,
              },
            ]}
            gridSpacing={4}
            gridItemXs={6}
          />
          {isWorkingAroundTheClock === false && (
            <ControlledWeekdayPeriods
              control={control}
              name="workingHours"
              required
            />
          )}
        </Stack>
      </Container>
    </form>
  );
};
