import { WizardStep } from "../types/WizardStep";

export function getActiveWizardStep<TState>(
  steps: WizardStep<TState>[],
): WizardStep<TState> | undefined {
  for (const step of steps) {
    if (step.isActive) {
      return step.steps ? getActiveWizardStep(step.steps) || step : step;
    }
  }
}
