import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {
  Alert,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Call } from "@snubes/snubes-types";
import dynamic from "next/dynamic";
import {
  FC,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import type WaveSurfer from "wavesurfer.js";
import { handleError } from "../../Common/helpers/handleError";
import { toLocaleMinutes } from "../../Common/helpers/toLocaleMinutes";
import { useMediaQueryUp } from "../../Common/hooks/useMediaQueryUp";
import { getCallTranscripts } from "../helpers/getCallTranscripts";
import { isVoicefileMono } from "../helpers/isVoicefileMono";
import { useVoicefileUrl } from "../hooks/useVoicefileUrl";
import { CallAudioMonoButton } from "./CallAudioMonoButton";
import { CallAudioPlayerVolumeView } from "./CallAudioPlayerVolumeView";
import { CallTranscriptLanguageSelect } from "./CallTranscriptLanguageSelect";

const CallWavesurferView = dynamic(
  () =>
    import("./CallWavesurferView").then(
      ({ CallWavesurferView }) => CallWavesurferView,
    ),
  {
    ssr: false,
  },
);

interface Props {
  playerRef: MutableRefObject<WaveSurfer | null>;
  call: Call;
  isLoadingAudio: boolean;
  voicefileDurationInSeconds: number;
  setIsLoadingAudio: (isLoading: boolean) => void;
  setActiveTranscriptId: (id?: string) => void;
  setVoicefileDurationInSeconds: (seconds: number) => void;
}

export const CallAudioPlayerView: FC<Props> = (props) => {
  const {
    playerRef,
    call,
    isLoadingAudio,
    voicefileDurationInSeconds,
    setIsLoadingAudio,
    setActiveTranscriptId,
    setVoicefileDurationInSeconds,
  } = props;

  const transcripts = useMemo(() => getCallTranscripts(call) || [], [call]);
  const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const isUpMd = useMediaQueryUp("md");
  const [voicefileUrl, isLoadingVoicefileUrl, voicefileUrlError] =
    useVoicefileUrl(call);
  const [playerErrorMessage, setPlayerErrorMessage] = useState<string>();

  // We have to put the transcripts into a reference so we can access the latest value
  // without triggering the init effect again.
  const transcriptsRef = useRef(transcripts);
  transcriptsRef.current = transcripts
    .slice()
    .sort((a, b) => b.startOffsetMilliseconds - a.startOffsetMilliseconds);

  // Play or pause audio
  const playPause = useCallback(() => {
    playerRef.current
      ?.playPause()
      ?.catch((error) => handleError(error).logAnd().toast());
    setIsPlaying((value) => !value);
  }, [playerRef]);

  const onReady = useCallback(
    (player: WaveSurfer) => {
      playerRef.current = player;
      setVoicefileDurationInSeconds(player.getDuration());
      setIsLoadingAudio(false);
    },
    [playerRef, setIsLoadingAudio, setVoicefileDurationInSeconds],
  );

  const onProcess = useCallback(
    (currentSecond: number) => {
      const nextTranscript = transcriptsRef.current.find(
        (m) => currentSecond >= Math.round(m.startOffsetMilliseconds / 1000),
      );

      setActiveTranscriptId(nextTranscript?.id);
      setCurrentTimeInSeconds(currentSecond);
    },
    [setActiveTranscriptId],
  );

  const onEnded = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const onError = useCallback(
    (error: unknown) => {
      setPlayerErrorMessage(String(error));
      handleError(error).log({
        tags: { projectId: call.projectId, callId: call.id },
        extra: { projectId: call.projectId, callId: call.id, voicefileUrl },
      });
    },
    [call.id, call.projectId, voicefileUrl],
  );

  const PlayPauseIcon = isPlaying
    ? PauseCircleOutlineIcon
    : PlayCircleOutlineIcon;

  const isLoaded = !isLoadingAudio && !isLoadingVoicefileUrl;

  if (voicefileUrlError || playerErrorMessage) {
    return (
      <Alert sx={{ m: 3 }} severity="error">
        {voicefileUrlError?.message || playerErrorMessage}
      </Alert>
    );
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={3}
        pl={3}
        pr={5}
        pt={3}
      >
        <IconButton color="primary" onClick={playPause} disabled={!isLoaded}>
          <PlayPauseIcon sx={{ fontSize: 32 }} />
        </IconButton>
        {isLoaded && (
          <Stack direction="row" alignItems="center" whiteSpace="nowrap">
            <Typography variant="h5" color="primary">
              {toLocaleMinutes(currentTimeInSeconds * 1000)}
            </Typography>
            <Typography ml={1} color="text.600">
              / {toLocaleMinutes(voicefileDurationInSeconds * 1000)}
            </Typography>
          </Stack>
        )}
        <Stack flex={1} />
        {isUpMd && isVoicefileMono(call) && <CallAudioMonoButton />}
        {call.transcriptLanguageCode && (
          <CallTranscriptLanguageSelect
            call={call}
            language={call.transcriptLanguageCode}
          />
        )}
        <CallAudioPlayerVolumeView playerRef={playerRef} />
      </Stack>
      <Stack px={5} pb={3}>
        <Stack height={64} position="relative">
          {!isLoaded && (
            <Stack
              position="absolute"
              alignItems="center"
              justifyContent="center"
              top={0}
              left={0}
              right={0}
              bottom={0}
            >
              <CircularProgress />
            </Stack>
          )}
          {voicefileUrl && (
            <CallWavesurferView
              url={voicefileUrl}
              onReady={onReady}
              onProcess={onProcess}
              onEnded={onEnded}
              onError={onError}
            />
          )}
        </Stack>
      </Stack>
    </>
  );
};
