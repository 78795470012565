import { TranslationKey } from "../../Translation/types/TranslationKey";

interface FooterLinkItem {
  translationKey: TranslationKey;
  href: string;
}

export const FOOTER_LINK_ITEMS: FooterLinkItem[] = [
  {
    translationKey: "AuthLayoutFooter_Title_AGB",
    href: "https://snubes.de/agb",
  },
  {
    translationKey: "AuthLayoutFooter_Title_Privacy",
    href: "https://snubes.de/datenschutz-und-cookies",
  },
  {
    translationKey: "AuthLayoutFooter_Title_Imprint",
    href: "https://snubes.de/impressum",
  },
];
