import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Paper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { useT } from "../../Translation/hooks/useT";
import { useProjectContext } from "../hooks/useProjectContext";
import { ProjectArchiveDialog } from "./ProjectArchiveDialog";
import { ProjectDeleteDialog } from "./ProjectDeleteDialog";
import { ProjectSettingsConnectorConfigView } from "./ProjectSettingsConnectorConfigView";
import { ProjectUpdateView } from "./ProjectUpdateView";

export const ProjectSettingsPage = () => {
  const { project } = useProjectContext();
  const t = useT();
  const hasPermission = useHasPermission();
  const canDeleteProject = hasPermission("CAN_DELETE_PROJECT", {
    organizationId: project?.providerOrganizationId,
  });
  const canArchiveProject = hasPermission("CAN_ARCHIVE_PROJECT", {
    organizationId: project?.providerOrganizationId,
  });

  const [isProjectDeleteDialogOpen, setIsProjectDeleteDialogOpen] =
    useState(false);
  const [isProjectArchiveDialogOpen, setIsProjectArchiveDialogOpen] =
    useState(false);

  if (!project) return null;

  const isArchived = project.isArchived;

  return (
    <FullHeightContainer maxWidth="md">
      <Stack py={5} spacing={3}>
        <Typography variant="h3">
          {t("ProjectSettingsPage_Heading_BasicSetting")}
        </Typography>
        <Stack component={Paper} p={3} variant="outlined">
          <ProjectUpdateView project={project} />
        </Stack>
        {project.connectorConfigId && (
          <ProjectSettingsConnectorConfigView
            connectorConfigId={project.connectorConfigId}
            organizationId={project.providerOrganizationId}
          />
        )}
        <Stack alignItems="center" direction="row" justifyContent="center">
          {canDeleteProject && (
            <Button
              variant="text"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => setIsProjectDeleteDialogOpen(true)}
            >
              {t("ProjectSettingsPage_Button_DeleteProject")}
            </Button>
          )}
          {!isArchived && canArchiveProject && (
            <>
              <Typography variant="body1" p={1}>
                /
              </Typography>
              <Button
                variant="text"
                color="inherit"
                startIcon={<ArchiveOutlinedIcon />}
                onClick={() => setIsProjectArchiveDialogOpen(true)}
              >
                {t("ProjectSettingsPage_Button_ArchiveProject")}
              </Button>
            </>
          )}
        </Stack>
        <ProjectDeleteDialog
          project={project}
          isOpen={isProjectDeleteDialogOpen}
          isNavigating
          close={() => setIsProjectDeleteDialogOpen(false)}
        />
        <ProjectArchiveDialog
          project={project}
          isOpen={isProjectArchiveDialogOpen}
          isNavigating
          close={() => setIsProjectArchiveDialogOpen(false)}
        />
      </Stack>
    </FullHeightContainer>
  );
};
