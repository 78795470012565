import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { TenderSupportDocumentsType } from "@snubes/snubes-types/types/TenderSupportDocumentsType";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface TenderSupportDocumentsTypeRecordValue {
  label: TranslationKey;
  caption?: TranslationKey;
  Icon?: OverridableComponent<SvgIconTypeMap>;
}

export const TENDER_SUPPORT_DOCUMENTS_TYPE_RECORD: Record<
  TenderSupportDocumentsType,
  TenderSupportDocumentsTypeRecordValue
> = {
  NO_DOCUMENTS: {
    label: "TenderSupportDocumentsType_Label_No_Documents",
    caption: "TenderSupportDocumentsType_Caption_No_Documents",
    Icon: CancelIcon,
  },
  WITH_DOCUMENTS: {
    label: "TenderSupportDocumentsType_Label_With_Documents",
    caption: "TenderSupportDocumentsType_Caption_With_Documents",
    Icon: CheckCircleIcon,
  },
};
