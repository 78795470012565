import { Typography } from "@mui/material";
import { FC } from "react";
import { useT } from "../../Translation/hooks/useT";
import { getTimeZoneByName } from "../helpers/getTimeZoneByName";

interface Props {
  operatingHours?: string;
  operatingTimeZone?: string;
}

export const TenderCustomOperatingHoursView: FC<Props> = (props) => {
  const t = useT();

  return (
    <>
      <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
        {props.operatingHours || "-"}
      </Typography>
      {props.operatingTimeZone && (
        <>
          <Typography
            variant="body1"
            color="text.secondary"
            gutterBottom
            mt={3}
          >
            {t("TenderCustomOperatingHoursView_Title")}
          </Typography>
          <Typography variant="body2">
            {getTimeZoneByName(props.operatingTimeZone) ||
              props.operatingTimeZone}
          </Typography>
        </>
      )}
    </>
  );
};
