import { superstructResolver } from "@hookform/resolvers/superstruct";
import { Button, Stack, Typography } from "@mui/material";
import { EmailStruct, PasswordStruct } from "@snubes/snubes-types";
import { sendEmailVerification, signInWithCustomToken } from "firebase/auth";
import { FC, useCallback, useMemo, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { define, Infer, nonempty, string, type } from "superstruct";
import { ENVIRONMENT } from "../../Application/consts/ENVIRONMENT";
import { handleError } from "../../Common/helpers/handleError";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { auth } from "../../Firebase/consts/FIREBASE";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useT } from "../../Translation/hooks/useT";
import { signUpCallable } from "../callables/signUpCallable";
import { useAuthEmail } from "../hooks/useAuthEmail";

export const AuthUserCreatePage: FC = () => {
  const [authState] = useAuthState(auth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const t = useT();
  const navigate = useNavigate();

  const FormValuesStruct = useMemo(
    () =>
      type({
        firstName: nonempty(string()),
        lastName: nonempty(string()),
        email: EmailStruct,
        password: PasswordStruct,
        passwordRepeat: define("password-repeat", (value, context) => {
          const { password } = context.branch[0] as { password: string };
          return password !== value
            ? t("AuthUserCreateForm_Error_PasswordsMustMatch")
            : true;
        }),
      }),
    [t],
  );

  type FormValues = Infer<typeof FormValuesStruct>;

  const { control, handleSubmit } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: useAuthEmail.getState().email,
      password: "",
      passwordRepeat: "",
    },
  });

  const handleSignup = useCallback(
    async (formValues: FormValues) => {
      try {
        setIsSubmitting(true);
        const { token } = await signUpCallable(formValues);
        await signInWithCustomToken(auth, token);

        if (authState) {
          await sendEmailVerification(authState, {
            url: `https://${ENVIRONMENT.firebaseConfig.authDomain}`,
          });
        }
        navigate("/");
      } catch (error) {
        handleError(error).logAnd().toast();
        setIsSubmitting(false);
      }
    },
    [authState, navigate],
  );

  return (
    <>
      <HeaderConfig variant="WITH_CENTERED_LOGO" />
      <FullHeightContainer maxWidth="sm">
        <Stack
          spacing={3}
          sx={{
            p: {
              sm: 6,
              xs: 4,
            },
            textAlign: "center",
          }}
        >
          <Typography variant="h2">
            {t("AuthUserCreateForm_Headline")}
          </Typography>
          <Typography variant="body1">
            {t("AuthUserCreateForm_Subline")}
          </Typography>
        </Stack>

        <Stack
          p={3}
          spacing={3}
          bgcolor="background.paper"
          border={1}
          borderColor="divider"
        >
          <Typography variant="h3">
            {t("AuthUserCreatePage_Title_CreateProfile")}
          </Typography>

          <form onSubmit={handleSubmit(handleSignup)}>
            <Stack spacing={3}>
              <ControlledTextField
                required
                fullWidth
                name="email"
                label={t("AuthUserCreateForm_Label_Email")}
                control={control}
                disabled={isSubmitting}
              />
              <ControlledTextField
                required
                fullWidth
                name="firstName"
                label={t("AuthUserCreateForm_Label_FirstName")}
                control={control}
                disabled={isSubmitting}
              />
              <ControlledTextField
                required
                fullWidth
                name="lastName"
                label={t("AuthUserCreateForm_Label_LastName")}
                control={control}
                disabled={isSubmitting}
              />
              <ControlledTextField
                required
                fullWidth
                name="password"
                type="password"
                label={t("AuthUserCreateForm_Label_Password")}
                control={control}
                disabled={isSubmitting}
              />
              <ControlledTextField
                required
                fullWidth
                name="passwordRepeat"
                type="password"
                label={t("AuthUserCreateForm_Label_Password")}
                control={control}
                disabled={isSubmitting}
              />
              <Button type="submit" variant="contained" disabled={isSubmitting}>
                {t("AuthUserCreateForm_Button_CreateAccount")}
              </Button>
            </Stack>
          </form>
        </Stack>
      </FullHeightContainer>
    </>
  );
};
