import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { useStore } from "zustand";
import { useFirebaseTableFilterStore } from "../hooks/useFirebaseTableFilters";

interface Props {
  filterName: string;
}

export const FirebaseFilterMenuButtonContentView: FC<Props> = (props) => {
  const { filterName } = props;

  const filterStore = useFirebaseTableFilterStore();
  const isActive = useStore(
    filterStore,
    (state) => !!state.filters[filterName].isActive,
  );
  const label = useStore(
    filterStore,
    (state) => state.filters[filterName].label,
  );

  const setInactive = useCallback(() => {
    filterStore.getState().setIsFilterActive(filterName, false);
  }, [filterStore, filterName]);

  return (
    <>
      <Typography
        component="span"
        variant="subtitle2"
        sx={{
          fontWeight: isActive ? "bold" : undefined,
          color: isActive ? "primary.main" : undefined,
        }}
      >
        {label}
      </Typography>
      {isActive && (
        <RemoveCircleOutlineIcon
          onClick={setInactive}
          sx={{
            color: "text.secondary",
            "&:hover": {
              color: "primary.main",
            },
          }}
        />
      )}
    </>
  );
};
