import { ProjectTier } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface ProjectTierRecordValue {
  label: TranslationKey;
}

export const PROJECT_TIER_RECORD: Readonly<
  Record<ProjectTier, ProjectTierRecordValue>
> = {
  PLATFORM: {
    label: "ProjectTier_Label_Platform",
  },
  PLATFORM_PLUS: {
    label: "ProjectTier_Label_PlatformPlus",
  },
};
