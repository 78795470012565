import {
  FirebaseFilter,
  isOrganization,
  Organization,
} from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import {
  FirebaseTableFilterContextProvider,
  FirebaseTableFilterRecord,
} from "../../Firebase/hooks/useFirebaseTableFilters";
import { CollectionTableColumn } from "../../Firebase/types/CollectionTableColumn";
import { CollectionTableView } from "../../Firebase/views/CollectionTableView";
import { useT } from "../../Translation/hooks/useT";
import { AdminOrganizationsCollectionTableActionsView } from "./AdminOrganizationsCollectionTableActionsView";

interface Props {
  filters?: FirebaseFilter<Organization>[];
}

export const AdminOrganizationsCollectionTableView: FC<Props> = (props) => {
  const t = useT();

  const columns = useMemo<CollectionTableColumn<Organization>[]>(
    () => [
      {
        title: t("OrganizationsView_TableCell_Name"),
        field: "name",
      },
      {
        title: t("OrganizationsView_TableCell_MembersCount"),
        field: "organizationMembersCount",
      },
      {
        title: t("OrganizationsView_TableCell_UpdatedAt"),
        field: "updatedAt",
        align: "right",
      },
      {
        title: t("OrganizationsView_TableCell_CreatedAt"),
        field: "createdAt",
        align: "right",
      },
      {
        title: "",
        align: "right",
        headSx: {
          width: 72,
        },
        renderCell: (organization) => (
          <AdminOrganizationsCollectionTableActionsView
            organization={organization}
          />
        ),
      },
    ],
    [t],
  );

  const filters = useMemo<FirebaseTableFilterRecord<Organization>>(
    () => ({
      name: {
        isSearch: true,
        label: t("FirebaseTableFilterView_Label_Search"),
        type: "text",
        operator: "search",
        value: null,
      },
      organizationMembersCount: {
        label: t("FirebaseTableFilterView_Label_MembersCount"),
        type: "number",
        operator: ">",
        value: null,
      },
    }),
    [t],
  );

  return (
    <FirebaseTableFilterContextProvider filters={filters}>
      <CollectionTableView
        collectionName="organizations"
        orderBy={["createdAt", "desc"]}
        keyField="id"
        isT={isOrganization}
        getTo={(organization) => `/organizations/${organization.id}`}
        columns={columns}
        filters={props.filters}
      />
    </FirebaseTableFilterContextProvider>
  );
};
