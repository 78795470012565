import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { DateTimeView } from "../../Common/views/DateTimeView";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface RowProps {
  date: string;
  translationKeyLabel: TranslationKey;
}

export const TenderApplicationsDeadlineRowView: FC<RowProps> = (props) => {
  const { date, translationKeyLabel } = props;
  const t = useT();

  return (
    <Stack direction="row" spacing={1}>
      <Typography variant="caption1">{t(translationKeyLabel)}</Typography>
      <Typography variant="caption2" color="text.secondary">
        <DateTimeView date={date} variant="date" />
      </Typography>
    </Stack>
  );
};
