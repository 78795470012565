import { FC, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { NavTab } from "../../Common/types/NavTab";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";
import { useMyUser } from "../../Users/hooks/useMyUser";

export const TendersLayoutView: FC = () => {
  const userType = useMyUser((state) => state.user?.userType);

  const tabs = useMemo<NavTab[]>(
    () => [
      {
        label: "TendersLayoutView_SecondaryHeaderLabel_MyTenders",
        route: "my-tenders",
        isHidden: userType == "PROVIDER",
      },
      {
        label: "TendersLayoutView_SecondaryHeaderLabel_MyEndedTenders",
        route: "my-ended-tenders",
        isHidden: userType == "PROVIDER",
      },
      {
        label: "TendersLayoutView_SecondaryHeaderLabel_BrowseTenders",
        route: "browse-tenders",
        isHidden: userType == "CLIENT",
      },
      {
        label: "TendersLayoutView_SecondaryHeaderLabel_WonTenders",
        route: "won-tenders",
        isHidden: userType == "CLIENT",
      },
      {
        label: "TendersLayoutView_SecondaryHeaderLabel_LostTenders",
        route: "lost-tenders",
        isHidden: userType == "CLIENT",
      },
    ],
    [userType],
  );

  return (
    <>
      <SecondaryHeaderView tabs={tabs} pathPrefix="/tenders" />
      <Outlet />
    </>
  );
};
