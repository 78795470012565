import { Container, InputAdornment, Stack, Typography } from "@mui/material";
import { ChannelType, ServiceType } from "@snubes/snubes-types";
import { FC } from "react";
import { number, string, type, union } from "superstruct";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { TENDER_CHANNEL_TYPE } from "../consts/TENDER_CHANNEL_TYPE";
import { findTenderServiceChannel } from "../helpers/findTenderServiceChannel";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  volume: union([number(), string()]),
});

type Props = WizardFormProps<{
  serviceType: ServiceType;
  channelType: ChannelType;
}>;

export const TenderWizardChannelVolumeForm: FC<Props> = (props) => {
  const { serviceType, channelType } = props.formProps;
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => ({
      volume:
        findTenderServiceChannel(serviceType, channelType, tender)?.volume ??
        "",
    }),
    toDoc: (formValues, tender) => {
      const channel = findTenderServiceChannel(
        serviceType,
        channelType,
        tender,
      );

      if (channel) {
        if (typeof formValues.volume === "number") {
          channel.volume = formValues.volume;
        } else {
          delete channel.volume;
        }
      }

      return {
        services: tender?.services,
        isReviewed: {
          ...tender?.isReviewed,
          services: false,
        },
      };
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardChannelVolumeForm_Title_Name")}
        </Typography>
        <Stack spacing={3}>
          <ControlledTextField
            required
            fullWidth
            name="volume"
            type="number"
            label={t(TENDER_CHANNEL_TYPE[channelType].volumeLabel)}
            control={control}
            endAdornment={
              <InputAdornment position="end">
                {t(TENDER_CHANNEL_TYPE[channelType].volumeAdornment)}
              </InputAdornment>
            }
            autoFocus
          />
        </Stack>
      </Container>
    </form>
  );
};
