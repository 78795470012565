import { isMember, Member } from "@snubes/snubes-types";
import { onSnapshot } from "firebase/firestore";
import { create } from "zustand";
import { handleError } from "../../Common/helpers/handleError";
import { getDataFromDocumentSnapshot } from "../../Firebase/helpers/getDataFromDocumentSnapshot";
import { getDocRef } from "../../Firebase/helpers/getDocRef";

interface MyMemberState {
  member?: Member;
  isInitializing: boolean;
  subscribe: (params: { organizationId: string; userId: string }) => void;
  unsubscribe?: () => void;
}

export const useMyMember = create<MyMemberState>((set) => ({
  isInitializing: true,
  subscribe: ({ organizationId, userId }) => {
    const unsubscribeFirestore = onSnapshot(
      getDocRef(`organizations/${organizationId}/members`, userId),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;

        set({
          member: getDataFromDocumentSnapshot(isMember, snapshot),
          isInitializing: false,
        });
      },
      (error) => {
        handleError(error).logAnd().toast();
        set({ member: undefined, isInitializing: false });
      },
    );

    set({
      unsubscribe: () => {
        set({ member: undefined, isInitializing: true });
        unsubscribeFirestore();
      },
    });
  },
}));
