import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import SpeakerNotesOffOutlinedIcon from "@mui/icons-material/SpeakerNotesOffOutlined";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Call, ProcessStatus } from "@snubes/snubes-types";
import { FC } from "react";
import { StatusColor } from "../../Common/types/StatusColor";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface Props {
  call: Call;
}

export const CallTranscriptsProcessStatusChip: FC<Props> = (props) => {
  const { call } = props;
  const t = useT();
  const status = call.transcriptsProcess?.status || "ON_HOLD";
  const { color, label, Icon, isLoading } = STATUS_RECORD[status];

  return (
    <StatusChip
      Icon={Icon}
      label={t(label)}
      color={color}
      isLoading={isLoading}
    />
  );
};

interface StatusRecordValue {
  color: StatusColor;
  label: TranslationKey;
  Icon?: OverridableComponent<SvgIconTypeMap>;
  isLoading?: boolean;
}

const STATUS_RECORD: Record<ProcessStatus, StatusRecordValue> = {
  ON_HOLD: {
    color: "default",
    label: "CallTranscriptsProcessStatusChip_Label_OnHold",
    Icon: SpeakerNotesOffOutlinedIcon,
  },
  PROCESSING: {
    color: "primary",
    label: "CallTranscriptsProcessStatusChip_Label_Processing",
    isLoading: true,
  },
  SUCCEEDED: {
    color: "secondary",
    label: "CallTranscriptsProcessStatusChip_Label_Succeeded",
    Icon: MessageOutlinedIcon,
  },
  FAILED: {
    color: "error",
    label: "CallTranscriptsProcessStatusChip_Label_Failed",
    Icon: SpeakerNotesOffOutlinedIcon,
  },
  REQUESTED: {
    color: "primary",
    label: "CallTranscriptsProcessStatusChip_Label_Requested",
    isLoading: true,
  },
};
