import { getName } from "@cospired/i18n-iso-languages";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import { Call, LANGUAGES, LanguageStruct } from "@snubes/snubes-types";
import { FC, useCallback, useMemo, useState } from "react";
import { LANGUAGE_RECORD } from "../../Common/consts/LANGUAGE_RECORD";
import { handleError } from "../../Common/helpers/handleError";
import { getCurrentLocale } from "../../Translation/helpers/getCurrentLocale";
import { useT } from "../../Translation/hooks/useT";
import { requestProcessCallCallable } from "../callables/requestProcessCallCallable";
import { isCallProcessing } from "../helpers/isCallProcessing";

interface Props {
  call: Call;
  language: string;
}

export const CallTranscriptLanguageSelect: FC<Props> = (props: Props) => {
  const currentLanguage = getCurrentLocale();
  const callId = props.call.id;
  const t = useT();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [language, setLanguage] = useState(
    LanguageStruct.is(props.language) ? props.language : undefined,
  );

  const options = useMemo(
    () => [
      ...LANGUAGES.map((language) => {
        const translation = getName(language, currentLanguage) || language;

        return {
          value: language,
          label: `${LANGUAGE_RECORD[language].emoji} ${translation}`,
          sortLabel: translation,
        };
      }).sort((a, b) => a.sortLabel.localeCompare(b.sortLabel)),
    ],
    [currentLanguage],
  );

  const onChange = useCallback((value: string) => {
    setIsDialogOpen(true);
    setLanguage(LanguageStruct.is(value) ? value : undefined);
  }, []);

  const onClose = useCallback(() => {
    setIsDialogOpen(false);
    setLanguage(LanguageStruct.is(props.language) ? props.language : undefined);
  }, [props.language]);

  const submit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await requestProcessCallCallable({
        callId,
        kind: "TRANSCRIPTS",
        ...(language && { language }),
      });

      setIsDialogOpen(false);
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsSubmitting(false);
    }
  }, [callId, language]);

  const isProcessing = isCallProcessing(props.call);
  const isDisabled = isSubmitting || isProcessing;

  return (
    <>
      <Select
        size="small"
        value={language || props.language}
        disabled={isDisabled}
        onChange={(event) => onChange(event.target.value)}
      >
        {!language && (
          <MenuItem value={props.language}>
            <em>{getName(props.language, currentLanguage)}</em>
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <Dialog open={isDialogOpen} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          {t(
            "CallTranscriptLanguageSelect_DialogTitle_UpdateVoicefileLanguage",
          )}
        </DialogTitle>
        <DialogContent sx={{ pt: 2 }}>
          {t(
            "CallTranscriptLanguageSelect_DialogContent_UpdateVoicefileLanguage",
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="text" disabled={isSubmitting}>
            {t("Common_Cancel")}
          </Button>
          <Button variant="contained" disabled={isSubmitting} onClick={submit}>
            {t("CallTranscriptLanguageSelect_Button_RegenerateTranscripts")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
