import {
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Fragment, useMemo } from "react";
import { NavLink } from "react-router-dom";
import {
  FirebaseTableFilter,
  useMaybeFirebaseTableFilterStore,
} from "../hooks/useFirebaseTableFilters";
import { FirebaseTableRowSelectionProvider } from "../hooks/useFirebaseTableRowSelectionContext";
import { CollectionTableColumn } from "../types/CollectionTableColumn";
import { CollectionTableWithCheckboxesProps } from "../types/CollectionTableWithCheckboxesProps";
import { FirebasePagination } from "../types/FirebasePagination";
import { CheckboxTableCellView } from "./CheckboxTableCellView";
import { CheckboxTableHeadView } from "./CheckboxTableHeadView";
import { CollectionTableCellContentView } from "./CollectionTableCellContentView";
import { FirebasePaginationView } from "./FirebasePaginationView";
import { FirebaseTableFilterView } from "./FirebaseTableFilterView";

type Props<T> = CollectionTableWithCheckboxesProps<
  T,
  {
    columns: CollectionTableColumn<T>[];
    rows: T[];
    isLoading?: boolean;
    getTo?: (row: T) => string;
    onClickRow?: (row: T) => void;
    getGroupTitle?: (row: T) => string;
    keyField: keyof T & string;
    pagination?: FirebasePagination;
    rowsPerPageOptions?: number[];
    userFilters?: Record<string, FirebaseTableFilter>;
  }
>;

export function FirebaseTableView<T>(props: Props<T>) {
  const columns = useMemo(() => {
    const columns = props.columns.filter((c) => !c.isHidden);

    if (props.hasCheckboxes && columns.length > 0) {
      const firstColumn = columns[0];
      return [
        {
          ...firstColumn,
          title: (
            <CheckboxTableHeadView
              title={firstColumn.title}
              rows={props.rows}
              getRowId={props.getRowId}
              onRowSelectionChanged={props.onRowSelectionChanged}
            />
          ),
          renderCell: (row: T) => (
            <CheckboxTableCellView
              row={row}
              field={firstColumn.field}
              renderCell={firstColumn.renderCell}
              rows={props.rows}
              getRowId={props.getRowId}
              onRowSelectionChanged={props.onRowSelectionChanged}
              isCheckboxDisabled={props.isCheckboxDisabled}
            />
          ),
        },
        ...columns.slice(1),
      ];
    } else {
      return columns;
    }
  }, [
    props.columns,
    props.getRowId,
    props.hasCheckboxes,
    props.isCheckboxDisabled,
    props.onRowSelectionChanged,
    props.rows,
  ]);

  const hasFilters = Boolean(useMaybeFirebaseTableFilterStore());

  /**
   * Credits: https://stackoverflow.com/a/23522755/1484222
   */
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <>
      {hasFilters && <FirebaseTableFilterView />}

      <FirebaseTableRowSelectionProvider selectedRowIds={props.selectedRowIds}>
        <TableContainer>
          <Table component="div" sx={{ whiteSpace: "nowrap" }}>
            <TableHead component="div">
              <TableRow component="div">
                {columns.map((column, index) => (
                  <StyledTableCell
                    key={index}
                    align={column.align}
                    component="div"
                    sx={column.headSx}
                  >
                    {column.title}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              component="div"
              sx={{ opacity: props.isLoading ? 0.3 : 1 }}
            >
              {props.rows.map((row, index) => (
                <Fragment key={String(row[props.keyField])}>
                  {hasGroupTitle(props, row, index) && !isSafari && (
                    <TableRow component="div" sx={{ position: "relative" }}>
                      <StyledTableCell
                        component="div"
                        sx={{ py: 0, height: 36 }}
                      >
                        <AbsoluteStack>
                          <Typography variant="caption1" color="text.600">
                            {props.getGroupTitle(row)}
                          </Typography>
                        </AbsoluteStack>
                      </StyledTableCell>
                    </TableRow>
                  )}
                  <TableRow
                    component={props.getTo ? NavLink : "div"}
                    sx={{
                      textDecorationLine: "none",
                      "&:hover": {
                        ...(props.onClickRow && { cursor: "pointer" }),
                      },
                    }}
                    to={props.getTo?.(row)}
                    onClick={() => props.onClickRow?.(row)}
                    hover
                  >
                    {columns.map((column, index) => (
                      <StyledTableCell
                        key={index}
                        align={column.align}
                        sx={column.sx}
                        component="div"
                      >
                        <CollectionTableCellContentView
                          row={row}
                          field={column.field}
                          renderCell={column.renderCell}
                        />
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FirebaseTableRowSelectionProvider>
      {props.pagination && (
        <FirebasePaginationView
          p={3}
          justifyContent="flex-end"
          pagination={props.pagination}
          rowsPerPageOptions={props.rowsPerPageOptions}
        />
      )}
    </>
  );
}

function hasGroupTitle<T>(
  props: Props<T>,
  row: T,
  index: number,
): props is Props<T> & { getGroupTitle: (row: T) => string } {
  if (!props.getGroupTitle) return false;
  if (index === 0) return true;

  return (
    props.getGroupTitle(row) !== props.getGroupTitle(props.rows[index - 1])
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const AbsoluteStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  position: "absolute",
  justifyContent: "center",
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(3),
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}));
