import { FC } from "react";
import { AppLoggedInRouter } from "../../Application/views/AppLoggedInRouter";
import { AppLoggedOutRouter } from "../../Application/views/AppLoggedOutRouter";
import { LoadingView } from "../../Common/views/LoadingView";
import { OnboardingOrganizationPage } from "../../Onboarding/views/OnboardingOrganizationPage";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { useAuthInit } from "../hooks/useAuthInit";

export const AuthView: FC = () => {
  const isInitializing = useAuthInit((state) => state.isInitializing);
  const isLoggedIn = useMyUser((state) => !!state.user);
  const hasSelectedOrganization = useSelectedOrganization((organization) =>
    Boolean(organization),
  );

  if (isInitializing) {
    return <LoadingView />;
  }

  if (!isLoggedIn) {
    return <AppLoggedOutRouter />;
  }

  if (!hasSelectedOrganization) {
    return <OnboardingOrganizationPage />;
  }

  return <AppLoggedInRouter />;
};
