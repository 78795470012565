import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { CONNECTOR_PLATFORMS, ConnectorPlatform } from "@snubes/snubes-types";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";
import { useT } from "../../Translation/hooks/useT";
import { CONNECTOR_PLATFORM_RECORD } from "../consts/CONNECTOR_PLATFORM_RECORD";
import { ConnectorConfigDialfireForm } from "./ConnectorConfigDialfireForm";
import { ConnectorConfigGenericWebhookForm } from "./ConnectorConfigGenericWebhookForm";
import { ConnectorConfigInoplaForm } from "./ConnectorConfigInoplaForm";
import { ConnectorConfigSftpForm } from "./ConnectorConfigSftpForm";
import { ConnectorConfigVccLiveForm } from "./ConnectorConfigVccLiveForm";

export const ConnectorConfigCreatePage = () => {
  const t = useT();
  const { organizationId } = useParams<"organizationId">();
  const [connectorPlatform, setConnectorPlatform] =
    useState<ConnectorPlatform>();

  if (!organizationId) return null;

  // We keep this commented out, to easily enable/disable the feature flag
  // const connectorPlatforms = CONNECTOR_PLATFORMS.filter((p) =>
  //   p === "GENERIC_WEBHOOK" ? isFeatureFlagEnabled("GENERIC_WEBHOOK") : true
  // );

  const options = CONNECTOR_PLATFORMS.map(
    (conenctorPlatform: ConnectorPlatform) => ({
      ...CONNECTOR_PLATFORM_RECORD[conenctorPlatform],
      value: conenctorPlatform,
    }),
  );

  return (
    <>
      <SecondaryHeaderView
        title={t("ConnectorConfigCreatePage_Title_CreateConnection")}
        backButton={{
          to: `/organizations/${organizationId}/connector-configs`,
          label: t("ConnectorConfigCreatePage_Label_BackToConnectorConfigs"),
        }}
      />
      <FullHeightContainer maxWidth="md">
        <Stack py={5} spacing={3}>
          <Paper>
            <Stack p={4}>
              <Typography variant="h3">
                {t("ConnectorConfigCreatePage_Heading_CreateConnection")}
              </Typography>
              <Stack mt={5}>
                <FormControl fullWidth>
                  <InputLabel>
                    {t("ConnectorConfigCreatePage_Label_Platform")}
                  </InputLabel>
                  <Select
                    required
                    value={connectorPlatform || ""}
                    label={t("ConnectorConfigCreatePage_Label_Platform")}
                    onChange={({ target }) =>
                      setConnectorPlatform(target.value as ConnectorPlatform)
                    }
                  >
                    {options.map((option) => {
                      return (
                        <MenuItem key={option.value} value={option.value}>
                          {t(option.label)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {connectorPlatform === "DIALFIRE" && (
                  <ConnectorConfigDialfireForm />
                )}
                {connectorPlatform === "INOPLA" && (
                  <ConnectorConfigInoplaForm />
                )}
                {connectorPlatform === "VCC_LIVE" && (
                  <ConnectorConfigVccLiveForm />
                )}
                {connectorPlatform === "SFTP" && <ConnectorConfigSftpForm />}
                {connectorPlatform === "GENERIC_WEBHOOK" && (
                  <ConnectorConfigGenericWebhookForm />
                )}
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </FullHeightContainer>
    </>
  );
};
