import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useWizardRootStep } from "../hooks/useWizardRootStep";
import { WizardStep } from "../types/WizardStep";
import { WizardStore } from "../types/WizardStore";
import { WizardProgressView } from "./WizardProgressView";
import { WizardSavingIndicatorView } from "./WizardSavingIndicatorView";

interface Props<TState> {
  backButtonHref: string;
  backButtonTitle: string;
  name: string;
  steps: WizardStep<TState>[];
  store: WizardStore<TState>;
  centerSlot?: ReactNode;
  rightSlot: ReactNode;
}

export function WizardHeaderView<TState>(props: Props<TState>) {
  const {
    centerSlot,
    rightSlot,
    backButtonHref,
    backButtonTitle,
    name,
    steps,
    store,
  } = props;

  const rootStep = useWizardRootStep(steps);

  return (
    <Stack
      flex={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Button
        size="small"
        href={backButtonHref}
        variant="regular"
        startIcon={<ArrowBackIcon />}
      >
        {backButtonTitle}
      </Button>

      <Stack direction="row" maxWidth="50vw" alignItems="center" spacing={3}>
        <WizardProgressView step={rootStep} store={store} />
        <Typography
          variant="h4"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {name}
        </Typography>
        {centerSlot}
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="end"
        spacing={2}
        minWidth={120}
      >
        {rightSlot}
        <WizardSavingIndicatorView store={store} />
      </Stack>
    </Stack>
  );
}
