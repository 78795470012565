import {
  Tender,
  TenderApplication,
  TenderApplicationStatus,
  UserType,
} from "@snubes/snubes-types";
import { isTenderBeforeApplicationDeadline } from "../helpers/isTenderBeforeApplicationDeadline";

export type TenderApplicationStatusTransition =
  | "EDIT"
  | "REJECT_BY_PROVIDER"
  | "REJECT_BY_CLIENT"
  | "PUBLISH"
  | "ACCEPT"
  | "REAPPLY"
  | "WITHDRAW";

type TenderApplicationStatusAllowedTransitions = Record<
  TenderApplicationStatusTransition,
  boolean
>;

type ProviderTenderApplicationStatusRecordValue = (args: {
  userType: UserType;
  tender: Tender;
  tenderApplication: TenderApplication;
}) => TenderApplicationStatusAllowedTransitions;

export const TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS = {
  EDIT: false,
  REJECT_BY_PROVIDER: false,
  REJECT_BY_CLIENT: false,
  PUBLISH: false,
  ACCEPT: false,
  WITHDRAW: false,
  REAPPLY: false,
};

/**
 * @see [TENDER_APPLICATION_STATUS_TRANSITIONS.md](TENDER_APPLICATION_STATUS_TRANSITIONS.md)
 */
export const TENDER_APPLICATION_STATUS_TRANSITIONS: Record<
  TenderApplicationStatus,
  ProviderTenderApplicationStatusRecordValue
> = {
  PENDING: ({ userType, tender }) => {
    switch (userType) {
      case "ADMIN":
        return {
          ...TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS,
          EDIT: true,
          REJECT_BY_PROVIDER: true,
        };
      case "PROVIDER":
        return {
          ...TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS,
          EDIT: isTenderBeforeApplicationDeadline(tender),
          REJECT_BY_PROVIDER: true,
        };
      default:
        return TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS;
    }
  },
  DRAFT: ({ userType, tender }) => {
    switch (userType) {
      case "ADMIN":
        return {
          ...TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS,
          PUBLISH: true,
          REJECT_BY_PROVIDER: true,
        };
      case "PROVIDER":
        return {
          ...TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS,
          PUBLISH: isTenderBeforeApplicationDeadline(tender),
          REJECT_BY_PROVIDER: true,
        };
      default:
        return TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS;
    }
  },
  PUBLISHED: ({ userType, tender }) => {
    switch (userType) {
      case "ADMIN":
        return {
          ...TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS,
          WITHDRAW: true,
        };
      case "CLIENT":
        return {
          ...TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS,
          ACCEPT: true,
          REJECT_BY_CLIENT: true,
        };
      case "PROVIDER":
        return {
          ...TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS,
          WITHDRAW: isTenderBeforeApplicationDeadline(tender),
        };
      default:
        return TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS;
    }
  },
  ACCEPTED: ({ userType }) => {
    switch (userType) {
      case "ADMIN":
        return {
          ...TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS,
          WITHDRAW: true,
          REJECT_BY_PROVIDER: true,
        };
      default:
        return TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS;
    }
  },
  REJECTED: ({ userType, tender, tenderApplication }) => {
    switch (userType) {
      case "ADMIN":
        return {
          ...TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS,
          REAPPLY: true,
        };
      case "PROVIDER": {
        return {
          ...TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS,
          // Providers can reapply if the tender is still open for applications
          // and if the application was rejected by the provider themselves.
          REAPPLY:
            isTenderBeforeApplicationDeadline(tender) &&
            tenderApplication.rejectedByUserType === userType,
        };
      }
      default:
        return TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS;
    }
  },
  REJECTED_FOR_RESUBMISSION: ({ userType, tender }) => {
    switch (userType) {
      case "ADMIN":
        return {
          ...TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS,
          REAPPLY: true,
        };
      case "PROVIDER":
        return {
          ...TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS,
          REAPPLY: isTenderBeforeApplicationDeadline(tender),
        };
      default:
        return TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS;
    }
  },
  REJECTED_WITHOUT_APPLICATION: () => {
    return TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS;
  },
};
