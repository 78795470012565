import { ProviderClientType } from "@snubes/snubes-types/types/ProviderClientType";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface ClientTypeType {
  label: TranslationKey;
  caption: TranslationKey;
}

export const PROVIDER_CLIENT_TYPE: Record<ProviderClientType, ClientTypeType> =
  {
    SMALL_BUSINESS: {
      label: "ProviderClientType_Label_SmallBusiness",
      caption: "ProviderClientType_Description_SmallBusiness",
    },
    MEDIUM_BUSINESS: {
      label: "ProviderClientType_Label_MediumBusiness",
      caption: "ProviderClientType_Description_MediumBusiness",
    },
    LARGE_BUSINESS: {
      label: "ProviderClientType_Label_LargeBusiness",
      caption: "ProviderClientType_Description_LargeBusiness",
    },
  };
