import { Button, Stack, Typography } from "@mui/material";
import dynamic from "next/dynamic";
import { FC } from "react";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";

// Use dynamic import for code splitting.
const ProviderCreationSuccessConfettiView = dynamic(
  () =>
    import("./ProviderCreationSuccessConfettiView").then(
      (m) => m.ProviderCreationSuccessConfettiView,
    ),
  { ssr: false },
);

export const ProviderCreationSuccessPage: FC = () => {
  const t = useT();
  const organizationId = useSelectedOrganization((state) => state?.id);

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer>
        <Stack
          height="100%"
          width="100%"
          mx="auto"
          py={4}
          spacing={3}
          maxWidth={580}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h1">🎉</Typography>
          <Typography color="secondary" variant="subtitle1" fontWeight="bold">
            {t("ProviderCreationSuccessPage_Subheading_CreatedSuccessfully")}
          </Typography>
          <Typography variant="h1" textAlign="center">
            {t("ProviderCreationSuccessPage_Heading_ReadyToBrowse")}
          </Typography>
          <Typography pt={2} variant="body2">
            {t("ProviderCreationSuccessPage_Paragraph_NextSteps")}
          </Typography>

          <Stack pt={3} spacing={3} width="100%" maxWidth={420}>
            {organizationId && (
              <Button
                href={`/organizations/${organizationId}/connector-configs`}
                variant="outlined"
              >
                {t("ProviderCreationSuccessPage_Button_ConnectPhoneSystems")}
              </Button>
            )}
            <Button href="/tenders/browse-tenders" variant="outlined">
              {t("ProviderCreationSuccessPage_Button_BrowseOpenTenders")}
            </Button>
            <Typography
              py={2}
              variant="caption1"
              fontWeight="bold"
              textAlign="center"
              textTransform="uppercase"
            >
              {t("Common_Or")}
            </Typography>
            <Button href="/home" variant="contained">
              {t("ProviderCreationSuccessPage_Button_ToDashboard")}
            </Button>
          </Stack>
        </Stack>
      </FullHeightContainer>
      <ProviderCreationSuccessConfettiView />
    </>
  );
};
