import AddIcon from "@mui/icons-material/Add";
import { Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { FirebaseFilter, Tender, TenderStatus } from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import {
  FirebaseTableFilterContextProvider,
  FirebaseTableFilterRecord,
} from "../../Firebase/hooks/useFirebaseTableFilters";
import { useT } from "../../Translation/hooks/useT";
import { TENDER_STATUS_RECORD } from "../consts/TENDER_STATUS_RECORD";
import { useTendersController } from "../hooks/useTendersController";
import { TendersCollectionTableView } from "./TendersCollectionTableView";
import { TendersControllerView } from "./TendersControllerView";

interface Props {
  organizationId: string;
}

export const TendersView: FC<Props> = (props) => {
  const t = useT();
  const controller = useTendersController({
    defaultOrderBy: "CREATED_AT",
  });

  const filters = useMemo<FirebaseTableFilterRecord<Tender>>(
    () => ({
      name: {
        isSearch: true,
        label: t("FirebaseTableFilterView_Label_Search"),
        type: "text",
        operator: "search",
        value: null,
      },
      status: {
        label: t("FirebaseTableFilterView_Label_Status"),
        type: "select",
        operator: "==",
        value: null,
        options: Object.entries(TENDER_STATUS_RECORD)
          .filter(([value]) => value !== ("ENDED" satisfies TenderStatus))
          .map(([value, { label }]) => ({
            label: t(label),
            value,
          })),
      },
    }),
    [t],
  );

  const tableFilters = useMemo<FirebaseFilter<Tender>[]>(() => {
    return [
      ["isDeleted", "==", false],
      ["organizationId", "==", props.organizationId],
      [
        "status",
        "in",
        [
          "DRAFT" satisfies TenderStatus,
          "WAITING_FOR_REVIEW" satisfies TenderStatus,
          "PUBLISHED" satisfies TenderStatus,
        ],
      ],
    ];
  }, [props.organizationId]);

  return (
    <Stack py={5} spacing={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">
          {t("TendersView_Headline_Tenders")}
        </Typography>

        <Stack direction="row" spacing={3}>
          <TendersControllerView
            controller={controller}
            options={["CREATED_AT", "UPDATED_AT", "PUBLISHED_AT"]}
          />
          <Button
            href="/tenders/create"
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ minWidth: 260 }}
          >
            {t("TendersView_Button_CreateTender")}
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <Paper>
        <FirebaseTableFilterContextProvider filters={filters}>
          <TendersCollectionTableView
            showStatus
            showActions
            controller={controller}
            filters={tableFilters}
          />
        </FirebaseTableFilterContextProvider>
      </Paper>
    </Stack>
  );
};
