import { isTenderLog } from "@snubes/snubes-types";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { LogListItemViewForTender } from "../../Logs/views/LogListItemViewForTender";
import { LogsButtonView } from "../../Logs/views/LogsButtonView";
import { useT } from "../../Translation/hooks/useT";
import { WizardStep } from "../../Wizard/types/WizardStep";
import { WizardStore } from "../../Wizard/types/WizardStore";
import { WizardHeaderView } from "../../Wizard/views/WizardHeaderView";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";
import { TenderStatusChip } from "./TenderStatusChip";

interface Props<TState> {
  steps: WizardStep<TState>[];
  store: WizardStore<TState>;
}

export function TenderWizardHeaderView<TState>(props: Props<TState>) {
  const { steps, store } = props;
  const tenderName = useTenderWizardStore((state) => state.doc?.name);
  const tenderStatus = useTenderWizardStore((state) => state.doc?.status);
  const tenderId = useTenderWizardStore((state) => state.docId);
  const hasPermission = useHasPermission();
  const t = useT();

  return (
    <WizardHeaderView
      backButtonHref="/tenders"
      backButtonTitle={t("TenderWizardPage_Button_HeaderBackToTenders")}
      name={
        tenderName || t("TenderWizardWizardHeaderView_Title_HeaderUntitled")
      }
      steps={steps}
      store={store}
      centerSlot={
        tenderStatus ? <TenderStatusChip status={tenderStatus} /> : null
      }
      rightSlot={
        hasPermission("CAN_READ_LOGS") && !!tenderId ? (
          <LogsButtonView
            collectionName={`tenders/${tenderId}/logs`}
            LogListItemView={LogListItemViewForTender}
            isT={isTenderLog}
          />
        ) : null
      }
    />
  );
}
