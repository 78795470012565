import { SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import { ProjectOrderBy } from "../types/ProjectOrderBy";

export interface ProjectsController {
  orderBy: ProjectOrderBy;
  onOrderByChanged: (event: SelectChangeEvent) => void;
}

interface Args {
  defaultOrderBy?: ProjectOrderBy;
}

export function useProjectsController(args: Args = {}): ProjectsController {
  const [orderBy, setOrderBy] = useState<ProjectOrderBy>(
    args.defaultOrderBy || "CREATED_AT",
  );

  const onOrderByChanged = (event: SelectChangeEvent) => {
    setOrderBy(event.target.value as ProjectOrderBy);
  };

  return { orderBy, onOrderByChanged };
}
