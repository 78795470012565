import { FirebaseFilter, isProject, Project } from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import { toLocaleDecimal } from "../../Common/helpers/toLocaleDecimal";
import { toLocaleMinutes } from "../../Common/helpers/toLocaleMinutes";
import { toLocalePercent } from "../../Common/helpers/toLocalePercent";
import { RelativeTimestampView } from "../../Common/views/RelativeTimestampView";
import {
  FirebaseTableFilterContextProvider,
  FirebaseTableFilterRecord,
} from "../../Firebase/hooks/useFirebaseTableFilters";
import { CollectionTableColumn } from "../../Firebase/types/CollectionTableColumn";
import { CollectionTableView } from "../../Firebase/views/CollectionTableView";
import { getProjectAnsweredCallsCount } from "../../Projects/helpers/getProjectAnsweredCallsCount";
import { getProjectAverageHandlingTimeInSeconds } from "../../Projects/helpers/getProjectAverageHandlingTimeInSeconds";
import { getProjectReachabilityPercentage } from "../../Projects/helpers/getProjectReachabilityPercentage";
import { getProjectServiceLevelPercentage } from "../../Projects/helpers/getProjectServiceLevelPercentage";
import { getProjectTotalCallsCount } from "../../Projects/helpers/getProjectTotalCallsCount";
import { getProjectTotalHandlingTimeInSeconds } from "../../Projects/helpers/getProjectTotalHandlingTimeInSeconds";
import { ProjectReportFramesStatusChip } from "../../Projects/views/ProjectReportFramesStatusChip";
import { useT } from "../../Translation/hooks/useT";
import { AdminProjectsCollectionTableActionsView } from "./AdminProjectsCollectionTableActionsView";

interface Props {
  filters?: FirebaseFilter<Project>[];
}

export const AdminProjectsCollectionTableView: FC<Props> = (props) => {
  const t = useT();

  const columns = useMemo<CollectionTableColumn<Project>[]>(
    () => [
      {
        title: t("ProjectsTableView_Title_Name"),
        field: "name",
      },
      {
        title: t("ProjectsTableView_Title_ProviderOrganizationName"),
        field: "providerOrganizationName",
      },
      {
        title: t("ProjectsTableView_Title_ClientOrganizationName"),
        field: "clientOrganizationName",
      },
      {
        title: t("ProjectsTableView_Title_ProjectManagerName"),
        field: "projectManagerName",
      },
      {
        title: t("ProjectsTableView_Title_ReportFramesNumberOfCalls"),
        renderCell: (project) => {
          const { today, lastSevenDays } = getProjectTotalCallsCount(project);

          return `${toLocaleDecimal(today)} (Ø ${toLocaleDecimal(
            lastSevenDays,
          )})`;
        },
      },
      {
        title: t("ProjectsTableView_Title_ReportFramesCallsAnswered"),
        renderCell: (project) => {
          const { today, lastSevenDays } =
            getProjectAnsweredCallsCount(project);

          return `${toLocaleDecimal(today)} (Ø ${toLocaleDecimal(
            lastSevenDays,
          )})`;
        },
      },
      {
        title: t("ProjectsTableView_Title_ReportFramesReachability"),
        renderCell: (project) => {
          const { today, lastSevenDays } =
            getProjectReachabilityPercentage(project);

          return `${toLocalePercent(today)} (Ø ${toLocalePercent(
            lastSevenDays,
          )})`;
        },
      },
      {
        title: t("ProjectsTableView_Title_ReportFramesAverageHandlingTime"),
        renderCell: (project) => {
          const { today, lastSevenDays } =
            getProjectAverageHandlingTimeInSeconds(project);

          return `${toLocaleMinutes(today * 1000)} (Ø ${toLocaleMinutes(
            lastSevenDays * 1000,
          )})`;
        },
      },
      {
        title: t("ProjectsTableView_Title_ReportFramesTotalHandlingTime"),
        renderCell: (project) => {
          const { today, lastSevenDays } =
            getProjectTotalHandlingTimeInSeconds(project);

          return `${toLocaleMinutes(today * 1000)} (Ø ${toLocaleMinutes(
            lastSevenDays * 1000,
          )})`;
        },
      },
      {
        title: t("ProjectsTableView_Title_ReportFramesServiceLevel"),
        renderCell: (project) => {
          const { today, lastSevenDays } =
            getProjectServiceLevelPercentage(project);

          return `${toLocalePercent(today)} (Ø ${toLocalePercent(
            lastSevenDays,
          )})`;
        },
      },
      {
        title: t("ProjectsTableView_Title_ReportFramesUpdatedAt"),
        renderCell: (project) => {
          return project.reportFramesByDay?.length ? (
            <RelativeTimestampView
              timestamp={project.updatedAt}
              shouldBeInThePast
              translation
              hasTooltip
            />
          ) : (
            <ProjectReportFramesStatusChip project={project} />
          );
        },
      },
      {
        title: "",
        renderCell: (project: Project) => (
          <AdminProjectsCollectionTableActionsView project={project} />
        ),
      },
    ],
    [t],
  );

  const filters = useMemo<FirebaseTableFilterRecord<Project>>(
    () => ({
      name: {
        isSearch: true,
        label: t("FirebaseTableFilterView_Label_Search"),
        type: "text",
        operator: "search",
        value: null,
      },
      providerOrganizationName: {
        label: t("ProjectsTableView_Filter_ProviderOrganizationName"),
        type: "text",
        operator: "search",
        value: null,
        placeholder: t("ProjectsTableView_Filter_ProviderOrganizationName"),
      },
      clientOrganizationName: {
        label: t("ProjectsTableView_Filter_ClientOrganizationName"),
        type: "text",
        operator: "search",
        value: null,
        placeholder: t("ProjectsTableView_Filter_ClientOrganizationName"),
      },
    }),
    [t],
  );

  return (
    <FirebaseTableFilterContextProvider filters={filters}>
      <CollectionTableView
        collectionName="projects"
        orderBy={["createdAt", "desc"]}
        keyField="id"
        isT={isProject}
        getTo={(project) => `/projects/${project.id}`}
        columns={columns}
        filters={props.filters}
      />
    </FirebaseTableFilterContextProvider>
  );
};
