import EditIcon from "@mui/icons-material/Edit";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { Tender } from "@snubes/snubes-types";
import { FC, useState } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { SidebarView } from "../../Common/views/SidebarView";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { TenderApplicationsStatusChipsView } from "./TenderApplicationsStatusChipsView";
import { TenderApplicationStatusChip } from "./TenderApplicationStatusChip";
import { TenderDeadlineChip } from "./TenderDeadlineChip";
import { TenderEndDialog } from "./TenderEndDialog";

interface Props {
  tender: Tender;
}

export const TenderDetailsSidebarView: FC<Props> = (props) => {
  const { tender } = props;
  const t = useT();
  const hasPermission = useHasPermission();
  const userType = useMyUser((state) => state.user?.userType);
  const selectedOrganizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );
  const [isEndTenderDialogOpen, setIsEndTenderDialogOpen] = useState(false);
  const tenderApplication = selectedOrganizationId
    ? tender?.providerApplications?.[selectedOrganizationId]
    : undefined;
  const isMatchedProvider =
    !!selectedOrganizationId &&
    tender.providerApplications &&
    selectedOrganizationId in tender.providerApplications;
  const isTenderOpen = !tender.isDeleted && tender.status !== "ENDED";
  const showEndTenderButton =
    hasPermission("CAN_END_TENDER") &&
    ((userType === "ADMIN" && isTenderOpen) ||
      (userType === "CLIENT" && tender.status === "PUBLISHED"));

  return (
    <SidebarView bgcolor="background.paper">
      <Stack p={4} height="100%" justifyContent="space-between">
        <Stack spacing={3}>
          <Typography variant="caption1" color="text.secondary">
            {t("TenderDetailsSidebarView_Label_TenderName")}
          </Typography>

          <Typography variant="h2">{tender.name}</Typography>

          <Stack spacing={5}>
            <Divider sx={{ width: "100%" }} />

            <Stack alignItems="center">
              <TenderDeadlineChip tender={tender} />
            </Stack>

            <Stack alignItems="center" spacing={3}>
              <Typography variant="caption1" color="text.secondary">
                {t("TenderDetailsSidebarView_Label_AllApplications")}
              </Typography>

              <TenderApplicationsStatusChipsView
                tender={tender}
                size="medium"
              />
            </Stack>

            {isMatchedProvider && (
              <Stack alignItems="center" spacing={3}>
                <Typography variant="caption1" color="text.secondary">
                  {t("TenderDetailsSidebarView_Label_MyApplication")}
                </Typography>
                <TenderApplicationStatusChip
                  tenderApplication={tenderApplication}
                />
              </Stack>
            )}
          </Stack>
        </Stack>

        <Stack spacing={2}>
          {isTenderOpen &&
            hasPermission("CAN_UPDATE_TENDER_PROVIDERS", {
              organizationId: tender.organizationId,
            }) && (
              <Button
                variant="outlined"
                startIcon={<EditIcon />}
                href={`/tenders/${tender.id}/edit`}
              >
                {t("TenderDetailsSidebarView_Button_EditTender")}
              </Button>
            )}

          {showEndTenderButton && (
            <Button
              variant="outlined"
              color="error"
              startIcon={<StopCircleOutlinedIcon />}
              onClick={() => setIsEndTenderDialogOpen(true)}
            >
              {t("TenderDetailsSidebarView_Button_EndTender")}
            </Button>
          )}
        </Stack>
      </Stack>
      {isEndTenderDialogOpen && (
        <TenderEndDialog
          tender={tender}
          close={() => setIsEndTenderDialogOpen(false)}
        />
      )}
    </SidebarView>
  );
};
