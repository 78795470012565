import {
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { StorageFile } from "@snubes/snubes-types";
import { t } from "i18next";
import { FC } from "react";
import { UploadState } from "../types/UploadState";
import { FileListItemView } from "./FileListItemView";

interface Props {
  files: StorageFile[];
  disabled?: boolean;
  showDeleteAction?: boolean;
  uploadState?: UploadState;
  onClickDeleteFile?: (file: StorageFile) => Promise<void> | void;
}

export const FileListView: FC<Props> = (props) => {
  const { uploadState } = props;
  const showPlaceholder =
    (uploadState?.status === "running" || uploadState?.status === "success") &&
    props.files.findIndex((file) => file.fileName === uploadState.fileName) < 0;

  return (
    <List dense disablePadding>
      {showPlaceholder && (
        <ListItem divider={props.files.length > 0}>
          <ListItemText
            primaryTypographyProps={{
              gutterBottom: true,
              variant: "subtitle1",
            }}
            secondaryTypographyProps={{ variant: "body1" }}
            primary={uploadState.fileName}
            secondary={
              <Stack spacing={2}>
                <span>
                  {(uploadState.fileSizeBytes / 1024).toFixed(2) + " KB"} •
                  {t("FileListView_Label_Uploading")}
                </span>
                <LinearProgress
                  variant="determinate"
                  value={uploadState.progress}
                />
              </Stack>
            }
          />
        </ListItem>
      )}
      {props.files
        .slice()
        .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
        .map((doc, i, all) => (
          <FileListItemView
            key={doc.fileHash}
            divider={i !== all.length - 1}
            file={doc}
            disabled={props.disabled}
            showDeleteAction={props.showDeleteAction}
            onClickDeleteFile={props.onClickDeleteFile}
          />
        ))}
    </List>
  );
};
