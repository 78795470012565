import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Grid, Stack, SxProps, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FC, ReactElement, useMemo } from "react";
import { PaperTooltipView } from "../../Common/views/PaperTooltipView";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  percentage: number;
  value?: string;
  label?: string;
  invertedColor?: boolean;
}

type Sign = -1 | 1 | 0;
type SignRecord = Record<
  Sign,
  {
    color: string;
    icon?: ReactElement;
    label: string;
  }
>;

export const ProjectReportFramesSummaryPercentageUnitView: FC<Props> = (
  props,
) => {
  const { percentage, value, label, invertedColor } = props;
  const t = useT();
  const { palette, spacing } = useTheme();
  const sign = Math.sign(percentage) as Sign;

  const signRecord = useMemo<SignRecord>(() => {
    const iconStyles: SxProps = { fontSize: spacing(3) };
    return {
      1: {
        color: palette.success.main,
        icon: <ArrowUpwardIcon sx={iconStyles} />,
        label: t(
          "ProjectReportFramesSummaryPercentageView_Percentage_Increase",
        ),
      },
      [-1]: {
        color: palette.error.main,
        icon: <ArrowDownwardIcon sx={iconStyles} />,
        label: t(
          "ProjectReportFramesSummaryPercentageView_Percentage_Decrease",
        ),
      },
      0: {
        color: "text.500",
        label: t("ProjectReportFramesSummaryPercentageView_Percentage_Change"),
      },
    };
  }, [spacing, palette, t]);

  return (
    <Grid item xs={12} sm={12 / 3} md={12 / 6}>
      {value && (
        <PaperTooltipView
          title={
            <Typography p={1} color="text.600" variant="h5">
              {`${
                label
                  ? `${label} ${t(
                      "ProjectReportFramesSummaryPercentageView_Label_Before",
                    )}: `
                  : ""
              }${value}`}
            </Typography>
          }
        >
          <Stack
            direction="row"
            color={
              signRecord[invertedColor ? ((-1 * sign) as Sign) : sign].color
            }
          >
            <Typography mr={2} variant="subtitle2">
              {signRecord[sign].icon}
            </Typography>
            <Typography mr={2} variant="subtitle2">
              {percentage.toLocaleString(undefined, {
                maximumFractionDigits: 1,
                style: "percent",
              })}{" "}
              {signRecord[sign].label}
            </Typography>
          </Stack>
        </PaperTooltipView>
      )}
    </Grid>
  );
};
