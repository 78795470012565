import { ListItemButton, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC } from "react";
import { NavLink } from "react-router-dom";

interface Props {
  title: string;
  IconComponent: OverridableComponent<SvgIconTypeMap>;
  to?: string;
}

export const AppSidebarNavLink: FC<Props> = (props) => {
  return (
    <ListItemButton
      {...(props.to && { component: NavLink, to: props.to })}
      sx={{
        py: 1,
        borderLeft: "5px solid transparent",
        "&.active": {
          bgcolor: "grey.200",
          borderLeftColor: "primary.main",
        },
      }}
    >
      <props.IconComponent sx={{ color: "grey.500", mr: 2 }} />
      {props.title}
    </ListItemButton>
  );
};
