import { DateTime, Interval } from "luxon";

export function getDaysToBackfill(backfillDate: string) {
  return Math.floor(
    Interval.fromDateTimes(
      DateTime.fromISO(backfillDate).startOf("day"),
      DateTime.utc().startOf("day"),
    )
      .toDuration()
      .as("days"),
  );
}
