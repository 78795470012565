import { Container, Typography } from "@mui/material";
import {
  PROVIDER_CLIENT_FOCUS,
  ProviderClientFocusStruct,
} from "@snubes/snubes-types";
import { FC } from "react";
import { array, type } from "superstruct";
import { ControlledCheckboxButtons } from "../../Form/views/ControlledCheckboxButtons";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { PROVIDER_CLIENT_FOCUS_RECORD } from "../consts/PROVIDER_CLIENT_FOCUS_RECORD";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  clientFocus: array(ProviderClientFocusStruct),
});

const PROVIDER_CLIENT_TYPE_OPTIONS = PROVIDER_CLIENT_FOCUS.map((type) => ({
  ...PROVIDER_CLIENT_FOCUS_RECORD[type],
  value: type,
}));

export const ProviderWizardClientFocusForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      clientFocus: provider?.clientFocus || [],
    }),
    toDoc: (formValues) => {
      return {
        clientFocus: formValues?.clientFocus,
      };
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("ProviderWizardClientFocusForm_Title")}
        </Typography>
        <Typography variant="body1" color="text.500" mb={4}>
          {t("ProviderWizardClientFocusForm_Label")}
        </Typography>

        <ControlledCheckboxButtons
          control={control}
          name="clientFocus"
          options={PROVIDER_CLIENT_TYPE_OPTIONS}
          gridSpacing={4}
          gridItemXs={6}
        />
      </Container>
    </form>
  );
};
