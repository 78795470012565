import { Grid, Typography } from "@mui/material";
import { FC } from "react";

interface Props {
  value: string;
  label: string;
}

export const ProjectReportFramesSummaryUnitView: FC<Props> = (props) => {
  const { value, label } = props;

  return (
    <Grid item xs={12} sm>
      <Typography variant="h2" whiteSpace="nowrap">
        {value}
      </Typography>
      <Typography color="text.500" variant="subtitle2" mt={3}>
        {label}
      </Typography>
    </Grid>
  );
};
