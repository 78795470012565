import { Paper, Stack, Typography } from "@mui/material";
import { FirebaseFilter, Provider } from "@snubes/snubes-types";
import { FC } from "react";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { ProvidersCollectionTableView } from "../../Providers/views/ProvidersCollectionTableView";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface Props {
  headline: TranslationKey;
  filters: FirebaseFilter<Provider>[];
}

export const AdminProvidersPage: FC<Props> = (props) => {
  const { headline, filters } = props;
  const t = useT();

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer>
        <Stack py={5} spacing={3}>
          <Typography variant="h2">{t(headline)}</Typography>
          <Paper>
            <ProvidersCollectionTableView filters={filters} />
          </Paper>
        </Stack>
      </FullHeightContainer>
    </>
  );
};
