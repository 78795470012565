import { isOrganization } from "@snubes/snubes-types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useOrganization(organizationId?: string) {
  return useValidDocumentData(
    organizationId ? getDocRef("organizations", organizationId) : null,
    isOrganization,
  );
}
