import { Avatar, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC } from "react";
import { STATUS_COLOR_RECORD } from "../consts/STATUS_COLOR_RECORD";
import { StatusColor } from "../types/StatusColor";

interface Props {
  Icon: OverridableComponent<SvgIconTypeMap>;
  size?: number;
  color?: StatusColor;
  bgcolor?: string;
}

export const AvatarWithIconView: FC<Props> = (props) => {
  const { Icon, bgcolor } = props;
  const color = props.color || "default";
  const size = props.size || CIRCLE_SIZE;

  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        bgcolor: bgcolor || STATUS_COLOR_RECORD[color].backgroundColor,
        color: STATUS_COLOR_RECORD[color].color,
      }}
    >
      <Icon sx={{ fontSize: size * 0.6 }} />
    </Avatar>
  );
};

const CIRCLE_SIZE = 36;
