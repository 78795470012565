import { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { getLanguageName } from "../../Common/helpers/getLanguageName";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { NUMBER_OF_EMPLOYEES_OPTIONS_VALUES } from "../../Organizations/consts/NUMBER_OF_EMPLOYEES_OPTIONS_TYPE_RECORD";
import { ORGANIZATION_INDUSTRY_RECORD } from "../../Organizations/consts/ORGANIZATION_INDUSTRY_RECORD";
import { TENDER_SERVICE_TYPE_RECORD } from "../../Tenders/consts/TENDER_SERVICE_TYPE_RECORD";
import { useT } from "../../Translation/hooks/useT";
import { updateActiveWizardSteps } from "../../Wizard/helpers/updateActiveWizardSteps";
import { updateNextAndPreviousWizardSteps } from "../../Wizard/helpers/updateNextAndPreviousWizardSteps";
import { updateWizardStepPaths } from "../../Wizard/helpers/updateWizardStepPaths";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { PROVIDER_CLIENT_FOCUS_RECORD } from "../consts/PROVIDER_CLIENT_FOCUS_RECORD";
import { PROVIDER_CLIENT_TYPE } from "../consts/PROVIDER_CLIENT_TYPE";
import { PROVIDER_TEAM_MODEL } from "../consts/PROVIDER_TEAM_MODEL";
import { PROVIDER_TECHNOLOGY } from "../consts/PROVIDER_TECHNOLOGY";
import { ProviderWizardStep } from "../types/ProviderWizardStep";
import { ProviderPricingModelsView } from "../views/ProviderPricingModelsView";
import { ProviderWizardAddressForm } from "../views/ProviderWizardAddressForm";
import { ProviderWizardClientFocusForm } from "../views/ProviderWizardClientFocusForm";
import { ProviderWizardClientTypesForm } from "../views/ProviderWizardClientTypesForm";
import { ProviderWizardContactInfoForm } from "../views/ProviderWizardContactInfoForm";
import { ProviderWizardDescriptionForm } from "../views/ProviderWizardDescriptionForm";
import { ProviderWizardIndustriesForm } from "../views/ProviderWizardIndustriesForm";
import { ProviderWizardNumberOfEmployeesForm } from "../views/ProviderWizardNumberOfEmployeesForm";
import { ProviderWizardOperationCentersForm } from "../views/ProviderWizardOperationCentersForm";
import { ProviderWizardOperationCentersView } from "../views/ProviderWizardOperationCentersView";
import { ProviderWizardOrganizationIndustryForm } from "../views/ProviderWizardOrganizationIndustryForm";
import { ProviderWizardPricingModelsForm } from "../views/ProviderWizardPricingModelsForm";
import { ProviderWizardRevenueForm } from "../views/ProviderWizardRevenueForm";
import { ProviderWizardReviewForm } from "../views/ProviderWizardReviewForm";
import { ProviderWizardServicesForm } from "../views/ProviderWizardServicesForm";
import { ProviderWizardSupportedLanguagesForm } from "../views/ProviderWizardSupportedLanguagesForm";
import { ProviderWizardTeamModelForm } from "../views/ProviderWizardTeamModelForm";
import { ProviderWizardToolsAndTechnologiesForm } from "../views/ProviderWizardToolsAndTechnologiesForm";
import { ProviderWizardWorkingHoursForm } from "../views/ProviderWizardWorkingHoursForm";
import { ProviderWorkingHoursView } from "../views/ProviderWorkingHoursView";

export function useProviderWizardSteps(): ProviderWizardStep[] {
  const t = useT();
  const { pathname } = useLocation();

  return useMemo(() => {
    const steps: ProviderWizardStep[] = [
      {
        title: t("useProviderWizardSteps_Title_BasicDetails"),
        pathSegment: "basic-details",
        steps: [
          {
            title: t("useProviderWizardSteps_Title_Contact"),
            pathSegment: "contact-info",
            Form: ProviderWizardContactInfoForm,
            valueSelector: (state) =>
              [state.doc?.email, state.doc?.phone, state.doc?.website]
                .filter(Boolean)
                .join(", "),
            progressSelector: (state) =>
              (state.doc?.organizationName ? 0.25 : 0) +
              (state.doc?.email ? 0.25 : 0) +
              (state.doc?.phone ? 0.25 : 0) +
              (state.doc?.website ? 0.25 : 0),
          },
          {
            title: t("useProviderWizardSteps_Title_Address"),
            pathSegment: "address",
            Form: ProviderWizardAddressForm,
            valueSelector: (state) =>
              [state.doc?.street, state.doc?.city, state.doc?.zipCode]
                .filter(Boolean)
                .join(", "),
            progressSelector: (state) =>
              (state.doc?.street ? 0.25 : 0) +
              (state.doc?.city ? 0.25 : 0) +
              (state.doc?.zipCode ? 0.25 : 0) +
              (state.doc?.country ? 0.25 : 0),
          },
          {
            title: t("useProviderWizardSteps_Title_Description"),
            pathSegment: "description",
            Form: ProviderWizardDescriptionForm,
            valueSelector: (state) =>
              `${state.doc?.description}. ${t(
                "OrganizationEditForm_Field_Founded",
              )} ${state.doc?.organizationFoundedInYear}.`,
            progressSelector: (state) =>
              (state.doc?.description ? 0.5 : 0) +
              (state.doc?.organizationFoundedInYear ? 0.5 : 0),
          },
          {
            title: t("useProviderWizardSteps_Title_OrganizationIndustry"),
            pathSegment: "organization-industry",
            Form: ProviderWizardOrganizationIndustryForm,
            valueSelector: (state) =>
              state.doc?.organizationIndustry &&
              t(
                ORGANIZATION_INDUSTRY_RECORD[state.doc?.organizationIndustry]
                  .label,
              ),
            progressSelector: (state) =>
              state.doc?.organizationIndustry ? 1 : 0,
          },
          {
            title: t("useProviderWizardSteps_Title_NumberOfEmployees"),
            pathSegment: "number-of-employees",
            Form: ProviderWizardNumberOfEmployeesForm,
            valueSelector: (state) =>
              NUMBER_OF_EMPLOYEES_OPTIONS_VALUES.find(
                (option) => option.value === state.doc?.numberOfEmployees,
              )?.tooltipLabel || state.doc?.numberOfEmployees,
            progressSelector: (state) => (state.doc?.numberOfEmployees ? 1 : 0),
          },
        ],
      },
      {
        title: t("useProviderWizardSteps_Title_BusinessDetails"),
        pathSegment: "business-details",
        steps: [
          {
            title: t("useProviderWizardSteps_Title_Services"),
            pathSegment: "services",
            Form: ProviderWizardServicesForm,
            valueSelector: (state) =>
              state.doc?.services
                ?.map((service) =>
                  t(TENDER_SERVICE_TYPE_RECORD[service].translationKey),
                )
                .join(", "),
            progressSelector: (state) => (state.doc?.services?.length ? 1 : 0),
          },
          {
            title: t("useProviderWizardSteps_Title_ClientTypes"),
            pathSegment: "client-types",
            Form: ProviderWizardClientTypesForm,
            valueSelector: (state) =>
              state.doc?.clientTypes
                ?.map((type) => t(PROVIDER_CLIENT_TYPE[type].label))
                .join(", "),
            progressSelector: (state) =>
              state.doc?.clientTypes?.length ? 1 : 0,
          },
          {
            title: t("useProviderWizardSteps_Title_ClientFocus"),
            pathSegment: "client-focus",
            Form: ProviderWizardClientFocusForm,
            valueSelector: (state) =>
              state.doc?.clientFocus
                ?.map((type) => t(PROVIDER_CLIENT_FOCUS_RECORD[type].label))
                .join(", "),
            progressSelector: (state) =>
              state.doc?.clientFocus?.length ? 1 : 0,
          },
          {
            title: t("useProviderWizardSteps_Title_Industries"),
            pathSegment: "industries",
            Form: ProviderWizardIndustriesForm,
            valueSelector: (state) =>
              state.doc?.industries
                ?.map((industry) =>
                  t(ORGANIZATION_INDUSTRY_RECORD[industry].label),
                )
                .join(", "),
            progressSelector: (state) =>
              state.doc?.industries?.length ? 1 : 0,
          },
          {
            title: t("useProviderWizardSteps_Title_Revenue"),
            pathSegment: "revenue",
            Form: ProviderWizardRevenueForm,
            valueSelector: (state) =>
              state.doc?.revenuePerYear
                ? toLocaleMoney(state.doc.revenuePerYear)
                : undefined,
            progressSelector: (state) => (state.doc?.revenuePerYear ? 1 : 0),
          },
        ],
      },
      {
        title: t("useProviderWizardSteps_Title_ServiceDetails"),
        pathSegment: "service-details",
        steps: [
          {
            title: t("useProviderWizardSteps_Title_PricingModels"),
            pathSegment: "pricing-models",
            Form: ProviderWizardPricingModelsForm,
            valueSelector: (state) =>
              state.doc ? (
                <ProviderPricingModelsView provider={state.doc} />
              ) : null,
            progressSelector: (state) =>
              Object.values(
                state.doc?.pricingModelsByServiceCategory || {},
              ).some((models) => models.length)
                ? 1
                : 0,
          },
          {
            title: t("useProviderWizardSteps_Title_ToolsAndTechnologies"),
            pathSegment: "tools-and-technologies",
            Form: ProviderWizardToolsAndTechnologiesForm,
            valueSelector: (state) =>
              state.doc?.toolsAndTechnologies
                ?.map((value) => t(PROVIDER_TECHNOLOGY[value].label))
                .join(", "),
            progressSelector: (state) =>
              state.doc?.toolsAndTechnologies?.length ? 1 : 0,
          },
          {
            title: t("useProviderWizardSteps_Title_SupportedLanguages"),
            pathSegment: "supported-languages",
            Form: ProviderWizardSupportedLanguagesForm,
            valueSelector: (state) =>
              state.doc?.supportedLanguages?.map(getLanguageName).join(", "),
            progressSelector: (state) =>
              state.doc?.supportedLanguages?.length ? 1 : 0,
          },
        ],
      },
      {
        title: t("useProviderWizardSteps_Title_OperationalDetails"),
        pathSegment: "operational-details",
        steps: [
          {
            title: t("useProviderWizardSteps_Title_WorkingHours"),
            pathSegment: "working-hours",
            Form: ProviderWizardWorkingHoursForm,
            valueSelector: (state) =>
              state.doc ? (
                <ProviderWorkingHoursView provider={state.doc} />
              ) : null,
            progressSelector: (state) =>
              state.doc?.isWorkingAroundTheClock === undefined ? 0 : 1,
          },
          {
            title: t("useProviderWizardSteps_Title_TeamModel"),
            pathSegment: "team-model",
            Form: ProviderWizardTeamModelForm,
            valueSelector: (state) =>
              state.doc?.teamModels
                ?.map((model) => t(PROVIDER_TEAM_MODEL[model].label))
                .join(", "),
            progressSelector: (state) =>
              state.doc?.teamModels?.length ? 1 : 0,
          },
          {
            title: t("useProviderWizardSteps_Title_OperationCenters"),
            pathSegment: "operation-centers",
            Form: ProviderWizardOperationCentersForm,
            valueSelector: (state) =>
              state.doc?.operationCenters && (
                <ProviderWizardOperationCentersView
                  operationCenters={state.doc.operationCenters}
                />
              ),
            progressSelector: (state) => {
              const operationCenters = state.doc?.operationCenters;
              if (!operationCenters?.length) {
                return 0;
              }
              return (
                operationCenters.reduce<number>((result, operationCenter) => {
                  return [
                    operationCenter.location?.city,
                    operationCenter.location?.country,
                    operationCenter.employees,
                    operationCenter.startingYear,
                  ].reduce<number>(
                    (sum, value) => sum + (value ? 0.25 : 0),
                    result,
                  );
                }, 0) / operationCenters.length
              );
            },
          },
        ],
      },
      {
        title: t("useTenderWizardSteps_Title_Review"),
        pathSegment: "review",
        Form: ProviderWizardReviewForm as FC<WizardFormProps<unknown>>,
        formProps: {
          getSteps: () => steps,
        },
        progressSelector: (state) =>
          (state.doc?.isReviewed?.basicDetails ? 0.25 : 0) +
          (state.doc?.isReviewed?.businessDetails ? 0.25 : 0) +
          (state.doc?.isReviewed?.serviceDetails ? 0.25 : 0) +
          (state.doc?.isReviewed?.operationalDetails ? 0.25 : 0),
      },
    ];

    // split "/providers/:id/create" from the rest of the path
    const paths = pathname.split("/").slice(3);
    const previousRoute = pathname.split("/").slice(0, 3).join("/");

    updateActiveWizardSteps(paths, steps);
    updateNextAndPreviousWizardSteps(steps);
    updateWizardStepPaths(steps, previousRoute);

    return steps;
  }, [pathname, t]);
}
