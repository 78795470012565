import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { isTenderStatus, Tender, TENDER_STATUSES } from "@snubes/snubes-types";
import { FC, useCallback, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { updateTenderStatusCallable } from "../callables/updateTenderStatusCallable";
import { TENDER_STATUS_RECORD } from "../consts/TENDER_STATUS_RECORD";

interface Props {
  tender: Tender;
}

/**
 * @deprecated TODO: Properly handle ending tenders or resetting them back to draft/waiting-for-review.
 */
export const TenderStatusSelectView: FC<Props> = (props) => {
  const { tender } = props;
  const tenderId = tender.id;
  const [isUpdating, setIsUpdating] = useState(false);
  const t = useT();

  const onChange = useCallback(
    async (event: SelectChangeEvent<unknown>) => {
      const status = event.target.value;
      if (isTenderStatus(status)) {
        try {
          setIsUpdating(true);
          await updateTenderStatusCallable({ status, tenderId });
        } catch (error) {
          handleError(error).logAnd().toast();
        } finally {
          setIsUpdating(false);
        }
      }
    },
    [tenderId],
  );

  return (
    <FormControl
      disabled={isUpdating}
      sx={{ minWidth: 170, backgroundColor: "background.paper" }}
    >
      <InputLabel>{t("TenderStatusSelectView_Select_TenderStatus")}</InputLabel>
      <Select
        label={t("TenderStatusSelectView_Select_TenderStatus")}
        value={tender.status}
        onChange={onChange}
      >
        {TENDER_STATUSES.map((status) => (
          <MenuItem
            key={status}
            value={status}
            disabled={["PUBLISHED", "ENDED"].includes(status)}
          >
            {t(TENDER_STATUS_RECORD[status].label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
