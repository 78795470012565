import { superstructResolver } from "@hookform/resolvers/superstruct";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Button,
  Divider,
  FormHelperText,
  IconButton,
  Paper,
  Stack,
} from "@mui/material";
import {
  Project,
  ProjectCallCategory,
  ProjectCallCategoryStruct,
} from "@snubes/snubes-types";
import { FC, Fragment, useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { array, Infer, type } from "superstruct";
import { v4 } from "uuid";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { updateProjectCallCategoriesCallable } from "../callables/updateProjectCallCategoriesCallable";
import { getProjectOrganizationIds } from "../helpers/getProjectOrganizationIds";
import { ProjectCallCategoriesFormHeaderView } from "./ProjectCallCategoriesFormHeaderView";

const FormValuesStruct = type({
  callCategories: array(ProjectCallCategoryStruct),
});

type FormValues = Infer<typeof FormValuesStruct>;

interface Props {
  project: Project;
}

export const ProjectCallCategoriesForm: FC<Props> = (props) => {
  const t = useT();
  const hasPermission = useHasPermission();
  const canUpdateProjectCallCategories = hasPermission(
    "CAN_UPDATE_PROJECT_CALL_CATEGORIES",
    { organizationIds: getProjectOrganizationIds(props.project) },
  );

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      callCategories: props.project.callCategories || [],
    },
  });
  const isFormDisabled = isSubmitting || !canUpdateProjectCallCategories;

  const { fields, append, remove } = useFieldArray({
    control,
    keyName: "fieldId", // We need to explicitly use react-hook-form's fieldId because otherwise the id field from the data will be overwritten
    name: "callCategories",
  });

  const addCallCategory = useCallback(
    () => append({ id: v4(), name: "" }),
    [append],
  );

  const removeCallCategory = (index: number) => remove(index);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      if (isSubmitting) return;

      try {
        const { callCategories } = formValues;

        await updateProjectCallCategoriesCallable({
          projectId: props.project.id,
          callCategories: callCategories.length ? callCategories : null,
        });

        toast.success(t("ProjectCallCategoriesForm_Toast_SavedCallCategories"));
        reset(formValues);
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [isSubmitting, props.project.id, reset, t],
  );

  const getIsCategoryUsedInFlaggingConfig = useCallback(
    (categoryId: string) => {
      return !!props.project.callFlaggingConfigs?.some((config) => {
        return config.parameters.some(
          (parameter) =>
            parameter.type === "CATEGORY" && parameter.value === categoryId,
        );
      });
    },
    [props.project.callFlaggingConfigs],
  );

  const onSetCallCategoriesFormValue = useCallback(
    (newCallCategories: ProjectCallCategory[]) => {
      setValue("callCategories", newCallCategories);
    },
    [setValue],
  );

  return (
    <>
      <ProjectCallCategoriesFormHeaderView
        isFormDirty={isDirty || !!dirtyFields.callCategories}
        callCategories={props.project.callCategories || []}
        onSetCallCategories={onSetCallCategoriesFormValue}
      />
      <Paper
        variant="outlined"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack spacing={3} p={3}>
          {fields.map((field, index) => {
            const isCategoryUsedInFlaggingConfig =
              getIsCategoryUsedInFlaggingConfig(field.id);
            const isFieldDisabled =
              isFormDisabled || isCategoryUsedInFlaggingConfig;

            return (
              <Fragment key={field.id}>
                <Stack direction="row" spacing={3} alignItems="center">
                  <Stack flex={2}>
                    <ControlledTextField
                      control={control}
                      name={`callCategories.${index}.name`}
                      label={t("ProjectCallCategoriesForm_Label_Name")}
                      disabled={isFieldDisabled}
                      required
                      fullWidth
                    />
                  </Stack>
                  <IconButton
                    size="large"
                    color="error"
                    onClick={() => removeCallCategory(index)}
                    disabled={isFieldDisabled}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Stack>
                {isCategoryUsedInFlaggingConfig && (
                  <FormHelperText>
                    {t(
                      "ProjectCallCategoriesForm_Label_CategoryIsUsedInFlaggingConfig",
                    )}
                  </FormHelperText>
                )}
                <Divider />
              </Fragment>
            );
          })}

          <Stack direction="row" bgcolor="grey.50" borderColor="divider">
            <Button
              variant="text"
              onClick={addCallCategory}
              startIcon={<AddCircleIcon />}
              disabled={isFormDisabled}
              fullWidth
              sx={{
                fontSize: 14,
                justifyContent: "flex-start",
                fontWeight: "bold",
                pl: 3,
              }}
            >
              {t("ProjectCallCategoriesForm_Button_NewCallCategory")}
            </Button>
          </Stack>
          <Divider />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isFormDisabled}
          >
            {t("Common_Save")}
          </Button>
        </Stack>
      </Paper>
    </>
  );
};
