import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PauseIcon from "@mui/icons-material/Pause";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ReplayIcon from "@mui/icons-material/Replay";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ProcessStatus } from "@snubes/snubes-types";

interface ProcessStatusRecordValue {
  color: string;
  Icon: OverridableComponent<SvgIconTypeMap>;
}

export const PROCESS_STATUS_RECORD: Record<
  ProcessStatus,
  ProcessStatusRecordValue
> = {
  ON_HOLD: {
    color: "text.500",
    Icon: PauseIcon,
  },
  PROCESSING: {
    color: "primary.main",
    Icon: PlayCircleIcon,
  },
  SUCCEEDED: {
    color: "secondary.main",
    Icon: CheckCircleIcon,
  },
  FAILED: {
    color: "error.main",
    Icon: ErrorIcon,
  },
  REQUESTED: {
    color: "primary.main",
    Icon: ReplayIcon,
  },
};
