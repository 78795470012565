import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import {
  Button,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Call, CallIssue } from "@snubes/snubes-types";
import { Timestamp } from "firebase/firestore";
import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { handleError } from "../../Common/helpers/handleError";
import { useMediaQueryUp } from "../../Common/hooks/useMediaQueryUp";
import { AvatarView } from "../../Common/views/AvatarView";
import { DateTimeView } from "../../Common/views/DateTimeView";
import { RoundButton } from "../../Common/views/RoundButton";
import { StatusChip } from "../../Common/views/StatusChip";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useProjectContext } from "../../Projects/hooks/useProjectContext";
import { useT } from "../../Translation/hooks/useT";
import { completeCallReviewCallable } from "../callables/completeCallReviewCallable";
import { useGetCallIssueName } from "../hooks/useGetCallIssueName";
import { CallAssignTaskView } from "./CallAssignTaskView";
import { CallIssueCreateDialog } from "./CallIssueCreateDialog";
import { CallIssueDeleteDialog } from "./CallIssueDeleteDialog";
import { CallTaskStatusChip } from "./CallTaskStatusChip";

interface TableColumn {
  title: ReactNode;
  field: keyof CallIssue;
  sx?: SxProps;
  align?: TableCellProps["align"];
  renderCell?: (issue: CallIssue) => ReactNode;
}

interface Props {
  call: Call;
}

export const CallIssuesView: FC<Props> = (props) => {
  const { call } = props;
  const t = useT();
  const { project } = useProjectContext();
  const isUpLg = useMediaQueryUp("lg");
  const getCallIssueName = useGetCallIssueName();
  const [isCompletingCallReview, setIsCompletingCallReview] = useState(false);

  const organizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );
  const hasPermission = useHasPermission();
  const canDeleteCallIssue = hasPermission("CAN_DELETE_CALL_ISSUE", {
    organizationId,
  });

  const issues = useMemo(
    () =>
      call.issues
        ? Object.values(call.issues).sort(
            (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis(),
          )
        : [],
    [call.issues],
  );
  const hasIssues = Boolean(issues.length);

  const [isReportIssueDialogOpen, setReportIssueDialogOpen] = useState(false);

  const onClickedOpenReportIssueDialog = useCallback(() => {
    setReportIssueDialogOpen(true);
  }, []);

  const onClickedCloseReportIssueDialog = useCallback(() => {
    setReportIssueDialogOpen(false);
  }, []);

  const onClickCompleteCallReview = useCallback(async () => {
    try {
      setIsCompletingCallReview(true);
      await completeCallReviewCallable({ callId: call.id });
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsCompletingCallReview(false);
    }
  }, [call.id]);

  const [issueToDelete, setIssueToDelete] = useState<CallIssue | undefined>();

  const columns = useMemo<TableColumn[]>(
    () => [
      {
        title: t("CallIssuesView_Column_ReportedIssue"),
        field: "name",
        renderCell: (issue) => {
          const issueCategory = project?.issueCategories?.find(
            (c) => c.id === issue.issueCategoryId,
          );

          return (
            <>
              {issueCategory && (
                <StatusChip
                  sx={{ mr: 2 }}
                  color="primary"
                  label={issueCategory.name}
                />
              )}
              {getCallIssueName(issue)}
            </>
          );
        },
      },
      {
        title: t("CallIssuesView_Column_ReportedAt"),
        field: "createdAt",
      },
      {
        title: t("CallIssuesView_Column_ReportedBy"),
        field: "reporterName",
        renderCell: (issue) => (
          <Stack gap={2} direction="row" alignItems="center">
            <AvatarView
              name={issue.reporterName ?? "Artificial Intelligence"}
              size={30}
            />
            <Typography variant="subtitle1">
              {issue.reporterName ??
                t("CallIssuesView_Column_AutomaticallyFlagged")}
            </Typography>
          </Stack>
        ),
      },

      ...(canDeleteCallIssue
        ? [
            {
              title: "",
              field: "status",
              align: "right",
              sx: {
                // shrink column width to content size
                width: "0.1%",
                whiteSpace: "nowrap",
              },
              renderCell: (issue) => {
                return (
                  <IconButton
                    color="error"
                    onClick={() => setIssueToDelete(issue)}
                  >
                    <DeleteIcon />
                  </IconButton>
                );
              },
            } satisfies TableColumn,
          ]
        : []),
    ],
    [t, canDeleteCallIssue, project?.issueCategories, getCallIssueName],
  );

  const isCompleted = !!call.reviewCompletedAt;
  const isCompleteReviewAllowed =
    !!call.assigneeUserId &&
    hasPermission("CAN_COMPLETE_CALL_REVIEW", {
      userId: call.assigneeUserId,
    });

  return (
    <Paper variant="outlined">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p={3}
      >
        <Stack direction="row" alignItems="center" spacing={3}>
          <FlagCircleIcon
            color={hasIssues ? "error" : "secondary"}
            fontSize="large"
          />
          {isUpLg && (
            <>
              {hasIssues && (
                <Typography variant="h5" color="error.main">
                  {t("CallIssuesView_Title_FlaggedForReview")}
                </Typography>
              )}
              {!hasIssues && (
                <Typography variant="h5" color="secondary.main">
                  {t("CallIssuesView_Title_NotFlagged")}
                </Typography>
              )}
            </>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={3}>
          <RoundButton
            variant="outlined"
            size="small"
            color="error"
            startIcon={<ReportOutlinedIcon />}
            onClick={onClickedOpenReportIssueDialog}
          >
            {t("CallIssuesView_Button_ReportIssue")}
          </RoundButton>
          <Divider flexItem orientation="vertical" />
          {isCompleted && call.assigneeName && (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography>{t("CallIssuesView_Label_AssignedTo")}</Typography>
              <AvatarView name={call.assigneeName} size={24} />
              <Typography variant="subtitle2">{call.assigneeName}</Typography>
            </Stack>
          )}
          {!isCompleted && (
            <>
              <CallAssignTaskView call={call} />
              <Collapse
                in={isCompleteReviewAllowed}
                orientation="horizontal"
                sx={{ height: "unset" }}
              >
                <Stack direction="row" alignItems="center" spacing={3}>
                  <Divider flexItem orientation="vertical" />
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    startIcon={<CheckCircleIcon />}
                    onClick={onClickCompleteCallReview}
                    disabled={isCompletingCallReview}
                  >
                    {t("CallIssuesView_Button_CompleteReview")}
                  </Button>
                </Stack>
              </Collapse>
            </>
          )}
          {isCompleted && (
            <>
              <Divider flexItem orientation="vertical" />
              <CallTaskStatusChip call={call} />
            </>
          )}
        </Stack>
      </Stack>

      {hasIssues && <Divider />}

      {hasIssues && (
        <TableContainer sx={{ px: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    sx={(theme) => ({
                      pl: 0,
                      color: theme.palette.text.secondary,
                      ...theme.typography.h5,
                    })}
                  >
                    {column.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {issues.map((issue) => (
                <TableRow key={issue.id}>
                  {columns.map((column) => {
                    const value = issue[column.field];
                    return (
                      <TableCell
                        key={column.field}
                        sx={{ pl: 0, ...column.sx }}
                        align={column.align}
                      >
                        {value instanceof Timestamp ? (
                          <DateTimeView date={value} />
                        ) : (
                          column.renderCell?.(issue) || String(value)
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <CallIssueCreateDialog
        callId={call.id}
        isOpen={isReportIssueDialogOpen}
        onClose={onClickedCloseReportIssueDialog}
      />
      <CallIssueDeleteDialog
        callIssue={issueToDelete}
        onClose={() => setIssueToDelete(undefined)}
      />
    </Paper>
  );
};
