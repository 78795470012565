import { WizardStep } from "../types/WizardStep";

export function updateNextAndPreviousWizardSteps<TState>(
  steps: WizardStep<TState>[],
) {
  const formSteps: WizardStep<TState>[] = [];
  const fillFormSteps = (steps: WizardStep<TState>[]) => {
    steps.forEach((step) => {
      if (step.Form) {
        formSteps.push(step);
      }

      if (step.steps) {
        fillFormSteps(step.steps);
      }
    });
  };

  fillFormSteps(steps);

  formSteps.forEach((step, index) => {
    step.previousStep = formSteps[index - 1];
    step.nextStep = formSteps[index + 1];
  });
}
