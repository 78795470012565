import { FC, PropsWithChildren, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logEvent } from "../helpers/logEvent";

export const AnalyticsProvider: FC<PropsWithChildren> = (props) => {
  // Use location to track page views. See https://reactrouter.com/en/main/hooks/use-location
  const location = useLocation();

  useEffect(() => {
    logEvent({
      event: "page_view",
      title: document.title,
      path: location.pathname,
      url: window.location.href,
    });
  }, [location]);

  return <>{props.children}</>;
};
