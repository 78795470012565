import { NEW_PROJECT_ID_PLACEHOLDER } from "@snubes/snubes-types";

interface ProjectMapping {
  projectId: string | null;
  externalProjectId: string | null;
}

export function haveProjectMappingsDuplicates(
  projectMappings: ProjectMapping[],
) {
  const filteredProjectMappings = projectMappings.filter(
    (m) => m.projectId !== NEW_PROJECT_ID_PLACEHOLDER,
  );
  return (
    filteredProjectMappings.map((m) => m.projectId).length !==
    new Set(filteredProjectMappings.map((v) => v.projectId)).size
  );
}
