import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import { Button } from "@mui/material";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { useT } from "../../Translation/hooks/useT";
import { useWizardStepProgress } from "../../Wizard/hooks/useWizardStepProgress";
import { WizardStep } from "../../Wizard/types/WizardStep";
import { WizardStore } from "../../Wizard/types/WizardStore";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TState,
> {
  control: Control<TFieldValues & Record<TName, boolean>>;
  name: TName;
  step: WizardStep<TState>;
  store: WizardStore<TState>;
  required?: boolean;
  disabled?: boolean;
}

export function ControlledWizardReviewButton<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
  TState,
>(props: Props<TFieldValues, TName, TState>) {
  const { step, store } = props;
  const t = useT();
  const progress = useWizardStepProgress(step, store);

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field }) => {
        const isReviewed: boolean = field.value;

        return (
          <Button
            variant="regular"
            size="small"
            disabled={props.disabled || progress < 1}
            sx={{ color: isReviewed ? "secondary.main" : "text.500" }}
            startIcon={isReviewed ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
            onClick={(event) => {
              event.stopPropagation();
              field.onChange(!isReviewed);
            }}
          >
            {isReviewed
              ? t("ControlledReviewButton_Button_Reviewed")
              : t("ControlledReviewButton_Button_MarkAsReviewed")}
          </Button>
        );
      }}
    />
  );
}
