import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { Stack, SvgIconTypeMap, Tooltip } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Call } from "@snubes/snubes-types";
import { FC } from "react";
import { StatusColor } from "../../Common/types/StatusColor";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";

type Status =
  | "UNASSIGNED_WITHOUT_ISSUES"
  | "UNASSIGNED_WITH_ISSUES"
  | "ASSIGNED"
  | "COMPLETED";

interface StatusValue {
  label: TranslationKey;
  tooltip: TranslationKey;
  Icon: OverridableComponent<SvgIconTypeMap>;
  color: StatusColor;
}

const STATUS_RECORDS: Record<Status, StatusValue> = {
  UNASSIGNED_WITHOUT_ISSUES: {
    label: "CallTaskStatusChip_Label_Unassigned",
    tooltip: "CallTaskStatusChip_Tooltip_Unassigned",
    Icon: ErrorIcon,
    color: "default",
  },
  UNASSIGNED_WITH_ISSUES: {
    label: "CallTaskStatusChip_Label_Unassigned",
    tooltip: "CallTaskStatusChip_Tooltip_Unassigned",
    Icon: ErrorIcon,
    color: "warning",
  },
  ASSIGNED: {
    label: "CallTaskStatusChip_Label_Assigned",
    tooltip: "CallTaskStatusChip_Tooltip_Assigned",
    Icon: HowToRegIcon,
    color: "primary",
  },
  COMPLETED: {
    label: "CallTaskStatusChip_Label_Completed",
    tooltip: "CallTaskStatusChip_Tooltip_Completed",
    Icon: CheckCircleIcon,
    color: "secondary",
  },
};

interface Props {
  call: Call;
}

export const CallTaskStatusChip: FC<Props> = (props: Props) => {
  const { call } = props;
  const t = useT();
  const status: Status = call.reviewCompletedAt
    ? "COMPLETED"
    : call.assigneeUserId
    ? "ASSIGNED"
    : call.hasIssues
    ? "UNASSIGNED_WITH_ISSUES"
    : "UNASSIGNED_WITHOUT_ISSUES";

  const { label, tooltip, Icon, color } = STATUS_RECORDS[status];

  return (
    <Tooltip
      placement="right"
      title={t(tooltip, {
        name: call.assigneeName || t("Common_Unknown"),
      })}
      arrow
    >
      <Stack>
        <StatusChip Icon={Icon} label={t(label)} color={color} />
      </Stack>
    </Tooltip>
  );
};
