export const HELP_CENTER_AGENTS = [
  {
    imageUrl: "/assets/help-center-agent-sb.jpg",
    name: "Steven Basel",
    title: "Director Sales Operations",
    phones: ["+49 30 3119 8930", "+49 173 255 1034"],
    email: "steven.basel@snubes.com",
  },
  {
    imageUrl: "/assets/help-center-agent-tw.jpg",
    name: "Thomas Wittkopf",
    title: "Director Growth",
    phones: ["+49 30 229 5756 3", "+41 76 268 92 98"],
    email: "thomas.wittkopf@snubes.com",
  },
];
