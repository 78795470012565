import { Settings } from "luxon";

declare module "luxon" {
  interface TSSettings {
    // Set to `true` so that DateTime functions don't return nullable values.
    throwOnInvalid: true;
  }
}

// Must not be true, otherwise it breaks @mui/x-date-pickers. Invalid DateTimes will
// not throw in the frontend but just return null (even though the type says otherwise).
// This is easier to deal with in the code and the chance of an invalid DateTime is low.
// In any case a `null` DateTime value would throw anyway somewhere. ¯\_(ツ)_/¯
Settings.throwOnInvalid = false;
