import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { removeMemberCallable } from "../callables/removeMemberCallable";

interface Props {
  organizationId: string;
  memberId: string;
}

export const RemoveMemberButton: FC<Props> = (props) => {
  const { memberId, organizationId } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const removeMember = useCallback(async () => {
    try {
      setIsSubmitting(true);

      await removeMemberCallable({
        memberId,
        organizationId,
      });
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsSubmitting(false);
    }
  }, [organizationId, memberId]);

  return (
    <IconButton
      onClick={async (event) => {
        event.preventDefault();
        event.stopPropagation();
        await removeMember();
      }}
      color="error"
      disabled={isSubmitting}
    >
      <DeleteIcon />
    </IconButton>
  );
};
