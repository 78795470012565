import { isTender, Tender, TenderStatus } from "@snubes/snubes-types";
import { usePaginatedCollection } from "../../Firebase/hooks/usePaginatedCollection";

interface Args {
  organizationId: string | null;
}

export function useLatestDraftTender(args: Args): Tender | undefined {
  const { rows } = usePaginatedCollection({
    collectionName: "tenders",
    isT: isTender,
    filters: [
      ["organizationId", "==", args.organizationId],
      ["status", "==", "DRAFT" satisfies TenderStatus],
    ],
    orderBy: ["createdAt", "desc"],
    rowsPerPage: 1,
  });
  return rows[0];
}
