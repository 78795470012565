import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { useNotifications } from "../hooks/useNotifications";
import { NotificationsCollectionListView } from "./NotificationsCollectionListView";
import { NotificationsMarkAllAsReadButton } from "./NotificationsMarkAllAsReadButton";

export const NotificationsMyTasksPage: FC = () => {
  const t = useT();
  const userId = useMyUser((state) => state.user?.id || null);
  const organizationId = useSelectedOrganization((state) => state?.id || null);

  const [, , , unreadTasksCount] = useNotifications({
    organizationId,
    userId,
    filters: [
      ["hasBeenRead", "==", false],
      ["isTask", "==", true],
    ],
    limit: 1,
    withCount: true,
  });

  if (!userId || !organizationId) {
    return null;
  }

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer>
        <Stack py={5} spacing={3}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={3}
          >
            <Typography variant="h3">
              {t("NotificationsUnassignedTasksPage_Headline_MyTasks")}
            </Typography>

            {organizationId && (
              <NotificationsMarkAllAsReadButton
                organizationId={organizationId}
                isDisabled={!unreadTasksCount}
                isAssigned
                isTask
              />
            )}
          </Stack>
          <NotificationsCollectionListView
            userId={userId}
            organizationId={organizationId}
            filters={[["isTask", "==", true]]}
          />
        </Stack>
      </FullHeightContainer>
    </>
  );
};
