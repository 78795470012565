import {
  FirebaseTableFilter,
  FirebaseTableFilterOfType,
  FirebaseTableFilterType,
} from "../hooks/useFirebaseTableFilters";

export function isFirebaseTableFilterType<T extends FirebaseTableFilterType>(
  filter: FirebaseTableFilter,
  filterType: T,
): filter is FirebaseTableFilterOfType<T> {
  return filter.type === filterType;
}
