import { Avatar } from "@mui/material";
import { FC } from "react";

interface Props {
  count: number;
  maxCount?: number;
}

export const HomeCardCountView: FC<Props> = (props) => {
  const { count, maxCount } = props;
  return (
    <Avatar
      sx={{
        backgroundColor: "primary.main",
        mr: 1,
        display: "inline-flex",
        width: 24,
        height: 24,
        fontSize: 12,
      }}
    >
      {(maxCount && count > maxCount ? `${maxCount}+` : count) || 0}
    </Avatar>
  );
};
