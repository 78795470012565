import LocationCityIcon from "@mui/icons-material/LocationCity";
import PinIcon from "@mui/icons-material/Pin";
import PlaceIcon from "@mui/icons-material/Place";
import { Container, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { string, type } from "superstruct";
import { ControlledCountriesAutocomplete } from "../../Form/views/ControlledCountriesAutocomplete";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  street: string(),
  city: string(),
  zipCode: string(),
  country: string(),
});

export const ProviderWizardAddressForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      street: provider?.street || "",
      city: provider?.city || "",
      zipCode: provider?.zipCode || "",
      country: provider?.country || "",
    }),
    toDoc: (formValues) => {
      return {
        street: formValues.street,
        city: formValues.city,
        zipCode: formValues.zipCode,
        country: formValues.country,
      };
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("ProviderWizardAddressForm_Title_Address")}
        </Typography>
        <Typography variant="body1" color="text.500" mb={4}>
          {t("ProviderWizardAddressForm_Label_Address")}
        </Typography>
        <Stack spacing={3}>
          <ControlledTextField
            required
            fullWidth
            name="street"
            label={t("ProviderWizardAddressForm_Label_Street")}
            control={control}
            endAdornment={<PlaceIcon sx={{ color: "text.secondary", mr: 2 }} />}
          />
          <ControlledTextField
            required
            fullWidth
            name="city"
            label={t("ProviderWizardAddressForm_Label_City")}
            control={control}
            endAdornment={
              <LocationCityIcon sx={{ color: "text.secondary", mr: 2 }} />
            }
          />
          <ControlledTextField
            required
            fullWidth
            name="zipCode"
            label={t("ProviderWizardAddressForm_Label_Zip")}
            control={control}
            endAdornment={<PinIcon sx={{ color: "text.secondary", mr: 2 }} />}
          />
          <ControlledCountriesAutocomplete
            required
            fullWidth
            name="country"
            label={t("ProviderWizardAddressForm_Label_Country")}
            control={control}
          />
        </Stack>
      </Container>
    </form>
  );
};
