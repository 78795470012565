import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Button,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  Call,
  CallProcessRequestKind,
  ProcessStatus,
} from "@snubes/snubes-types";
import { FC, useCallback, useEffect, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { CollapseExpandView } from "../../Common/views/CollapseExpandView";
import { useProjectContext } from "../../Projects/hooks/useProjectContext";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";
import { requestProcessCallCallable } from "../callables/requestProcessCallCallable";
import { hasOutdatedQuestionAnswers } from "../helpers/hasOutdatedQuestionAnswers";
import { isCallProcessExpanded } from "../helpers/isCallProcessExpanded";
import { isCallProcessing } from "../helpers/isCallProcessing";
import { CallProcessItemView } from "./CallProcessItemView";
import { CallQuestionAnswersOutdatedButton } from "./CallQuestionAnswersOutdatedButton";

interface Props {
  call: Call;
}

export const CallProcessView: FC<Props> = (props) => {
  const { call } = props;
  const t = useT();
  const { project } = useProjectContext();
  const {
    voicefileProcess,
    transcriptsProcess,
    summaryProcess,
    categoryProcess,
    questionAnswersProcess,
  } = call;

  const isProcessing = isCallProcessing(call, project);
  const isExpanded = isCallProcessExpanded(call, project);
  const hasCategories = !!project?.callCategories?.length;
  const hasQuestions = !!project?.questions?.length;
  const hasFlaggingConfigs = !!project?.callFlaggingConfigs?.length;
  const hasVoicefile = !!call.isVoicefileAvailable;
  const hasTranscripts = !!call.transcripts;
  const hasQuestionAnswers = !!call.questionAnswers?.length;
  const hasQuestionsOrQuestionAnswers = hasQuestions || hasQuestionAnswers;
  const hasOutdatedAnswers = hasOutdatedQuestionAnswers(
    project?.questions,
    call.questionAnswers,
  );
  const flaggingProcessStatus: ProcessStatus =
    questionAnswersProcess?.status === "SUCCEEDED" ? "SUCCEEDED" : "ON_HOLD";
  const [isRequesting, setIsRequesting] = useState(false);

  const requestProcessCall = useCallback(
    async (kind: CallProcessRequestKind) => {
      try {
        setIsRequesting(true);
        await requestProcessCallCallable({
          callId: call.id,
          kind,
        });
      } catch (error) {
        setIsRequesting(false);
        handleError(error).logAnd().toast();
      }
    },
    [call.id],
  );

  useEffect(() => {
    if (isProcessing) {
      setIsRequesting(false);
    }
  }, [isProcessing]);

  const isLoading = isProcessing || isRequesting;

  if (!project) return null;

  return (
    <Stack component={Paper} variant="outlined">
      <CollapseExpandView
        defaultExpanded={isExpanded}
        headerContent={
          <>
            <Stack flex={1}>
              <Typography variant="h4">
                {isLoading
                  ? t("CallProcessView_Heading_Processing")
                  : t("CallProcessView_Heading_NotProcessing")}
              </Typography>
            </Stack>
            {isLoading && <CircularProgress size={20} />}
          </>
        }
      >
        <CallProcessItemView
          title={t("CallProcessView_Title_Voicefile")}
          processStatus={voicefileProcess?.status || "ON_HOLD"}
          startedAt={voicefileProcess?.startedAt}
          errorMessage={
            voicefileProcess?.status === "FAILED"
              ? voicefileProcess?.errorMessage ||
                t("CallProcessView_Error_VoicefileOverdue")
              : undefined
          }
          caption={t(
            TRANSLATION_RECORD.VOICEFILE[voicefileProcess?.status || "ON_HOLD"],
          )}
          button={
            voicefileProcess?.status === "FAILED" ? (
              <Button
                startIcon={<RefreshIcon fontSize="small" />}
                size="tiny"
                variant="outlined"
                onClick={() => requestProcessCall("VOICEFILE")}
                disabled={isLoading}
              >
                {t("CallProcessView_Button_Redownload")}
              </Button>
            ) : undefined
          }
        />
        <Divider sx={{ mx: 4 }} />
        <CallProcessItemView
          title={t("CallProcessView_Title_Transcripts")}
          processStatus={transcriptsProcess?.status || "ON_HOLD"}
          startedAt={transcriptsProcess?.startedAt}
          errorMessage={
            transcriptsProcess?.status === "FAILED"
              ? transcriptsProcess?.errorMessage ||
                t("CallProcessView_Error_TranscriptsOverdue")
              : undefined
          }
          caption={t(
            TRANSLATION_RECORD.TRANSCRIPTS[
              transcriptsProcess?.status || "ON_HOLD"
            ],
          )}
          button={
            hasVoicefile && !isLoading ? (
              <Button
                startIcon={<RefreshIcon fontSize="small" />}
                size="tiny"
                variant="outlined"
                onClick={() => requestProcessCall("TRANSCRIPTS")}
              >
                {t("CallProcessView_Button_Regenerate")}
              </Button>
            ) : undefined
          }
        />
        <Divider sx={{ mx: 4 }} />
        <CallProcessItemView
          title={t("CallProcessView_Title_Summary")}
          processStatus={summaryProcess?.status || "ON_HOLD"}
          startedAt={summaryProcess?.startedAt}
          errorMessage={
            summaryProcess?.status === "FAILED"
              ? summaryProcess?.errorMessage ||
                t("CallProcessView_Error_SummaryOverdue")
              : undefined
          }
          caption={t(
            TRANSLATION_RECORD.SUMMARY[summaryProcess?.status || "ON_HOLD"],
          )}
          button={
            hasTranscripts && !isLoading ? (
              <Button
                startIcon={<RefreshIcon fontSize="small" />}
                size="tiny"
                variant="outlined"
                onClick={() => requestProcessCall("SUMMARY")}
              >
                {t("CallProcessView_Button_Regenerate")}
              </Button>
            ) : undefined
          }
        />
        {hasCategories && (
          <>
            <Divider sx={{ mx: 4 }} />
            <CallProcessItemView
              title={t("CallProcessView_Title_Category")}
              processStatus={categoryProcess?.status || "ON_HOLD"}
              startedAt={categoryProcess?.startedAt}
              errorMessage={
                categoryProcess?.status === "FAILED"
                  ? categoryProcess?.errorMessage ||
                    t("CallProcessView_Error_CategoryOverdue")
                  : undefined
              }
              caption={t(
                TRANSLATION_RECORD.CATEGORY[
                  categoryProcess?.status || "ON_HOLD"
                ],
              )}
              button={
                hasTranscripts && !isLoading ? (
                  <Button
                    startIcon={<RefreshIcon fontSize="small" />}
                    size="tiny"
                    variant="outlined"
                    onClick={() => requestProcessCall("CATEGORY")}
                  >
                    {t("CallProcessView_Button_Reassign")}
                  </Button>
                ) : undefined
              }
            />
          </>
        )}
        {!hasCategories && (
          <>
            <Divider />
            <CallProcessItemView
              Icon={NotInterestedIcon}
              color="text.500"
              backgroundColor="background.default"
              title={t("CallProcessView_Title_Category")}
              processStatus="ON_HOLD"
              caption={t("CallProcessView_Caption_NoCategoriesAdded")}
              button={
                <Button
                  size="tiny"
                  endIcon={<ChevronRightIcon />}
                  href={`/projects/${project.id}/settings/call-categories`}
                >
                  {t("CallProcessView_Button_GoToProjectCallCategories")}
                </Button>
              }
            />
          </>
        )}
        {hasQuestionsOrQuestionAnswers && (
          <>
            <Divider sx={{ mx: 4 }} />
            <CallProcessItemView
              title={t("CallProcessView_Title_Questions")}
              processStatus={questionAnswersProcess?.status || "ON_HOLD"}
              startedAt={questionAnswersProcess?.startedAt}
              errorMessage={
                questionAnswersProcess?.status === "FAILED"
                  ? questionAnswersProcess?.errorMessage ||
                    t("CallProcessView_Error_QuestionAnswersOverdue")
                  : undefined
              }
              caption={t(
                TRANSLATION_RECORD.QUESTION_ANSWERS[
                  questionAnswersProcess?.status || "ON_HOLD"
                ],
              )}
              button={
                hasTranscripts && hasQuestions && !isLoading ? (
                  <Stack spacing={2} direction="row" alignItems="center">
                    {hasOutdatedAnswers && hasQuestionAnswers && !isLoading && (
                      <CallQuestionAnswersOutdatedButton project={project} />
                    )}
                    <Button
                      startIcon={<RefreshIcon fontSize="small" />}
                      size="tiny"
                      variant="outlined"
                      onClick={() => requestProcessCall("QUESTION_ANSWERS")}
                    >
                      {t("CallProcessView_Button_Regenerate")}
                    </Button>
                  </Stack>
                ) : undefined
              }
            />
          </>
        )}
        {!hasQuestionsOrQuestionAnswers && (
          <>
            <Divider />
            <CallProcessItemView
              Icon={NotInterestedIcon}
              color="text.500"
              backgroundColor="background.default"
              title={t("CallProcessView_Title_Questions")}
              processStatus="ON_HOLD"
              caption={t("CallProcessView_Caption_NoQuestionsAdded")}
              button={
                <Button
                  size="tiny"
                  endIcon={<ChevronRightIcon />}
                  href={`/projects/${project.id}/settings/questions`}
                >
                  {t("CallProcessView_Button_GoToProjectQuestions")}
                </Button>
              }
            />
          </>
        )}
        {hasFlaggingConfigs && (
          <>
            <Divider sx={{ mx: 4 }} />
            <CallProcessItemView
              title={t("CallProcessView_Title_AutoFlaggingOfIssues")}
              processStatus={flaggingProcessStatus}
              caption={t(TRANSLATION_RECORD.FLAGGING[flaggingProcessStatus])}
              button={
                hasQuestionAnswers && !isLoading ? (
                  <Button
                    startIcon={<RefreshIcon fontSize="small" />}
                    size="tiny"
                    variant="outlined"
                    onClick={() => requestProcessCall("FLAGGING")}
                  >
                    {t("CallProcessView_Button_Regenerate")}
                  </Button>
                ) : undefined
              }
            />
          </>
        )}
        {!hasFlaggingConfigs && (
          <>
            <Divider />
            <CallProcessItemView
              Icon={NotInterestedIcon}
              color="text.500"
              backgroundColor="background.default"
              title={t("CallProcessView_Title_AutoFlaggingOfIssues")}
              processStatus="ON_HOLD"
              caption={t("CallProcessView_Caption_NoFlaggingRulesDefined")}
              button={
                <Button
                  endIcon={<ChevronRightIcon />}
                  size="tiny"
                  href={`/projects/${project.id}/settings/flagging-rules`}
                >
                  {t("CallProcessView_Button_GoToFlaggingRules")}
                </Button>
              }
            />
          </>
        )}
      </CollapseExpandView>
    </Stack>
  );
};

const TRANSLATION_RECORD: Record<
  CallProcessRequestKind,
  Record<ProcessStatus, TranslationKey>
> = {
  VOICEFILE: {
    SUCCEEDED: "CallProcessView_Caption_Voicefile_Succeeded",
    FAILED: "CallProcessView_Caption_Voicefile_Failed",
    PROCESSING: "CallProcessView_Caption_Voicefile_Processing",
    REQUESTED: "CallProcessView_Caption_Voicefile_Requested",
    ON_HOLD: "CallProcessView_Caption_Voicefile_OnHold",
  },
  TRANSCRIPTS: {
    SUCCEEDED: "CallProcessView_Caption_Transcripts_Succeeded",
    FAILED: "CallProcessView_Caption_Transcripts_Failed",
    PROCESSING: "CallProcessView_Caption_Transcripts_Processing",
    REQUESTED: "CallProcessView_Caption_Transcripts_Requested",
    ON_HOLD: "CallProcessView_Caption_Transcripts_OnHold",
  },
  SUMMARY: {
    SUCCEEDED: "CallProcessView_Caption_Summary_Succeeded",
    FAILED: "CallProcessView_Caption_Summary_Failed",
    PROCESSING: "CallProcessView_Caption_Summary_Processing",
    REQUESTED: "CallProcessView_Caption_Summary_Requested",
    ON_HOLD: "CallProcessView_Caption_Summary_OnHold",
  },
  CATEGORY: {
    SUCCEEDED: "CallProcessView_Caption_Category_Succeeded",
    FAILED: "CallProcessView_Caption_Category_Failed",
    PROCESSING: "CallProcessView_Caption_Category_Processing",
    REQUESTED: "CallProcessView_Caption_Category_Requested",
    ON_HOLD: "CallProcessView_Caption_Category_OnHold",
  },
  QUESTION_ANSWERS: {
    SUCCEEDED: "CallProcessView_Caption_QuestionAnswers_Succeeded",
    FAILED: "CallProcessView_Caption_QuestionAnswers_Failed",
    PROCESSING: "CallProcessView_Caption_QuestionAnswers_Processing",
    REQUESTED: "CallProcessView_Caption_QuestionAnswers_Requested",
    ON_HOLD: "CallProcessView_Caption_QuestionAnswers_OnHold",
  },
  FLAGGING: {
    SUCCEEDED: "CallProcessView_Caption_Flagging_Succeeded",
    FAILED: "CallProcessView_Caption_Flagging_Failed",
    PROCESSING: "CallProcessView_Caption_Flagging_Processing",
    REQUESTED: "CallProcessView_Caption_Flagging_Requested",
    ON_HOLD: "CallProcessView_Caption_Flagging_OnHold",
  },
};
