import { SidebarView } from "../../Common/views/SidebarView";
import { WizardStep } from "../types/WizardStep";
import { WizardStore } from "../types/WizardStore";
import { WizardSidebarAccordionButton } from "./WizardSidebarAccordionButton";

interface Props<TState> {
  steps: WizardStep<TState>[];
  store: WizardStore<TState>;
}

export function WizardSidebarView<TState>(props: Props<TState>) {
  const { steps, store } = props;

  return (
    <SidebarView>
      {steps.map((step, index) => (
        <WizardSidebarAccordionButton
          key={step.pathSegment}
          step={step}
          store={store}
          index={index}
        />
      ))}
    </SidebarView>
  );
}
