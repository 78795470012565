import { Stack } from "@mui/material";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { useProjectContext } from "../hooks/useProjectContext";
import { ProjectCallFlaggingConfigsForm } from "./ProjectCallFlaggingConfigsForm";

export const ProjectCallFlaggingConfigsPage = () => {
  const { project } = useProjectContext();

  if (!project) return null;

  return (
    <FullHeightContainer maxWidth="md">
      <Stack py={5} spacing={3}>
        <ProjectCallFlaggingConfigsForm project={project} />
      </Stack>
    </FullHeightContainer>
  );
};
