import { TENDER_APPLICATION_DESCRIPTION_SEPARATOR } from "../consts/TENDER_APPLICATION_DESCRIPTION_SEPARATOR";

export function mergeTenderApplicationDescription(
  description: string,
  priceOffer: string,
) {
  return [description, priceOffer].join(
    TENDER_APPLICATION_DESCRIPTION_SEPARATOR,
  );
}
