import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface Step {
  text: TranslationKey;
  isChecked: boolean;
}

interface Props {
  titleText: TranslationKey;
  subtitleText: TranslationKey;
  steps: Step[];
}

export const HomeWelcomeCardView: FC<Props> = (props) => {
  const { titleText, subtitleText, steps } = props;
  const t = useT();
  return (
    <>
      <Stack spacing={3}>
        <Typography variant="h5" color="text.secondary">
          {t(titleText)}
        </Typography>
        <Typography variant="h2">{t(subtitleText)}</Typography>
      </Stack>
      <Stack mt={5} spacing={3}>
        {steps.map(({ isChecked, text }) => (
          <Stack key={text} direction="row" spacing={3} alignItems="center">
            {isChecked ? (
              <CheckCircleIcon
                sx={{ color: isChecked ? "primary.main" : "text.500" }}
              />
            ) : (
              <CheckCircleOutlineIcon sx={{ color: "text.500" }} />
            )}
            <Typography variant="h5" color="text.secondary">
              {t(text)}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </>
  );
};
