import { Paper, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { useT } from "../../Translation/hooks/useT";
import { ConnectorConfigStatusLogsCollectionTableView } from "./ConnectorConfigStatusLogsCollectionTableView";

export const ConnectorConfigStatusLogsPage = () => {
  const t = useT();
  const { organizationId, connectorConfigId } = useParams<
    "organizationId" | "connectorConfigId"
  >();
  if (!organizationId || !connectorConfigId) return null;
  return (
    <FullHeightContainer maxWidth="lg">
      <Stack py={5} spacing={4}>
        <Typography variant="h2">
          {t("ConnectorConfigStatusLogsPage_Heading_ConnectionLog")}
        </Typography>
        <Paper>
          <ConnectorConfigStatusLogsCollectionTableView
            organizationId={organizationId}
            connectorConfigId={connectorConfigId}
          />
        </Paper>
      </Stack>
    </FullHeightContainer>
  );
};
