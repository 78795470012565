import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Container, Stack, Typography } from "@mui/material";
import { SystemStruct } from "@snubes/snubes-types";
import { FC } from "react";
import { nullable, string, type } from "superstruct";
import { ControlledRadioButtons } from "../../Form/views/ControlledRadioButtons";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  system: nullable(SystemStruct),
  systemDescription: string(),
});

export const TenderWizardSystemForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit, watch } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => ({
      system: tender?.system || null,
      systemDescription: tender?.systemDescription || "",
    }),
    toDoc: (formValues, tender) => ({
      system: formValues.system || undefined,
      systemDescription:
        (formValues.system === "CLIENT_SYSTEM" &&
          formValues.systemDescription) ||
        undefined,
      isReviewed: {
        ...tender?.isReviewed,
        operationalDetails: false,
      },
    }),
  });

  const system = watch("system");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardSystemForm_Title")}
        </Typography>
        <Typography variant="body2" color="text.500" mb={4}>
          {t("TenderWizardSystemForm_Label")}
        </Typography>
        <Stack spacing={3}>
          <ControlledRadioButtons
            control={control}
            name="system"
            options={[
              {
                Icon: CheckCircleIcon,
                label: "TenderWizardSystemForm_Label_ClientSystem",
                value: "CLIENT_SYSTEM",
                caption: "TenderWizardSystemForm_Caption_ClientSystem",
              },
              {
                Icon: CancelIcon,
                label: "TenderWizardSystemForm_Label_ProviderSystem",
                value: "PROVIDER_SYSTEM",
                caption: "TenderWizardSystemForm_Caption_ProviderSystem",
              },
            ]}
            gridSpacing={4}
            gridItemXs={6}
          />

          {system === "CLIENT_SYSTEM" && (
            <ControlledTextField
              control={control}
              name="systemDescription"
              label={t("TenderWizardSystemForm_Label_SystemDescription")}
              required
              fullWidth
              multiline
              autoFocus
              minRows={3}
            />
          )}
        </Stack>
      </Container>
    </form>
  );
};
