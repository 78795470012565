import {
  CallFlaggingConfigParameterOperator,
  CallFlaggingConfigParameterType,
} from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";
import { CallFlaggingConfigParameterAvailableValue } from "../types/CallFlaggingConfigParameterAvailableValue";
import { CallFlaggingConfigParameterValueFieldType } from "../types/CallFlaggingConfigParameterValueFieldType";

interface CallFlaggingConfigParameterTypeRecordValue {
  label: TranslationKey;
  availableOperators: CallFlaggingConfigParameterOperator[];
  availableValues?: CallFlaggingConfigParameterAvailableValue[];
  valueFieldType: CallFlaggingConfigParameterValueFieldType;
  unitLabel?: TranslationKey;
}

export const CALL_FLAGGING_CONFIG_PARAMETER_TYPE_RECORD: Record<
  CallFlaggingConfigParameterType,
  CallFlaggingConfigParameterTypeRecordValue
> = {
  DURATION_IN_SECONDS: {
    label: "CallFlagging_Parameter_DurationInSeconds",
    availableOperators: ["EQUALS", "NOT_EQUALS", "GREATER_THAN", "LESS_THAN"],
    valueFieldType: "number",
    unitLabel: "CallFlaggingConfigParameterTypeRecord_Unit_Seconds",
  },
  DIRECTION: {
    label: "CallFlagging_Parameter_Direction",
    availableOperators: ["EQUALS", "NOT_EQUALS"],
    availableValues: [
      {
        value: "INBOUND",
        label: "CallFlaggingConfigParameterTypeRecord_Value_Inbound",
      },
      {
        value: "OUTBOUND",
        label: "CallFlaggingConfigParameterTypeRecord_Value_Outbound",
      },
    ],
    valueFieldType: "select",
  },
  TRANSCRIPT: {
    label: "CallFlagging_Parameter_Transcript",
    availableOperators: ["EQUALS", "NOT_EQUALS", "CONTAINS"],
    valueFieldType: "text",
  },
  WAITING_TIME_IN_SECONDS: {
    label: "CallFlagging_Parameter_WaitingTimeInSeconds",
    availableOperators: ["EQUALS", "NOT_EQUALS", "GREATER_THAN", "LESS_THAN"],
    valueFieldType: "number",
    unitLabel: "CallFlaggingConfigParameterTypeRecord_Unit_Seconds",
  },
  HOLDING_TIME_IN_SECONDS: {
    label: "CallFlagging_Parameter_HoldingTimeInSeconds",
    availableOperators: ["EQUALS", "NOT_EQUALS", "GREATER_THAN", "LESS_THAN"],
    valueFieldType: "number",
    unitLabel: "CallFlaggingConfigParameterTypeRecord_Unit_Seconds",
  },
  PRE_CALL_WORK_TIME_IN_SECONDS: {
    label: "CallFlagging_Parameter_PreCallWorkTimeInSeconds",
    availableOperators: ["EQUALS", "NOT_EQUALS", "GREATER_THAN", "LESS_THAN"],
    valueFieldType: "number",
    unitLabel: "CallFlaggingConfigParameterTypeRecord_Unit_Seconds",
  },
  POST_CALL_WORK_TIME_IN_SECONDS: {
    label: "CallFlagging_Parameter_PostCallWorkTimeInSeconds",
    availableOperators: ["EQUALS", "NOT_EQUALS", "GREATER_THAN", "LESS_THAN"],
    valueFieldType: "number",
    unitLabel: "CallFlaggingConfigParameterTypeRecord_Unit_Seconds",
  },
  TOTAL_TIME_IN_SECONDS: {
    label: "CallFlagging_Parameter_TotalTimeInSeconds",
    availableOperators: ["EQUALS", "NOT_EQUALS", "GREATER_THAN", "LESS_THAN"],
    valueFieldType: "number",
    unitLabel: "CallFlaggingConfigParameterTypeRecord_Unit_Seconds",
  },
  // we only support checking for "exists == true" because the voice file is added asynchronously
  VOICE_FILE: {
    label: "CallFlagging_Parameter_Voicefile",
    availableOperators: ["EXISTS"],
    valueFieldType: "none",
  },
  QUESTION_ANSWER: {
    label: "CallFlagging_Parameter_QuestionAnswer",
    availableOperators: [
      "EQUALS",
      "NOT_EQUALS",
      "GREATER_THAN",
      "LESS_THAN",
      "CONTAINS",
    ],
    valueFieldType: "dynamic",
  },
  CATEGORY: {
    label: "CallFlagging_Parameter_Category",
    availableOperators: ["EQUALS", "NOT_EQUALS"],
    valueFieldType: "select",
  },
};
