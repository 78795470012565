import { superstructResolver } from "@hookform/resolvers/superstruct";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import {
  Button,
  Chip,
  Divider,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CALL_FLAGGING_CONDITIONS,
  CallFlaggingConfig,
  CallFlaggingConfigParameter,
  CallFlaggingConfigParameterStruct,
  Project,
} from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC, Fragment, useCallback, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { v4 } from "uuid";
import { handleError } from "../../Common/helpers/handleError";
import { CollapseExpandView } from "../../Common/views/CollapseExpandView";
import { StatusChip } from "../../Common/views/StatusChip";
import { ControlledIssueCategoryIdAutocomplete } from "../../Form/views/ControlledIssueCategoryIdAutocomplete";
import { ControlledMemberIdAutocomplete } from "../../Form/views/ControlledMemberIdAutocomplete";
import { ControlledSelect } from "../../Form/views/ControlledSelect";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { updateProjectCallFlaggingConfigsCallable } from "../callables/updateProjectCallFlaggingConfigsCallable";
import { PROJECT_CALL_FLAGGING_CONDITION_RECORD } from "../consts/PROJECT_CALL_FLAGGING_CONDITION_RECORD";
import {
  ProjectCallFlaggingConfigsFormValues,
  ProjectCallFlaggingConfigsFormValuesParameter,
  ProjectCallFlaggingConfigsFormValuesStruct,
} from "../types/ProjectCallFlaggingConfigsFormValues";
import { ControlledCallFlaggingConfigParameter } from "./ControlledCallFlaggingConfigParameter";
import { ProjectCallFlaggingConfigsFormConfirmationDialog } from "./ProjectCallFlaggingConfigsFormConfirmationDialog";
import { ProjectCallFlaggingConfigsFormHeaderView } from "./ProjectCallFlaggingConfigsFormHeaderView";

const CONDITION_OPTIONS = CALL_FLAGGING_CONDITIONS.map((condition) => ({
  label: PROJECT_CALL_FLAGGING_CONDITION_RECORD[condition].label,
  value: condition,
}));

interface Props {
  project: Project;
}

export const ProjectCallFlaggingConfigsForm: FC<Props> = (props) => {
  const { project } = props;
  const { issueCategories } = project;
  const t = useT();

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    resetField,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = useForm<ProjectCallFlaggingConfigsFormValues>({
    resolver: superstructResolver(ProjectCallFlaggingConfigsFormValuesStruct),
    defaultValues: {
      configs: project.callFlaggingConfigs?.length
        ? project.callFlaggingConfigs
        : [
            {
              id: v4(),
              condition: "ALL",
              reviewerUserId: null,
              issueCategoryId: null,
              parameters: [],
            } satisfies CallFlaggingConfig,
          ],
      isAppliedToHistoricData: false,
      checkExistingCallsFromDate: DateTime.utc()
        .startOf("day")
        .minus({ days: 7 })
        .toISO(),
    },
  });

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: "configs",
  });

  // listening to changes of setValue to render parameters correctly
  // while keeping the possibility to add/remove configs with useFieldArray
  const watchConfigs = watch("configs");
  const configs = fields.map((field, index) => ({
    ...field,
    ...watchConfigs[index],
  }));

  // This will be passed to the confirmation dialog
  const isAppliedToHistoricData = watch("isAppliedToHistoricData");

  // This will be passed to the confirmation dialog
  const isCallsUpdateInProgress =
    !isAppliedToHistoricData &&
    (props.project.flaggingConfigsCallsProcessingCount || 0) > 0;

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  // To regular users we show an assignee list based on their selected organization which must be either
  // the provider or client organization.
  // To admins we show an assignee list based on both the provider and client organization.
  const selectedOrganizationId = useSelectedOrganization(
    (state) => state?.id || null,
  );
  const assigneeOrganizationId = useMemo<string | string[]>(() => {
    if (
      selectedOrganizationId === project.providerOrganizationId ||
      selectedOrganizationId === project.clientOrganizationId
    ) {
      return selectedOrganizationId;
    }
    return [
      project.providerOrganizationId,
      ...(project.clientOrganizationId ? [project.clientOrganizationId] : []),
    ];
  }, [
    project.clientOrganizationId,
    project.providerOrganizationId,
    selectedOrganizationId,
  ]);

  const addConfig = useCallback(() => {
    append({
      id: v4(),
      condition: "ALL",
      reviewerUserId: null,
      issueCategoryId: null,
      parameters: [],
    });
  }, [append]);

  const addParameter = useCallback(
    (configIndex: number) => {
      const parameters = getValues(`configs.${configIndex}.parameters`);
      const newParameter: ProjectCallFlaggingConfigsFormValuesParameter = {
        id: v4(),
        type: "",
        operator: "",
        value: "",
      };

      setValue(
        `configs.${configIndex}.parameters`,
        [...parameters, newParameter],
        {
          shouldDirty: true,
        },
      );
    },
    [getValues, setValue],
  );

  const removeParameter = useCallback(
    (configIndex: number, parameterIndex: number) => {
      const parameters = getValues(`configs.${configIndex}.parameters`).filter(
        (_, index) => index !== parameterIndex,
      );

      setValue(`configs.${configIndex}.parameters`, parameters, {
        shouldDirty: true,
      });
    },
    [getValues, setValue],
  );

  const onSetProjectCallFlaggingConfigsFormValues = useCallback(
    (newCallFlaggingConfigs: ProjectCallFlaggingConfigsFormValues) => {
      setValue("configs", newCallFlaggingConfigs.configs);
    },
    [setValue],
  );

  const onClickedCancelConfirmation = () => setIsConfirmationDialogOpen(false);

  const onClickSave = () => setIsConfirmationDialogOpen(true);

  const onExitedConfirmationDialog = useCallback(() => {
    resetField("isAppliedToHistoricData");
    resetField("checkExistingCallsFromDate");
  }, [resetField]);

  const onSubmit = async (formValues: ProjectCallFlaggingConfigsFormValues) => {
    try {
      const callFlaggingConfigs = formValues.configs.reduce<
        CallFlaggingConfig[]
      >((result, config) => {
        if (!config.parameters.length) return result;

        result.push({
          id: config.id,
          condition: config.condition,
          reviewerUserId: config.reviewerUserId,
          issueCategoryId: config.issueCategoryId,
          parameters: config.parameters.reduce<CallFlaggingConfigParameter[]>(
            (res, parameter) => {
              if (CallFlaggingConfigParameterStruct.is(parameter)) {
                res.push(parameter);
              }

              return res;
            },
            [],
          ),
        });

        return result;
      }, []);

      await updateProjectCallFlaggingConfigsCallable({
        projectId: props.project.id,
        project: {
          callFlaggingConfigs: callFlaggingConfigs.length
            ? callFlaggingConfigs
            : null,
        },
        ...(formValues.isAppliedToHistoricData
          ? {
              checkExistingCallsFromDate: formValues.checkExistingCallsFromDate,
            }
          : {}),
      });

      setIsConfirmationDialogOpen(false);
      reset(formValues);
      toast.success(t("ProjectCallFlaggingConfigsForm_Toast_Success"));
    } catch (error) {
      handleError(error).logAnd().toast();
    }
  };

  return (
    <Stack
      spacing={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      data-testid="project-call-flagging-configs-form"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <Typography variant="h3">
          {t("ProjectCallFlaggingConfigsForm_Heading_CallFlaggingConfig")}
        </Typography>

        <Stack direction="row" spacing={1} justifyContent="space-between">
          <ProjectCallFlaggingConfigsFormHeaderView
            project={project}
            isFormDirty={isDirty || !!dirtyFields.configs}
            onSetProjectCallFlaggingConfigs={
              onSetProjectCallFlaggingConfigsFormValues
            }
          />
          <Button
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            onClick={onClickSave}
          >
            {t("Common_Save")}
          </Button>
        </Stack>
      </Stack>
      {configs.map((config, configIndex) => (
        <Stack key={config.id} component={Paper} variant="outlined">
          <CollapseExpandView
            defaultExpanded
            headerContent={
              <>
                <Typography variant="h5">
                  {t("ProjectCallFlaggingConfigsForm_Heading_Rule", {
                    value: configIndex + 1,
                  })}
                </Typography>
                <Stack flex={1} />
                <IconButton
                  color="inherit"
                  disabled={isSubmitting || configIndex === 0}
                  onClick={() => swap(configIndex, configIndex - 1)}
                >
                  <Tooltip
                    title={t(
                      "ProjectCallFlaggingConfigsForm_TooltipTitle_MoveGroupUp",
                    )}
                  >
                    <ExpandLessIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  color="inherit"
                  disabled={isSubmitting || configIndex === configs.length - 1}
                  onClick={() => swap(configIndex, configIndex + 1)}
                >
                  <Tooltip
                    title={t(
                      "ProjectCallFlaggingConfigsForm_TooltipTitle_MoveGroupDown",
                    )}
                  >
                    <ExpandMoreIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  color="error"
                  disabled={isSubmitting}
                  onClick={() => remove(configIndex)}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </>
            }
          >
            <Stack p={3} spacing={4}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography>
                  {t("ProjectCallFlaggingConfigsForm_Text_If")}
                </Typography>
                <ControlledSelect
                  control={control}
                  name={`configs.${configIndex}.condition`}
                  label={t("ProjectCallFlaggingConfigsForm_Label_Condition")}
                  items={CONDITION_OPTIONS}
                  renderItem={(item) => t(item.label)}
                  sx={{ minWidth: 120 }}
                  disabled={isSubmitting}
                  size="small"
                  required
                />
                <Typography>
                  {t("ProjectCallFlaggingConfigsForm_Text_MetConditions")}
                </Typography>
              </Stack>
              <Divider />
              {config.parameters.map((parameter, parameterIndex) => (
                <Fragment key={parameter.id}>
                  <ControlledCallFlaggingConfigParameter
                    control={control}
                    name={`configs.${configIndex}.parameters.${parameterIndex}`}
                    remove={() => removeParameter(configIndex, parameterIndex)}
                    disabled={isSubmitting}
                    project={project}
                  />
                  {parameterIndex !== config.parameters.length - 1 && (
                    <Stack direction="row">
                      <Chip
                        size="small"
                        sx={{
                          color: "primary.main",
                          backgroundColor: "background.default",
                          border: 1,
                          borderColor: "text.disabled",
                          textTransform: "uppercase",
                          fontWeight: 800,
                        }}
                        label={t(
                          PROJECT_CALL_FLAGGING_CONDITION_RECORD[
                            config.condition
                          ].operatorLabel,
                        )}
                      />
                    </Stack>
                  )}
                </Fragment>
              ))}
              <Stack direction="row">
                <Button
                  variant="text"
                  disabled={isSubmitting}
                  onClick={() => addParameter(configIndex)}
                  startIcon={<AddCircleIcon />}
                >
                  {t("ProjectCallFlaggingConfigsForm_Button_NewCondition")}
                </Button>
              </Stack>
              <Divider />
              <Stack spacing={3}>
                <Typography variant="h4">
                  {t("ProjectCallFlaggingConfigsForm_Heading_Assignee")}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {t("ProjectCallFlaggingConfigsForm_Text_ReviewAssignedTo")}
                </Typography>
                <ControlledMemberIdAutocomplete
                  control={control}
                  name={`configs.${configIndex}.reviewerUserId`}
                  label={t("ProjectCallFlaggingConfigsForm_Label_AssignTo")}
                  organizationId={assigneeOrganizationId}
                  disabled={isSubmitting}
                />
              </Stack>
              <Divider />
              <Stack spacing={3}>
                <Typography variant="h4">
                  {t("ProjectCallFlaggingConfigsForm_Heading_IssueCategory")}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {t("ProjectCallFlaggingConfigsForm_Text_IssueCategory")}
                </Typography>
                {!!issueCategories?.length && (
                  <ControlledIssueCategoryIdAutocomplete
                    control={control}
                    name={`configs.${configIndex}.issueCategoryId`}
                    label={t(
                      "ProjectCallFlaggingConfigsForm_Label_IssueCategory",
                    )}
                    issueCategories={issueCategories}
                    disabled={isSubmitting}
                  />
                )}
                {!issueCategories?.length && (
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <StatusChip
                      color="warning"
                      Icon={HelpIcon}
                      label={t(
                        "ProjectCallFlaggingConfigsForm_Chip_NoIssueCategories",
                      )}
                    />
                    <Button
                      size="tiny"
                      endIcon={<ChevronRightIcon />}
                      href={`/projects/${project.id}/settings/issue-categories`}
                    >
                      {t(
                        "ProjectCallFlaggingConfigsForm_Button_GoToProjectIssueCategories",
                      )}
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </CollapseExpandView>
        </Stack>
      ))}
      <Stack direction="row">
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={addConfig}
          disabled={isSubmitting}
        >
          {t("ProjectCallFlaggingConfigsForm_Button_AddNewRule")}
        </Button>
      </Stack>

      <ProjectCallFlaggingConfigsFormConfirmationDialog
        close={onClickedCancelConfirmation}
        isOpen={isConfirmationDialogOpen}
        isCallsUpdateInProgress={isCallsUpdateInProgress}
        control={control}
        isAppliedToHistoricData={isAppliedToHistoricData}
        isDisabled={isSubmitting}
        onExited={onExitedConfirmationDialog}
      />
    </Stack>
  );
};
