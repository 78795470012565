import { Box, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useT } from "../../Translation/hooks/useT";

export const App404Page: FC = () => {
  const t = useT();

  return (
    <>
      <HeaderConfig variant={"WITH_NAVIGATION"} />
      <FullHeightContainer>
        <Stack height="100%" alignItems="center" justifyContent="center">
          <Typography variant="h1">404</Typography>
          <Typography>{t("App404Page_Title_PageNotFound")}</Typography>

          <Box mt={5}>
            <Link to="/">{t("App404Page_Title_GoHome")}</Link>
          </Box>
        </Stack>
      </FullHeightContainer>
    </>
  );
};
