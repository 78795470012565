import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { ProviderOperationCenter } from "@snubes/snubes-types";
import { FC } from "react";
import { getCountryName } from "../../Common/helpers/getCountryName";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  operationCenters: ProviderOperationCenter[];
}

export const ProviderWizardOperationCentersView: FC<Props> = (props) => {
  const t = useT();

  return (
    <List disablePadding>
      {props.operationCenters.map((value) => (
        <ListItem
          disablePadding
          key={[
            value.location?.city,
            value.location?.country,
            value.employees,
            value.startingYear,
          ].join("")}
        >
          {value.location?.country && (
            <ListItemText disableTypography>
              <Typography variant="body2">
                {value.location.city}, {getCountryName(value.location.country)}{" "}
                (
                {t(
                  "ProviderWizardOperationCentersForm_Label_NumberOfEmployees",
                )}
                : {value.employees},{" "}
                {t("ProviderWizardOperationCentersForm_Label_StartingYear")}:{" "}
                {value.startingYear})
              </Typography>
            </ListItemText>
          )}
        </ListItem>
      ))}
    </List>
  );
};
