import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { deleteNotificationAllCallable } from "../callables/deleteNotificationAllCallable";

interface BaseProps {
  isDisabled?: boolean;
  isOpen: boolean;
  onClose: () => void;
}

interface PropsWithOrganizationId extends BaseProps {
  organizationId: string;
}

interface PropsWithUserId extends BaseProps {
  userId: string;
}

type Props = PropsWithOrganizationId | PropsWithUserId;

export const NotificationsDeleteAllDialog: FC<Props> = (props) => {
  const t = useT();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onDeleteNotificationAllCallable = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await deleteNotificationAllCallable(
        "organizationId" in props
          ? {
              organizationId: props.organizationId,
            }
          : {
              userId: props.userId,
            },
      );
      props.onClose();
      toast.success(t("NotificationsDeleteAllDialog_Toast_DeletedAll"));
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsSubmitting(false);
    }
  }, [props, t]);

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogTitle>
        {t("NotificationsDeleteAllDialog_Title_Confirmation")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("NotificationsDeleteAllDialog_Text_Confirmation")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={props.onClose}>
          {t("NotificationsDeleteAllDialog_Button_Cancel")}
        </Button>
        <Button
          color="error"
          variant="contained"
          size="small"
          disabled={isSubmitting || props.isDisabled}
          startIcon={<DeleteIcon />}
          onClick={onDeleteNotificationAllCallable}
        >
          {t("NotificationsDeleteAllDialog_Button_DeleteAll")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
