import { Box } from "@mui/material";
import { StorageFilePath } from "@snubes/snubes-types";
import Image from "next/image";
import { FC } from "react";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { getStorageRef } from "../../Firebase/helpers/getStorageRef";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";

export const HeaderOrganizationLogoView: FC = () => {
  const showOrganizationLogo = useSelectedOrganization(
    (organization) => organization?.layout?.useLogo,
  );
  const logoFilePath = useSelectedOrganization(
    (organization) => organization?.layout?.logo?.filePath as StorageFilePath,
  );

  const [downloadUrl = "", isLoading, error] = useDownloadURL(
    logoFilePath && getStorageRef(logoFilePath),
  );

  if (!showOrganizationLogo || !downloadUrl || isLoading || error) {
    return (
      <Image src="/assets/logo.svg" alt="Snubes Logo" width={120} height={30} />
    );
  }

  return (
    <Box width={120} height={30} position="relative">
      <Image
        src={downloadUrl}
        alt="Organization Logo"
        fill
        style={{ objectFit: "contain" }}
      />
    </Box>
  );
};
