import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button } from "@mui/material";
import { Organization } from "@snubes/snubes-types";
import { FC, useState } from "react";
import { T } from "../../Translation/views/T";
import { InviteDialog } from "./InviteDialog";

interface Props {
  organization: Organization;
  disabled?: boolean;
}

export const InviteButton: FC<Props> = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setIsDialogOpen(true)}
        variant="contained"
        disabled={props.disabled}
        startIcon={<AddCircleIcon />}
      >
        <T k="Invitation_Button_InviteNewMember" />
      </Button>

      {isDialogOpen && (
        <InviteDialog
          organization={props.organization}
          close={() => setIsDialogOpen(false)}
        />
      )}
    </>
  );
};
