import { Box, Container, Typography } from "@mui/material";
import { FC } from "react";
import { nullable, number, type } from "superstruct";
import { ControlledSlider } from "../../Form/views/ControlledSlider";
import { NUMBER_OF_EMPLOYEES_OPTIONS_VALUES } from "../../Organizations/consts/NUMBER_OF_EMPLOYEES_OPTIONS_TYPE_RECORD";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  numberOfEmployees: nullable(number()),
});

export const ProviderWizardNumberOfEmployeesForm: FC<WizardFormProps> = (
  props,
) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      numberOfEmployees:
        NUMBER_OF_EMPLOYEES_OPTIONS_VALUES.find(
          (v) => v.value === provider?.numberOfEmployees,
        )?.index || null,
    }),
    toDoc: (formValues) => ({
      numberOfEmployees: formValues?.numberOfEmployees
        ? NUMBER_OF_EMPLOYEES_OPTIONS_VALUES[formValues.numberOfEmployees].value
        : undefined,
    }),
  });

  const marks = NUMBER_OF_EMPLOYEES_OPTIONS_VALUES.map((option, i, array) => ({
    value: option.index,
    label:
      i < array.length - 1
        ? option.valueLabel || option.value
        : option.tooltipLabel,
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("ProviderWizardNumberOfEmployeesForm_Title")}
        </Typography>
        <Typography variant="body1" color="text.500" mb={4}>
          {t("ProviderWizardNumberOfEmployeesForm_Label")}
        </Typography>
        <Box py={3} px={4}>
          <ControlledSlider
            control={control}
            name="numberOfEmployees"
            min={NUMBER_OF_EMPLOYEES_OPTIONS_VALUES[0].value}
            max={NUMBER_OF_EMPLOYEES_OPTIONS_VALUES.length - 1}
            marks={marks}
            valueLabelDisplay="on"
            valueLabelFormat={(index) =>
              NUMBER_OF_EMPLOYEES_OPTIONS_VALUES[index].tooltipLabel
            }
          />
        </Box>
      </Container>
    </form>
  );
};
