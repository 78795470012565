import { FirebaseFilter, Provider, ProviderStatus } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

export const ADMIN_PROVIDERS_ROUTES = ["all", "draft", "published"] as const;

type Route = (typeof ADMIN_PROVIDERS_ROUTES)[number];

interface RouteRecordValue {
  label: TranslationKey;
  filters: FirebaseFilter<Provider>[];
}

export const ADMIN_PROVIDERS_ROUTE_RECORD: Record<Route, RouteRecordValue> = {
  all: {
    label: "AdminTendersRouteRecord_Label_All",
    filters: [["isDeleted", "==", false]],
  },
  draft: {
    label: "AdminTendersRouteRecord_Label_Draft",
    filters: [
      ["isDeleted", "==", false],
      ["status", "==", "DRAFT" satisfies ProviderStatus],
    ],
  },
  published: {
    label: "AdminTendersRouteRecord_Label_Published",
    filters: [
      ["isDeleted", "==", false],
      ["status", "==", "PUBLISHED" satisfies ProviderStatus],
    ],
  },
};
