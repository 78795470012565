import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Log } from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC, useMemo } from "react";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";
import { deepReplaceLogUpdate } from "../helpers/deepReplaceLogUpdate";

interface Props {
  log?: Log;
  close: () => void;
}

export const LogDialog: FC<Props> = (props) => {
  const t = useT();
  const logJsonString = useMemo(
    () => JSON.stringify(deepReplaceLogUpdate(props.log?.update), null, 4),
    [props.log],
  );

  if (!props.log) return null;

  return (
    <Dialog onClose={props.close} open fullWidth>
      <DialogTitle
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack>Log</Stack>
        <Typography>
          {DateTime.fromMillis(props.log.createdAt.toMillis()).toLocaleString(
            DateTime.DATETIME_FULL_WITH_SECONDS,
          )}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack spacing={2} direction="row">
          <StatusChip
            label={`${props.log.updatedBy.type}: ${props.log.updatedBy.name}`}
          />
          <StatusChip label={`ID: ${props.log.updatedBy.id}`} />
        </Stack>
        <pre>{logJsonString}</pre>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={props.close}>
          {t("Common_Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
