import { ComponentProps, FC, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { toLocaleMinutes } from "../../Common/helpers/toLocaleMinutes";
import { toLocaleWeekdayPeriod } from "../../Common/helpers/toLocaleWeekdayPeriod";
import { FileListView } from "../../Common/views/FileListView";
import { ORGANIZATION_INDUSTRY_RECORD } from "../../Organizations/consts/ORGANIZATION_INDUSTRY_RECORD";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";
import { updateActiveWizardSteps } from "../../Wizard/helpers/updateActiveWizardSteps";
import { updateNextAndPreviousWizardSteps } from "../../Wizard/helpers/updateNextAndPreviousWizardSteps";
import { updateWizardStepPaths } from "../../Wizard/helpers/updateWizardStepPaths";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { WizardStep } from "../../Wizard/types/WizardStep";
import { TENDER_CHANNEL_TYPE } from "../consts/TENDER_CHANNEL_TYPE";
import { TENDER_PRICE_MODEL } from "../consts/TENDER_PRICE_MODEL";
import { TENDER_SERVICE_CATEGORIES_BY_SERVICE_TYPE } from "../consts/TENDER_SERVICE_CATEGORIES";
import { TENDER_TARGET_GROUP } from "../consts/TENDER_TARGET_GROUP";
import { TENDER_TEAM_MODEL } from "../consts/TENDER_TEAM_MODEL";
import { findTenderServiceChannel } from "../helpers/findTenderServiceChannel";
import { TenderReviewKey } from "../types/TenderReviewKey";
import { TenderWizardState } from "../types/TenderWizardState";
import { TenderWizardStep } from "../types/TenderWizardStep";
import { TenderAgentTrainingView } from "../views/TenderAgentTrainingView";
import { TenderCustomOperatingHoursView } from "../views/TenderCustomOperatingHoursView";
import { TenderProvidersView } from "../views/TenderProvidersView";
import { TenderWizardAgentTrainingForm } from "../views/TenderWizardAgentTrainingForm";
import { TenderWizardBudgetForm } from "../views/TenderWizardBudgetForm";
import { TenderWizardChannelAhtForm } from "../views/TenderWizardChannelAhtForm";
import { TenderWizardChannelLanguagesForm } from "../views/TenderWizardChannelLanguagesForm";
import { TenderWizardChannelOperatingHoursForm } from "../views/TenderWizardChannelOperatingHoursForm";
import { TenderWizardChannelPriceModelsForm } from "../views/TenderWizardChannelPriceModelsForm";
import { TenderWizardChannelsForm } from "../views/TenderWizardChannelsForm";
import { TenderWizardChannelTargetGroupsForm } from "../views/TenderWizardChannelTargetGroupsForm";
import { TenderWizardChannelTeamModelForm } from "../views/TenderWizardChannelTeamModelForm";
import { TenderWizardChannelVolumeForm } from "../views/TenderWizardChannelVolumeForm";
import { TenderWizardContactForm } from "../views/TenderWizardContactForm";
import { TenderWizardCountriesForm } from "../views/TenderWizardCountriesForm";
import { TenderWizardDescriptionForm } from "../views/TenderWizardDescriptionForm";
import { TenderWizardIndustriesForm } from "../views/TenderWizardIndustriesForm";
import { TenderWizardLanguagesForm } from "../views/TenderWizardLanguagesForm";
import { TenderWizardNameForm } from "../views/TenderWizardNameForm";
import { TenderWizardOperatingHoursForm } from "../views/TenderWizardOperatingHoursForm";
import { TenderWizardProvidersForm } from "../views/TenderWizardProvidersForm";
import { TenderWizardReviewForm } from "../views/TenderWizardReviewForm";
import { TenderWizardServicesForm } from "../views/TenderWizardServicesForm";
import { TenderWizardSystemForm } from "../views/TenderWizardSystemForm";
import { TenderWizardTimetableForm } from "../views/TenderWizardTimetableForm";
import { TenderWizardUploadDocumentsForm } from "../views/TenderWizardUploadDocumentsForm";
import { useGetTenderBudgetValue } from "./useGetTenderBudgetValue";
import { useGetTenderOperatingHoursValue } from "./useGetTenderOperatingHoursValue";
import { useTenderWizardStore } from "./useTenderWizardStore";

type WizardStepWithChannelsForm = WizardStep<
  TenderWizardState,
  ComponentProps<typeof TenderWizardChannelsForm>["formProps"]
>;

type WizardStepWithChannelLanguagesForm = WizardStep<
  TenderWizardState,
  ComponentProps<typeof TenderWizardChannelLanguagesForm>["formProps"]
>;

type WizardStepWithChannelOperatingHoursForm = WizardStep<
  TenderWizardState,
  ComponentProps<typeof TenderWizardChannelOperatingHoursForm>["formProps"]
>;

type WizardStepWithChannelTeamModelForm = WizardStep<
  TenderWizardState,
  ComponentProps<typeof TenderWizardChannelTeamModelForm>["formProps"]
>;

type WizardStepWithChannelVolumeForm = WizardStep<
  TenderWizardState,
  ComponentProps<typeof TenderWizardChannelVolumeForm>["formProps"]
>;

type WizardStepWithChannelAhtForm = WizardStep<
  TenderWizardState,
  ComponentProps<typeof TenderWizardChannelAhtForm>["formProps"]
>;

type WizardStepWithChannelPriceModelsForm = WizardStep<
  TenderWizardState,
  ComponentProps<typeof TenderWizardChannelPriceModelsForm>["formProps"]
>;

type WizardStepWithChannelTargetGroupsForm = WizardStep<
  TenderWizardState,
  ComponentProps<typeof TenderWizardChannelTargetGroupsForm>["formProps"]
>;

export function useTenderWizardSteps(): TenderWizardStep[] {
  const t = useT();
  const { pathname } = useLocation();
  const services = useTenderWizardStore((state) => state.doc?.services);
  const getTenderBudgetValue = useGetTenderBudgetValue();
  const getTenderOperatingHoursValue = useGetTenderOperatingHoursValue();
  const hasPermission = useHasPermission();

  return useMemo(() => {
    const steps: TenderWizardStep[] = [
      {
        title: t("useTenderWizardSteps_Title_BasicDetails"),
        pathSegment: "basic-details",
        reviewKey: "basicDetails" satisfies TenderReviewKey,
        steps: [
          {
            title: t("useTenderWizardSteps_Title_Name"),
            pathSegment: "name",
            Form: TenderWizardNameForm,
            valueSelector: (state) => state.doc?.name,
            progressSelector: (state) => (state.doc?.name ? 1 : 0),
          },
          {
            title: t("useTenderWizardSteps_Title_Languages"),
            pathSegment: "languages",
            Form: TenderWizardLanguagesForm,
            valueSelector: (state) => state.doc?.languages?.join(", "),
            progressSelector: (state) => (state.doc?.languages?.length ? 1 : 0),
          },
          {
            title: t("useTenderWizardSteps_Title_OperatingHours"),
            pathSegment: "operating-hours",
            Form: TenderWizardOperatingHoursForm,
            valueSelector: (state) => (
              <TenderCustomOperatingHoursView
                operatingHours={getTenderOperatingHoursValue(state.doc)}
                operatingTimeZone={state.doc?.operatingTimeZone}
              />
            ),
            progressSelector: (state) =>
              state.doc?.isOperatingAroundTheClock === undefined
                ? 0
                : state.doc?.isOperatingAroundTheClock
                ? 1
                : (state.doc?.operatingHours?.length ? 0.5 : 0) +
                  (state.doc?.operatingTimeZone ? 0.5 : 0),
          },
        ],
      },
      {
        title: t("useTenderWizardSteps_Title_Services"),
        pathSegment: "services",
        reviewKey: "services" satisfies TenderReviewKey,
        Form: TenderWizardServicesForm,
        valueSelector: (state) =>
          state.doc?.services
            ?.map((s) => t(`ServiceType_${s.type}`))
            .join(", "),
        steps: services
          ?.map<WizardStepWithChannelsForm>((service) => ({
            title: t(`ServiceType_${service.type}`),
            pathSegment: service.type,
            Form: TenderWizardChannelsForm,
            formProps: {
              serviceType: service.type,
            },
            valueSelector: (state) =>
              state.doc?.services
                ?.find((s) => s.type === service.type)
                ?.channels?.map((c) => t(TENDER_CHANNEL_TYPE[c.type].label))
                .join(", "),
            steps: service.channels?.map<TenderWizardStep>((channel) => ({
              title: t(TENDER_CHANNEL_TYPE[channel.type].label),
              pathSegment: channel.type,
              hasTabs: true,
              tabsLabel: t("useTenderWizardSteps_TabsLabel_ChannelDetailsFor"),
              steps: [
                {
                  title: t("useTenderWizardSteps_Title_ChannelLanguages"),
                  pathSegment: "languages",
                  Form: TenderWizardChannelLanguagesForm,
                  formProps: {
                    serviceType: service.type,
                    channelType: channel.type,
                  },
                  progressSelector: (state) =>
                    findTenderServiceChannel(
                      service.type,
                      channel.type,
                      state.doc,
                    )?.languages?.length
                      ? 1
                      : 0,
                  valueSelector: (state) =>
                    findTenderServiceChannel(
                      service.type,
                      channel.type,
                      state.doc,
                    )?.languages?.join(", "),
                } satisfies WizardStepWithChannelLanguagesForm,
                {
                  title: t("useTenderWizardSteps_Title_ChannelOperatingHours"),
                  pathSegment: "operating-hours",
                  Form: TenderWizardChannelOperatingHoursForm,
                  formProps: {
                    serviceType: service.type,
                    channelType: channel.type,
                  },
                  progressSelector: (state) => {
                    const serviceChannel = findTenderServiceChannel(
                      service.type,
                      channel.type,
                      state.doc,
                    );

                    return serviceChannel?.operatingHoursType &&
                      serviceChannel.operatingHoursType === "CUSTOM"
                      ? (serviceChannel?.operatingHours?.length ? 0.5 : 0) +
                          (serviceChannel?.operatingTimeZone ? 0.5 : 0)
                      : 1;
                  },
                  valueSelector: (state) => {
                    const c = findTenderServiceChannel(
                      service.type,
                      channel.type,
                      state.doc,
                    );

                    switch (c?.operatingHoursType) {
                      case "DEFAULT":
                        return state.doc?.isOperatingAroundTheClock
                          ? t(
                              "useTenderWizardSteps_Value_OperatingHours_AroundTheClock",
                            )
                          : state.doc?.operatingHours
                          ? state.doc.operatingHours
                              .map(toLocaleWeekdayPeriod)
                              .join("\n")
                          : "-";
                      case "AROUND_THE_CLOCK":
                        return t(
                          "useTenderWizardSteps_Value_OperatingHours_AroundTheClock",
                        );
                      case "CUSTOM":
                        return (
                          <TenderCustomOperatingHoursView
                            operatingHours={c?.operatingHours
                              ?.map(toLocaleWeekdayPeriod)
                              .join("\n")}
                            operatingTimeZone={c.operatingTimeZone}
                          />
                        );
                    }
                  },
                } satisfies WizardStepWithChannelOperatingHoursForm,
                {
                  title: t("useTenderWizardSteps_Title_ChannelTeamModel"),
                  pathSegment: "team-model",
                  Form: TenderWizardChannelTeamModelForm,
                  formProps: {
                    serviceType: service.type,
                    channelType: channel.type,
                  },
                  progressSelector: (state) =>
                    findTenderServiceChannel(
                      service.type,
                      channel.type,
                      state.doc,
                    )?.teamModel
                      ? 1
                      : 0,
                  valueSelector: (state) => {
                    const teamModel = findTenderServiceChannel(
                      service.type,
                      channel.type,
                      state.doc,
                    )?.teamModel;

                    if (teamModel) {
                      return t(TENDER_TEAM_MODEL[teamModel].label);
                    }
                  },
                } satisfies WizardStepWithChannelTeamModelForm,
                {
                  title: t("useTenderWizardSteps_Title_ChannelVolume"),
                  pathSegment: "volume",
                  Form: TenderWizardChannelVolumeForm,
                  formProps: {
                    serviceType: service.type,
                    channelType: channel.type,
                  },
                  progressSelector: (state) =>
                    findTenderServiceChannel(
                      service.type,
                      channel.type,
                      state.doc,
                    )?.volume !== undefined
                      ? 1
                      : 0,
                  valueSelector: (state) => {
                    const volume = findTenderServiceChannel(
                      service.type,
                      channel.type,
                      state.doc,
                    )?.volume;

                    if (volume !== undefined) {
                      return `${volume} ${t(
                        TENDER_CHANNEL_TYPE[channel.type].volumeAdornment,
                      )}`;
                    }
                  },
                } satisfies WizardStepWithChannelVolumeForm,
                {
                  title: t("useTenderWizardSteps_Title_ChannelAht"),
                  pathSegment: "avg-handling-time",
                  Form: TenderWizardChannelAhtForm,
                  formProps: {
                    serviceType: service.type,
                    channelType: channel.type,
                  },
                  progressSelector: (state) =>
                    findTenderServiceChannel(
                      service.type,
                      channel.type,
                      state.doc,
                    )?.avgHandlingTimeInSeconds !== undefined
                      ? 1
                      : 0,
                  valueSelector: (state) => {
                    const avgHandlingTimeInSeconds = findTenderServiceChannel(
                      service.type,
                      channel.type,
                      state.doc,
                    )?.avgHandlingTimeInSeconds;

                    if (avgHandlingTimeInSeconds !== undefined) {
                      return t("TenderWizardChannelAhtForm_Slider_Label", {
                        min: toLocaleMinutes(avgHandlingTimeInSeconds * 1000),
                      });
                    }
                  },
                } satisfies WizardStepWithChannelAhtForm,
                {
                  title: t("useTenderWizardSteps_Title_ChannelPriceModels"),
                  pathSegment: "price-models",
                  Form: TenderWizardChannelPriceModelsForm,
                  formProps: {
                    serviceType: service.type,
                    channelType: channel.type,
                  },
                  progressSelector: (state) =>
                    findTenderServiceChannel(
                      service.type,
                      channel.type,
                      state.doc,
                    )?.priceModels?.length
                      ? 1
                      : 0,
                  valueSelector: (state) =>
                    findTenderServiceChannel(
                      service.type,
                      channel.type,
                      state.doc,
                    )
                      ?.priceModels?.map((p) => t(TENDER_PRICE_MODEL[p].label))
                      .join(", "),
                } satisfies WizardStepWithChannelPriceModelsForm,
                ...("OUTBOUND" ===
                TENDER_SERVICE_CATEGORIES_BY_SERVICE_TYPE.get(service.type)
                  ? [
                      {
                        title: t(
                          "useTenderWizardSteps_Title_ChannelTargetGroups",
                        ),
                        pathSegment: "target-groups",
                        Form: TenderWizardChannelTargetGroupsForm,
                        formProps: {
                          serviceType: service.type,
                          channelType: channel.type,
                        },
                        progressSelector: (state) =>
                          findTenderServiceChannel(
                            service.type,
                            channel.type,
                            state.doc,
                          )?.targetGroups?.length
                            ? 1
                            : 0,
                        valueSelector: (state) =>
                          findTenderServiceChannel(
                            service.type,
                            channel.type,
                            state.doc,
                          )
                            ?.targetGroups?.map((tg) =>
                              t(TENDER_TARGET_GROUP[tg].label),
                            )
                            .join(", "),
                      } satisfies WizardStepWithChannelTargetGroupsForm,
                    ]
                  : []),
              ],
            })),
          }))
          .sort((a, b) => a.title.localeCompare(b.title)),
      },
      {
        title: t("useTenderWizardSteps_Title_OperationalDetails"),
        pathSegment: "operational-details",
        reviewKey: "operationalDetails" satisfies TenderReviewKey,
        steps: [
          {
            title: t("useTenderWizardSteps_Title_SelectionCriteria"),
            pathSegment: "selection-criteria",
            steps: [
              {
                title: t("useTenderWizardSteps_Title_Budget"),
                pathSegment: "budget",
                Form: TenderWizardBudgetForm,
                progressSelector: (state) =>
                  state.doc?.budgetType === "FLEXIBLE"
                    ? 1
                    : (state.doc?.budgetType ? 0.25 : 0) +
                      (state.doc?.budgetDurationType ? 0.25 : 0) +
                      (state.doc?.budget ? 0.5 : 0),
                valueSelector: (state) =>
                  state.doc && getTenderBudgetValue(state.doc),
              },
              {
                title: t("useTenderWizardSteps_Title_Countries"),
                pathSegment: "call-center-locations",
                Form: TenderWizardCountriesForm,
                progressSelector: (state) =>
                  state.doc?.countries === undefined ? 0 : 1,
                valueSelector: (state) =>
                  state.doc?.countries?.length === 0
                    ? t("TenderWizardCountriesForm_Label_AllLocations")
                    : state.doc?.countries?.join(", "),
              },
            ],
          },
          {
            title: t("useTenderWizardSteps_Title_SkillsAndTechnologies"),
            pathSegment: "skills-and-technologies",
            steps: [
              {
                title: t("useTenderWizardSteps_Title_Industries"),
                pathSegment: "industries",
                Form: TenderWizardIndustriesForm,
                progressSelector: (state) =>
                  state.doc?.industries === undefined ? 0 : 1,
                valueSelector: (state) =>
                  state.doc?.industries?.length === 0
                    ? t("TenderWizardIndustriesForm_Label_AllIndustries")
                    : state.doc?.industries
                        ?.map((i) => t(ORGANIZATION_INDUSTRY_RECORD[i].label))
                        ?.join(", "),
              },
              {
                title: t("useTenderWizardSteps_Title_AgentTrainingModel"),
                pathSegment: "agent-training-model",
                Form: TenderWizardAgentTrainingForm,

                valueSelector: (state) => (
                  <TenderAgentTrainingView
                    agentTrainingBudgetType={state.doc?.agentTrainingBudgetType}
                    agentTrainingBudget={state.doc?.agentTrainingBudget}
                    agentTrainingModels={state.doc?.agentTrainingModels}
                    trainingHours={state.doc?.trainingHours}
                  />
                ),
                progressSelector: (state) => {
                  const agentTrainingModels = state.doc?.agentTrainingModels;
                  const modelLength = agentTrainingModels?.length || 0;
                  const invalidBudget =
                    !state.doc?.agentTrainingBudgetType ||
                    (state.doc?.agentTrainingBudgetType == "FIXED" &&
                      state.doc?.agentTrainingBudget === undefined);

                  const progressBudget = invalidBudget
                    ? 0
                    : 1 / (1 + (modelLength || 1));

                  const progressModels =
                    modelLength > 0 ? modelLength / (1 + modelLength) : 0;

                  return progressBudget + progressModels;
                },
              },
              {
                title: t("useTenderWizardSteps_Title_System"),
                pathSegment: "system",
                Form: TenderWizardSystemForm,
                progressSelector: (state) =>
                  state.doc?.system === "PROVIDER_SYSTEM"
                    ? 1
                    : (state.doc?.system ? 0.5 : 0) +
                      (state.doc?.systemDescription ? 0.5 : 0),
                valueSelector: (state) =>
                  state.doc?.system === "PROVIDER_SYSTEM"
                    ? t("TenderWizardSystemForm_Label_ProviderSystem")
                    : state.doc?.systemDescription,
              },
            ],
          },
          {
            title: t("useTenderWizardSteps_Title_Timetable"),
            pathSegment: "timetable",
            Form: TenderWizardTimetableForm,
            progressSelector: (state) => {
              const tt = state.doc?.timetable;
              if (!tt) return 0;

              const progresses = [
                tt.applicationDeadlineAt ? 1 : 0,
                tt.estimatedProjectStartAt ? 1 : 0,
                tt.timetableType ? 1 : 0,
              ];

              if (tt.timetableType === "FINITE") {
                progresses.push(tt.estimatedProjectEndAt ? 1 : 0);
              }

              const progressSum = progresses.reduce(
                (result, p) => result + p,
                0,
              );

              return progressSum / progresses.length;
            },
            valueSelector: (state) => {
              const tt = state.doc?.timetable;
              if (!tt) return;

              const lines: [TranslationKey, string][] = [];

              if (tt.confirmationDeadlineAt) {
                lines.push([
                  "TenderWizardTimetableForm_Label_ConfirmationDeadlineAt",
                  toLocaleDateTimeString(tt.confirmationDeadlineAt, "date"),
                ]);
              }

              if (tt.applicationDeadlineAt) {
                lines.push([
                  "TenderWizardTimetableForm_Label_ApplicationDeadlineAt",
                  toLocaleDateTimeString(tt.applicationDeadlineAt, "date"),
                ]);
              }

              if (tt.estimatedDecisionAt) {
                lines.push([
                  "TenderWizardTimetableForm_Label_EstimatedDecisionAt",
                  toLocaleDateTimeString(tt.estimatedDecisionAt, "date"),
                ]);
              }

              if (tt.estimatedProjectStartAt) {
                lines.push([
                  "TenderWizardTimetableForm_Label_EstimatedProjectStartAt",
                  toLocaleDateTimeString(tt.estimatedProjectStartAt, "date"),
                ]);
              }

              if (tt.timetableType) {
                lines.push([
                  "TenderWizardTimetableForm_Label_EstimatedProjectEndAt",
                  tt.timetableType === "FINITE" && tt.estimatedProjectEndAt
                    ? toLocaleDateTimeString(tt.estimatedProjectEndAt, "date")
                    : t("TimetableTypeRecord_Label_Continuous"),
                ]);
              }

              return lines
                .reduce<string[]>((result, [key, value]) => {
                  result.push(`${t(key)}: ${value}`);
                  return result;
                }, [])
                .join("\n");
            },
          },
          {
            title: t("useTenderWizardSteps_Title_Contact"),
            pathSegment: "contact",
            Form: TenderWizardContactForm,
            progressSelector: (state) =>
              (state.doc?.contact?.firstName ? 0.25 : 0) +
              (state.doc?.contact?.lastName ? 0.25 : 0) +
              (state.doc?.contact?.email ? 0.25 : 0) +
              (state.doc?.contact?.phone ? 0.25 : 0),
            valueSelector: (state) =>
              [
                [state.doc?.contact?.firstName, state.doc?.contact?.lastName]
                  .filter((n) => n)
                  .join(" "),
                state.doc?.contact?.email,
                state.doc?.contact?.phone,
              ]
                .filter((n) => n)
                .join(", "),
          },
          {
            title: t("useTenderWizardSteps_Title_Description"),
            pathSegment: "description",
            Form: TenderWizardDescriptionForm,
            progressSelector: (state) => (state.doc?.description ? 1 : 0),
            valueSelector: (state) => state.doc?.description,
          },
        ],
      },
      {
        title: t("useTenderWizardSteps_Title_UploadDocuments"),
        pathSegment: "upload-documents",
        reviewKey: "uploadDocuments" satisfies TenderReviewKey,
        steps: [
          {
            title: t(
              "useTenderWizardSteps_Title_UploadDocuments_SupportingDocuments",
            ),
            pathSegment: "supporting-documents",
            Form: TenderWizardUploadDocumentsForm,
            progressSelector: (state) =>
              state.doc?.supportDocumentsType === "NO_DOCUMENTS" ||
              (state.doc?.supportDocuments &&
                Object.keys(state.doc?.supportDocuments).length)
                ? 1
                : 0,
            valueSelector: (state) =>
              state.doc?.supportDocumentsType === "WITH_DOCUMENTS" &&
              state.doc?.supportDocuments && (
                <FileListView
                  files={Object.values(state.doc.supportDocuments)}
                />
              ),
          },
        ],
      },
      {
        title: t("useTenderWizardSteps_Title_InviteProviders"),
        pathSegment: "invite-providers",
        reviewKey: "providers" satisfies TenderReviewKey,
        isHidden: !hasPermission("CAN_UPDATE_TENDER_PROVIDERS"),
        steps: [
          {
            title: t("useTenderWizardSteps_Title_ChooseProviders"),
            pathSegment: "choose-providers",
            Form: TenderWizardProvidersForm,
            progressSelector: (state) => {
              const hasMatchedProviders =
                state.doc?.providerApplications &&
                Object.keys(state.doc.providerApplications).length > 0;
              // Draft tenders can be submitted for review without matched providers.
              return hasMatchedProviders || state.doc?.status === "DRAFT"
                ? 1
                : 0;
            },
            valueSelector: (state) =>
              state.doc && <TenderProvidersView tender={state.doc} />,
          },
        ],
      },
      {
        title: t("useTenderWizardSteps_Title_Review"),
        pathSegment: "review",
        Form: TenderWizardReviewForm as FC<WizardFormProps<unknown>>,
        formProps: {
          getSteps: () => steps,
        } satisfies ComponentProps<typeof TenderWizardReviewForm>["formProps"],
        progressSelector: (state) => {
          let totalReviewStepsCount = 0;
          let reviewedStepsCount = 0;
          for (const step of steps) {
            if (step.reviewKey && !step.isHidden) {
              totalReviewStepsCount += 1;
              if (state.doc?.isReviewed?.[step.reviewKey as TenderReviewKey]) {
                reviewedStepsCount += 1;
              }
            }
          }
          return Math.floor(reviewedStepsCount / totalReviewStepsCount);
        },
      },
    ];

    // split "/tenders/{id}" from the rest of the path
    const paths = pathname.split("/").slice(3);
    const previousRoute = pathname.split("/").slice(0, 3).join("/");

    updateActiveWizardSteps(paths, steps);
    updateNextAndPreviousWizardSteps(steps);
    updateWizardStepPaths(steps, previousRoute);

    return steps.filter((step) => !step.isHidden);
  }, [
    getTenderBudgetValue,
    getTenderOperatingHoursValue,
    hasPermission,
    pathname,
    services,
    t,
  ]);
}
