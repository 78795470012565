import { Call, Project } from "@snubes/snubes-types";

export function isCallProcessExpanded(call: Call, project?: Project) {
  const hasQuestions = !!project?.questions?.length;
  const hasCategories = !!project?.callCategories?.length;

  const processes = [
    call.voicefileProcess,
    call.transcriptsProcess,
    call.summaryProcess,
    ...(hasCategories ? [call.categoryProcess] : []),
    ...(hasQuestions ? [call.questionAnswersProcess] : []),
  ];

  return processes.some((process) => process?.status !== "SUCCEEDED");
}
