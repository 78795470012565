import { Alert, List } from "@mui/material";
import { CollectionName, Log } from "@snubes/snubes-types";
import { limit, orderBy, query } from "firebase/firestore";
import { FC, useCallback, useMemo, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { LoadingView } from "../../Common/views/LoadingView";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { getDataFromCollectionSnapshot } from "../../Firebase/helpers/getDataFromQuerySnapshot";
import { LogListItem } from "../types/LogListItem";
import { LogDialog } from "./LogDialog";

interface Props<T extends Log> {
  collectionName: CollectionName;
  LogListItemView: FC<LogListItem<T>>;
  limit: number;
  isT: (obj: unknown) => obj is T;
}

export function LogListView<T extends Log>(props: Props<T>) {
  const { LogListItemView: LogListItem } = props;
  const [selectedLog, setSelectedLog] = useState<T>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snapshot, isLoading, error] = useCollection(
    query(
      getCollectionRef(props.collectionName),
      orderBy("createdAt", "desc"),
      limit(props.limit),
    ),
  );

  const logs = useMemo(
    () =>
      getDataFromCollectionSnapshot(props.isT, snapshot).sort(
        (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis(),
      ),
    [props, snapshot],
  );

  const onClick = useCallback((log: T) => {
    setSelectedLog(log);
    setIsDialogOpen(true);
  }, []);

  const isLoaded = !isLoading && !error;

  return (
    <>
      {isLoading && <LoadingView />}
      {error && <Alert severity="error">{error.message}</Alert>}
      {isLoaded && (
        <List>
          {logs.length === 0 && <Alert severity="info">No logs</Alert>}
          {logs.map((log, index) => (
            <LogListItem
              key={`${log.createdAt.toMillis()}-${index}`}
              log={log}
              isFirstItem={index === 0}
              isLastItem={index === logs.length - 1}
              onClick={() => onClick(log)}
            />
          ))}
        </List>
      )}
      {isDialogOpen && (
        <LogDialog log={selectedLog} close={() => setIsDialogOpen(false)} />
      )}
    </>
  );
}
