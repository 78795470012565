import PersonIcon from "@mui/icons-material/Person";
import SecurityIcon from "@mui/icons-material/Security";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { UserRole } from "@snubes/snubes-types";
import { StatusColor } from "../../Common/types/StatusColor";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface UserRoleRecordValue {
  Icon: OverridableComponent<SvgIconTypeMap>;
  label: TranslationKey;
  color: StatusColor;
}

export const USER_ROLE_RECORD: Readonly<Record<UserRole, UserRoleRecordValue>> =
  {
    USER_ADMIN: {
      Icon: SecurityIcon,
      label: "UserRoleRecord_Label_Admin",
      color: "error",
    },
    USER_REGULAR: {
      Icon: PersonIcon,
      label: "UserRoleRecord_Label_Regular",
      color: "default",
    },
  };
