import { FormControlProps } from "@mui/material";
import { useMemo } from "react";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { useOrganizations } from "../../Organizations/hooks/useOrganizations";
import { ControlledAutocomplete } from "./ControlledAutocomplete";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues & Record<TName, string | null>>;
  name: TName;
  required?: boolean;
  label?: string;
  fullWidth?: boolean;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  size?: FormControlProps["size"];
  multiple?: boolean;
}

export function ControlledOrganizationIdAutocomplete<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const [unsortedRows, isLoading, error] = useOrganizations([
    ["isDeleted", "==", false],
  ]);
  const rows = useMemo(
    () => unsortedRows.sort((a, b) => a.name.localeCompare(b.name)),
    [unsortedRows],
  );

  return (
    <ControlledAutocomplete
      control={props.control}
      name={props.name}
      label={props.label}
      options={rows.map((p) => p.id)}
      multiple={props.multiple}
      getOptionLabel={(projectId) =>
        rows.find((project) => project.id === projectId)?.name || ""
      }
      required={props.required}
      disabled={props.disabled || isLoading}
      fullWidth={props.fullWidth}
      placeholder={props.placeholder}
      helperText={props.helperText}
      size={props.size}
      error={error}
    />
  );
}
