import {
  Badge,
  Container,
  FormHelperText,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  ProviderPricingModelStruct,
  recordOf,
  SERVICE_CATEGORIES,
  ServiceCategory,
  ServiceCategoryStruct,
} from "@snubes/snubes-types";
import { FC, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { array, type } from "superstruct";
import { CheckboxButtons } from "../../Form/views/CheckboxButtons";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { PROVIDER_PRICING_MODEL } from "../consts/PROVIDER_PRICING_MODEL";
import { PROVIDER_PRICING_MODELS_BY_CATEGORY } from "../consts/PROVIDER_SERVICE_CATEGORIES";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  pricingModelsByServiceCategory: recordOf(
    ServiceCategoryStruct,
    array(ProviderPricingModelStruct),
  ),
});

export const ProviderWizardPricingModelsForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      pricingModelsByServiceCategory: provider?.pricingModelsByServiceCategory
        ? {
            INBOUND: provider.pricingModelsByServiceCategory.INBOUND || [],
            OUTBOUND: provider.pricingModelsByServiceCategory.OUTBOUND || [],
            BACK_OFFICE_SUPPORT:
              provider.pricingModelsByServiceCategory.BACK_OFFICE_SUPPORT || [],
          }
        : {
            INBOUND: provider?.pricingModels || [],
            OUTBOUND: [],
            BACK_OFFICE_SUPPORT: [],
          },
    }),
    toDoc: (formValues) => {
      const models = formValues?.pricingModelsByServiceCategory;
      return {
        pricingModelsByServiceCategory: models
          ? {
              ...(models.INBOUND?.length && {
                INBOUND: models.INBOUND,
              }),
              ...(models.OUTBOUND?.length && {
                OUTBOUND: models.OUTBOUND,
              }),
              ...(models.BACK_OFFICE_SUPPORT?.length && {
                BACK_OFFICE_SUPPORT: models.BACK_OFFICE_SUPPORT,
              }),
            }
          : {},
      };
    },
  });

  const [selectedCategory, setSelectedCategory] =
    useState<ServiceCategory>("INBOUND");
  const optionsForSelectedCategory = useMemo(
    () =>
      PROVIDER_PRICING_MODELS_BY_CATEGORY.get(selectedCategory)
        ?.map((model) => ({
          ...PROVIDER_PRICING_MODEL[model],
          value: model,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)) || [],
    [selectedCategory],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("ProviderWizardPricingModelsForm_Title_PricingModels")}
        </Typography>
        <Typography variant="body1" color="text.500" mb={4}>
          {t("ProviderWizardPricingModelsForm_Paragraph_PricingModels")}
        </Typography>
        <Stack spacing={3}>
          <Controller
            name="pricingModelsByServiceCategory"
            control={control}
            rules={{
              required: "Required",
            }}
            render={({ field, fieldState }) => {
              return (
                <Stack spacing={3}>
                  <Tabs
                    value={selectedCategory}
                    onChange={(_, nextValue: ServiceCategory) =>
                      setSelectedCategory(nextValue)
                    }
                  >
                    {SERVICE_CATEGORIES.map((category) => (
                      <Tab
                        key={category}
                        value={category}
                        label={
                          <Badge
                            badgeContent={field.value[category]?.length || 0}
                            color="primary"
                            sx={{ ".MuiBadge-badge": { right: -5 } }}
                          >
                            {t(`ServiceCategory_${category}`)}
                          </Badge>
                        }
                      />
                    ))}
                  </Tabs>

                  <CheckboxButtons
                    options={optionsForSelectedCategory}
                    error={!!fieldState.error}
                    value={field.value[selectedCategory]}
                    onChange={(values) =>
                      field.onChange({
                        ...field.value,
                        [selectedCategory]: values,
                      })
                    }
                    gridSpacing={4}
                    gridItemXs={12}
                    gridItemSm={6}
                    gridItemMd={4}
                  />

                  {fieldState.error && (
                    <FormHelperText error>
                      {t("ControlledServiceTypesField_ErrorMessage")}
                    </FormHelperText>
                  )}
                </Stack>
              );
            }}
          />
        </Stack>
      </Container>
    </form>
  );
};
