import { Call } from "@snubes/snubes-types";
import { getCallProcessStatus } from "./getCallProcessStatus";

export function getNormalizedCall(originalCall: Call) {
  // We clone the call to avoid mutating the original object.
  const call = { ...originalCall };

  const isVoicefileProcessable = !!call.hasVoicefile;
  const isVoicefileProcessed = !!call.isVoicefileAvailable;
  const isTranscriptProcessed = !!call.transcripts;
  const isSummaryProcessed = !!call.summary;
  const isCategoryProcessed = !!call.categoryId;
  const isQuestionAnswersProcessed = !!call.questionAnswers?.length;
  const voicefileProcessEndedAt =
    call.voicefileProcess?.endedAt || call.createdAt;
  const transcriptProcessEndedAt =
    call.transcriptsProcess?.endedAt || call.createdAt;

  // Voicefile
  call.voicefileProcess = {
    ...call.voicefileProcess,
    status: getCallProcessStatus({
      process: call.voicefileProcess,
      isProcessed: isVoicefileProcessed,
      isProcessable: isVoicefileProcessable,
      previousProcessEndedAt: call.createdAt,
    }),
  };

  // Transcripts
  call.transcriptsProcess = {
    ...call.transcriptsProcess,
    status: getCallProcessStatus({
      process: call.transcriptsProcess,
      isProcessed: isTranscriptProcessed,
      isProcessable: isVoicefileProcessed,
      previousProcessEndedAt: voicefileProcessEndedAt,
    }),
  };

  // Summary
  call.summaryProcess = {
    ...call.summaryProcess,
    status: getCallProcessStatus({
      process: call.summaryProcess,
      isProcessed: isSummaryProcessed,
      isProcessable: isTranscriptProcessed,
      previousProcessEndedAt: transcriptProcessEndedAt,
    }),
  };

  // Call Category
  call.categoryProcess = {
    ...call.categoryProcess,
    status: getCallProcessStatus({
      process: call.categoryProcess,
      isProcessed: isCategoryProcessed,
      isProcessable: isTranscriptProcessed,
      previousProcessEndedAt: transcriptProcessEndedAt,
    }),
  };

  // Question Answers
  call.questionAnswersProcess = {
    ...call.questionAnswersProcess,
    status: getCallProcessStatus({
      process: call.questionAnswersProcess,
      isProcessed: isQuestionAnswersProcessed,
      isProcessable: isTranscriptProcessed,
      previousProcessEndedAt: transcriptProcessEndedAt,
    }),
  };

  return call;
}
