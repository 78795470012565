import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, CircularProgress, Stack } from "@mui/material";
import { Tender, TenderApplication } from "@snubes/snubes-types";
import { FC, useCallback, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { createOrUpdateTenderApplicationCallable } from "../callables/createOrUpdateTenderApplicationCallable";
import { getTenderApplicationAllowedTransitions } from "../helpers/getTenderApplicationAllowedTransitions";
import { useTenderStatus } from "../hooks/useTenderStatus";
import { TenderApplicationRejectDialog } from "./TenderApplicationRejectDialog";

interface Props {
  tender: Tender;
  application: TenderApplication;
}

export const TenderApplicationActionsView: FC<Props> = (props) => {
  const { tender, application } = props;
  const t = useT();
  const userType = useMyUser((state) => state.user?.userType || null);
  const [isAccepting, setIsAccepting] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);

  const allowedTransitions = getTenderApplicationAllowedTransitions({
    userType,
    tender,
    tenderApplication: application,
  });

  const canClientReject = allowedTransitions.REJECT_BY_CLIENT;
  const canClientAccept = allowedTransitions.ACCEPT;
  const hasTenderEnded = useTenderStatus(tender) === "ENDED" ? true : false;

  const onClickAcceptApplication = useCallback(
    async (application: TenderApplication) => {
      if (isAccepting) {
        return;
      }
      setIsAccepting(true);
      try {
        await createOrUpdateTenderApplicationCallable({
          tenderId: tender.id,
          providerId: application.providerId,
          tenderApplication: {
            status: "ACCEPTED",
          },
        });
      } catch (error) {
        handleError(error).logAnd().toast();
      } finally {
        setIsAccepting(false);
      }
    },
    [isAccepting, tender.id],
  );

  return (
    <Stack direction="row" justifyContent="center" spacing={2}>
      <Button
        size="small"
        sx={{ minWidth: 160 }}
        color="error"
        endIcon={<CancelIcon />}
        disabled={isAccepting || !canClientReject || hasTenderEnded}
        onClick={() => setIsRejectDialogOpen(true)}
      >
        {t("TenderApplicationActionsView_Button_RejectApplication")}
      </Button>
      <Button
        size="small"
        sx={{ minWidth: 160 }}
        color="primary"
        endIcon={<CheckCircleIcon />}
        disabled={isAccepting || !canClientAccept || hasTenderEnded}
        onClick={() => onClickAcceptApplication(application)}
      >
        {t("TenderApplicationActionsView_Button_AcceptApplication")}
        {isAccepting && <CircularProgress size={20} color="inherit" />}
      </Button>
      {isRejectDialogOpen && (
        <TenderApplicationRejectDialog
          tenderId={tender.id}
          providerId={application.providerId}
          close={() => setIsRejectDialogOpen(false)}
        />
      )}
    </Stack>
  );
};
