import { Money } from "@snubes/snubes-types";
import { DeepPartial } from "react-hook-form";

export const getValidMoney = (
  money: DeepPartial<Money> | null | undefined,
): Money | undefined => {
  const amount = money?.amount;
  const currency = money?.currency;
  if (amount === undefined || currency === undefined) {
    return undefined;
  }

  return { amount, currency };
};
