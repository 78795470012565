import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useState } from "react";
import { HeaderLanguageMenuButton } from "../../Header/views/HeaderLanguageMenuButton";
import { HeaderLanguageMenuView } from "../../Header/views/HeaderLanguageMenuView";
import { useT } from "../../Translation/hooks/useT";
import { FOOTER_LINK_ITEMS } from "../consts/FOOTER_LINK_ITEMS";

export const AuthLayoutFooter: FC = () => {
  const t = useT();
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] =
    useState<HTMLElement>();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={3}
      minHeight={64}
      borderTop={1}
      borderColor="divider"
      bgcolor="background.paper"
    >
      <Grid
        container
        spacing={3}
        width="100%"
        maxWidth="lg"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm
          sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
            },
          }}
        >
          <Typography variant="caption2" color="text.secondary">
            © {new Date().getFullYear()}{" "}
            {t("AuthLayoutFooter_Title_SnubesGmbH")}
          </Typography>
        </Grid>

        <Grid item xs={12} sm>
          <Stack direction="row" justifyContent="center" spacing={2}>
            {FOOTER_LINK_ITEMS.map((item) => (
              <FooterLink
                variant="caption2"
                color="text.secondary"
                textAlign="center"
                href={item.href}
                key={item.href}
              >
                {t(item.translationKey)}
              </FooterLink>
            ))}
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          sm
          display="flex"
          sx={{
            justifyContent: {
              xs: "center",
              sm: "flex-end",
            },
          }}
        >
          <Box>
            <HeaderLanguageMenuButton
              small
              onSetLanguageMenuAnchorEl={setLanguageMenuAnchorEl}
            />
          </Box>
        </Grid>
      </Grid>
      <HeaderLanguageMenuView
        anchorEl={languageMenuAnchorEl}
        closeMenu={() => setLanguageMenuAnchorEl(undefined)}
      />
    </Box>
  );
};

const FooterLink = styled(Link)({
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
});
