import { FC } from "react";
import { useParams } from "react-router-dom";
import { App404Page } from "../../Application/views/App404Page";
import { NOTIFICATION_NAV_TABS } from "../consts/NOTIFICATION_NAV_TABS";

export const NotificationsSwitchPage: FC = () => {
  const { notificationTab } = useParams<"notificationTab">();
  const Page = NOTIFICATION_NAV_TABS.find((t) => t.route === notificationTab)
    ?.Page;

  if (Page) {
    return <Page />;
  } else {
    return <App404Page />;
  }
};
