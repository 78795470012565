import { Organization } from "@snubes/snubes-types";
import { createContext, useContext } from "react";

interface OrganizationContext {
  organization?: Organization;
}
const organizationContext = createContext<OrganizationContext>({});

export const OrganizationContextProvider = organizationContext.Provider;

export const useOrganizationContext = () => {
  return useContext(organizationContext);
};
