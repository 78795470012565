import { Stack, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { FirebaseFilter, Member, Tender, User } from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import { FullHeightStack } from "../../Common/views/FullHeightStack";
import { MaxWidthContainer } from "../../Common/views/MaxWidthContainer";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { getUserName } from "../../Users/helpers/getUserName";
import { useSetUserType } from "../../Users/hooks/useSetUserType";
import { getTimeOfDayGreeting } from "../helpers/getTimeOfDayGreeting";
import { HomeApplicationCardView } from "./HomeApplicationCardView";
import { HomeCardNotificationsView } from "./HomeCardNotificationsView";
import { HomeCardOrganizationView } from "./HomeCardOrganizationView";
import { HomeCardProjectsView } from "./HomeCardProjectsView";
import { HomeCardTendersView } from "./HomeCardTendersView";
import { HomeOtherApplicationsView } from "./HomeOtherApplicationsView";

interface Props {
  user: User;
  member?: Member;
}

export const ClientHomeView: FC<Props> = (props) => {
  const { user } = props;
  const t = useT();

  const organizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );
  const [setUserType] = useSetUserType();
  const tenderFilters = useMemo<FirebaseFilter<Tender>[]>(
    () => [
      ["organizationId", "==", organizationId || null],
      ["isDeleted", "==", false],
    ],
    [organizationId],
  );

  return (
    <FullHeightStack>
      <MaxWidthContainer>
        <Stack p={5} spacing={4}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h2">
              {t(getTimeOfDayGreeting())}, {getUserName(user)}
            </Typography>
          </Stack>

          <Grid container spacing={3}>
            <Grid xs={12} sm={6} md={4}>
              <HomeCardOrganizationView organizationId={organizationId} />
            </Grid>

            <Grid xs={12} sm={6} md={4}>
              <HomeCardNotificationsView userId={user.id} />
            </Grid>

            <Grid xs={12} sm={6} md={4}>
              <HomeCardProjectsView organizationId={organizationId} />
            </Grid>

            <Grid xs={12} sm={12} md={8}>
              <HomeCardTendersView
                filters={tenderFilters}
                orderBy={["updatedAt", "desc"]}
                variant="CLIENT"
                maxCount={3}
              />
            </Grid>
          </Grid>
        </Stack>
      </MaxWidthContainer>

      <HomeOtherApplicationsView sx={{ flex: 1 }}>
        <HomeApplicationCardView
          bgcolor="primary.50"
          subtitleText="ClientHomePage_Subtitle_ForCallCenters"
          titleText="ClientHomePage_Heading_FindNewProject"
          buttonText="ClientHomePage_Button_LearnMore"
          buttonHref="/home/provider"
          buttonOnClick={() => setUserType("PROVIDER")}
          imgSrc="/assets/home-provider.png"
          imgBgcolor="secondary.50"
        />
        <HomeApplicationCardView
          bgcolor="secondary.50"
          subtitleText="ClientHomePage_Subtitle_ForQualityMonitoring"
          titleText="ClientHomePage_Heading_AnalyzeGetInsights"
          buttonText="ClientHomePage_Button_LearnMore"
          buttonHref="/home/qm"
          buttonOnClick={() => setUserType("QM")}
          imgSrc="/assets/home-qm.png"
          imgBgcolor="error.50"
        />
      </HomeOtherApplicationsView>
    </FullHeightStack>
  );
};
