import { Paper, Stack, Typography } from "@mui/material";
import { FirebaseFilter, Tender } from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import {
  FirebaseTableFilterContextProvider,
  FirebaseTableFilterRecord,
} from "../../Firebase/hooks/useFirebaseTableFilters";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { TENDER_STATUS_RECORD } from "../../Tenders/consts/TENDER_STATUS_RECORD";
import { useTendersController } from "../../Tenders/hooks/useTendersController";
import { TendersCollectionTableView } from "../../Tenders/views/TendersCollectionTableView";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface Props {
  headline: TranslationKey;
  filters: FirebaseFilter<Tender>[];
}

export const AdminTendersPage: FC<Props> = (props) => {
  const { headline, filters } = props;
  const t = useT();
  const controller = useTendersController({
    defaultOrderBy: "CREATED_AT",
  });

  const firebaseFilters = useMemo<FirebaseTableFilterRecord<Tender>>(
    () => ({
      name: {
        isSearch: true,
        label: t("FirebaseTableFilterView_Label_Search"),
        type: "text",
        operator: "search",
        value: null,
      },
      status: {
        label: t("FirebaseTableFilterView_Label_Status"),
        type: "select",
        operator: "==",
        value: null,
        options: Object.entries(TENDER_STATUS_RECORD).map(
          ([value, { label }]) => ({
            label: t(label),
            value,
          }),
        ),
      },
    }),
    [t],
  );

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer>
        <Stack py={5} spacing={3}>
          <Typography variant="h2">{t(headline)}</Typography>
          <Paper>
            <FirebaseTableFilterContextProvider filters={firebaseFilters}>
              <TendersCollectionTableView
                showStatus
                showMatches
                showActions
                controller={controller}
                filters={filters}
              />
            </FirebaseTableFilterContextProvider>
          </Paper>
        </Stack>
      </FullHeightContainer>
    </>
  );
};
