import { Project } from "@snubes/snubes-types";
import { useCallback, useEffect, useState } from "react";
import { getProjectLookerUrlCallable } from "../callables/getProjectLookerUrlCallable";

export function useProjectLookerUrl(
  project?: Project,
): [
  lookerUrl: string | undefined,
  isLoading: boolean,
  error: Error | undefined,
] {
  const [isLoading, setIsLoading] = useState(true);
  const [lookerUrl, setLookerUrl] = useState<string>();
  const [error, setError] = useState<Error>();

  const getLookerUrl = useCallback(async () => {
    if (!project?.isLookerAnalyticsEnabled) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      const { lookerUrl } = await getProjectLookerUrlCallable({
        projectId: project.id,
      });

      setLookerUrl(lookerUrl);
      setError(undefined);
    } catch (e) {
      const error = e instanceof Error ? e : new Error(String(e));
      setLookerUrl(undefined);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [project?.id, project?.isLookerAnalyticsEnabled]);

  useEffect(() => {
    void getLookerUrl();
  }, [getLookerUrl]);

  return [lookerUrl, isLoading, error];
}
