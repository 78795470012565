import {
  Divider,
  FormControlLabel,
  Slider,
  Switch,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { WeekdayPeriod } from "@snubes/snubes-types";
import { DateTime, Duration } from "luxon";
import { Fragment } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { toLocaleClockTimeString } from "../../Common/helpers/toLocaleClockTimeString";
import { toLocaleWeekday } from "../../Common/helpers/toLocaleWeekday";
import { useT } from "../../Translation/hooks/useT";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues & Record<TName, WeekdayPeriod[]>>;
  name: TName;
  required?: boolean;
  disabled?: boolean;
}

export function ControlledWeekdayPeriods<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const t = useT();

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field }) => {
        const periods = toFormWeekdayPeriods(field.value);

        return (
          <Stack
            borderColor="divider"
            borderRadius="4px"
            sx={{ borderWidth: 1, borderStyle: "solid" }}
          >
            {periods.map((period, index) => (
              <Fragment key={period.weekday}>
                <Stack p={3} py={2}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormControlLabel
                      label={
                        <Typography variant="caption1">
                          {toLocaleWeekday(period.weekday)}
                        </Typography>
                      }
                      control={
                        <Switch
                          checked={period.isEnabled}
                          color="secondary"
                          onChange={(_, value) => {
                            period.isEnabled = value;
                            field.onChange(toNormalizedWeekdayPeriods(periods));
                          }}
                        />
                      }
                    />
                    {period.isEnabled && (
                      <Stack direction="row" spacing={2}>
                        <Typography variant="caption1" color="primary.main">
                          ({toReadableTimeString(period.from)} -{" "}
                          {toReadableTimeString(period.to)})
                        </Typography>
                        <Typography variant="caption1" color="text.secondary">
                          {t("ControlledWeekdayPeriods_Caption_TotalHours", {
                            hours: (
                              (period.to - period.from) /
                              60
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            }),
                          })}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                  {period.isEnabled && (
                    <Slider
                      value={[period.from, period.to]}
                      min={0}
                      max={60 * 24}
                      step={15}
                      onChange={(_, value) => {
                        if (!Array.isArray(value)) return;

                        periods[index].from = value[0];
                        periods[index].to = value[1];

                        field.onChange(toNormalizedWeekdayPeriods(periods));
                      }}
                      disabled={props.disabled}
                      valueLabelDisplay="auto"
                      valueLabelFormat={toReadableTimeString}
                    />
                  )}
                </Stack>
                {index !== periods.length - 1 && <Divider sx={{ m: 0 }} />}
              </Fragment>
            ))}
          </Stack>
        );
      }}
    />
  );
}

function toMinutes(value: string) {
  return Duration.fromISOTime(value || "09:00").toMillis() / 1000 / 60;
}

function toValueString(minutes: number) {
  return Duration.fromMillis(minutes * 60 * 1000).toFormat("hh:mm");
}

function toReadableTimeString(minute: number) {
  return toLocaleClockTimeString(
    DateTime.fromMillis(0).toUTC().set({ minute }),
  );
}

interface FormWeekdayPeriod {
  weekday: number;
  from: number;
  to: number;
  isEnabled: boolean;
}

function toFormWeekdayPeriods(periods: WeekdayPeriod[]): FormWeekdayPeriod[] {
  return new Array(7).fill(null).map((_, i) => {
    const period = periods.find((p) => p.weekday === i);

    return {
      weekday: i,
      from: toMinutes(period?.from || "09:00"),
      to: toMinutes(period?.to || "17:00"),
      isEnabled: !!period,
    };
  });
}

function toNormalizedWeekdayPeriods(
  periods: FormWeekdayPeriod[],
): WeekdayPeriod[] {
  return periods
    .filter((period) => period.isEnabled)
    .map((period) => ({
      weekday: period.weekday,
      from: toValueString(period.from),
      to: toValueString(period.to),
    }));
}
