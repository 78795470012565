import { superstructResolver } from "@hookform/resolvers/superstruct";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { CreateConnectionRequest, ExternalProject } from "@snubes/snubes-types";
import { FC, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Infer, nullable, string, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { isProjectMappable } from "../../Common/helpers/isProjectMappable";
import { ControlledProjectIdAutocomplete } from "../../Form/views/ControlledProjectIdAutocomplete";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { createConnectorConfigCallable } from "../callables/createConnectorConfigCallable";
import { testConnectorConfigDialfireCallable } from "../callables/testConnectorConfigDialfireCallable";

const FormValuesStruct = type({
  campaignId: string(),
  campaignToken: string(),
  name: string(),
  projectId: nullable(string()),
  projectName: string(),
});

type FormValues = Infer<typeof FormValuesStruct>;

export const ConnectorConfigDialfireForm: FC = () => {
  const t = useT();
  const navigate = useNavigate();
  const { organizationId } = useParams<"organizationId">();
  const [externalProjects, setExternalProjects] = useState<
    ExternalProject[] | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { control, formState, handleSubmit } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      campaignId: "",
      campaignToken: "",
      name: "",
      projectId: null,
      projectName: "",
    },
  });

  const testConnectorDialfireConfig = useCallback(
    async (formValues: FormValues, organizationId: string) => {
      try {
        const data = await testConnectorConfigDialfireCallable({
          campaignId: formValues.campaignId,
          campaignToken: formValues.campaignToken,
          organizationId,
          platform: "DIALFIRE",
        });

        setErrorMessage(undefined);
        setExternalProjects(data.externalProjects);
      } catch (error) {
        setExternalProjects(undefined);
        setErrorMessage(error instanceof Error ? error.message : String(error));
      }
    },
    [],
  );
  const createConnectorConfig = useCallback(
    async (formValues: FormValues, organizationId: string) => {
      try {
        if (!formValues.projectId) throw new Error("Invalid Project Id");

        const externalProject = formValues.campaignId; // TODO: maybe support multiple DIALFIRE projects?
        const projectMappings: CreateConnectionRequest["projectMappings"] =
          externalProject
            ? [
                {
                  projectId: formValues.projectId,
                  projectName: formValues.projectName,
                  externalProjectId: externalProject,
                },
              ]
            : [];

        await createConnectorConfigCallable({
          name: formValues.name,
          organizationId,
          platform: "DIALFIRE",
          campaignId: formValues.campaignId,
          campaignToken: formValues.campaignToken,
          projectMappings,
        });
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!organizationId) throw new Error("No organization selected");

        if (externalProjects) {
          await createConnectorConfig(formValues, organizationId);
          navigate(`/organizations/${organizationId}/connector-configs`);
        } else {
          await testConnectorDialfireConfig(formValues, organizationId);
        }
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [
      organizationId,
      externalProjects,
      createConnectorConfig,
      navigate,
      testConnectorDialfireConfig,
    ],
  );
  const { isSubmitting } = formState;

  const isConnecting = isSubmitting && !externalProjects;

  return (
    <Stack pt={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <ControlledTextField
            required
            fullWidth
            autoComplete="off"
            name="name"
            label={t("ConnectorConfigDialfireForm_Inputlabel_Name")}
            control={control}
            disabled={isSubmitting}
          />
          <Divider />

          <Typography variant="subtitle1">
            {t("ConnectorConfigDialfireForm_SubHeading_Credentials")}
          </Typography>

          <ControlledTextField
            required
            fullWidth
            autoComplete="off"
            name="campaignId"
            label={t("ConnectorConfigDialfireForm_InputLabel_CampaignId")}
            control={control}
            disabled={isSubmitting}
          />
          <ControlledTextField
            required
            fullWidth
            autoComplete="off"
            name="campaignToken"
            label={t("ConnectorConfigDialfireForm_InputLabel_Token")}
            control={control}
            disabled={isSubmitting}
          />
          {isConnecting && (
            <Stack spacing={3} alignItems="center" direction="row">
              <CircularProgress size={20} />
              <Typography variant="subtitle2">
                {t("ConnectorConfigDialfireForm_SubHeading_ConnectingDialFire")}
              </Typography>
            </Stack>
          )}
          {!!errorMessage && (
            <Stack color="error.main" spacing={3}>
              <Stack spacing={3} alignItems="center" direction="row">
                <InfoIcon />
                <Typography variant="subtitle2">
                  {t("ConnectorConfigDialfireForm_SubHeading_ConnectedError")}
                </Typography>
              </Stack>
              <Stack
                p={5}
                bgcolor="background.default"
                justifyContent="center"
                alignContent="center"
              >
                {errorMessage}
              </Stack>
              <Divider />
            </Stack>
          )}
          {externalProjects && (
            <Stack spacing={3}>
              <Stack
                color="success.main"
                spacing={3}
                alignItems="center"
                direction="row"
              >
                <CheckCircleIcon />
                <Typography variant="subtitle2">
                  {t("ConnectorConfigDialfireForm_SubHeading_Connected")}
                </Typography>
              </Stack>
              <Divider />
              <Typography variant="subtitle1">
                {t("ConnectorConfigDialfireForm_SubTitle_ChooseProject")}
              </Typography>
              {organizationId && (
                <ControlledProjectIdAutocomplete
                  required
                  filter={(project) => isProjectMappable(project)}
                  name="projectId"
                  projectNameFieldName="projectName"
                  control={control}
                  label={t(
                    "ConnectorConfigDialfireForm_SelectLabel_SnubesProject",
                  )}
                  organizationId={organizationId}
                  disabled={isSubmitting}
                />
              )}
            </Stack>
          )}
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {!externalProjects &&
              t("ConnectorConfigDialfireForm_Button_Connect")}
            {externalProjects &&
              t("ConnectorConfigDialfireForm_Button_FinishSetUp")}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};
