import { CallQuestionAnswer, ProjectQuestion } from "@snubes/snubes-types";

export function hasOutdatedQuestionAnswers(
  projectQuestions?: ProjectQuestion[],
  callQuestionAnswers?: CallQuestionAnswer[],
) {
  if (!projectQuestions?.length) return false;
  if (!callQuestionAnswers?.length) return false;

  const questionIdsFromAnswers = new Set(
    callQuestionAnswers.map((qa) => qa.questionId),
  );

  return projectQuestions.some((q) => !questionIdsFromAnswers.has(q.id));
}
