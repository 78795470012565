import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import TodayIcon from "@mui/icons-material/Today";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { TimetableType } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface TimetableTypeRecordValue {
  label: TranslationKey;
  caption?: TranslationKey;
  Icon?: OverridableComponent<SvgIconTypeMap>;
}
export const TIMETABLE_TYPE_RECORD: Readonly<
  Record<TimetableType, TimetableTypeRecordValue>
> = {
  CONTINUOUS: {
    label: "TimetableTypeRecord_Label_Continuous",
    Icon: AllInclusiveIcon,
  },
  FINITE: {
    label: "TimetableTypeRecord_Label_Finite",
    Icon: TodayIcon,
  },
};
