import { Stack, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { TaskStatus, Timestamp } from "@snubes/snubes-types";
import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";
import { STATUS_COLOR_RECORD } from "../../Common/consts/STATUS_COLOR_RECORD";
import { StatusColor } from "../../Common/types/StatusColor";
import { AvatarWithIconView } from "../../Common/views/AvatarWithIconView";
import { RelativeTimestampView } from "../../Common/views/RelativeTimestampView";
import { NotificationListItemMenuItemView } from "./NotificationListItemMenuItemView";
import { NotificationListItemTitleView } from "./NotificationListItemTitleView";

const HAS_BEEN_READ_CIRCLE_SIZE = 8;

interface Props {
  Icon: OverridableComponent<SvgIconTypeMap>;
  color: StatusColor;
  caption: ReactNode;
  title: ReactNode;
  error?: Error;
  isLoading?: boolean;
  onShouldUpdateMenuPosition?: () => void;
  createdAt: Timestamp;
  hasBeenRead?: boolean;
  actions?: ReactNode;
  isMenuItem?: boolean;
  taskStatus?: TaskStatus;
  to?: string;
}

export const NotificationListItemView: FC<Props> = (props) => {
  const {
    caption,
    title,
    error,
    isLoading,
    onShouldUpdateMenuPosition,
    createdAt,
    actions,
    isMenuItem,
    to,
    hasBeenRead,
    taskStatus,
    Icon,
  } = props;

  const isDone = taskStatus === "DONE";
  const color: StatusColor = isDone
    ? "secondary"
    : error
    ? "error"
    : props.color;

  return (
    <Stack
      flex={1}
      p={3}
      spacing={3}
      direction="row"
      component={isMenuItem ? NotificationListItemMenuItemView : "div"}
      to={to}
    >
      <Stack justifyContent="center">
        <AvatarWithIconView Icon={Icon} color={color} />
      </Stack>
      <Stack flex={1} spacing={1.5} sx={{ overflow: "hidden" }}>
        <Typography variant="caption1" color={STATUS_COLOR_RECORD[color].color}>
          <Trans>{caption}</Trans>
        </Typography>
        <NotificationListItemTitleView
          isMenuItem={isMenuItem}
          isLoading={isLoading}
          error={error}
          onShouldUpdateMenuPosition={onShouldUpdateMenuPosition}
        >
          <Trans>{title}</Trans>
        </NotificationListItemTitleView>
        <Typography variant="caption1" color="text.600">
          <RelativeTimestampView
            timestamp={createdAt}
            shouldBeInThePast
            translation
          />
        </Typography>
      </Stack>
      {actions && !isMenuItem && (
        <Stack spacing={2} direction="row" alignItems="center">
          {actions}
        </Stack>
      )}
      <Stack justifyContent="center">
        <Stack
          bgcolor="primary.main"
          sx={{ opacity: hasBeenRead ? 0 : 1 }}
          height={HAS_BEEN_READ_CIRCLE_SIZE}
          width={HAS_BEEN_READ_CIRCLE_SIZE}
          borderRadius={HAS_BEEN_READ_CIRCLE_SIZE}
        />
      </Stack>
    </Stack>
  );
};
