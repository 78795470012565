import { ReactNode } from "react";
import { create } from "zustand";
import { HeaderConfigVariant } from "../types/HeaderConfigVariant";

interface HeaderConfigState {
  variant: HeaderConfigVariant;
  children?: ReactNode;
  hasSecondaryHeader?: boolean;
}

export const useHeaderConfig = create<HeaderConfigState>(() => ({
  variant: "NONE",
}));
