import { ConnectorConfigInterruptionReason } from "../types/ConnectorConfigInterruptionReason";

export const SFTP_INTERRUPTION_REASONS: ConnectorConfigInterruptionReason[] = [
  {
    title: "SftpInterruptionReasons_Title_CheakAndUpdateCredentials",
    description:
      "SftpInterruptionReasons_Description_CheakAndUpdateCredentials",
  },
  {
    title: "SftpInterruptionReasons_Title_CheakAndUpdateProjectMappings",
    description:
      "SftpInterruptionReasons_Description_CheakAndUpdateProjectMappings",
    to: "mappings",
  },
  {
    title: "SftpInterruptionReasons_Title_ContactSftpSupport",
    description: "SftpInterruptionReasons_Description_SftpSupport",
  },
];
