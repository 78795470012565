import { Typography } from "@mui/material";
import { Timestamp } from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC, useCallback, useEffect, useState } from "react";

interface Props {
  startedAt: Timestamp;
}

export const CallProcessingDurationView: FC<Props> = (props) => {
  const { startedAt } = props;
  const getValue = useCallback(
    () =>
      DateTime.fromMillis(startedAt.toMillis())
        .diffNow()
        .negate()
        .toFormat("mm:ss"),
    [startedAt],
  );

  const [value, setValue] = useState(getValue());

  useEffect(() => {
    const interval = setInterval(() => setValue(getValue()), 1000);

    return () => clearInterval(interval);
  }, [getValue]);

  return (
    <Typography variant="body3" color="text.600">
      {value}
    </Typography>
  );
};
