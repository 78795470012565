import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import { Button, Paper, Stack, Typography } from "@mui/material";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { unarchiveProjectCallable } from "../callables/unarchiveProjectCallable";

interface Props {
  projectId: string;
}

export const ProjectDetailsPageArchivedBanner: FC<Props> = (props) => {
  const t = useT();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClickUnarchiveProject = useCallback(async () => {
    if (!props.projectId) return;

    try {
      setIsSubmitting(true);

      await unarchiveProjectCallable({
        projectId: props.projectId,
      });

      toast.success(t("ProjectDetailsPageArchivedBanner_Toast_SuccessMessage"));
    } catch (error) {
      handleError(error).logAnd().toast();
      setIsSubmitting(false);
    }
  }, [props.projectId, t]);

  return (
    <Stack pb={3}>
      <Paper variant="outlined">
        <Stack direction="row" p={3} justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={3}>
            <ArchiveIcon sx={{ width: "32px", height: "32px" }} />
            <Typography variant="h4">
              {t("ProjectDetailsPageArchivedBanner_Label_Unarchive")}
            </Typography>
          </Stack>
          <Button
            color="primary"
            variant="outlined"
            startIcon={<UnarchiveOutlinedIcon />}
            disabled={isSubmitting}
            onClick={onClickUnarchiveProject}
            sx={{ height: "36px", borderRadius: "4px" }}
          >
            {t("ProjectDetailsPageArchivedBanner_Button_Unarchive")}
          </Button>
        </Stack>
      </Paper>
    </Stack>
  );
};
