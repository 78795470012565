import { CollectionName } from "@snubes/snubes-types";
import { ENVIRONMENT } from "../../Application/consts/ENVIRONMENT";

const FIRESTORE_EMULATOR_PREFIX = "http://localhost:4000/firestore/data";
const FIRESTORE_CONSOLE_PREFIX = "https://console.firebase.google.com";

export function getFirestoreConsoleLink(collectionName: CollectionName) {
  const documentPath = collectionName.split("/").slice(0, -1).join("/");

  if (ENVIRONMENT.firebaseConfig.connectFirebaseFunctionsEmulator) {
    return `${FIRESTORE_EMULATOR_PREFIX}/${documentPath}`;
  }

  const firebaseProjectName = ENVIRONMENT.firebaseConfig.projectId;

  if (!firebaseProjectName) {
    return;
  }

  const firebaseUser = localStorage.getItem("u");

  return `${FIRESTORE_CONSOLE_PREFIX}${
    firebaseUser ? `/u/${firebaseUser}` : ""
  }/project/${firebaseProjectName}/firestore/data/~2F${documentPath.replaceAll(
    "/",
    "~2F",
  )}`;
}
