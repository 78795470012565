import { Tender } from "@snubes/snubes-types";
import { getSelectedOrganizationId } from "../../Organizations/helpers/getSelectedOrganizationId";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { createTenderCallable } from "../callables/createTenderCallable";
import { updateTenderCallable } from "../callables/updateTenderCallable";

export async function createOrUpdateTender(
  tender: Partial<Tender>,
  tenderId?: string,
): Promise<string> {
  const organizationId = getSelectedOrganizationId(useMyUser.getState().user);

  if (!organizationId) {
    throw new Error("Missing organization ID.");
  }

  if (tenderId) {
    await updateTenderCallable({
      tenderId,
      tender,
    });
    return tenderId;
  }

  const response = await createTenderCallable({ organizationId, tender });
  return response.tenderId;
}
