import { message } from "@snubes/snubes-types";
import { useMemo } from "react";
import { boolean, Infer, nonempty, string, type } from "superstruct";
import { useT } from "../../Translation/hooks/useT";
import { UrlOrDomainStruct } from "../types/UrlOrDomain";

export type OrganizationCreateFormValues = Infer<
  ReturnType<typeof useOrganizationCreateFormValues>
>;

export function useOrganizationCreateFormValues() {
  const t = useT();

  return useMemo(
    () =>
      type({
        urlOrDomain: message(
          UrlOrDomainStruct,
          t("useOrganizationCreateFormValues_Error_UrlOrDomain"),
        ),
        name: message(
          nonempty(string()),
          t("useOrganizationCreateFormValues_Error_Name"),
        ),
        hubspotCompanyId: string(),
        isHubspotEnabled: boolean(),
        domain: nonempty(string()), // hidden field
        website: nonempty(string()), // hidden field
      }),
    [t],
  );
}
