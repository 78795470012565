import { enums, Infer } from "superstruct";

export const TENDER_ORDER_BY_OPTIONS = [
  "CREATED_AT",
  "UPDATED_AT",
  "PUBLISHED_AT",
  "BUDGET",
] as const;

export const TenderOrderByStruct = enums(TENDER_ORDER_BY_OPTIONS);

export type TenderOrderBy = Infer<typeof TenderOrderByStruct>;
