import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Avatar,
  Box,
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useStore } from "zustand";
import { useT } from "../../Translation/hooks/useT";
import { useFirebaseTableFilterStore } from "../hooks/useFirebaseTableFilters";
import { FirebaseFilterMenuButtonContentView } from "./FirebaseFilterMenuButtonContentView";
import { FirebaseTableFilterMenuSelectedFilterView } from "./FirebaseTableFilterMenuSelectedFilterView";
import { FirebaseTableFilterMenuView } from "./FirebaseTableFilterMenuView";
import { FirebaseTableFilterTextView } from "./FirebaseTableFilterTextView";

export const FirebaseTableFilterView: FC = () => {
  const t = useT();
  const filterStore = useFirebaseTableFilterStore();
  const { filterNames, searchFilterName } = filterStore.getState();
  const activeFilterCount = useStore(filterStore, (state) =>
    Object.values(state.filters).reduce((count, filter) => {
      if ("isSearch" in filter && filter.isSearch) {
        return count;
      }
      if (filter.isActive) {
        return count + 1;
      }
      return count;
    }, 0),
  );

  const [selectedFilterName, setSelectedFilterName] = useState(filterNames[0]);
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const isOpen = Boolean(filterMenuAnchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setFilterMenuAnchorEl(null);
  };

  return (
    <Stack
      p={3}
      borderBottom={1}
      borderColor="divider"
      direction="row"
      alignItems="center"
      spacing={2}
    >
      {searchFilterName && (
        <FirebaseTableFilterTextView
          size="small"
          filterName={searchFilterName}
          placeholder={t("FirebaseTableFilterView_Label_Search")}
          hasClearButton
        />
      )}
      {filterNames.length > 0 && (
        <Stack spacing={3}>
          <Button
            onClick={handleClick}
            size="small"
            startIcon={<FilterAltIcon />}
            endIcon={
              !!activeFilterCount && (
                <Avatar
                  sx={(theme) => ({
                    bgcolor: theme.palette.primary.main,
                    fontSize: `${theme.typography.subtitle2.fontSize}px !important`,
                    width: 20,
                    height: 20,
                  })}
                >
                  {activeFilterCount}
                </Avatar>
              )
            }
            sx={{ minWidth: 0, justifyContent: "space-around" }}
            variant="outlined"
            aria-controls={isOpen ? "filter-menu" : undefined}
            aria-expanded={isOpen ? "true" : undefined}
            aria-haspopup="true"
          >
            {t("FirebaseTableFilterView_Button_Filters")}
          </Button>

          <FirebaseTableFilterMenuView
            anchorEl={filterMenuAnchorEl}
            open={isOpen}
            onClose={handleClose}
          >
            <Stack direction="row">
              <Stack spacing={2} p={2}>
                <Typography variant="h4" p={2} pt={1}>
                  {/* TODO FIXME: for some reason the first letter of the text label, i.e. "filter" 
                      catches the focus when the first letter for the word is pressed, i.e. "f". This
                      hack below fixes the problem for some reason (prefixing with a character).
                  */}
                  <Box component="span" sx={{ color: "transparent", ml: -1 }}>
                    |
                  </Box>
                  {t("FirebaseTableFilterView_Title_Filters")}
                </Typography>

                <Stack spacing={3} height="100%" justifyContent="space-between">
                  <ToggleButtonGroup
                    fullWidth
                    exclusive
                    orientation="vertical"
                    value={selectedFilterName}
                    onChange={(e, selectedFilterName: string | null) => {
                      if (selectedFilterName) {
                        setSelectedFilterName(selectedFilterName);
                      }
                    }}
                    sx={(theme) => ({
                      "& .MuiToggleButtonGroup-grouped": {
                        border: 0,
                        "&.Mui-disabled": {
                          border: 0,
                        },
                        "&:not(:first-of-type)": {
                          marginTop: theme.spacing(2),
                          borderRadius: 1,
                        },
                        "&:first-of-type": {
                          borderRadius: 1,
                        },
                      },
                    })}
                  >
                    {filterNames.map((filterName) => {
                      const isSelected = filterName === selectedFilterName;
                      const { isDisabled } =
                        filterStore.getState().filters[filterName];
                      return (
                        <ToggleButton
                          key={filterName}
                          value={filterName}
                          disabled={isDisabled}
                          size="small"
                          sx={{
                            minHeight: 38,
                            minWidth: 200,
                            justifyContent: "space-between",
                            textTransform: "none",
                            border: "none",
                            backgroundColor: isSelected
                              ? "grey.100"
                              : "inherit",
                            color: "text.primary",
                            ".MuiSvgIcon-root": {
                              width: isSelected ? undefined : 0,
                            },
                            "&:hover .MuiSvgIcon-root": {
                              width: "unset",
                            },
                          }}
                        >
                          <FirebaseFilterMenuButtonContentView
                            filterName={filterName}
                          />
                        </ToggleButton>
                      );
                    })}
                  </ToggleButtonGroup>

                  <Button
                    size="small"
                    disabled={!activeFilterCount}
                    sx={{
                      color: "text.secondary",
                      justifyContent: "flex-start",
                    }}
                    startIcon={<ClearIcon />}
                    onClick={() => {
                      filterStore.getState().unsetAllFilters();
                    }}
                  >
                    {t("FirebaseTableFilterView_Button_ClearAllFilters")}
                  </Button>
                </Stack>
              </Stack>

              <Stack spacing={2} p={2} borderLeft={1} borderColor="divider">
                {selectedFilterName && (
                  <FirebaseTableFilterMenuSelectedFilterView
                    filterName={selectedFilterName}
                  />
                )}
              </Stack>
            </Stack>
          </FirebaseTableFilterMenuView>
        </Stack>
      )}
    </Stack>
  );
};
