import { TranslationKey } from "../../Translation/types/TranslationKey";
import { CallReprocessRequestKind } from "../types/CallReprocessRequestKind";

interface ProjectRegenerateCallsKindRecordValue {
  label: TranslationKey;
  type: CallReprocessRequestKind;
}

export const CALL_REPROCESS_REQUEST_KIND_RECORD: Record<
  CallReprocessRequestKind,
  ProjectRegenerateCallsKindRecordValue
> = {
  VOICEFILE: {
    label: "ProjectDetailsRegenerateCallsView_RegenerateKind_Voicefile_Label",
    type: "VOICEFILE",
  },
  TRANSCRIPTS: {
    label: "ProjectDetailsRegenerateCallsView_RegenerateKind_Transcripts_Label",
    type: "TRANSCRIPTS",
  },
  SUMMARY: {
    label: "ProjectDetailsRegenerateCallsView_RegenerateKind_Summary_Label",
    type: "SUMMARY",
  },
  QUESTION_ANSWERS: {
    label:
      "ProjectDetailsRegenerateCallsView_RegenerateKind_QuestionAnswers_Label",
    type: "QUESTION_ANSWERS",
  },
  CATEGORY: {
    label: "ProjectDetailsRegenerateCallsView_RegenerateKind_Category_Label",
    type: "CATEGORY",
  },
};
