import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { Invitation, isInvitationLog } from "@snubes/snubes-types";
import { FC, useState } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { InvitationReplyButton } from "../../Invitations/views/InvitationReplyButton";
import { LogListItemViewForInvitation } from "../../Logs/views/LogListItemViewForInvitation";
import { LogsButtonView } from "../../Logs/views/LogsButtonView";
import { useT } from "../../Translation/hooks/useT";
import { NotificationListItemView } from "./NotificationListItemView";

interface Props {
  invitation: Invitation;
  isMenuItem?: boolean;
}

export const NotificationListItemForInvitationView: FC<Props> = (props) => {
  const { invitation, isMenuItem } = props;
  const [isLoading, setIsLoading] = useState(false);
  const hasPermission = useHasPermission();
  const t = useT();

  return (
    <NotificationListItemView
      Icon={AdminPanelSettingsIcon}
      color="primary"
      caption={t(
        "NotificationListItemForInvitationView_Caption_OrganizationInvite",
      )}
      title={t("NotificationListItemForInvitationView_Title_InvitedBy", {
        organizationName: invitation.organizationName,
        invitedByUserName: invitation.invitedByUserName,
      })}
      to="/notifications/my-invitations"
      createdAt={invitation.createdAt}
      hasBeenRead={false}
      taskStatus="OPEN"
      isMenuItem={isMenuItem}
      actions={
        <>
          <InvitationReplyButton
            reply="decline"
            invitationId={invitation.id}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
          <InvitationReplyButton
            reply="accept"
            invitationId={invitation.id}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
          {hasPermission("CAN_READ_LOGS") && (
            <LogsButtonView
              collectionName={`invitations/${invitation.id}/logs`}
              LogListItemView={LogListItemViewForInvitation}
              isT={isInvitationLog}
            />
          )}
        </>
      }
    />
  );
};
