import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FaxOutlinedIcon from "@mui/icons-material/FaxOutlined";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ChannelType } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface TenderChannelType {
  label: TranslationKey;
  volumeLabel: TranslationKey;
  volumeAdornment: TranslationKey;
  Icon?: OverridableComponent<SvgIconTypeMap>;
}

export const TENDER_CHANNEL_TYPE: Record<ChannelType, TenderChannelType> = {
  PHONE: {
    label: "TenderChannelType_Label_PHONE",
    volumeLabel: "TenderChannelType_VolumeLabel_PHONE",
    volumeAdornment: "TenderChannelType_VolumeAdornment_PHONE",
    Icon: PhoneOutlinedIcon,
  },
  EMAIL: {
    label: "TenderChannelType_Label_EMAIL",
    volumeLabel: "TenderChannelType_VolumeLabel_EMAIL",
    volumeAdornment: "TenderChannelType_VolumeAdornment_EMAIL",
    Icon: EmailOutlinedIcon,
  },
  MAIL: {
    label: "TenderChannelType_Label_MAIL",
    volumeLabel: "TenderChannelType_VolumeLabel_MAIL",
    volumeAdornment: "TenderChannelType_VolumeAdornment_MAIL",
    Icon: MarkunreadMailboxOutlinedIcon,
  },
  WEB_CHAT: {
    label: "TenderChannelType_Label_WEB_CHAT",
    volumeLabel: "TenderChannelType_VolumeLabel_WEB_CHAT",
    volumeAdornment: "TenderChannelType_VolumeAdornment_WEB_CHAT",
    Icon: ChatBubbleOutlineOutlinedIcon,
  },
  FAX: {
    label: "TenderChannelType_Label_FAX",
    volumeLabel: "TenderChannelType_VolumeLabel_FAX",
    volumeAdornment: "TenderChannelType_VolumeAdornment_FAX",
    Icon: FaxOutlinedIcon,
  },
};
