import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { FC, SyntheticEvent, useCallback, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { deleteConnectorConfigCallable } from "../callables/deleteConnectorConfigCallable";

interface Props {
  organizationId: string;
  connectorConfigId: string;
  isOpen: boolean;
  close: (event: SyntheticEvent<HTMLButtonElement>) => void;
}

export const ConnectorConfigDeleteDialog: FC<Props> = (props) => {
  const t = useT();
  const { connectorConfigId, organizationId } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const deleteConnectorConfig = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await deleteConnectorConfigCallable({
        connectorConfigId,
        organizationId,
      });
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsSubmitting(false);
    }
  }, [organizationId, connectorConfigId]);

  return (
    <Dialog open={props.isOpen} onClose={props.close} fullWidth>
      <DialogTitle>
        {t("ConnectorConfigDeleteDialog_Title_DeleteConnection")}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          {t("ConnectorConfigDeleteDialog_Content_DeleteConnection")}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button disabled={isSubmitting} onClick={props.close}>
          {t("ConnectorConfigDeleteDialog_Button_Cancel")}
        </Button>
        <Button
          color="error"
          variant="contained"
          disabled={isSubmitting}
          onClick={async (event) => {
            event.preventDefault();
            event.stopPropagation();
            await deleteConnectorConfig();
          }}
        >
          {t("ConnectorConfigDeleteDialog_Button_Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
