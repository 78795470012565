import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Call } from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import { useT } from "../../Translation/hooks/useT";
import {
  CALL_DIRECTION_RECORD,
  CALL_DIRECTIONS,
} from "../consts/CALL_DIRECTION_RECORD";

interface Props {
  callDirection: Call["direction"];
  onChangeCallDirection: (callDirection: Call["direction"]) => void;
}

export const CallDirectionSelect: FC<Props> = (props) => {
  const { callDirection, onChangeCallDirection } = props;
  const t = useT();

  const handleCallDirectionChanged = useCallback(
    (event: SelectChangeEvent) => {
      onChangeCallDirection(
        (event.target.value.trim() || undefined) as Call["direction"],
      );
    },
    [onChangeCallDirection],
  );

  return (
    <FormControl
      fullWidth
      sx={{
        minWidth: 140,
        backgroundColor: "background.paper",
      }}
    >
      <InputLabel id="select-call-direction-label">
        {t("CallDirectionSelect_Label_CallDirection")}
      </InputLabel>
      <Select
        labelId="select-call-direction-label"
        label={t("CallDirectionSelect_Label_CallDirection")}
        displayEmpty
        value={callDirection || " "}
        onChange={handleCallDirectionChanged}
        size="small"
      >
        <MenuItem value=" ">{t(CALL_DIRECTION_RECORD.ANY.label)}</MenuItem>
        {CALL_DIRECTIONS.map((dir) => (
          <MenuItem key={dir} value={dir}>
            {t(CALL_DIRECTION_RECORD[dir].label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
