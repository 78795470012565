import { ChipProps, Stack, Tooltip } from "@mui/material";
import { Tender } from "@snubes/snubes-types";
import { FC } from "react";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";
import { getTenderApplications } from "../helpers/getTenderApplications";

interface Props {
  tender: Tender;
  size?: ChipProps["size"];
}

export const TenderApplicationsStatusChipsView: FC<Props> = (props) => {
  const { tender, size } = props;
  const t = useT();

  const publishedApplicationsCount = getTenderApplications(
    tender,
    "PUBLISHED",
  ).length;
  const acceptedApplicationsCount = getTenderApplications(
    tender,
    "ACCEPTED",
  ).length;
  const rejectedForResubmissionApplicationsCount = getTenderApplications(
    tender,
    "REJECTED_FOR_RESUBMISSION",
  ).length;
  const rejectedApplicationsCount = getTenderApplications(
    tender,
    "REJECTED",
  ).length;
  const allRejectedApplicationsCount =
    rejectedForResubmissionApplicationsCount + rejectedApplicationsCount;

  return (
    <Stack direction="row" spacing={1} display="inline-flex">
      <Tooltip
        title={t(
          "TenderApplicationsStatusChipsView_Tooltip_PublishedApplications",
          { count: publishedApplicationsCount },
        )}
      >
        <StatusChip
          color={publishedApplicationsCount ? "primary" : "default"}
          label={publishedApplicationsCount}
          size={size}
        />
      </Tooltip>
      <Tooltip
        title={t(
          "TenderApplicationsStatusChipsView_Tooltip_AcceptedApplications",
          { count: acceptedApplicationsCount },
        )}
      >
        <StatusChip
          color={acceptedApplicationsCount ? "secondary" : "default"}
          label={acceptedApplicationsCount}
          size={size}
        />
      </Tooltip>
      <Tooltip
        title={t(
          "TenderApplicationsStatusChipsView_Tooltip_RejectedApplications",
          { count: allRejectedApplicationsCount },
        )}
      >
        <StatusChip
          color={allRejectedApplicationsCount ? "error" : "default"}
          label={allRejectedApplicationsCount}
          size={size}
        />
      </Tooltip>
    </Stack>
  );
};
