import { Box, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ControlledDatePicker } from "../../Form/views/ControlledDatePicker";
import { ControlledSelect } from "../../Form/views/ControlledSelect";
import { useT } from "../../Translation/hooks/useT";
import {
  DEFAULT_BACKFILL_DAYS_CUSTOM_OPTION,
  DEFAULT_BACKFILL_DAYS_OPTIONS,
} from "../consts/DEFAULT_BACKFILL_DAYS_OPTIONS";
import { getBackfillDate } from "../helpers/getBackfillDate";
import { getDaysToBackfill } from "../helpers/getDaysToBackfill";

interface FormValues {
  backfillDate: string;
  backfillDays: number;
}

export function ConnectorConfigBackfillDaysFormFieldsView() {
  const { control, watch, setValue } = useFormContext<FormValues>();

  const t = useT();

  const backfillDays = watch("backfillDays");
  const backfillDate = watch("backfillDate");

  useEffect(() => {
    if (backfillDays > 0) {
      setValue("backfillDate", getBackfillDate(backfillDays));
    }
  }, [backfillDays, setValue]);

  useEffect(() => {
    const backfillDaysNext = getDaysToBackfill(backfillDate);
    if ([30, 90, 180].includes(backfillDaysNext)) {
      setValue("backfillDays", backfillDaysNext);
    } else {
      setValue("backfillDays", DEFAULT_BACKFILL_DAYS_CUSTOM_OPTION);
    }
  }, [backfillDate, setValue]);

  const backfillDaysOptions = DEFAULT_BACKFILL_DAYS_OPTIONS.map((value) => ({
    value,
  }));

  return (
    <Stack direction="row" spacing={4}>
      <ControlledDatePicker
        control={control}
        name="backfillDate"
        label={t("ConnectorConfigForm_Select_BackfillDate_Label")}
        disableFuture
        fullWidth
        required
        minDate={DateTime.fromISO("1950-01-01")}
      />

      <ControlledSelect
        label={t("ConnectorConfigForm_Select_DaysToBackfill_Label")}
        control={control}
        name="backfillDays"
        items={backfillDaysOptions}
        renderItem={(item) => (
          <>
            {item.value === DEFAULT_BACKFILL_DAYS_CUSTOM_OPTION && (
              <Box component="em" sx={{ color: "text.secondary" }}>
                {backfillDays !== DEFAULT_BACKFILL_DAYS_CUSTOM_OPTION &&
                  t("ConnectorConfigForm_Select_DaysToBackfill_Label_Custom")}
                {backfillDays === DEFAULT_BACKFILL_DAYS_CUSTOM_OPTION &&
                  t("ConnectorConfigForm_Select_DaysToBackfill_Label_DaysAgo", {
                    days: getDaysToBackfill(backfillDate),
                  })}
              </Box>
            )}
            {item.value !== DEFAULT_BACKFILL_DAYS_CUSTOM_OPTION &&
              t("ConnectorConfigForm_Select_DaysToBackfill_Label_DaysAgo", {
                days: item.value,
              })}
          </>
        )}
        fullWidth
        required
      />
    </Stack>
  );
}
