import { Unstable_Grid2 as Grid } from "@mui/material";
import { Member } from "@snubes/snubes-types";
import { FC } from "react";
import { useIsFeatureFlagEnabled } from "../../Admin/hooks/useIsFeatureFlagEnabled";
import { FullHeightStack } from "../../Common/views/FullHeightStack";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useSetUserType } from "../../Users/hooks/useSetUserType";
import { useLatestDraftTender } from "../hooks/useLatestDraftTender";
import { HomeApplicationCardView } from "./HomeApplicationCardView";
import { HomeGetStartedCardView } from "./HomeGetStartedCardView";
import { HomeGetStartedView } from "./HomeGetStartedView";
import { HomeInviteMembersCardView } from "./HomeInviteMembersCardView";
import { HomeKeyFeaturesView } from "./HomeKeyFeaturesView";
import { HomeLaunchCardView } from "./HomeLaunchCardView";
import { HomeOtherApplicationsView } from "./HomeOtherApplicationsView";
import { HomeWelcomeCardView } from "./HomeWelcomeCardView";

interface Props {
  member: Member;
}

export const ClientHomeOnboardingView: FC<Props> = (props) => {
  const { member } = props;
  const [setUserType] = useSetUserType();
  const organizationId = useSelectedOrganization((state) => state?.id || null);
  const tender = useLatestDraftTender({
    organizationId,
  });
  const tenderButtonHref = tender ? `/tenders/${tender.id}` : "/tenders/create";
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled();
  const isOnboardingDemosEnabled = isFeatureFlagEnabled("ONBOARDING_DEMOS");

  const isTeamMemberInvited = !!member?.tasks?.isTeamMemberInvited;
  const isTenderCreated = !!member?.tasks?.isTenderCreated;

  return (
    <FullHeightStack>
      <HomeGetStartedView>
        <Grid sm={12} md={12} lg={4}>
          <HomeWelcomeCardView
            titleText="ClientHomePage_Started_Headline"
            subtitleText="ClientHomePage_Started_Subheadline"
            steps={[
              {
                isChecked: true,
                text: "ClientHomePage_Step_CreateAccount",
              },
              {
                isChecked: isTeamMemberInvited,
                text: "ClientHomePage_Step_InviteTeam",
              },
              {
                isChecked: isTenderCreated,
                text: "ClientHomePage_Step_CreateTender",
              },
            ]}
          />
        </Grid>
        <Grid sm={12} md={6} lg={4}>
          <HomeGetStartedCardView
            titleText="ClientHomePage_Card_Tender_Title"
            subtitleText1="ClientHomePage_Card_Tender_Subtitle1"
            subtitleText2="ClientHomePage_Card_Tender_Subtitle2"
            buttonText="ClientHomePage_Card_Tender_Button"
            buttonHref={tenderButtonHref}
            buttonDisabled={isTenderCreated}
          />
        </Grid>
        <Grid sm={12} md={6} lg={4}>
          <HomeInviteMembersCardView
            member={member}
            titleText="ClientHomePage_Card_Team_Title"
            subtitleText1="ClientHomePage_Card_Team_Subtitle1"
            subtitleText2="ClientHomePage_Card_Team_Subtitle2"
            buttonTextPrimary="ClientHomePage_Card_Team_Button1"
            buttonTextSecondary="ClientHomePage_Card_Team_Button2"
          />
        </Grid>
      </HomeGetStartedView>

      {isOnboardingDemosEnabled && (
        <HomeKeyFeaturesView>
          <HomeLaunchCardView
            titleText="ClientHomePage_Card_Demo_Tender_Title"
            subtitleText="ClientHomePage_Card_Demo_Tender_Subtitle"
            buttonText="ClientHomePage_Card_Demo_Tender_Button"
          />
        </HomeKeyFeaturesView>
      )}

      <HomeOtherApplicationsView sx={{ flex: 1 }}>
        <HomeApplicationCardView
          bgcolor="primary.50"
          subtitleText="ClientHomePage_Subtitle_ForCallCenters"
          titleText="ClientHomePage_Heading_FindNewProject"
          buttonText="ClientHomePage_Button_LearnMore"
          buttonHref="/home/provider"
          buttonOnClick={() => setUserType("PROVIDER")}
          imgSrc="/assets/home-provider.png"
          imgBgcolor="secondary.50"
        />
        <HomeApplicationCardView
          bgcolor="secondary.50"
          subtitleText="ClientHomePage_Subtitle_ForQualityMonitoring"
          titleText="ClientHomePage_Heading_AnalyzeGetInsights"
          buttonText="ClientHomePage_Button_LearnMore"
          buttonHref="/home/qm"
          buttonOnClick={() => setUserType("QM")}
          imgSrc="/assets/home-qm.png"
          imgBgcolor="error.50"
        />
      </HomeOtherApplicationsView>
    </FullHeightStack>
  );
};
