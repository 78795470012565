import { CircularProgress, Typography } from "@mui/material";
import { useStore } from "zustand";
import { useT } from "../../Translation/hooks/useT";
import { WizardStore } from "../types/WizardStore";

interface Props<TState> {
  store: WizardStore<TState>;
}

export function WizardSavingIndicatorView<TState>(props: Props<TState>) {
  const { store } = props;

  const isDirty = useStore(store, (state) => state.isDirty);
  const isSaving = useStore(store, (state) => state.isSaving);
  const hasFailedSaving = useStore(store, (state) => state.hasFailedSaving);
  const t = useT();

  const color = isSaving
    ? "primary.main"
    : hasFailedSaving
    ? "error.main"
    : "text.500";

  const status = isSaving
    ? t("WizardSavingIndicatorView_Title_Saving")
    : hasFailedSaving
    ? t("WizardSavingIndicatorView_Title_SavingFailed")
    : isDirty
    ? t("WizardSavingIndicatorView_Title_Unsaved")
    : t("WizardSavingIndicatorView_Title_Saved");

  return (
    <>
      {isSaving && <CircularProgress size={12} color="primary" />}
      <Typography variant="caption1" ml={2.5} color={color}>
        {status}
      </Typography>
    </>
  );
}
