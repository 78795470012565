import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Alert, IconButton, Stack, Typography } from "@mui/material";
import { isUserLog } from "@snubes/snubes-types";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { ENVIRONMENT } from "../../Application/consts/ENVIRONMENT";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { ExternalLinkView } from "../../Common/views/ExternalLinkView";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LoadingView } from "../../Common/views/LoadingView";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { LogListItemViewForUser } from "../../Logs/views/LogListItemViewForUser";
import { LogsButtonView } from "../../Logs/views/LogsButtonView";
import { useT } from "../../Translation/hooks/useT";
import { getUserName } from "../helpers/getUserName";
import { useUser } from "../hooks/useUser";
import { UserDeleteDialog } from "./UserDeleteDialog";
import { UserEditForm } from "./UserEditForm";
import { UserEmailPasswordView } from "./UserEmailPasswordView";
import { UserOrganizationsView } from "./UserOrganizationsView";

export const UserPage: FC = () => {
  const params = useParams<"userId">();
  const hasPermission = useHasPermission();
  const [user, isLoading, error] = useUser(params.userId);
  const [isUserDeleteDialogOpen, setIsUserDeleteDialogOpen] = useState(false);
  const t = useT();

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer maxWidth="md">
        <Stack py={5} spacing={3}>
          {isLoading && <LoadingView />}
          {error && <Alert severity="error">{error.message}</Alert>}
          {user && (
            <>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h2">{getUserName(user)}</Typography>
                <Stack direction="row" alignItems="center">
                  {user.hubspotContactId && (
                    <ExternalLinkView
                      variant="button"
                      href={`${ENVIRONMENT.hubspot.contactBaseUrl}/${user.hubspotContactId}`}
                      tooltip={t("UserPage_Tooltip_ViewInHubspot", {
                        id: user.hubspotContactId,
                      })}
                    >
                      {t("UserPage_Button_ViewInHubspot")}
                    </ExternalLinkView>
                  )}
                  {hasPermission("CAN_READ_LOGS") && (
                    <LogsButtonView
                      collectionName={`users/${user.id}/logs`}
                      LogListItemView={LogListItemViewForUser}
                      isT={isUserLog}
                    />
                  )}
                  <IconButton
                    size="large"
                    onClick={() => {
                      setIsUserDeleteDialogOpen(true);
                    }}
                  >
                    <DeleteOutlinedIcon />
                  </IconButton>
                </Stack>

                <UserDeleteDialog
                  key={`delete-${user.id}`}
                  user={user}
                  isOpen={isUserDeleteDialogOpen}
                  close={() => {
                    setIsUserDeleteDialogOpen(false);
                  }}
                />
              </Stack>
              <UserEditForm user={user} />
              {hasPermission("CAN_WRITE_AUTH_USER", { userId: user.id }) && (
                <UserEmailPasswordView />
              )}
              {!!user.organizationIds?.length && (
                <UserOrganizationsView
                  userOrganizationIds={user.organizationIds}
                />
              )}
            </>
          )}
        </Stack>
      </FullHeightContainer>
    </>
  );
};
