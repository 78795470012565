import { TaskStatus } from "@snubes/snubes-types";
import { FC, PropsWithChildren } from "react";
import { useMyInvitations } from "../../Invitations/hooks/useMyInvitations";
import { useNotifications } from "../../Notifications/hooks/useNotifications";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { HomeCardActionButton } from "./HomeCardActionButton";
import { HomeCardCountView } from "./HomeCardCountView";
import { HomeCardView } from "./HomeCardView";

interface Props extends PropsWithChildren {
  userId?: string;
}

export const HomeCardNotificationsView: FC<Props> = (props) => {
  const { userId = null } = props;

  const t = useT();
  const organizationId = useSelectedOrganization((state) => state?.id || null);
  const maxNotificationsCount = 3;
  const [invitations] = useMyInvitations();
  const [notifications] = useNotifications({
    userId,
    organizationId,
    filters: [["status", "==", "OPEN" satisfies TaskStatus]],
    limit: maxNotificationsCount + 1,
  });

  const totalNotificationsCount = invitations.length + notifications.length;

  return (
    <HomeCardView
      title={t("HomeCardNotificationsView_Title_Notifications")}
      actions={
        <HomeCardActionButton
          title={t("HomeCardNotificationsView_Button_SeeAll")}
          href="/notifications/all"
        />
      }
    >
      <HomeCardCountView
        count={totalNotificationsCount}
        maxCount={maxNotificationsCount}
      />{" "}
      {t("HomeCardNotificationsView_Label_Notifications")}
    </HomeCardView>
  );
};
