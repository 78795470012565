import PsychologyIcon from "@mui/icons-material/Psychology";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import { Container, Stack, Typography } from "@mui/material";
import { IndustryStruct } from "@snubes/snubes-types";
import { FC, useEffect } from "react";
import { array, boolean, nullable, type } from "superstruct";
import { ControlledIndustriesAutocomplete } from "../../Form/views/ControlledIndustriesAutocomplete";
import { ControlledRadioButtons } from "../../Form/views/ControlledRadioButtons";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  isUsingAll: nullable(boolean()),
  industries: array(IndustryStruct),
});

export const TenderWizardIndustriesForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit, watch, setValue } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => {
      const isUsingAll = tender?.industries
        ? tender?.industries.length === 0 // all/specific industries
        : null; // no selection

      return {
        isUsingAll,
        industries: tender?.industries || [],
        isReviewed: {
          ...tender?.isReviewed,
          operationalDetails: false,
        },
      };
    },
    toDoc: (formValues) => ({
      industries: !formValues.isUsingAll ? formValues.industries : [],
    }),
  });

  const isUsingAll = watch("isUsingAll");

  useEffect(() => {
    if (!isUsingAll) return;
    setValue("industries", []);
  }, [isUsingAll, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardIndustriesForm_Title")}
        </Typography>
        <Typography variant="body2" color="text.500" mb={4}>
          {t("TenderWizardIndustriesForm_Label")}
        </Typography>
        <Stack spacing={3}>
          <ControlledRadioButtons
            control={control}
            name="isUsingAll"
            options={[
              {
                Icon: PsychologyAltOutlinedIcon,
                label: "TenderWizardIndustriesForm_Label_AllIndustries",
                value: true,
                caption: "TenderWizardIndustriesForm_Caption_AllIndustries",
              },
              {
                Icon: PsychologyIcon,
                label: "TenderWizardIndustriesForm_Label_SpecificIndustries",
                value: false,
                caption:
                  "TenderWizardIndustriesForm_Caption_SpecificIndustries",
              },
            ]}
            gridSpacing={4}
            gridItemXs={6}
          />

          {isUsingAll === false && (
            <ControlledIndustriesAutocomplete
              control={control}
              name="industries"
              label={t(
                "TenderWizardIndustriesForm_Label_IndustriesAutocomplete",
              )}
              fullWidth
              multiple
              required
            />
          )}
        </Stack>
      </Container>
    </form>
  );
};
