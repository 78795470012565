import { Control, FieldPath, FieldValues } from "react-hook-form";
import { TIME_ZONES_NAMES } from "../../Tenders/consts/TIME_ZONES_NAMES";
import { getTimeZoneByName } from "../../Tenders/helpers/getTimeZoneByName";
import { useT } from "../../Translation/hooks/useT";
import { ControlledAutocomplete } from "./ControlledAutocomplete";

interface Props<TFields extends FieldValues> {
  control: Control<TFields>;
  name: FieldPath<TFields>;
  required?: boolean;
  fullWidth?: boolean;
}

export function ControlledAutocompleteTimeZone<TFields extends FieldValues>(
  props: Props<TFields>,
) {
  const t = useT();

  return (
    <ControlledAutocomplete
      control={props.control}
      name={props.name}
      required={props.required}
      fullWidth={props.fullWidth}
      label={t("ControlledAutocompleteTimeZone_Label")}
      options={TIME_ZONES_NAMES}
      getOptionLabel={(tzName) => getTimeZoneByName(tzName) || tzName}
    />
  );
}
