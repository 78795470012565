import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { UseFormGetValues } from "react-hook-form";
import toast from "react-hot-toast";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { ConnectorConfigSftpEditFormValues } from "./ConnectorConfigSftpEditForm";

interface Props {
  isFormDirty: boolean;
  connectorConfigSftpGetValues: UseFormGetValues<ConnectorConfigSftpEditFormValues>;
}

export const ConnectorConfigSftpFormHeaderView: FC<Props> = (props) => {
  const t = useT();
  const hasPermission = useHasPermission();
  const { connectorConfigSftpGetValues, isFormDirty } = props;

  const onClickCopySftpConnectionToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(
        JSON.stringify(connectorConfigSftpGetValues()),
      );

      toast.success(
        t(
          "ConnectorConfigSftpFormHeaderView_Toast_CopiedSftpConnectionToClipboard",
        ),
      );
    } catch (error) {
      handleError(error).log();
    }
  }, [connectorConfigSftpGetValues, t]);

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between">
      <Typography variant="h3">
        {t("ConnectorConfigSftpEditForm_Heading_UpdateConnection")}
      </Typography>
      {hasPermission("CAN_COPY_SFTP_CONNECTION") && (
        <Stack direction="row" spacing={1} alignItems="center">
          <Tooltip
            title={t(
              isFormDirty
                ? "ConnectorConfigSftpFormHeaderView_Tooltip_DisabledCopySftpConnectionToClipboard"
                : "ConnectorConfigSftpFormHeaderView_Tooltip_CopySftpConnectionToClipboard",
            )}
          >
            <Box>
              <IconButton
                disabled={isFormDirty}
                onClick={onClickCopySftpConnectionToClipboard}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};
