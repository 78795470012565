import { Alert, Button, Stack, Typography } from "@mui/material";
import { isProviderLog } from "@snubes/snubes-types";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LinkView } from "../../Common/views/LinkView";
import { LoadingView } from "../../Common/views/LoadingView";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { LogListItemViewForProvider } from "../../Logs/views/LogListItemViewForProvider";
import { LogsButtonView } from "../../Logs/views/LogsButtonView";
import { TendersBrowseSetupView } from "../../Tenders/views/TendersBrowseSetupView";
import { useT } from "../../Translation/hooks/useT";
import { useProvider } from "../hooks/useProvider";
import { ProviderDetailsView } from "./ProviderDetailsView";

export const ProviderDetailsPage: FC = () => {
  const { providerId } = useParams();
  const [provider, isLoading, error] = useProvider(providerId);
  const hasPermission = useHasPermission();
  const t = useT();

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer>
        <Stack py={5} spacing={3} height="100%">
          {isLoading && <LoadingView />}
          {error && <Alert severity="error">{error.message}</Alert>}
          {provider && (
            <>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h2">
                  {provider.organizationName}
                </Typography>

                {/* TODO: make this row responsive */}
                <Stack direction="row" spacing={1}>
                  <LinkView
                    variant="button"
                    to={`/organizations/${provider.id}`}
                  >
                    {t("ProviderDetailsPage_Link_OrganizationProfile")}
                  </LinkView>
                  {hasPermission("CAN_READ_LOGS") && (
                    <LogsButtonView
                      collectionName={`providers/${provider.id}/logs`}
                      LogListItemView={LogListItemViewForProvider}
                      isT={isProviderLog}
                    />
                  )}
                </Stack>
              </Stack>

              <ProviderDetailsView provider={provider} />

              <Button
                variant="text"
                href={`/providers/${provider.id}/create`}
                sx={{ maxWidth: 200, alignSelf: "center" }}
              >
                {t("ProviderDetailsView_Button_EditProviderProfile")}
              </Button>
            </>
          )}
          {!isLoading && !error && !provider && <TendersBrowseSetupView />}
        </Stack>
      </FullHeightContainer>
    </>
  );
};
