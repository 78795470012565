import BoltIcon from "@mui/icons-material/Bolt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ConnectorConfig } from "@snubes/snubes-types";
import { FC, useCallback, useState } from "react";
import { toast } from "react-hot-toast";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { generateConnectorConfigWebhookUrlCallable } from "../callables/generateConnectorConfigWebhookUrlCallable";

interface Props {
  connectorConfig: ConnectorConfig;
}

export const ConnectorConfigWebhookView: FC<Props> = (props) => {
  const { connectorConfig } = props;
  const t = useT();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const generateWebhook = useCallback(async () => {
    try {
      setIsLoading(true);
      await generateConnectorConfigWebhookUrlCallable({
        connectorConfigId: connectorConfig.id,
        connectorPlatform: connectorConfig.platform,
      });
      setIsOpen(false);
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsLoading(false);
    }
  }, [connectorConfig.id, connectorConfig.platform]);

  const onClickGenerate = useCallback(() => {
    if (connectorConfig.webhookUrl) {
      setIsOpen(true);
    } else {
      void generateWebhook();
    }
  }, [connectorConfig.webhookUrl, generateWebhook]);

  const onClickCopy = useCallback(async () => {
    if (!connectorConfig.webhookUrl) return;

    await navigator.clipboard.writeText(connectorConfig.webhookUrl);
    toast.success(
      t("ConnectorConfigGenricWebhookEditForm_Toast_CopiedToClipboard"),
    );
  }, [connectorConfig.webhookUrl, t]);

  return (
    <>
      <Stack component={Paper} variant="outlined" p={3} spacing={3}>
        <Typography variant="h5">
          {t("ConnectorConfigWebhookView_Title_GenerateWebhook")}
        </Typography>
        <Stack
          component={Paper}
          direction="row"
          variant="outlined"
          bgcolor="background.default"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          p={3}
        >
          {!!connectorConfig.webhookUrl && (
            <Typography>{connectorConfig.webhookUrl}</Typography>
          )}
          {!connectorConfig.webhookUrl && (
            <>
              <InfoOutlinedIcon sx={{ color: "text.500" }} />
              <Typography variant="caption1" color="text.500">
                {t("ConnectorConfigWebhookView_Label_MissingWebhook")}
              </Typography>
            </>
          )}
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            disabled={isLoading}
            startIcon={<BoltIcon />}
            onClick={onClickGenerate}
            fullWidth
          >
            {connectorConfig.webhookUrl
              ? t("ConnectorConfigWebhookView_Button_RegenerateWebhook")
              : t("ConnectorConfigWebhookView_Button_GenerateWebhook")}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            disabled={isLoading || !connectorConfig.webhookUrl}
            startIcon={<ContentCopyIcon />}
            onClick={onClickCopy}
            fullWidth
          >
            {t("ConnectorConfigWebhookView_Button_CopyWebhook")}
          </Button>
        </Stack>
      </Stack>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          {t("ConnectorConfigWebhookView_ModalTitle_RegenerateWebhook")}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            {t("ConnectorConfigWebhookView_ModalDescription_RegenerateWebhook")}
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button disabled={isLoading} onClick={() => setIsOpen(false)}>
            {t("Common_Close")}
          </Button>
          <Button
            variant="contained"
            onClick={generateWebhook}
            disabled={isLoading}
          >
            {t("ConnectorConfigWebhookView_ModalButton_RegenerateWebhook")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
