import { Alert, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { FirebaseFilter, Path } from "@snubes/snubes-types";
import { useMemo } from "react";
import { DEFAULT_COLLECTION_ROWS_PER_PAGE } from "../consts/DEFAULT_COLLECTION_ROWS_PER_PAGE";
import { useFirebaseTableFilterState } from "../hooks/useFirebaseTableFilters";
import { usePaginatedCollection } from "../hooks/usePaginatedCollection";
import { CollectionTableColumn } from "../types/CollectionTableColumn";
import { CollectionTableWithCheckboxesProps } from "../types/CollectionTableWithCheckboxesProps";
import { CollectionViewProps } from "../types/CollectionViewProps";
import { CollectionEmptyView } from "./CollectionEmptyView";
import { FirebaseTableView } from "./FirebaseTableView";

export type CollectionTableViewProps<T> = CollectionTableWithCheckboxesProps<
  T,
  CollectionViewProps<
    T,
    {
      getTo?: (row: T) => string;
      onClickRow?: (row: T) => void;
      getGroupTitle?: (row: T) => string;
      getNormalizedRow?: (row: T) => T;
      columns: CollectionTableColumn<T>[];
      hasCheckboxes?: boolean;
      onRowSelectionChanged?: (selectedRowIds: string[]) => void;
      getRowId?: (row: T) => string;
    }
  >
>;

export function CollectionTableView<T>(props: CollectionTableViewProps<T>) {
  const rowsPerPage =
    props.rowsPerPageOptions?.[0] || DEFAULT_COLLECTION_ROWS_PER_PAGE;

  const activeFilters = useFirebaseTableFilterState((state) =>
    Object.entries(state.filters).filter(([, filter]) => filter.isActive),
  );
  const mergedFilters = useMemo(() => {
    if (!activeFilters?.length) {
      return props.filters;
    }
    return [
      ...(props.filters || []), // TODO: maybe handle duplicate filter fields
      ...activeFilters.map(
        ([name, filter]) =>
          [
            name as Path<T>, // TODO: find a way to type this
            filter.operator,
            filter.value,
          ] satisfies FirebaseFilter<T>,
      ),
    ] as FirebaseFilter<T>[];
  }, [activeFilters, props.filters]);

  const { rows, isLoading, error, pagination } = usePaginatedCollection({
    ...props,
    filters: mergedFilters,
    rowsPerPage,
  });

  const showLoading = isLoading && !rows.length && !error && !activeFilters;
  const showEmpty = !error && !rows.length && !isLoading && !activeFilters;
  const showTable = (!error && rows.length > 0) || (!error && activeFilters);

  return (
    <Stack flex={1}>
      {showLoading && (
        <Stack p={4} alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      {error && <Alert severity="error">{error.message}</Alert>}
      {showEmpty && <CollectionEmptyView />}
      {showTable && (
        <FirebaseTableView
          {...props}
          pagination={pagination}
          rows={rows}
          isLoading={isLoading}
        />
      )}
    </Stack>
  );
}
