import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { auth } from "../../Firebase/consts/FIREBASE";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useT } from "../../Translation/hooks/useT";
import { InvitationsCollectionListView } from "./InvitationsCollectionListView";

export const InvitationsPage: FC = () => {
  const t = useT();
  const [authState] = useAuthState(auth);

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer>
        <Stack py={5} spacing={3}>
          <Typography variant="h3">{t("Invitations_Headline")}</Typography>
          {authState?.email && (
            <InvitationsCollectionListView email={authState.email} />
          )}
        </Stack>
      </FullHeightContainer>
    </>
  );
};
