import { Project } from "@snubes/snubes-types";

export function getProjectOrganizationIds(project: Project | undefined) {
  if (!project) {
    return [];
  }
  return [
    ...new Set([
      project.providerOrganizationId,
      ...(project.clientOrganizationId ? [project.clientOrganizationId] : []),
    ]),
  ];
}
