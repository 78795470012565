import { GlobalStyles } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FC } from "react";

interface Props {
  background: "PAPER" | "GRADIENT";
}

export const BodyConfig: FC<Props> = (props) => {
  const { palette } = useTheme();

  switch (props.background) {
    case "PAPER":
      return (
        <GlobalStyles
          styles={{
            body: {
              backgroundColor: palette.background.paper,
            },
          }}
        />
      );
    case "GRADIENT":
      return (
        <GlobalStyles
          styles={{
            body: {
              backgroundSize: "cover",
              backgroundAttachment: "fixed",
              backgroundRepeat: "no-repeat",
              backgroundImage: "url('/assets/home-gradient-background.svg')",
            },
          }}
        />
      );
  }
};
