import { Container, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { boolean, Describe, type } from "superstruct";
import { ControlledWizardReviewButton } from "../../Form/views/ControlledWizardReviewButton";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { WizardReviewStepView } from "../../Wizard/views/WizardReviewStepView";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";
import { TenderReviewKey } from "../types/TenderReviewKey";
import { TenderWizardStep } from "../types/TenderWizardStep";

type TenderWizardStepWithReviewKey = TenderWizardStep & {
  reviewKey: TenderReviewKey;
};

const FormValuesStruct = type({
  basicDetails: boolean(),
  services: boolean(),
  operationalDetails: boolean(),
  uploadDocuments: boolean(),
  providers: boolean(),
}) satisfies Describe<Record<TenderReviewKey, boolean>>;

type Props = WizardFormProps<{
  getSteps: () => TenderWizardStep[];
}>;

export const TenderWizardReviewForm: FC<Props> = (props) => {
  const t = useT();
  const steps = props.formProps.getSteps();

  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => {
      const isReviewed = tender?.isReviewed;

      return {
        basicDetails: !!isReviewed?.basicDetails,
        services: !!isReviewed?.services,
        operationalDetails: !!isReviewed?.operationalDetails,
        uploadDocuments: !!isReviewed?.uploadDocuments,
        providers: !!isReviewed?.providers,
      };
    },
    toDoc: (formValues) => ({
      isReviewed: formValues,
    }),
  });

  // We remove the last step, which is the review step itself.
  const stepsToReview = steps.slice(0, -1);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderReviewForm_Title_Main")}
        </Typography>
        <Stack mx={-3}>
          {stepsToReview
            .filter(
              (step): step is TenderWizardStepWithReviewKey =>
                !!step.reviewKey && !step.isHidden,
            )
            .map((step) => (
              <WizardReviewStepView
                key={step.pathSegment}
                step={step}
                store={useTenderWizardStore}
                button={
                  <ControlledWizardReviewButton
                    control={control}
                    name={step.reviewKey}
                    store={useTenderWizardStore}
                    step={step}
                  />
                }
              />
            ))}
        </Stack>
      </Container>
    </form>
  );
};
