import { Avatar } from "@mui/material";
import { FC, useMemo } from "react";
import { getAvatarName } from "../helpers/getAvatarName";

interface Props {
  name: string;
  size: number;
}

export const AvatarView: FC<Props> = (props) => {
  /**
   * Credits: MUI avatar example code
   */
  const backgroundColor = useMemo(() => {
    let hash = 0;
    let i;

    for (i = 0; i < props.name.length; i += 1) {
      hash = props.name.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }, [props.name]);

  return (
    <Avatar
      sx={{
        width: props.size,
        height: props.size,
        bgcolor: backgroundColor,
        fontSize: props.size / 2,
      }}
    >
      {getAvatarName(props.name)}
    </Avatar>
  );
};
