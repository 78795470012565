import CheckIcon from "@mui/icons-material/Check";
import { Collapse, Stack } from "@mui/material";
import { useWizardStepProgress } from "../hooks/useWizardStepProgress";
import { WizardStep } from "../types/WizardStep";
import { WizardStore } from "../types/WizardStore";

interface Props<TState> {
  step: WizardStep<TState>;
  store: WizardStore<TState>;
}

export function WizardTabLabelView<TState>(props: Props<TState>) {
  const { step, store } = props;
  const progress = useWizardStepProgress(step, store);
  const isDone = progress >= 1;

  return (
    <Stack direction="row" alignItems="center">
      <Collapse orientation="horizontal" in={isDone}>
        <CheckIcon sx={{ mr: 1.5, color: "secondary.main" }} />
      </Collapse>
      {step.title}
    </Stack>
  );
}
