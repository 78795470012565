import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC, useMemo } from "react";
import { useT } from "../../Translation/hooks/useT";
import { ProjectsController } from "../hooks/useProjectsController";
import { PROJECT_ORDER_BY_OPTIONS } from "../types/ProjectOrderBy";

interface Props {
  controller: ProjectsController;
}

export const ProjectsControllerView: FC<Props> = (props) => {
  const t = useT();
  const { controller } = props;

  const orderByOptions = useMemo(() => {
    return PROJECT_ORDER_BY_OPTIONS.map((value) => ({
      value,
      label: t(`Project_OrderBy_${value}`),
    })).sort((a, b) => a.label.localeCompare(b.label));
  }, [t]);

  return (
    <FormControl sx={{ bgcolor: "background.paper", minWidth: 200 }}>
      <InputLabel>{t("Common_OrderBy")}</InputLabel>
      <Select
        label={t("Common_OrderBy")}
        value={controller.orderBy}
        onChange={controller.onOrderByChanged}
      >
        {orderByOptions.map((orderByOption) => (
          <MenuItem key={orderByOption.value} value={orderByOption.value}>
            {orderByOption.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
