import {
  FirebaseFilter,
  isNotification,
  Notification,
} from "@snubes/snubes-types";
import { limit as limitFn, query, where } from "firebase/firestore";
import { useEffect, useMemo, useRef } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { getCollectionGroupRef } from "../../Firebase/helpers/getCollectionGroupRef";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { toQueryWhereArgs } from "../../Firebase/helpers/toQueryWhereArgs";
import { useCollectionCountOnce } from "../../Firebase/hooks/useCollectionCountOnce";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

interface BaseArgs {
  filters?: FirebaseFilter<Notification>[];
  limit?: number;
  withCount?: boolean;
}

interface ArgsWithOrganizationIdAndUserId extends BaseArgs {
  organizationId: string | null;
  userId: string | null;
}

interface ArgsWithUserId extends BaseArgs {
  userId: string | null;
}

type Args = ArgsWithOrganizationIdAndUserId | ArgsWithUserId;

export function useNotifications(args: Args) {
  const { limit, withCount } = args;
  const countRef = useRef<number | null>(null);

  const collectionRef = useMemo(() => {
    if ("organizationId" in args) {
      if (!args.organizationId) {
        return null;
      }
      return getCollectionRef(
        `organizations/${args.organizationId satisfies string}/notifications`,
      );
    } else {
      return getCollectionGroupRef("notifications");
    }
  }, [args]);

  const filters = useMemo<FirebaseFilter<Notification>[] | null>(() => {
    // When filtering by organizationId AND userId, userId may be null.
    // When filtering ONLY by userId, userId may not be null.
    if (("organizationId" in args && args.organizationId) || args.userId) {
      return [["userId", "==", args.userId], ...(args.filters || [])];
    }
    return null;
  }, [args]);

  const [notifications, isLoading, error] = useValidCollectionData(
    collectionRef && filters
      ? query(
          collectionRef,
          ...filters.map((filter) => where(...toQueryWhereArgs(filter))),
          ...(limit ? [limitFn(limit)] : []),
        )
      : null,
    isNotification,
  );

  const [totalCount = 0, , , reload] = useCollectionCountOnce(
    withCount && collectionRef && filters
      ? query(
          collectionRef,
          ...filters.map((filter) => where(...toQueryWhereArgs(filter))),
        )
      : null,
  );

  // The count query is reloaded whenever the data changes.
  useEffect(() => {
    if (
      (withCount && countRef.current !== null) ||
      countRef.current !== totalCount
    ) {
      reload().catch((error) => handleError(error).log());
    }
    countRef.current = totalCount;
  }, [totalCount, notifications, reload, withCount]);

  return [notifications, isLoading, error, totalCount] as const;
}
