import { Alert, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Tender } from "@snubes/snubes-types";
import { FC } from "react";
import { LoadingView } from "../../Common/views/LoadingView";
import { useProvidersById } from "../../Providers/hooks/useProvidersById";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  tender: Partial<Tender>;
}

export const TenderProvidersView: FC<Props> = (props) => {
  const t = useT();
  const { tender } = props;
  const matchedProviderIds = Object.keys(tender.providerApplications || {});
  const [providers, isLoading, error] = useProvidersById(matchedProviderIds);

  return (
    <List>
      {isLoading && (
        <ListItem>
          <LoadingView />
        </ListItem>
      )}
      {error && (
        <ListItem>
          <Typography color="error">{error?.message}</Typography>
        </ListItem>
      )}
      {providers?.length === 0 && tender.status === "DRAFT" && (
        <ListItem disablePadding>
          <Alert severity="info">
            {t("TenderWizardProvidersForm_Alert_ChoosingProvidersOptional")}
          </Alert>
        </ListItem>
      )}
      {providers?.map((provider) => (
        <ListItem disablePadding key={provider.id}>
          <ListItemText disableTypography>
            <Typography variant="body2">
              {provider.organizationName}{" "}
              {provider.email && `(${provider.email})`}
            </Typography>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};
