import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { isNotificationLog, Notification } from "@snubes/snubes-types";
import { FC, MouseEvent, useCallback, useState } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { handleError } from "../../Common/helpers/handleError";
import { RoundButton } from "../../Common/views/RoundButton";
import { LogListItemViewForNotification } from "../../Logs/views/LogListItemViewForNotification";
import { LogsButtonView } from "../../Logs/views/LogsButtonView";
import { useTender } from "../../Tenders/hooks/useTender";
import { useT } from "../../Translation/hooks/useT";
import { deleteNotificationCallable } from "../callables/deleteNotificationCallable";
import { TENDER_NOTIFICATION_RECORD } from "../consts/TENDER_NOTIFICATION_RECORD";
import { NotificationListItemView } from "./NotificationListItemView";

interface Props {
  notification: Notification & { type: `TENDER_${string}` };
  isMenuItem?: boolean;
  onShouldUpdateMenuPosition?: () => void;
}

export const NotificationListItemForTenderView: FC<Props> = (props) => {
  const { notification, isMenuItem, onShouldUpdateMenuPosition } = props;
  const t = useT();
  const isAssigned = !!notification.userId;
  const hasPermission = useHasPermission();
  const { Icon, title, caption, path } =
    TENDER_NOTIFICATION_RECORD[notification.type];
  const to = `/tenders/${notification.tenderId}${path}`;

  const [tender, isLoading, error] = useTender(notification.tenderId);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteNotification = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      try {
        setIsDeleting(true);

        event.preventDefault();
        event.stopPropagation();

        await deleteNotificationCallable({
          notificationId: notification.id,
        });
      } catch (error) {
        handleError(error).logAnd().toast();
      } finally {
        setIsDeleting(false);
      }
    },
    [notification.id],
  );

  return (
    <NotificationListItemView
      Icon={Icon}
      color={isAssigned ? "primary" : "error"}
      caption={t(caption, { organizationName: tender?.organizationName })}
      title={t(title, {
        tenderName: tender?.name,
        organizationName: tender?.organizationName,
      })}
      to={to}
      createdAt={notification.createdAt}
      hasBeenRead={notification.hasBeenRead}
      isMenuItem={isMenuItem}
      isLoading={isLoading}
      onShouldUpdateMenuPosition={onShouldUpdateMenuPosition}
      error={error}
      actions={
        <>
          <RoundButton
            href={to}
            variant="outlined"
            size="small"
            color="primary"
            endIcon={<ArrowOutwardIcon />}
          >
            view tender
          </RoundButton>
          {hasPermission("CAN_READ_LOGS") && (
            <LogsButtonView
              collectionName={`organizations/${notification.organizationId}/notifications/${notification.id}/logs`}
              LogListItemView={LogListItemViewForNotification}
              isT={isNotificationLog}
            />
          )}
          <IconButton
            onClick={deleteNotification}
            color="error"
            disabled={isDeleting}
          >
            <DeleteIcon />
          </IconButton>
        </>
      }
    />
  );
};
