import { useEffect, useMemo } from "react";

type UseEffectCallback = Parameters<typeof useEffect>[0];

function arrayToKey(dependencies: unknown[]): string {
  return dependencies
    .filter((dependency) => dependency !== undefined)
    .map((dependency) => {
      if (Array.isArray(dependency)) {
        return arrayToKey(dependency);
      }
      if (typeof dependency === "object" && dependency !== null) {
        return Object.entries(dependency).map(
          ([key, value]) => `${key}:${arrayToKey([value])}`,
        );
      }
      return String(dependency);
    })
    .join("-");
}

function useArrayToKeyMemoize(dependencies: unknown[]) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => arrayToKey(dependencies), dependencies);
}

/**
 * Inspired by [use-deep-compare-effect](https://github.com/kentcdodds/use-deep-compare-effect).
 */
export function useDeepCompareEffect(
  callback: UseEffectCallback,
  dependencies: unknown[],
): void {
  const key = useArrayToKeyMemoize(dependencies);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(callback, [key]);
}
