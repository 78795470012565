import { GlobalStyles, Stack } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import {
  HEADER_HEIGHT,
  SECONDARY_HEADER_HEIGHT,
} from "../../Header/consts/HEADER_HEIGHT";
import { useHeaderConfig } from "../../Header/hooks/useHeaderConfig";

export const OverlayPage: FC<PropsWithChildren> = (props) => {
  const hasSecondaryHeader = useHeaderConfig(
    (state) => state.hasSecondaryHeader,
  );
  const top = hasSecondaryHeader
    ? HEADER_HEIGHT + SECONDARY_HEADER_HEIGHT
    : HEADER_HEIGHT;

  return (
    <>
      <GlobalStyles styles={{ body: { overflow: "hidden" } }} />
      <Stack
        bgcolor="background.default"
        position="fixed"
        overflow="auto"
        left={0}
        top={top}
        right={0}
        bottom={0}
        zIndex={5}
      >
        {props.children}
      </Stack>
    </>
  );
};
