import { CollectionReference, DocumentReference } from "firebase/firestore";
import type { RefHook } from "react-firebase-hooks/firestore/dist/util";
import { isRefEqual } from "../helpers/isRefEqual";
import { useComparatorRefs } from "./useComparatorRefs";

export const useAreFirestoreRefsEqual = <
  T extends DocumentReference<unknown> | CollectionReference<unknown>,
>(
  values: T[] | null | undefined,
  onChange?: () => void,
): RefHook<T[] | null | undefined> => {
  return useComparatorRefs(values, isRefEqual, onChange);
};
