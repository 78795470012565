import HelpIcon from "@mui/icons-material/Help";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { PaperTooltipView } from "../../Common/views/PaperTooltipView";
import { RoundButton } from "../../Common/views/RoundButton";
import { useT } from "../../Translation/hooks/useT";

export const CallAudioMonoButton: FC = () => {
  const t = useT();

  return (
    <PaperTooltipView
      placement="bottom-end"
      title={
        <Stack p={2} spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <HelpIcon color="primary" />
            <Typography color="primary" variant="h5">
              {t("CallAudioMonoButton_TooltipTitle_MonoAudio")}
            </Typography>
          </Stack>
          <Typography variant="subtitle2">
            {t("CallAudioMonoButton_TooltipMessage_MonoAudio")}
          </Typography>
        </Stack>
      }
    >
      <RoundButton
        size="small"
        sx={{ px: 3 }}
        endIcon={<HelpIcon sx={{ fontSize: 16 }} />}
      >
        {t("CallAudioMonoButton_Button_MonoAudio")}
      </RoundButton>
    </PaperTooltipView>
  );
};
