import { FC } from "react";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { AuthLayoutView } from "./AuthLayoutView";
import { AuthLoginView } from "./AuthLoginView";

export const AuthLoginPage: FC = () => {
  return (
    <AuthLayoutView>
      <HeaderConfig variant="NONE" />
      <AuthLoginView />
    </AuthLayoutView>
  );
};
