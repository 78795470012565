import { useCallback, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { updateUserCallable } from "../callables/updateUserCallable";
import { useMyUser } from "./useMyUser";

export function useSetUserSelectedOrganizationId() {
  const isInitializing = useMyUser((state) => state.isInitializing);
  const userId = useMyUser((state) => state.user?.id);
  const currentSelectedOrganizationId =
    useMyUser.getState().user?.selectedOrganizationId;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setUserSelectedOrganizationId = useCallback(
    async (selectedOrganizationId: string) => {
      if (isSubmitting || isInitializing || !userId) return;
      if (selectedOrganizationId === currentSelectedOrganizationId) return;

      try {
        setIsSubmitting(true);
        await updateUserCallable({ userId, user: { selectedOrganizationId } });
      } catch (error) {
        handleError(error).logAnd().toast();
      } finally {
        setIsSubmitting(false);
      }
    },
    [currentSelectedOrganizationId, isInitializing, isSubmitting, userId],
  );

  return [setUserSelectedOrganizationId, isSubmitting] as const;
}
