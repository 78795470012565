import {
  Chip,
  InputLabel,
  MenuItem,
  PaginationItem,
  Select,
  Stack,
  StackProps,
  styled,
} from "@mui/material";
import { FC } from "react";
import { useT } from "../../Translation/hooks/useT";
import { FirebasePagination } from "../types/FirebasePagination";

interface Props extends Omit<StackProps, "onChange" | "direction"> {
  pagination: FirebasePagination;
  rowsPerPageOptions?: number[];
}

export const FirebasePaginationView: FC<Props> = (props) => {
  const t = useT();
  const { pagination, rowsPerPageOptions, ...restProps } = props;
  const { onChange, page, count } = pagination;

  const onClickedPrevious = () => onChange(page - 1);
  const onClickedNext = () => onChange(page + 1);

  return (
    <Stack direction="row" justifyContent="center" {...restProps}>
      {rowsPerPageOptions && (
        <>
          <InputLabel
            sx={{
              display: "flex",
              alignItems: "center",
              color: "inherit",
              pr: "0.5em",
            }}
          >
            {t("FirebasePaginationView_RowsPerPage")}
          </InputLabel>
          <Select
            variant="standard"
            sx={{
              "&.MuiInputBase-root::before": {
                display: "none",
              },
              "&.MuiInputBase-root::after": {
                display: "none",
              },
              "&.MuiInputBase-root .MuiInputBase-input": {
                pr: "1.5em",
                pl: "0.5em",
              },
            }}
            value={pagination.rowsPerPage}
            onChange={(event) =>
              pagination.onRowsPerPageChange(event.target.value as number)
            }
          >
            {rowsPerPageOptions.map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      <PaginationItem
        type="previous"
        onClick={onClickedPrevious}
        disabled={page <= 1}
      />
      <PageIndicatorChip size="medium" label={page} />
      <PaginationItem
        type="next"
        onClick={onClickedNext}
        disabled={page === count}
      />
    </Stack>
  );
};

const PageIndicatorChip = styled(Chip)(({ theme }) => ({
  ...theme.typography.body2,
  backgroundColor: theme.palette.grey[300],
}));
