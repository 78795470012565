import { isConnectorConfig } from "@snubes/snubes-types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useConnectorConfig(
  organizationId?: string,
  connectorConfigId?: string,
) {
  return useValidDocumentData(
    organizationId && connectorConfigId
      ? getDocRef(
          `organizations/${organizationId}/connectorConfigs`,
          connectorConfigId,
        )
      : null,
    isConnectorConfig,
  );
}
