import { Alert, Stack, Typography } from "@mui/material";
import { Call } from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC, useMemo, useState } from "react";
import { LoadingView } from "../../Common/views/LoadingView";
import { useT } from "../../Translation/hooks/useT";
import { useCalculateSummaryValues } from "../hooks/useCalculateSummaryValues";
import { useProjects } from "../hooks/useProjects";
import { ProjectReportFramesSummaryView } from "./ProjectReportFramesSummaryView";
import { ProjectsPageHeaderView } from "./ProjectsPageHeaderView";
import { ProjectsSetupView } from "./ProjectsSetupView";
import { ProjectsTableView } from "./ProjectsTableView";

const ROWS_PER_PAGE = 50;

interface Props {
  organizationId: string;
}

export const ProjectsView: FC<Props> = (props) => {
  const t = useT();
  const { rows, isLoading, error, pagination } = useProjects({
    organizationId: props.organizationId,
    rowsPerPage: ROWS_PER_PAGE,
  });

  const { reportFramesToday, reportFramesNotToday } = useMemo(() => {
    const reportFrames = rows.flatMap((project) =>
      project.reportFramesByDay ? project.reportFramesByDay : [],
    );

    const now = DateTime.now().toISODate();
    const reportFramesToday = reportFrames.filter((frame) => frame.id === now);
    const reportFramesNotToday = reportFrames.filter(
      (frame) => frame.id !== now,
    ); // last seven days
    return { reportFramesToday, reportFramesNotToday };
  }, [rows]);

  const summaryValues = useCalculateSummaryValues({
    frames: reportFramesToday,
    framesReference: reportFramesNotToday,
    useAverages: true,
  });

  const [callDirection, setCallDirection] = useState<Call["direction"]>();

  if (!isLoading && !error && !rows.length) {
    return <ProjectsSetupView organizationId={props.organizationId} />;
  }

  return (
    <Stack py={3} spacing={4} height="100%">
      {rows.length === 0 && isLoading && <LoadingView />}
      {rows.length === 0 && error && (
        <Alert severity="error">{error.message}</Alert>
      )}
      {rows.length > 0 && (
        <>
          <ProjectsPageHeaderView />
          <Stack spacing={2}>
            <Typography variant="h3">
              {t("ProjectsView_Subtitle_InsightsToday")}
            </Typography>
            <Typography variant="h5" color="text.600">
              {t("ProjectsView_Subcaption_ComparedTo")}
            </Typography>
          </Stack>
          <ProjectReportFramesSummaryView
            summary={summaryValues}
            callDirection={callDirection}
            onChangeCallDirection={setCallDirection}
          />
          <Stack spacing={2}>
            <Typography variant="h3">
              {t("ProjectsView_Subtitle_AllProjects")}
            </Typography>
            <Typography variant="h5" color="text.600">
              {t("ProjectsView_Subcaption_Averages")}
            </Typography>
          </Stack>
          <ProjectsTableView
            rows={rows}
            pagination={pagination}
            isLoading={isLoading}
          />
        </>
      )}
    </Stack>
  );
};
