import { Stack } from "@mui/material";
import { PropsWithChildren, useMemo } from "react";
import { useSidebarWidth } from "../../Common/hooks/useSidebarWidth";
import { WIZARD_FOOTER_HEIGHT } from "../consts/WIZARD_FOOTER_HEIGHT";
import { getActiveWizardStep } from "../helpers/getActiveWizardStep";
import { WizardStep } from "../types/WizardStep";
import { WizardStore } from "../types/WizardStore";
import { WizardFooterView } from "./WizardFooterView";
import { WizardSidebarView } from "./WizardSidebarView";

interface Props<TState> extends PropsWithChildren {
  steps: WizardStep<TState>[];
  store: WizardStore<TState>;
  onSubmitNavigateTo: string;
}

export function WizardLayoutView<TState>(props: Props<TState>) {
  const { steps, store, onSubmitNavigateTo } = props;
  const activeStep = useMemo(() => getActiveWizardStep(steps), [steps]);
  const sidebarWidth = useSidebarWidth();

  return (
    <>
      {!!sidebarWidth && <WizardSidebarView steps={steps} store={store} />}
      <Stack pl={`${sidebarWidth}px`} pb={`${WIZARD_FOOTER_HEIGHT}px`} pt={6}>
        {props.children}
      </Stack>
      <WizardFooterView
        steps={steps}
        activeStep={activeStep}
        store={store}
        onSubmitNavigateTo={onSubmitNavigateTo}
      />
    </>
  );
}
