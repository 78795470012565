import { Container, Stack, Typography } from "@mui/material";
import { EmailStruct } from "@snubes/snubes-types";
import { FC } from "react";
import { string, type } from "superstruct";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  firstName: string(),
  lastName: string(),
  email: string(),
  phone: string(),
});

export const TenderWizardContactForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => ({
      firstName: tender?.contact?.firstName || "",
      lastName: tender?.contact?.lastName || "",
      email: tender?.contact?.email || "",
      phone: tender?.contact?.phone || "",
    }),
    toDoc: (formValues, tender) => {
      const firstName = formValues.firstName;
      const lastName = formValues.lastName;
      const email = EmailStruct.is(formValues.email)
        ? formValues.email
        : undefined;
      const phone = formValues.phone;

      return {
        contact: {
          ...(firstName && { firstName }),
          ...(lastName && { lastName }),
          ...(email && { email }),
          ...(phone && { phone }),
        },
        isReviewed: {
          ...tender?.isReviewed,
          operationalDetails: false,
        },
      };
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="sm">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardContactForm_Title_Contact")}
        </Typography>
        <Stack spacing={3}>
          <Stack direction="row" spacing={3}>
            <ControlledTextField
              required
              fullWidth
              name="firstName"
              label={t("TenderWizardContactForm_Label_FirstName")}
              control={control}
              autoFocus
            />
            <ControlledTextField
              required
              fullWidth
              name="lastName"
              label={t("TenderWizardContactForm_Label_LastName")}
              control={control}
            />
          </Stack>
          <ControlledTextField
            required
            fullWidth
            type="email"
            name="email"
            label={t("TenderWizardContactForm_Label_Email")}
            control={control}
          />
          <ControlledTextField
            required
            fullWidth
            name="phone"
            label={t("TenderWizardContactForm_Label_Phone")}
            control={control}
          />
        </Stack>
      </Container>
    </form>
  );
};
