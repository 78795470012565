import { Box, Tooltip } from "@mui/material";
import { ConnectorConfig, isConnectorConfig } from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import { CONNECTOR_PLATFORM_RECORD } from "../../ConnectorConfigs/consts/CONNECTOR_PLATFORM_RECORD";
import { ConnectorConfigStatusChip } from "../../ConnectorConfigs/views/ConnectorConfigStatusChip";
import {
  FirebaseTableFilterContextProvider,
  FirebaseTableFilterRecord,
} from "../../Firebase/hooks/useFirebaseTableFilters";
import { CollectionTableColumn } from "../../Firebase/types/CollectionTableColumn";
import { CollectionTableView } from "../../Firebase/views/CollectionTableView";
import { useT } from "../../Translation/hooks/useT";

export const AdminConnectorConfigsCollectionTableView: FC = () => {
  const t = useT();

  const columns = useMemo<CollectionTableColumn<ConnectorConfig>[]>(
    () => [
      {
        title: t("ConnectorConfigsTableView_Title_Name"),
        field: "name",
      },
      {
        title: t("ConnectorConfigsTableView_Title_Organization"),
        field: "organizationName",
      },
      {
        title: t("ConnectorConfigsTableView_Title_Platform"),
        renderCell: (connectorConfig) => (
          <>{t(CONNECTOR_PLATFORM_RECORD[connectorConfig.platform].label)}</>
        ),
      },
      {
        title: t("ConnectorConfigsTableView_Title_Status"),
        renderCell: (connectorConfig) => (
          <Tooltip
            placement="bottom"
            title={connectorConfig.statusErrorMessage}
            arrow
          >
            <Box>
              <ConnectorConfigStatusChip status={connectorConfig.status} />
            </Box>
          </Tooltip>
        ),
      },
      {
        title: t("ConnectorConfigsTableView_Title_Projects"),
        renderCell: (connectorConfig) => (
          <>{connectorConfig.projectMappings.length}</>
        ),
      },
      {
        title: t("ConnectorConfigsTableView_Title_CreatedAt"),
        field: "createdAt",
      },
      {
        title: t("ConnectorConfigsTableView_Title_UpdatedAt"),
        field: "updatedAt",
      },
    ],
    [t],
  );

  const filters = useMemo<FirebaseTableFilterRecord<ConnectorConfig>>(
    () => ({
      name: {
        isSearch: true,
        label: t("FirebaseTableFilterView_Label_Search"),
        type: "text",
        operator: "search",
        value: null,
      },
    }),
    [t],
  );

  return (
    <FirebaseTableFilterContextProvider filters={filters}>
      <CollectionTableView
        collectionGroupName="connectorConfigs"
        orderBy={["organizationId", "asc"]}
        keyField="id"
        isT={isConnectorConfig}
        getTo={(connectorConfig) =>
          `/organizations/${connectorConfig.organizationId}/connector-configs/${connectorConfig.id}/details`
        }
        columns={columns}
      />
    </FirebaseTableFilterContextProvider>
  );
};
