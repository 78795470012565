import { Box, Stack, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { Children, FC, PropsWithChildren } from "react";
import { MaxWidthContainer } from "../../Common/views/MaxWidthContainer";
import { useT } from "../../Translation/hooks/useT";

export const HomeKeyFeaturesView: FC<PropsWithChildren> = (props) => {
  const t = useT();

  return (
    <MaxWidthContainer bgcolor="background.paper">
      <Stack p={5} spacing={4}>
        <Stack spacing={2}>
          <Typography variant="h3">
            {t("HomeKeyFeaturesView_Headline_ExploreFeatures")}
          </Typography>
          <Typography variant="body1" color="text.600">
            {t("HomeKeyFeaturesView_Body_NotSureWhatToDoNext")}
          </Typography>
        </Stack>
        <Box>
          <Grid container spacing={4}>
            {Children.map(props.children, (element) => (
              <Grid xs={12} sm={6} md={6} lg={4}>
                {element}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    </MaxWidthContainer>
  );
};
