import {
  Box,
  Stack,
  SxProps,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { Children, FC, PropsWithChildren } from "react";
import { MaxWidthContainer } from "../../Common/views/MaxWidthContainer";
import { useT } from "../../Translation/hooks/useT";

interface Props extends PropsWithChildren {
  sx?: SxProps;
}

export const HomeOtherApplicationsView: FC<Props> = (props) => {
  const t = useT();

  return (
    <MaxWidthContainer bgcolor="background.paper" sx={props.sx}>
      <Stack p={5} spacing={4} bgcolor="background.paper">
        <Stack spacing={2}>
          <Typography variant="h3">
            {t("HomeOtherApplicationsView_Cta_Title")}
          </Typography>
          <Typography variant="body1" color="text.600">
            {t("HomeOtherApplicationsView_Cta_Body")}
          </Typography>
        </Stack>
        <Box>
          <Grid container spacing={4}>
            {Children.map(props.children, (element) => (
              <Grid xs={12} md={6} lg={5}>
                {element}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    </MaxWidthContainer>
  );
};
