import { Alert } from "@mui/material";
import { isProjectLog } from "@snubes/snubes-types";
import { FC } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { NavTab } from "../../Common/types/NavTab";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LoadingView } from "../../Common/views/LoadingView";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";
import { LogListItemViewForProject } from "../../Logs/views/LogListItemViewForProject";
import { LogsButtonView } from "../../Logs/views/LogsButtonView";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { useProject } from "../hooks/useProject";
import { ProjectContextProvider } from "../hooks/useProjectContext";

export const ProjectLayoutView: FC = () => {
  const { projectId } = useParams<"projectId">();
  const [project, isLoading, error] = useProject(projectId);
  const isLoaded = !isLoading && !error;
  const t = useT();
  const organizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );
  const hasPermission = useHasPermission();

  const tabs: NavTab[] = [
    {
      label: "ProjectLayoutView_TabLabel_Details",
      route: "calls/insights",
    },
    {
      label: "ProjectLayoutView_TabLabel_Analytics",
      route: "analytics",
      isHidden: !project?.isLookerAnalyticsEnabled,
    },
    {
      label: "ProjectLayoutView_TabLabel_Settings",
      route: "settings",
      isHidden: !hasPermission("CAN_UPDATE_PROJECT_LIMITED", {
        organizationId,
      }),
    },
  ];

  if (!projectId) return null;

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <SecondaryHeaderView
        tabs={tabs}
        pathPrefix={`/projects/${projectId}`}
        backButton={{
          to: "/projects",
          label: t("ProjectLayoutView_Label_BackToProjects"),
        }}
      >
        {hasPermission("CAN_READ_LOGS") && (
          <LogsButtonView
            sx={{ position: "absolute", right: 0, mr: 4 }}
            collectionName={`projects/${projectId}/logs`}
            LogListItemView={LogListItemViewForProject}
            isT={isProjectLog}
          />
        )}
      </SecondaryHeaderView>
      {!isLoaded && (
        <FullHeightContainer>
          {isLoading && <LoadingView />}
          {error && (
            <Alert sx={{ m: 3 }} severity="error">
              {error.message}
            </Alert>
          )}
        </FullHeightContainer>
      )}
      {isLoaded && !project && (
        <FullHeightContainer>
          <Alert sx={{ m: 3 }} severity="error">
            {t("ProjectLayoutView_Error_ProjectNotFound")}
          </Alert>
        </FullHeightContainer>
      )}
      {isLoaded && project && (
        <ProjectContextProvider value={{ project }}>
          <Outlet />
        </ProjectContextProvider>
      )}
    </>
  );
};
