import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Alert,
  Button,
  Divider,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Call } from "@snubes/snubes-types";
import { DateTime, Interval } from "luxon";
import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { CallDetailsPage } from "../../Calls/views/CallDetailsPage";
import { CallDirectionSelect } from "../../Calls/views/CallDirectionSelect";
import { CallsCollectionTableView } from "../../Calls/views/CallsCollectionTableView";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import {
  LocalStorage,
  useLocalStorage,
} from "../../Common/hooks/useLocalStorage";
import { AlertBannerView } from "../../Common/views/AlertBannerView";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LoadingView } from "../../Common/views/LoadingView";
import { OverlayPage } from "../../Common/views/OverlayPage";
import { useT } from "../../Translation/hooks/useT";
import { getNormalizedProjectReportFrames } from "../helpers/getNormalizedProjectReportFrames";
import { useCalculateSummaryValues } from "../hooks/useCalculateSummaryValues";
import { useLastProjectReportFrame } from "../hooks/useLastProjectReportFrame";
import { useProjectContext } from "../hooks/useProjectContext";
import { useProjectController } from "../hooks/useProjectController";
import { useProjectReportFrames } from "../hooks/useProjectReportFrames";
import { ProjectChartGrid } from "./ProjectChartGrid";
import { ProjectConnectorConfigNotAvailableView } from "./ProjectConnectorConfigNotAvailableView";
import { ProjectDetailsPageArchivedBanner } from "./ProjectDetailsPageArchivedBanner";
import { ProjectDetailsPageHeaderView } from "./ProjectDetailsPageHeaderView";
import { ProjectDetailsReprocessCallsMenuView } from "./ProjectDetailsReprocessCallsMenuView";
import { ProjectReportFramesCollectionTableView } from "./ProjectReportFramesCollectionTableView";
import { ProjectReportFramesSummaryView } from "./ProjectReportFramesSummaryView";

export const ProjectDetailsPage: FC = () => {
  const t = useT();
  const hasPermission = useHasPermission();
  const { project } = useProjectContext();
  const projectController = useProjectController();
  const { filterProjectTabs } = useLocalStorage((state) => state.data);
  const { callId } = useParams<"callId">();
  const [regenerateCallsMenuAnchorEl, setRegenerateMenuAnchorEl] =
    useState<HTMLElement>();

  const selectedTimePeriodDays = useMemo(() => {
    return projectController.fromDate
      ? (projectController.toDate?.diff(projectController.fromDate, "days")
          .days || 0) + 1
      : 1; // minimum minus 1 day
  }, [projectController.fromDate, projectController.toDate]);

  // Calculate previous time period based on the number of days in the selected period.
  const previousTimePeriod = useMemo(
    () => ({
      fromDate:
        projectController.fromDate?.minus({
          days: selectedTimePeriodDays,
        }) || null,
      toDate:
        projectController.toDate?.minus({
          days: selectedTimePeriodDays,
        }) || null,
    }),
    [
      projectController.fromDate,
      projectController.toDate,
      selectedTimePeriodDays,
    ],
  );

  const timePeriodLabels = useMemo(() => {
    return {
      selected: {
        startDate: toLocaleDateTimeString(projectController.fromDate, "date"),
        endDate: toLocaleDateTimeString(projectController.toDate, "date"),
      },
      previous: {
        startDate: toLocaleDateTimeString(previousTimePeriod.fromDate, "date"),
        endDate: toLocaleDateTimeString(previousTimePeriod.toDate, "date"),
      },
    };
  }, [
    projectController.fromDate,
    projectController.toDate,
    previousTimePeriod.fromDate,
    previousTimePeriod.toDate,
  ]);

  const [callDirection, setCallDirection] = useState<Call["direction"]>();

  // Fetch report frames for the selected period and for the previous period.
  const [reportFrames, isLoading, error] = useProjectReportFrames({
    projectId: project?.id,
    fromDate: previousTimePeriod.fromDate,
    toDate: projectController.toDate,
    callDirection: callDirection,
  });
  const isLoaded = !isLoading && !error;
  const hasReportFrames = !!reportFrames.length;

  // Calculate report frame sets for the summary view.
  const { reportFramesSelected, reportFramesReference } = useMemo(() => {
    // All report frames in the selected period.
    const reportFramesSelected = reportFrames.filter((frame) => {
      return (
        frame.date.toMillis() <= (projectController.toDate?.toMillis() ?? 0) &&
        frame.date.toMillis() >= (projectController.fromDate?.toMillis() ?? 0)
      );
    });
    // All report frames in the previous period.
    const reportFramesReference = reportFrames.filter((frame) => {
      return (
        frame.date.toMillis() <= (previousTimePeriod.toDate?.toMillis() ?? 0) &&
        frame.date.toMillis() >= (previousTimePeriod.fromDate?.toMillis() ?? 0)
      );
    });

    return { reportFramesSelected, reportFramesReference };
  }, [
    projectController.fromDate,
    projectController.toDate,
    previousTimePeriod.toDate,
    previousTimePeriod.fromDate,
    reportFrames,
  ]);

  const summaryValues = useCalculateSummaryValues({
    frames: reportFramesSelected,
    framesReference: reportFramesReference,
  });

  // Calculate continuous range of report frames for the selected period.
  const normalizedReportFrames = useMemo(
    () =>
      getNormalizedProjectReportFrames({
        projectReportFrames: reportFramesSelected,
        fromDate: projectController.fromDate,
        toDate: projectController.toDate,
      }),
    [
      projectController.fromDate,
      projectController.toDate,
      reportFramesSelected,
    ],
  );

  const [lastProjectReportFrame] = useLastProjectReportFrame({
    projectId: project?.id || null,
  });

  const lastProjectReportFrameDate = useMemo(() => {
    if (lastProjectReportFrame) {
      return DateTime.fromJSDate(lastProjectReportFrame.date.toDate());
    }
  }, [lastProjectReportFrame]);

  const isLastProjectReportFrameDateVisible = useMemo(() => {
    if (!lastProjectReportFrameDate) {
      return false;
    }

    return Interval.fromDateTimes(
      DateTime.fromJSDate(projectController.dateRange.startDate),
      DateTime.fromJSDate(projectController.dateRange.endDate),
    ).contains(lastProjectReportFrameDate.minus({ days: 1 }));
  }, [
    lastProjectReportFrameDate,
    projectController.dateRange.endDate,
    projectController.dateRange.startDate,
  ]);

  const { setDateRange } = projectController;

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    if (hasReportFrames) {
      return;
    }
    if (!lastProjectReportFrameDate) {
      return;
    }
    if (isLastProjectReportFrameDateVisible) {
      return;
    }
    if (projectController.isDateRangeSelectedByUser) {
      return;
    }

    setDateRange(
      {
        startDate: lastProjectReportFrameDate.minus({ month: 1 }).toJSDate(),
        endDate: lastProjectReportFrameDate.toJSDate(),
      },
      /* selectedByUser */ false,
    );
  }, [
    hasReportFrames,
    isLastProjectReportFrameDateVisible,
    isLoaded,
    lastProjectReportFrame,
    lastProjectReportFrameDate,
    projectController.isDateRangeSelectedByUser,
    setDateRange,
  ]);

  if (!project) return null;

  const hasConnectorConfig = !!project.connectorConfigId;
  const isArchived = project.isArchived;

  return (
    <FullHeightContainer maxWidth={false}>
      <Stack py={5} spacing={3} height="100%">
        {isArchived && (
          <ProjectDetailsPageArchivedBanner projectId={project.id} />
        )}
        <ProjectDetailsPageHeaderView
          project={project}
          projectController={projectController}
        />
        <Divider />
        {hasConnectorConfig && (
          <Stack spacing={2}>
            <Typography variant="h3">
              {t(
                "ProjectDetailsPage_Subtitle_ReportInsights",
                timePeriodLabels.selected,
              )}
            </Typography>
            {hasReportFrames && (
              <Typography variant="h5" color="text.600">
                {t(
                  "ProjectDetailsPage_Subcaption_ComparedTo",
                  timePeriodLabels.previous,
                )}
              </Typography>
            )}
          </Stack>
        )}
        <>
          {isLoading && <LoadingView />}
          {error && (
            <Alert sx={{ m: 3 }} severity="error">
              {error.message}
            </Alert>
          )}
        </>
        {isLoaded && !hasConnectorConfig && !hasReportFrames && (
          <ProjectConnectorConfigNotAvailableView
            organizationId={project.providerOrganizationId}
          />
        )}
        {isLoaded && !hasConnectorConfig && hasReportFrames && (
          <AlertBannerView color="error">
            <Stack
              width="100%"
              spacing={4}
              direction={{ xs: "column", md: "row" }}
              justifyContent="space-between"
            >
              <Stack spacing={2}>
                <Typography variant="h4">
                  {t("ProjectConnectorConfigNotAvailableView_Title_NoData")}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {t("ProjectConnectorConfigNotAvailableView_SubTitle_NoData")}
                </Typography>
              </Stack>
              <Button
                variant="contained"
                href={`/organizations/${project.providerOrganizationId}/connector-configs`}
              >
                {t("ProjectConnectorConfigNotAvailableView_Button_NoData")}
              </Button>
            </Stack>
          </AlertBannerView>
        )}
        {isLoaded && hasConnectorConfig && !hasReportFrames && (
          <AlertBannerView color="warning">
            <Stack spacing={2}>
              <Typography variant="h4">
                {t("ProjectDetailsPage_WarningTitle_NoReportFrames")}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {t("ProjectDetailsPage_WarningMessage_NoReportFrames")}
              </Typography>
            </Stack>
          </AlertBannerView>
        )}
        {isLoaded && hasReportFrames && (
          <>
            <ProjectReportFramesSummaryView
              summary={summaryValues}
              callDirection={callDirection}
              onChangeCallDirection={setCallDirection}
            />
            <Stack>
              <ProjectChartGrid projectReportFrames={normalizedReportFrames} />
            </Stack>
            <Stack pt={3}>
              <Tabs
                value={filterProjectTabs}
                onChange={(_, next: TabValue) =>
                  useLocalStorage
                    .getState()
                    .setData({ filterProjectTabs: next })
                }
              >
                <Tab
                  value={"REPORT_FRAMES" satisfies TabValue}
                  label={t("ProjectDetailsPage_Tab_ReportFrames")}
                />
                <Tab
                  value={"CALLS" satisfies TabValue}
                  label={t("ProjectDetailsPage_Tab_Calls")}
                />
                <Tab
                  value={"FLAGGED_CALLS" satisfies TabValue}
                  label={t("ProjectDetailsPage_Tab_FlaggedCalls")}
                />
              </Tabs>
              <Divider />
            </Stack>
            {filterProjectTabs === "REPORT_FRAMES" && (
              <>
                <Typography variant="h3">
                  {t("ProjectDetailsPage_Tab_ReportFrames")}
                </Typography>
                <Paper>
                  <ProjectReportFramesCollectionTableView
                    projectId={project.id}
                    fromDate={projectController.fromDate}
                    toDate={projectController.toDate}
                  />
                </Paper>
              </>
            )}
            {filterProjectTabs === "CALLS" && (
              <>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h3">
                    {t("ProjectDetailsPage_Tab_Calls")}
                  </Typography>

                  <Stack direction="row" spacing={3}>
                    <CallDirectionSelect
                      callDirection={callDirection}
                      onChangeCallDirection={setCallDirection}
                    />
                    {hasPermission("CAN_REQUEST_PROCESS_CALL", {
                      organizationId: project.providerOrganizationId,
                    }) && (
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        sx={{ minWidth: 220, justifyContent: "space-between" }}
                        startIcon={<RestartAltIcon />}
                        endIcon={<KeyboardArrowDownOutlinedIcon />}
                        onClick={(event) => {
                          setRegenerateMenuAnchorEl(event.currentTarget);
                        }}
                      >
                        {t("ProjectDetailsPage_Button_RegenerateOutputs")}
                      </Button>
                    )}
                    {regenerateCallsMenuAnchorEl && (
                      <ProjectDetailsReprocessCallsMenuView
                        anchorEl={regenerateCallsMenuAnchorEl}
                        project={project}
                        fromDate={projectController.fromDate}
                        toDate={projectController.toDate}
                        closeMenu={() => {
                          setRegenerateMenuAnchorEl(undefined);
                        }}
                      />
                    )}
                  </Stack>
                </Stack>
                <Paper>
                  <CallsCollectionTableView
                    projectId={project.id}
                    from={projectController.fromDate?.toJSDate()}
                    to={projectController.toDate?.toJSDate()}
                    callDirection={callDirection || undefined}
                  />
                </Paper>
              </>
            )}
            {filterProjectTabs === "FLAGGED_CALLS" && (
              <>
                <Typography variant="h3">
                  {t("ProjectDetailsPage_Tab_FlaggedCalls")}
                </Typography>
                <Paper>
                  <CallsCollectionTableView
                    projectId={project.id}
                    from={projectController.fromDate?.toJSDate()}
                    to={projectController.toDate?.toJSDate()}
                    hasIssues
                  />
                </Paper>
              </>
            )}
          </>
        )}
      </Stack>
      {callId !== "insights" && (
        <OverlayPage>
          <CallDetailsPage />
        </OverlayPage>
      )}
    </FullHeightContainer>
  );
};

type TabValue = LocalStorage["filterProjectTabs"];
