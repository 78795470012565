import { Process, Timestamp } from "@snubes/snubes-types";
import { getCallProcessingDurationInSeconds } from "./getCallProcessingDurationInSeconds";

const CALL_PROCESS_OVERDUE_IN_SECONDS = 30 * 60; // 30 minutes

export function isCallProcessOverdue(
  process: Process | undefined,
  previousProcessEndedAt: Timestamp,
) {
  return (
    getCallProcessingDurationInSeconds(process, previousProcessEndedAt) >
    CALL_PROCESS_OVERDUE_IN_SECONDS
  );
}
