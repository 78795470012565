import { ListItemButton, Stack, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { WIZARD_ACTIVE_PSEUDO_SX } from "../consts/WIZARD_ACTIVE_PSEUDO_SX";
import { WIZARD_PROGRESS_SIZE } from "../consts/WIZARD_PROGRESS_SIZE";
import { WizardStep } from "../types/WizardStep";
import { WizardStore } from "../types/WizardStore";
import { WizardProgressView } from "./WizardProgressView";

interface Props<TState> {
  step: WizardStep<TState>;
  store: WizardStore<TState>;
  level: number;
}

export function WizardSidebarButton<TState>(props: Props<TState>) {
  const { step, store, level } = props;
  const { pathname } = useLocation();
  const isPathActive =
    step.hasTabs && step.path
      ? pathname.startsWith(step.path)
      : step.path === pathname;

  return (
    <>
      <ListItemButton
        component={NavLink}
        to={step.path || "/"}
        sx={{
          pl: 4,
          "&:hover": {
            bgcolor: "grey.50",
          },
          ...(isPathActive && {
            ...WIZARD_ACTIVE_PSEUDO_SX,
            bgcolor: "info.50",
            "&:hover": {
              bgcolor: "info.50",
            },
          }),
        }}
      >
        {new Array(level).fill(null).map((_, index) => (
          <Stack key={index} width={WIZARD_PROGRESS_SIZE} mr={4} />
        ))}
        <WizardProgressView step={step} store={store} />
        <Typography
          variant="h5"
          color={isPathActive ? "primary.main" : undefined}
          ml={4}
        >
          {step.title}
        </Typography>
      </ListItemButton>
      {!step.hasTabs &&
        step.steps?.map((childStep) => (
          <WizardSidebarButton
            key={childStep.pathSegment}
            step={childStep}
            store={store}
            level={level + 1}
          />
        ))}
    </>
  );
}
