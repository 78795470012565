import { Tooltip, Typography } from "@mui/material";
import { FC } from "react";

interface Props {
  title: string | undefined;
}

export const CallsCollectionTableCellTooltip: FC<Props> = (props) => {
  return (
    <Tooltip placement="right" title={props.title} arrow>
      <Typography
        variant="inherit"
        sx={{
          overflowX: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {props.title || "-"}
      </Typography>
    </Tooltip>
  );
};
