import {
  CollectionGroupName,
  CollectionName,
  FirebaseFilter,
  FirebaseOrderBy,
  FirebaseOrderByDirection,
} from "@snubes/snubes-types";
import {
  DocumentData,
  limit,
  orderBy,
  OrderByDirection,
  Query,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  startAfter,
  where,
  WhereFilterOp,
} from "firebase/firestore";
import { useCallback, useMemo, useRef, useState } from "react";
import { useDeepCompareEffect } from "../../Common/hooks/useDeepCompareEffect";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";
import { getCollectionGroupRef } from "../helpers/getCollectionGroupRef";
import { CollectionSortBy } from "../types/CollectionSortBy";
import { FirebasePagination } from "../types/FirebasePagination";

const INEQUALITY_FILTER_OPS: WhereFilterOp[] = [
  "<",
  "<=",
  "!=",
  "not-in",
  ">",
  ">=",
];

interface PaginationState {
  /**
   * Current page index.
   */
  page: number;
  /**
   * Number of rows per page.
   */
  rowsPerPage: number;
  /**
   * Snapshots of all docs on previous pages.
   */
  cursors: QueryDocumentSnapshot<DocumentData>[];
  /**
   * Fetch documents after this one.
   */
  startAfter?: QueryConstraint;
  /**
   * Where/orderBy/startAfter query constraints.
   */
  queryConstraints: QueryConstraint[];
}

interface BaseArgs<T> {
  isT: (obj: unknown) => obj is T;
  getNormalizedRow?: (row: T) => T;
  filters?: FirebaseFilter<T>[];
  filterDirection?: FirebaseOrderByDirection;
  orderBy?: FirebaseOrderBy<T>;
  sortBy?: CollectionSortBy<T>;
  rowsPerPage: number;
}

interface UsePaginatedCollectionArgs<T> extends BaseArgs<T> {
  collectionName: CollectionName;
}

interface UsePaginatedCollectionGroupArgs<T> extends BaseArgs<T> {
  collectionGroupName: CollectionGroupName;
}

type Args<T> =
  | UsePaginatedCollectionArgs<T>
  | UsePaginatedCollectionGroupArgs<T>;

export function usePaginatedCollection<T>(args: Args<T>) {
  const collectionRef =
    "collectionName" in args
      ? getCollectionRef(args.collectionName)
      : getCollectionGroupRef(args.collectionGroupName);

  return useRawPaginatedCollection({ ...args, collectionRef });
}

interface UseRawPaginatedCollectionArgs<T> extends BaseArgs<T> {
  collectionRef: Query<DocumentData>;
}

function useRawPaginatedCollection<T>(args: UseRawPaginatedCollectionArgs<T>) {
  const { getNormalizedRow } = args;

  const [state, setState] = useState<PaginationState>({
    page: 1,
    rowsPerPage: args.rowsPerPage,
    cursors: [],
    queryConstraints: [],
  });

  useDeepCompareEffect(() => {
    const queryConstraints: QueryConstraint[] = [];

    // We use this set to keep track of orderBy keys that ware already
    // added to the list of constraints. OrderBys must not be duplicated.
    const orderByKeys = new Set<string>();

    for (const [key, op, value] of args.filters || []) {
      if (op === "search") {
        // Special string prefix match. See https://stackoverflow.com/a/56815787
        // TODO: This is not a good solution and is case sensitive. We should use a proper search engine.
        queryConstraints.push(
          where(key, ">=", value),
          where(key, "<=", `${value as string}\uf8ff`),
        );
      } else {
        queryConstraints.push(where(key, op, value));
      }
      // When using a filter with an inequality operation on a field then
      // we must also add the same field as the first argument to orderBy().
      if (
        (op === "search" || INEQUALITY_FILTER_OPS.includes(op)) &&
        !orderByKeys.has(key)
      ) {
        // If an orderBy for the given key is provided, use that direction.
        // Otherwise use the filterDirection argument or fall back to `asc`.
        const direction: OrderByDirection =
          args.orderBy?.[0] === key
            ? args.orderBy[1]
            : args.filterDirection || "asc";
        queryConstraints.push(orderBy(key, direction));
        orderByKeys.add(key);
      }
    }

    if (args.orderBy && !orderByKeys.has(args.orderBy[0])) {
      queryConstraints.push(orderBy(...args.orderBy));
    }

    setState((prev) => ({
      ...prev,
      page: 1,
      cursors: [],
      afterCursor: undefined,
      queryConstraints,
    }));
  }, [args.filterDirection, args.filters, args.orderBy]);

  const [rows, isLoading, error, snapshot] = useValidCollectionData(
    query(
      args.collectionRef,
      ...state.queryConstraints,
      ...(state.startAfter ? [state.startAfter] : []),
      limit(state.rowsPerPage + 1), // fetch one extra so we know if there's a next page
    ),
    args.isT,
  );

  const normalizedRows = useMemo(() => {
    return getNormalizedRow && rows
      ? rows.map((row) => getNormalizedRow(row))
      : rows;
  }, [getNormalizedRow, rows]);

  // to prevent "flashing" in the consumer of the hook, only set rows after loading
  const rowsRef = useRef(normalizedRows);
  if (!isLoading) rowsRef.current = normalizedRows;

  const onChange = useCallback(
    (page: number) => {
      if (!snapshot) return;

      setState((prev): PaginationState => {
        let cursors;
        if (page > prev.page) {
          // navigate forward, add new docs to list of cursors
          cursors = [...prev.cursors, ...snapshot.docs.slice(0, -1)];
        } else {
          // navigate backward, remove rowsPerPage cursors from the list
          cursors = prev.cursors.slice(
            0,
            prev.cursors.length - prev.rowsPerPage,
          );
        }

        const afterCursor = cursors[cursors.length - 1];
        return {
          ...prev,
          page,
          cursors,
          startAfter: afterCursor ? startAfter(afterCursor) : undefined,
        };
      });
    },
    [snapshot],
  );

  const onRowsPerPageChange = useCallback((rowsPerPage: number) => {
    setState((prev) => ({ ...prev, rowsPerPage }));
  }, []);

  // calculate the number of total documents by summing the amount
  // of docs on previous pages (cursors) and on the current page
  const totalDocs = state.cursors.length + rowsRef.current.length;

  // calculate the number of pages by dividing
  // the number of docs by rows (docs) per page
  const count =
    Math.floor(totalDocs / state.rowsPerPage) +
    (totalDocs % state.rowsPerPage ? 1 : 0);

  const pagination: FirebasePagination = useMemo(
    () => ({
      count,
      page: state.page,
      rowsPerPage: state.rowsPerPage,
      onChange,
      onRowsPerPageChange,
    }),
    [count, state.page, state.rowsPerPage, onChange, onRowsPerPageChange],
  );

  // Slice the rows to only return the ones for the current page.
  const resultRows = rowsRef.current.slice(0, state.rowsPerPage);
  // Sort the rows on the client-side if a sortBy function is provided.
  if (args.sortBy) {
    resultRows.sort(args.sortBy);
  }

  return {
    rows: resultRows,
    isLoading,
    error,
    pagination,
  };
}
