import { isTender } from "@snubes/snubes-types";
import { createWizardStore } from "../../Wizard/helpers/createWizardStore";
import { publishTenderCallable } from "../callables/publishTenderCallable";
import { submitTenderCallable } from "../callables/submitTenderCallable";
import { updatePublishedTenderCallable } from "../callables/updatePublishedTenderCallable";
import { createOrUpdateTender } from "../helpers/createOrUpdateTender";

export const useTenderWizardStore = createWizardStore({
  collectionName: "tenders",
  isT: isTender,
  createOrUpdateDoc: createOrUpdateTender,
  getIsAutosaveDisabled: (tender) => tender.status === "PUBLISHED",
  submitDoc: async (tenderId, tender) => {
    if (tender?.status === "WAITING_FOR_REVIEW") {
      await publishTenderCallable({ tenderId });
    } else if (tender?.status === "PUBLISHED") {
      await updatePublishedTenderCallable({ tenderId, tender });
    } else {
      await submitTenderCallable({ tenderId });
    }
  },
});
