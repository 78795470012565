import { Project } from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { getProjectReportFramesFromLastSevenDays } from "./getProjectReportFramesFromLastSevenDays";

export function getProjectAnsweredCallsCount(project: Project) {
  const now = DateTime.now().toISODate();
  const today =
    project.reportFramesByDay?.find((r) => r.id === now)?.answeredCallsCount ||
    0;
  const frames = getProjectReportFramesFromLastSevenDays(project);
  const lastSevenDays = frames.length
    ? frames.reduce(
        (result, frame) => result + (frame.answeredCallsCount || 0), // TODO: answeredCallsCount should be required
        0,
      ) / frames.length
    : 0;

  return { today, lastSevenDays };
}
