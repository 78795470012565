import { Chip } from "@mui/material";
import { TenderApplication } from "@snubes/snubes-types";
import { FC } from "react";
import { RelativeTimestampView } from "../../Common/views/RelativeTimestampView";
import { useT } from "../../Translation/hooks/useT";
import { TENDER_APPLICATION_STATUS_RECORD } from "../consts/TENDER_APPLICATION_STATUS_RECORD";

interface Props {
  tenderApplication: TenderApplication | undefined;
}

export const TenderApplicationStatusChip: FC<Props> = (props: Props) => {
  const { tenderApplication } = props;
  const { status = "PENDING" } = tenderApplication || {};

  const t = useT();
  const Icon = TENDER_APPLICATION_STATUS_RECORD[status].Icon;
  const timestamp =
    tenderApplication?.[TENDER_APPLICATION_STATUS_RECORD[status].timestampKey];

  return (
    <Chip
      color={TENDER_APPLICATION_STATUS_RECORD[status].color}
      icon={<Icon />}
      variant={TENDER_APPLICATION_STATUS_RECORD[status].variant}
      label={
        <>
          {t(TENDER_APPLICATION_STATUS_RECORD[status].label)}
          {timestamp && (
            <>
              {" "}
              <RelativeTimestampView
                hasTooltip
                timestamp={timestamp}
                shouldBeInThePast
                translation
              />
            </>
          )}
        </>
      }
    />
  );
};
