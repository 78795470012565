import { Currency } from "@snubes/snubes-types";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { AdminFeatureFlag } from "../../Admin/types/AdminFeatureFlag";

const LOCAL_STORAGE_KEY = "SNUBES";

export const DEFAULT_LOCAL_STORAGE: LocalStorage = {
  defaultCurrency: "EUR",
  defaultVolume: 50,
  featureFlags: {},
  // Filter local storage keys are split in 3 pieces. Please follow when adding new filters:
  // "filter" + name of page + name of filter
  filterProjectTabs: "REPORT_FRAMES",
};

export interface LocalStorage {
  defaultCurrency: Currency;
  defaultVolume: number;
  featureFlags: Partial<Record<AdminFeatureFlag, boolean>>;
  filterProjectTabs: "REPORT_FRAMES" | "CALLS" | "FLAGGED_CALLS";
  filterProjectStartDate?: string;
  filterProjectEndDate?: string;
  filterProjectSaveDate?: string;
}

interface LocalStorageState {
  data: LocalStorage;
  setData: (next: Partial<LocalStorage>) => void;
}

export const useLocalStorage = create<LocalStorageState>()(
  persist(
    (set, get) => ({
      data: { ...DEFAULT_LOCAL_STORAGE },

      setData: (next) => {
        set({
          data: {
            ...DEFAULT_LOCAL_STORAGE,
            ...get().data,
            ...next,
          },
        });
      },
    }),
    {
      name: LOCAL_STORAGE_KEY,
      partialize: (state) => ({
        data: state.data,
      }),
    },
  ),
);
