import {
  BaseTextFieldProps,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
} from "@mui/material";
import { ReactNode } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

interface Props<T extends FieldValues> {
  control: Control<T>;
  name: FieldPath<T>;
  label?: ReactNode;
  sx?: SxProps<Theme>;
  inputSx?: SxProps<Theme>;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  type?: TextFieldProps["type"];
  multiline?: boolean;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  helperText?: string;
  disabled?: boolean;
  autoComplete?: "off";
  size?: BaseTextFieldProps["size"];
  autoFocus?: BaseTextFieldProps["autoFocus"];
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  onPressEnter?: () => void;
  min?: number;
  max?: number;
}

export function ControlledTextField<T extends FieldValues>(props: Props<T>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          size={props.size}
          placeholder={props.placeholder}
          fullWidth={props.fullWidth}
          required={props.required}
          autoComplete={props.type === "password" ? "auto complete" : undefined}
          label={props.label}
          sx={props.sx}
          autoFocus={props.autoFocus}
          {...(props.onPressEnter && {
            onKeyDown: (e) => e.key === "Enter" && props.onPressEnter?.(),
          })}
          disabled={props.disabled}
          error={!!fieldState.error}
          helperText={props.helperText || fieldState.error?.message}
          multiline={props.multiline}
          rows={props.rows}
          minRows={props.minRows}
          maxRows={props.maxRows}
          type={props.type}
          {...(props.type === "number" && {
            onChange: (e) => {
              const value = e.target.value === "" ? "" : Number(e.target.value);

              if (
                props.min !== undefined &&
                value !== "" &&
                value < props.min
              ) {
                return field.onChange(props.min);
              }

              field.onChange(value);
            },
          })}
          InputProps={{
            startAdornment: props.startAdornment,
            endAdornment: props.endAdornment,
            autoComplete: props.autoComplete,
            sx: props.inputSx,
            ...((props.min !== undefined || props.max !== undefined) && {
              inputProps: {
                min: props.min,
                max: props.max,
              },
            }),
          }}
        />
      )}
    />
  );
}
