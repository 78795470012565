import { Container, Stack, Typography } from "@mui/material";
import { ChannelTypeStruct, ServiceType } from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import { array, type } from "superstruct";
import { ControlledCheckboxButtons } from "../../Form/views/ControlledCheckboxButtons";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { TENDER_CHANNEL_TYPE } from "../consts/TENDER_CHANNEL_TYPE";
import { TENDER_SERVICE_TYPE_RECORD } from "../consts/TENDER_SERVICE_TYPE_RECORD";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  channelTypes: array(ChannelTypeStruct),
});

type Props = WizardFormProps<{
  serviceType: ServiceType;
}>;

export const TenderWizardChannelsForm: FC<Props> = (props) => {
  const { serviceType } = props.formProps;
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => ({
      channelTypes:
        tender?.services
          ?.find((s) => s.type === serviceType)
          ?.channels?.map((c) => c.type) || [],
    }),
    toDoc: (formValues, tender) => ({
      services: tender?.services?.map((s) => {
        if (s.type !== serviceType) return s;
        return {
          ...s,
          channels: formValues.channelTypes?.map((channelType) => {
            const channel = s?.channels?.find((c) => c.type === channelType);
            return channel || { type: channelType };
          }),
        };
      }),
      isReviewed: {
        ...tender?.isReviewed,
        services: false,
      },
    }),
  });

  const channelOptions = useMemo(() => {
    return TENDER_SERVICE_TYPE_RECORD[serviceType].channelTypes.map(
      (channelType) => ({
        ...TENDER_CHANNEL_TYPE[channelType],
        value: channelType,
      }),
    );
  }, [serviceType]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={6}>
          {t("TenderWizardChannelsForm_Title_Channels", {
            service: t(`ServiceType_${serviceType}`),
          })}
        </Typography>
        <Stack spacing={3}>
          <ControlledCheckboxButtons
            control={control}
            name="channelTypes"
            options={channelOptions}
            gridSpacing={4}
            gridItemXs={6}
            gridItemMd={3}
          />
        </Stack>
      </Container>
    </form>
  );
};
