import { Checkbox, Stack } from "@mui/material";
import { ReactNode } from "react";
import { useFirebaseTableRowSelection } from "../hooks/useFirebaseTableRowSelection";
import { CheckboxTableCellViewProps } from "../types/CheckboxTableCellViewProps";

interface Props<T> extends CheckboxTableCellViewProps<T> {
  title: ReactNode;
  rows: T[];
}

export function CheckboxTableHeadView<T>(props: Props<T>) {
  const { handleHeaderCheckboxChange, areAllRowsSelected } =
    useFirebaseTableRowSelection({
      rows: props.rows,
      getRowId: props.getRowId,
      onRowSelectionChanged: props.onRowSelectionChanged,
    });

  return (
    <Stack direction="row" alignItems="center" gap={3}>
      <Checkbox
        checked={areAllRowsSelected}
        onChange={handleHeaderCheckboxChange}
        name="header"
        color="primary"
      />
      {props.title}
    </Stack>
  );
}
