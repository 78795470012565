import { Stack, Typography } from "@mui/material";
import { FC, PropsWithChildren, ReactNode } from "react";

interface Props extends PropsWithChildren {
  title: string;
  children: ReactNode;
}

export const DetailsAccordionContentView: FC<Props> = (props) => {
  return (
    <Stack p={3} mx={-3}>
      <Typography variant="body1" color="text.secondary" gutterBottom>
        {props.title}
      </Typography>
      <Typography
        component={Stack}
        variant="body2"
        sx={{ whiteSpace: "pre-line" }}
      >
        {props.children}
      </Typography>
    </Stack>
  );
};
