import { Alert, Paper, Stack, Typography } from "@mui/material";
import { Call, isProjectReportFrameLog } from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { CallDetailsPage } from "../../Calls/views/CallDetailsPage";
import { CallsCollectionTableView } from "../../Calls/views/CallsCollectionTableView";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LoadingView } from "../../Common/views/LoadingView";
import { OverlayPage } from "../../Common/views/OverlayPage";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";
import { LogListItemViewForProjectReportFrame } from "../../Logs/views/LogListItemViewForProjectReportFrame";
import { LogsButtonView } from "../../Logs/views/LogsButtonView";
import { useT } from "../../Translation/hooks/useT";
import { useCalculateSummaryValues } from "../hooks/useCalculateSummaryValues";
import { useProjectContext } from "../hooks/useProjectContext";
import { useProjectReportFrameByDay } from "../hooks/useProjectReportFrameByDay";
import { ProjectReportFramesSummaryView } from "./ProjectReportFramesSummaryView";

export const ProjectReportFramesByDayDetailPage: FC = () => {
  const hasPermission = useHasPermission();
  const { projectReportFrameId, projectId, callId } = useParams<
    "projectReportFrameId" | "projectId" | "callId"
  >();
  const t = useT();
  const [reportFrame, isLoading, error] = useProjectReportFrameByDay({
    projectId,
    projectReportFrameId,
  });
  const { project } = useProjectContext();

  const [reportFrames, reportFramesReference] = useMemo(
    () => (reportFrame ? [[reportFrame], []] : [[], []]),
    [reportFrame],
  );
  const summaryValues = useCalculateSummaryValues({
    frames: reportFrames,
    framesReference: reportFramesReference,
  });

  const isLoaded = !isLoading && !error;

  const { fromDate, toDate } = useMemo(() => {
    if (!projectReportFrameId) return {};
    const dateTime = DateTime.fromISO(projectReportFrameId, { zone: "utc" });
    // We need to provide a bracket for the day even though we're filtering by the "date" property of the call.
    // This is to account for timezone offsets, e.g. the report frame date is in UTC but the call date may be in UTC+2.
    return {
      fromDate: dateTime.startOf("day").toJSDate(),
      toDate: dateTime.endOf("day").toJSDate(),
    } as const;
  }, [projectReportFrameId]);

  const [callDirection, setCallDirection] = useState<Call["direction"]>();

  if (!project || !projectReportFrameId || !projectId || !fromDate || !toDate) {
    return null;
  }

  const formatFrameDate =
    DateTime.fromISO(projectReportFrameId).toFormat("LLLL dd");

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <SecondaryHeaderView
        title={
          <Stack direction="row">
            <Typography variant="subtitle1" color="text.primary">
              {project.name} /
            </Typography>
            <Typography ml={1} variant="h4" color="text.600">
              {formatFrameDate}
            </Typography>
          </Stack>
        }
        backButton={{
          to: `/projects/${projectId}`,
          label: t(
            "ProjectReportFramesPerDayDetailPage_Button_BackToProjectDetails",
          ),
        }}
      >
        {hasPermission("CAN_READ_LOGS") && (
          <LogsButtonView
            sx={{ position: "absolute", right: 0, mr: 4 }}
            collectionName={`projects/${project.id}/reportFramesByDay/${projectReportFrameId}/logs`}
            LogListItemView={LogListItemViewForProjectReportFrame}
            isT={isProjectReportFrameLog}
          />
        )}
      </SecondaryHeaderView>
      <FullHeightContainer maxWidth={false}>
        {isLoading && <LoadingView />}
        {error && (
          <Alert sx={{ m: 3 }} severity="error">
            {error.message}
          </Alert>
        )}

        <Stack py={5} spacing={3}>
          {isLoaded && reportFrame && (
            <>
              <Stack spacing={5} mb={4}>
                {summaryValues && (
                  <ProjectReportFramesSummaryView
                    summary={summaryValues}
                    callDirection={callDirection}
                    onChangeCallDirection={setCallDirection}
                  />
                )}
                <Typography variant="h3">
                  {t("ProjectReportFramesPerDayDetailPage_Heading_Allcalls")}
                </Typography>
              </Stack>
              <Paper>
                <CallsCollectionTableView
                  projectId={projectId}
                  projectReportFrameId={projectReportFrameId}
                  from={fromDate}
                  to={toDate}
                />
              </Paper>
            </>
          )}
        </Stack>
        {callId !== "insights" && (
          <OverlayPage>
            <CallDetailsPage />
          </OverlayPage>
        )}
      </FullHeightContainer>
    </>
  );
};
