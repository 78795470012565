import { FormControlProps } from "@mui/material";
import { INDUSTRIES, LegacyIndustry } from "@snubes/snubes-types";
import { ReactNode } from "react";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import {
  LEGACY_ORGANIZATION_INDUSTRY_RECORD,
  ORGANIZATION_INDUSTRY_RECORD,
} from "../../Organizations/consts/ORGANIZATION_INDUSTRY_RECORD";
import { useT } from "../../Translation/hooks/useT";
import { ControlledAutocomplete } from "./ControlledAutocomplete";

interface Props<TFields extends FieldValues> {
  control: Control<TFields>;
  name: FieldPath<TFields>;
  label?: ReactNode;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  size?: FormControlProps["size"];
  multiple?: boolean;
}

export function ControlledIndustriesAutocomplete<TFields extends FieldValues>(
  props: Props<TFields>,
) {
  const t = useT();
  return (
    <ControlledAutocomplete
      control={props.control}
      name={props.name}
      label={props.label}
      options={INDUSTRIES}
      getOptionLabel={(option) => t(ORGANIZATION_INDUSTRY_RECORD[option].label)}
      multiple={props.multiple}
      required={props.required}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      placeholder={props.placeholder}
      helperText={props.helperText}
      size={props.size}
      isOptionEqualToValue={(option, value) => {
        if (value in LEGACY_ORGANIZATION_INDUSTRY_RECORD) {
          return (
            option ===
            LEGACY_ORGANIZATION_INDUSTRY_RECORD[value as LegacyIndustry]
              .defaultValue
          );
        }
        return option === value;
      }}
    />
  );
}
