import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { ChromePicker } from "react-color";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { PALETTE } from "../../Application/consts/PALETTE";
import { useT } from "../../Translation/hooks/useT";

interface Props<T extends FieldValues> {
  name: FieldPath<T>;
  control: Control<T>;
  onClose: () => void;
}

export function ControlledColorPicker<T extends FieldValues>(props: Props<T>) {
  const { onClose, name, control } = props;
  const t = useT();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <ChromePicker
            disableAlpha
            color={field.value}
            onChangeComplete={({ hex }) => field.onChange(hex)}
          />
          <Stack p={2} spacing={2} direction="row">
            <Button size="tiny" variant="contained" onClick={onClose}>
              {t("ControlledColorPicker_Button_Select")}
            </Button>
            <Button
              size="tiny"
              variant="outlined"
              onClick={() => field.onChange(PALETTE.primary.main)}
            >
              {t("ControlledColorPicker_Button_DefaultColor")}
            </Button>
          </Stack>
        </>
      )}
    />
  );
}
