import { UserType } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface UserTypeRecordValue {
  image: string;
  homeScreenPath: string;
  defaultTendersPath: string;
  tendersScreenPath: string;
  title: TranslationKey;
  caption: TranslationKey;
  subcaption: TranslationKey;
  workspaceName: TranslationKey;
  backgroundColor: string;
}

export const USER_TYPE_RECORD: Record<UserType, UserTypeRecordValue> = {
  ADMIN: {
    image: "/assets/home-qm.png",
    homeScreenPath: "/home/qm",
    defaultTendersPath: "my-tenders",
    tendersScreenPath: "/tenders/my-tenders",
    title: "UserTypeRecord_Title_Admin",
    caption: "UserTypeRecord_Caption_Admin",
    subcaption: "UserTypeRecord_Subcaption_Admin",
    workspaceName: "UserTypeRecord_WorkspaceName_Admin",
    backgroundColor: "error.50",
  },
  CLIENT: {
    image: "/assets/home-client.png",
    homeScreenPath: "/home/client",
    defaultTendersPath: "my-tenders",
    tendersScreenPath: "/tenders/my-tenders",
    title: "OnboardingUserType_Title_Clients",
    caption: "OnboardingUserType_Caption_Client",
    subcaption: "OnboardingUserType_Subcaption_Client",
    workspaceName: "UserTypeRecord_WorkspaceName_Client",
    backgroundColor: "primary.200",
  },
  PROVIDER: {
    image: "/assets/home-provider.png",
    homeScreenPath: "/home/provider",
    defaultTendersPath: "browse-tenders",
    tendersScreenPath: "/tenders/browse-tenders",
    title: "OnboardingUserType_Title_Providers",
    caption: "OnboardingUserType_Caption_Provider",
    subcaption: "OnboardingUserType_Subcaption_Provider",
    workspaceName: "UserTypeRecord_WorkspaceName_Provider",
    backgroundColor: "secondary.50",
  },
  QM: {
    image: "/assets/home-qm.png",
    homeScreenPath: "/home/qm",
    defaultTendersPath: "my-tenders",
    tendersScreenPath: "/tenders/my-tenders",
    title: "OnboardingUserType_Title_Qm",
    caption: "OnboardingUserType_Caption_QM",
    subcaption: "OnboardingUserType_Subcaption_QM",
    workspaceName: "UserTypeRecord_WorkspaceName_QM",
    backgroundColor: "error.50",
  },
};
