import {
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  SvgIconTypeMap,
  SxProps,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { TranslationKey } from "../../Translation/types/TranslationKey";
import { CheckboxButton } from "./CheckboxButton";

type Value = string | number;

export type CheckboxOption = {
  value: Value;
  label: TranslationKey;
  caption?: TranslationKey;
  Icon?: OverridableComponent<SvgIconTypeMap>;
};

interface Props<TData extends CheckboxOption> {
  label?: string;
  options: TData[];
  gridItemXs?: number;
  gridItemSm?: number;
  gridItemMd?: number;
  gridItemLg?: number;
  gridItemXl?: number;
  gridSpacing?: number;
  sx?: SxProps;
  disabled?: boolean;
  error?: boolean;
  value: Value[];
  onChange: (items: Value[]) => void;
}

export function CheckboxButtons<TData extends CheckboxOption>(
  props: Props<TData>,
) {
  const toggle = (arr: Value[], item: Value): Value[] => {
    return arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];
  };

  return (
    <FormControl fullWidth error={props.error} disabled={props.disabled}>
      <FormLabel component="legend">{props.label}</FormLabel>
      <FormGroup>
        <Grid container spacing={props.gridSpacing}>
          {props.options?.map((option) => (
            <Grid
              item
              key={option.value}
              xs={props.gridItemXs}
              sm={props.gridItemSm}
              md={props.gridItemMd}
              lg={props.gridItemLg}
              xl={props.gridItemXl}
            >
              <CheckboxButton
                {...option}
                sx={props.sx}
                disabled={props.disabled}
                checked={props.value.includes(option.value)}
                onChange={() =>
                  props.onChange(toggle(props.value, option.value))
                }
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </FormControl>
  );
}
