import { superstructResolver } from "@hookform/resolvers/superstruct";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import {
  ClientFocus,
  CurrencyStruct,
  EmailStruct,
  IndustryStruct,
  Organization,
  ORGANIZATION_REVENUE_VALUES,
  ORGANIZATION_TYPES,
  OrganizationRevenueValue,
  OrganizationTypeStruct,
} from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  array,
  boolean,
  Infer,
  literal,
  nonempty,
  nullable,
  number,
  optional,
  string,
  type,
  union,
} from "superstruct";
import { PALETTE } from "../../../Application/consts/PALETTE";
import { useHasPermission } from "../../../Auth/hooks/useHasPermission";
import { handleError } from "../../../Common/helpers/handleError";
import { useLocalStorage } from "../../../Common/hooks/useLocalStorage";
import { ControlledCountriesAutocomplete } from "../../../Form/views/ControlledCountriesAutocomplete";
import { ControlledDatePicker } from "../../../Form/views/ControlledDatePicker";
import { ControlledIndustriesAutocomplete } from "../../../Form/views/ControlledIndustriesAutocomplete";
import { ControlledSelect } from "../../../Form/views/ControlledSelect";
import { ControlledSwitch } from "../../../Form/views/ControlledSwitch";
import { ControlledTextField } from "../../../Form/views/ControlledTextField";
import { PROVIDER_REVENUE_RANGE_RECORD } from "../../../Providers/consts/PROVIDER_REVENUE_RANGE_RECORD";
import { useT } from "../../../Translation/hooks/useT";
import { T } from "../../../Translation/views/T";
import { useMyUser } from "../../../Users/hooks/useMyUser";
import { updateOrganizationCallable } from "../../callables/updateOrganizationCallable";
import { ORGANIZATION_TYPE_RECORD } from "../../consts/ORGANIZATION_TYPE_RECORD";
import { useRevenueOptions } from "../../hooks/useRevenueOptions";
import { OrganizationLogoUploadView } from "../OrganizationLogoUploadView";
import { clientFocusOptions } from "./options/clientFocusOptions";
import { numberOfEmployeesOptions } from "./options/numberOfEmployeesOptions";
import { OrganizationColorPickerView } from "./OrganizationColorPickerView";
import { OrganizationDeleteDialog } from "./OrganizationDeleteDialog";
import { PaperWithTitleView } from "./PaperWithTitleView";

const FormValuesStruct = type({
  name: string(),
  email: EmailStruct,
  phone: string(),
  street: string(),
  city: string(),
  zipCode: string(),
  country: string(),
  description: string(),
  useLogo: boolean(),
  primaryColor: string(),
  types: array(OrganizationTypeStruct),
  numberOfEmployees: union([string(), number()]),
  foundedInYear: union([string(), number()]),
  revenuePerYearCurrency: CurrencyStruct,
  revenuePerYearAmountIndex: union([number(), literal("")]),
  industry: union([IndustryStruct, literal("")]),
  clientFocus: array(string()),
  website: nonempty(string()),
  socialMediaFacebook: string(),
  socialMediaTwitter: string(),
  socialMediaLinkedIn: string(),
  socialMediaInstagram: string(),
  socialMediaYoutube: string(),
  hubspotCompanyId: optional(nullable(string())),
  isHubspotEnabled: boolean(),
});

type FormValues = Infer<typeof FormValuesStruct>;

interface Props {
  organization: Organization;
  isEditEnabled: boolean;
}

export const OrganizationEditForm: FC<Props> = (props) => {
  const { organization, isEditEnabled } = props;
  const userRole = useMyUser((state) => state.user?.role);
  const userId = useMyUser((state) => state.user?.id);
  const isProvider = organization.types?.includes("PROVIDER");
  const hasPermission = useHasPermission();
  const t = useT();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      name: organization.name || "",
      city: organization.city || "",
      description: organization.description || "",
      email: organization.email || "",
      street: organization.street || "",
      useLogo: organization.layout?.useLogo ?? false,
      primaryColor: organization.layout?.primaryColor || PALETTE.primary.main,
      numberOfEmployees: organization.numberOfEmployees || "",
      revenuePerYearCurrency:
        organization.revenuePerYear?.currency ||
        useLocalStorage.getState().data.defaultCurrency,
      revenuePerYearAmountIndex:
        PROVIDER_REVENUE_RANGE_RECORD[
          (organization?.revenuePerYear?.amount ||
            0) as OrganizationRevenueValue
        ]?.index || "",
      foundedInYear: organization.foundedInYear?.toString() || "",
      clientFocus: organization.clientFocus || [],
      types: organization.types || [],
      industry: organization.industry || "",
      phone: organization.phone || "",
      website: organization.website || "",
      zipCode: organization.zipCode || "",
      country: organization.country || "",
      socialMediaFacebook: organization.socialMediaFacebook || "",
      socialMediaInstagram: organization.socialMediaInstagram || "",
      socialMediaLinkedIn: organization.socialMediaLinkedIn || "",
      socialMediaTwitter: organization.socialMediaTwitter || "",
      socialMediaYoutube: organization.socialMediaYoutube || "",
      hubspotCompanyId: organization.hubspotCompanyId || "",
      isHubspotEnabled: organization.isHubspotEnabled ?? true,
    },
  });

  const isDisabled = isSubmitting || !isEditEnabled || organization.isDeleted;
  const canWriteHubSpot = hasPermission("CAN_WRITE_HUBSPOT");
  const canDeleteOrganization = hasPermission("CAN_DELETE_ORGANIZATION", {
    organizationId: organization.id,
  });
  const isHubspotEnabled = watch("isHubspotEnabled");
  const revenueOptions = useRevenueOptions(watch("revenuePerYearCurrency"));

  const onSubmit = async (formValues: FormValues) => {
    try {
      const organizationUpdate = {
        ...formValues,
        [`layout.useLogo`]: formValues.useLogo,
        [`layout.primaryColor`]: formValues.primaryColor,
        // here we have to revert the type back to number to remove the defaultValue of ""
        foundedInYear: formValues.foundedInYear
          ? Number(formValues.foundedInYear)
          : undefined,
        ...(formValues.revenuePerYearCurrency &&
        formValues.revenuePerYearAmountIndex &&
        ORGANIZATION_REVENUE_VALUES[formValues.revenuePerYearAmountIndex]
          ? {
              revenuePerYear: {
                currency: formValues.revenuePerYearCurrency,
                amount:
                  ORGANIZATION_REVENUE_VALUES[
                    formValues.revenuePerYearAmountIndex
                  ],
              },
            }
          : { revenuePerYear: undefined }),
        numberOfEmployees: formValues.numberOfEmployees
          ? Number(formValues.numberOfEmployees)
          : undefined,
        clientFocus: formValues.clientFocus as ClientFocus[],
        industry: formValues.industry === "" ? undefined : formValues.industry,
        ...(canWriteHubSpot &&
          formValues.isHubspotEnabled && {
            hubspotCompanyId: formValues.hubspotCompanyId || undefined,
          }),
        ...(canWriteHubSpot &&
          !formValues.isHubspotEnabled && {
            hubspotCompanyId: null, // Delete HubSpot id if HubSpot is disabled.
          }),
      };

      await updateOrganizationCallable({
        organizationId: organization.id,
        organization: organizationUpdate,
      });

      toast.success(t("OrganizationEditForm_Success_Message"));
    } catch (error) {
      handleError(error).logAnd().toast();
    }
  };

  const [isOrganizationDeleteDialogOpen, setIsOrganizationDeleteDialogOpen] =
    useState(false);

  const organizationTypeOptions = ORGANIZATION_TYPES.map((type) => ({
    id: type,
    value: type,
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <PaperWithTitleView
            title={<T k="OrganizationEditForm_Title_BasicInformation" />}
          >
            <ControlledTextField
              required
              fullWidth
              name="name"
              label={t("OrganizationEditForm_Field_Name")}
              control={control}
              disabled={isDisabled}
            />
            <ControlledTextField
              fullWidth
              name="email"
              label={t("OrganizationEditForm_Field_Email")}
              control={control}
              disabled={isDisabled}
            />
            <ControlledTextField
              fullWidth
              name="phone"
              label={t("OrganizationEditForm_Field_Phone")}
              control={control}
              disabled={isDisabled}
            />
          </PaperWithTitleView>
        </Grid>
        <Grid item xs={6}>
          <PaperWithTitleView
            title={t("OrganizationEditForm_Field_Headquarters")}
          >
            <ControlledTextField
              fullWidth
              name="street"
              label={t("OrganizationEditForm_Field_Street")}
              control={control}
              disabled={isDisabled}
            />
            <ControlledTextField
              fullWidth
              name="city"
              label={t("OrganizationEditForm_Field_City")}
              control={control}
              disabled={isDisabled}
            />
            <ControlledTextField
              fullWidth
              name="zipCode"
              label={t("OrganizationEditForm_Field_Zip")}
              control={control}
              disabled={isDisabled}
            />
            <ControlledCountriesAutocomplete
              fullWidth
              name="country"
              label={t("OrganizationEditForm_Field_Country")}
              control={control}
              disabled={isDisabled}
            />
          </PaperWithTitleView>
        </Grid>
        <Grid item xs={6}>
          <PaperWithTitleView
            title={<T k="OrganizationEditForm_Title_Description" />}
          >
            <Typography variant="body1">
              <T k="OrganizationEditForm_Text_Description" />
            </Typography>
            <ControlledTextField
              fullWidth
              multiline={true}
              minRows={9}
              maxRows={20}
              name="description"
              label={t("OrganizationEditForm_Field_Description")}
              control={control}
              disabled={isDisabled}
            />
          </PaperWithTitleView>
        </Grid>
        <Grid item xs={6}>
          <PaperWithTitleView
            title={t("OrganizationEditForm_Title_OrganizationalInfo")}
          >
            <ControlledSelect
              label={t("OrganizationEditForm_Field_NumberOfEmployees")}
              control={control}
              disabled={isDisabled}
              name="numberOfEmployees"
              items={numberOfEmployeesOptions}
              renderItem={(item) => item.title}
              fullWidth
            />
            <ControlledDatePicker
              fullWidth
              name="foundedInYear"
              label={t("OrganizationEditForm_Field_Founded")}
              control={control}
              disabled={isDisabled}
              disableFuture
              view="year"
              minDate={DateTime.fromISO("1800-01-01")}
            />
            <ControlledSelect
              label={t("OrganizationEditForm_Field_Revenue")}
              control={control}
              disabled={isDisabled}
              name="revenuePerYearAmountIndex"
              items={revenueOptions}
              renderItem={(item) => item.label}
              fullWidth
            />
            <ControlledIndustriesAutocomplete
              label={t("OrganizationEditForm_Field_Industry")}
              control={control}
              disabled={isDisabled}
              name="industry"
              fullWidth
            />
            <ControlledSelect
              label={t("OrganizationEditForm_Field_ClientFocus")}
              control={control}
              disabled={isDisabled}
              name="clientFocus"
              multiple
              items={clientFocusOptions}
              renderItem={(item) => item.value}
              fullWidth
            />
            {userRole === "USER_ADMIN" && (
              <ControlledSelect
                label={t("OrganizationEditForm_Field_Type")}
                control={control}
                disabled={isDisabled}
                name="types"
                multiple
                items={organizationTypeOptions}
                renderItem={(item) =>
                  t(ORGANIZATION_TYPE_RECORD[item.value].label)
                }
                fullWidth
              />
            )}
          </PaperWithTitleView>
        </Grid>
        <Grid item xs={6}>
          <PaperWithTitleView
            title={t("OrganizationEditForm_Title_SupportingInformation")}
          >
            <ControlledTextField
              required
              fullWidth
              name="website"
              label={t("OrganizationEditForm_Field_Website")}
              control={control}
              disabled={isDisabled}
            />

            <Typography>
              {t("OrganizationEditForm_Title_SocialMediaLinks")}
            </Typography>
            <ControlledTextField
              fullWidth
              name="socialMediaFacebook"
              label={t("OrganizationEditForm_Field_Facebook")}
              control={control}
              disabled={isDisabled}
            />
            <ControlledTextField
              fullWidth
              name="socialMediaInstagram"
              label={t("OrganizationEditForm_Field_Instagram")}
              control={control}
              disabled={isDisabled}
            />
            <ControlledTextField
              fullWidth
              name="socialMediaLinkedIn"
              label={t("OrganizationEditForm_Field_LinkedIn")}
              control={control}
              disabled={isDisabled}
            />
            <ControlledTextField
              fullWidth
              name="socialMediaTwitter"
              label={t("OrganizationEditForm_Field_Twitter")}
              control={control}
              disabled={isDisabled}
            />
            <ControlledTextField
              fullWidth
              name="socialMediaYoutube"
              label={t("OrganizationEditForm_Field_Youtube")}
              control={control}
              disabled={isDisabled}
            />
          </PaperWithTitleView>
        </Grid>
        {userId && (
          <Grid item xs={6}>
            <PaperWithTitleView
              title={<T k="OrganizationEditForm_Title_OrganizationLayout" />}
            >
              <Typography variant="h5">
                <T k="OrganizationEditForm_Text_CompanyLogo" />
              </Typography>
              <OrganizationLogoUploadView
                organizationId={organization.id}
                organizationLogo={organization.layout?.logo}
                userId={userId}
              />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>
                  <T k="OrganizationEditForm_Field_UseLogo" />
                </Typography>

                <ControlledSwitch
                  label=""
                  name="useLogo"
                  control={control}
                  disabled={isDisabled}
                />
              </Stack>

              <OrganizationColorPickerView
                primaryColor={watch("primaryColor")}
                name="primaryColor"
                control={control}
              />
            </PaperWithTitleView>
          </Grid>
        )}
        {canWriteHubSpot && (
          <Grid item xs={6}>
            <Paper>
              <Stack spacing={5} p={5}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h3">
                    {t("OrganizationEditForm_Title_HubspotInformation")}
                  </Typography>

                  <ControlledSwitch
                    name="isHubspotEnabled"
                    label={t("OrganizationEditForm_Field_HubspotEnabled")}
                    control={control}
                    disabled={isDisabled}
                  />
                </Stack>

                <Stack mt={6} spacing={3}>
                  <ControlledTextField
                    fullWidth
                    name="hubspotCompanyId"
                    label={t("OrganizationEditForm_Field_HubspotId")}
                    control={control}
                    disabled={isDisabled || !isHubspotEnabled}
                  />
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        )}
      </Grid>

      {!organization.isDeleted && (
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          justifyContent="center"
          mt={5}
          mb={5}
        >
          {canDeleteOrganization && (
            <Button
              variant="text"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => setIsOrganizationDeleteDialogOpen(true)}
              sx={{ minWidth: 200 }}
            >
              {t("OrganizationEditForm_Button_DeleteOrganization")}
            </Button>
          )}
          {isProvider && (
            <Button
              variant="text"
              disabled={isDisabled}
              href={`/providers/${organization.id}/create`}
              sx={{ minWidth: 200 }}
            >
              {t("OrganizationEditForm_Button_EditProviderProfile")}
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            disabled={isDisabled}
            sx={{ minWidth: 200 }}
          >
            {t("Common_Save")}
          </Button>
        </Stack>
      )}
      <OrganizationDeleteDialog
        organization={organization}
        isOpen={isOrganizationDeleteDialogOpen}
        close={() => setIsOrganizationDeleteDialogOpen(false)}
      />
    </form>
  );
};
