import ToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";
import Tooltip from "@mui/material/Tooltip";
import { FC, forwardRef } from "react";

type TooltipToggleButtonProps = ToggleButtonProps & {
  title: string;
};

export const TooltipToggleButton: FC<TooltipToggleButtonProps> = forwardRef<
  HTMLButtonElement,
  TooltipToggleButtonProps
>(({ title, ...props }, ref) => {
  return (
    <Tooltip title={title}>
      <ToggleButton ref={ref} {...props} />
    </Tooltip>
  );
});

TooltipToggleButton.displayName = "TooltipToggleButton";
