import AddCircleIcon from "@mui/icons-material/AddCircle";
import CategoryIcon from "@mui/icons-material/Category";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PersonIcon from "@mui/icons-material/Person";
import PollIcon from "@mui/icons-material/Poll";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
  Call,
  CALL_PROCESS_REQUEST_KINDS,
  CallLog,
  CallProcessRequestKind,
  FieldValueStruct,
  ProcessStatus,
  ProcessStatusStruct,
  TimestampStruct,
} from "@snubes/snubes-types";
import { CALL_PROCESS_REQUEST_KIND_RECORD } from "../../../../snubes-backend/src/Calls/consts/CALL_PROCESS_REQUEST_KIND_RECORD";
import { PROCESS_STATUS_RECORD } from "../../Common/consts/PROCESS_STATUS_RECORD";
import { useLogListItemProps } from "../helpers/useLogListItemProps";
import { LogListItem } from "../types/LogListItem";
import { LogListItemView } from "./LogListItemView";

export function LogListItemViewForCall<T extends CallLog>(
  props: LogListItem<T>,
) {
  const restProps = useLogListItemProps(props);
  const { update } = props.log;

  if (update.id) {
    return (
      <LogListItemView
        {...restProps}
        title="Call created"
        iconColor="secondary.main"
        iconComponent={AddCircleIcon}
      />
    );
  }

  for (const requestKind of CALL_PROCESS_REQUEST_KINDS) {
    if (requestKind === "FLAGGING") continue;
    const { processKey } = CALL_PROCESS_REQUEST_KIND_RECORD[requestKind];
    const processStatus = update[`${processKey}.status`];
    if (!ProcessStatusStruct.is(processStatus)) continue;

    return (
      <LogListItemView
        {...restProps}
        title={`${CALL_PROCESS_REQUEST_KIND_TRANSLATION_RECORD[requestKind]} ${PROCESS_STATUS_TRANSLATION_RECORD[processStatus]}`}
        iconColor={PROCESS_STATUS_RECORD[processStatus].color}
        iconComponent={PROCESS_STATUS_RECORD[processStatus].Icon}
      />
    );
  }

  if (
    TimestampStruct.is(update.reviewCompletedAt) &&
    typeof update.assigneeName === "string"
  ) {
    return (
      <LogListItemView
        {...restProps}
        title={`Completed by ${update.assigneeName}`}
        iconColor="text.primary"
        iconComponent={CheckBoxIcon}
      />
    );
  }

  if (update.assigneeUserId && typeof update.assigneeName === "string") {
    return (
      <LogListItemView
        {...restProps}
        title={`Assigned to ${update.assigneeName}`}
        iconColor="warning.main"
        iconComponent={PersonIcon}
      />
    );
  }

  const keys = Object.keys(update) as (keyof typeof update)[];

  for (const key of keys) {
    if (key.startsWith("scorecards")) {
      const value = update[key];
      if (FieldValueStruct.is(value) && value.type === "DELETE") {
        return (
          <LogListItemView
            {...restProps}
            title="Scorecard deleted"
            iconColor="error.main"
            iconComponent={PollIcon}
          />
        );
      }

      if (typeof update[key] === "object") {
        return (
          <LogListItemView
            {...restProps}
            title="Scorecard submitted"
            iconColor="secondary.main"
            iconComponent={PollIcon}
          />
        );
      }
    }

    const voteKey = VOTE_KEYS.find((voteKey) => key.startsWith(`${voteKey}.`));

    if (voteKey) {
      return (
        <LogListItemView
          {...restProps}
          title={VOTE_KEY_TRANSLATION_RECORD[voteKey]}
          iconColor={update[key] ? "primary.main" : "error.main"}
          iconComponent={update[key] ? ThumbUpIcon : ThumbDownIcon}
        />
      );
    }
  }

  if (update.categoryId) {
    return (
      <LogListItemView
        {...restProps}
        title="Category updated"
        iconColor="primary.main"
        iconComponent={CategoryIcon}
      />
    );
  }

  return (
    <LogListItemView
      {...restProps}
      title="Call edited"
      iconColor="primary.main"
      iconComponent={ChangeCircleIcon}
    />
  );
}

const PROCESS_STATUS_TRANSLATION_RECORD: Record<ProcessStatus, string> = {
  SUCCEEDED: "succeeded",
  FAILED: "failed",
  ON_HOLD: "on hold",
  PROCESSING: "started",
  REQUESTED: "requested",
};

const CALL_PROCESS_REQUEST_KIND_TRANSLATION_RECORD: Record<
  CallProcessRequestKind,
  string
> = {
  VOICEFILE: "Voicefile",
  TRANSCRIPTS: "Transcripts",
  SUMMARY: "Summary",
  CATEGORY: "Category",
  QUESTION_ANSWERS: "Question answers",
  FLAGGING: "Flagging",
};

const VOTE_KEY_TRANSLATION_RECORD = {
  isQuestionAnswerHelpful: "Voted Answer",
  isSummaryHelpful: "Voted Summary",
  isTranscriptHelpful: "Voted Transcript",
} satisfies Partial<Record<keyof Call, string>>;

const VOTE_KEYS = Object.keys(
  VOTE_KEY_TRANSLATION_RECORD,
) as (keyof typeof VOTE_KEY_TRANSLATION_RECORD)[];
