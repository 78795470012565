import { Alert, Stack, Typography } from "@mui/material";
import {
  FirebaseFilter,
  Provider,
  ProviderStatus,
  TenderApplication,
} from "@snubes/snubes-types";
import { FC, useCallback, useState } from "react";
import { Controller } from "react-hook-form";
import { array, string, type } from "superstruct";
import { ProvidersCollectionTableView } from "../../Providers/views/ProvidersCollectionTableView";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";
import { TenderWizardProvidersDialog } from "./TenderWizardProvidersDialog";

const FILTERS: FirebaseFilter<Provider>[] = [
  ["status", "==", "PUBLISHED" satisfies ProviderStatus],
  ["hasOrganizationMembers", "==", true],
  ["isDeleted", "==", false],
];

const FormValuesStruct = type({
  matchedProviderIds: array(string()),
});

export const TenderWizardProvidersForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => ({
      matchedProviderIds: Object.keys(tender?.providerApplications || []),
    }),
    toDoc: (formValues, tender) => {
      const providerApplications: Record<
        string,
        Partial<TenderApplication> | null
      > = Object.fromEntries([
        // Include existing, pending applications as "null" to delete them.
        ...Object.entries(tender?.providerApplications || {})
          .filter(([, application]) => application.status === "PENDING")
          .map(([providerId]) => [providerId, null] as const),
        // Add new pending applications for the matched providers. This overwrites any "null" entries from above.
        ...(
          formValues.matchedProviderIds?.filter(
            // Only include IDs of providers that are not already in the tender applications or only have a pending application.
            (id) =>
              !tender?.providerApplications?.[id]?.status ||
              tender?.providerApplications?.[id]?.status === "PENDING",
          ) || []
        ).map((providerId): [string, Partial<TenderApplication>] => {
          const providerApplication: Partial<TenderApplication> = {
            providerId,
            status: "PENDING",
          };
          return [providerId, providerApplication];
        }),
      ]);

      return {
        // We need to cast because the Wizard state type requires the full object, however
        // the API accepts (nullable) partials for the provider applications.
        providerApplications: providerApplications as Record<
          string,
          TenderApplication
        >,
        isReviewed: {
          ...tender?.isReviewed,
          providers: false,
        },
      };
    },
  });

  const tenderStatus = useTenderWizardStore((state) => state.doc?.status);
  const [selectedProvider, setSelectedProvider] = useState<Provider>();

  const isCheckboxDisabled = useCallback((provider: Provider) => {
    // We cannot uncheck a provider who's application has already been submitted.
    const existingProviderApplications =
      useTenderWizardStore.getState().doc?.providerApplications || {};

    if (!existingProviderApplications[provider.id]) {
      return false;
    }
    const { status } = existingProviderApplications[provider.id];
    if (status === "PENDING" || status === "REJECTED_WITHOUT_APPLICATION") {
      return false;
    }
    return true;
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
          mb={4}
          paddingLeft={4}
          paddingRight={3}
        >
          <Typography variant="h1">
            {t("TenderWizardProvidersForm_Title_ChooseProviders")}
          </Typography>

          {tenderStatus === "DRAFT" && (
            <Alert severity="info">
              {t("TenderWizardProvidersForm_Alert_ChoosingProvidersOptional")}
            </Alert>
          )}
        </Stack>

        <Controller
          name="matchedProviderIds"
          control={control}
          render={({ field }) => (
            <ProvidersCollectionTableView
              hasCheckboxes
              filters={FILTERS}
              onRowSelectionChanged={field.onChange}
              isCheckboxDisabled={isCheckboxDisabled}
              selectedRowIds={field.value}
              onClickRow={setSelectedProvider}
            />
          )}
        />
      </form>
      <TenderWizardProvidersDialog
        provider={selectedProvider}
        close={() => setSelectedProvider(undefined)}
      />
    </>
  );
};
