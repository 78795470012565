import { Paper } from "@mui/material";
import { Project } from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import { toLocaleDecimal } from "../../Common/helpers/toLocaleDecimal";
import { toLocaleMinutes } from "../../Common/helpers/toLocaleMinutes";
import { toLocalePercent } from "../../Common/helpers/toLocalePercent";
import { RelativeTimestampView } from "../../Common/views/RelativeTimestampView";
import { CollectionTableColumn } from "../../Firebase/types/CollectionTableColumn";
import { FirebasePagination } from "../../Firebase/types/FirebasePagination";
import { FirebaseTableView } from "../../Firebase/views/FirebaseTableView";
import { useT } from "../../Translation/hooks/useT";
import { getProjectAnsweredCallsCount } from "../helpers/getProjectAnsweredCallsCount";
import { getProjectAverageHandlingTimeInSeconds } from "../helpers/getProjectAverageHandlingTimeInSeconds";
import { getProjectReachabilityPercentage } from "../helpers/getProjectReachabilityPercentage";
import { getProjectServiceLevelPercentage } from "../helpers/getProjectServiceLevelPercentage";
import { getProjectTotalCallsCount } from "../helpers/getProjectTotalCallsCount";
import { getProjectTotalHandlingTimeInSeconds } from "../helpers/getProjectTotalHandlingTimeInSeconds";
import { ProjectReportFramesStatusChip } from "./ProjectReportFramesStatusChip";

interface Props {
  rows: Project[];
  isLoading: boolean;
  pagination: FirebasePagination;
}

export const ProjectsTableView: FC<Props> = (props) => {
  const { rows, isLoading, pagination } = props;
  const t = useT();

  const columns = useMemo<CollectionTableColumn<Project>[]>(
    () => [
      {
        title: t("ProjectsTableView_Title_Name"),
        field: "name",
      },
      {
        title: t("ProjectsTableView_Title_ProviderOrganizationName"),
        field: "providerOrganizationName",
      },
      {
        title: t("ProjectsTableView_Title_ClientOrganizationName"),
        field: "clientOrganizationName",
      },
      {
        title: t("ProjectsTableView_Title_ReportFramesNumberOfCalls"),
        renderCell: (project) => {
          const { today, lastSevenDays } = getProjectTotalCallsCount(project);

          return `${toLocaleDecimal(today)} (Ø ${toLocaleDecimal(
            lastSevenDays,
          )})`;
        },
      },
      {
        title: t("ProjectsTableView_Title_ReportFramesCallsAnswered"),
        renderCell: (project) => {
          const { today, lastSevenDays } =
            getProjectAnsweredCallsCount(project);

          return `${toLocaleDecimal(today)} (Ø ${toLocaleDecimal(
            lastSevenDays,
          )})`;
        },
      },
      {
        title: t("ProjectsTableView_Title_ReportFramesReachability"),
        renderCell: (project) => {
          const { today, lastSevenDays } =
            getProjectReachabilityPercentage(project);

          return `${toLocalePercent(today)} (Ø ${toLocalePercent(
            lastSevenDays,
          )})`;
        },
      },
      {
        title: t("ProjectsTableView_Title_ReportFramesAverageHandlingTime"),
        renderCell: (project) => {
          const { today, lastSevenDays } =
            getProjectAverageHandlingTimeInSeconds(project);

          return `${toLocaleMinutes(today * 1000)} (Ø ${toLocaleMinutes(
            lastSevenDays * 1000,
          )})`;
        },
      },
      {
        title: t("ProjectsTableView_Title_ReportFramesTotalHandlingTime"),
        renderCell: (project) => {
          const { today, lastSevenDays } =
            getProjectTotalHandlingTimeInSeconds(project);

          return `${toLocaleMinutes(today * 1000)} (Ø ${toLocaleMinutes(
            lastSevenDays * 1000,
          )})`;
        },
      },
      {
        title: t("ProjectsTableView_Title_ReportFramesServiceLevel"),
        renderCell: (project) => {
          const { today, lastSevenDays } =
            getProjectServiceLevelPercentage(project);

          return `${toLocalePercent(today)} (Ø ${toLocalePercent(
            lastSevenDays,
          )})`;
        },
      },
      {
        title: t("ProjectsTableView_Title_ReportFramesUpdatedAt"),
        renderCell: (project) => {
          return project.reportFramesByDay?.length ? (
            <RelativeTimestampView
              timestamp={project.updatedAt}
              shouldBeInThePast
              translation
              hasTooltip
            />
          ) : (
            <ProjectReportFramesStatusChip project={project} />
          );
        },
      },
    ],
    [t],
  );

  return (
    <Paper>
      <FirebaseTableView
        keyField="id"
        pagination={pagination}
        rows={rows}
        isLoading={isLoading}
        columns={columns}
        getTo={(project) => `/projects/${project.id}`}
      />
    </Paper>
  );
};
