import { Box, Grid, Stack, Typography } from "@mui/material";
import { USER_TYPES, UserType } from "@snubes/snubes-types";
import { FC, useCallback, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { updateUserCallable } from "../../Users/callables/updateUserCallable";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { OnboardingUserTypeCard } from "./OnboardingUserTypeCard";

export const OnboardingUserTypeView: FC = () => {
  const userId = useMyUser((state) => state.user?.id);
  const t = useT();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSelectUserType = useCallback(
    async (userType: UserType) => {
      try {
        if (userId) {
          setIsSubmitting(true);
          await updateUserCallable({
            userId,
            user: {
              userType,
            },
          });
        }
      } catch (error) {
        handleError(error).logAnd().toast();
      } finally {
        setIsSubmitting(false);
      }
    },
    [userId],
  );

  return (
    <Stack spacing={3} pt={4} alignItems="center">
      <Stack spacing={4} maxWidth="25rem" textAlign="center">
        <Typography variant="h2">
          {t("OnboardingUserTypeView_Headline_TellUs")}
        </Typography>
        <Typography color="text.500" variant="subtitle1">
          {t("OnboardingUserTypeView_Subtitle_Choose")}
        </Typography>
      </Stack>
      <Box p={3}>
        <Grid container spacing={3}>
          {USER_TYPES.filter((userType) => userType !== "ADMIN").map(
            (userType) => {
              return (
                <Grid key={userType} item xs={12} sm={12} md={4}>
                  <OnboardingUserTypeCard
                    userType={userType}
                    onSelectUserType={onSelectUserType}
                    isDisabled={isSubmitting}
                  />
                </Grid>
              );
            },
          )}
        </Grid>
      </Box>
    </Stack>
  );
};
