import { superstructResolver } from "@hookform/resolvers/superstruct";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import { PasswordStruct } from "@snubes/snubes-types";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { FC, useCallback, useMemo } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { define, Infer, string, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { auth } from "../../Firebase/consts/FIREBASE";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { T } from "../../Translation/views/T";
import { updateUserAuthCallable } from "../callables/updateUserPasswordCallable";

interface Props {
  close: () => void;
}

export const UserEditPasswordDialog: FC<Props> = (props) => {
  const [authState] = useAuthState(auth);
  const t = useT();

  const FormValuesStruct = useMemo(
    () =>
      type({
        currentPassword: string(),
        password: PasswordStruct,
        passwordRepeat: define("password-repeat", (value, context) => {
          const { password } = context.branch[0] as { password: string };
          return password !== value
            ? t("UserEditPasswordDialog_WrongPassword")
            : true;
        }),
      }),
    [t],
  );

  type FormValues = Infer<typeof FormValuesStruct>;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      currentPassword: "",
      password: "",
      passwordRepeat: "",
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!authState || !authState.email) return;

        const credential = EmailAuthProvider.credential(
          authState.email,
          formValues.currentPassword,
        );

        await reauthenticateWithCredential(authState, credential);

        await updateUserAuthCallable({
          userId: authState.uid,
          password: formValues.password,
        });

        toast.success(t("UserEditPasswordDialog_Success_Message"));
        props.close();
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [authState, props, t],
  );

  return (
    <Dialog onClose={props.close} open fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <T k="UserEditPasswordDialog_Title" />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Stack spacing={3}>
            <ControlledTextField
              required
              fullWidth
              name="currentPassword"
              type="password"
              label={t("UserEditPasswordDialog_Field_CurrentPassword")}
              control={control}
              disabled={isSubmitting}
            />
            <ControlledTextField
              required
              fullWidth
              name="password"
              type="password"
              label={t("UserEditPasswordDialog_Field_NewPassword")}
              control={control}
              disabled={isSubmitting}
            />
            <ControlledTextField
              required
              fullWidth
              name="passwordRepeat"
              type="password"
              label={t("UserEditPasswordDialog_Field_ConfirmNewPassword")}
              control={control}
              disabled={isSubmitting}
            />
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            disabled={isSubmitting}
            onClick={props.close}
          >
            {t("Common_Close")}
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {t("Common_Save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
