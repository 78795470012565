import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
} from "firebase/firestore";
import { useMemo } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { getDataFromDocumentSnapshot } from "../helpers/getDataFromDocumentSnapshot";

export function useValidDocumentData<T>(
  docRef: DocumentReference<DocumentData> | null,
  isT: (obj: unknown) => obj is T,
): [
  doc: T | undefined,
  isLoading: boolean,
  error: FirestoreError | undefined,
  snapshot: DocumentSnapshot<DocumentData> | undefined,
] {
  const [snapshot, isLoading, error] = useDocument(docRef);

  const doc = useMemo(
    () => getDataFromDocumentSnapshot(isT, snapshot),
    [isT, snapshot],
  );

  return [doc, isLoading, error, snapshot];
}
