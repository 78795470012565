import { TranslationKey } from "../../Translation/types/TranslationKey";
import { TimeDiffTranslationFormat } from "../types/TimeDiffTranslationFormat";

interface TimeUnitRecordValue {
  short: TranslationKey;
  long: TranslationKey;
}

/**
 * TODO: Refactor to use TranslationKey
 */
export const TIME_UNIT_RECORD: Record<
  keyof TimeDiffTranslationFormat,
  TimeUnitRecordValue
> = {
  months: {
    short: "TimeUnitRecord_LabelShort_Months",
    long: "TimeUnitRecord_Label_Months",
  },
  days: {
    short: "TimeUnitRecord_LabelShort_Days",
    long: "TimeUnitRecord_Label_Days",
  },
  hours: {
    short: "TimeUnitRecord_LabelShort_Hours",
    long: "TimeUnitRecord_Label_Hours",
  },
  minutes: {
    short: "TimeUnitRecord_LabelShort_Minutes",
    long: "TimeUnitRecord_Label_Minutes",
  },
  seconds: {
    short: "TimeUnitRecord_LabelShort_Seconds",
    long: "TimeUnitRecord_Label_Seconds",
  },
};
