import { Tender } from "@snubes/snubes-types";
import { createContext, useContext } from "react";

interface TenderContext {
  tenderId?: string;
  tender?: Tender;
  isOwnTender?: boolean;
  isLoading: boolean;
  error?: Error;
}

const context = createContext<TenderContext>({ isLoading: true });

export const TenderContextProvider = context.Provider;

export function useTenderContext() {
  return useContext(context);
}
