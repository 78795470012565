import { Container, Stack, Typography } from "@mui/material";
import { IndustryStruct } from "@snubes/snubes-types";
import { FC } from "react";
import { array, type } from "superstruct";
import { ControlledIndustriesAutocomplete } from "../../Form/views/ControlledIndustriesAutocomplete";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  industries: array(IndustryStruct),
});

export const ProviderWizardIndustriesForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      industries: provider?.industries || [],
    }),
    toDoc: (formValues) => ({
      industries: formValues.industries?.length
        ? formValues.industries
        : undefined,
    }),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("ProviderWizardIndustriesForm_Title_Industries")}
        </Typography>
        <Typography variant="body1" color="text.500" mb={4}>
          {t("ProviderWizardIndustriesForm_Paragraph_Industries")}
        </Typography>
        <Stack spacing={3}>
          <ControlledIndustriesAutocomplete
            control={control}
            name="industries"
            label={t(
              "ProviderWizardIndustriesForm_Label_IndustriesAutocomplete",
            )}
            fullWidth
            multiple
            required
          />
        </Stack>
      </Container>
    </form>
  );
};
