import { Container, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { FC } from "react";
import { string, type } from "superstruct";
import { ControlledDatePicker } from "../../Form/views/ControlledDatePicker";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  description: string(),
  organizationFoundedInYear: string(),
});

export const ProviderWizardDescriptionForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      description: provider?.description || "",
      organizationFoundedInYear:
        provider?.organizationFoundedInYear?.toString() || "",
    }),
    toDoc: (formValues) => ({
      description: formValues.description,
      organizationFoundedInYear: formValues.organizationFoundedInYear
        ? Number(formValues.organizationFoundedInYear)
        : undefined,
    }),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("ProviderWizardDescriptionForm_Title_Description")}
        </Typography>
        <Stack spacing={3}>
          <ControlledTextField
            required
            fullWidth
            name="description"
            autoComplete="off"
            label={t("ProviderWizardDescriptionForm_Label_Description")}
            control={control}
            multiline
            minRows={3}
            autoFocus
          />
          <ControlledDatePicker
            required
            fullWidth
            name="organizationFoundedInYear"
            label={t("OrganizationEditForm_Field_Founded")}
            control={control}
            disableFuture
            view="year"
            autoFocus
            minDate={DateTime.fromISO("1800-01-01")}
          />
        </Stack>
      </Container>
    </form>
  );
};
