import { Call, CallTranscriptSource } from "@snubes/snubes-types";

/**
 * Get filtered list of transcript items for a call and a given transcript source.
 */
export function getCallTranscripts(call: Call, source?: CallTranscriptSource) {
  if (!call.transcripts?.length) {
    return;
  }
  // By default, only consider the first transcript source in case there are multiple.
  const onlyIncludeSource = source || call.transcripts[0].transcriptSource;
  const transcripts = call.transcripts.filter(
    (t) => t.transcriptSource === onlyIncludeSource,
  );
  if (transcripts.length > 0) {
    return transcripts;
  }
  // As a fallback, return all transcripts that don't have a transcript source. This only happens
  // with old data, where call.transcripts[number].transcriptSource was not yet implemented.
  return call.transcripts.filter((t) => !t.transcriptSource);
}
