import { FC } from "react";
import { Outlet } from "react-router-dom";
import { NavTab } from "../../Common/types/NavTab";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";
import {
  ADMIN_PROVIDERS_ROUTE_RECORD,
  ADMIN_PROVIDERS_ROUTES,
} from "../consts/ADMIN_PROVIDERS_ROUTE_RECORD";

const TABS = ADMIN_PROVIDERS_ROUTES.map<NavTab>((route) => ({
  label: ADMIN_PROVIDERS_ROUTE_RECORD[route].label,
  route,
}));

export const AdminProvidersLayoutView: FC = () => {
  return (
    <>
      <SecondaryHeaderView pathPrefix="/admin/providers" tabs={TABS} />
      <Outlet />
    </>
  );
};
