import { FirebaseFilter, Project } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

export const ADMIN_PROJECTS_ROUTES = ["all", "archived", "deleted"] as const;

type Route = (typeof ADMIN_PROJECTS_ROUTES)[number];

interface RouteRecordValue {
  label: TranslationKey;
  filters: FirebaseFilter<Project>[];
  hasCreateButton?: boolean;
}

export const ADMIN_PROJECTS_ROUTE_RECORD: Record<Route, RouteRecordValue> = {
  all: {
    label: "AdminProjectsRouteRecord_Label_All",
    filters: [
      ["isDeleted", "==", false],
      ["isArchived", "==", false],
    ],
    hasCreateButton: true,
  },
  archived: {
    label: "AdminProjectsRouteRecord_Label_Archived",
    filters: [
      ["isDeleted", "==", false],
      ["isArchived", "==", true],
    ],
  },
  deleted: {
    label: "AdminProjectsRouteRecord_Label_Deleted",
    filters: [["isDeleted", "==", true]],
  },
};
