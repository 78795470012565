import { OrganizationRevenueValue } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface ProviderRevenueRangeRecordValue {
  index: number;
  value: OrganizationRevenueValue;
  labelValue: number;
  label: TranslationKey;
}

export const PROVIDER_REVENUE_RANGE_RECORD: Record<
  OrganizationRevenueValue,
  ProviderRevenueRangeRecordValue
> = {
  0: {
    index: 0,
    value: 0,
    labelValue: 0,
    label: "Common_Million_Short_LessThan",
  },
  1_000_000: {
    index: 1,
    value: 1_000_000,
    labelValue: 1,
    label: "Common_Million_Short",
  },
  10_000_000: {
    index: 2,
    value: 10_000_000,
    labelValue: 10,
    label: "Common_Million_Short",
  },
  50_000_000: {
    index: 3,
    value: 50_000_000,
    labelValue: 50,
    label: "Common_Million_Short",
  },
  100_000_000: {
    index: 4,
    value: 100_000_000,
    labelValue: 100,
    label: "Common_Million_Short",
  },
  200_000_000: {
    index: 5,
    value: 200_000_000,
    labelValue: 200,
    label: "Common_Million_Short",
  },
  500_000_000: {
    index: 6,
    value: 500_000_000,
    labelValue: 500,
    label: "Common_Million_Short",
  },
  1_000_000_000: {
    index: 7,
    value: 1_000_000_000,
    labelValue: 1,
    label: "Common_Billion_Short",
  },
  1_000_000_001: {
    index: 8,
    value: 1_000_000_001,
    labelValue: 1,
    label: "Common_Billion_Short_GreaterThan",
  },
};
