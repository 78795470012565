import {
  FirebaseFilter,
  FirebaseOrderBy,
  isProject,
  OrganizationType,
  Project,
} from "@snubes/snubes-types";
import { useMemo } from "react";
import { usePaginatedCollection } from "../../Firebase/hooks/usePaginatedCollection";

interface Args {
  organizationType?: OrganizationType;
  organizationId?: string;
  rowsPerPage: number;
}

export function useProjects(args: Args) {
  const { organizationType, organizationId = null } = args;

  const filters = useMemo<FirebaseFilter<Project>[]>(() => {
    const filters: FirebaseFilter<Project>[] = [
      ["isDeleted", "==", false],
      ["isArchived", "==", false],
    ];

    switch (organizationType) {
      case "CLIENT":
        filters.push(["clientOrganizationId", "==", organizationId]);
        break;
      case "PROVIDER":
        filters.push(["providerOrganizationId", "==", organizationId]);
        break;
      default:
        filters.push(["organizationIds", "array-contains", organizationId]);
    }

    return filters;
  }, [organizationType, organizationId]);

  const orderBy = useMemo<FirebaseOrderBy<Project>>(
    () => ["createdAt", "desc"],
    [],
  );

  return usePaginatedCollection({
    isT: isProject,
    collectionName: "projects",
    filters,
    orderBy,
    rowsPerPage: args.rowsPerPage,
  });
}
