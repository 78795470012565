import { ConnectorConfig, isConnectorConfig } from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { CollectionTableColumn } from "../../Firebase/types/CollectionTableColumn";
import { CollectionTableView } from "../../Firebase/views/CollectionTableView";
import { useT } from "../../Translation/hooks/useT";
import { CONNECTOR_PLATFORM_RECORD } from "../consts/CONNECTOR_PLATFORM_RECORD";
import { ConnectorConfigDeleteButton } from "./ConnectorConfigDeleteButton";
import { ConnectorConfigStatusChip } from "./ConnectorConfigStatusChip";

interface Props {
  organizationId: string;
}

export const ConnectorConfigsCollectionTableView: FC<Props> = (props) => {
  const t = useT();
  const hasPermission = useHasPermission();
  const isDeleteAllowed = hasPermission(
    "CAN_WRITE_ORGANIZATION_CONNECTOR_CONFIG",
  );
  const columns = useMemo<CollectionTableColumn<ConnectorConfig>[]>(
    () => [
      {
        title: t("ConnectorConfigsCollectionTableView_Header_Name"),
        field: "name",
      },
      {
        title: t("ConnectorConfigsCollectionTableView_Header_Platform"),
        renderCell: (connectorConfig) =>
          t(CONNECTOR_PLATFORM_RECORD[connectorConfig.platform].label),
      },
      {
        title: t("ConnectorConfigsCollectionTableView_Header_CreatedAt"),
        field: "createdAt",
        align: "left",
      },

      {
        title: t("ConnectorConfigsCollectionTableView_Header_UpdatedAt"),
        field: "updatedAt",
      },
      {
        title: t("ConnectorConfigsCollectionTableView_Header_Status"),
        sx: { py: 0 },
        renderCell: (connectorConfig) => {
          return <ConnectorConfigStatusChip status={connectorConfig.status} />;
        },
      },
      {
        title: "",
        align: "right",
        sx: { py: 0 },
        renderCell: (connector) => {
          return (
            !!isDeleteAllowed && (
              <ConnectorConfigDeleteButton
                connectorConfigId={connector.id}
                organizationId={props.organizationId}
              />
            )
          );
        },
      },
    ],
    [isDeleteAllowed, props.organizationId, t],
  );

  return (
    <CollectionTableView
      collectionName={`organizations/${props.organizationId}/connectorConfigs`}
      orderBy={["createdAt", "desc"]}
      keyField="id"
      isT={isConnectorConfig}
      getTo={(connectorConfig) =>
        `/organizations/${props.organizationId}/connector-configs/${connectorConfig.id}`
      }
      columns={columns}
    />
  );
};
