import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { CallScorecard } from "@snubes/snubes-types";
import { FC, Fragment } from "react";
import toast from "react-hot-toast";
import { useT } from "../../Translation/hooks/useT";
import { deleteCallScorecardCallable } from "../callables/deleteCallScorecardCallable";

interface Props {
  scorecard?: CallScorecard;
  isOpen: boolean;
  onClose: () => void;
}

export const CallProjectScorecardDialog: FC<Props> = (props) => {
  const t = useT();

  return (
    <Dialog open={props.isOpen} onClose={props.onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t("CallProjectScorecardDialog_Title_Scorecard")}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          {props.scorecard?.items.map((item, i, items) => (
            <Fragment key={item.question}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack spacing={2}>
                  <Typography variant="h5">Question {i + 1}</Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {item.question}
                  </Typography>
                </Stack>

                <Typography variant="h3">
                  {item.score}/{item.maxScore}
                </Typography>
              </Stack>
              {item.remark && (
                <Stack
                  spacing={1}
                  bgcolor="background.default"
                  color="text.secondary"
                  p={2}
                  borderRadius={1}
                  border={1}
                  borderColor="divider"
                >
                  <Typography variant="h5">
                    {t("CallProjectScorecardDialog_Title_Remark")}
                  </Typography>
                  <Typography>{item.remark}</Typography>
                </Stack>
              )}
              {i < items.length - 1 ? <Divider /> : null}
            </Fragment>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={async () => {
            if (props.scorecard) {
              await deleteCallScorecardCallable({
                callId: props.scorecard.callId,
                scorecardId: props.scorecard.id,
              });
              props.onClose();
              toast.success(
                t("CallProjectScorecardDialog_Toast_DeletedScorecard"),
              );
            }
          }}
        >
          {t("CallProjectScorecardDialog_Button_DeleteScorecard")}
        </Button>
        <Button onClick={props.onClose} variant="regular">
          {t("Common_Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
