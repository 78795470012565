import { superstructResolver } from "@hookform/resolvers/superstruct";
import { Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { ConnectorConfig } from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Infer, string, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { updateConnectorConfigCallable } from "../callables/updateConnectorConfigCallable";
import { ConnectorConfigErrorView } from "./ConnectorConfigErrorView";
import { ConnectorConfigWebhookView } from "./ConnectorConfigWebhookView";

interface Props {
  connectorConfig: ConnectorConfig & { platform: "GENERIC_WEBHOOK" };
}

const FormValuesStruct = type({
  name: string(),
});

type FormValues = Infer<typeof FormValuesStruct>;

export const ConnectorConfigGenricWebhookEditForm: FC<Props> = (props) => {
  const { connectorConfig } = props;
  const t = useT();
  const { organizationId, connectorConfigId } = useParams<
    "organizationId" | "connectorConfigId"
  >();

  const { control, formState, handleSubmit } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      name: connectorConfig.name,
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!organizationId) {
          throw new Error("No organization selected");
        }
        if (!connectorConfigId) {
          throw new Error("No connectorConfig selected");
        }
        await updateConnectorConfigCallable({
          connectorConfigId,
          organizationId,
          connectorConfig: {
            name: formValues.name,
            projectMappings: connectorConfig.projectMappings,
          },
        });
        toast.success(t("ConnectorConfigForm_Toast_Update_Success"));
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [connectorConfig.projectMappings, connectorConfigId, organizationId, t],
  );

  const { isSubmitting } = formState;

  return (
    <>
      {connectorConfig.status === "INTERRUPTED" &&
        connectorConfig.statusErrorMessage && (
          <ConnectorConfigErrorView
            statusErrorMessage={connectorConfig.statusErrorMessage || ""}
            title={t(
              "ConnectorConfigGenricWebhookEditForm_Title_ConnectionInterrupted",
            )}
          />
        )}
      <Stack component={Paper} variant="outlined" p={4} spacing={5}>
        <Typography variant="h3">
          {t("ConnectorConfigGenricWebhookEditForm_Heading_UpdateConnection")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={5}>
            <ControlledTextField
              autoComplete="off"
              required
              fullWidth
              name="name"
              label={t("ConnectorConfigGenricWebhookEditForm_InputLabel_Name")}
              control={control}
              disabled={isSubmitting}
            />
            <Divider />
            <ConnectorConfigWebhookView connectorConfig={connectorConfig} />
            {connectorConfig.webhookUrl && (
              <Stack spacing={3}>
                <Typography variant="h5">
                  {t(
                    "ConnectorConfigGenricWebhookEditForm_SubHeading_RequestBody",
                  )}
                </Typography>
                <Stack
                  component={Paper}
                  variant="outlined"
                  bgcolor="background.default"
                  overflow="auto"
                  sx={{ fontFamily: "monospace", whiteSpace: "pre" }}
                  p={3}
                >
                  {WEBHOOK_BODY}
                </Stack>
              </Stack>
            )}
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              {t("Common_Update")}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

const WEBHOOK_BODY = `{
  "id": "123", // Your call ID
  "projectId": "68a9a8f5-bb66-4b08-8191-91b379f7a859", // Snubes project ID
  "startTimestamp": "2023-01-01T00:00:00.000Z",
  "agentId": "1234567890", // Your agent ID
  "agentName": "John Doe", // Your agent name (OPTIONAL)
  "source": "+49 123456789", // Caller phone number (OPTIONAL)
  "destination": "+49 987654321", // Called phone number (OPTIONAL)
  "ringSeconds": 5, // Ringing time in seconds
  "talkSeconds": 180, // Talking time in seconds
  "queueSeconds": 10, // Queue time in seconds
  "holdSeconds": 25, // Hold time in seconds
  "preworkSeconds": 10, // Prework time in seconds
  "afterworkSeconds": 120, // Afterwork time in seconds
  "direction": "INBOUND", // "INBOUND" or "OUTBOUND"
  "voicefileUrl": "https://your-domain.com/calls/123/voicefile.mp3" // Public voicefile URL (OPTIONAL)
}`;
