import { BrowserHistory, createBrowserHistory } from "@remix-run/router";
import { useRouter } from "next/navigation";
import { ReactNode, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Navigator, Router } from "react-router-dom";

interface Props {
  basename?: string;
  children?: ReactNode;
}

/**
 * Wrapper for BrowserRouter that keeps the Next.js app router in sync.
 * This is necessary to avoid nasty page reloads when clicking the browser back/forward buttons.
 *
 * @see https://github.com/remix-run/react-router/blob/v6.3.0/packages/react-router-dom/index.tsx#L146
 */
export function NextCompatRouter(props: Props) {
  const { basename, children } = props;
  const router = useRouter();

  const historyRef = useRef<BrowserHistory>();
  if (historyRef.current == null) {
    historyRef.current = createBrowserHistory({ window, v5Compat: true });
  }

  const history = historyRef.current;
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history, setState]);

  const navigator = useMemo<Navigator>(
    () => ({
      ...history,
      go: (delta) => {
        history.go(delta);
        if (delta === 0) {
          router.refresh();
        }
        if (delta > 0) {
          // TODO: handle delta > 1
          router.forward();
        }
        if (delta < 0) {
          // TODO: handle delta < -1
          router.back();
        }
      },
      push: (to, state) => {
        history.push(to, state);
        if (typeof to === "string") {
          router.push(to, { scroll: false });
        } else if (to.pathname) {
          // TODO: handle other properties of `to`
          router.push(to.pathname, { scroll: false });
        }
      },
      replace: (to, state) => {
        history.replace(to, state);
        if (typeof to === "string") {
          router.replace(to, { scroll: false });
        } else if (to.pathname) {
          // TODO: handle other properties of `to`
          router.replace(to.pathname, { scroll: false });
        }
      },
    }),
    [history, router],
  );

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={navigator}
    >
      {children}
    </Router>
  );
}
