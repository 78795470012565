import { FC } from "react";
import { Navigate } from "react-router-dom";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LoadingView } from "../../Common/views/LoadingView";
import { useHomeScreenPath } from "../hooks/useHomeScreenPath";

export const HomeIndexPage: FC = () => {
  const [homeScreenPath, isInitializing] = useHomeScreenPath();

  if (isInitializing) {
    return (
      <FullHeightContainer>
        <LoadingView />
      </FullHeightContainer>
    );
  } else {
    return <Navigate replace to={homeScreenPath} />;
  }
};
