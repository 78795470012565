import { FC } from "react";
import { AvatarView } from "../../Common/views/AvatarView";

interface Props {
  name: string;
  size: number;
}

export const UserAvatarView: FC<Props> = (props) => {
  return <AvatarView name={props.name} size={props.size} />;
};
