import { Grid } from "@mui/material";
import { FC } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { NoDataView } from "../../Common/views/NoDataView";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  organizationId: string;
}

export const ProjectConnectorConfigNotAvailableView: FC<Props> = (props) => {
  const { organizationId } = props;
  const t = useT();
  const hasPermission = useHasPermission();

  return (
    <Grid container alignItems="center" justifyContent="center" flex={1}>
      <Grid item xs={6}>
        <NoDataView
          label={t("ProjectConnectorConfigNotAvailableView_Label_NoData")}
          title={t("ProjectConnectorConfigNotAvailableView_Title_NoData")}
          subTitle={t("ProjectConnectorConfigNotAvailableView_SubTitle_NoData")}
          button={
            hasPermission("CAN_WRITE_ORGANIZATION_CONNECTOR_CONFIG", {
              organizationId,
            })
              ? {
                  label: t(
                    "ProjectConnectorConfigNotAvailableView_Button_NoData",
                  ),
                  href: `/organizations/${organizationId}/connector-configs`,
                }
              : undefined
          }
        />
      </Grid>
    </Grid>
  );
};
