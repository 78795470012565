import { Grid, Typography } from "@mui/material";
import { Call } from "@snubes/snubes-types";
import { FC, MutableRefObject } from "react";
import type WaveSurfer from "wavesurfer.js";
import { toLocaleDateTimeString } from "../../Common/helpers/toLocaleDateTimeString";
import { toLocaleDecimal } from "../../Common/helpers/toLocaleDecimal";
import { toLocaleMinutes } from "../../Common/helpers/toLocaleMinutes";
import { useT } from "../../Translation/hooks/useT";
import { CALL_TRANSCRIPT_SOURCE_RECORD } from "../consts/CALL_TRANSCRIPT_SOURCE_RECORD";
import { getCallTranscriptSources } from "../helpers/getCallTranscriptSources";

interface Props {
  call: Call;
  playerRef: MutableRefObject<WaveSurfer | null>;
  voicefileDurationInSeconds: number;
}

export const CallAudioMetadataView: FC<Props> = (props) => {
  const { call, playerRef, voicefileDurationInSeconds } = props;
  const t = useT();

  const buffer = playerRef.current?.getDecodedData();

  // Only consider the first transcript source.
  const transcriptSource = getCallTranscriptSources(call)[0];

  const items = [
    {
      label: t("CallAudioMetadataView_Label_VoicefileDuration"),
      value: voicefileDurationInSeconds
        ? t("Common_Mins", {
            minutes: toLocaleMinutes(voicefileDurationInSeconds * 1000),
          })
        : "-",
    },
    {
      label: t("CallAudioMetadataView_Label_FileSize"),
      value: buffer?.length
        ? `${toLocaleDecimal(buffer.length / 1_000_000)} MB`
        : "-",
    },
    {
      label: t("CallAudioMetadataView_Label_SampleRate"),
      value: buffer?.sampleRate
        ? `${toLocaleDecimal(buffer.sampleRate / 1_000)} kHz`
        : "-",
    },
    {
      label: t("CallAudioMetadataView_Label_TranscriptSource"),
      value: transcriptSource
        ? t(CALL_TRANSCRIPT_SOURCE_RECORD[transcriptSource].label)
        : "-",
    },
    {
      label: t("CallAudioMetadataView_Label_FileReceivedOn"),
      value: call.isVoicefileAvailable
        ? toLocaleDateTimeString(call.createdAt)
        : "-",
    },
    {
      label: t("CallAudioMetadataView_Label_TranscriptGeneratedOn"),
      value: call.transcriptUpdatedAt
        ? toLocaleDateTimeString(call.transcriptUpdatedAt)
        : "-",
    },
    // TODO: Add summary!
    // { label: t("CallAudioMetadataView_Label_SummaryGeneratedOn"), value: "-" },
  ];

  return (
    <Grid container>
      {items.map((item) => (
        <Grid
          key={item.label}
          item
          lg={3}
          md={4}
          sm={6}
          xs={12}
          p={3}
          borderBottom={1}
          borderRight={1}
          borderColor="divider"
        >
          <Typography variant="body1" color="text.secondary">
            {item.label}
          </Typography>
          <Typography variant="body2" color="text.primary">
            {item.value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
