import type { FirebaseFunctionDefinition } from "@snubes/snubes-types";
import { httpsCallable, HttpsCallableOptions } from "firebase/functions";
import { handleError } from "../../Common/helpers/handleError";
import { functions } from "../consts/FIREBASE";
import { CallableError } from "./CallableError";

/**
 * Name of the server cloud function defined in snubes-backend.
 */
const SERVER_NAME = "app";

interface Args<RequestArgs, ResponseArgs>
  extends FirebaseFunctionDefinition<RequestArgs, ResponseArgs> {
  options?: HttpsCallableOptions;
}

export function getCallable<RequestArgs, ResponseArgs>(
  args: Args<RequestArgs, ResponseArgs>,
) {
  const callable = httpsCallable(
    functions,
    `${SERVER_NAME}${args.path}`,
    args.options,
  );

  return async (requestArgs: RequestArgs): Promise<ResponseArgs> => {
    try {
      const response = await callable(args.getReq(requestArgs));
      return args.getRes?.(response.data) as ResponseArgs;
    } catch (error) {
      const callableError = new CallableError({
        path: args.path,
        error: error as Error,
      });
      return handleError(callableError)
        .logAnd({
          tags: { path: args.path },
          extra: { path: args.path },
        })
        .throw();
    }
  };
}
