import { FormControlProps, SxProps } from "@mui/material";
import { UserRole } from "@snubes/snubes-types";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { getUserName } from "../../Users/helpers/getUserName";
import { useUsers } from "../../Users/hooks/useUsers";
import { ControlledAutocomplete } from "./ControlledAutocomplete";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues & Record<TName, string | null>>;
  name: TName;
  userRoles: UserRole[];
  required?: boolean;
  label?: string;
  fullWidth?: boolean;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  size?: FormControlProps["size"];
  sx?: SxProps;
  multiple?: boolean;
}

export function ControlledUserIdAutocomplete<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const [unsortedUsers, isLoading, error] = useUsers(props.userRoles);
  const users = unsortedUsers.sort((a, b) =>
    getUserName(a).localeCompare(getUserName(b)),
  );

  return (
    <ControlledAutocomplete
      control={props.control}
      name={props.name}
      label={props.label}
      options={users.map((user) => user.id)}
      multiple={props.multiple}
      getOptionLabel={(userId) => {
        const user = users.find((u) => u.id === userId);
        return user ? getUserName(user) : "";
      }}
      required={props.required}
      disabled={props.disabled || isLoading}
      fullWidth={props.fullWidth}
      placeholder={props.placeholder}
      helperText={props.helperText}
      size={props.size}
      sx={props.sx}
      error={error}
    />
  );
}
