import { TranslationKey } from "../../Translation/types/TranslationKey";
import { TimeOfDay } from "../types/TimeOfDay";

interface TimeOfDayRecordValue {
  label: TranslationKey;
}

export const TIME_OF_DAY_GREETING_RECORD: Readonly<
  Record<TimeOfDay, TimeOfDayRecordValue>
> = {
  MORNING: {
    label: "TimeOfDayGreetingRecord_Label_Morning",
  },
  AFTERNOON: {
    label: "TimeOfDayGreetingRecord_Label_Afternoon",
  },
  EVENING: {
    label: "TimeOfDayGreetingRecord_Label_Evening",
  },
  OTHER: {
    label: "TimeOfDayGreetingRecord_Label_Other",
  },
};
