import { TranslationKey } from "../../Translation/types/TranslationKey";

interface SftpCsvField {
  name: string;
  example: string;
  isMandatory: boolean;
  description: TranslationKey;
}

export const SFTP_CSV_FIELDS: SftpCsvField[] = [
  {
    name: "id",
    example: "123",
    isMandatory: true,
    description: "SftpCsvField_Description_Id",
  },
  {
    name: "projectId",
    example: "45678910",
    isMandatory: true,
    description: "SftpCsvField_Description_ProjectId",
  },
  {
    name: "agentId",
    example: "1234567890",
    isMandatory: true,
    description: "SftpCsvField_Description_AgentId",
  },
  {
    name: "agentName",
    example: "John Doe",
    isMandatory: false,
    description: "SftpCsvField_Description_AgentName",
  },
  {
    name: "source",
    example: "+49 123456789",
    isMandatory: false,
    description: "SftpCsvField_Description_Source",
  },
  {
    name: "destination",
    example: "+49 987654321",
    isMandatory: false,
    description: "SftpCsvField_Description_Destination",
  },
  {
    name: "startTimestamp",
    example: "2023-06-01T18:57:25Z",
    isMandatory: true,
    description: "SftpCsvField_Description_StartTimestamp",
  },
  {
    name: "ringSeconds",
    example: "15",
    isMandatory: true,
    description: "SftpCsvField_Description_RingSeconds",
  },
  {
    name: "talkSeconds",
    example: " 180",
    isMandatory: true,
    description: "SftpCsvField_Description_TalkSeconds",
  },
  {
    name: "queueSeconds",
    example: "10",
    isMandatory: true,
    description: "SftpCsvField_Description_QueueSeconds",
  },
  {
    name: "holdSeconds",
    example: "25",
    isMandatory: true,
    description: "SftpCsvField_Description_HoldSeconds",
  },
  {
    name: "afterworkSeconds",
    example: "120",
    isMandatory: true,
    description: "SftpCsvField_Description_AfterworkSeconds",
  },
  {
    name: "preworkSeconds",
    example: "10",
    isMandatory: true,
    description: "SftpCsvField_Description_PreworkSeconds",
  },
  {
    name: "direction",
    example: "INBOUND",
    isMandatory: true,
    description: "SftpCsvField_Description_Direction",
  },
  {
    name: "voicefilePath",
    example: "calls/123/voicefile.mp3",
    isMandatory: false,
    description: "SftpCsvField_Description_VoicefilePath",
  },
];
