import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import { Stack, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { CallTranscript } from "@snubes/snubes-types";
import { FC } from "react";
import { toLocaleMinutes } from "../../Common/helpers/toLocaleMinutes";
import { useT } from "../../Translation/hooks/useT";
import { CALL_PARTICIPANT_ROLE_RECORD } from "../consts/CALL_PARTICIPANT_ROLE_RECORD";
import { CallSentimentIconView } from "./CallSentimentIconView";

interface Props {
  activeTranscriptId?: string;
  transcript: CallTranscript;
  setActiveTranscript: (transcript?: CallTranscript) => void;
}

export const CallTranscriptTableRow: FC<Props> = (props) => {
  const { activeTranscriptId, transcript, setActiveTranscript } = props;
  const t = useT();
  const value = CALL_PARTICIPANT_ROLE_RECORD[transcript.participantRole];

  return (
    <TableRow
      onClick={() => setActiveTranscript(transcript)}
      sx={{
        cursor: "pointer",
        bgcolor: transcript.isFlagged
          ? "error.50"
          : transcript.participantRole === "AGENT"
          ? "background.default"
          : "background.paper",
      }}
    >
      <TableCell
        padding="none"
        sx={{
          position: "relative",
          "::before": {
            content: "''",
            width: transcript.id === activeTranscriptId ? 4 : 0,
            top: 0,
            bottom: 0,
            backgroundColor: "primary.main",
            position: "absolute",
          },
        }}
      >
        <Typography
          p={3}
          pl={5}
          variant="h5"
          color={
            transcript.id === activeTranscriptId ? "primary.main" : "text.600"
          }
        >
          {toLocaleMinutes(transcript.startOffsetMilliseconds)}
        </Typography>
      </TableCell>
      <TableCell>
        <Tooltip title={t(value.label)} arrow>
          <value.Icon sx={{ color: value.color }} />
        </Tooltip>
      </TableCell>
      <TableCell width="100%">{transcript.content}</TableCell>
      <TableCell
        sx={{
          borderRight: 1,
          borderColor: "divider",
        }}
      >
        {transcript.isFlagged && (
          <Stack>
            <FlagCircleIcon color="error" sx={{ fontSize: 32 }} />
          </Stack>
        )}
      </TableCell>
      <TableCell>
        <CallSentimentIconView sentiment={transcript.sentiment} />
      </TableCell>
    </TableRow>
  );
};
