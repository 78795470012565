import { Container, Stack, Typography } from "@mui/material";
import { ServiceTypeStruct } from "@snubes/snubes-types";
import { FC } from "react";
import { array, type } from "superstruct";
import { ControlledServiceTypesField } from "../../Form/views/ControlledServiceTypesField";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  serviceTypes: array(ServiceTypeStruct),
});

export const TenderWizardServicesForm: FC<WizardFormProps<unknown>> = (
  props,
) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => ({
      serviceTypes: tender?.services ? tender.services.map((s) => s.type) : [],
    }),
    toDoc: (formValues, tender) => ({
      services: formValues.serviceTypes?.map((serviceType) => {
        const service = tender?.services?.find((s) => s.type === serviceType);

        return service || { type: serviceType };
      }),
      isReviewed: {
        ...tender?.isReviewed,
        services: false,
      },
    }),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardServicesForm_Title_Services")}
        </Typography>
        <Stack spacing={3}>
          <ControlledServiceTypesField control={control} name="serviceTypes" />
        </Stack>
      </Container>
    </form>
  );
};
