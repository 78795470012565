import AddLocationOutlinedIcon from "@mui/icons-material/AddLocationOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import { Container, Stack, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { array, boolean, nullable, string, type } from "superstruct";
import { ControlledCountriesAutocomplete } from "../../Form/views/ControlledCountriesAutocomplete";
import { ControlledRadioButtons } from "../../Form/views/ControlledRadioButtons";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  isGlobal: nullable(boolean()),
  countries: array(string()),
});

export const TenderWizardCountriesForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit, watch, setValue } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => {
      const isGlobal = tender?.countries
        ? tender?.countries.length === 0 // all/specific locations
        : null; // no selection

      return {
        isGlobal,
        countries: tender?.countries || [],
      };
    },
    toDoc: (formValues, tender) => ({
      countries: !formValues.isGlobal ? formValues.countries : [],
      isReviewed: {
        ...tender?.isReviewed,
        operationalDetails: false,
      },
    }),
  });

  const isGlobal = watch("isGlobal");

  useEffect(() => {
    if (!isGlobal) return;
    setValue("countries", []);
  }, [isGlobal, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardCountriesForm_Title")}
        </Typography>
        <Typography variant="body2" color="text.500" mb={4}>
          {t("TenderWizardCountriesForm_Label")}
        </Typography>
        <Stack spacing={3}>
          <ControlledRadioButtons
            control={control}
            name="isGlobal"
            options={[
              {
                Icon: PublicOutlinedIcon,
                label: "TenderWizardCountriesForm_Label_AllLocations",
                value: true,
                caption: "TenderWizardCountriesForm_Caption_AllLocations",
              },
              {
                Icon: AddLocationOutlinedIcon,
                label: "TenderWizardCountriesForm_Label_SpecificLocations",
                value: false,
                caption: "TenderWizardCountriesForm_Caption_SpecificLocations",
              },
            ]}
            gridSpacing={4}
            gridItemXs={6}
          />

          {isGlobal === false && (
            <ControlledCountriesAutocomplete
              control={control}
              name="countries"
              label={t("TenderWizardCountriesForm_Label_CountriesAutocomplete")}
              multiple
              required
              fullWidth
            />
          )}
        </Stack>
      </Container>
    </form>
  );
};
