import { CollectionName } from "@snubes/snubes-types";
import { getDoc, onSnapshot } from "firebase/firestore";
import debounce from "lodash.debounce";
import { create } from "zustand";
import { handleError } from "../../Common/helpers/handleError";
import { getDataFromDocumentSnapshot } from "../../Firebase/helpers/getDataFromDocumentSnapshot";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { WizardState } from "../../Wizard/types/WizardState";
import { WizardStore } from "../types/WizardStore";

const DEBOUNCE_DELAY_IN_MS = 800;

interface Params<TDoc> {
  collectionName: CollectionName;
  isT: (obj: unknown) => obj is TDoc;
  createOrUpdateDoc: (doc: Partial<TDoc>, docId?: string) => Promise<string>;
  submitDoc?: (docId: string, doc?: Partial<TDoc>) => Promise<void>;
  getIsAutosaveDisabled?: (doc: TDoc) => boolean;
  onInitialize?: (
    docId: string,
    doc?: TDoc,
  ) => Promise<Partial<TDoc>> | Partial<TDoc>;
}

export function createWizardStore<TDoc>(
  params: Params<TDoc>,
): WizardStore<WizardState<TDoc>> {
  const {
    collectionName,
    isT,
    createOrUpdateDoc,
    submitDoc,
    onInitialize,
    getIsAutosaveDisabled,
  } = params;

  return create<WizardState<TDoc>>((set, get) => ({
    formState: {},
    isInitializing: true,
    isInitialized: false,
    isSaving: false,
    isSubmitting: false,
    hasFailedSaving: false,
    isDirty: false,
    isAutosaveDisabled: false,

    getFormValues: <T>(formKey: string) => get().formState[formKey] as T,

    setFormState: (formKey, formValues, update) => {
      const isAutosaveDisabled = get().isAutosaveDisabled;

      set({
        formState: {
          ...get().formState,
          [formKey]: formValues,
        },
        doc: {
          ...get().doc,
          ...update,
        },
        isDirty: true,
      });

      if (!isAutosaveDisabled) {
        get().debouncedSave();
      }
    },

    initialize: async (docId) => {
      if (!docId) {
        return;
      }
      set({ isInitializing: true, error: undefined });

      try {
        const snapshot = await getDoc(getDocRef(collectionName, docId));
        const doc = getDataFromDocumentSnapshot(isT, snapshot);

        if (doc) {
          set({ docId });
          get().doc = (await onInitialize?.(docId, doc)) || doc;
        } else if (onInitialize) {
          get().doc = await onInitialize(docId);
        }
      } catch (error) {
        if (error instanceof Error) {
          set({ error });
        }
      } finally {
        set({ isInitializing: false, isInitialized: true });
      }
    },

    subscribe: (docId) => {
      if (!docId || get().isInitialized) {
        return;
      }
      set({ isInitializing: true, error: undefined });

      const unsubscribeFirestore = onSnapshot(
        getDocRef(collectionName, docId),
        async (snapshot) => {
          if (snapshot.metadata.hasPendingWrites) return;
          const doc = getDataFromDocumentSnapshot(isT, snapshot);

          if (get().isInitialized) {
            set({ doc });
          } else {
            try {
              if (doc) {
                set({ docId });
                get().doc = (await onInitialize?.(docId, doc)) || doc;
                get().isAutosaveDisabled =
                  getIsAutosaveDisabled?.(doc) || false;
              } else if (onInitialize) {
                get().doc = await onInitialize(docId);
              }
            } catch (error) {
              if (error instanceof Error) {
                set({ error });
              }
            } finally {
              set({ isInitializing: false, isInitialized: true });
            }
          }
        },
        (error) => {
          if (error instanceof Error) {
            set({ error, isInitializing: false });
          }
        },
      );

      set({
        unsubscribe: () => {
          set({ doc: undefined, isInitializing: true, isInitialized: false });
          unsubscribeFirestore();
        },
      });
    },

    reset: () => {
      set({
        doc: undefined,
        docId: undefined,
        isInitializing: true,
        isSubmitting: false,
        error: undefined,
        formState: {},
      });
    },

    debouncedSave: debounce(() => get().save(), DEBOUNCE_DELAY_IN_MS, {
      leading: false,
      trailing: true,
    }),

    save: async () => {
      const doc = get().doc;

      if (!doc) return;
      if (get().isSaving) return;
      if (get().isSubmitting) return;

      try {
        set({ isSaving: true, hasFailedSaving: false, isDirty: false });
        const [docId] = await Promise.all([
          createOrUpdateDoc(doc, get().docId),
          // because saving is so fast, we add a delay to make sure the user sees the saving indicator
          new Promise((resolve) => setTimeout(resolve, 500)),
        ]);

        set({ docId, isSaving: false });

        // if the user has made changes while saving, we save again
        if (get().isDirty) {
          await get().save();
        }
      } catch (error) {
        set({ isSaving: false, hasFailedSaving: true, isDirty: true });
        handleError(error).logAnd().toast();
      }
    },

    submit: async () => {
      const docId = get().docId;

      if (!docId) return;
      if (get().isSubmitting) return;
      if (get().isSaving) return;
      if (!get().isAutosaveDisabled && get().isDirty) return;

      try {
        if (submitDoc) {
          set({ isSubmitting: true });
          await submitDoc(docId, get().doc);
          set({ isDirty: false });
        }
      } catch (error) {
        handleError(error).logAnd().toast();
      } finally {
        set({ isSubmitting: false });
      }
    },
  }));
}
