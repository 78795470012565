import { Paper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC, ReactNode } from "react";

interface Props {
  title: ReactNode;
  children?: React.ReactNode;
}

export const PaperWithTitleView: FC<Props> = (props) => {
  const { title, children } = props;
  return (
    <Paper>
      <Stack spacing={5} p={5}>
        <Typography variant="h3">{title}</Typography>

        <Stack mt={6} spacing={3}>
          {children}
        </Stack>
      </Stack>
    </Paper>
  );
};
