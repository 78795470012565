import { Tender, TenderApplication, UserType } from "@snubes/snubes-types";
import {
  TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS,
  TENDER_APPLICATION_STATUS_TRANSITIONS,
} from "../consts/TENDER_APPLICATION_STATUS_TRANSITIONS";

interface Args {
  userType: UserType | null;
  tender: Tender | undefined;
  tenderApplication: TenderApplication | undefined;
}

/**
 * It returns the allowed transitions, based on the userType and tenderApplication
 */
export function getTenderApplicationAllowedTransitions(args: Args) {
  const { userType, tender, tenderApplication } = args;

  if (!userType || !tender || !tenderApplication) {
    return TENDER_APPLICATION_STATUS_DEFAULT_TRANSITIONS;
  }

  return TENDER_APPLICATION_STATUS_TRANSITIONS[tenderApplication.status]({
    userType,
    tender,
    tenderApplication,
  });
}
