import { Grid, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { ExternalLinkView } from "../../Common/views/ExternalLinkView";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useT } from "../../Translation/hooks/useT";
import { HelpCenterAgentCard } from "../components/HelpCenterAgentCard";
import { HELP_CENTER_AGENTS } from "../consts/HELP_CENTER_AGENTS";

export const HelpCenterPage: FC = () => {
  const t = useT();

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer>
        <Stack py={5} spacing={3} alignItems="center">
          <Typography variant="h2" textAlign="center" fontWeight="bold">
            {t("HelpCenterView_Headline_Question")}
          </Typography>
          <Grid
            container
            height="100%"
            py={3}
            rowSpacing={2}
            justifyContent="center"
            columns={{ xs: 1, sm: 2 }}
          >
            {HELP_CENTER_AGENTS.map((agent, index) => (
              <Grid p={2} key={`profile-${index}`} display="flex">
                <HelpCenterAgentCard
                  imageUrl={agent.imageUrl}
                  name={agent.name}
                  title={agent.title}
                  phones={agent.phones}
                  email={agent.email}
                />
              </Grid>
            ))}
          </Grid>

          <Typography variant="h2" textAlign="center" fontWeight="bold">
            {t("HelpCenterPage_Title_Documentation")}
          </Typography>

          <ExternalLinkView
            variant="button"
            href={t("HelpCenterPage_Link_DocumentationHref")}
            sx={{
              maxWidth: "fit-content",
            }}
          >
            {t("HelpCenterPage_Link_GoToDocumentation")}
          </ExternalLinkView>
        </Stack>
      </FullHeightContainer>
    </>
  );
};
