import { superstructResolver } from "@hookform/resolvers/superstruct";
import BusinessIcon from "@mui/icons-material/Business";
import LanguageIcon from "@mui/icons-material/Language";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { capitalCase } from "change-case";
import { FC, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { ControlledSwitch } from "../../Form/views/ControlledSwitch";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import {
  OrganizationCreateFormValues,
  useOrganizationCreateFormValues,
} from "../hooks/useOrganizationCreateFormValues";
import { UrlOrDomainStruct } from "../types/UrlOrDomain";

export type OrganizationCreateFormOnSubmitFormValues = Omit<
  OrganizationCreateFormValues,
  "isHubspotEnabled" | "hubspotCompanyId" | "urlOrDomain"
> &
  Partial<
    Pick<OrganizationCreateFormValues, "isHubspotEnabled" | "hubspotCompanyId">
  >;

interface Props {
  onSubmit: (
    formValues: OrganizationCreateFormOnSubmitFormValues,
  ) => Promise<void>;
}

export const OrganizationCreateForm: FC<Props> = (props) => {
  const { onSubmit } = props;
  const t = useT();
  const hasPermission = useHasPermission();

  const FormValuesStruct = useOrganizationCreateFormValues();

  const {
    control,
    handleSubmit,
    formState: { errors, touchedFields, isSubmitting },
    watch,
    setValue,
  } = useForm<OrganizationCreateFormValues>({
    resolver: superstructResolver(FormValuesStruct),
    mode: "onBlur",
    defaultValues: {
      urlOrDomain: "",
      name: "",
      domain: "",
      website: "",
      isHubspotEnabled: true,
      hubspotCompanyId: "",
    },
  });

  const urlOrDomain = watch("urlOrDomain");
  const isHubspotEnabled = watch("isHubspotEnabled");
  const hubspotCompanyId = watch("hubspotCompanyId");

  useEffect(() => {
    try {
      const url = new URL(UrlOrDomainStruct.create(urlOrDomain));
      const hostname = url.hostname.replace("www.", "");
      const companyName = capitalCase(hostname.split(".")[0]);
      if (companyName && !touchedFields.urlOrDomain) {
        setValue("name", companyName);
      }
      setValue("domain", hostname);
      setValue("website", url.origin);
    } catch {
      if (!touchedFields.urlOrDomain) {
        setValue("name", "");
      }
      setValue("domain", "");
      setValue("website", "");
    }
  }, [touchedFields.urlOrDomain, setValue, urlOrDomain]);

  const onSubmitForm = useCallback(
    (formValues: OrganizationCreateFormValues) =>
      onSubmit({
        name: formValues.name,
        domain: formValues.domain,
        website: formValues.website,
        ...(hasPermission("CAN_WRITE_HUBSPOT") && {
          isHubspotEnabled: formValues.isHubspotEnabled,
          ...(formValues.hubspotCompanyId && {
            hubspotCompanyId: formValues.hubspotCompanyId,
          }),
        }),
      }),
    [hasPermission, onSubmit],
  );

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Stack spacing={4}>
        <ControlledTextField
          required
          fullWidth
          name="urlOrDomain"
          label={t("OrganizationCreateForm_Label_UrlOrDomain")}
          placeholder="company.com"
          control={control}
          disabled={isSubmitting}
          endAdornment={<LanguageIcon />}
          helperText={
            errors.urlOrDomain?.message ||
            t("OrganizationCreateForm_HelperText_UrlOrDomain")
          }
        />
        <ControlledTextField
          required
          fullWidth
          name="name"
          label={t("OrganizationCreateForm_Label_Name")}
          control={control}
          disabled={isSubmitting}
          endAdornment={<BusinessIcon />}
          helperText={
            errors.name?.message || t("OrganizationCreateForm_HelperText_Name")
          }
        />
        {hasPermission("CAN_WRITE_HUBSPOT") && (
          <Stack spacing={2}>
            <Stack direction="row" spacing={3} alignItems="center">
              <ControlledTextField
                fullWidth
                name="hubspotCompanyId"
                label={t("OrganizationCreateForm_Label_HubspotId")}
                control={control}
                disabled={isSubmitting || !isHubspotEnabled}
              />

              <ControlledSwitch
                fullWidth
                name="isHubspotEnabled"
                label={t("OrganizationCreateForm_Label_IsHubspotEnabled")}
                control={control}
                disabled={isSubmitting}
                labelPlacement="start"
              />
            </Stack>
            {isHubspotEnabled && !hubspotCompanyId && (
              <Alert severity="warning">
                <Typography variant="body1" color="text.secondary">
                  {t("OrganizationCreateForm_Alert_NewHubspotCompany")}
                </Typography>
              </Alert>
            )}
            {isHubspotEnabled && hubspotCompanyId && (
              <Alert severity="info">
                <Typography variant="body1" color="text.secondary">
                  {t("OrganizationCreateForm_Alert_ExistingHubspotCompany")}
                </Typography>
              </Alert>
            )}
          </Stack>
        )}
        <Button type="submit" variant="contained" disabled={isSubmitting}>
          {t("OrganizationCreateForm_Button_CreateOrganization")}
        </Button>
      </Stack>
    </form>
  );
};
