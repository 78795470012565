import { Provider } from "@snubes/snubes-types";
import { FC } from "react";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";
import { PROVIDER_STATUS_RECORD } from "../consts/PROVIDER_STATUS_RECORD";

interface Props {
  provider: Provider;
}

export const ProviderStatusChip: FC<Props> = (props: Props) => {
  const { provider } = props;
  const t = useT();
  const { color, Icon, label } = PROVIDER_STATUS_RECORD[provider.status];

  return <StatusChip color={color} label={t(label)} Icon={Icon} />;
};
