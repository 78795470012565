import BoltIcon from "@mui/icons-material/Bolt";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import FlagIcon from "@mui/icons-material/Flag";
import { isNotificationLog, Notification } from "@snubes/snubes-types";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { RoundButton } from "../../Common/views/RoundButton";
import { LogListItemViewForNotification } from "../../Logs/views/LogListItemViewForNotification";
import { LogsButtonView } from "../../Logs/views/LogsButtonView";
import { useT } from "../../Translation/hooks/useT";
import { NotificationListItemView } from "./NotificationListItemView";

interface Props {
  notification: Notification & { type: "CALL_REVIEW" };
  isMenuItem?: boolean;
}

export const NotificationListItemForCallReviewView: FC<Props> = (props) => {
  const { notification, isMenuItem } = props;
  const { notificationTab = "all" } = useParams<"notificationTab">();
  const t = useT();
  const isDone = notification.status === "DONE";
  const isAssigned = !!notification.userId;
  const hasPermission = useHasPermission();
  const to = `/notifications/${notificationTab}/projects/${notification.projectId}/calls/${notification.callId}`;

  return (
    <NotificationListItemView
      Icon={FlagIcon}
      color={isAssigned ? "primary" : "error"}
      caption={t(
        isDone
          ? "NotificationListItemForCallReviewView_Caption_CallReviewCompleted"
          : isAssigned
          ? "NotificationListItemForCallReviewView_Caption_CallReview"
          : "NotificationListItemForCallReviewView_Caption_CallReviewUnassigned",
      )}
      title={t(
        isDone
          ? "NotificationListItemForCallReviewView_Title_CallReviewCompleted"
          : isAssigned
          ? "NotificationListItemForCallReviewView_Title_CallReview"
          : "NotificationListItemForCallReviewView_Title_CallReviewUnassigned",
        {
          callId: notification.callId.substring(0, 6),
        },
      )}
      to={to}
      createdAt={notification.createdAt}
      hasBeenRead={notification.hasBeenRead}
      taskStatus={notification.status}
      isMenuItem={isMenuItem}
      actions={
        <>
          <RoundButton
            href={to}
            variant="outlined"
            size="small"
            color="primary"
            startIcon={isDone ? <CallOutlinedIcon /> : <BoltIcon />}
          >
            {isDone
              ? t("NotificationListItemForCallReviewView_Button_ShowCall")
              : t("NotificationListItemForCallReviewView_Button_StartReview")}
          </RoundButton>
          {hasPermission("CAN_READ_LOGS") && (
            <LogsButtonView
              collectionName={`organizations/${notification.organizationId}/notifications/${notification.id}/logs`}
              LogListItemView={LogListItemViewForNotification}
              // TODO: Remove eslint disable, and fix the too deep type!
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              isT={isNotificationLog as (obj: unknown) => obj is any}
            />
          )}
        </>
      }
    />
  );
};
