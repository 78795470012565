import GroupWorkIcon from "@mui/icons-material/GroupWork";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { TeamModel } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface TeamModelType {
  label: TranslationKey;
  caption?: TranslationKey;
  Icon?: OverridableComponent<SvgIconTypeMap>;
}

export const TENDER_TEAM_MODEL: Record<TeamModel, TeamModelType> = {
  DEDICATED: {
    label: "TenderTeamModel_Label_Dedicated",
    Icon: GroupWorkIcon,
  },
  SHARED: {
    label: "TenderTeamModel_Label_Shared",
    Icon: WorkspacesOutlinedIcon,
  },
};
