import { Alert } from "@mui/material";
import { FC } from "react";
import { LoadingView } from "../../Common/views/LoadingView";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useTenderContext } from "../hooks/useTenderContext";
import { TenderMyApplicationForm } from "./TenderMyApplicationForm";

export const TenderMyApplicationPage: FC = () => {
  const { tender, isLoading, error: tenderError } = useTenderContext();

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      {isLoading && <LoadingView />}
      {tenderError && <Alert severity="error">{tenderError.message}</Alert>}
      {tender && <TenderMyApplicationForm tender={tender} />}
    </>
  );
};
