import { WizardStep } from "../types/WizardStep";

export function updateActiveWizardSteps<TState>(
  pathSegments: string[],
  steps?: WizardStep<TState>[],
) {
  const [firstPathSegment, ...restPathSegments] = pathSegments;

  if (!steps || !firstPathSegment) return;

  steps.forEach((step) => {
    step.isActive = step.pathSegment === firstPathSegment;
    updateActiveWizardSteps(restPathSegments, step.steps);
  });
}
