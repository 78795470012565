import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import { ConnectorStatusLog, isConnectorStatusLog } from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC, useMemo } from "react";
import { toLocaleMinutes } from "../../Common/helpers/toLocaleMinutes";
import { CollectionTableColumn } from "../../Firebase/types/CollectionTableColumn";
import { CollectionTableView } from "../../Firebase/views/CollectionTableView";
import { useT } from "../../Translation/hooks/useT";
import { CONNECTOR_STATUS_LOG_ACTIONS_RECORD } from "../consts/CONNECTOR_STATUS_LOG_ACTIONS_RECORD";
import { ConnectorConfigStatusLogStatusChip } from "./ConnectorConfigStatusLogStatusChip";

interface Props {
  organizationId: string;
  connectorConfigId: string;
}

export const ConnectorConfigStatusLogsCollectionTableView: FC<Props> = (
  props,
) => {
  const t = useT();

  const columns = useMemo<CollectionTableColumn<ConnectorStatusLog>[]>(
    () => [
      {
        title: t(
          "ConnectorConfigStatusLogsCollectionTableView_Header_EventTime",
        ),
        renderCell: (connectorStatusLog) => {
          const time = DateTime.fromMillis(
            connectorStatusLog.createdAt.toMillis(),
          ).toFormat("t");
          const date = DateTime.fromMillis(
            connectorStatusLog.createdAt.toMillis(),
          ).toFormat("DD");
          return `${time}, ${date}`;
        },
      },
      {
        title: t(
          "ConnectorConfigStatusLogsCollectionTableView_Header_EventType",
        ),
        renderCell: (connectorStatusLog) =>
          t(
            CONNECTOR_STATUS_LOG_ACTIONS_RECORD[connectorStatusLog.action]
              .label,
          ),
      },
      {
        title: t("ConnectorConfigStatusLogsCollectionTableView_Header_CallId"),
        renderCell: (connectorStatusLog) => connectorStatusLog.callId ?? "NA",
      },
      {
        title: t(
          "ConnectorConfigStatusLogsCollectionTableView_Header_ResponseTime",
        ),
        renderCell: (connectorStatusLog) =>
          connectorStatusLog.durationInMillis
            ? toLocaleMinutes(connectorStatusLog.durationInMillis)
            : "NA",
      },
      {
        title: t("ConnectorConfigStatusLogsCollectionTableView_Header_Status"),
        renderCell: (connectorStatusLog) => (
          <ConnectorConfigStatusLogStatusChip
            status={connectorStatusLog.status}
          />
        ),
      },
      {
        title: t(
          "ConnectorConfigStatusLogsCollectionTableView_Header_StatusResponse",
        ),
        renderCell: (connectorStatusLog) => (
          <Tooltip
            disableHoverListener={!connectorStatusLog.statusMessage}
            title={connectorStatusLog.statusMessage}
            arrow
          >
            <InfoOutlinedIcon
              sx={{
                color: connectorStatusLog.statusMessage
                  ? "text.secondary"
                  : "text.disabled",
              }}
            />
          </Tooltip>
        ),
      },
    ],
    [t],
  );

  return (
    <CollectionTableView
      collectionName={`organizations/${props.organizationId}/connectorConfigs/${props.connectorConfigId}/statusLogs`}
      orderBy={["createdAt", "desc"]}
      keyField="createdAt"
      isT={isConnectorStatusLog}
      columns={columns}
      rowsPerPageOptions={[10, 25, 50, 100]}
    />
  );
};
