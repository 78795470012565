import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ListItemButton, Typography } from "@mui/material";
import { FC, MouseEventHandler, useCallback } from "react";
import { getUserName } from "../../Users/helpers/getUserName";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { UserAvatarView } from "../../Users/views/UserAvatarView";

interface Props {
  onSetUserMenuAnchorEl: (event: HTMLElement) => void;
}

export const HeaderUserMenuButton: FC<Props> = (props) => {
  const { onSetUserMenuAnchorEl } = props;
  const userName = useMyUser((state) =>
    state.user ? getUserName(state.user) : null,
  );
  const userFirstName = useMyUser((state) => state.user?.firstName);

  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      onSetUserMenuAnchorEl(event.currentTarget);
    },
    [onSetUserMenuAnchorEl],
  );

  return (
    <ListItemButton onClick={onClick}>
      {userName && <UserAvatarView name={userName} size={24} />}
      <Typography fontWeight={800} mx={2}>
        {userFirstName}
      </Typography>
      <ExpandMoreIcon />
    </ListItemButton>
  );
};
