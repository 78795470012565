import {
  BACK_OFFICE_SERVICE_TYPES,
  INBOUND_SERVICE_TYPES,
  OUTBOUND_SERVICE_TYPES,
  ServiceCategory,
  ServiceType,
} from "@snubes/snubes-types";

export const TENDER_SERVICES_BY_CATEGORY = new Map<
  ServiceCategory,
  ReadonlyArray<ServiceType>
>([
  ["INBOUND", INBOUND_SERVICE_TYPES],
  ["OUTBOUND", OUTBOUND_SERVICE_TYPES],
  ["BACK_OFFICE_SUPPORT", BACK_OFFICE_SERVICE_TYPES],
]);

function* serviceTypeCategoryTuples() {
  for (const [category, services] of TENDER_SERVICES_BY_CATEGORY.entries()) {
    for (const service of services) {
      yield [service, category] as const;
    }
  }
}

export const TENDER_SERVICE_CATEGORIES_BY_SERVICE_TYPE = new Map<
  ServiceType,
  ServiceCategory
>(serviceTypeCategoryTuples());
