import { FirebaseWhereFilterOp, Path } from "@snubes/snubes-types";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { createStore, StoreApi, useStore } from "zustand";
import { FormOption } from "../../Form/types/FormOption";
import { isFirebaseTableFilterType } from "../helpers/isFirebaseTableFilterType";
import { FirebaseTableFilterNumberOperator } from "../types/FirebaseTableFilterNumberOperator";

interface FirebaseTableAbstractFilter<T> {
  isActive?: boolean;
  isDisabled?: boolean;
  label: string;
  value: T | null;
  operator: FirebaseWhereFilterOp;
}

export interface FirebaseTableSelectFilter
  extends FirebaseTableAbstractFilter<string> {
  type: "select";
  operator: "==";
  options: FormOption[];
}

export interface FirebaseTableAutocompleteFilter
  extends FirebaseTableAbstractFilter<string[]> {
  type: "autocomplete";
  /**
   * "in" is used if the document's field is a single value, "array-contains-any" if it's an array.
   *
   * Firestore doesn't support inclusive array intersection: array-contains-any will match any
   * element of the value array. That we cannot query documents that match all the given values.
   *
   * Another problem is that only a single array-contains-(any) clause is allowed per query.
   */
  operator: "in" | "array-contains-any";
  options: FormOption[];
}

export interface FirebaseTableNumberFilter
  extends FirebaseTableAbstractFilter<number> {
  type: "number";
  operator: FirebaseTableFilterNumberOperator;
}

export interface FirebaseTableTextFilter
  extends FirebaseTableAbstractFilter<string> {
  type: "text";
  operator: "==" | "!=" | "search";
  placeholder?: string;
}

/**
 * Special filter used for the search field only.
 */
export interface FirebaseTableSearchFilter
  extends Omit<FirebaseTableTextFilter, "operator"> {
  isSearch: true;
  operator: "search";
}

export type FirebaseTableFilter =
  | FirebaseTableSelectFilter
  | FirebaseTableAutocompleteFilter
  | FirebaseTableNumberFilter
  | FirebaseTableTextFilter
  | FirebaseTableSearchFilter;

export type FirebaseTableFilterType = FirebaseTableFilter["type"];

export type FirebaseTableFilterOfType<T extends FirebaseTableFilterType> =
  Extract<FirebaseTableFilter, { type: T }>;

export type FirebaseTableFilterRecord<T> = Partial<
  Record<Path<T>, FirebaseTableFilter>
>;

export interface FilterState {
  filters: Record<string, FirebaseTableFilter>;
  filterNames: string[];
  searchFilterName?: string;

  unsetAllFilters: () => void;
  setFilterValue: <T extends FirebaseTableFilterType>(
    filterType: T,
    filterName: string,
    value: FirebaseTableFilterOfType<T>["value"],
  ) => void;
  setFilterOperator: <T extends FirebaseTableFilterType>(
    filterType: T,
    filterName: string,
    operator: FirebaseTableFilterOfType<T>["operator"],
  ) => void;
  setIsFilterActive: (filterName: string, isActive: boolean) => void;
  getIsFilterActive: (filterName: string) => boolean;
}

type FilterStore = StoreApi<FilterState>;

const FirebaseTableFilterContextContext = createContext<FilterStore | null>(
  null,
);

interface Args {
  filters: Record<string, FirebaseTableFilter>;
}

function createFirebaseTableFilterStore(args: Args) {
  return createStore<FilterState>((set, get) => ({
    filters: { ...args.filters },
    filterNames: Object.entries(args.filters || {})
      .filter(([, filter]) => !isSearchFilter(filter))
      .map(([key]) => key),
    searchFilterName: Object.entries(args.filters || {}).find((entry) =>
      isSearchFilter(entry[1]),
    )?.[0],

    unsetAllFilters: () => {
      set(({ filterNames, filters }) => ({
        filters: {
          ...filters,
          ...Object.fromEntries(
            filterNames
              .filter((filterName) => !isSearchFilter(filters[filterName]))
              .map((filterName) => [
                filterName,
                {
                  ...filters[filterName],
                  value: null,
                  isActive: false,
                  isDisabled: false,
                },
              ]),
          ),
        },
      }));
    },

    setFilterValue: (filterType, filterName, value) => {
      const filter = get().filters[filterName];
      if (!isFirebaseTableFilterType(filter, filterType)) {
        throw new Error(
          `Need filter ${filterName} of type ${filterType} but got ${filter.type}`,
        );
      }
      set((state) => ({
        filters: {
          ...state.filters,
          [filterName]: {
            ...filter,
            value,
            isActive: value !== null,
          },
          ...getToggledFilters({
            filterName,
            filters: state.filters,
            isDisabled: value !== null,
          }),
        },
      }));
    },

    setFilterOperator: (filterType, filterName, operator) => {
      const filter = get().filters[filterName];
      if (!isFirebaseTableFilterType(filter, filterType)) {
        throw new Error(
          `Need filter ${filterName} of type ${filterType} but got ${filter.type}`,
        );
      }
      set((state) => ({
        filters: {
          ...state.filters,
          [filterName]: {
            ...filter,
            operator,
          },
        },
      }));
    },

    setIsFilterActive: (filterName, isActive) => {
      if (!get().filters[filterName]) {
        throw new Error(`No filter found with name ${filterName}`);
      }
      set((state) => ({
        filters: {
          ...state.filters,
          [filterName]: {
            ...state.filters[filterName],
            isActive,
            ...(!isActive && { value: null }),
          },
          ...getToggledFilters({
            filterName,
            filters: state.filters,
            isDisabled: isActive,
          }),
        },
      }));
    },

    getIsFilterActive: (filterName) => {
      return get().filters[filterName]?.isActive || false;
    },
  }));
}

function isSearchFilter(
  filter: FirebaseTableFilter,
): filter is FirebaseTableSearchFilter {
  return "isSearch" in filter && filter.isSearch;
}

/**
 * Get all filters that need to be toggled when another filter is set or unset.
 */
function getToggledFilters(args: {
  filterName: string;
  filters: Record<string, FirebaseTableFilter>;
  isDisabled?: boolean;
}): Record<string, FirebaseTableFilter> {
  return Object.fromEntries(
    Object.entries(args.filters)
      .filter(
        ([key, filter]) =>
          // In Firestore, there can only be one array-contains(-any) clause per query
          // and only one field with an inequality operator.
          key !== args.filterName &&
          (filter.operator === "array-contains-any" ||
            filter.operator === "search" ||
            filter.type === "number"),
      )
      .map(([filterName, filter]) => [
        filterName,
        {
          ...filter,
          isDisabled: args.isDisabled,
        },
      ]),
  );
}

interface Props<T> extends PropsWithChildren {
  filters: FirebaseTableFilterRecord<T>;
}

/**
 * Wrap a CollectionTableView with this provider in order to add filters.
 * Filters should be memoized to avoid resetting the filter state on every render.
 */
export function FirebaseTableFilterContextProvider<T>(props: Props<T>) {
  const store = useMemo(
    () =>
      createFirebaseTableFilterStore({
        filters: props.filters as Record<string, FirebaseTableFilter>,
      }),
    [props.filters],
  );

  return (
    <FirebaseTableFilterContextContext.Provider value={store}>
      {props.children}
    </FirebaseTableFilterContextContext.Provider>
  );
}

export function useMaybeFirebaseTableFilterStore() {
  return useContext(FirebaseTableFilterContextContext);
}

export function useFirebaseTableFilterState<U>(
  selector: (state: FilterState) => U,
) {
  const store = useMaybeFirebaseTableFilterStore();
  // Either the store is provided or not, it's not going to change.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return store ? useStore(store, selector) : null;
}

export function useFirebaseTableFilterStore() {
  const store = useMaybeFirebaseTableFilterStore();
  if (!store) {
    throw new Error("No FirebaseTableFilterContextProvider provided.");
  }
  return store;
}
