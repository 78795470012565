import { User } from "@snubes/snubes-types";
import { FC } from "react";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";
import { USER_ROLE_RECORD } from "../consts/USER_ROLE_RECORD";

interface Props {
  user: User;
}

export const UserRoleChip: FC<Props> = (props: Props) => {
  const { user } = props;
  const t = useT();
  const { color, Icon, label } = USER_ROLE_RECORD[user.role];

  return <StatusChip color={color} label={t(label)} Icon={Icon} />;
};
