import { isCall } from "@snubes/snubes-types";
import { useMemo } from "react";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";
import { getNormalizedCall } from "../helpers/getNormalizedCall";

export function useCall(projectId?: string, callId?: string) {
  const [call, isLoading, error, snapshot] = useValidDocumentData(
    projectId && callId
      ? getDocRef(`projects/${projectId}/calls`, callId)
      : null,
    isCall,
  );

  const normalizedCall = useMemo(() => call && getNormalizedCall(call), [call]);

  return [normalizedCall, isLoading, error, snapshot] as const;
}
