import { Box, BoxProps, Container, ContainerProps } from "@mui/material";
import { FC, PropsWithChildren } from "react";

interface Props
  extends PropsWithChildren,
    Omit<BoxProps, "maxWidth">,
    Pick<ContainerProps, "maxWidth"> {}

export const MaxWidthContainer: FC<Props> = (props) => {
  const { children, maxWidth = "xl", ...restProps } = props;
  return (
    <Box {...restProps}>
      <Container maxWidth={maxWidth} disableGutters>
        {children}
      </Container>
    </Box>
  );
};
