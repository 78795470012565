import { TOptions } from "i18next";
import { FC } from "react";
import { Trans } from "react-i18next";
import { useT } from "../hooks/useT";
import { TranslationKey } from "../types/TranslationKey";

interface Props {
  /**
   * this is the TranslationKey like "My_Component_Title"
   */
  k: TranslationKey;
  options?: TOptions;
}

export const T: FC<Props> = (props) => {
  const t = useT();
  return <Trans>{t(props.k, props.options)}</Trans>;
};
