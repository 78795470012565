import { FormControlProps } from "@mui/material";
import { ReactNode } from "react";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { getLanguageName } from "../../Common/helpers/getLanguageName";
import { useLanguageOptions } from "../hooks/useLanguageOptions";
import { ControlledAutocomplete } from "./ControlledAutocomplete";

interface Props<TFields extends FieldValues> {
  control: Control<TFields>;
  name: FieldPath<TFields>;
  label?: ReactNode;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  size?: FormControlProps["size"];
  multiple?: boolean;
}

export function ControlledLanguagesAutocomplete<TFields extends FieldValues>(
  props: Props<TFields>,
) {
  const languageOptions = useLanguageOptions();

  return (
    <ControlledAutocomplete
      control={props.control}
      name={props.name}
      label={props.label}
      options={languageOptions}
      getOptionLabel={getLanguageName}
      multiple={props.multiple}
      required={props.required}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      placeholder={props.placeholder}
      helperText={props.helperText}
      size={props.size}
    />
  );
}
