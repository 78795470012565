import { Alert } from "@mui/material";
import { FC, useEffect } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { BodyConfig } from "../../Common/views/BodyConfig";
import { LoadingView } from "../../Common/views/LoadingView";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { getFirstIncompleteWizardStepPath } from "../../Wizard/helpers/getFirstIncompleteWizardStepPath";
import { WizardContentView } from "../../Wizard/views/WizardContentView";
import { WizardLayoutView } from "../../Wizard/views/WizardLayoutView";
import { useProviderWizardSteps } from "../hooks/useProviderWizardSteps";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";
import { ProviderWizardHeaderView } from "./ProviderWizardHeaderView";

export const ProviderWizardPage: FC = () => {
  const { providerId } = useParams();
  const steps = useProviderWizardSteps();
  const isInitializing = useProviderWizardStore(
    (state) => state.isInitializing,
  );
  const hasProvider = useProviderWizardStore((state) => Boolean(state.doc));
  const error = useProviderWizardStore((state) => state.error);
  const { pathname } = useLocation();

  useEffect(() => {
    useProviderWizardStore
      .getState()
      .initialize(providerId) // The provider profile ID is the same as the organization ID.
      .catch(console.error);
  }, [providerId]);

  useEffect(() => useProviderWizardStore.getState().reset, []);

  if (!isInitializing && providerId && pathname.endsWith("create")) {
    const path = getFirstIncompleteWizardStepPath(
      steps,
      useProviderWizardStore,
    );
    return <Navigate replace to={path} />;
  }

  // We redirect back to `/`, if provider cannot be found.
  if (!isInitializing && !hasProvider && !error) {
    return <Navigate replace to="/" />;
  }

  return (
    <>
      <HeaderConfig variant="WITH_CUSTOM_CHILDREN">
        <ProviderWizardHeaderView
          steps={steps}
          store={useProviderWizardStore}
        />
      </HeaderConfig>
      <BodyConfig background="PAPER" />
      <WizardLayoutView
        steps={steps}
        store={useProviderWizardStore}
        onSubmitNavigateTo={"/providers/success"}
      >
        {error && <Alert severity="error">{error.message}</Alert>}
        {isInitializing && <LoadingView />}
        {!isInitializing && (
          <WizardContentView steps={steps} store={useProviderWizardStore} />
        )}
      </WizardLayoutView>
    </>
  );
};
