import { FieldError } from "react-hook-form";

export function formatError(error: Error | FieldError | undefined) {
  if (!error) {
    return;
  }
  if ("name" in error) {
    return `${error.name}: ${error.message}`;
  }
  return error.message;
}
