import { setUserId } from "firebase/analytics";
import { analytics } from "../consts/FIREBASE";

interface Args {
  userId: string;
}

export function setUser(args: Args) {
  if (!analytics) return;
  setUserId(analytics, args.userId);
}
