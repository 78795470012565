import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { WIZARD_ACTIVE_PSEUDO_SX } from "../consts/WIZARD_ACTIVE_PSEUDO_SX";
import { WizardStep } from "../types/WizardStep";
import { WizardStore } from "../types/WizardStore";
import { WizardProgressView } from "./WizardProgressView";
import { WizardSidebarButton } from "./WizardSidebarButton";

interface Props<TState> {
  index: number;
  step: WizardStep<TState>;
  store: WizardStore<TState>;
}

export function WizardSidebarAccordionButton<TState>(props: Props<TState>) {
  const { step, store } = props;
  const { pathname } = useLocation();
  const isPathActive = step?.path === pathname;
  const isSingleStep = !step.steps?.length;

  return (
    <SAccordion expanded={step.isActive} square disableGutters>
      <AccordionSummary
        component={NavLink}
        to={step.path || "/"}
        expandIcon={<ExpandMoreIcon />}
        sx={{
          py: 2,
          px: 4,
          "& > .MuiAccordionSummary-content": {
            alignItems: "center",
          },
          "&:hover": {
            bgcolor: "grey.50",
          },
          ...(isPathActive && {
            ...WIZARD_ACTIVE_PSEUDO_SX,
            bgcolor: "info.50",
            ":hover": {
              bgcolor: "info.50",
            },
          }),
          // Don't rotate the icon if there are no child steps.
          "& .MuiAccordionSummary-expandIconWrapper": {
            ...(isSingleStep ? { transform: "rotate(-90deg) !important" } : {}),
          },
        }}
      >
        <WizardProgressView step={step} store={store} />
        <Stack ml={4}>
          <Typography variant="h5" color="text.secondary">
            Step {props.index + 1}
          </Typography>
          <Typography
            variant="h4"
            color={isPathActive ? "primary.main" : undefined}
          >
            {step.title}
          </Typography>
        </Stack>
      </AccordionSummary>
      {step.steps?.map((childStep) => (
        <WizardSidebarButton
          key={childStep.pathSegment}
          step={childStep}
          store={store}
          level={1}
        />
      ))}
    </SAccordion>
  );
}

const SAccordion = styled(Accordion)(({ theme }) => ({
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: theme.palette.divider,
  boxShadow: "none",
  backgroundColor: theme.palette.grey[50],
  "&.Mui-expanded": {
    margin: 0,
    backgroundColor: theme.palette.background.paper,
  },
  ".MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(-90deg)",
    "&.Mui-expanded": {
      transform: "rotate(0deg)",
    },
  },
}));
