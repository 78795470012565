import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  SxProps,
  Theme,
} from "@mui/material";
import { ReactNode } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

type Value = string | number;

interface Props<TFields extends FieldValues, TData extends { value: Value }> {
  control: Control<TFields>;
  name: FieldPath<TFields>;
  items: TData[];
  renderItem: (item: TData) => ReactNode;
  label: ReactNode;
  sx?: SxProps<Theme>;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  multiple?: boolean;
  size?: FormControlProps["size"];
  emptyLabel?: string;
  startAdornment?: SelectProps["startAdornment"];
  endAdornment?: SelectProps["endAdornment"];
  onPressEnter?: () => void;
}

export function ControlledSelect<
  TFields extends FieldValues,
  TData extends { value: Value },
>(props: Props<TFields, TData>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field, fieldState }) => (
        <FormControl
          error={!!fieldState.error}
          disabled={props.disabled}
          fullWidth={props.fullWidth}
        >
          <InputLabel
            id={`${props.name}-select-label`}
            shrink={props.emptyLabel ? true : undefined}
          >
            {props.label}
          </InputLabel>
          <Select
            label={props.label}
            labelId={`${props.name}-select-label`}
            error={!!fieldState.error}
            placeholder={props.placeholder}
            required={props.required}
            fullWidth={props.fullWidth}
            size={props.size}
            sx={props.sx}
            displayEmpty={!!props.emptyLabel}
            startAdornment={props.startAdornment}
            endAdornment={props.endAdornment}
            input={
              props.emptyLabel ? (
                <OutlinedInput notched label={props.label} />
              ) : undefined
            }
            multiple={props.multiple}
            {...(props.onPressEnter && {
              onKeyDown: (e) => e.key === "Enter" && props.onPressEnter?.(),
            })}
            {...field}
            value={field.value !== null ? field.value : ""}
          >
            {!!props.emptyLabel && (
              <MenuItem value="">
                <em>{props.emptyLabel}</em>
              </MenuItem>
            )}
            {props.items.map((item) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {props.renderItem(item)}
                </MenuItem>
              );
            })}
          </Select>
          {(props.helperText || fieldState.error?.message) && (
            <FormHelperText>
              {props.helperText || fieldState.error?.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
