import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, Paper, Stack, Typography } from "@mui/material";
import { FirebaseFilter, Organization } from "@snubes/snubes-types";
import { FC } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";
import { AdminOrganizationsCollectionTableView } from "./AdminOrganizationsCollectionTableView";

interface Props {
  headline: TranslationKey;
  filters: FirebaseFilter<Organization>[];
  hasCreateButton?: boolean;
}

export const AdminOrganizationsPage: FC<Props> = (props) => {
  const { headline, filters, hasCreateButton } = props;

  const t = useT();
  const hasPermission = useHasPermission();

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer>
        <Stack py={5} spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ minHeight: 52 }}
          >
            <Typography variant="h2">{t(headline)}</Typography>
            {hasCreateButton && hasPermission("CAN_WRITE_ORGANIZATION") && (
              <Button
                href="/admin/organizations/create"
                variant="contained"
                startIcon={<AddCircleIcon />}
              >
                {t("AdminOrganizationsPage_Button_CreateOrganization")}
              </Button>
            )}
          </Stack>
          <Paper>
            <AdminOrganizationsCollectionTableView filters={filters} />
          </Paper>
        </Stack>
      </FullHeightContainer>
    </>
  );
};
