import { Menu } from "@mui/material";
import { FC, ReactNode } from "react";

interface Props {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const FirebaseTableFilterMenuView: FC<Props> = (props) => {
  const { anchorEl, open, onClose, children } = props;

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{ onClick }}
      sx={{
        "& .MuiMenu-list": {
          padding: 0,
        },
      }}
    >
      {children}
    </Menu>
  );
};

const onClick = (event: React.MouseEvent<HTMLUListElement>) => {
  event.stopPropagation();
};
