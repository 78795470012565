import { Mark } from "@mui/base";
import { Container, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import {
  CurrencyStruct,
  ORGANIZATION_REVENUE_VALUES,
  OrganizationRevenueValue,
} from "@snubes/snubes-types";
import { FC, useCallback, useMemo } from "react";
import { number, type } from "superstruct";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { ControlledSlider } from "../../Form/views/ControlledSlider";
import { DefaultCurrencySelect } from "../../Form/views/DefaultCurrencySelect";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { PROVIDER_REVENUE_RANGE_RECORD } from "../consts/PROVIDER_REVENUE_RANGE_RECORD";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  revenuePerYearCurrency: CurrencyStruct,
  revenuePerYearAmountIndex: number(),
});

export const ProviderWizardRevenueForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, setValue, watch, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      revenuePerYearCurrency:
        provider?.revenuePerYear?.currency ||
        useLocalStorage.getState().data.defaultCurrency,
      revenuePerYearAmountIndex:
        PROVIDER_REVENUE_RANGE_RECORD[
          (provider?.revenuePerYear?.amount || 0) as OrganizationRevenueValue
        ]?.index || 0,
    }),
    toDoc: (formValues) => ({
      ...(formValues.revenuePerYearCurrency &&
      formValues.revenuePerYearAmountIndex &&
      ORGANIZATION_REVENUE_VALUES[formValues.revenuePerYearAmountIndex]
        ? {
            revenuePerYear: {
              currency: formValues.revenuePerYearCurrency,
              amount:
                ORGANIZATION_REVENUE_VALUES[
                  formValues.revenuePerYearAmountIndex
                ],
            },
          }
        : { revenuePerYear: undefined }),
    }),
  });

  const currency = watch("revenuePerYearCurrency");

  const marks = useMemo(() => {
    return ORGANIZATION_REVENUE_VALUES.map((value, i) => {
      if (i === 0) {
        return { value: i, label: "0" };
      }
      const { label, labelValue } = PROVIDER_REVENUE_RANGE_RECORD[value];
      const labelText = toLocaleMoney({
        amount: labelValue,
        currency,
        fractionDigits: 0,
      });
      return {
        value: i,
        label: t(label, {
          value: labelText,
        }),
      };
    }) satisfies Mark[];
  }, [currency, t]);

  const getLabelDisplay = useCallback(
    (index: number) => {
      if (index === 0) {
        return toLocaleMoney({
          amount: 0,
          currency,
          fractionDigits: 0,
        });
      }
      const from = marks[index - 1].label || 0;
      const to = marks[index].label;

      if (index === 1) {
        const valueBefore = ORGANIZATION_REVENUE_VALUES[index - 1];
        const value = ORGANIZATION_REVENUE_VALUES[index];
        return t(PROVIDER_REVENUE_RANGE_RECORD[valueBefore].label, {
          value: PROVIDER_REVENUE_RANGE_RECORD[value].labelValue,
        });
      }
      if (index === marks.length - 1) {
        return to;
      }
      return `${from} - ${to}`;
    },
    [currency, marks, t],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("ProviderWizardRevenueForm_Title_Revenue")}
        </Typography>
        <Typography variant="body1" color="text.500" mb={4}>
          {t("ProviderWizardRevenueForm_Paragraph_Revenue")}
        </Typography>

        <Grid container columnSpacing={4} rowSpacing={4}>
          <Grid xs={12} sm={12} md={12} lg="auto">
            <DefaultCurrencySelect
              sx={{ width: "5rem" }}
              onChange={(nextCurrency) => {
                setValue("revenuePerYearCurrency", nextCurrency);
              }}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} lg pl={3} pr={4}>
            <ControlledSlider
              control={control}
              name="revenuePerYearAmountIndex"
              marks={marks}
              min={0}
              max={ORGANIZATION_REVENUE_VALUES.length - 1}
              step={1}
              valueLabelDisplay="auto"
              valueLabelFormat={getLabelDisplay}
              sx={(theme) => ({
                "& .MuiSlider-markLabel": {
                  fontSize: theme.typography.body1.fontSize,
                },
              })}
            />
          </Grid>
        </Grid>
      </Container>
    </form>
  );
};
