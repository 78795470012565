import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { Divider, Link, Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { CollapseExpandView } from "../../Common/views/CollapseExpandView";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";
import { INOPLA_APIS } from "../consts/INOPLA_APIS";

interface Props {
  title: string;
}

export const ConnectorConfigInoplaHelpView: FC<Props> = (props) => {
  const t = useT();
  return (
    <Paper variant="outlined">
      <CollapseExpandView
        defaultExpanded
        headerContent={
          <Stack
            color="primary.main"
            direction="row"
            spacing={2}
            alignItems="center"
          >
            <TipsAndUpdatesIcon />
            <Typography variant="h5">{props.title}</Typography>
          </Stack>
        }
        sx={{
          bgcolor: "background.default",
        }}
      >
        <Stack p={3} spacing={3}>
          <Typography variant="subtitle2">
            {t("ConnectorConfigInoplaHelpView_Subtitle_EnableApi")}
          </Typography>
          <Typography variant="h5">
            {t("ConnectorConfigInoplaHelpView_Heading_EnableFollowingApi")}
          </Typography>
          <Stack spacing={2} direction="row">
            {INOPLA_APIS.map((api) => (
              <StatusChip key={api} label={api} color="primary" />
            ))}
          </Stack>

          <Divider />

          <Typography variant="h5">
            {t("ConnectorConfigInoplaHelpView_Heading_EnableCallRecordings")}
          </Typography>
          <Typography variant="subtitle2">
            {t("ConnectorConfigInoplaHelpView_Text_EnableCallRecordings")}
          </Typography>

          <Divider />

          <Typography variant="h5">
            {t("ConnectorConfigInoplaHelpView_Heading_ContactDetails")}
          </Typography>
          <Stack spacing={3} direction="row">
            <Stack spacing={2} direction="row" alignItems="center">
              <EmailIcon color="primary" />
              <ContactLinkView
                label={t("ConnectorConfigInoplaHelpView_Label_Email")}
                href={`mailto:${t(
                  "ConnectorConfigInoplaHelpView_Label_Email",
                )}`}
              />
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack spacing={2} direction="row" alignItems="center">
              <CallIcon color="primary" />
              <ContactLinkView
                label={t("ConnectorConfigInoplaHelpView_Label_PhoneNumber")}
                href={`tel:${t(
                  "ConnectorConfigInoplaHelpView_Label_PhoneNumber",
                )}`}
              />
            </Stack>
          </Stack>
        </Stack>
      </CollapseExpandView>
    </Paper>
  );
};

interface ContactLinkViewProps {
  label: string;
  href: string;
}

const ContactLinkView: FC<ContactLinkViewProps> = (props) => (
  <Typography
    variant="h5"
    component={Link}
    sx={{
      textDecoration: "none",
      color: "inherit",
      "&:hover": {
        color: "primary.main",
        textDecoration: "underline",
      },
    }}
    href={props.href}
  >
    {props.label}
  </Typography>
);
