import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Menu,
  MenuItem,
  PopoverActions,
  Stack,
  Typography,
} from "@mui/material";
import { Invitation, Notification } from "@snubes/snubes-types";
import { FC, useCallback, useRef } from "react";
import { NavLink } from "react-router-dom";
import { AvatarWithIconView } from "../../Common/views/AvatarWithIconView";
import { LinkView } from "../../Common/views/LinkView";
import { NotificationListItemForInvitationView } from "../../Notifications/views/NotificationListItemForInvitationView";
import { NotificationListItemSwitchView } from "../../Notifications/views/NotificationListItemSwitchView";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  anchorEl?: HTMLElement;
  closeMenu: () => void;
  invitations: Invitation[];
  notifications: Notification[];
}

export const HeaderNotificationMenuView: FC<Props> = (props) => {
  const { invitations, notifications } = props;

  const t = useT();
  const action = useRef<PopoverActions>(null);

  const onShouldUpdateMenuPosition = useCallback(() => {
    action.current?.updatePosition();
  }, []);

  const count = notifications.length + invitations.length;

  return (
    <Menu
      action={action}
      anchorEl={props.anchorEl}
      open={!!props.anchorEl}
      onClick={props.closeMenu}
      onClose={props.closeMenu}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      keepMounted={false}
    >
      {count > 0 && [
        ...invitations.map((invitation) => (
          <NotificationListItemForInvitationView
            key={invitation.id}
            invitation={invitation}
            isMenuItem
          />
        )),
        ...notifications.map((notification) => (
          <NotificationListItemSwitchView
            key={notification.id}
            notification={notification}
            onShouldUpdateMenuPosition={onShouldUpdateMenuPosition}
            isMenuItem
          />
        )),
      ]}
      {count === 0 && (
        <MenuItem disabled sx={{ "&&": { opacity: 1 } }} divider>
          <Stack
            height={260}
            width={380}
            spacing={2}
            padding={3}
            justifyContent="center"
            alignItems="center"
          >
            <AvatarWithIconView
              size={54}
              Icon={CheckCircleOutlineIcon}
              color="secondary"
            />
            <Typography variant="h3" textAlign="center">
              {t("HeaderNotificationMenuView_Title_NoNotifications")}
            </Typography>
          </Stack>
        </MenuItem>
      )}
      <MenuItem component={NavLink} to="/notifications" sx={{ py: 3 }}>
        <LinkView>
          {t(
            "HeaderNotificationMenuView_NavLink_ViewAllNotificationsAndUpdates",
          )}
        </LinkView>
      </MenuItem>
    </Menu>
  );
};
