import { Paper, Stack, Typography } from "@mui/material";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { useT } from "../../Translation/hooks/useT";
import { useProjectContext } from "../hooks/useProjectContext";
import { ProjectIssueCategoriesForm } from "./ProjectIssueCategoriesForm";

export const ProjectIssueCategoriesPage = () => {
  const { project } = useProjectContext();
  const t = useT();

  if (!project) return null;

  return (
    <FullHeightContainer maxWidth="md">
      <Stack py={5} spacing={3}>
        <Typography variant="h3">
          {t("ProjectIssueCategoriesPage_Heading_IssueCategories")}
        </Typography>
        <Paper variant="outlined">
          <ProjectIssueCategoriesForm project={project} />
        </Paper>
      </Stack>
    </FullHeightContainer>
  );
};
