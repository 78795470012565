import { Box, IconButton, Menu, Stack, Tooltip } from "@mui/material";
import { CalendarIcon } from "@mui/x-date-pickers";
import { DateRangePickerComponent } from "@snubes/daterange-picker";
import { DateTime } from "luxon";
import { FC, MouseEvent, useState } from "react";
import { LOCALE_RECORD } from "../../Translation/consts/LOCALE_RECORD";
import { getCurrentLocale } from "../../Translation/helpers/getCurrentLocale";
import { useT } from "../../Translation/hooks/useT";
import { useDefaultDateRanges } from "../hooks/useDefaultDateRanges";
import { ProjectController } from "../hooks/useProjectController";
import { ProjectDatePicker } from "./ProjectDatePicker";

const MAX_DATE = DateTime.now().toJSDate();

interface Props {
  projectController: ProjectController;
}

export const ProjectControllerView: FC<Props> = (props) => {
  const { projectController } = props;
  const t = useT();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onClickOpenDateRangePicker = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onCloseDateRangePicker = () => {
    setAnchorEl(null);
  };
  const definedRanges = useDefaultDateRanges();

  return (
    <Stack
      direction="row"
      spacing={2}
      maxWidth={400}
      alignItems="center"
      p={2}
      sx={{
        backgroundColor: "background.paper",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "divider",
        borderRadius: 1,
      }}
    >
      <Tooltip title={t("ProjectControllerView_Tooltip_SelectDateRange")}>
        <Box>
          <IconButton
            id="project-date-ranger-picker-button"
            aria-controls={anchorEl ? "date-ranger-picker" : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? "true" : undefined}
            onClick={onClickOpenDateRangePicker}
          >
            <CalendarIcon />
          </IconButton>
        </Box>
      </Tooltip>
      <Menu
        id="date-ranger-picker"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onCloseDateRangePicker}
        MenuListProps={{
          "aria-labelledby": "project-date-ranger-picker-button",
        }}
      >
        <DateRangePickerComponent
          open
          onChange={projectController.setDateRange}
          locale={LOCALE_RECORD[getCurrentLocale()].dateFnsLocale}
          initialDateRange={projectController.dateRange}
          startDateLabel={t("ProjectControllerView_Label_FromDate")}
          endDateLabel={t("ProjectControllerView_Label_ToDate")}
          definedRanges={definedRanges}
          maxDate={MAX_DATE}
        />
      </Menu>
      <ProjectDatePicker
        label={t("ProjectControllerView_Label_FromDate")}
        value={projectController.fromDate}
        onChange={projectController.setFromDate}
        shouldDisableDate={projectController.shouldDisableFromDate}
        align="startOfDay"
      />
      <ProjectDatePicker
        label={t("ProjectControllerView_Label_ToDate")}
        value={projectController.toDate}
        onChange={projectController.setToDate}
        shouldDisableDate={projectController.shouldDisableToDate}
        align="endOfDay"
      />
    </Stack>
  );
};
