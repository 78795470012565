import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguageIcon from "@mui/icons-material/Language";
import { ListItemButton, Typography } from "@mui/material";
import { FC, MouseEventHandler, useCallback } from "react";
import { useSelectedLocale } from "../../Translation/hooks/useSelectedLocale";

interface Props {
  small?: boolean;
  onSetLanguageMenuAnchorEl: (event: HTMLElement) => void;
}

export const HeaderLanguageMenuButton: FC<Props> = (props) => {
  const { onSetLanguageMenuAnchorEl } = props;
  const selectedLocale = useSelectedLocale();

  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      onSetLanguageMenuAnchorEl(event.currentTarget);
    },
    [onSetLanguageMenuAnchorEl],
  );

  return (
    <ListItemButton dense={props.small} sx={{ px: 3 }} onClick={onClick}>
      <LanguageIcon sx={{ color: "text.secondary" }} />
      <Typography component="span" textTransform="uppercase" mx={2}>
        {selectedLocale}
      </Typography>
      <ExpandMoreIcon />
    </ListItemButton>
  );
};
