import {
  DocumentData,
  FirestoreError,
  getCountFromServer,
  Query,
} from "firebase/firestore";
import { useCallback, useEffect } from "react";
import type { LoadingHook } from "react-firebase-hooks/firestore/dist/util";
import { useIsFirestoreQueryEqual } from "./useIsFirestoreQueryEqual";
import { useIsMounted } from "./useIsMounted";
import { useLoadingValue } from "./useLoadingValue";

export type CollectionCountHook = LoadingHook<number, FirestoreError>;

export type CollectionCountOnceHook = [
  ...CollectionCountHook,
  () => Promise<void>,
];

/**
 * TODO: Remove once https://github.com/CSFrequency/react-firebase-hooks/pull/281/files is merged.
 */
export const useCollectionCountOnce = <T = DocumentData>(
  query?: Query<T> | null,
): CollectionCountOnceHook => {
  const { error, loading, reset, setError, setValue, value } = useLoadingValue<
    number,
    FirestoreError
  >();
  const isMounted = useIsMounted();
  const ref = useIsFirestoreQueryEqual<Query<T>>(query, reset);

  const loadData = useCallback(
    async (query?: Query<T> | null) => {
      if (!query) {
        setValue(undefined);
        return;
      }

      try {
        const result = await getCountFromServer(query);
        if (isMounted) {
          setValue(result?.data()?.count);
        }
      } catch (error) {
        if (isMounted) {
          setError(error as FirestoreError);
        }
      }
    },
    [isMounted, setError, setValue],
  );

  const reloadData = useCallback(() => loadData(ref.current), [loadData, ref]);

  useEffect(() => {
    void loadData(ref.current);
  }, [loadData, ref]);

  return [value, loading, error, reloadData];
};
