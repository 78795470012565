import { FormControlProps, SxProps } from "@mui/material";
import { useCallback, useMemo } from "react";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { useMembers } from "../../Members/hooks/useMembers";
import { getUserName } from "../../Users/helpers/getUserName";
import { ControlledAutocomplete } from "./ControlledAutocomplete";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues & Record<TName, string | null>>;
  name: TName;
  required?: boolean;
  label?: string;
  fullWidth?: boolean;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  size?: FormControlProps["size"];
  sx?: SxProps;
  multiple?: boolean;
  organizationId: string | string[] | undefined;
}

export function ControlledMemberIdAutocomplete<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const [unsortedMembers, isLoading, error] = useMembers(props.organizationId);

  const members = useMemo(
    () =>
      unsortedMembers
        .slice()
        .sort((a, b) => getUserName(a).localeCompare(getUserName(b))),
    [unsortedMembers],
  );

  const options = useMemo(() => {
    return [...new Set(members.map((member) => member.id))];
  }, [members]);

  const getOptionLabel = useCallback(
    (memberId: string) => {
      const member = members.find((m) => m.id === memberId);
      return member ? getUserName(member) : "";
    },
    [members],
  );

  return (
    <ControlledAutocomplete
      control={props.control}
      name={props.name}
      label={props.label}
      options={options}
      multiple={props.multiple}
      getOptionLabel={getOptionLabel}
      required={props.required}
      disabled={props.disabled || isLoading}
      fullWidth={props.fullWidth}
      placeholder={props.placeholder}
      helperText={props.helperText}
      size={props.size}
      sx={props.sx}
      error={error}
    />
  );
}
