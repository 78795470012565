import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import {
  ButtonProps,
  Collapse,
  CollapseProps,
  Divider,
  IconButton,
  Stack,
  SxProps,
} from "@mui/material";
import { FC, ReactNode, useState } from "react";

interface Props extends CollapseProps {
  defaultExpanded?: boolean;
  children: ReactNode;
  headerContent: ReactNode;
  sx?: SxProps;
  iconColor?: ButtonProps["color"];
}

export const CollapseExpandView: FC<Props> = ({
  children,
  headerContent,
  defaultExpanded = false,
  iconColor,
  sx,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  return (
    <>
      <Stack direction="row" alignItems="center" p={2} pr={4} spacing={1}>
        <IconButton
          color={iconColor || "primary"}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
        </IconButton>
        {headerContent}
      </Stack>
      <Collapse sx={sx} in={isExpanded} {...props}>
        <Divider />
        {children}
      </Collapse>
    </>
  );
};
