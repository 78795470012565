import CancelIcon from "@mui/icons-material/Cancel";
import { Stack, Typography } from "@mui/material";
import { FirebaseFilter, Invitation, isInvitation } from "@snubes/snubes-types";
import { FC, useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { handleError } from "../../Common/helpers/handleError";
import { AvatarView } from "../../Common/views/AvatarView";
import { RoundButton } from "../../Common/views/RoundButton";
import { CollectionTableColumn } from "../../Firebase/types/CollectionTableColumn";
import { CollectionTableView } from "../../Firebase/views/CollectionTableView";
import { MemberRoleChip } from "../../Members/views/MemberRoleChip";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { deleteInvitationCallable } from "../callables/deleteInvitationCallable";
import { DEFAULT_MEMBER_ROLE } from "../consts/DEFAULT_MEMBER_ROLE";

interface Props {
  filters: FirebaseFilter<Invitation>[];
}

export const InvitationsCollectionTableView: FC<Props> = (props) => {
  const t = useT();

  const hasPermission = useHasPermission();
  const organizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteInvitation = useCallback(
    async (invitationId: string) => {
      setIsDeleting(true);
      try {
        await deleteInvitationCallable({
          invitationId,
        });
        toast.success(t("InvitationsCollectionTableView_Toast_SuccessMessage"));
      } catch (error) {
        handleError(error).logAnd().toast();
      } finally {
        setIsDeleting(false);
      }
    },
    [t],
  );

  const filters = useMemo(() => {
    const filters = props.filters.slice();
    if (!filters.find((filter) => filter[0] === "acceptedAt")) {
      filters.push(["acceptedAt", "==", null]);
    }
    return filters;
  }, [props.filters]);

  const columns = useMemo<CollectionTableColumn<Invitation>[]>(
    () => [
      {
        title: t("InvitationsCollectionTableView_Title_Email"),
        field: "email",
      },
      {
        title: "Role",
        field: "memberRole",
        renderCell: (invitation) => (
          <MemberRoleChip role={invitation.memberRole || DEFAULT_MEMBER_ROLE} />
        ),
      },
      {
        title: t("InvitationsCollectionTableView_Title_InvitedOn"),
        field: "createdAt",
      },
      {
        title: t("InvitationsCollectionTableView_Title_InvitedBy"),
        field: "invitedByUserName",
        renderCell(invitation) {
          return (
            <Stack direction="row" alignItems="center" spacing={2}>
              <AvatarView size={30} name={invitation.invitedByUserName} />
              <Typography variant="subtitle2">
                {invitation.invitedByUserName}
              </Typography>
            </Stack>
          );
        },
      },

      {
        title: t("Common_Action"),
        align: "right",
        isHidden: !hasPermission("CAN_WRITE_INVITATION", { organizationId }),
        renderCell: (invitation) => (
          <Stack direction="row" justifyContent="flex-end" spacing={3}>
            <RoundButton
              size="small"
              variant="outlined"
              color="error"
              startIcon={<CancelIcon />}
              disabled={isDeleting}
              onClick={() => deleteInvitation(invitation.id)}
            >
              {t("InvitationsCollectionTableView_Title_RevokeInvite")}
            </RoundButton>

            {/*  TODO: Implement resend invite later
              <RoundButton
                size="small"
                variant="outlined"
                startIcon={<CachedIcon />}
              >
                {t("InvitationsCollectionTableView_Title_ResendInvite")}
              </RoundButton> */}
          </Stack>
        ),
      },
    ],
    [deleteInvitation, hasPermission, isDeleting, organizationId, t],
  );

  return (
    <CollectionTableView
      collectionName="invitations"
      keyField="id"
      isT={isInvitation}
      columns={columns}
      filters={filters}
      orderBy={["createdAt", "desc"]}
    />
  );
};
