import { superstructResolver } from "@hookform/resolvers/superstruct";
import { Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { ConnectorConfig } from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Infer, string, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { updateConnectorConfigCallable } from "../callables/updateConnectorConfigCallable";
import { ConnectorConfigErrorView } from "./ConnectorConfigErrorView";
import { ConnectorConfigWebhookView } from "./ConnectorConfigWebhookView";

interface Props {
  connectorConfig: ConnectorConfig & { platform: "DIALFIRE" };
}

const FormValuesStruct = type({
  apiToken: string(),
  name: string(),
});

type FormValues = Infer<typeof FormValuesStruct>;

export const ConnectorConfigDialfireEditForm: FC<Props> = (props) => {
  const { connectorConfig } = props;
  const t = useT();
  const { organizationId, connectorConfigId } = useParams<
    "organizationId" | "connectorConfigId"
  >();

  const { control, formState, handleSubmit } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      name: connectorConfig.name,
      apiToken: connectorConfig.campaignToken,
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!organizationId) {
          throw new Error("No organization selected");
        }
        if (!connectorConfigId) {
          throw new Error("No connectorConfig selected");
        }
        await updateConnectorConfigCallable({
          connectorConfigId,
          organizationId,
          connectorConfig: {
            name: formValues.name,
            campaignToken: formValues.apiToken,
            projectMappings: connectorConfig.projectMappings,
          },
        });
        toast.success(t("ConnectorConfigForm_Toast_Update_Success"));
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [connectorConfig.projectMappings, connectorConfigId, organizationId, t],
  );

  const { isSubmitting } = formState;

  return (
    <>
      {connectorConfig.status === "INTERRUPTED" &&
        connectorConfig.statusErrorMessage && (
          <ConnectorConfigErrorView
            statusErrorMessage={connectorConfig.statusErrorMessage}
            title={t(
              "ConnectorConfigDialfireEditForm_Title_ConnectionInterrupted",
            )}
          />
        )}
      <Stack component={Paper} variant="outlined" p={4} spacing={5}>
        <Typography variant="h3">
          {t("ConnectorConfigDialfireEditForm_Heading_UpdateConnection")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={5}>
            <ControlledTextField
              autoComplete="off"
              required
              fullWidth
              name="name"
              label={t("ConnectorConfigDialfireEditForm_InputLabel_Name")}
              control={control}
              disabled={isSubmitting}
            />
            <Divider />

            <Typography textTransform="uppercase" variant="subtitle1">
              {t("ConnectorConfigDialfireEditForm_SubHeading_Credentials")}
            </Typography>

            <ControlledTextField
              required
              fullWidth
              autoComplete="off"
              name="apiToken"
              label={t("ConnectorConfigDialfireEditForm_InputLabel_Token")}
              control={control}
              disabled={isSubmitting}
            />
            <ConnectorConfigWebhookView connectorConfig={connectorConfig} />
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              {t("ConnectorConfigDialfireEditForm_Button_Update")}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};
