import { superstructResolver } from "@hookform/resolvers/superstruct";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { EmailStruct, PasswordStruct } from "@snubes/snubes-types";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
} from "firebase/auth";
import { FC, useCallback } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Infer, type } from "superstruct";
import { ENVIRONMENT } from "../../Application/consts/ENVIRONMENT";
import { handleError } from "../../Common/helpers/handleError";
import { auth } from "../../Firebase/consts/FIREBASE";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { T } from "../../Translation/views/T";
import { updateUserAuthCallable } from "../callables/updateUserPasswordCallable";

const FormValuesStruct = type({
  newEmail: EmailStruct,
  password: PasswordStruct,
});

type FormValues = Infer<typeof FormValuesStruct>;

interface Props {
  close: () => void;
}

export const UserEditEmailDialog: FC<Props> = (props) => {
  const [authState] = useAuthState(auth);
  const t = useT();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      password: "",
      newEmail: "",
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!authState || !authState.email) return;

        const credential = EmailAuthProvider.credential(
          authState.email,
          formValues.password,
        );

        await reauthenticateWithCredential(authState, credential);

        await updateUserAuthCallable({
          userId: authState.uid,
          email: formValues.newEmail,
        });

        // After the email changed, we need to reauthenticate with the new email address.
        await reauthenticateWithCredential(
          authState,
          EmailAuthProvider.credential(
            formValues.newEmail,
            formValues.password,
          ),
        );

        await sendEmailVerification(authState, {
          url: `https://${ENVIRONMENT.firebaseConfig.authDomain}`,
        });

        toast.success(t("UserEditEmailDialog_Success_Message"));
        props.close();
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [authState, props, t],
  );

  return (
    <Dialog onClose={props.close} open fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <T k="UserEditEmailDialog_Title" />
        </DialogTitle>
        <Divider />
        <DialogContent>
          {authState?.email && (
            <DialogContentText mb={3}>
              {t("UserEditEmailDialog_Subtitle")}{" "}
              <Typography component="span" fontWeight={700} display="inline">
                {authState.email}
              </Typography>
            </DialogContentText>
          )}
          <Stack spacing={3}>
            <ControlledTextField
              required
              fullWidth
              name="password"
              type="password"
              label={t("UserEditEmailDialog_Field_Password")}
              control={control}
              disabled={isSubmitting}
            />
            <ControlledTextField
              required
              fullWidth
              name="newEmail"
              type="email"
              label={t("UserEditEmailDialog_Field_NewEmail")}
              control={control}
              disabled={isSubmitting}
            />
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            disabled={isSubmitting}
            onClick={props.close}
          >
            {t("Common_Close")}
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {t("Common_Save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
