import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { Paper, Stack, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC, PropsWithChildren } from "react";
import { StatusColor } from "../types/StatusColor";
import { AvatarWithIconView } from "./AvatarWithIconView";

interface Props extends PropsWithChildren {
  Icon?: OverridableComponent<SvgIconTypeMap>;
  color?: StatusColor;
}

export const AlertBannerView: FC<Props> = (props) => {
  const iconMap: Record<StatusColor, OverridableComponent<SvgIconTypeMap>> = {
    default: InfoIcon,
    primary: InfoIcon,
    secondary: InfoIcon,
    success: CheckCircleIcon,
    error: ErrorIcon,
    warning: WarningIcon,
  };

  const color = props.color || "default";
  const Icon = props.Icon || iconMap[color];

  return (
    <Stack
      component={Paper}
      direction="row"
      spacing={3}
      p={4}
      alignItems="center"
    >
      <AvatarWithIconView color={color} Icon={Icon} />
      {props.children}
    </Stack>
  );
};
