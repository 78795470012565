import { superstructResolver } from "@hookform/resolvers/superstruct";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  UpdateUserRequest,
  User,
  USER_ROLES,
  UserRoleStruct,
} from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { boolean, Infer, string, type } from "superstruct";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledSelect } from "../../Form/views/ControlledSelect";
import { ControlledSwitch } from "../../Form/views/ControlledSwitch";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { PaperWithTitleView } from "../../Organizations/views/OrganizationEditForm/PaperWithTitleView";
import { useT } from "../../Translation/hooks/useT";
import { updateUserCallable } from "../callables/updateUserCallable";
import { USER_ROLE_RECORD } from "../consts/USER_ROLE_RECORD";

const FormValuesStruct = type({
  firstName: string(),
  lastName: string(),
  role: UserRoleStruct,
  areEmailsEnabledForTaskAssigned: boolean(),
  isHubspotEnabled: boolean(),
});

type FormValues = Infer<typeof FormValuesStruct>;

interface Props {
  user: User;
}

export const UserEditForm: FC<Props> = (props) => {
  const { user } = props;
  const t = useT();
  const hasPermission = useHasPermission();

  const roleOptions = USER_ROLES.map((role) => {
    return {
      value: role,
      label: t(USER_ROLE_RECORD[role].label),
    };
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      areEmailsEnabledForTaskAssigned:
        user?.enabledEmails?.taskAssigned ?? true,
      role: user.role,
      isHubspotEnabled: user.isHubspotEnabled ?? true,
    },
  });

  const onSubmit = async (formValues: FormValues) => {
    try {
      const userUpdate: UpdateUserRequest["user"] = {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        enabledEmails: {
          taskAssigned: formValues.areEmailsEnabledForTaskAssigned,
        },
        ...(hasPermission("CAN_WRITE_USER_ROLE") && {
          role: formValues.role,
        }),
        isHubspotEnabled: formValues.isHubspotEnabled,
      };

      await updateUserCallable({
        userId: user.id,
        user: userUpdate,
      });

      toast.success(t("UserEditForm_Success_Message"));
    } catch (error) {
      handleError(error).logAnd().toast();
    }
  };

  const onClickUserEmailToClipboard = useCallback(async () => {
    if (!user.email) return;
    await navigator.clipboard.writeText(user.email);
    toast.success(t("UserEditForm_Toast_CopiedToClipboard"));
  }, [t, user.email]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaperWithTitleView title={t("UserEditForm_Title_BasicInformation")}>
            <Stack spacing={3}>
              <ControlledTextField
                required
                fullWidth
                name="firstName"
                label={t("UserEditForm_Field_FirstName")}
                control={control}
                disabled={isSubmitting}
              />
              <ControlledTextField
                required
                fullWidth
                name="lastName"
                label={t("UserEditForm_Field_LastName")}
                control={control}
                disabled={isSubmitting}
              />
              <TextField
                value={user.email}
                label={t("UserEditForm_Field_Email")}
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={onClickUserEmailToClipboard}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {hasPermission("CAN_WRITE_USER_ROLE") && (
                <ControlledSelect
                  required
                  fullWidth
                  name="role"
                  label={t("UserEditForm_Select_RoleTitle")}
                  control={control}
                  disabled={isSubmitting}
                  items={roleOptions}
                  renderItem={(item) => item.label}
                />
              )}

              <Typography variant="h3">
                {t("UserEditForm_Heading_EmailNotifications")}
              </Typography>
              <ControlledSwitch
                fullWidth
                name="areEmailsEnabledForTaskAssigned"
                label={t("UserEditForm_Switch_TaskAssigned")}
                control={control}
                disabled={isSubmitting}
              />

              {hasPermission("CAN_WRITE_HUBSPOT") && (
                <>
                  <Typography variant="h3">
                    {t("UserEditForm_Heading_Hubspot")}
                  </Typography>
                  <ControlledSwitch
                    fullWidth
                    name="isHubspotEnabled"
                    label={t("UserEditForm_Switch_Hubspot")}
                    control={control}
                    disabled={isSubmitting}
                  />
                </>
              )}

              <Button type="submit" variant="contained" disabled={isSubmitting}>
                {t("Common_Save")}
              </Button>
            </Stack>
          </PaperWithTitleView>
        </Grid>
      </Grid>
    </form>
  );
};
