import {
  FormControlLabel,
  FormGroup,
  Link,
  Paper,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { ENVIRONMENT } from "../../Application/consts/ENVIRONMENT";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useT } from "../../Translation/hooks/useT";
import { useIsFeatureFlagEnabled } from "../hooks/useIsFeatureFlagEnabled";
import { ADMIN_FEATURE_FLAGS } from "../types/AdminFeatureFlag";

export const AdminFeatureFlagsPage: FC = () => {
  const t = useT();
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled();

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer maxWidth="md">
        <Stack py={5} spacing={3}>
          <Typography variant="h2">
            {t("AdminFeatureFlagsPage_Headline_FeatureFlags")}
          </Typography>
          <FormGroup>
            <Stack component={Paper} p={3}>
              {ADMIN_FEATURE_FLAGS.map((flag) => (
                <FormControlLabel
                  key={flag}
                  control={
                    <Switch
                      checked={isFeatureFlagEnabled(flag)}
                      onChange={(event) => {
                        const state = useLocalStorage.getState();

                        state.setData({
                          featureFlags: {
                            ...state.data.featureFlags,
                            [flag]: event.target.checked,
                          },
                        });
                      }}
                    />
                  }
                  label={flag}
                />
              ))}
            </Stack>
          </FormGroup>

          {ENVIRONMENT.gitHash && (
            <Typography variant="caption" color="text.secondary" px={3}>
              git revision{" "}
              <Link
                target="_blank"
                rel="noopener noreferrer"
                href={`https://github.com/snubes/octopus/commit/${ENVIRONMENT.gitHash}`}
              >
                {ENVIRONMENT.gitHash}
              </Link>
            </Typography>
          )}
        </Stack>
      </FullHeightContainer>
    </>
  );
};
