import { List, ListItem } from "@mui/material";
import { FC } from "react";
import { LinkView } from "../../Common/views/LinkView";
import { LoadingView } from "../../Common/views/LoadingView";
import { useOrganizations } from "../../Organizations/hooks/useOrganizations";
import { PaperWithTitleView } from "../../Organizations/views/OrganizationEditForm/PaperWithTitleView";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  userOrganizationIds: string[];
}

export const UserOrganizationsView: FC<Props> = (props) => {
  const [organizations, isLoading] = useOrganizations([
    ["id", "in", props.userOrganizationIds],
  ]);
  const t = useT();

  return (
    <PaperWithTitleView title={t("UserOrganizationsView_Title_Organizations")}>
      <List
        sx={{
          overflow: "auto",
          display: "flex",
          flexFlow: "column wrap",
          maxHeight: 160,
        }}
      >
        {isLoading && (
          <ListItem>
            <LoadingView />
          </ListItem>
        )}
        {!isLoading &&
          organizations?.map((organization) => {
            return (
              <ListItem key={organization.id} sx={{ width: "auto" }}>
                <LinkView to={`/organizations/${organization.id}/details`}>
                  {organization.name}
                </LinkView>
              </ListItem>
            );
          })}
      </List>
    </PaperWithTitleView>
  );
};
