import { isProviderLog } from "@snubes/snubes-types";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { LogListItemViewForProvider } from "../../Logs/views/LogListItemViewForProvider";
import { LogsButtonView } from "../../Logs/views/LogsButtonView";
import { useT } from "../../Translation/hooks/useT";
import { WizardStep } from "../../Wizard/types/WizardStep";
import { WizardStore } from "../../Wizard/types/WizardStore";
import { WizardHeaderView } from "../../Wizard/views/WizardHeaderView";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

interface Props<TState> {
  steps: WizardStep<TState>[];
  store: WizardStore<TState>;
}

export function ProviderWizardHeaderView<TState>(props: Props<TState>) {
  const { steps, store } = props;
  const providerId = useProviderWizardStore((state) => state.docId);
  const hasPermission = useHasPermission();
  const t = useT();

  return (
    <WizardHeaderView
      backButtonHref="/home"
      backButtonTitle={t("ProviderWizardPage_Button_HeaderBackToDashboard")}
      name={t("ProviderWizardWizardHeaderView_Title_Header")}
      steps={steps}
      store={store}
      rightSlot={
        hasPermission("CAN_READ_LOGS") && !!providerId ? (
          <LogsButtonView
            collectionName={`providers/${providerId}/logs`}
            LogListItemView={LogListItemViewForProvider}
            isT={isProviderLog}
          />
        ) : null
      }
    />
  );
}
