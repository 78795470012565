import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../Common/helpers/handleError";
import { createProjectCallable } from "../callables/createProjectCallable";
import { ProjectForm, ProjectFormValues } from "./ProjectForm";

export const ProjectCreateView: FC = () => {
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (formValues: ProjectFormValues) => {
      try {
        const {
          name,
          tier,
          providerOrganizationId,
          clientOrganizationId,
          projectManagerUserId,
          isLookerAnalyticsEnabled,
          voicefileLanguage,
          responseLanguage,
        } = formValues;
        if (!providerOrganizationId) {
          throw new Error("Invalid form values");
        }
        const response = await createProjectCallable({
          name,
          tier,
          providerOrganizationId,
          ...(!!clientOrganizationId && { clientOrganizationId }),
          ...(!!projectManagerUserId && { projectManagerUserId }),
          ...(!!isLookerAnalyticsEnabled && { isLookerAnalyticsEnabled }),
          ...(!!voicefileLanguage && { voicefileLanguage }),
          ...(!!responseLanguage && { responseLanguage }),
        });
        navigate(`/projects/${response.id}`);
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [navigate],
  );

  return <ProjectForm onSubmit={onSubmit} />;
};
