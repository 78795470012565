import { Alert, Stack, Typography } from "@mui/material";
import { isCallLog } from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { useSidebarWidth } from "../../Common/hooks/useSidebarWidth";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LoadingView } from "../../Common/views/LoadingView";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";
import { LogListItemViewForCall } from "../../Logs/views/LogListItemViewForCall";
import { LogsButtonView } from "../../Logs/views/LogsButtonView";
import { useUpdateNotificationHasBeenRead } from "../../Notifications/hooks/useUpdateNotificationHasBeenRead";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useProjectContext } from "../../Projects/hooks/useProjectContext";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { CALL_SIDEBAR_WIDTH } from "../consts/CALL_SIDEBAR_WIDTH";
import { useCall } from "../hooks/useCall";
import { CallIssuesView } from "./CallIssuesView";
import { CallProcessView } from "./CallProcessView";
import { CallProjectScorecardView } from "./CallProjectScorecardView";
import { CallQuestionAnswersView } from "./CallQuestionAnswersView";
import { CallSidebarView } from "./CallSidebarView";
import { CallSummaryView } from "./CallSummaryView";
import { CallTranscriptView } from "./CallTranscriptView";

export const CallDetailsPage: FC = () => {
  const { projectReportFrameId, callId, notificationTab } = useParams<
    "callId" | "projectReportFrameId" | "notificationTab"
  >();

  const t = useT();
  const { project } = useProjectContext();
  const userId = useMyUser((state) => state?.user?.id || null);
  const selectedOrganizationId = useSelectedOrganization(
    (state) => state?.id || null,
  );
  const [call, isLoading, error] = useCall(project?.id, callId);
  const sidebarWidth = useSidebarWidth(CALL_SIDEBAR_WIDTH);
  const hasPermission = useHasPermission();

  useUpdateNotificationHasBeenRead({
    organizationId: selectedOrganizationId,
    userId,
    filters: callId ? [["callId", "==", callId]] : [],
  });

  if (!callId || !project) return null;

  const callStartTime =
    call?.startTime && DateTime.fromMillis(call.startTime.toMillis());

  const isScorecardVisible =
    call?.isVoicefileAvailable && !!call?.transcripts?.length;

  return (
    <>
      <SecondaryHeaderView
        title={
          callStartTime && (
            <Stack direction="row">
              <Typography variant="subtitle1" color="text.600">
                {callStartTime.toFormat("LLLL dd")} /
              </Typography>
              <Typography ml={1} variant="h4" color="text.primary">
                {callStartTime.toFormat("HH:mm")} - {call.externalCallId}
              </Typography>
            </Stack>
          )
        }
        backButton={
          notificationTab
            ? {
                to: `/notifications/${notificationTab}`,
                label: t("CallDetailsPage_ButtonLabel_BackToNotifications"),
              }
            : projectReportFrameId
            ? {
                to: `/projects/${project.id}/report-frames/${projectReportFrameId}/calls/insights`,
                label: t("CallDetailsPage_ButtonLabel_BackToReportFrame", {
                  date: DateTime.fromISO(projectReportFrameId).toFormat(
                    "dd LLLL, yyyy",
                  ),
                }),
              }
            : {
                to: `/projects/${project.id}/calls/insights`,
                label: t("CallDetailsPage_ButtonLabel_BackToProject"),
              }
        }
      >
        {hasPermission("CAN_READ_LOGS") && (
          <LogsButtonView
            sx={{ position: "absolute", right: 0, mr: 4 }}
            collectionName={`projects/${project.id}/calls/${callId}/logs`}
            LogListItemView={LogListItemViewForCall}
            isT={isCallLog}
          />
        )}
      </SecondaryHeaderView>
      {Boolean(sidebarWidth) && call && <CallSidebarView call={call} />}
      <Stack pl={`${sidebarWidth}px`} pt={3}>
        <FullHeightContainer>
          {isLoading && <LoadingView />}
          {error && (
            <Alert sx={{ m: 3 }} severity="error">
              {error.message}
            </Alert>
          )}
          {call && (
            <Stack pb={5} spacing={5}>
              <CallIssuesView call={call} />
              <CallProcessView call={call} />
              {call.summary && (
                <>
                  <Typography variant="h3">
                    {t("CallDetailsPage_Headline_CallSummary")}
                  </Typography>
                  <CallSummaryView call={call} />
                </>
              )}
              {call.isVoicefileAvailable && (
                <>
                  <Typography variant="h3">
                    {t("CallDetailsPage_Headline_CallVoicefile")}
                  </Typography>
                  <CallTranscriptView call={call} />
                </>
              )}
              {!!call.questionAnswers?.length && (
                <>
                  <Typography variant="h3" py={3}>
                    {t("CallDetailsPage_Headline_Questions")}
                  </Typography>
                  <CallQuestionAnswersView call={call} />
                </>
              )}
              {project.scorecardQuestions && isScorecardVisible && (
                <>
                  <Typography variant="h3" py={3}>
                    {t("CallDetailsPage_Headline_ProjectScorecard")}
                  </Typography>
                  <CallProjectScorecardView
                    call={call}
                    scorecardQuestions={project.scorecardQuestions}
                  />
                </>
              )}
            </Stack>
          )}
        </FullHeightContainer>
      </Stack>
    </>
  );
};
