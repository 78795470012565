import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Divider, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { Call, CallQuestionAnswer } from "@snubes/snubes-types";
import { FC, Fragment, useCallback, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { StatusChip } from "../../Common/views/StatusChip";
import { VoteButtons } from "../../Common/views/VoteButtons";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { updateCallIsQuestionAnswerHelpfulCallable } from "../callables/updateCallIsQuestionAnswerHelpfulCallable";

interface Props {
  call: Call;
}

export const CallQuestionAnswersView: FC<Props> = (props) => {
  const { call } = props;
  const { questionAnswers } = call;
  const t = useT();
  const user = useMyUser((state) => state.user);
  // We store the IDs to not hide buttons when voting and moving the cursor away.
  const [votedQuestionAnswerIds, setVotedQuestionAnswerIds] = useState<
    string[]
  >([]);

  const updateIsHelpful = useCallback(
    async (questionAnswer: CallQuestionAnswer, value: boolean) => {
      try {
        await updateCallIsQuestionAnswerHelpfulCallable({
          questionAnswerId: questionAnswer.id,
          callId: call.id,
          isHelpful: value,
        });
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [call.id],
  );

  if (!user) return null;
  if (!questionAnswers) return null;

  return (
    <Paper variant="outlined">
      {questionAnswers.map((questionAnswer, index) => (
        <Fragment key={questionAnswer.id}>
          <SHoverStack
            areVoteButtonsVisible={
              !!Object.keys(
                call.isQuestionAnswerHelpful?.[questionAnswer.id] || {},
              ).length || votedQuestionAnswerIds.includes(questionAnswer.id)
            }
            direction="row"
          >
            <Stack spacing={1} p={3} flex={1}>
              <Typography variant="body1" color="text.600">
                Q{index + 1}: {questionAnswer.text}
              </Typography>
              {questionAnswer.type === "BOOLEAN" && (
                <Stack direction="row" justifyContent="flex-start" pt={1}>
                  {questionAnswer.booleanValue ? (
                    <StatusChip
                      label={t("Common_Yes")}
                      color="secondary"
                      Icon={CheckCircleIcon}
                    />
                  ) : (
                    <StatusChip
                      label={t("Common_No")}
                      color="error"
                      Icon={CancelIcon}
                    />
                  )}
                </Stack>
              )}
              {questionAnswer.type === "NUMBER" && (
                <Stack direction="row" justifyContent="flex-start" pt={1}>
                  <StatusChip
                    color="primary"
                    label={String(questionAnswer.numberValue)}
                  />
                </Stack>
              )}
              {questionAnswer.type === "TEXT" && (
                <Typography variant="body2">
                  {questionAnswer.textValue}
                </Typography>
              )}
            </Stack>
            <Stack
              className={VOTE_BUTTONS_CLASS_NAME}
              justifyContent="center"
              pr={3}
              py={2}
            >
              <VoteButtons
                votes={call.isQuestionAnswerHelpful?.[questionAnswer.id]}
                setValue={async (value) => {
                  setVotedQuestionAnswerIds((ids) => [
                    ...ids,
                    questionAnswer.id,
                  ]);
                  await updateIsHelpful(questionAnswer, value);
                }}
              />
            </Stack>
          </SHoverStack>
          {questionAnswers.length - 1 !== index && <Divider />}
        </Fragment>
      ))}
      {/* <Stack py={4}>
        <Divider>
          <Typography
            variant="caption1"
            color="text.500"
            textTransform="uppercase"
            px={3}
            py={1}
            borderRadius={6}
            border={1}
            borderColor="divider"
            bgcolor="grey.100"
          >
            {t("CallQuestionChatView_Divider_EndOfProjectQuestions")}
          </Typography>
        </Divider>
      </Stack>
      <Stack spacing={3} px={3} pb={4}>
        {LIVE_QUESTIONS.map((question, index) => (
          <Fragment key={index}>
            <Stack
              spacing={3}
              direction="row-reverse"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Stack>
                <UserAvatarView user={user} size={40} />
              </Stack>
              <Stack
                bgcolor="primary.50"
                px={4}
                py={2}
                borderRadius={6}
                maxWidth="75%"
              >
                <Typography variant="body2">{question.question}</Typography>
              </Stack>
            </Stack>
            <Stack
              spacing={3}
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Stack>
                <Avatar sx={{ bgcolor: "primary.main" }}>AI</Avatar>
              </Stack>
              <Stack
                bgcolor="primary.main"
                px={4}
                py={2}
                borderRadius={6}
                maxWidth="75%"
              >
                <Typography variant="body2" color="background.paper">
                  {question.answer}
                </Typography>
              </Stack>
            </Stack>
          </Fragment>
        ))}
      </Stack>
      <Stack direction="row" p={3} spacing={3}>
        <TextField fullWidth label={t("CallQuestionChatView_Label_Question")} />
        <Button sx={{ px: 5 }} startIcon={<SendIcon />} variant="contained">
          {t("CallQuestionChatView_Button_Send")}
        </Button>
      </Stack> */}
    </Paper>
  );
};

const VOTE_BUTTONS_CLASS_NAME = "vote-buttons";

interface SHoverStackProps {
  areVoteButtonsVisible: boolean;
}

const SHoverStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "areVoteButtonsVisible", // Used to prevent passing the prop to the DOM.
})<SHoverStackProps>((props) => ({
  [`& .${VOTE_BUTTONS_CLASS_NAME}`]: {
    opacity: props.areVoteButtonsVisible ? 1 : 0,
    transform: `scale(${props.areVoteButtonsVisible ? 1 : 0.75})`,
    transition: "all 150ms ease-in-out",
  },
  [`&:hover .${VOTE_BUTTONS_CLASS_NAME}`]: {
    transform: "scale(1)",
    opacity: 1,
  },
}));
