import { superstructResolver } from "@hookform/resolvers/superstruct";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import {
  EmailStruct,
  MEMBER_ROLES,
  MemberRoleStruct,
  Organization,
} from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Infer, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledSelect } from "../../Form/views/ControlledSelect";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { MEMBER_ROLE_RECORD } from "../../Members/consts/MEMBER_ROLE_RECORD";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { createInvitationCallable } from "../callables/createInvitationCallable";
import { DEFAULT_MEMBER_ROLE } from "../consts/DEFAULT_MEMBER_ROLE";

const FormValuesStruct = type({
  email: EmailStruct,
  memberRole: MemberRoleStruct,
});

type FormValues = Infer<typeof FormValuesStruct>;

interface Props {
  close: () => void;
  organization: Organization;
}

export const InviteDialog: FC<Props> = (props) => {
  const { organization } = props;

  const t = useT();
  const user = useMyUser((state) => state.user);

  const {
    control,
    handleSubmit,
    reset: resetForm,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      email: "",
      memberRole: DEFAULT_MEMBER_ROLE,
    },
  });

  const roleOptions = MEMBER_ROLES.map((role) => {
    return {
      value: role,
      label: t(MEMBER_ROLE_RECORD[role].label),
    };
  });

  const onInvite = useCallback(
    async (formValues: FormValues) => {
      if (!user) {
        return;
      }
      try {
        await createInvitationCallable({
          organizationId: organization.id,
          organizationName: organization.name,
          email: formValues.email,
          memberRole: formValues.memberRole,
        });
        resetForm();
        props.close();
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [organization.id, organization.name, props, resetForm, user],
  );

  return (
    <Dialog
      onClose={props.close}
      component={"form"}
      onSubmit={handleSubmit(onInvite)}
      open
      fullWidth
    >
      <DialogTitle>{t("Invitation_InviteDialog_Title")}</DialogTitle>

      <Divider />
      <DialogContent>
        <Stack spacing={3}>
          <ControlledTextField
            required
            fullWidth
            name="email"
            type="email"
            label={t("InviteForm_Label_Email")}
            control={control}
            disabled={isSubmitting}
          />
          <ControlledSelect
            required
            label={t("InviteForm_Label_MemberRole")}
            control={control}
            name="memberRole"
            items={roleOptions}
            renderItem={(item) => item.label}
            fullWidth
            disabled={isSubmitting}
          />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={props.close}>{t("Common_Close")}</Button>

        <Button type="submit" variant="contained" disabled={isSubmitting}>
          {t("Invitation_Button_Invite")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
