import { Button, Grid, Stack } from "@mui/material";
import { FC, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../Firebase/consts/FIREBASE";
import { PaperWithTitleView } from "../../Organizations/views/OrganizationEditForm/PaperWithTitleView";
import { useT } from "../../Translation/hooks/useT";
import { T } from "../../Translation/views/T";
import { UserEditEmailDialog } from "./UserEditEmailDialog";
import { UserEditPasswordDialog } from "./UserEditPasswordDialog";

export const UserEmailPasswordView: FC = () => {
  const [authState] = useAuthState(auth);
  const t = useT();
  const [isEditEmailDialogOpen, setIsEditEmailDialogOpen] = useState(false);
  const [isEditPasswordDialogOpen, setIsEditPasswordDialogOpen] =
    useState(false);
  const hasPasswordProvider = authState?.providerData
    .map((data) => data.providerId)
    .includes("password");

  if (!hasPasswordProvider) return null;

  return (
    <>
      <Grid item xs={12}>
        <PaperWithTitleView
          title={t("UserEmailPasswordView_Title_EmailAndPassword")}
        >
          <Stack direction="row" spacing={3}>
            <Button fullWidth onClick={() => setIsEditEmailDialogOpen(true)}>
              <T k="UserEmailPasswordView_Button_EditEmail" />
            </Button>
            <Button fullWidth onClick={() => setIsEditPasswordDialogOpen(true)}>
              <T k="UserEmailPasswordView_Button_EditPassword" />
            </Button>
          </Stack>
        </PaperWithTitleView>
      </Grid>
      {isEditEmailDialogOpen && (
        <UserEditEmailDialog close={() => setIsEditEmailDialogOpen(false)} />
      )}
      {isEditPasswordDialogOpen && (
        <UserEditPasswordDialog
          close={() => setIsEditPasswordDialogOpen(false)}
        />
      )}
    </>
  );
};
