import { isLocale } from "@snubes/snubes-types";
import { useParams, usePathname } from "next/navigation";
import { FC, PropsWithChildren } from "react";
import { StaticRouter } from "react-router-dom/server";
import { NextCompatRouter } from "./NextCompatRouter";

export const Router: FC<PropsWithChildren> = (props) => {
  const locale = useParams().locale;
  const pathname = usePathname();
  const basename = isLocale(locale) ? `/${locale}` : undefined;

  if (typeof window !== "undefined") {
    return <NextCompatRouter basename={basename} {...props} />;
  }

  return <StaticRouter location={pathname} {...props} />;
};
