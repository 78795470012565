const numberOfEmployeesValues = [10, 50, 200, 500, 1000, 5000, 10000, 10001];

/**
 * @deprecated Use NUMBER_OF_EMPLOYEES_OPTIONS_TYPE_RECORD instead.
 */
export const numberOfEmployeesOptions = numberOfEmployeesValues.map(
  (value, index) => {
    const from = index > 0 ? numberOfEmployeesValues[index - 1] : 1;
    const to = value;
    return {
      value,
      title: `${from} - ${to}`,
    };
  },
);
