import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import { Tender } from "@snubes/snubes-types";
import { TenderApplicationRejectionReason } from "@snubes/snubes-types/types/TenderApplicationRejectionReason";
import { FC, useCallback, useMemo, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { T } from "../../Translation/views/T";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { endTenderCallable } from "../callables/endTenderCallable";
import { TenderApplicationRejectionReasonSelect } from "./TenderApplicationRejectionReasonSelect";

interface Props {
  tender: Tender;
  close: () => void;
}

export const TenderEndDialog: FC<Props> = (props) => {
  const { tender, close } = props;
  const { id: tenderId, providerApplications } = tender;
  const userType = useMyUser((state) => state.user?.userType || null);
  const [isEndingTender, setIsEndingTender] = useState(false);
  const [rejectionReason, setRejectionReason] = useState<
    TenderApplicationRejectionReason | ""
  >("");
  const t = useT();

  const hasPublishedProviderApplications: boolean = useMemo(() => {
    const providerApplicationsList = providerApplications
      ? Object.values(providerApplications)
      : [];

    return providerApplicationsList.some((application) =>
      ["PUBLISHED", "REJECTED_FOR_RESUBMISSION"].includes(application.status),
    );
  }, [providerApplications]);

  const onClickRejectApplication = useCallback(async () => {
    if (isEndingTender) {
      return;
    }

    if (hasPublishedProviderApplications && !rejectionReason) {
      return;
    }

    try {
      setIsEndingTender(true);
      await endTenderCallable({
        tenderId,
        ...(rejectionReason && { rejectionReason }),
      });
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsEndingTender(false);
      close();
    }
  }, [
    close,
    hasPublishedProviderApplications,
    isEndingTender,
    rejectionReason,
    tenderId,
  ]);

  if (!userType) {
    return (
      <Dialog onClose={close} open fullWidth>
        <DialogTitle>
          <T k="TenderEndDialog_MissingUser_Title" />
        </DialogTitle>
        <Divider />
        <DialogActions>
          <Button variant="contained" onClick={close}>
            {t("Common_Close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={close} open fullWidth>
      <DialogTitle>
        <T k="TenderEndDialog_Title" />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText mb={3}>
          {t("TenderEndDialog_Subtitle")}
        </DialogContentText>
        {hasPublishedProviderApplications && (
          <Stack spacing={3}>
            <Alert variant="outlined" severity="warning">
              {t("TenderEndDialog_HasOpenProviderApplications_Alert")}
            </Alert>
            <TenderApplicationRejectionReasonSelect
              userType={userType}
              rejectionReason={rejectionReason}
              onSelectRejectionReason={setRejectionReason}
            />
          </Stack>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" disabled={isEndingTender} onClick={close}>
          {t("Common_Cancel")}
        </Button>
        <Button
          color="error"
          variant="contained"
          disabled={isEndingTender}
          onClick={onClickRejectApplication}
        >
          {t("TenderEndDialog_Button_EndTender")}
          {isEndingTender && <CircularProgress size={20} color="inherit" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
