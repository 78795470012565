import { Alert, Box, Stack, Typography } from "@mui/material";
import { Provider } from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import { LoadingView } from "../../Common/views/LoadingView";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useProvidersById } from "../../Providers/hooks/useProvidersById";
import { useT } from "../../Translation/hooks/useT";
import { useTenderContext } from "../hooks/useTenderContext";
import { TenderApplicationView } from "./TenderApplicationView";

export const TenderApplicationsPage: FC = () => {
  const t = useT();
  const {
    tender,
    isLoading: isLoadingTender,
    error: tenderError,
  } = useTenderContext();

  const rows = useMemo(() => {
    if (!tender?.providerApplications) {
      return [];
    }
    return Object.values(tender.providerApplications)
      .filter((application) =>
        [
          "PUBLISHED",
          "ACCEPTED",
          "REJECTED",
          "REJECTED_FOR_RESUBMISSION",
        ].includes(application.status),
      )
      .sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis());
  }, [tender?.providerApplications]);

  const [providers, isLoadingProviders, providersError] = useProvidersById(
    rows.map((row) => row.providerId),
  );

  const providersById = useMemo(() => {
    if (!providers) {
      return {};
    }
    return providers.reduce(
      (result, provider) => {
        result[provider.id] = provider;
        return result;
      },
      {} as Record<string, Provider>,
    );
  }, [providers]);

  const error = tenderError || providersError;
  const isLoading = isLoadingTender || isLoadingProviders;

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      {isLoading && <LoadingView />}
      {error && <Alert severity="error">{error.message}</Alert>}
      {!isLoading && tender && (
        <Stack spacing={3}>
          <Typography variant="h3">
            {t("TenderApplicationsPage_Title_ReceivedApplications")}
          </Typography>

          <Box>
            {rows.map((row) => (
              <TenderApplicationView
                key={row.providerId}
                tender={tender}
                title={providersById[row.providerId]?.organizationName || "-"}
                application={row}
              />
            ))}
          </Box>
        </Stack>
      )}
    </>
  );
};
