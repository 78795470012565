import { logEvent as logEventFn } from "firebase/analytics";
import { analytics } from "../consts/FIREBASE";

interface LoginArgs {
  event: "login";
}

interface PageViewArgs {
  event: "page_view";
  title: string;
  path: string;
  url: string;
}

type Args = LoginArgs | PageViewArgs;

export function logEvent(args: Args): void {
  if (!analytics) return;

  switch (args.event) {
    case "page_view":
      logEventFn(analytics, args.event, {
        page_title: args.title,
        page_path: args.path,
        page_location: args.url,
      });
      break;
    default:
      logEventFn(analytics, args.event, args);
  }
}
