import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { BudgetType } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface TenderAgentTrainingBudgetType {
  label: TranslationKey;
  caption?: TranslationKey;
  Icon?: OverridableComponent<SvgIconTypeMap>;
}

export const TENDER_AGENT_TRAINING_BUDGET_TYPE_RECORD: Record<
  BudgetType,
  TenderAgentTrainingBudgetType
> = {
  FIXED: {
    label: "TenderAgentTrainingBudgetType_Label_Fixed",
    caption: "TenderAgentTrainingBudgetType_Caption_Fixed",
    Icon: AttachMoneyIcon,
  },
  FLEXIBLE: {
    label: "TenderAgentTrainingBudgetType_Label_Flexible",
    caption: "TenderAgentTrainingBudgetType_Caption_Flexible",
    Icon: MoneyOffIcon,
  },
};
