import GavelIcon from "@mui/icons-material/Gavel";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import PolicyIcon from "@mui/icons-material/Policy";
import {
  Divider,
  Hidden,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { UserType } from "@snubes/snubes-types";
import { signOut } from "firebase/auth";
import { FC } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { NavLink, useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../../Application/consts/ENVIRONMENT";
import { auth } from "../../Firebase/consts/FIREBASE";
import { useMyOrganizations } from "../../Organizations/hooks/useMyOrganizations";
import { useT } from "../../Translation/hooks/useT";
import { getUserName } from "../../Users/helpers/getUserName";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { UserAvatarView } from "../../Users/views/UserAvatarView";
import { HeaderOrganizationMenuItemsView } from "./HeaderOrganizationMenuItemsView";
import { HeaderWorkspacesView } from "./HeaderWorkspacesView";

interface Props {
  anchorEl?: HTMLElement;
  closeMenu: () => void;
  onSetOrganizationId: (organizationId: string) => Promise<void>;
  onSetUserType: (userType: UserType) => Promise<void>;
}

export const HeaderUserMenuView: FC<Props> = (props) => {
  const navigate = useNavigate();
  const userId = useMyUser((state) => state.user?.id);
  const userName = useMyUser((state) =>
    state.user ? getUserName(state.user) : null,
  );
  const organizations = useMyOrganizations(
    (state) => state.organizations || [],
  );
  const [authState] = useAuthState(auth);
  const t = useT();
  const handleSignOut = async () => {
    await signOut(auth);
    navigate("/");
  };

  return (
    <Menu
      anchorEl={props.anchorEl}
      open={!!props.anchorEl}
      onClick={props.closeMenu}
      onClose={props.closeMenu}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      keepMounted={false}
    >
      {userId && (
        <MenuItem component={NavLink} to={`/users/${userId}`}>
          <Stack width={320} alignItems="center" px={2}>
            {userName && <UserAvatarView name={userName} size={72} />}
            <Typography fontWeight="bold" pt={2}>
              {userName}
            </Typography>
            {authState?.email && (
              <Typography color="grey.500">{authState.email}</Typography>
            )}
          </Stack>
        </MenuItem>
      )}

      <Divider />

      <Hidden smUp>
        <HeaderWorkspacesView
          onSetUserType={props.onSetUserType}
          sx={{ p: 3 }}
        />
        <Divider />

        <HeaderOrganizationMenuItemsView
          organizations={organizations}
          closeMenu={props.closeMenu}
          onSetOrganizationId={props.onSetOrganizationId}
        />
        <Divider />
      </Hidden>

      <MenuItem
        component={NavLink}
        target="_blank"
        to={`${ENVIRONMENT.landingPageUrl}/terms-and-conditions`}
      >
        <ListItemIcon>
          <GavelIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t("HeaderUserMenuView_MenuItem_TermsOfUse")}
        </ListItemText>
      </MenuItem>
      <MenuItem
        component={NavLink}
        target="_blank"
        to={`${ENVIRONMENT.landingPageUrl}/privacy-and-cookie-policy`}
      >
        <ListItemIcon>
          <PolicyIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t("HeaderUserMenuView_MenuItem_PrivacyPolicy")}
        </ListItemText>
      </MenuItem>
      <MenuItem component={NavLink} to="/help-center">
        <ListItemIcon>
          <HelpOutlineIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          {t("HeaderUserMenuView_MenuItem_HelpCenter")}
        </ListItemText>
      </MenuItem>
      <MenuItem onClick={handleSignOut}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t("HeaderUserMenuView_MenuItem_Logout")}</ListItemText>
      </MenuItem>
    </Menu>
  );
};
