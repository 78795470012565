import { Box, Button, Popover, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { ControlledColorPicker } from "../../../Form/views/ControlledColorPicker";
import { useT } from "../../../Translation/hooks/useT";
import { T } from "../../../Translation/views/T";

interface Props<T extends FieldValues> {
  primaryColor: string;
  name: FieldPath<T>;
  control: Control<T>;
}

export function OrganizationColorPickerView<T extends FieldValues>(
  props: Props<T>,
) {
  const { primaryColor, name, control } = props;
  const t = useT();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showColorPicker, setShowColorPicker] = useState(false);

  return (
    <>
      <Typography variant="h5">
        <T k="OrganizationColorPickerView_Text_CompanyColor" />
      </Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack spacing={1} direction="row" alignItems="center">
          <Box width={20} height={20} borderRadius={1} bgcolor={primaryColor} />
          <Typography>
            <T k="OrganizationColorPickerView_Field_PrimaryColor" />
          </Typography>
        </Stack>
        <Button
          size="small"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setShowColorPicker(!showColorPicker);
          }}
        >
          {t("OrganizationColorPickerView_Button_SelectColor")}
        </Button>
        <Popover
          anchorEl={anchorEl}
          open={showColorPicker}
          onClose={() => setShowColorPicker(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <ControlledColorPicker
            name={name}
            control={control}
            onClose={() => setShowColorPicker(false)}
          />
        </Popover>
      </Stack>
    </>
  );
}
