/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, TextField } from "@mui/material";
import { FC, SyntheticEvent, useCallback } from "react";
import { useStore } from "zustand";
import { FormOption } from "../../Form/types/FormOption";
import { selectFirebaseTableFilter } from "../helpers/selectFirebaseTableFilter";
import { useFirebaseTableFilterStore } from "../hooks/useFirebaseTableFilters";

interface Props {
  filterName: string;
}

export const FirebaseTableFilterMenuAutocompleteView: FC<Props> = (props) => {
  const { filterName } = props;

  const filterStore = useFirebaseTableFilterStore();
  const filterLabel = useStore(
    filterStore,
    useCallback(
      selectFilter(filterName, (filter) => filter.label),
      [filterName],
    ),
  );
  const options = useStore(
    filterStore,
    useCallback(
      selectFilter(filterName, (filter) => filter.options),
      [filterName],
    ),
  );
  const optionsByValue = useStore(
    filterStore,
    useCallback(
      selectFilter(filterName, (filter) => {
        const optionsByValue = new Map<string, FormOption>();
        filter.options.forEach((option) => {
          optionsByValue.set(option.value, option);
        });
        return optionsByValue;
      }),
      [filterName],
    ),
  );
  const value = useStore(
    filterStore,
    useCallback(
      selectFilter(
        filterName,
        (filter) =>
          filter.value?.flatMap((value) => {
            const option = optionsByValue.get(value);
            return option ? [option] : [];
          }) || [],
      ),
      [filterName],
    ),
  );

  const handleChange = (
    _: SyntheticEvent<Element, Event>,
    value: ReadonlyArray<FormOption>,
  ) => {
    filterStore
      .getState()
      .setFilterValue(
        "autocomplete",
        filterName,
        value.length ? value.map((n) => n.value) : null,
      );
  };

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      value={value}
      options={options}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ width: 300 }}
          label={filterLabel}
          autoComplete="off"
        />
      )}
      onChange={handleChange}
    />
  );
};

const selectFilter = selectFirebaseTableFilter("autocomplete");
