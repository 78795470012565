import { Query, queryEqual } from "firebase/firestore";
import type { RefHook } from "react-firebase-hooks/firestore/dist/util";
import { useComparatorRef } from "./useComparatorRef";

const isQueryEqual = <T extends Query<unknown>>(
  v1: T | null | undefined,
  v2: T | null | undefined,
): boolean => {
  const bothNull: boolean = !v1 && !v2;
  const equal: boolean = !!v1 && !!v2 && queryEqual(v1, v2);
  return bothNull || equal;
};

/**
 * TODO: Remove once https://github.com/CSFrequency/react-firebase-hooks/pull/281/files is merged.
 */
export const useIsFirestoreQueryEqual = <T extends Query<unknown>>(
  value: T | null | undefined,
  onChange?: () => void,
): RefHook<T | null | undefined> => {
  return useComparatorRef(value, isQueryEqual, onChange);
};
