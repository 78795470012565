import { Button } from "@mui/material";
import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { FC, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { auth } from "../../Firebase/consts/FIREBASE";
import { useT } from "../../Translation/hooks/useT";

type Provider = "GOOGLE";

const authProviderMap = {
  GOOGLE: new GoogleAuthProvider(),
};

export const AuthGoogleSsoButton: FC = () => {
  const t = useT();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleContinueWithProvider = (provider: Provider) => async () => {
    try {
      setIsSubmitting(true);
      await signInWithRedirect(auth, authProviderMap[provider]);
    } catch (error) {
      handleError(error).logAnd().toast();
      setIsSubmitting(false);
    }
  };

  return (
    <Button
      variant="outlined"
      onClick={handleContinueWithProvider("GOOGLE")}
      fullWidth
      disabled={isSubmitting}
      startIcon={
        <img
          src="/assets/auth-provider-google.svg"
          alt="Google"
          width={24}
          height={24}
        />
      }
    >
      {t("AuthLoginView_Button_ContinueWithGoogle")}
    </Button>
  );
};
