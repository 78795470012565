import { Container } from "@mui/material";
import { ContainerProps } from "@mui/system";
import { FC } from "react";
import { useHeaderHeight } from "../hooks/useHeaderHeight";

export const FullHeightContainer: FC<ContainerProps> = (props) => {
  const headerHeight = useHeaderHeight();
  const height = `calc(100% - ${headerHeight}px)`;
  return <Container {...props} sx={{ height, ...props.sx }} />;
};
