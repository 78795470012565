import { ProjectReportFrame } from "@snubes/snubes-types";
import { Timestamp } from "firebase/firestore";
import { DateTime, Interval } from "luxon";

interface Args {
  projectReportFrames: ProjectReportFrame[];
  fromDate: DateTime | null;
  toDate: DateTime | null;
}

export function getNormalizedProjectReportFrames(args: Args) {
  const { projectReportFrames, fromDate, toDate } = args;

  if (!fromDate || !toDate) return [];

  return Interval.fromDateTimes(fromDate, toDate.plus({ days: 1 }))
    .splitBy({ days: 1 })
    .map<ProjectReportFrame>((interval) => {
      const frame = projectReportFrames.find(
        (f) => f.date.toMillis() === interval.start.toMillis(),
      );

      if (frame) return frame;

      return {
        id: interval.start.toISODate(),
        date: Timestamp.fromMillis(interval.start.toMillis()),
        connectorConfigId: "unknown",
        projectId: "unknown",
        externalProjectId: "unknown",
        totalCallsCount: 0,
        totalCallsIncomingCount: 0,
        totalCallsOutgoingCount: 0,
        answeredCallsCount: 0,
        answeredCallsIncomingCount: 0,
        answeredCallsOutgoingCount: 0,
        totalHandlingTimeInSeconds: 0,
        totalHandlingTimeIncomingSeconds: 0,
        totalHandlingTimeOutgoingSeconds: 0,
        averageHandlingTimeInSeconds: 0,
        averageHandlingTimeIncomingSeconds: 0,
        averageHandlingTimeOutgoingSeconds: 0,
        serviceLevelThresholdInSeconds: 0,
        serviceLevelPercentage: 0,
        reachabilityPercentage: 0,
        reachabilityIncomingPercentage: 0,
        reachabilityOutgoingPercentage: 0,
        createdAt: Timestamp.fromMillis(interval.start.toMillis()),
        updatedAt: Timestamp.fromMillis(interval.start.toMillis()),
      };
    });
}
