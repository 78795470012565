import {
  SvgIconTypeMap,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC } from "react";
import { CallSidebarItemWithChildrenView } from "./CallSidebarItemWithChildrenView";

interface Props {
  Icon: OverridableComponent<SvgIconTypeMap>;
  label: string;
  value: string;
  valueColor?: string;
  valueSx?: SxProps<Theme>;
  tooltip?: string;
}

export const CallSidebarItemView: FC<Props> = (props) => {
  const { Icon, label, value, valueColor, valueSx, tooltip } = props;

  return (
    <CallSidebarItemWithChildrenView Icon={Icon}>
      <Typography variant="caption1" color="text.secondary">
        {label}
      </Typography>
      <Tooltip title={tooltip}>
        <Typography variant="h4" color={valueColor} sx={valueSx}>
          {value}
        </Typography>
      </Tooltip>
    </CallSidebarItemWithChildrenView>
  );
};
