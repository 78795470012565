import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TuneIcon from "@mui/icons-material/Tune";
import { Container, Stack, Typography } from "@mui/material";
import {
  ChannelType,
  OperatingHoursTypeStruct,
  ServiceType,
  WeekdayPeriodStruct,
} from "@snubes/snubes-types";
import { FC } from "react";
import { array, nullable, optional, string, type } from "superstruct";
import { ControlledAutocompleteTimeZone } from "../../Form/views/ControlledAutocompleteTimeZone";
import { ControlledRadioButtons } from "../../Form/views/ControlledRadioButtons";
import { ControlledWeekdayPeriods } from "../../Form/views/ControlledWeekdayPeriods";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { BROWSER_TIME_ZONE_NAME } from "../consts/BROWSER_TIME_ZONE_NAME";
import { findTenderServiceChannel } from "../helpers/findTenderServiceChannel";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  operatingHoursType: nullable(OperatingHoursTypeStruct),
  operatingHours: array(WeekdayPeriodStruct),
  operatingTimeZone: optional(string()),
});

type Props = WizardFormProps<{
  serviceType: ServiceType;
  channelType: ChannelType;
}>;

export const TenderWizardChannelOperatingHoursForm: FC<Props> = (props) => {
  const { serviceType, channelType } = props.formProps;
  const t = useT();
  const { control, handleSubmit, onSubmit, watch } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => {
      const channel = findTenderServiceChannel(
        serviceType,
        channelType,
        tender,
      );

      return {
        operatingTimeZone: channel?.operatingTimeZone || BROWSER_TIME_ZONE_NAME,
        operatingHoursType: channel?.operatingHoursType || null,
        operatingHours:
          channel?.operatingHours ||
          tender?.operatingHours?.map((o) => ({ ...o })) ||
          [],
      };
    },
    toDoc: (formValues, tender) => {
      const channel = findTenderServiceChannel(
        serviceType,
        channelType,
        tender,
      );

      if (channel && formValues.operatingHoursType) {
        channel.operatingHoursType = formValues.operatingHoursType;
        if (formValues.operatingHoursType === "CUSTOM") {
          channel.operatingHours = formValues.operatingHours;
          channel.operatingTimeZone = formValues.operatingTimeZone;
        } else {
          delete channel.operatingTimeZone;
          delete channel.operatingHours;
        }
      }

      return {
        services: tender?.services,
        isReviewed: {
          ...tender?.isReviewed,
          services: false,
        },
      };
    },
  });

  const operatingHoursType = watch("operatingHoursType");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardChannelOperatingHoursForm_Title")}
        </Typography>
        <Typography variant="body2" color="text.500" mb={4}>
          {t("TenderWizardChannelOperatingHoursForm_Label")}
        </Typography>
        <Stack spacing={4}>
          <ControlledRadioButtons
            control={control}
            name="operatingHoursType"
            options={[
              {
                Icon: ContentCopyIcon,
                label: "TenderWizardChannelOperatingHoursForm_Label_Default",
                caption:
                  "TenderWizardChannelOperatingHoursForm_Caption_Default",
                value: "DEFAULT",
              },
              {
                Icon: AccessTimeIcon,
                label:
                  "TenderWizardChannelOperatingHoursForm_Label_AroundTheClock",
                caption:
                  "TenderWizardChannelOperatingHoursForm_Caption_AroundTheClock",
                value: "AROUND_THE_CLOCK",
              },
              {
                Icon: TuneIcon,
                label: "TenderWizardChannelOperatingHoursForm_Label_Custom",
                caption: "TenderWizardChannelOperatingHoursForm_Caption_Custom",
                value: "CUSTOM",
              },
            ]}
            gridSpacing={4}
            gridItemXs={4}
          />

          {operatingHoursType === "CUSTOM" && (
            <Stack flex={1} spacing={4}>
              <ControlledAutocompleteTimeZone
                control={control}
                name="operatingTimeZone"
                required
              />
              <ControlledWeekdayPeriods
                control={control}
                name="operatingHours"
                required
              />
            </Stack>
          )}
        </Stack>
      </Container>
    </form>
  );
};
