import { superstructResolver } from "@hookform/resolvers/superstruct";
import { Button, Divider, Stack } from "@mui/material";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Infer, nullable, string, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { isProjectMappable } from "../../Common/helpers/isProjectMappable";
import { ControlledProjectIdAutocomplete } from "../../Form/views/ControlledProjectIdAutocomplete";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { createConnectorConfigCallable } from "../callables/createConnectorConfigCallable";

const FormValuesStruct = type({
  name: string(),
  projectId: nullable(string()),
  projectName: string(),
});

type FormValues = Infer<typeof FormValuesStruct>;

export const ConnectorConfigGenericWebhookForm: FC = () => {
  const t = useT();
  const navigate = useNavigate();
  const { organizationId } = useParams<"organizationId">();
  const { control, formState, handleSubmit } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      name: "",
      projectId: null,
      projectName: "",
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!organizationId) throw new Error("No organization selected");
        if (!formValues.projectId) throw new Error("No project selected");

        const { id } = await createConnectorConfigCallable({
          name: formValues.name,
          organizationId,
          platform: "GENERIC_WEBHOOK",
          projectMappings: [
            {
              projectId: formValues.projectId,
              projectName: formValues.projectName,
              externalProjectId: "GENERIC_WEBHOOK",
            },
          ],
        });

        navigate(`/organizations/${organizationId}/connector-configs/${id}`);
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [navigate, organizationId],
  );

  const { isSubmitting } = formState;

  return (
    <Stack pt={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <ControlledTextField
            required
            fullWidth
            autoComplete="off"
            name="name"
            label={t("ConnectorConfigGenericWebhookForm_Label_Name")}
            control={control}
            disabled={isSubmitting}
          />
          <Divider />
          {organizationId && (
            <ControlledProjectIdAutocomplete
              filter={(project) => isProjectMappable(project)}
              name="projectId"
              projectNameFieldName="projectName"
              control={control}
              label={t("ConnectorConfigGenericWebhookForm_Label_Project")}
              organizationId={organizationId}
              disabled={isSubmitting}
              required
            />
          )}
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {t("Common_Submit")}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};
