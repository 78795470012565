import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import { Provider } from "@snubes/snubes-types";
import { FC } from "react";
import { ProviderDetailsView } from "../../Providers/views/ProviderDetailsView";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  close: () => void;
  onExited?: () => void;
  provider?: Provider;
}

export const TenderWizardProvidersDialog: FC<Props> = (props) => {
  const t = useT();

  return (
    <Dialog
      onClose={props.close}
      open={!!props.provider}
      TransitionProps={{
        onExited: props.onExited,
      }}
      fullWidth
      maxWidth="lg"
    >
      {props.provider && (
        <DialogTitle>{props.provider.organizationName}</DialogTitle>
      )}
      <Divider />
      <DialogContent>
        <Stack spacing={3}>
          {props.provider && <ProviderDetailsView provider={props.provider} />}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={props.close}>
          {t("Common_Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
