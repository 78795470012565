"use client";
import "../../setup";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ReactNode } from "react";
import { Toaster } from "react-hot-toast";
import { Router } from "../../Application/views/Router";
import { AuthInitView } from "../../Auth/views/AuthInitView";
import { AuthView } from "../../Auth/views/AuthView";
import { AnalyticsProvider } from "../../Firebase/views/AnalyticsProvider";
import { HeaderView } from "../../Header/views/HeaderView";

interface Props {
  children: ReactNode;
}

export default function PageLayout(props: Props) {
  const { children } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Router>
        <AnalyticsProvider>
          <HeaderView />
          <AuthInitView />
          <AuthView />
          {children}
        </AnalyticsProvider>
      </Router>
      <Toaster />
    </LocalizationProvider>
  );
}
