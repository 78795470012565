import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  SvgIconTypeMap,
  Typography,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ProcessStatus, Timestamp } from "@snubes/snubes-types";
import { FC, ReactElement, useState } from "react";
import { PROCESS_STATUS_RECORD } from "../../Common/consts/PROCESS_STATUS_RECORD";
import { useT } from "../../Translation/hooks/useT";
import { CallProcessingDurationView } from "./CallProcessingDurationView";

interface Props {
  title: string;
  caption: string;
  errorMessage?: string;
  processStatus: ProcessStatus;
  startedAt?: Timestamp;
  button?: ReactElement;
  Icon?: OverridableComponent<SvgIconTypeMap>;
  color?: string;
  backgroundColor?: string;
}

export const CallProcessItemView: FC<Props> = (props) => {
  const { title, caption, processStatus, startedAt, button, errorMessage } =
    props;

  const value = PROCESS_STATUS_RECORD[processStatus];
  const captionColor = props.color || value.color;
  const titleColor =
    props.color ||
    (processStatus === "ON_HOLD" ? "text.secondary" : "text.main");
  const backgroundColor = props.backgroundColor || "background.paper";
  const Icon = props.Icon || value.Icon;
  const t = useT();

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const isLoading = ["PROCESSING", "REQUESTED"].includes(processStatus);

  return (
    <Stack
      bgcolor={backgroundColor}
      direction="row"
      py={3}
      px={4}
      spacing={2}
      alignItems="center"
    >
      <Stack flex={1} spacing={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack>
            {isLoading && <CircularProgress sx={{ mr: 0.5 }} size={16} />}
            {!isLoading && (
              <Icon fontSize="small" sx={{ color: captionColor }} />
            )}
          </Stack>
          <Typography variant="h5" color={titleColor}>
            {title}
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="caption1" color={captionColor}>
            {caption}
          </Typography>
        </Stack>
      </Stack>
      {errorMessage && (
        <Button
          size="tiny"
          color="error"
          variant="outlined"
          onClick={() => setIsErrorDialogOpen(true)}
        >
          {t("CallProcessItemView_Button_ViewErrorDetails")}
        </Button>
      )}
      {processStatus === "PROCESSING" && startedAt && (
        <CallProcessingDurationView startedAt={startedAt} />
      )}
      <Stack>{button}</Stack>
      <Dialog
        fullWidth
        open={isErrorDialogOpen}
        onClose={() => setIsErrorDialogOpen(false)}
      >
        <DialogTitle>{caption}</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setIsErrorDialogOpen(false)}
          >
            {t("Common_Close")}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
