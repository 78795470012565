import { Tender } from "@snubes/snubes-types";
import { useCallback } from "react";
import { toLocaleMoney } from "../../Common/helpers/toLocaleMoney";
import { useT } from "../../Translation/hooks/useT";
import { TENDER_BUDGET_DURATION_TYPE } from "../consts/TENDER_BUDGET_DURATION_TYPE";
import { TENDER_BUDGET_TYPE } from "../consts/TENDER_BUDGET_TYPE";

export function useGetTenderBudgetValue() {
  const t = useT();

  return useCallback(
    (tender: Partial<Tender>) => {
      if (tender.budgetType === "FLEXIBLE") {
        return t(TENDER_BUDGET_TYPE["FLEXIBLE"].label);
      }

      const localeMoney = toLocaleMoney(tender.budget);

      if (
        tender.budgetType === "FIXED" &&
        tender.budgetDurationType &&
        localeMoney
      ) {
        return `${localeMoney} - ${t(
          TENDER_BUDGET_DURATION_TYPE[tender.budgetDurationType],
        )}`;
      }
    },
    [t],
  );
}
