import { Checkbox, Stack } from "@mui/material";
import { ChangeEvent, MouseEvent, ReactNode, useCallback } from "react";
import { useFirebaseTableRowSelection } from "../hooks/useFirebaseTableRowSelection";
import { CheckboxTableCellViewProps } from "../types/CheckboxTableCellViewProps";
import { CollectionTableCellContentView } from "./CollectionTableCellContentView";

interface Props<T> extends CheckboxTableCellViewProps<T> {
  row: T;
  field?: keyof T;
  renderCell?: (row: T) => ReactNode;
  isCheckboxDisabled?: (row: T) => boolean;
}

export function CheckboxTableCellView<T>(props: Props<T>) {
  const { isRowSelected, handleRowCheckboxChange } =
    useFirebaseTableRowSelection({
      rows: props.rows,
      getRowId: props.getRowId,
      onRowSelectionChanged: props.onRowSelectionChanged,
    });

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleRowCheckboxChange(e.target.checked, props.row);
    },
    [handleRowCheckboxChange, props.row],
  );

  return (
    <Stack direction="row" alignItems="center" gap={3}>
      <Checkbox
        checked={isRowSelected(props.row)}
        disabled={props.isCheckboxDisabled?.(props.row)}
        onChange={onChange}
        onClick={onClick}
        name="header"
        color="primary"
      />
      <CollectionTableCellContentView
        row={props.row}
        field={props.field}
        renderCell={props.renderCell}
      />
    </Stack>
  );
}

const onClick = (e: MouseEvent) => e.stopPropagation();
