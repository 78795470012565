import { Project } from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { getProjectReportFramesFromLastSevenDays } from "./getProjectReportFramesFromLastSevenDays";

export function getProjectServiceLevelPercentage(project: Project) {
  const now = DateTime.now().toISODate();
  const today =
    project.reportFramesByDay?.find((r) => r.id === now)
      ?.serviceLevelPercentage || 0;
  const frames = getProjectReportFramesFromLastSevenDays(project);
  const totalCallsCount = frames.reduce(
    (result, frame) => result + frame.totalCallsCount,
    0,
  );
  const lastSevenDays = totalCallsCount
    ? frames.reduce(
        (result, frame) =>
          result + (frame.serviceLevelPercentage || 0) * frame.totalCallsCount, // TODO: serviceLevelPercentage should be required
        0,
      ) / totalCallsCount
    : 0;

  return { today, lastSevenDays };
}
