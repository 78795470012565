import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SendIcon from "@mui/icons-material/Send";
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useStore } from "zustand";
import { useSidebarWidth } from "../../Common/hooks/useSidebarWidth";
import { useT } from "../../Translation/hooks/useT";
import { useWizardRootStep } from "../hooks/useWizardRootStep";
import { useWizardStepProgress } from "../hooks/useWizardStepProgress";
import { WizardStep } from "../types/WizardStep";
import { WizardStore } from "../types/WizardStore";

interface Props<TState> {
  steps: WizardStep<TState>[];
  activeStep?: WizardStep<TState>;
  store: WizardStore<TState>;
  onSubmitNavigateTo: string;
}

export function WizardFooterView<TState>(props: Props<TState>) {
  const { activeStep, store, onSubmitNavigateTo } = props;
  const docId = useStore(store, (state) => state.docId);
  const isSaving = useStore(store, (state) => state.isSaving);
  const isDirty = useStore(store, (state) => state.isDirty);
  const isSubmitting = useStore(store, (state) => state.isSubmitting);
  const isAutosaveDisabled = useStore(
    store,
    (state) => state.isAutosaveDisabled,
  );
  const rootStep = useWizardRootStep(props.steps);
  const isWizardComplete = useWizardStepProgress(rootStep, store) >= 1;
  const sidebarWidth = useSidebarWidth();
  const navigate = useNavigate();
  const t = useT();

  const isFirstStep = !activeStep?.previousStep?.path;
  const isSubmitStep = !activeStep?.nextStep?.path;

  return (
    <Stack
      flexDirection="row"
      position="fixed"
      right={0}
      bottom={0}
      left={sidebarWidth}
      p={3}
      borderTop={1}
      borderColor="divider"
      justifyContent="space-between"
      bgcolor="background.paper"
      zIndex={2}
    >
      <Button
        href={activeStep?.previousStep?.path || "/"}
        disabled={isFirstStep}
        variant="regular"
        sx={{ minWidth: 320 }}
        startIcon={<ChevronLeftIcon />}
      >
        {t("Common_Back")}
        {activeStep?.previousStep ? `: ${activeStep?.previousStep.title}` : ""}
      </Button>
      {isSubmitStep && (
        <Button
          disabled={
            (!isAutosaveDisabled && isDirty) ||
            (isAutosaveDisabled && !isDirty) ||
            isSubmitting ||
            isSaving ||
            !docId ||
            !isWizardComplete
          }
          variant="contained"
          color="secondary"
          onClick={async () => {
            await store.getState().submit();
            navigate(onSubmitNavigateTo);
          }}
          sx={{ minWidth: 320 }}
          endIcon={isAutosaveDisabled ? <SaveAltIcon /> : <SendIcon />}
        >
          {isAutosaveDisabled ? t("Common_Save") : t("Common_Submit")}
        </Button>
      )}
      {!isSubmitStep && (
        <Button
          href={activeStep?.nextStep?.path || "/"}
          disabled={isSubmitting}
          variant="contained"
          sx={{ minWidth: 320 }}
          endIcon={<ChevronRightIcon />}
        >
          {t("Common_Next")}
          {activeStep?.nextStep ? `: ${activeStep?.nextStep.title}` : ""}
        </Button>
      )}
    </Stack>
  );
}
