import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import { Stack, Typography } from "@mui/material";
import { Call } from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import { CounterView } from "../../Common/views/CounterView";
import { LinkView } from "../../Common/views/LinkView";
import { PaperTooltipView } from "../../Common/views/PaperTooltipView";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";
import { useGetCallIssueName } from "../hooks/useGetCallIssueName";

const MAX_ISSUES_COUNT = 2;

interface Props {
  call: Call;
  to: string;
}

export const CallFlagView: FC<Props> = (props) => {
  const t = useT();
  const issues = useMemo(
    () =>
      props.call.issues
        ? Object.values(props.call.issues).sort((a, b) =>
            a.name.localeCompare(b.name),
          )
        : [],
    [props.call.issues],
  );
  const hasIssues = issues.length > 0;
  const getCallIssueName = useGetCallIssueName();

  return (
    <PaperTooltipView
      disableHoverListener={!hasIssues}
      placement="bottom-end"
      title={
        <Stack spacing={3} p={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <CounterView sx={{ bgcolor: "error.main" }}>
              {issues.length}
            </CounterView>
            <Typography color="text.600" variant="h5">
              {t("CallFlagView_Title_Issues")}
            </Typography>
          </Stack>

          <Stack spacing={2}>
            {issues.slice(0, MAX_ISSUES_COUNT).map((issue) => (
              <div key={issue.id}>
                <StatusChip label={getCallIssueName(issue)} color="error" />
              </div>
            ))}
          </Stack>
          <LinkView to={props.to}>
            {t("CallFlagView_Label_ViewAllIssues_Start")}
            <CounterView
              sx={{
                bgcolor: "primary.main",
                display: "inline-flex",
                mx: 1,
              }}
            >
              {issues.length}
            </CounterView>
            {t("CallFlagView_Label_ViewAllIssues_End")}
          </LinkView>
        </Stack>
      }
    >
      <FlagCircleIcon
        sx={{ color: hasIssues ? "error.main" : "text.disabled", fontSize: 26 }}
      />
    </PaperTooltipView>
  );
};
