import { superstructResolver } from "@hookform/resolvers/superstruct";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Infer, nullable, string, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledIssueCategoryIdAutocomplete } from "../../Form/views/ControlledIssueCategoryIdAutocomplete";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useProjectContext } from "../../Projects/hooks/useProjectContext";
import { useT } from "../../Translation/hooks/useT";
import { createCallIssueCallable } from "../callables/createCallIssueCallable";

const FormValuesStruct = type({
  name: string(),
  issueCategoryId: nullable(string()),
});

type FormValues = Infer<typeof FormValuesStruct>;

interface Props {
  callId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const CallIssueCreateDialog: FC<Props> = (props) => {
  const t = useT();
  const { callId, isOpen, onClose } = props;
  const { project } = useProjectContext();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      name: "",
      issueCategoryId: null,
    },
  });

  const onReportIssueClicked = useCallback(
    async (values: FormValues) => {
      try {
        const { name, issueCategoryId } = values;
        if (!name) return;

        await createCallIssueCallable({
          callId,
          callIssue: {
            name,
            ...(issueCategoryId && { issueCategoryId }),
          },
        });
        toast.success(t("CallIssueCreateDialog_Toast_Created"));
        reset();
        onClose();
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [callId, onClose, reset, t],
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onReportIssueClicked)}>
        <DialogTitle>{t("CallIssueCreateDialog_Button_Title")}</DialogTitle>
        <DialogContent>
          <Stack spacing={3} mt={3}>
            <ControlledTextField
              label={t("CallIssueCreateDialog_Label_Reason")}
              name="name"
              control={control}
              disabled={isSubmitting}
              fullWidth
              required
              autoFocus
            />
            {project?.issueCategories?.length && (
              <ControlledIssueCategoryIdAutocomplete
                label={t("CallIssueCreateDialog_Label_IssueCategory")}
                name="issueCategoryId"
                control={control}
                disabled={isSubmitting}
                issueCategories={project.issueCategories}
                fullWidth
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="text" disabled={isSubmitting}>
            {t("CallIssueCreateDialog_Button_Cancel")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="error"
            disabled={isSubmitting}
          >
            {t("CallIssueCreateDialog_Button_Create")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
