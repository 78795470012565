import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  CircularProgress,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { FC, MouseEventHandler, useCallback } from "react";
import { AvatarView } from "../../Common/views/AvatarView";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { USER_TYPE_RECORD } from "../../Users/consts/USER_TYPE_RECORD";
import { useMyUser } from "../../Users/hooks/useMyUser";

interface Props {
  onSetOrganizationMenuAnchorEl: (event: HTMLElement) => void;
  isLoading?: boolean;
}

export const HeaderOrganizationMenuButton: FC<Props> = (props) => {
  const { onSetOrganizationMenuAnchorEl, isLoading } = props;

  const t = useT();

  const organizationName = useSelectedOrganization(
    (organization) => organization?.name || "",
  );
  const userType = useMyUser((state) => state.user?.userType);

  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      onSetOrganizationMenuAnchorEl(event.currentTarget);
    },
    [onSetOrganizationMenuAnchorEl],
  );

  return (
    <ListItemButton sx={{ px: 3 }} onClick={onClick} disabled={isLoading}>
      {!isLoading && <AvatarView name={organizationName} size={24} />}
      {isLoading && (
        <Box>
          <CircularProgress size={24} />
        </Box>
      )}
      <Stack component="span" mx={2} maxWidth={175}>
        <Typography
          variant="h5"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {organizationName}
        </Typography>
        {userType && (
          <Typography
            variant="caption"
            color="text.secondary"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {t(USER_TYPE_RECORD[userType].workspaceName)}
          </Typography>
        )}
      </Stack>
      <ExpandMoreIcon />
    </ListItemButton>
  );
};
