import { Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { WizardStep } from "../types/WizardStep";
import { WizardStore } from "../types/WizardStore";
import { WizardTabLabelView } from "./WizardTabLabelView";

interface Props<TState> {
  step: WizardStep<TState>;
  store: WizardStore<TState>;
}

export function WizardTabsView<TState>(props: Props<TState>) {
  const { step, store } = props;
  const { pathname } = useLocation();
  const hasActiveStep = step.steps?.some((s) => s.isActive);

  return (
    <Stack
      mt={-6}
      mb={6}
      borderBottom={1}
      sx={{ borderBottomColor: "divider" }}
    >
      <Container maxWidth="md">
        <Stack pt={4} spacing={2} mb={3}>
          {step.tabsLabel && (
            <Typography variant="caption1" color="primary.main">
              {step.tabsLabel}
            </Typography>
          )}
          <Typography variant="h3">{step.title}</Typography>
        </Stack>
        {hasActiveStep && (
          <Tabs value={pathname} variant="scrollable">
            {step.steps?.map((step) => (
              <Tab
                key={step.path}
                component={NavLink}
                to={step.path || "/"}
                value={step.path || "/"}
                label={<WizardTabLabelView step={step} store={store} />}
              />
            ))}
          </Tabs>
        )}
      </Container>
    </Stack>
  );
}
