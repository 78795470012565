import { Call } from "@snubes/snubes-types";
import { getCallTranscripts } from "./getCallTranscripts";

/**
 * @deprecated Use information from PSH CDR instead.
 */
export function isVoicefileMono(call: Call) {
  const transcripts = getCallTranscripts(call);
  return new Set(transcripts?.map((t) => t.participantRole)).size < 2;
}
