import { FC } from "react";
import { Navigate } from "react-router-dom";
import { USER_TYPE_RECORD } from "../../Users/consts/USER_TYPE_RECORD";
import { useMyUser } from "../../Users/hooks/useMyUser";

export const TendersIndexPage: FC = () => {
  const defaultTendersPath = useMyUser(
    (state) =>
      USER_TYPE_RECORD[state.user?.userType || "CLIENT"]?.defaultTendersPath,
  );
  return <Navigate replace to={defaultTendersPath} />;
};
