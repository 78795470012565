import { Divider, Paper, Stack, Typography } from "@mui/material";
import {
  FirebaseFilter,
  Tender,
  TenderApplicationStatus,
  TenderStatus,
} from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import {
  FirebaseTableFilterContextProvider,
  FirebaseTableFilterRecord,
} from "../../Firebase/hooks/useFirebaseTableFilters";
import { useT } from "../../Translation/hooks/useT";
import { TendersCollectionTableView } from "./TendersCollectionTableView";

interface Props {
  organizationId: string;
}

export const TendersWonView: FC<Props> = (props) => {
  const t = useT();

  const filters = useMemo<FirebaseTableFilterRecord<Tender>>(
    () => ({
      name: {
        isSearch: true,
        label: t("FirebaseTableFilterView_Label_Search"),
        type: "text",
        operator: "search",
        value: null,
      },
    }),
    [t],
  );

  const tableFilters = useMemo<FirebaseFilter<Tender>[]>(() => {
    return [
      ["status", "in", ["PUBLISHED", "ENDED"] satisfies TenderStatus[]],
      ["isDeleted", "==", false],
      [
        `providerApplications.${props.organizationId}.status`,
        "==",
        "ACCEPTED" satisfies TenderApplicationStatus,
      ],
    ];
  }, [props.organizationId]);

  return (
    <Stack py={5} spacing={3}>
      <Typography variant="h2">
        {t("TendersWonView_Headline_TendersWon")}
      </Typography>
      <Divider />
      <Paper>
        <FirebaseTableFilterContextProvider filters={filters}>
          <TendersCollectionTableView filters={tableFilters} />
        </FirebaseTableFilterContextProvider>
      </Paper>
    </Stack>
  );
};
