import { Container, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { string, type } from "superstruct";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  description: string(),
});

export const TenderWizardDescriptionForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => ({
      description: tender?.description || "",
    }),
    toDoc: (formValues, tender) => ({
      description: formValues.description || undefined,
      isReviewed: {
        ...tender?.isReviewed,
        operationalDetails: false,
      },
    }),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="sm">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardDescriptionForm_Title_Description")}
        </Typography>
        <Stack spacing={3}>
          <ControlledTextField
            required
            fullWidth
            name="description"
            label={t("TenderWizardDescriptionForm_Label_Description")}
            control={control}
            minRows={3}
            multiline
            autoFocus
          />
        </Stack>
      </Container>
    </form>
  );
};
