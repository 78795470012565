import { InputAdornment, Stack, TextField } from "@mui/material";
import { Money } from "@snubes/snubes-types";
import { ReactNode } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { CURRENCY_RECORD } from "../../Common/consts/CURRENCY_RECORD";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { DefaultCurrencySelect } from "./DefaultCurrencySelect";

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues & Record<TName, Money | null>>;
  name: TName;
  label: ReactNode;
  required?: boolean;
  disabled?: boolean;
  min?: number;
}

export function ControlledMoneyField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const currency = useLocalStorage((state) => state.data.defaultCurrency);

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field }) => {
        const currentValue: Money | null = field.value;

        return (
          <Stack direction="row" spacing={3} flex={1}>
            <DefaultCurrencySelect
              onChange={(nextCurrency) => {
                const amount = currentValue?.amount;

                if (typeof amount === "number") {
                  field.onChange({
                    currency: nextCurrency,
                    amount: amount,
                  });
                } else {
                  field.onChange(null);
                }
              }}
              sx={{ width: "100px" }}
            />
            <TextField
              required={props.required}
              label={props.label}
              disabled={props.disabled}
              type="number"
              value={currentValue?.amount ?? ""}
              onChange={(e) => {
                const amount =
                  e.target.value === "" ? "" : Number(e.target.value);

                if (amount === "") {
                  return field.onChange(null);
                }

                if (props.min !== undefined && amount < props.min) {
                  return field.onChange({
                    currency,
                    amount: props.min,
                  });
                }

                field.onChange({
                  currency,
                  amount,
                });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {CURRENCY_RECORD[currency].label}
                  </InputAdornment>
                ),
              }}
              sx={{ flex: 1 }}
            />
          </Stack>
        );
      }}
    />
  );
}
