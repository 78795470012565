import { TenderStatus } from "@snubes/snubes-types";
import { FC } from "react";
import { StatusChip } from "../../Common/views/StatusChip";
import { useT } from "../../Translation/hooks/useT";
import { TENDER_STATUS_RECORD } from "../consts/TENDER_STATUS_RECORD";

interface Props {
  status: TenderStatus;
}

export const TenderStatusChip: FC<Props> = (props: Props) => {
  const { status } = props;
  const t = useT();
  const { color, Icon, label } = TENDER_STATUS_RECORD[status];

  return <StatusChip color={color} label={t(label)} Icon={Icon} />;
};
