import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { User } from "@snubes/snubes-types";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../Common/helpers/handleError";
import { auth } from "../../Firebase/consts/FIREBASE";
import { useT } from "../../Translation/hooks/useT";
import { T } from "../../Translation/views/T";
import { deleteUserCallable } from "../callables/deleteUserCallable";
import { useMyUser } from "../hooks/useMyUser";
interface Props {
  user?: User;
  isOpen: boolean;
  close: () => void;
}

export const UserDeleteDialog: FC<Props> = (props) => {
  const myUser = useMyUser((state) => state.user);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const t = useT();

  const deleteUser = async (user?: User) => {
    if (!user) return;

    try {
      setIsSubmitting(true);

      await deleteUserCallable({
        userId: user.id,
      });

      if (user.id === myUser?.id) {
        await auth.signOut();
        navigate(`/`);
      } else {
        navigate(`/admin/users`);
      }

      toast.success(t("UserDeleteDialog_Toast_SuccessMessage"));
    } catch (error) {
      handleError(error).logAnd().toast();
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog open={props.isOpen} onClose={props.close} fullWidth>
      <DialogTitle>{t("UserDeleteDialog_Title_DeleteUser")}</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <T
            k="UserDeleteDialog_Text_ConfirmationQuestion"
            options={{
              userName: [props.user?.firstName, props.user?.lastName].join(" "),
            }}
          />
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          color="error"
          variant="contained"
          disabled={isSubmitting}
          onClick={async () => {
            await deleteUser(props.user);
          }}
        >
          {t("UserDeleteDialog_Button_Delete")}
        </Button>
        <Button onClick={props.close}>
          {t("UserDeleteDialog_Button_Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
