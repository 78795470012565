import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import NumbersIcon from "@mui/icons-material/Numbers";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Call } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

type CallDirection = Required<Call>["direction"];
type CallDirectionRecordKey = CallDirection | "ANY";

export const CALL_DIRECTIONS: CallDirection[] = ["INBOUND", "OUTBOUND"];

interface CallDirectionRecordValue {
  label: TranslationKey;
  Icon: OverridableComponent<SvgIconTypeMap>;
  DestinationPhoneNumberIcon: OverridableComponent<SvgIconTypeMap>;
  SourcePhoneNumberIcon: OverridableComponent<SvgIconTypeMap>;
  color: string;
}

export const CALL_DIRECTION_RECORD: Readonly<
  Record<CallDirectionRecordKey, CallDirectionRecordValue>
> = {
  ANY: {
    label: "CallDirectionRecord_Label_Any",
    Icon: ImportExportIcon,
    DestinationPhoneNumberIcon: NumbersIcon,
    SourcePhoneNumberIcon: NumbersIcon,
    color: "text.main",
  },
  INBOUND: {
    label: "CallDirectionRecord_Label_Inbound",
    Icon: CallReceivedIcon,
    DestinationPhoneNumberIcon: SupportAgentIcon, // agent
    SourcePhoneNumberIcon: FaceOutlinedIcon, // customer
    color: "secondary.main",
  },
  OUTBOUND: {
    label: "CallDirectionRecord_Label_Outbound",
    Icon: CallMadeIcon,
    DestinationPhoneNumberIcon: FaceOutlinedIcon, // customer
    SourcePhoneNumberIcon: SupportAgentIcon, // agent
    color: "primary.main",
  },
};
