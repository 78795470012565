import { Slider, SliderProps, SxProps, Theme } from "@mui/material";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface Props<TFields extends FieldValues> {
  control: Control<TFields>;
  name: FieldPath<TFields>;
  label?: TranslationKey;
  min?: SliderProps["min"];
  max?: SliderProps["max"];
  step?: SliderProps["step"];
  marks?: SliderProps["marks"];
  orientation?: SliderProps["orientation"];
  valueLabelDisplay?: SliderProps["valueLabelDisplay"];
  valueLabelFormat?: SliderProps["valueLabelFormat"];
  disabled?: boolean;
  sx?: Exclude<SxProps<Theme>, ReadonlyArray<unknown>>;
}

export function ControlledSlider<TFields extends FieldValues>(
  props: Props<TFields>,
) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => (
        <Slider
          min={props.min}
          step={props.step}
          max={props.max}
          marks={props.marks}
          disabled={props.disabled}
          valueLabelDisplay={props.valueLabelDisplay}
          valueLabelFormat={props.valueLabelFormat}
          orientation={props.orientation}
          sx={[
            (theme) => ({
              "& .MuiSlider-valueLabel": {
                background: theme.palette.primary.main,
                fontSize: theme.typography.caption.fontSize,
                fontWeight: 700,
                padding: "4px 8px",
                borderRadius: 4,
              },
              "& .Mui-disabled .MuiSlider-valueLabel": {
                background: theme.palette.grey[400],
              },
              "& .MuiSlider-markLabel": {
                color: theme.palette.text[500],
                fontWeight: 800,
              },
            }),
            props.sx || {},
          ]}
          {...field}
        />
      )}
    />
  );
}
