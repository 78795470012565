import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { CallParticipantRole } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface CallCallParticipantRoleRecordValue {
  label: TranslationKey;
  sentiment: TranslationKey;
  Icon: OverridableComponent<SvgIconTypeMap>;
  color: string;
}

export const CALL_PARTICIPANT_ROLE_RECORD: Readonly<
  Record<CallParticipantRole, CallCallParticipantRoleRecordValue>
> = {
  AGENT: {
    label: "CallParticipantRoleRecord_Label_Agent",
    sentiment: "CallParticipantRoleRecord_Sentiment_Agent",
    Icon: SupportAgentIcon,
    color: "primary.main",
  },
  CUSTOMER: {
    label: "CallParticipantRoleRecord_Label_Customer",
    sentiment: "CallParticipantRoleRecord_Sentiment_Customer",
    Icon: FaceOutlinedIcon,
    color: "text.secondary",
  },
  SPEAKER_0: {
    label: "CallParticipantRoleRecord_Label_Speaker0",
    sentiment: "CallParticipantRoleRecord_Sentiment_Speaker0",
    Icon: PersonIcon,
    color: "text.secondary",
  },
  SPEAKER_1: {
    label: "CallParticipantRoleRecord_Label_Speaker1",
    sentiment: "CallParticipantRoleRecord_Sentiment_Speaker1",
    Icon: PersonOutlineIcon,
    color: "text.secondary",
  },
  UNKNOWN: {
    label: "CallParticipantRoleRecord_Label_Unknown",
    sentiment: "CallParticipantRoleRecord_Sentiment_Unknown",
    Icon: QuestionMarkIcon,
    color: "text.secondary",
  },
};
