import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { getMonth, getYear, setMonth, setYear } from "date-fns";
import React from "react";

interface HeaderProps {
  date: Date;
  minDate: Date;
  maxDate: Date;
  setDate: (date: Date) => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
  locale?: Locale;
}

const generateYears = (
  relativeTo: Date,
  count: number,
  minDate?: Date,
  maxDate?: Date,
) => {
  const half = Math.floor(count / 2);
  const minYear = minDate?.getFullYear();
  const maxYear = maxDate?.getFullYear();
  return new Array(count).fill(0).flatMap((_y, i) => {
    const year = relativeTo.getFullYear() - half + i;
    if (minYear && year < minYear) return [];
    if (maxYear && year > maxYear) return [];
    return [year];
  }); // TODO: make part of the state
};

const Header: React.FunctionComponent<HeaderProps> = ({
  date,
  maxDate,
  minDate,
  setDate,
  nextDisabled,
  prevDisabled,
  onClickNext,
  onClickPrevious,
  locale,
}: HeaderProps) => {
  const MONTHS =
    typeof locale !== "undefined"
      ? [...new Array(12).keys()].map(
          (d) =>
            locale.localize?.month(d, {
              width: "abbreviated",
              context: "standalone",
            }) as string,
        )
      : [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ];

  const handleMonthChange = (event: SelectChangeEvent<number>) => {
    setDate(setMonth(date, parseInt(event.target.value as string, 10)));
  };

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setDate(setYear(date, parseInt(event.target.value as string, 10)));
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item sx={{ padding: "5px" }}>
        <IconButton
          sx={{
            padding: "10px",
            "&:hover": {
              background: "none",
            },
          }}
          disabled={prevDisabled}
          onClick={onClickPrevious}
        >
          <ChevronLeftIcon color={prevDisabled ? "disabled" : "action"} />
        </IconButton>
      </Grid>
      <Grid item>
        <FormControl variant="standard">
          <Select
            value={getMonth(date)}
            onChange={handleMonthChange}
            MenuProps={{ disablePortal: true }}
          >
            {MONTHS.map((month, idx) => (
              <MenuItem key={month} value={idx}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl variant="standard">
          <Select
            value={getYear(date)}
            onChange={handleYearChange}
            MenuProps={{ disablePortal: true }}
          >
            {generateYears(date, 30, minDate, maxDate).map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item sx={{ padding: "5px" }}>
        <IconButton
          sx={{
            padding: "10px",
            "&:hover": {
              background: "none",
            },
          }}
          disabled={nextDisabled}
          onClick={onClickNext}
        >
          <ChevronRightIcon color={nextDisabled ? "disabled" : "action"} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Header;
