import { useCallback } from "react";
import { useFirebaseTableRowSelectionContext } from "./useFirebaseTableRowSelectionContext";

interface Args<T> {
  rows: T[];
  getRowId: (row: T) => string;
  onRowSelectionChanged: (selectedRowIds: string[]) => void;
}

export function useFirebaseTableRowSelection<T>(args: Args<T>) {
  const { rows, getRowId, onRowSelectionChanged } = args;

  const selectedRowIds = useFirebaseTableRowSelectionContext();

  const isRowSelected = useCallback(
    (row: T) => selectedRowIds.includes(getRowId(row)),
    [getRowId, selectedRowIds],
  );

  const handleHeaderCheckboxChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      const checked = event.target.checked;
      if (checked) {
        // Add all visible row IDs to the set of already selected row ids.
        onRowSelectionChanged([
          ...new Set([...selectedRowIds, ...rows.map(getRowId)]),
        ]);
      } else {
        // Remove all visible IDs from the set of selected row IDs.
        const visibleRowIds = new Set(rows.map(getRowId));
        onRowSelectionChanged(
          selectedRowIds.filter((id) => !visibleRowIds.has(id)),
        );
      }
    },
    [onRowSelectionChanged, selectedRowIds, rows, getRowId],
  );

  const handleRowCheckboxChange = useCallback(
    (checked: boolean, changedRow: T) => {
      if (checked) {
        onRowSelectionChanged([
          ...new Set([...selectedRowIds, getRowId(changedRow)]),
        ]);
      } else {
        const changedRowId = getRowId(changedRow);
        onRowSelectionChanged(
          selectedRowIds.filter((id) => id !== changedRowId),
        );
      }
    },
    [onRowSelectionChanged, selectedRowIds, getRowId],
  );

  const areAllRowsSelected = rows.length === rows.filter(isRowSelected).length;

  return {
    selectedRowIds,
    handleHeaderCheckboxChange,
    handleRowCheckboxChange,
    isRowSelected,
    areAllRowsSelected,
  };
}
