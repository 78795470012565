import { Skeleton, Typography } from "@mui/material";
import { FC, ReactNode, useEffect } from "react";

interface Props {
  error?: Error;
  isLoading?: boolean;
  onShouldUpdateMenuPosition?: () => void;
  isMenuItem?: boolean;
  children?: ReactNode;
}

export const NotificationListItemTitleView: FC<Props> = (props) => {
  const { children, error, isLoading, onShouldUpdateMenuPosition, isMenuItem } =
    props;

  // When the content of the notification is loaded, we need to update the menu position to achieve proper positioning.
  useEffect(() => {
    if (!isLoading && children && isMenuItem && onShouldUpdateMenuPosition) {
      // We need to call this function multiple times to ensure that the menu position is updated correctly.
      onShouldUpdateMenuPosition();
      onShouldUpdateMenuPosition();
      onShouldUpdateMenuPosition();
    }
  }, [children, isLoading, isMenuItem, onShouldUpdateMenuPosition]);

  if (error) {
    return (
      <Typography variant="body1" display="block" width="100%" color="error">
        {error.message}
      </Typography>
    );
  }
  if (isLoading) {
    return <Skeleton width={isMenuItem ? 300 : undefined} />;
  }

  return (
    <Typography
      variant="body1"
      display="block"
      // maxWidth={width}
      width="100%"
      whiteSpace="pre-line"
      overflow="hidden"
      textOverflow="ellipsis"
      sx={{
        hyphens: "auto",
      }}
    >
      {children}
    </Typography>
  );
};
