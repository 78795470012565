import { Call, CallTranscriptSource } from "@snubes/snubes-types";

/**
 * Get list of unique transcript sources for a call.
 */
export function getCallTranscriptSources(call: Call): CallTranscriptSource[] {
  return Array.from(
    new Set([
      ...(call.transcripts?.flatMap((t) =>
        t.transcriptSource ? [t.transcriptSource] : [],
      ) || []),
      ...(call.transcriptSource ? [call.transcriptSource] : []),
    ]),
  ).sort((a, b) => a.localeCompare(b));
}
