import { ServiceCategory, ServiceType } from "@snubes/snubes-types";
import { TENDER_SERVICE_CATEGORIES_BY_SERVICE_TYPE } from "../consts/TENDER_SERVICE_CATEGORIES";

export function getServiceTypesByCategory(serviceTypes: ServiceType[]) {
  return serviceTypes.reduce(
    (result, service) => {
      const category = TENDER_SERVICE_CATEGORIES_BY_SERVICE_TYPE.get(service);
      if (!category) return result;

      const servicesByCategory = result[category] || [];
      return {
        ...result,
        [category]: [...servicesByCategory, service],
      };
    },
    {} as Record<ServiceCategory, ServiceType[]>,
  );
}
