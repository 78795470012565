import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { User } from "@snubes/snubes-types";
import { FC, MouseEvent, useState } from "react";
import { ENVIRONMENT } from "../../Application/consts/ENVIRONMENT";
import { ExternalLinkView } from "../../Common/views/ExternalLinkView";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  user: User;
}

export const AdminUsersCollectionTableActionsView: FC<Props> = (props) => {
  const { user } = props;
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const t = useT();

  if (!user.hubspotContactId) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={(event) => {
          event.preventDefault();
          setMenuAnchor(event.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!menuAnchor}
        onClose={(event: MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          setMenuAnchor(null);
        }}
      >
        <MenuItem
          sx={{
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          <ExternalLinkView
            variant="link"
            href={`${ENVIRONMENT.hubspot.companyBaseUrl}/${user.hubspotContactId}`}
            tooltip={t("UserPage_Tooltip_ViewInHubspot", {
              id: user.hubspotContactId,
            })}
          >
            {t("UserPage_Button_ViewInHubspot")}
          </ExternalLinkView>
        </MenuItem>
      </Menu>
    </>
  );
};
