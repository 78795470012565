import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { SxProps } from "@mui/system";
import { FC } from "react";
import { useGetCallSentimentColor } from "../hooks/useGetCallSentimentColor";

interface Props {
  sentiment: number;
  fontSize?: number;
}

export const CallSentimentIconView: FC<Props> = (props) => {
  const { sentiment, fontSize } = props;
  const getCallSentimentColor = useGetCallSentimentColor();
  const sx: SxProps = {
    color: getCallSentimentColor(sentiment),
    fontSize,
  };

  if (sentiment <= -0.5) return <SentimentVeryDissatisfiedIcon sx={sx} />;
  if (sentiment <= -0.25) return <SentimentDissatisfiedIcon sx={sx} />;
  if (sentiment >= 0.5) return <SentimentVerySatisfiedIcon sx={sx} />;
  if (sentiment >= 0.25) return <SentimentSatisfiedIcon sx={sx} />;

  return <SentimentNeutralIcon sx={sx} />;
};
