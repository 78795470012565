import { isUser, UserRole } from "@snubes/snubes-types";
import { query, where } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export function useUsers(userRoles: UserRole[]) {
  return useValidCollectionData(
    query(getCollectionRef(`users`), where("role", "in", userRoles)),
    isUser,
  );
}
