import { TenderApplicationRejectionReason } from "@snubes/snubes-types/types/TenderApplicationRejectionReason";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface TenderApplicationRejectionReasonRecordValue {
  label: TranslationKey;
}

export const TENDER_APPLICATION_REJECTION_REASON_RECORD: Record<
  TenderApplicationRejectionReason,
  TenderApplicationRejectionReasonRecordValue
> = {
  OTHER: { label: "TenderApplicationReject_Label_Other" },
  NON_COMPETITION_CLAUSE: {
    label: "TenderApplicationReject_Label_NonCompetitionClause",
  },
  // TENDER_APPLICATION_CLIENT_REJECTION_REASONS
  PRICE: { label: "TenderApplicationReject_Label_Price" },
  COMPANY_SIZE: { label: "TenderApplicationReject_Label_CompanySize" },
  INDUSTRY_KNOWLEDGE: {
    label: "TenderApplicationReject_Label_IndustryKnowledge",
  },
  TRANSPARENCY: { label: "TenderApplicationReject_Label_Transparency" },
  LOCATION: { label: "TenderApplicationReject_Label_Location" },
  QUALITY: { label: "TenderApplicationReject_Label_Quality" },
  CAPACITY: { label: "TenderApplicationReject_Label_Capacity" },
  FINANCIAL_SITUATION: {
    label: "TenderApplicationReject_Label_FinancialSituation",
  },
  QUALITY_OF_PROPOSAL: {
    label: "TenderApplicationReject_Label_QualityOfProposal",
  },
  FITNESS_OF_REQUIREMENT: {
    label: "TenderApplicationReject_Label_FitnessOfRequirement",
  },
  PRICE_PERFORMANCE_RATIO: {
    label: "TenderApplicationReject_Label_PricePerformanceRatio",
  },
  RESPONSIVENESS: { label: "TenderApplicationReject_Label_Responsiveness" },
  DEADLINE_EXPIRED: { label: "TenderApplicationReject_Label_DeadlineExpired" },
  // TENDER_APPLICATION_PROVIDER_REJECTION_REASONS
  MISSING_INDUSTRY_EXPERIENCE: {
    label: "TenderApplicationReject_Label_MissingIndustryExperience",
  },
  PROJECT_COMPLEXITY: {
    label: "TenderApplicationReject_Label_ProjectComplexity",
  },
  PROJECT_PRICING_MODEL: {
    label: "TenderApplicationReject_Label_ProjectPricingModel",
  },
  PROJECT_SERIOUSNESS: {
    label: "TenderApplicationReject_Label_ProjectSeriousness",
  },
  PROJECT_SIZE: { label: "TenderApplicationReject_Label_ProjectSize" },
  PROJECT_TIMELINE: { label: "TenderApplicationReject_Label_ProjectTimeline" },
  // TENDER_APPLICATION_SYSTEM_REJECTION_REASONS
  TENDER_ENDED: { label: "TenderApplicationReject_Label_Tender_Ended" },
};
