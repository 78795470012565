import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { IconButton, Slider } from "@mui/material";
import { FC, MutableRefObject, useCallback, useRef, useState } from "react";
import type WaveSurfer from "wavesurfer.js";
import {
  DEFAULT_LOCAL_STORAGE,
  useLocalStorage,
} from "../../Common/hooks/useLocalStorage";

interface Props {
  playerRef: MutableRefObject<WaveSurfer | null>;
}

export const CallAudioPlayerVolumeView: FC<Props> = (props) => {
  const { playerRef } = props;

  const {
    data: { defaultVolume },
  } = useLocalStorage();
  const [volume, setVolume] = useState<number>(defaultVolume);
  const volumeRef = useRef<number>(defaultVolume);

  const VolumeIcon = volume === 0 ? VolumeOffIcon : VolumeUpIcon;
  const previousMuteVolume = useRef(
    volume || DEFAULT_LOCAL_STORAGE.defaultVolume,
  );

  // Store default volume in local storage when change is committed.
  const onSaveDefaultVolume = () => {
    useLocalStorage.getState().setData({
      defaultVolume: volumeRef.current || DEFAULT_LOCAL_STORAGE.defaultVolume,
    });
  };

  const onClickMute = useCallback(() => {
    if (volume > 0) {
      // Mute: set volume to 0
      previousMuteVolume.current = volume;
      playerRef.current?.setVolume(0);
      setVolume(0);
    } else {
      // Unmute: set volume to previous volume
      playerRef.current?.setVolume(previousMuteVolume.current / 100);
      setVolume(previousMuteVolume.current);
    }
  }, [playerRef, volume]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onVolumeChange = useCallback(
    (_: Event, nextVolume: number | number[]) => {
      if (typeof nextVolume !== "number") return;
      setVolume(nextVolume);
      volumeRef.current = nextVolume;
      playerRef.current?.setVolume(nextVolume / 100);
    },
    [playerRef],
  );

  return (
    <>
      <IconButton onClick={onClickMute}>
        <VolumeIcon sx={{ color: "text.secondary" }} />
      </IconButton>
      <Slider
        sx={{ width: SLIDER_WIDTH }}
        value={volume}
        onChange={onVolumeChange}
        onBlur={onSaveDefaultVolume}
      />
    </>
  );
};

const SLIDER_WIDTH = 160;
