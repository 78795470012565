import { Typography } from "@mui/material";
import { SERVICE_CATEGORIES, Tender } from "@snubes/snubes-types";
import { FC, Fragment, useMemo } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { toLocaleMinutes } from "../../Common/helpers/toLocaleMinutes";
import { DateTimeView } from "../../Common/views/DateTimeView";
import { DetailsAccordionContainerView } from "../../Common/views/DetailsAccordionContainerView";
import { DetailsAccordionContentView } from "../../Common/views/DetailsAccordionContentView";
import { FileListView } from "../../Common/views/FileListView";
import { useOrganization } from "../../Organizations/hooks/useOrganization";
import { useT } from "../../Translation/hooks/useT";
import { getUserName } from "../../Users/helpers/getUserName";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { TENDER_CHANNEL_TYPE } from "../consts/TENDER_CHANNEL_TYPE";
import { TENDER_SERVICE_CATEGORIES_BY_SERVICE_TYPE } from "../consts/TENDER_SERVICE_CATEGORIES";
import { TENDER_SERVICE_CATEGORY_RECORD } from "../consts/TENDER_SERVICE_CATEGORY_RECORD";
import { getTimeZoneByName } from "../helpers/getTimeZoneByName";
import { useGetTenderBudgetValue } from "../hooks/useGetTenderBudgetValue";
import { useGetTenderOperatingHoursValue } from "../hooks/useGetTenderOperatingHoursValue";
import { TenderAgentTrainingView } from "./TenderAgentTrainingView";
import { TenderProvidersView } from "./TenderProvidersView";

interface Props {
  tender: Tender;
}

export const TenderDetailsView: FC<Props> = (props) => {
  const { tender } = props;
  const t = useT();
  const getTenderBudgetValue = useGetTenderBudgetValue();
  const getTenderOperatingHoursValue = useGetTenderOperatingHoursValue();
  const [clientOrganization] = useOrganization(tender.organizationId);
  const hasPermission = useHasPermission();
  const userOrganizationIds = useMyUser((state) => state.user?.organizationIds);
  const userSelectedOrganizationId = useMyUser(
    (state) => state.user?.selectedOrganizationId,
  );

  const categories = useMemo(() => {
    return SERVICE_CATEGORIES.map((category) => {
      return {
        category,
        services: tender.services?.filter(
          (s) =>
            category === TENDER_SERVICE_CATEGORIES_BY_SERVICE_TYPE.get(s.type),
        ),
      };
    }).filter((c) => c.services?.length);
  }, [tender]);

  const supportDocuments =
    tender.supportDocuments && Object.values(tender.supportDocuments);

  const showMatchedProviders =
    Object.keys(tender.providerApplications || {}).length > 0 &&
    hasPermission("CAN_UPDATE_TENDER_PROVIDERS");

  const isProviderApplicationAcceptedOrPublished = useMemo(() => {
    const providerApplication =
      userSelectedOrganizationId &&
      tender.providerApplications?.[userSelectedOrganizationId];

    return Boolean(
      providerApplication &&
        ["ACCEPTED", "PUBLISHED"].includes(providerApplication.status),
    );
  }, [tender.providerApplications, userSelectedOrganizationId]);

  const showContactInformation =
    hasPermission("CAN_SEE_ADMIN_PANEL") ||
    userOrganizationIds?.includes(tender.organizationId) ||
    isProviderApplicationAcceptedOrPublished;

  return (
    <>
      <DetailsAccordionContainerView
        title={t("TenderDetailsView_Title_BasicDetails")}
      >
        <DetailsAccordionContentView title="Name">
          <Typography variant="body2">{tender.name}</Typography>
        </DetailsAccordionContentView>
        <DetailsAccordionContentView
          title={t("TenderDetailsView_Title_Languages")}
        >
          <Typography variant="body2">
            {tender.languages?.join(", ") || "-"}
          </Typography>
        </DetailsAccordionContentView>

        <DetailsAccordionContentView
          title={t("TenderDetailsView_Title_OperatingHours")}
        >
          {getTenderOperatingHoursValue(tender)}
        </DetailsAccordionContentView>
        {tender.operatingTimeZone && (
          <DetailsAccordionContentView
            title={t("TenderDetailsView_Title_TimeZone")}
          >
            {getTimeZoneByName(tender.operatingTimeZone) ||
              tender.operatingTimeZone}
          </DetailsAccordionContentView>
        )}
        {clientOrganization && (
          <>
            <DetailsAccordionContentView
              title={t("TenderDetailsView_Title_Industry")}
            >
              <Typography variant="body2">
                {clientOrganization.industry}
              </Typography>
            </DetailsAccordionContentView>
            <DetailsAccordionContentView
              title={t("TenderDetailsView_Title_NumberOfEmployees")}
            >
              <Typography variant="body2">
                {clientOrganization.numberOfEmployees}
              </Typography>
            </DetailsAccordionContentView>
          </>
        )}
      </DetailsAccordionContainerView>

      <DetailsAccordionContainerView
        title={t("TenderDetailsView_Title_Services")}
      >
        {tender.services && (
          <DetailsAccordionContentView
            title={t("TenderDetailsView_Title_SelectedServices")}
          >
            <Typography variant="body2">
              {tender.services
                .map((service) => t(`ServiceType_${service.type}`))
                .join(", ")}
            </Typography>
          </DetailsAccordionContentView>
        )}
        {categories.map(({ category, services }) => (
          <Fragment key={category}>
            <Typography mb={4} mt={3} variant="h4">
              {t("TenderDetailsView_Heading_SelectedServices", {
                category: t(TENDER_SERVICE_CATEGORY_RECORD[category].label),
              })}
            </Typography>
            {services?.map((service) => (
              <DetailsAccordionContainerView
                key={service.type}
                title={t(`ServiceType_${service.type}`)}
              >
                <Typography mb={4} mt={3} variant="h4">
                  {t(`TenderDetailsView_Heading_SelectedChannel`)}{" "}
                  {t(`ServiceType_${service.type}`)}
                </Typography>
                {service.channels?.map((channel) => (
                  <DetailsAccordionContainerView
                    key={channel.type}
                    title={t(TENDER_CHANNEL_TYPE[channel.type].label)}
                  >
                    <DetailsAccordionContentView
                      title={t("TenderDetailsView_Title_Languages")}
                    >
                      <Typography variant="body2">
                        {channel.languages?.join(", ") || "-"}
                      </Typography>
                    </DetailsAccordionContentView>
                    {channel.teamModel && (
                      <DetailsAccordionContentView
                        title={t("TenderDetailsView_Title_TeamModel")}
                      >
                        <Typography variant="body2">
                          {t(`TenderDetailsView_Value_${channel.teamModel}`)}
                        </Typography>
                      </DetailsAccordionContentView>
                    )}
                    {channel.volume && (
                      <DetailsAccordionContentView
                        title={t("TenderDetailsView_Title_Volume")}
                      >
                        <Typography variant="body2">
                          {t(`TenderDetailsView_Value_Volume${channel.type}`, {
                            volume: channel.volume,
                          })}
                        </Typography>
                      </DetailsAccordionContentView>
                    )}
                    {channel.priceModels && (
                      <DetailsAccordionContentView
                        title={t("TenderDetailsView_Title_PriceModel")}
                      >
                        <Typography variant="body2">
                          {channel.priceModels
                            .map((p) =>
                              t(`TenderDetailsView_Value_PriceModel${p}`),
                            )
                            .join(", ")}
                        </Typography>
                      </DetailsAccordionContentView>
                    )}
                    {channel.avgHandlingTimeInSeconds && (
                      <DetailsAccordionContentView
                        title={t("TenderDetailsView_Title_AvgHandlingTime")}
                      >
                        <Typography variant="body2">
                          {t("TenderDetailsView_Value_AvgHandlingTimeInMin", {
                            min: toLocaleMinutes(
                              channel.avgHandlingTimeInSeconds * 1000,
                            ),
                          })}
                        </Typography>
                      </DetailsAccordionContentView>
                    )}
                    {!!channel?.targetGroups?.length && (
                      <DetailsAccordionContentView
                        title={t("TenderDetailsView_Title_TargetGroups")}
                      >
                        <Typography variant="body2">
                          {channel.targetGroups.map((tg) =>
                            t(`TenderDetailsView_Value_TargetGroups${tg}`),
                          )}
                        </Typography>
                      </DetailsAccordionContentView>
                    )}
                    <DetailsAccordionContentView
                      title={t("TenderDetailsView_Title_OperatingHours")}
                    >
                      {getTenderOperatingHoursValue(tender, channel)}
                    </DetailsAccordionContentView>
                    {channel.operatingTimeZone && (
                      <DetailsAccordionContentView
                        title={t("TenderDetailsView_Title_TimeZone")}
                      >
                        {getTimeZoneByName(channel.operatingTimeZone) ||
                          channel.operatingTimeZone}
                      </DetailsAccordionContentView>
                    )}
                  </DetailsAccordionContainerView>
                ))}
              </DetailsAccordionContainerView>
            ))}
          </Fragment>
        ))}
      </DetailsAccordionContainerView>

      <DetailsAccordionContainerView
        title={t("TenderDetailsView_Title_OperationalDetails")}
      >
        <DetailsAccordionContainerView
          title={t("TenderDetailsView_Title_SelectionCriteria")}
        >
          <DetailsAccordionContentView
            title={t("TenderDetailsView_Title_Budget")}
          >
            {getTenderBudgetValue(tender)}
          </DetailsAccordionContentView>

          <DetailsAccordionContentView
            title={t("TenderDetailsView_Title_CallCenterLocations")}
          >
            {!tender.countries?.length &&
              t("TenderDetailsView_Label_ProvidersFromWorld")}
            {tender.countries &&
              tender.countries.map((country) => country).join(", ")}
          </DetailsAccordionContentView>
        </DetailsAccordionContainerView>

        <DetailsAccordionContainerView
          title={t("TenderDetailsView_Title_SkillAndTechnologies")}
        >
          <DetailsAccordionContentView
            title={t("TenderDetailsView_Title_Industries")}
          >
            {!tender.industries?.length &&
              t("TenderDetailsView_Label_NoExperienceRequired")}
            {tender.industries && tender.industries.join(", ")}
          </DetailsAccordionContentView>

          <DetailsAccordionContentView
            title={t("TenderDetailsView_Title_AgentTrainingModel")}
          >
            <TenderAgentTrainingView
              agentTrainingBudget={tender.agentTrainingBudget}
              agentTrainingModels={tender.agentTrainingModels}
              trainingHours={tender.trainingHours}
            />
          </DetailsAccordionContentView>
          <DetailsAccordionContentView
            title={t("TenderDetailsView_Title_Systems")}
          >
            {tender.system === "PROVIDER_SYSTEM" &&
              t("TenderDetailsView_Label_WorkOnTheirSystems")}
            {tender.systemDescription || "-"}
          </DetailsAccordionContentView>
        </DetailsAccordionContainerView>

        <DetailsAccordionContainerView
          title={t("TenderDetailsView_Title_Timetable")}
        >
          {tender.timetable?.confirmationDeadlineAt && (
            <DetailsAccordionContentView
              title={t("TenderDetailsView_Title_ConfirmationDeadline")}
            >
              <DateTimeView
                date={tender.timetable?.confirmationDeadlineAt}
                variant="date"
              />
            </DetailsAccordionContentView>
          )}
          {tender.timetable?.applicationDeadlineAt && (
            <DetailsAccordionContentView
              title={t("TenderDetailsView_Title_LastDateReceiveApplication")}
            >
              <DateTimeView
                date={tender.timetable?.applicationDeadlineAt}
                variant="date"
              />
            </DetailsAccordionContentView>
          )}
          {tender.timetable?.estimatedDecisionAt && (
            <DetailsAccordionContentView
              title={t("TenderDetailsView_Title_EstimatedDecisionDate")}
            >
              <DateTimeView
                date={tender.timetable?.estimatedDecisionAt}
                variant="date"
              />
            </DetailsAccordionContentView>
          )}
          {tender.timetable?.estimatedProjectStartAt && (
            <DetailsAccordionContentView
              title={t("TenderDetailsView_Title_EstimatedProjectStartDate")}
            >
              <DateTimeView
                date={tender.timetable?.estimatedProjectStartAt}
                variant="date"
              />
            </DetailsAccordionContentView>
          )}
          {tender.timetable?.estimatedProjectEndAt && (
            <DetailsAccordionContentView
              title={t("TenderDetailsView_Title_EstimatedProjectEndDate")}
            >
              <DateTimeView
                date={tender.timetable?.estimatedProjectEndAt}
                variant="date"
              />
            </DetailsAccordionContentView>
          )}
          {tender.timetable?.timetableType === "CONTINUOUS" && (
            <DetailsAccordionContentView
              title={t("TenderDetailsView_Title_EstimatedProjectEndDate")}
            >
              <Typography variant="body2">
                {t("TenderDetailsView_Value_ProjectExpectedRunContinuously")}
              </Typography>
            </DetailsAccordionContentView>
          )}
        </DetailsAccordionContainerView>

        {showContactInformation && (
          <DetailsAccordionContainerView
            title={t("TenderDetailsView_Title_ContactForQuestions")}
          >
            <DetailsAccordionContentView
              title={t("TenderDetailsView_Title_Name")}
            >
              <Typography variant="body2">
                {tender.contact ? getUserName(tender.contact) : "-"}
              </Typography>
            </DetailsAccordionContentView>
            <DetailsAccordionContentView
              title={t("TenderDetailsView_Title_EmailAddress")}
            >
              <Typography variant="body2">
                {tender.contact?.email || "-"}
              </Typography>
            </DetailsAccordionContentView>
            <DetailsAccordionContentView
              title={t("TenderDetailsView_Title_PhoneNumber")}
            >
              <Typography variant="body2">
                {tender.contact?.phone || "-"}
              </Typography>
            </DetailsAccordionContentView>
          </DetailsAccordionContainerView>
        )}

        <DetailsAccordionContentView
          title={t("TenderDetailsView_Title_Description")}
        >
          <Typography variant="body2">{tender.description || "-"}</Typography>
        </DetailsAccordionContentView>
      </DetailsAccordionContainerView>

      {supportDocuments && (
        <DetailsAccordionContainerView
          title={t("TenderDetailsView_Title_Documents")}
        >
          <DetailsAccordionContentView
            title={t("TenderDetailsView_Title_SupportingDocuments")}
          >
            <FileListView files={supportDocuments} />
          </DetailsAccordionContentView>
        </DetailsAccordionContainerView>
      )}

      {showMatchedProviders && (
        <DetailsAccordionContainerView
          title={t("TenderDetailsView_Title_MatchedProviders")}
        >
          <TenderProvidersView tender={tender} />
        </DetailsAccordionContainerView>
      )}
    </>
  );
};
