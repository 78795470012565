import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TuneIcon from "@mui/icons-material/Tune";
import { Container, Stack, Typography } from "@mui/material";
import { ChannelType, ServiceType } from "@snubes/snubes-types";
import { FC, useEffect } from "react";
import { array, boolean, nullable, string, type } from "superstruct";
import { isEqualArray } from "../../Common/helpers/isEqualArray";
import { ControlledLanguagesAutocomplete } from "../../Form/views/ControlledLanguagesAutocomplete";
import { ControlledRadioButtons } from "../../Form/views/ControlledRadioButtons";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { findTenderServiceChannel } from "../helpers/findTenderServiceChannel";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  isUsingDefaultLanguages: nullable(boolean()),
  languages: array(string()),
});

type Props = WizardFormProps<{
  serviceType: ServiceType;
  channelType: ChannelType;
}>;

export const TenderWizardChannelLanguagesForm: FC<Props> = (props) => {
  const { serviceType, channelType } = props.formProps;
  const t = useT();
  const { control, handleSubmit, onSubmit, watch, setValue } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => {
      const languages = findTenderServiceChannel(
        serviceType,
        channelType,
        tender,
      )?.languages;
      const defaultLanguages = tender?.languages ?? [];
      const isUsingDefaultLanguages = languages
        ? isEqualArray(languages, defaultLanguages)
        : null;

      return {
        isUsingDefaultLanguages,
        languages: languages || defaultLanguages,
      };
    },
    toDoc: (formValues, tender) => {
      const defaultLanguages = tender?.languages ?? [];
      const channel = findTenderServiceChannel(
        serviceType,
        channelType,
        tender,
      );

      if (channel) {
        channel.languages = formValues.isUsingDefaultLanguages
          ? defaultLanguages
          : formValues.languages;
      }

      return {
        services: tender?.services,
        isReviewed: {
          ...tender?.isReviewed,
          services: false,
        },
      };
    },
  });

  const isUsingDefaultLanguages = watch("isUsingDefaultLanguages");
  const isLanguagesInputEnabled = isUsingDefaultLanguages === false; // null/true -> disabled

  useEffect(() => {
    if (!isUsingDefaultLanguages) return;
    const defaultLanguages =
      useTenderWizardStore.getState().doc?.languages || [];
    setValue("languages", defaultLanguages);
  }, [isUsingDefaultLanguages, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardChannelLanguagesForm_Title_Languages")}
        </Typography>
        <Stack spacing={4}>
          <ControlledRadioButtons
            control={control}
            name="isUsingDefaultLanguages"
            options={[
              {
                Icon: ContentCopyIcon,
                label: "TenderWizardChannelLanguagesForm_Label_Default",
                value: true,
                caption: "TenderWizardChannelLanguagesForm_Caption_Default",
              },
              {
                Icon: TuneIcon,
                label: "TenderWizardChannelLanguagesForm_Label_Custom",
                value: false,
                caption: "TenderWizardChannelLanguagesForm_Caption_Custom",
              },
            ]}
            gridSpacing={4}
            gridItemXs={6}
          />
          {isLanguagesInputEnabled && (
            <ControlledLanguagesAutocomplete
              control={control}
              name="languages"
              label={t("TenderWizardLanguagesForm_Label_Languages")}
              multiple
              required
              fullWidth
            />
          )}
        </Stack>
      </Container>
    </form>
  );
};
