import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC, useMemo } from "react";
import { useT } from "../../Translation/hooks/useT";
import { TendersController } from "../hooks/useTendersController";
import { TENDER_ORDER_BY_OPTIONS, TenderOrderBy } from "../types/TenderOrderBy";

interface Props {
  controller: TendersController;
  options?: TenderOrderBy[];
}

/**
 * @deprecated Use dedicated filter and sort component instead.
 */
export const TendersControllerView: FC<Props> = (props) => {
  const t = useT();
  const { controller } = props;

  const orderByOptions = useMemo(() => {
    const options = props.options || TENDER_ORDER_BY_OPTIONS;
    return options
      .map((value) => ({
        value,
        label: t(`Tender_OrderBy_${value}`),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [props.options, t]);

  return (
    <FormControl sx={{ bgcolor: "background.paper", minWidth: 200 }}>
      <InputLabel>{t("Common_OrderBy")}</InputLabel>
      <Select
        label={t("Common_OrderBy")}
        value={controller.orderBy}
        onChange={controller.onOrderByChanged}
      >
        {orderByOptions.map((orderByOption) => (
          <MenuItem key={orderByOption.value} value={orderByOption.value}>
            {orderByOption.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
