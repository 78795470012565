import { Call, Project } from "@snubes/snubes-types";

interface Args {
  call: Call;
  project: Project;
}

export function getCallCategoryName(args: Args) {
  const { call, project } = args;

  const category = project.callCategories?.find((category) => {
    return category.id === call.categoryId;
  });

  return category?.name;
}
