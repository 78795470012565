import { superstructResolver } from "@hookform/resolvers/superstruct";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CreateConnectionRequest, ExternalProject } from "@snubes/snubes-types";
import { FC, useCallback, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  array,
  Infer,
  nullable,
  number,
  object,
  string,
  type,
} from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { isProjectMappable } from "../../Common/helpers/isProjectMappable";
import { ControlledAutocomplete } from "../../Form/views/ControlledAutocomplete";
import { ControlledProjectIdAutocomplete } from "../../Form/views/ControlledProjectIdAutocomplete";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { createConnectorConfigCallable } from "../callables/createConnectorConfigCallable";
import { testConnectorConfigInoplaCallable } from "../callables/testConnectorConfigInoplaCallable";
import { DEFAULT_BACKFILL_DAYS } from "../consts/DEFAULT_BACKFILL_DAYS";
import { getBackfillDate } from "../helpers/getBackfillDate";
import { getDaysToBackfill } from "../helpers/getDaysToBackfill";
import { haveProjectMappingsDuplicates } from "../helpers/haveProjectMappingsDuplicates";
import { ConnectorConfigBackfillDaysFormFieldsView } from "./ConnectorConfigBackfillDaysFormFieldsView";
import { ConnectorConfigInoplaHelpView } from "./ConnectorConfigInoplaHelpView";

const FormValuesStruct = type({
  authId: string(),
  authKey: string(),
  name: string(),
  projectMappings: array(
    object({
      projectId: nullable(string()),
      externalProjectId: nullable(string()),
      projectName: string(),
    }),
  ),
  backfillDate: string(),
  backfillDays: number(),
});

type FormValues = Infer<typeof FormValuesStruct>;

export const ConnectorConfigInoplaForm: FC = () => {
  const t = useT();
  const navigate = useNavigate();
  const { organizationId } = useParams<"organizationId">();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [externalProjects, setExternalProjects] = useState<ExternalProject[]>();

  const form = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      authId: "",
      authKey: "",
      name: "",
      projectMappings: [
        { projectId: null, externalProjectId: null, projectName: "" },
      ],
      backfillDate: getBackfillDate(DEFAULT_BACKFILL_DAYS),
      backfillDays: DEFAULT_BACKFILL_DAYS,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "projectMappings",
  });

  const handleProjectMappingRemove = (index: number) => {
    remove(index);
  };
  const handleProjectMappingAppend = () => {
    append({ projectId: null, externalProjectId: null, projectName: "" });
  };

  const testConnectorConfig = useCallback(
    async (formValues: FormValues, organizationId: string) => {
      const data = await testConnectorConfigInoplaCallable({
        authId: formValues.authId,
        authKey: formValues.authKey,
        organizationId,
        platform: "INOPLA",
      });

      setErrorMessage(undefined);
      setExternalProjects(data.externalProjects);
    },
    [],
  );

  const createConnectorConfig = useCallback(
    async (formValues: FormValues, organizationId: string) => {
      const projectMappings = formValues.projectMappings.reduce<
        CreateConnectionRequest["projectMappings"]
      >((result, mapping) => {
        const { projectId, externalProjectId, projectName } = mapping;
        if (projectId && externalProjectId) {
          result.push({
            projectId,
            externalProjectId,
            projectName,
          });
        }
        return result;
      }, []);

      await createConnectorConfigCallable({
        name: formValues.name,
        organizationId,
        platform: "INOPLA",
        authId: formValues.authId,
        authKey: formValues.authKey,
        projectMappings,
        backfillDays: getDaysToBackfill(formValues.backfillDate),
      });
    },
    [],
  );
  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (haveProjectMappingsDuplicates(formValues.projectMappings)) {
          throw new Error(t("ConnectorConfigSftpForm_Toast_MultipleProjects"));
        }
        if (!organizationId) {
          throw new Error("No organization selected");
        }

        if (externalProjects) {
          await createConnectorConfig(formValues, organizationId);
          navigate(`/organizations/${organizationId}/connector-configs`);
        } else {
          await testConnectorConfig(formValues, organizationId);
        }
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [
      createConnectorConfig,
      externalProjects,
      navigate,
      organizationId,
      t,
      testConnectorConfig,
    ],
  );

  const { isSubmitting } = form.formState;
  const isConnecting = isSubmitting && !externalProjects;

  return (
    <Stack pt={3}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <ControlledTextField
            autoComplete="off"
            required
            fullWidth
            name="name"
            label={t("ConnectorConfigInoplaForm_Inputlabel_Name")}
            control={form.control}
            disabled={isSubmitting}
          />
          <Divider />

          <Typography textTransform="uppercase" variant="subtitle1">
            {t("ConnectorConfigInoplaForm_SubHeading_Credentials")}
          </Typography>

          <ConnectorConfigInoplaHelpView
            title={t("ConnectorConfigInoplaForm_Heading_PreRequisite")}
          />

          <ControlledTextField
            required
            autoComplete="off"
            fullWidth
            name="authId"
            label={t("ConnectorConfigInoplaForm_InputLabel_ApiId")}
            control={form.control}
            disabled={isSubmitting}
          />
          <ControlledTextField
            required
            autoComplete="off"
            fullWidth
            name="authKey"
            label={t("ConnectorConfigInoplaForm_InputLabel_ApiKey")}
            control={form.control}
            disabled={isSubmitting}
          />

          <FormProvider {...form}>
            <ConnectorConfigBackfillDaysFormFieldsView />
          </FormProvider>

          {isConnecting && (
            <Stack spacing={3} alignItems="center" direction="row">
              <CircularProgress size={20} />
              <Typography variant="subtitle2">
                {t("ConnectorConfigInoplaForm_SubHeading_ConnectingInopla")}
              </Typography>
            </Stack>
          )}
          {!!errorMessage && (
            <Stack color="error.main" spacing={3}>
              <Stack spacing={3} alignItems="center" direction="row">
                <InfoIcon />
                <Typography variant="subtitle2">
                  {t("ConnectorConfigInoplaForm_SubHeading_ConnectedError")}
                </Typography>
              </Stack>
              <Stack
                p={5}
                bgcolor="background.default"
                justifyContent="center"
                alignContent="center"
              >
                {errorMessage}
              </Stack>
              <Divider />
            </Stack>
          )}
          {externalProjects && (
            <>
              <Stack spacing={3}>
                <Stack
                  color="success.main"
                  spacing={3}
                  alignItems="center"
                  direction="row"
                >
                  <CheckCircleIcon />
                  <Typography variant="subtitle2">
                    {t("ConnectorConfigInoplaForm_SubHeading_Connected")}
                  </Typography>
                </Stack>
                <Divider />
                <Typography textTransform="uppercase" variant="subtitle1">
                  {t("ConnectorConfigInoplaForm_SubHeading_ChooseProject")}
                </Typography>
                <Stack
                  justifyContent="space-between"
                  alignItems="center"
                  direction="row"
                  spacing={3}
                >
                  <Stack flex={1}>
                    <Typography variant="subtitle1">
                      {t("ConnectorConfigInoplaForm_Label_InoplaPhoneNumber")}
                    </Typography>
                  </Stack>
                  <Stack flex={1}>
                    <Typography variant="subtitle1">
                      {t("ConnectorConfigInoplaForm_Label_SnubesProject")}
                    </Typography>
                  </Stack>
                </Stack>
                <Divider />
                {fields.map((item, index) => (
                  <Stack
                    key={item.id}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    direction="row"
                    spacing={3}
                  >
                    {externalProjects.length > 0 && (
                      <Stack flex={1}>
                        <ControlledAutocomplete
                          fullWidth
                          required
                          name={`projectMappings.${index}.externalProjectId`}
                          control={form.control}
                          onChange={(externalProjectId) => {
                            form.setValue(
                              `projectMappings.${index}.projectName`,
                              externalProjects.find(
                                (f) => f.id === externalProjectId,
                              )?.name || "",
                            );
                          }}
                          options={externalProjects.map((p) => p.id)}
                          getOptionLabel={(projectId) =>
                            externalProjects.find(
                              (project) => project.id === projectId,
                            )?.name || ""
                          }
                          label={t(
                            "ConnectorConfigInoplaForm_SelectLabel_ChooseInoplaPhoneNumber",
                          )}
                        />
                      </Stack>
                    )}
                    {organizationId && (
                      <Stack flex={1} spacing={3}>
                        <ControlledProjectIdAutocomplete
                          fullWidth
                          required
                          filter={(project) => isProjectMappable(project)}
                          name={`projectMappings.${index}.projectId`}
                          projectNameFieldName={`projectMappings.${index}.projectName`}
                          control={form.control}
                          label={t(
                            "ConnectorConfigInoplaForm_SelectLabel_SnubesProject",
                          )}
                          organizationId={organizationId}
                        />
                      </Stack>
                    )}

                    {(index !== 0 || fields.length > 1) && (
                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() => {
                          handleProjectMappingRemove(index);
                        }}
                      >
                        <DeleteForeverIcon sx={{ color: "error.main" }} />
                      </IconButton>
                    )}
                  </Stack>
                ))}
              </Stack>
              <Stack
                borderTop={1}
                borderBottom={1}
                py={3}
                px={2}
                bgcolor="background.default"
                borderColor="divider"
                alignItems="center"
                direction="row"
              >
                <Button
                  size="small"
                  variant="text"
                  onClick={handleProjectMappingAppend}
                  startIcon={<AddCircleIcon />}
                >
                  {t("ConnectorConfigSftpForm_Button_NewMapping")}
                </Button>
              </Stack>
            </>
          )}
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting || isConnecting}
          >
            {!errorMessage &&
              !externalProjects &&
              t("ConnectorConfigInoplaForm_Button_Connect")}
            {externalProjects &&
              t("ConnectorConfigInoplaForm_Button_FinishSetUp")}
            {!!errorMessage && t("ConnectorConfigInoplaForm_Button_TryAgain")}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};
