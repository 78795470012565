import { Container, Stack, Typography } from "@mui/material";
import {
  BUDGET_DURATION_TYPES,
  BUDGET_TYPES,
  BudgetDurationTypeStruct,
  BudgetTypeStruct,
  MoneyStruct,
} from "@snubes/snubes-types";
import { FC } from "react";
import { nullable, type } from "superstruct";
import { getValidMoney } from "../../Common/helpers/getValidMoney";
import { ControlledMoneyField } from "../../Form/views/ControlledMoneyField";
import { ControlledRadioButtons } from "../../Form/views/ControlledRadioButtons";
import { ControlledSelect } from "../../Form/views/ControlledSelect";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { TENDER_BUDGET_DURATION_TYPE } from "../consts/TENDER_BUDGET_DURATION_TYPE";
import { TENDER_BUDGET_TYPE } from "../consts/TENDER_BUDGET_TYPE";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  budget: nullable(MoneyStruct),
  budgetDurationType: BudgetDurationTypeStruct,
  budgetType: nullable(BudgetTypeStruct),
});

const BUDGET_TYPE_OPTIONS = BUDGET_TYPES.map((budgetType) => ({
  ...TENDER_BUDGET_TYPE[budgetType],
  value: budgetType,
}));

export const TenderWizardBudgetForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit, watch } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => ({
      budget: tender?.budget || null,
      budgetDurationType: tender?.budgetDurationType || "MONTHLY",
      budgetType: tender?.budgetType || null,
    }),
    toDoc: (formValues, tender) => {
      const { budget, budgetDurationType, budgetType } = formValues;
      const isFixed = budgetType === "FIXED";
      const validBudget = getValidMoney(budget);

      return {
        budgetType: budgetType || undefined,
        budgetDurationType:
          isFixed && budgetDurationType ? budgetDurationType : undefined,
        budget: isFixed && validBudget ? validBudget : undefined,
        isReviewed: {
          ...tender?.isReviewed,
          operationalDetails: false,
        },
      };
    },
  });

  const budgetTypeOptions = BUDGET_DURATION_TYPES.map((type) => {
    return {
      value: type,
      label: TENDER_BUDGET_DURATION_TYPE[type],
    };
  });

  const isFixedBudget = watch("budgetType") === "FIXED";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardBudgetForm_Title")}
        </Typography>
        <Typography variant="body2" color="text.500" mb={4}>
          {t("TenderWizardBudgetForm_Label")}
        </Typography>
        <Stack spacing={3}>
          <ControlledRadioButtons
            control={control}
            name="budgetType"
            options={BUDGET_TYPE_OPTIONS}
            gridSpacing={4}
            gridItemXs={6}
          />

          {isFixedBudget && (
            <Stack spacing={3} direction="row">
              <ControlledMoneyField
                control={control}
                name="budget"
                label={t("TenderWizardBudgetForm_Label_MoneyFieldAmount")}
                required
              />
              <ControlledSelect
                control={control}
                name="budgetDurationType"
                label={t("TenderWizardBudgetForm_Label_SelectDuration")}
                items={budgetTypeOptions}
                renderItem={(item) => t(item.label)}
                sx={{ width: 160 }}
              />
            </Stack>
          )}
        </Stack>
      </Container>
    </form>
  );
};
