import { Box, Grid, Paper, Stack } from "@mui/material";
import { Call } from "@snubes/snubes-types";
import { FC } from "react";
import { CallDirectionToggleButton } from "../../Calls/views/CallDirectionToggleButton";
import { toLocaleHours } from "../../Common/helpers/toLocaleHours";
import { toLocaleMinutes } from "../../Common/helpers/toLocaleMinutes";
import { useT } from "../../Translation/hooks/useT";
import { ReportFrameByDaySummary } from "../types/ReportFrameByDaySummary";
import { ProjectReportFramesSummaryPercentageUnitView } from "./ProjectReportFramesSummaryPercentageUnitView";
import { ProjectReportFramesSummaryUnitView } from "./ProjectReportFramesSummaryUnitView";

interface Props {
  summary: ReportFrameByDaySummary;
  callDirection: Call["direction"];
  onChangeCallDirection: (callDirection: Call["direction"]) => void;
}

export const ProjectReportFramesSummaryView: FC<Props> = (props: Props) => {
  const {
    summary: { values, valuesReference, comparedPercentages },
    callDirection,
    onChangeCallDirection,
  } = props;
  const t = useT();

  return (
    <Paper variant="outlined">
      <Box p={4}>
        <Grid container spacing={5}>
          <ProjectReportFramesSummaryUnitView
            value={`${values.totalCallsCount}`}
            label={t("ProjectReportFramesSummaryView_SubHeading_NumberOfCalls")}
          />
          <ProjectReportFramesSummaryUnitView
            value={`${values.answeredCallsCount}`}
            label={t("ProjectReportFramesSummaryView_SubHeading_CallsAnswered")}
          />
          <ProjectReportFramesSummaryUnitView
            value={values.reachabilityPercentage.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              style: "percent",
            })}
            label={t("ProjectReportFramesSummaryView_SubHeading_Reachability")}
          />
          <ProjectReportFramesSummaryUnitView
            value={t("Common_Mins_Short", {
              minutes: toLocaleMinutes(
                values.averageHandlingTimeInSeconds * 1000,
              ),
            })}
            label={t(
              "ProjectReportFramesSummaryView_SubHeading_AverageHandlingTime",
            )}
          />
          <ProjectReportFramesSummaryUnitView
            value={t("Common_Hours_Short", {
              hours: toLocaleHours(values.totalHandlingTimeInSeconds * 1000),
            })}
            label={t(
              "ProjectReportFramesSummaryView_SubHeading_TotalHandlingTime",
            )}
          />
          <ProjectReportFramesSummaryUnitView
            value={values.serviceLevelPercentage.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              style: "percent",
            })}
            label={t("ProjectReportFramesSummaryView_SubHeading_ServiceLevel")}
          />
          <Grid item xs="auto" sm="auto">
            <Stack justifyContent="center" height="100%">
              <CallDirectionToggleButton
                callDirection={callDirection}
                onChangeCallDirection={onChangeCallDirection}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      {comparedPercentages && (
        <Box
          p={4}
          bgcolor="background.default"
          borderTop={1}
          borderColor="divider"
        >
          <Grid container spacing={3}>
            <ProjectReportFramesSummaryPercentageUnitView
              percentage={comparedPercentages.totalCallsCount}
              value={
                valuesReference
                  ? `${Math.round(valuesReference.totalCallsCount)}`
                  : undefined
              }
              label={t(
                "ProjectReportFramesSummaryView_SubHeading_NumberOfCalls",
              )}
            />
            <ProjectReportFramesSummaryPercentageUnitView
              percentage={comparedPercentages.answeredCallsCount}
              value={
                valuesReference
                  ? `${Math.round(valuesReference.answeredCallsCount)}`
                  : undefined
              }
              label={t(
                "ProjectReportFramesSummaryView_SubHeading_CallsAnswered",
              )}
            />
            <ProjectReportFramesSummaryPercentageUnitView
              percentage={comparedPercentages.reachabilityPercentage}
              value={
                valuesReference
                  ? valuesReference.reachabilityPercentage.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 2,
                        style: "percent",
                      },
                    )
                  : undefined
              }
              label={t(
                "ProjectReportFramesSummaryView_SubHeading_Reachability",
              )}
            />
            <ProjectReportFramesSummaryPercentageUnitView
              percentage={comparedPercentages.averageHandlingTimeInSeconds}
              value={
                valuesReference
                  ? t("Common_Mins_Short", {
                      minutes: toLocaleMinutes(
                        valuesReference.averageHandlingTimeInSeconds * 1000,
                      ),
                    })
                  : undefined
              }
              label={t(
                "ProjectReportFramesSummaryView_SubHeading_AverageHandlingTime",
              )}
              invertedColor
            />
            <ProjectReportFramesSummaryPercentageUnitView
              percentage={comparedPercentages.totalHandlingTimeInSeconds}
              value={
                valuesReference
                  ? t("Common_Hours_Short", {
                      hours: toLocaleHours(
                        valuesReference.totalHandlingTimeInSeconds * 1000,
                      ),
                    })
                  : undefined
              }
              label={t(
                "ProjectReportFramesSummaryView_SubHeading_TotalHandlingTime",
              )}
              invertedColor
            />
            <ProjectReportFramesSummaryPercentageUnitView
              percentage={comparedPercentages.serviceLevelPercentage}
              value={
                valuesReference
                  ? valuesReference.serviceLevelPercentage.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 2,
                        style: "percent",
                      },
                    )
                  : undefined
              }
              label={t(
                "ProjectReportFramesSummaryView_SubHeading_ServiceLevel",
              )}
            />
          </Grid>
        </Box>
      )}
    </Paper>
  );
};
