import { Box, Link, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { AuthLoginView } from "../../Auth/views/AuthLoginView";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useT } from "../../Translation/hooks/useT";

export const AppLoggedOutPage: FC = () => {
  const t = useT();

  return (
    <>
      <HeaderConfig variant={"WITH_CENTERED_LOGO"} />
      <FullHeightContainer maxWidth="sm">
        <Stack alignItems="center" justifyContent="center" spacing={2}>
          <Typography variant="h3" py={3}>
            {t("AppLoggedOutPage_Title_LoggedOut")}
          </Typography>
          <Box width={"80%"}>
            <AuthLoginView />
          </Box>

          <Box py={2}>
            <Link href="/">{t("AppLoggedOutPage_Title_GoHome")}</Link>
          </Box>
        </Stack>
      </FullHeightContainer>
    </>
  );
};
