import { Alert } from "@mui/material";
import { FC } from "react";
import { Outlet, useParams } from "react-router-dom";
import { NavTab } from "../../Common/types/NavTab";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LoadingView } from "../../Common/views/LoadingView";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";
import { useT } from "../../Translation/hooks/useT";
import { useConnectorConfig } from "../hooks/useConnectorConfig";
import { ConnectorConfigContextProvider } from "../hooks/useConnectorConfigContext";

const Tabs: NavTab[] = [
  {
    label: "ConnectorConfigLayoutView_SecondaryHeaderLabel_Details",
    route: "details",
  },
  {
    label: "ConnectorConfigLayoutView_SecondaryHeaderLabel_ProjectMappings",
    route: "mappings",
  },
  {
    label: "ConnectorConfigLayoutView_SecondaryHeaderLabel_Logs",
    route: "logs",
  },
];

export const ConnectorConfigLayoutView: FC = () => {
  const { organizationId, connectorConfigId } = useParams<
    "organizationId" | "connectorConfigId"
  >();
  const [connectorConfig, isLoading, error] = useConnectorConfig(
    organizationId,
    connectorConfigId,
  );
  const isLoaded = !isLoading && !error;
  const t = useT();

  if (!organizationId || !connectorConfigId) return null;

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <SecondaryHeaderView
        tabs={Tabs}
        pathPrefix={`/organizations/${organizationId}/connector-configs/${connectorConfigId}`}
        backButton={{
          label: t("ConnectorConfigLayoutView_Button_BackToConnections"),
          to: `/organizations/${organizationId}/connector-configs`,
        }}
      />
      {!isLoaded && (
        <FullHeightContainer>
          {isLoading && <LoadingView />}
          {error && (
            <Alert sx={{ m: 3 }} severity="error">
              {error.message}
            </Alert>
          )}
        </FullHeightContainer>
      )}
      {isLoaded && !connectorConfig && (
        <FullHeightContainer>
          <Alert sx={{ m: 3 }} severity="error">
            {t("ConnectorConfigLayoutView_Error_ConnectionNotFound")}
          </Alert>
        </FullHeightContainer>
      )}
      {isLoaded && connectorConfig && (
        <ConnectorConfigContextProvider value={{ connectorConfig }}>
          <Outlet />
        </ConnectorConfigContextProvider>
      )}
    </>
  );
};
