import { Process, Timestamp } from "@snubes/snubes-types";
import { getDiffInSeconds } from "../../Common/helpers/getDiffInSeconds";

export function getCallProcessingDurationInSeconds(
  process: Process | undefined,
  previousProcessEndedAt: Timestamp,
) {
  if (!process) {
    return getDiffInSeconds(previousProcessEndedAt, true);
  }

  if (process?.status === "PROCESSING" && process.startedAt) {
    return getDiffInSeconds(process.startedAt, true);
  }

  if (process?.status === "REQUESTED" && process.requestedAt) {
    return getDiffInSeconds(process.requestedAt, true);
  }

  return 0;
}
