import { Typography } from "@mui/material";
import { Notification } from "@snubes/snubes-types";
import { FC } from "react";
import { NotificationListItemForCallReviewView } from "./NotificationListItemForCallReviewView";
import { NotificationListItemForTenderView } from "./NotificationListItemForTenderView";

interface Props {
  notification: Notification;
  isMenuItem?: boolean;
  onShouldUpdateMenuPosition?: () => void;
}

export const NotificationListItemSwitchView: FC<Props> = (props) => {
  const { notification, isMenuItem, onShouldUpdateMenuPosition } = props;

  switch (notification.type) {
    case "CALL_REVIEW":
      return (
        <NotificationListItemForCallReviewView
          notification={notification}
          isMenuItem={isMenuItem}
        />
      );
    case "TENDER_SUBMITTED":
    case "TENDER_PUBLISHED":
    case "TENDER_MATCHED":
    case "TENDER_ENDED":
    case "TENDER_ENDED_ADMIN":
    case "TENDER_APPLICATION_RECEIVED":
    case "TENDER_APPLICATION_ACCEPTED":
    case "TENDER_APPLICATION_REJECTED":
    case "TENDER_APPLICATIONS_COMPLETED_ADMIN":
      return (
        <NotificationListItemForTenderView
          notification={notification}
          isMenuItem={isMenuItem}
          onShouldUpdateMenuPosition={onShouldUpdateMenuPosition}
        />
      );
    default:
      return (
        <Typography p={3}>{(notification as { type: string }).type}</Typography>
      );
  }
};
