import { Container, Stack, Typography } from "@mui/material";
import { TIMETABLE_TYPES, TimetableTypeStruct } from "@snubes/snubes-types";
import { FC } from "react";
import { nullable, string, type } from "superstruct";
import { ControlledDatePicker } from "../../Form/views/ControlledDatePicker";
import { ControlledRadioButtons } from "../../Form/views/ControlledRadioButtons";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { TIMETABLE_TYPE_RECORD } from "../consts/TIMETABLE_TYPE_RECORD";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  confirmationDeadlineAt: nullable(string()),
  applicationDeadlineAt: nullable(string()),
  estimatedDecisionAt: nullable(string()),
  estimatedProjectStartAt: nullable(string()),
  timetableType: nullable(TimetableTypeStruct),
  estimatedProjectEndAt: nullable(string()),
});

const TIMETABLE_TYPE_OPTIONS = TIMETABLE_TYPES.map((type) => ({
  ...TIMETABLE_TYPE_RECORD[type],
  value: type,
}));

export const TenderWizardTimetableForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit, watch } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => ({
      confirmationDeadlineAt: tender?.timetable?.confirmationDeadlineAt || null,
      applicationDeadlineAt: tender?.timetable?.applicationDeadlineAt || null,
      estimatedDecisionAt: tender?.timetable?.estimatedDecisionAt || null,
      estimatedProjectStartAt:
        tender?.timetable?.estimatedProjectStartAt || null,
      timetableType: tender?.timetable?.timetableType || null,
      estimatedProjectEndAt: tender?.timetable?.estimatedProjectEndAt || null,
    }),
    toDoc: (formValues, tender) => {
      const {
        confirmationDeadlineAt,
        applicationDeadlineAt,
        estimatedDecisionAt,
        estimatedProjectStartAt,
        timetableType,
        estimatedProjectEndAt,
      } = formValues;
      const isFinite = timetableType === "FINITE";

      return {
        timetable: {
          ...(!!confirmationDeadlineAt && { confirmationDeadlineAt }),
          ...(!!applicationDeadlineAt && {
            applicationDeadlineAt,
          }),
          ...(!!estimatedDecisionAt && { estimatedDecisionAt }),
          ...(!!estimatedProjectStartAt && { estimatedProjectStartAt }),
          ...(!!timetableType && { timetableType }),
          ...(isFinite && !!estimatedProjectEndAt && { estimatedProjectEndAt }),
        },
        isReviewed: {
          ...tender?.isReviewed,
          operationalDetails: false,
        },
      };
    },
  });

  const isFiniteTimetable = watch("timetableType") === "FINITE";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="sm">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardTimetableForm_Title_Timetable")}
        </Typography>
        <Stack spacing={3}>
          <Typography variant="body2" color="text.500" mb={4}>
            {t("TenderWizardTimetableForm_Label_Specify")}
          </Typography>
          {/* TODO: implement short/long match toggle. confirmationDeadline is only relevant for long match. */}
          {/* <ControlledDatePicker
            control={control}
            name="confirmationDeadlineAt"
            label={t("TenderWizardTimetableForm_Label_ConfirmationDeadlineAt")}
            disablePast
            fullWidth
            required
          /> */}
          <ControlledDatePicker
            control={control}
            name="applicationDeadlineAt"
            label={t("TenderWizardTimetableForm_Label_ApplicationDeadlineAt")}
            disablePast
            fullWidth
            required
          />
          <ControlledDatePicker
            control={control}
            name="estimatedDecisionAt"
            label={t("TenderWizardTimetableForm_Label_EstimatedDecisionAt")}
            disablePast
            fullWidth
          />
          <ControlledDatePicker
            control={control}
            name="estimatedProjectStartAt"
            label={t("TenderWizardTimetableForm_Label_EstimatedProjectStartAt")}
            disablePast
            fullWidth
            required
          />
          <Typography variant="body2" color="text.500" mb={4}>
            {t("TenderWizardTimetableForm_Label_Continuous")}
          </Typography>
          <Stack spacing={3}>
            <ControlledRadioButtons
              control={control}
              name="timetableType"
              options={TIMETABLE_TYPE_OPTIONS}
              gridSpacing={4}
              gridItemXs={6}
              required
            />
          </Stack>
          {isFiniteTimetable && (
            <ControlledDatePicker
              control={control}
              name="estimatedProjectEndAt"
              label={t("TenderWizardTimetableForm_Label_EstimatedProjectEndAt")}
              disablePast
              fullWidth
              required
              autoFocus
            />
          )}
        </Stack>
      </Container>
    </form>
  );
};
