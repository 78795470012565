import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthForgotPasswordPage } from "../../Auth/views/AuthForgotPasswordPage";
import { AuthLoginPage } from "../../Auth/views/AuthLoginPage";
import { AuthSignupPage } from "../../Auth/views/AuthSignupPage";
import { AuthUserCreatePage } from "../../Auth/views/AuthUserCreatePage";
import { AppLoggedOutPage } from "./AppLoggedOutPage";

export const AppLoggedOutRouter: FC = () => {
  return (
    <Routes>
      <Route index element={<Navigate replace to="signup" />} />
      <Route path="signup">
        <Route index element={<AuthSignupPage />} />
        <Route path="user" element={<AuthUserCreatePage />} />
      </Route>
      <Route path="login">
        <Route index element={<AuthLoginPage />} />
        <Route path="forgot-password" element={<AuthForgotPasswordPage />} />
      </Route>
      <Route path="*" element={<AppLoggedOutPage />} />
    </Routes>
  );
};
