import { Navigate, useLocation } from "react-router-dom";
import { WizardStep } from "../types/WizardStep";
import { WizardStore } from "../types/WizardStore";
import { WizardTabsView } from "./WizardTabsView";

interface Props<TState> {
  steps: WizardStep<TState>[];
  store: WizardStore<TState>;
  previousPath?: string;
}

export function WizardContentView<TState>(props: Props<TState>) {
  const { steps, store } = props;
  const previousPath = props.previousPath ? `${props.previousPath}/` : "";
  const activeStep = steps.find((s) => s.isActive);
  const { pathname } = useLocation();
  const isPathActive = activeStep?.path === pathname;

  if (!activeStep) {
    return <Navigate replace to={`${previousPath}${steps[0].pathSegment}`} />;
  }

  if (isPathActive && activeStep.Form) {
    return (
      <activeStep.Form
        key={activeStep.pathSegment} // This will reset the form default values when switching between channels
        formKey={activeStep.pathSegment}
        formProps={activeStep.formProps}
      />
    );
  }

  if (activeStep.steps) {
    return (
      <>
        {activeStep.hasTabs && (
          <WizardTabsView step={activeStep} store={store} />
        )}
        <WizardContentView
          steps={activeStep.steps}
          store={store}
          previousPath={`${previousPath}${activeStep.pathSegment}`}
        />
      </>
    );
  }

  return null;
}
