import { Alert } from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { useProjects } from "../../Projects/hooks/useProjects";
import { useT } from "../../Translation/hooks/useT";
import { HomeCardActionButton } from "./HomeCardActionButton";
import { HomeCardCountView } from "./HomeCardCountView";
import { HomeCardView } from "./HomeCardView";

interface Props extends PropsWithChildren {
  organizationId?: string;
}

export const HomeCardProjectsView: FC<Props> = (props) => {
  const { organizationId } = props;

  const t = useT();
  const maxProjectsCount = 3;
  const { rows: projects, error } = useProjects({
    organizationId,
    rowsPerPage: maxProjectsCount + 1,
  });

  return (
    <HomeCardView
      title={t("HomeCardProjectsView_Title_Projects")}
      actions={
        <HomeCardActionButton
          title={t("HomeCardProjectsView_Button_SeeAllProjects")}
          href="/projects"
        />
      }
    >
      {error && <Alert severity="error">{error.message}</Alert>}
      <HomeCardCountView
        count={projects.length}
        maxCount={maxProjectsCount}
      />{" "}
      {t("HomeCardProjectsView_Label_Projects")}
    </HomeCardView>
  );
};
