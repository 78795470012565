import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  ADMIN_ORGANIZATIONS_ROUTE_RECORD,
  ADMIN_ORGANIZATIONS_ROUTES,
} from "../../Admin/consts/ADMIN_ORGANIZATIONS_ROUTE_RECORD";
import {
  ADMIN_PROJECTS_ROUTE_RECORD,
  ADMIN_PROJECTS_ROUTES,
} from "../../Admin/consts/ADMIN_PROJECTS_ROUTE_RECORD";
import {
  ADMIN_PROVIDERS_ROUTE_RECORD,
  ADMIN_PROVIDERS_ROUTES,
} from "../../Admin/consts/ADMIN_PROVIDERS_ROUTE_RECORD";
import {
  ADMIN_TENDERS_ROUTE_RECORD,
  ADMIN_TENDERS_ROUTES,
} from "../../Admin/consts/ADMIN_TENDERS_ROUTE_RECORD";
import { AdminConnectorConfigsPage } from "../../Admin/views/AdminConnectorConfigsPage";
import { AdminFeatureFlagsPage } from "../../Admin/views/AdminFeatureFlagsPage";
import { AdminOrganizationCreatePage } from "../../Admin/views/AdminOrganizationCreatePage";
import { AdminOrganizationsLayoutView } from "../../Admin/views/AdminOrganizationsLayoutView";
import { AdminOrganizationsPage } from "../../Admin/views/AdminOrganizationsPage";
import { AdminProjectsLayoutView } from "../../Admin/views/AdminProjectsLayoutView";
import { AdminProjectsPage } from "../../Admin/views/AdminProjectsPage";
import { AdminProvidersLayoutView } from "../../Admin/views/AdminProvidersLayoutView";
import { AdminProvidersPage } from "../../Admin/views/AdminProvidersPage";
import { AdminTendersLayoutView } from "../../Admin/views/AdminTendersLayoutView";
import { AdminTendersPage } from "../../Admin/views/AdminTendersPage";
import { AdminUsersPage } from "../../Admin/views/AdminUsersPage";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { CallDetailsPage } from "../../Calls/views/CallDetailsPage";
import { ConnectorConfigCreatePage } from "../../ConnectorConfigs/views/ConnectorConfigCreatePage";
import { ConnectorConfigEditPage } from "../../ConnectorConfigs/views/ConnectorConfigEditPage";
import { ConnectorConfigLayoutView } from "../../ConnectorConfigs/views/ConnectorConfigLayoutView";
import { ConnectorConfigProjectMappingsEditPage } from "../../ConnectorConfigs/views/ConnectorConfigProjectMappingsEditPage";
import { ConnectorConfigsPage } from "../../ConnectorConfigs/views/ConnectorConfigsPage";
import { ConnectorConfigStatusLogsPage } from "../../ConnectorConfigs/views/ConnectorConfigStatusLogsPage";
import { HelpCenterPage } from "../../HelpCenter/views/HelpCenterPage";
import { ClientHomePage } from "../../Home/views/ClientHomePage";
import { HomeIndexPage } from "../../Home/views/HomeIndexPage";
import { ProviderHomePage } from "../../Home/views/ProviderHomePage";
import { QmHomePage } from "../../Home/views/QmHomePage";
import { MemberPage } from "../../Members/views/MemberPage";
import { NotificationsLayoutView } from "../../Notifications/views/NotificationsLayoutView";
import { NotificationsSwitchPage } from "../../Notifications/views/NotificationsSwitchPage";
import { OrganizationDetailsPage } from "../../Organizations/views/OrganizationDetailsPage";
import { OrganizationsLayoutView } from "../../Organizations/views/OrganizationLayoutView";
import { OrganizationMembersPage } from "../../Organizations/views/OrganizationMembersPage";
import { ProjectAnalyticsPage } from "../../Projects/views/ProjectAnalyticsPage";
import { ProjectCallCategoriesPage } from "../../Projects/views/ProjectCallCategoriesPage";
import { ProjectCallFlaggingConfigsPage } from "../../Projects/views/ProjectCallFlaggingConfigsPage";
import { ProjectCreatePage } from "../../Projects/views/ProjectCreatePage";
import { ProjectDetailsPage } from "../../Projects/views/ProjectDetailsPage";
import { ProjectIssueCategoriesPage } from "../../Projects/views/ProjectIssueCategoriesPage";
import { ProjectLayoutView } from "../../Projects/views/ProjectLayoutView";
import { ProjectQuestionsPage } from "../../Projects/views/ProjectQuestionsPage";
import { ProjectReportFramesByDayDetailPage } from "../../Projects/views/ProjectReportFramesByDayDetailPage";
import { ProjectScorecardPage } from "../../Projects/views/ProjectScorecardPage";
import { ProjectSettingsLayoutView } from "../../Projects/views/ProjectSettingsLayoutView";
import { ProjectSettingsPage } from "../../Projects/views/ProjectSettingsPage";
import { ProjectsPage } from "../../Projects/views/ProjectsPage";
import { ProviderCreationSuccessPage } from "../../Providers/views/ProviderCreationSuccessPage";
import { ProviderDetailsPage } from "../../Providers/views/ProviderDetailsPage";
import { ProviderWizardPage } from "../../Providers/views/ProviderWizardPage";
import { TenderApplicationsPage } from "../../Tenders/views/TenderApplicationsPage";
import { TenderDetailsLayoutView } from "../../Tenders/views/TenderDetailsLayoutView";
import { TenderDetailsPage } from "../../Tenders/views/TenderDetailsPage";
import { TenderIndexPage } from "../../Tenders/views/TenderIndexPage";
import { TenderMyApplicationPage } from "../../Tenders/views/TenderMyApplicationPage";
import { TendersBrowsePage } from "../../Tenders/views/TendersBrowsePage";
import { TendersEndedPage } from "../../Tenders/views/TendersEndedPage";
import { TendersIndexPage } from "../../Tenders/views/TendersIndexPage";
import { TendersLayoutView } from "../../Tenders/views/TendersLayoutView";
import { TendersLostPage } from "../../Tenders/views/TendersLostPage";
import { TendersPage } from "../../Tenders/views/TendersPage";
import { TendersWonPage } from "../../Tenders/views/TendersWonPage";
import { TenderWizardPage } from "../../Tenders/views/TenderWizardPage";
import { UserPage } from "../../Users/views/UserPage";
import { App404Page } from "./App404Page";

export const AppLoggedInRouter: FC = () => {
  const hasPermission = useHasPermission();

  return (
    <Routes>
      <Route index element={<Navigate replace to="home" />} />

      {/* Redirect after login/signup with SSO */}
      <Route path="login" element={<Navigate replace to="/" />} />
      <Route path="signup" element={<Navigate replace to="/" />} />

      <Route path="help-center" element={<HelpCenterPage />} />

      <Route path="home">
        <Route index element={<HomeIndexPage />} />
        <Route path="provider" element={<ProviderHomePage />} />
        <Route path="client" element={<ClientHomePage />} />
        <Route path="qm" element={<QmHomePage />} />
      </Route>

      <Route path="notifications" element={<NotificationsLayoutView />}>
        <Route index element={<Navigate replace to="all" />} />
        <Route path=":notificationTab">
          <Route index element={<NotificationsSwitchPage />} />
          <Route path="projects/:projectId" element={<ProjectLayoutView />}>
            <Route path="calls/:callId" element={<CallDetailsPage />} />
          </Route>
        </Route>
      </Route>

      <Route path="tenders">
        <Route element={<TendersLayoutView />}>
          <Route index element={<TendersIndexPage />} />
          <Route path="my-tenders" element={<TendersPage />} />
          <Route path="my-ended-tenders" element={<TendersEndedPage />} />
          <Route path="browse-tenders" element={<TendersBrowsePage />} />
          <Route path="won-tenders" element={<TendersWonPage />} />
          <Route path="lost-tenders" element={<TendersLostPage />} />
        </Route>
        <Route path="create" element={<Navigate replace to="next-step" />} />
        <Route path=":tenderId">
          <Route element={<TenderDetailsLayoutView />}>
            <Route index element={<TenderIndexPage />} />
            <Route path="details" element={<TenderDetailsPage />} />
            <Route path="applications" element={<TenderApplicationsPage />} />
            <Route
              path="my-application"
              element={<TenderMyApplicationPage />}
            />
          </Route>
          <Route path="*" element={<TenderWizardPage />} />
        </Route>
      </Route>

      <Route path="providers">
        <Route path="success" element={<ProviderCreationSuccessPage />} />
        <Route path=":providerId" element={<ProviderDetailsPage />} />
        <Route path=":providerId/*" element={<ProviderWizardPage />} />
      </Route>

      <Route path="projects">
        <Route index element={<ProjectsPage />} />
        <Route path="create" element={<ProjectCreatePage />} />
        <Route path=":projectId" element={<ProjectLayoutView />}>
          <Route index element={<Navigate replace to="calls" />} />
          <Route path="analytics" element={<ProjectAnalyticsPage />} />
          <Route path="settings" element={<ProjectSettingsLayoutView />}>
            <Route index element={<Navigate replace to="basic" />} />
            <Route path="basic" element={<ProjectSettingsPage />} />
            <Route path="questions" element={<ProjectQuestionsPage />} />
            <Route path="scorecard" element={<ProjectScorecardPage />} />
            <Route
              path="call-categories"
              element={<ProjectCallCategoriesPage />}
            />
            <Route
              path="issue-categories"
              element={<ProjectIssueCategoriesPage />}
            />
            <Route
              path="flagging-rules"
              element={<ProjectCallFlaggingConfigsPage />}
            />
          </Route>
          <Route path="calls">
            <Route index element={<Navigate replace to="insights" />} />
            <Route path=":callId" element={<ProjectDetailsPage />} />
          </Route>
          <Route path="report-frames/:projectReportFrameId">
            <Route index element={<Navigate replace to="calls" />} />
            <Route path="calls">
              <Route index element={<Navigate replace to="insights" />} />
              <Route
                path=":callId"
                element={<ProjectReportFramesByDayDetailPage />}
              />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="users/:userId" element={<UserPage />} />

      <Route path="organizations">
        <Route path=":organizationId" element={<OrganizationsLayoutView />}>
          <Route index element={<Navigate replace to="details" />} />
          <Route path="details" element={<OrganizationDetailsPage />} />
          <Route path="members" element={<OrganizationMembersPage />} />
          <Route path="connector-configs">
            <Route index element={<ConnectorConfigsPage />} />
            <Route path="create" element={<ConnectorConfigCreatePage />} />
            <Route
              path=":connectorConfigId"
              element={<ConnectorConfigLayoutView />}
            >
              <Route index element={<Navigate replace to="details" />} />
              <Route path="details" element={<ConnectorConfigEditPage />} />
              <Route
                path="mappings"
                element={<ConnectorConfigProjectMappingsEditPage />}
              />
              <Route path="logs" element={<ConnectorConfigStatusLogsPage />} />
            </Route>
          </Route>
          <Route path="members/:userId" element={<MemberPage />} />
        </Route>
      </Route>

      {hasPermission("CAN_SEE_ADMIN_PANEL") && (
        <Route path="admin">
          <Route index element={<Navigate replace to="users" />} />
          <Route path="users" element={<AdminUsersPage />} />
          <Route path="tenders" element={<AdminTendersLayoutView />}>
            <Route index element={<Navigate replace to="all" />} />
            {ADMIN_TENDERS_ROUTES.map((route) => (
              <Route
                key={route}
                path={route}
                element={
                  <AdminTendersPage
                    headline={ADMIN_TENDERS_ROUTE_RECORD[route].label}
                    filters={ADMIN_TENDERS_ROUTE_RECORD[route].filters}
                  />
                }
              />
            ))}
          </Route>
          <Route path="providers" element={<AdminProvidersLayoutView />}>
            <Route index element={<Navigate replace to="all" />} />
            {ADMIN_PROVIDERS_ROUTES.map((route) => (
              <Route
                key={route}
                path={route}
                element={
                  <AdminProvidersPage
                    headline={ADMIN_PROVIDERS_ROUTE_RECORD[route].label}
                    filters={ADMIN_PROVIDERS_ROUTE_RECORD[route].filters}
                  />
                }
              />
            ))}
          </Route>
          <Route path="projects" element={<AdminProjectsLayoutView />}>
            <Route index element={<Navigate replace to="all" />} />
            {ADMIN_PROJECTS_ROUTES.map((route) => (
              <Route
                key={route}
                path={route}
                element={
                  <AdminProjectsPage
                    headline={ADMIN_PROJECTS_ROUTE_RECORD[route].label}
                    filters={ADMIN_PROJECTS_ROUTE_RECORD[route].filters}
                    hasCreateButton={
                      ADMIN_PROJECTS_ROUTE_RECORD[route].hasCreateButton
                    }
                  />
                }
              />
            ))}
          </Route>
          <Route
            path="organizations"
            element={<AdminOrganizationsLayoutView />}
          >
            <Route index element={<Navigate replace to="all" />} />
            {ADMIN_ORGANIZATIONS_ROUTES.map((route) => (
              <Route
                key={route}
                path={route}
                element={
                  <AdminOrganizationsPage
                    headline={ADMIN_ORGANIZATIONS_ROUTE_RECORD[route].label}
                    filters={ADMIN_ORGANIZATIONS_ROUTE_RECORD[route].filters}
                    hasCreateButton={
                      ADMIN_ORGANIZATIONS_ROUTE_RECORD[route].hasCreateButton
                    }
                  />
                }
              />
            ))}
            <Route path="create" element={<AdminOrganizationCreatePage />} />
          </Route>
          <Route
            path="connector-configs"
            element={<AdminConnectorConfigsPage />}
          />
          <Route path="feature-flags" element={<AdminFeatureFlagsPage />} />
        </Route>
      )}
      <Route path="*" element={<App404Page />} />
    </Routes>
  );
};
