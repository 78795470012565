import { Container, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { array, string, type } from "superstruct";
import { ControlledLanguagesAutocomplete } from "../../Form/views/ControlledLanguagesAutocomplete";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  supportedLanguages: array(string()),
});

export const ProviderWizardSupportedLanguagesForm: FC<WizardFormProps> = (
  props,
) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      supportedLanguages: provider?.supportedLanguages || [],
    }),
    toDoc: (formValues) => ({
      supportedLanguages: formValues.supportedLanguages?.length
        ? formValues.supportedLanguages
        : undefined,
    }),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("ProviderWizardSupportedLanguagesForm_Title_SupportedLanguages")}
        </Typography>
        <Typography variant="body1" color="text.500" mb={4}>
          {t(
            "ProviderWizardSupportedLanguagesForm_Paragraph_SupportedLanguages",
          )}
        </Typography>
        <Stack spacing={3}>
          <ControlledLanguagesAutocomplete
            control={control}
            name="supportedLanguages"
            label={t(
              "ProviderWizardSupportedLanguagesForm_Label_SupportedLanguages",
            )}
            multiple
            required
            fullWidth
          />
        </Stack>
      </Container>
    </form>
  );
};
