import {
  Box,
  Link,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { Provider } from "@snubes/snubes-types";
import { FC } from "react";
import { getLanguageName } from "../../Common/helpers/getLanguageName";
import { DetailsAccordionContainerView } from "../../Common/views/DetailsAccordionContainerView";
import { DetailsAccordionContentView } from "../../Common/views/DetailsAccordionContentView";
import { ORGANIZATION_INDUSTRY_RECORD } from "../../Organizations/consts/ORGANIZATION_INDUSTRY_RECORD";
import { TENDER_SERVICE_TYPE_RECORD } from "../../Tenders/consts/TENDER_SERVICE_TYPE_RECORD";
import { useT } from "../../Translation/hooks/useT";
import { PROVIDER_CLIENT_FOCUS_RECORD } from "../consts/PROVIDER_CLIENT_FOCUS_RECORD";
import { PROVIDER_CLIENT_TYPE } from "../consts/PROVIDER_CLIENT_TYPE";
import { PROVIDER_TEAM_MODEL } from "../consts/PROVIDER_TEAM_MODEL";
import { PROVIDER_TECHNOLOGY } from "../consts/PROVIDER_TECHNOLOGY";
import { ProviderPricingModelsView } from "./ProviderPricingModelsView";
import { ProviderWizardOperationCentersView } from "./ProviderWizardOperationCentersView";
import { ProviderWorkingHoursView } from "./ProviderWorkingHoursView";

interface Props {
  provider: Provider;
}

export const ProviderDetailsView: FC<Props> = ({ provider }) => {
  const t = useT();

  return (
    <Box>
      <DetailsAccordionContainerView
        title={t("ProviderDetailsView_Title_BasicDetails")}
      >
        <DetailsAccordionContentView title="Contact">
          <Grid container columnSpacing={6} rowSpacing={3}>
            <Grid>
              <Stack spacing={2}>
                {provider.email && (
                  <Typography variant="body2">{provider.email}</Typography>
                )}
                {provider.phone && (
                  <Typography variant="body2">{provider.phone}</Typography>
                )}
                {provider.website && (
                  <Link variant="body2" href={provider.website} target="_blank">
                    {provider.website}
                  </Link>
                )}
              </Stack>
            </Grid>

            <Grid>
              <Stack spacing={2}>
                {provider.socialMediaFacebook && (
                  <Link
                    variant="body2"
                    href={provider.socialMediaFacebook}
                    target="_blank"
                  >
                    {provider.socialMediaFacebook}
                  </Link>
                )}
                {provider.socialMediaTwitter && (
                  <Link
                    variant="body2"
                    href={provider.socialMediaTwitter}
                    target="_blank"
                  >
                    {provider.socialMediaTwitter}
                  </Link>
                )}
                {provider.socialMediaLinkedIn && (
                  <Link
                    variant="body2"
                    href={provider.socialMediaLinkedIn}
                    target="_blank"
                  >
                    {provider.socialMediaLinkedIn}
                  </Link>
                )}
                {provider.socialMediaInstagram && (
                  <Link
                    variant="body2"
                    href={provider.socialMediaInstagram}
                    target="_blank"
                  >
                    {provider.socialMediaInstagram}
                  </Link>
                )}
                {provider.socialMediaYoutube && (
                  <Link
                    variant="body2"
                    href={provider.socialMediaYoutube}
                    target="_blank"
                  >
                    {provider.socialMediaYoutube}
                  </Link>
                )}
              </Stack>
            </Grid>
          </Grid>
        </DetailsAccordionContentView>

        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_Address")}
        >
          {provider.street && provider.city && provider.zipCode && (
            <Typography variant="body2">
              {provider.street}, {provider.city}, {provider.zipCode}
            </Typography>
          )}
        </DetailsAccordionContentView>

        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_Description")}
        >
          {provider.description && (
            <Typography variant="body2">{provider.description}</Typography>
          )}
        </DetailsAccordionContentView>

        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_FoundedIn")}
        >
          {provider.organizationFoundedInYear && (
            <Typography variant="body2">
              {provider.organizationFoundedInYear}
            </Typography>
          )}
        </DetailsAccordionContentView>

        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_NumberOfEmployees")}
        >
          {provider.numberOfEmployees && (
            <Typography variant="body2">
              {provider.numberOfEmployees}
            </Typography>
          )}
        </DetailsAccordionContentView>

        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_OrganizationIndustry")}
        >
          {provider.organizationIndustry && (
            <Typography variant="body2">
              {t(
                ORGANIZATION_INDUSTRY_RECORD[provider.organizationIndustry]
                  ?.label,
              )}
            </Typography>
          )}
        </DetailsAccordionContentView>
      </DetailsAccordionContainerView>

      <DetailsAccordionContainerView
        title={t("ProviderDetailsView_Title_BusinessDetails")}
      >
        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_Services")}
        >
          <Typography variant="body2">
            {provider.services
              ?.map((service) =>
                t(TENDER_SERVICE_TYPE_RECORD[service].translationKey),
              )
              .join(", ")}
          </Typography>
        </DetailsAccordionContentView>

        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_ClientTypes")}
        >
          <Typography variant="body2">
            {provider.clientTypes
              ?.map((type) => t(PROVIDER_CLIENT_TYPE[type].label))
              .join(", ")}
          </Typography>
        </DetailsAccordionContentView>

        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_ClientFocus")}
        >
          <Typography variant="body2">
            {provider.clientFocus
              ?.map((type) => t(PROVIDER_CLIENT_FOCUS_RECORD[type].label))
              .join(", ")}
          </Typography>
        </DetailsAccordionContentView>

        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_Industries")}
        >
          <Typography variant="body2">
            {provider.industries
              ?.map((industry) =>
                t(ORGANIZATION_INDUSTRY_RECORD[industry].label),
              )
              .join(", ")}
          </Typography>
        </DetailsAccordionContentView>

        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_Revenue")}
        >
          <Typography variant="body2">
            {provider.revenueRangeYearly
              ?.map((n) => n.toLocaleString())
              .join("-")}{" "}
            {provider.revenueRangeCurrency}
          </Typography>
        </DetailsAccordionContentView>
      </DetailsAccordionContainerView>

      <DetailsAccordionContainerView
        title={t("ProviderDetailsView_Title_ServiceDetails")}
      >
        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_PricingModels")}
        >
          <ProviderPricingModelsView provider={provider} />
        </DetailsAccordionContentView>

        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_ToolsAndTechnologies")}
        >
          <Typography variant="body2">
            {provider.toolsAndTechnologies
              ?.map((value) => t(PROVIDER_TECHNOLOGY[value].label))
              .join(", ")}
          </Typography>
        </DetailsAccordionContentView>

        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_SupportedLanguages")}
        >
          <Typography variant="body2">
            {provider.supportedLanguages?.map(getLanguageName).join(", ")}
          </Typography>
        </DetailsAccordionContentView>
      </DetailsAccordionContainerView>

      <DetailsAccordionContainerView
        title={t("ProviderDetailsView_Title_OperationalDetails")}
      >
        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_WorkingHours")}
        >
          <ProviderWorkingHoursView provider={provider} />
        </DetailsAccordionContentView>

        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_TeamModel")}
        >
          <Typography variant="body2">
            {provider.teamModels
              ?.map((model) => t(PROVIDER_TEAM_MODEL[model].label))
              .join(", ")}
          </Typography>
        </DetailsAccordionContentView>

        <DetailsAccordionContentView
          title={t("useProviderWizardSteps_Title_OperationCenters")}
        >
          {provider.operationCenters && (
            <ProviderWizardOperationCentersView
              operationCenters={provider.operationCenters}
            />
          )}
        </DetailsAccordionContentView>
      </DetailsAccordionContainerView>
    </Box>
  );
};
