import { FirebaseFilter, Tender, TenderStatus } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

export const ADMIN_TENDERS_ROUTES = [
  "all",
  "waiting-for-review",
  "published",
  "ended",
  "deleted",
] as const;

type Route = (typeof ADMIN_TENDERS_ROUTES)[number];

interface RouteRecordValue {
  label: TranslationKey;
  filters: FirebaseFilter<Tender>[];
}

export const ADMIN_TENDERS_ROUTE_RECORD: Record<Route, RouteRecordValue> = {
  all: {
    label: "AdminTendersRouteRecord_Label_All",
    filters: [["isDeleted", "==", false]],
  },
  ["waiting-for-review"]: {
    label: "AdminTendersRouteRecord_Label_WaitingForReview",
    filters: [
      ["status", "==", "WAITING_FOR_REVIEW" satisfies TenderStatus],
      ["isDeleted", "==", false],
    ],
  },
  published: {
    label: "AdminTendersRouteRecord_Label_Published",
    filters: [
      ["status", "==", "PUBLISHED" satisfies TenderStatus],
      ["isDeleted", "==", false],
    ],
  },
  ended: {
    label: "AdminTendersRouteRecord_Label_Ended",
    filters: [
      ["status", "==", "ENDED" satisfies TenderStatus],
      ["isDeleted", "==", false],
    ],
  },
  deleted: {
    label: "AdminTendersRouteRecord_Label_Deleted",
    filters: [["isDeleted", "==", true]],
  },
};
