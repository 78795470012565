import { CallIssue } from "@snubes/snubes-types";
import { useCallback } from "react";
import { useT } from "../../Translation/hooks/useT";
import { CALL_FLAGGING_CONFIG_PARAMETER_OPERATOR_RECORD } from "../consts/CALL_FLAGGING_CONFIG_PARAMETER_OPERATOR_RECORD";
import { CALL_FLAGGING_CONFIG_PARAMETER_TYPE_RECORD } from "../consts/CALL_FLAGGING_CONFIG_PARAMETER_TYPE_RECORD";

export function useGetCallIssueName() {
  const t = useT();

  return useCallback(
    (issue: CallIssue) => {
      const { name, callFlaggingConfigError } = issue;

      if (!callFlaggingConfigError) {
        return name;
      }
      if (
        callFlaggingConfigError.type === "QUESTION_ANSWER" &&
        callFlaggingConfigError.questionText
      ) {
        return callFlaggingConfigError.questionText;
      }
      if (
        callFlaggingConfigError.type === "CATEGORY" &&
        callFlaggingConfigError.actual
      ) {
        return [
          t(CALL_FLAGGING_CONFIG_PARAMETER_TYPE_RECORD.CATEGORY.label),
          callFlaggingConfigError.actual,
        ].join(" ");
      }
      return [
        t(
          CALL_FLAGGING_CONFIG_PARAMETER_TYPE_RECORD[
            callFlaggingConfigError.type
          ].label,
        ),
        t(
          CALL_FLAGGING_CONFIG_PARAMETER_OPERATOR_RECORD[
            callFlaggingConfigError.operator
          ].label,
        ),
        typeof callFlaggingConfigError.expected === "boolean"
          ? ""
          : callFlaggingConfigError.expected,
      ].join(" ");
    },
    [t],
  );
}
