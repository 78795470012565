import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Alert, Button, Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { LoadingView } from "../../Common/views/LoadingView";
import { NoDataView } from "../../Common/views/NoDataView";
import { useOrganization } from "../../Organizations/hooks/useOrganization";
import { useT } from "../../Translation/hooks/useT";
import { useConnectorConfigs } from "../hooks/useConnectorConfigs";
import { ConnectorConfigsCollectionTableView } from "./ConnectorConfigsCollectionTableView";

export const ConnectorConfigsPage: FC = () => {
  const t = useT();
  const { organizationId } = useParams<"organizationId">();
  const [organization] = useOrganization(organizationId);
  const [connectorConfigs, isLoading, error] =
    useConnectorConfigs(organizationId);

  const isLoaded = !isLoading && !error;

  return (
    <>
      {isLoading && <LoadingView />}
      {error && (
        <Alert sx={{ m: 3 }} severity="error">
          {error.message}
        </Alert>
      )}
      {isLoaded && connectorConfigs.length === 0 && (
        <FullHeightContainer maxWidth="sm" sx={{ display: "flex" }}>
          <NoDataView
            label={t("ConnectorConfigsPage_Label_NoConnections")}
            title={t("ConnectorConfigsPage_Title_NoConnections")}
            subTitle={t("ConnectorConfigsPage_SubTitle_ConnectTools")}
            button={{
              href: "create",
              label: t("ConnectorConfigsPage_Button_NewConnection"),
              disabled: organization?.isDeleted,
            }}
          />
        </FullHeightContainer>
      )}
      {isLoaded && connectorConfigs.length > 0 && (
        <FullHeightContainer>
          <Stack py={5} spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h2">
                {t("ConnectorConfigsPage_Header_Connections")}
              </Typography>
              <Button
                href="create"
                variant="contained"
                startIcon={<AddCircleIcon />}
                disabled={organization?.isDeleted}
              >
                {t("ConnectorConfigsPage_Button_NewConnection")}
              </Button>
            </Stack>
            {organizationId && (
              <Paper>
                <ConnectorConfigsCollectionTableView
                  organizationId={organizationId}
                />
              </Paper>
            )}
          </Stack>
        </FullHeightContainer>
      )}
    </>
  );
};
