import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Button, Typography, TypographyProps } from "@mui/material";
import { FC, ReactNode, SyntheticEvent } from "react";
import { Link } from "react-router-dom";

interface BaseProps {
  to?: string;
  children: ReactNode;
  variant?: "button" | "link";
  onClick?: (event: SyntheticEvent) => void;
}

interface LinkProps extends BaseProps {
  variant?: "link";
}

interface ButtonProps extends BaseProps {
  to: string;
  variant: "button";
}

type Props = LinkProps | ButtonProps;

export const LinkView: FC<Props> = (props) => {
  if (props.variant === "button") {
    return (
      <Button
        href={props.to}
        onClick={props.onClick}
        size="small"
        variant="text"
        endIcon={<ArrowOutwardIcon color="primary" />}
      >
        {props.children}
      </Button>
    );
  }
  return (
    <LinkTypography
      color="primary.main"
      variant="h5"
      display="flex"
      alignItems="center"
      to={props.to}
      onClick={props.onClick}
    >
      {props.children}
      <ArrowOutwardIcon sx={{ ml: 2 }} fontSize="small" />
    </LinkTypography>
  );
};

type LinkTypographyProps = Pick<BaseProps, "to" | "children" | "onClick"> &
  Omit<TypographyProps, "ref">;

export const LinkTypography: FC<LinkTypographyProps> = (props) => {
  const { to, sx, ...restProps } = props;
  if (to) {
    return (
      <Typography
        component={Link}
        to={to}
        sx={{
          ...sx,
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
        {...restProps}
      />
    );
  } else {
    return <Typography sx={sx} {...restProps} />;
  }
};
