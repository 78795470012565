import { useMemo } from "react";
import { aggregateReportFrames } from "../helpers/aggregateReportFrames";
import { calculateComparedPercentages } from "../helpers/calculateComparedPercentages";
import { ProjectReportFrameByDay } from "../types/ProjectReportFrameByDay";
import { ReportFrameByDaySummary } from "../types/ReportFrameByDaySummary";

interface Args {
  frames: ProjectReportFrameByDay[];
  framesReference: ProjectReportFrameByDay[];
  useAverages?: boolean;
}

export function useCalculateSummaryValues(args: Args): ReportFrameByDaySummary {
  const { frames, framesReference, useAverages } = args;

  return useMemo(() => {
    const validFrames = frames.map((frame) => {
      // TODO: Can be removed when values are required again
      return {
        ...frame,
        answeredCallsCount: frame.answeredCallsCount ?? 0,
        totalHandlingTimeInSeconds: frame.totalHandlingTimeInSeconds ?? 0,
        serviceLevelPercentage: frame.serviceLevelPercentage ?? 0,
      };
    });
    const validFramesReference = framesReference.map((frame) => {
      // TODO: Can be removed when values are required again
      return {
        ...frame,
        answeredCallsCount: frame.answeredCallsCount ?? 0,
        totalHandlingTimeInSeconds: frame.totalHandlingTimeInSeconds ?? 0,
        serviceLevelPercentage: frame.serviceLevelPercentage ?? 0,
      };
    });

    const values = aggregateReportFrames(validFrames);
    const reportFrameReference = aggregateReportFrames(validFramesReference);

    if (!validFrames.length || !validFramesReference.length) {
      return { values };
    }

    const valuesReference = useAverages
      ? {
          totalCallsCount:
            reportFrameReference.totalCallsCount / validFramesReference.length,
          answeredCallsCount:
            reportFrameReference.answeredCallsCount /
            validFramesReference.length,
          reachabilityPercentage: reportFrameReference.reachabilityPercentage,
          averageHandlingTimeInSeconds:
            reportFrameReference.averageHandlingTimeInSeconds,
          totalHandlingTimeInSeconds:
            reportFrameReference.totalHandlingTimeInSeconds /
            validFramesReference.length,
          serviceLevelPercentage: reportFrameReference.serviceLevelPercentage,
        }
      : reportFrameReference;

    const comparedPercentages = calculateComparedPercentages({
      reportFrameToday: values,
      reportFrameReference,
    });

    return {
      values,
      valuesReference,
      comparedPercentages,
    };
  }, [frames, framesReference, useAverages]);
}
