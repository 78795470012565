import { SelectChangeEvent } from "@mui/material";
import { useState } from "react";
import { TenderOrderBy } from "../types/TenderOrderBy";

export interface TendersController {
  orderBy: TenderOrderBy;
  onOrderByChanged: (event: SelectChangeEvent) => void;
}

interface Args {
  defaultOrderBy?: TenderOrderBy;
}

/**
 * @deprecated Use dedicated filter and sort component instead.
 */
export function useTendersController(args: Args = {}): TendersController {
  const [orderBy, setOrderBy] = useState<TenderOrderBy>(
    args.defaultOrderBy || "CREATED_AT",
  );

  const onOrderByChanged = (event: SelectChangeEvent) => {
    setOrderBy(event.target.value as TenderOrderBy);
  };

  return { orderBy, onOrderByChanged };
}
