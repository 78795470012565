import { Stack, StackProps } from "@mui/material";
import { FC } from "react";
import {
  HEADER_HEIGHT,
  SECONDARY_HEADER_HEIGHT,
} from "../../Header/consts/HEADER_HEIGHT";
import { useHeaderConfig } from "../../Header/hooks/useHeaderConfig";
import { SIDEBAR_WIDTH } from "../consts/SIDEBAR_WIDTH";

type Props = Omit<
  StackProps,
  | "position"
  | "top"
  | "left"
  | "bottom"
  | "width"
  | "borderRight"
  | "borderColor"
> & {
  width?: number;
};

export const SidebarView: FC<Props> = (props) => {
  const { sx, width, ...restProps } = props;
  const secondaryHeaderHeight = useHeaderConfig((state) =>
    state.hasSecondaryHeader ? SECONDARY_HEADER_HEIGHT : 0,
  );

  return (
    <Stack
      position="fixed"
      top={HEADER_HEIGHT + secondaryHeaderHeight}
      left={0}
      bottom={0}
      width={width || SIDEBAR_WIDTH}
      borderRight={1}
      sx={{ overflowY: "auto", zIndex: 3, ...sx }}
      borderColor="divider"
      bgcolor="grey.50"
      {...restProps}
    />
  );
};
