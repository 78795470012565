import DateRangePicker from "./components/DateRangePicker";
import DateRangePickerExporter from "./components/DateRangePickerExporter";
import { DateRange, DefinedRange } from "./types";

export {
  DateRangePickerExporter as DateRangePicker,
  DateRangePicker as DateRangePickerComponent,
};

export type { DateRange, DefinedRange };
