import { enums, Infer } from "superstruct";

export const ADMIN_FEATURE_FLAGS = [
  "ONBOARDING_DEMOS", // users can see links to demos on the onboarding home page
  "MATCHING_PROVIDERS", // clients can see matching providers for their tenders
] as const;

export const AdminFeatureFlagStruct = enums(ADMIN_FEATURE_FLAGS);

export type AdminFeatureFlag = Infer<typeof AdminFeatureFlagStruct>;
