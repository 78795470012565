import { Avatar, Paper, Stack, Typography } from "@mui/material";
import { Call } from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { VoteButtons } from "../../Common/views/VoteButtons";
import { updateCallIsSummaryHelpfulCallable } from "../callables/updateCallIsSummaryHelpfulCallable";

interface Props {
  call: Call;
}

export const CallSummaryView: FC<Props> = (props) => {
  const { call } = props;

  const updateIsSummaryHelpful = useCallback(
    async (value: boolean) => {
      try {
        await updateCallIsSummaryHelpfulCallable({
          callId: call.id,
          isSummaryHelpful: value,
        });
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [call.id],
  );

  return (
    <Paper variant="outlined">
      <Stack p={3} spacing={3} direction="row">
        <Stack>
          <Avatar sx={{ bgcolor: "primary.main" }}>AI</Avatar>
        </Stack>
        <Stack spacing={2} flex={1}>
          <Stack bgcolor="grey.50" borderRadius={1} p={3} flex={1}>
            <Typography variant="mono">{call.summary}</Typography>
          </Stack>
          <VoteButtons
            setValue={updateIsSummaryHelpful}
            votes={call.isSummaryHelpful}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};
