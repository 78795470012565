import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ConnectorConfigStatus } from "@snubes/snubes-types/types/ConnectorConfigStatus";
import { StatusColor } from "../../Common/types/StatusColor";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface ConnectorConfigStatusRecordValue {
  color: StatusColor;
  labelShort: TranslationKey;
  labelLong: TranslationKey;
  Icon: OverridableComponent<SvgIconTypeMap>;
}

export const CONNECTOR_CONFIG_STATUS_RECORD: Record<
  ConnectorConfigStatus,
  ConnectorConfigStatusRecordValue
> = {
  INITIAL: {
    color: "warning",
    labelShort: "ConnectorConfigStatusRecord_LabelShort_Initial",
    labelLong: "ConnectorConfigStatusRecord_LabelLong_Initial",
    Icon: PendingOutlinedIcon,
  },
  INTERRUPTED: {
    color: "error",
    labelShort: "ConnectorConfigStatusRecord_LabelShort_Interrupted",
    labelLong: "ConnectorConfigStatusRecord_LabelLong_Interrupted",
    Icon: ReportProblemIcon,
  },
  RUNNING: {
    color: "secondary",
    labelShort: "ConnectorConfigStatusRecord_LabelShort_Running",
    labelLong: "ConnectorConfigStatusRecord_LabelLong_Running",
    Icon: CheckCircleIcon,
  },
};
