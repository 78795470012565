/* eslint-disable react-hooks/exhaustive-deps */
import ClearIcon from "@mui/icons-material/Clear";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextFieldProps,
} from "@mui/material";
import { ChangeEvent, FC, useCallback } from "react";
import { useStore } from "zustand";
import { selectFirebaseTableFilter } from "../helpers/selectFirebaseTableFilter";
import { useFirebaseTableFilterStore } from "../hooks/useFirebaseTableFilters";

interface Props
  extends Pick<TextFieldProps, "placeholder" | "size" | "fullWidth"> {
  filterName: string;
  hasClearButton?: boolean;
}

export const FirebaseTableFilterTextView: FC<Props> = (props) => {
  const { filterName, hasClearButton } = props;

  const filterStore = useFirebaseTableFilterStore();
  const filterLabel = useStore(
    filterStore,
    useCallback(
      selectFilter(filterName, (filter) => filter.label),
      [filterName],
    ),
  );
  const value = useStore(
    filterStore,
    useCallback(
      selectFilter(filterName, (filter) => filter.value || ""),
      [filterName],
    ),
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    filterStore
      .getState()
      .setFilterValue("text", filterName, event.target.value || null);
  };

  const handleClear = () => {
    filterStore.getState().setFilterValue("text", filterName, null);
  };

  return (
    <OutlinedInput
      size={props.size}
      fullWidth={props.fullWidth}
      placeholder={props.placeholder}
      label={props.placeholder ? undefined : filterLabel}
      value={value}
      onChange={handleChange}
      sx={{
        ...(hasClearButton && { minWidth: 235 }),
      }}
      endAdornment={
        hasClearButton &&
        value && (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={handleClear}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};

const selectFilter = selectFirebaseTableFilter("text");
