import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import { Project } from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import toast from "react-hot-toast";
import { useT } from "../../Translation/hooks/useT";
import { useProjectCallFlaggingConfigsClipboard } from "../hooks/useProjectCallFlaggingConfigsClipboard";
import { ProjectCallFlaggingConfigsFormValues } from "../types/ProjectCallFlaggingConfigsFormValues";

interface Props {
  project: Project;
  isFormDirty: boolean;
  onSetProjectCallFlaggingConfigs: (
    callFlaggingConfigs: ProjectCallFlaggingConfigsFormValues,
  ) => void;
}

export const ProjectCallFlaggingConfigsFormHeaderView: FC<Props> = (props) => {
  const t = useT();
  const { project, isFormDirty, onSetProjectCallFlaggingConfigs } = props;
  const {
    setProjectCallFlaggingConfigsToClipboard,
    getProjectCallFlaggingConfigsFromClipboard,
  } = useProjectCallFlaggingConfigsClipboard(project);

  const onClickPasteCallFlaggingConfigs = useCallback(async () => {
    const callFlaggingConfigsFromClipboard =
      await getProjectCallFlaggingConfigsFromClipboard();

    if (callFlaggingConfigsFromClipboard) {
      toast.success(
        t(
          "ProjectCallFlaggingConfigsFormHeaderView_Toast_PastedCallFlaggingConfigsFromClipboard",
        ),
      );

      onSetProjectCallFlaggingConfigs(callFlaggingConfigsFromClipboard);
    }
  }, [
    onSetProjectCallFlaggingConfigs,
    getProjectCallFlaggingConfigsFromClipboard,
    t,
  ]);

  return (
    <Stack direction="row" spacing={2} pr={2} alignItems="center">
      <Box>
        <Tooltip
          title={t(
            "ProjectCallFlaggingConfigsFormHeaderView_Tooltip_PasteCallFlaggingConfigsFromClipboard",
          )}
        >
          <IconButton onClick={onClickPasteCallFlaggingConfigs}>
            <ContentPasteGoIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {!!project.callFlaggingConfigs?.length && (
        <Tooltip
          title={t(
            isFormDirty
              ? "ProjectCallFlaggingConfigsFormHeaderView_Tooltip_DisabledCopyCallFlaggingConfigsToClipboard"
              : "ProjectCallFlaggingConfigsFormHeaderView_Tooltip_CopyCallFlaggingConfigsToClipboard",
          )}
        >
          <Box>
            <IconButton
              disabled={isFormDirty}
              onClick={setProjectCallFlaggingConfigsToClipboard}
            >
              <ContentCopyIcon />
            </IconButton>
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};
