import { Alert } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { BodyConfig } from "../../Common/views/BodyConfig";
import { LoadingView } from "../../Common/views/LoadingView";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { getFirstIncompleteWizardStepPath } from "../../Wizard/helpers/getFirstIncompleteWizardStepPath";
import { WizardContentView } from "../../Wizard/views/WizardContentView";
import { WizardLayoutView } from "../../Wizard/views/WizardLayoutView";
import { useTenderWizardSteps } from "../hooks/useTenderWizardSteps";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";
import { TenderWizardHeaderView } from "./TenderWizardHeaderView";

export const TenderWizardPage: FC = () => {
  const steps = useTenderWizardSteps();
  const isInitializing = useTenderWizardStore((state) => state.isInitializing);
  const tenderId = useTenderWizardStore((state) => state.docId);
  const error = useTenderWizardStore((state) => state.error);
  const hasTender = useTenderWizardStore((state) => Boolean(state.doc));
  const navigate = useNavigate();
  const params = useParams<"tenderId">();
  const isNew = params.tenderId === "create";
  const { pathname } = useLocation();

  // This reference is used to avoid flickering when the URL
  // is switching from `tenders/create/` to `tenders/{id}`.
  const wasNewRef = useRef(isNew);
  const isLoadingViewVisible = !wasNewRef.current && isInitializing;

  useEffect(() => {
    if (!isNew) {
      useTenderWizardStore.getState().subscribe(params.tenderId);
    } else if (wasNewRef.current) {
      useTenderWizardStore.setState({ isInitializing: false });
    }
  }, [isNew, params.tenderId]);

  useEffect(() => {
    return () => {
      useTenderWizardStore.getState().unsubscribe?.();
    };
  }, []);

  useEffect(() => useTenderWizardStore.getState().reset, []);

  // TODO: This effect is only to redirect to a URL path with tender id
  // after the tender was saved. We could refactor this logic to happen
  // in createOrUpdateTender.
  useEffect(() => {
    if (isNew && tenderId) {
      const { pathname } = document.location;
      const restPath = pathname.split("/").slice(3).join("/");
      const nextPath = `/tenders/${tenderId}/${restPath}`;
      navigate(nextPath, { replace: true });
    }
  }, [isNew, tenderId, navigate]);

  if (!isInitializing && hasTender && pathname.endsWith("next-step")) {
    const path = getFirstIncompleteWizardStepPath(steps, useTenderWizardStore);
    return <Navigate replace to={path} />;
  }

  // We redirect back to `/tenders`, if tender cannot be found.
  if (!isInitializing && !hasTender && !wasNewRef.current && !error) {
    return <Navigate replace to="/tenders" />;
  }

  return (
    <>
      <HeaderConfig variant="WITH_CUSTOM_CHILDREN">
        <TenderWizardHeaderView steps={steps} store={useTenderWizardStore} />
      </HeaderConfig>
      <BodyConfig background="PAPER" />
      <WizardLayoutView
        steps={steps}
        store={useTenderWizardStore}
        onSubmitNavigateTo="/tenders/my-tenders"
      >
        {error && <Alert severity="error">{error.message}</Alert>}
        {isLoadingViewVisible && <LoadingView />}
        {!isLoadingViewVisible && (
          <WizardContentView steps={steps} store={useTenderWizardStore} />
        )}
      </WizardLayoutView>
    </>
  );
};
