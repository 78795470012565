import {
  Autocomplete,
  FormControl,
  FormControlProps,
  FormHelperText,
  SxProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { ReactNode } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { formatError } from "../helpers/formatError";

interface Props<TFields extends FieldValues, TData extends string> {
  control: Control<TFields>;
  name: FieldPath<TFields>;
  options: TData[] | ReadonlyArray<TData>;
  getOptionLabel: (value: TData) => string;
  label?: ReactNode;
  required?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  helperText?: string;
  disabled?: boolean;
  size?: FormControlProps["size"];
  multiple?: boolean;
  sx?: SxProps;
  error?: Error;
  InputProps?: TextFieldProps["InputProps"];
  isOptionEqualToValue?: (option: TData, value: TData) => boolean;
  onChange?: (value: string | null) => void;
}

// TODO: support optional "" (none) value like in
// https://mui.com/material-ui/react-select/#filled-and-standard-variants
export function ControlledAutocomplete<
  TFields extends FieldValues,
  TData extends string,
>(props: Props<TFields, TData>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field, fieldState }) => {
        const error = props.error || fieldState.error;
        const helperText = props.helperText || formatError(error);

        return (
          <FormControl error={!!error}>
            <Autocomplete
              multiple={props.multiple}
              filterSelectedOptions
              sx={props.sx}
              size={props.size}
              fullWidth={props.fullWidth}
              disabled={props.disabled}
              options={props.options}
              getOptionLabel={props.getOptionLabel}
              // @ts-expect-error key does not exist in renderOptionProps
              // Material UI bug: key must not be spread
              // See https://github.com/mui/material-ui/blob/v5.12.3/packages/mui-material/src/Autocomplete/Autocomplete.js#L544
              // and https://github.com/mui/material-ui/blob/v5.12.3/packages/mui-base/src/useAutocomplete/useAutocomplete.js#L1146
              renderOption={({ key: _, ...renderOptionProps }, option) => (
                <li key={String(option)} {...renderOptionProps}>
                  {props.getOptionLabel(option)}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={props.label}
                  placeholder={props.placeholder}
                  required={props.required}
                  disabled={props.disabled}
                  size={props.size}
                  InputProps={{
                    ...params.InputProps,
                    ...props.InputProps,
                  }}
                />
              )}
              {...field}
              value={field.value || null}
              isOptionEqualToValue={(option, value) =>
                value === undefined ||
                value === "" ||
                option === value ||
                !!props.isOptionEqualToValue?.(option, value)
              }
              onChange={(_, value) => {
                field.onChange(value);
                if (typeof value === "string" || value === null) {
                  props.onChange?.(value);
                }
              }}
              onInputChange={(_e, _v, reason) => {
                if (reason === "reset") {
                  field.onChange(field.value);
                }
              }}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
}
