import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { Provider } from "@snubes/snubes-types";
import { FC } from "react";
import { toLocaleWeekdayPeriod } from "../../Common/helpers/toLocaleWeekdayPeriod";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  provider: Partial<Provider>;
}

export const ProviderWorkingHoursView: FC<Props> = (props) => {
  const { provider } = props;
  const t = useT();

  if (provider.isWorkingAroundTheClock) {
    return (
      <Typography variant="body2">
        {t("ProviderWizardWorkingHoursForm_Label_AroundTheClock")}
      </Typography>
    );
  }
  return (
    <List>
      {provider.workingHours?.map(toLocaleWeekdayPeriod).map((value) => (
        <ListItem disablePadding key={value}>
          <ListItemText disableTypography>
            <Typography variant="body2">{value}</Typography>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};
