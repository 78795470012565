import { WizardStep } from "../types/WizardStep";

export function updateWizardStepPaths<TState>(
  steps: WizardStep<TState>[],
  previousPath: string,
) {
  steps.forEach((step) => {
    step.path = `${previousPath}/${step.pathSegment}`;
    if (step.steps) {
      updateWizardStepPaths(step.steps, step.path);
    }
  });
}
