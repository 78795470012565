import {
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { FC, isValidElement, PropsWithChildren, ReactNode } from "react";

interface Props extends PropsWithChildren {
  title?: ReactNode;
  actions?: ReactNode;
}

export const HomeCardView: FC<Props> = (props) => {
  const { title, actions, children } = props;
  return (
    <Card>
      <CardContent component={Stack} spacing={3}>
        {isValidElement(title) ? (
          title
        ) : (
          <Typography
            variant="h4"
            sx={{
              whiteSpace: "nowrap",
              overflowX: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
        )}

        <Typography
          color="text.secondary"
          variant="subtitle2"
          sx={{
            whiteSpace: "nowrap",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {children}
        </Typography>
      </CardContent>

      <CardActions>{actions}</CardActions>
    </Card>
  );
};
