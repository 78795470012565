import { Typography } from "@mui/material";
import {
  FirebaseFilter,
  FirebaseOrderBy,
  isProvider,
  Provider,
  SERVICE_TYPES,
} from "@snubes/snubes-types";
import { FC, useMemo } from "react";
import {
  FirebaseTableFilterContextProvider,
  FirebaseTableFilterRecord,
} from "../../Firebase/hooks/useFirebaseTableFilters";
import { CollectionTableColumn } from "../../Firebase/types/CollectionTableColumn";
import {
  CollectionTableView,
  CollectionTableViewProps,
} from "../../Firebase/views/CollectionTableView";
import { useLanguageOptions } from "../../Form/hooks/useLanguageOptions";
import { DEFAULT_ORGANIZATION_INDUSTRY_RECORD } from "../../Organizations/consts/ORGANIZATION_INDUSTRY_RECORD";
import { useT } from "../../Translation/hooks/useT";
import { PROVIDER_CLIENT_TYPE } from "../consts/PROVIDER_CLIENT_TYPE";
import { PROVIDER_TEAM_MODEL } from "../consts/PROVIDER_TEAM_MODEL";
import { ProviderStatusChip } from "./ProviderStatusChip";

interface BaseProps {
  filters?: FirebaseFilter<Provider>[];
  onClickRow?: (provider: Provider) => void;
}

interface PropsWithoutCheckboxes extends BaseProps {
  hasCheckboxes?: false;
  onRowSelectionChanged?: (selectedRowIds: string[]) => void;
  selectedRowIds?: string[];
}

interface PropsWithCheckboxes extends BaseProps {
  hasCheckboxes: true;
  onRowSelectionChanged: (selectedRowIds: string[]) => void;
  isCheckboxDisabled: (provider: Provider) => boolean;
  selectedRowIds: string[];
}

type Props = PropsWithoutCheckboxes | PropsWithCheckboxes;

export const ProvidersCollectionTableView: FC<Props> = (props) => {
  const t = useT();
  const orderBy = useMemo<FirebaseOrderBy<Provider>>(
    () => ["createdAt", "desc"],
    [],
  );

  const columns = useMemo<CollectionTableColumn<Provider>[]>(
    () => [
      {
        title: t("ProvidersCollectionTableView_TableCell_Name"),
        renderCell: (provider) => (
          <Typography variant="body2">{provider.organizationName}</Typography>
        ),
      },
      {
        title: t("ProvidersCollectionTableView_TableCell_ServicesProvided"),
        renderCell: (provider) => (
          <>
            <Typography
              variant="body2"
              maxWidth="20vw"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {provider.services?.map((s) => t(`ServiceType_${s}`)).join(", ")}
            </Typography>
            <Typography variant="caption2" style={{ marginTop: 2 }}>
              {t(
                "ProvidersCollectionTableView_TableCell_ServicesProvided_Count",
                { count: provider.services?.length || 0 },
              )}
            </Typography>
          </>
        ),
      },
      {
        title: "Email",
        field: "email",
      },

      {
        title: t("ProvidersCollectionTableView_TableCell_Status"),
        renderCell: (provider) => <ProviderStatusChip provider={provider} />,
      },
      {
        title: t("Common_UpdatedAt"),
        field: "updatedAt",
        align: "right",
      },
    ],
    [t],
  );

  const getToProps = useMemo(
    () =>
      ({
        ...(!props.onClickRow && {
          getTo: (provider) => `/providers/${provider.id}`,
        }),
        onClickRow: props.onClickRow,
      }) satisfies Partial<CollectionTableViewProps<Provider>>,
    [props.onClickRow],
  );

  const languageOptions = useLanguageOptions({ asOptions: true });

  const filters = useMemo<FirebaseTableFilterRecord<Provider>>(
    () => ({
      organizationName: {
        isSearch: true,
        label: t("FirebaseTableFilterView_Label_Search"),
        type: "text",
        operator: "search",
        value: null,
      },
      services: {
        label: t("ProvidersCollectionTableView_Filter_Label_Services"),
        type: "autocomplete",
        operator: "array-contains-any",
        value: null,
        options: SERVICE_TYPES.map((service) => ({
          label: t(`ServiceType_${service}`),
          value: service,
        })).sort((a, b) => a.label.localeCompare(b.label)),
      },
      industries: {
        label: t("ProvidersCollectionTableView_Filter_Label_Industries"),
        type: "autocomplete",
        operator: "array-contains-any",
        value: null,
        options: Object.entries(DEFAULT_ORGANIZATION_INDUSTRY_RECORD)
          .map(([value, industry]) => ({
            label: t(industry.label),
            value,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      },
      clientTypes: {
        label: t("ProvidersCollectionTableView_Filter_Label_ClientTypes"),
        type: "autocomplete",
        operator: "array-contains-any",
        value: null,
        options: Object.entries(PROVIDER_CLIENT_TYPE).map(
          ([value, { label }]) => ({
            label: t(label),
            value,
          }),
        ),
      },
      supportedLanguages: {
        label: t("ProvidersCollectionTableView_Filter_Label_Languages"),
        type: "autocomplete",
        operator: "array-contains-any",
        value: null,
        options: languageOptions,
      },
      teamModels: {
        label: t("ProvidersCollectionTableView_Filter_Label_TeamModels"),
        type: "autocomplete",
        operator: "array-contains-any",
        value: null,
        options: Object.entries(PROVIDER_TEAM_MODEL).map(
          ([value, { label }]) => ({
            label: t(label),
            value,
          }),
        ),
      },
      organizationMembersCount: {
        label: t("FirebaseTableFilterView_Label_MembersCount"),
        type: "number",
        operator: ">",
        value: null,
      },
    }),
    [languageOptions, t],
  );

  return (
    <FirebaseTableFilterContextProvider filters={filters}>
      <CollectionTableView
        collectionName="providers"
        orderBy={orderBy}
        keyField="id"
        isT={isProvider}
        columns={columns}
        getRowId={getRowId}
        {...props}
        {...getToProps}
      />
    </FirebaseTableFilterContextProvider>
  );
};

const getRowId = (provider: Provider) => provider.id;
