import { ProviderTechnology } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface ProviderTechnologyType {
  label: TranslationKey;
  caption: TranslationKey;
}

export const PROVIDER_TECHNOLOGY: Record<
  ProviderTechnology,
  ProviderTechnologyType
> = {
  SKILL_BASED_ROUTING: {
    label: "ProviderTechnology_Label_SkillBasedRouting",
    caption: "ProviderTechnology_Description_SkillBasedRouting",
  },
  CUSTOMER_INTERACTION_ANALYTICS: {
    label: "ProviderTechnology_Label_CustomerInteractionAnalytics",
    caption: "ProviderTechnology_Description_CustomerInteractionAnalytics",
  },
  AUTOMATIC_CALL_DISTRIBUTOR: {
    label: "ProviderTechnology_Label_AutomaticCallDistributor",
    caption: "ProviderTechnology_Description_AutomaticCallDistributor",
  },
  VOICE_AND_SCREEN_RECORDING: {
    label: "ProviderTechnology_Label_VoiceAndScreenRecording",
    caption: "ProviderTechnology_Description_VoiceAndScreenRecording",
  },
  VOICE_BIOMETRICS: {
    label: "ProviderTechnology_Label_VoiceBiometrics",
    caption: "ProviderTechnology_Description_VoiceBiometrics",
  },
  REPORTING_TOOLS: {
    label: "ProviderTechnology_Label_ReportingTools",
    caption: "ProviderTechnology_Description_ReportingTools",
  },
  DIALER: {
    label: "ProviderTechnology_Label_Dialer",
    caption: "ProviderTechnology_Description_Dialer",
  },
  WORKFORCE_MANAGEMENT_SOFTWARE: {
    label: "ProviderTechnology_Label_WorkforceManagementSoftware",
    caption: "ProviderTechnology_Description_WorkforceManagementSoftware",
  },
  CUSTOMER_RELATIONSHIP_MANAGEMENT: {
    label: "ProviderTechnology_Label_CustomerRelationshipManagement",
    caption: "ProviderTechnology_Description_CustomerRelationshipManagement",
  },
  CUSTOMER_FEEDBACK_TOOLS: {
    label: "ProviderTechnology_Label_CustomerFeedbackTools",
    caption: "ProviderTechnology_Description_CustomerFeedbackTools",
  },
  INTERACTIVE_VOICE_RESPONSE: {
    label: "ProviderTechnology_Label_InteractiveVoiceResponse",
    caption: "ProviderTechnology_Description_InteractiveVoiceResponse",
  },
  INTERACTIVE_VIDEO: {
    label: "ProviderTechnology_Label_InteractiveVideo",
    caption: "ProviderTechnology_Description_InteractiveVideo",
  },
  "CALL-BACK": {
    label: "ProviderTechnology_Label_CallBack",
    caption: "ProviderTechnology_Description_CallBack",
  },
  INTELLIGENT_QUEUING: {
    label: "ProviderTechnology_Label_IntelligentQueuing",
    caption: "ProviderTechnology_Description_IntelligentQueuing",
  },
  DYNAMIC_FAQ: {
    label: "ProviderTechnology_Label_DynamicFaq",
    caption: "ProviderTechnology_Description_DynamicFaq",
  },
  VOICE_BOTS: {
    label: "ProviderTechnology_Label_VoiceBots",
    caption: "ProviderTechnology_Description_VoiceBots",
  },
  CHAT_BOTS: {
    label: "ProviderTechnology_Label_ChatBots",
    caption: "ProviderTechnology_Description_ChatBots",
  },
};
