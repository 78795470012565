import { Provider } from "@snubes/snubes-types";
import { createProviderCallable } from "../callables/createProviderCallable";
import { updateProviderCallable } from "../callables/updateProviderCallable";

export async function createOrUpdateProvider(
  provider: Partial<Provider>,
  providerId?: string,
): Promise<string> {
  const { organizationId } = provider;

  if (!organizationId) {
    throw new Error("Missing organization ID in provider.");
  }

  if (providerId) {
    await updateProviderCallable({
      providerId,
      provider,
    });
    return providerId;
  }

  const response = await createProviderCallable({ organizationId, provider });
  return response.providerId;
}
