import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Stack, Typography } from "@mui/material";
import { TASK_TYPES } from "@snubes/snubes-types";
import { FC, useCallback, useMemo, useState } from "react";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { useNotifications } from "../hooks/useNotifications";
import { NotificationsAdminToggleButton } from "./NotificationsAdminToggleButton";
import { NotificationsCollectionListView } from "./NotificationsCollectionListView";
import { NotificationsDeleteAllDialog } from "./NotificationsDeleteAllDialog";
import { NotificationsMarkAllAsReadButton } from "./NotificationsMarkAllAsReadButton";

export const NotificationsAllPage: FC = () => {
  const t = useT();

  const userRole = useMyUser((state) => state.user?.role || null);
  const userType = useMyUser((state) => state.user?.userType || null);
  const userId = useMyUser((state) => state.user?.id || null);
  const organizationId = useSelectedOrganization((state) => state?.id || null);
  const [isDeleteAllDialogOpen, setIsDeleteAllDialogOpen] = useState(false);

  const onClickedOpenDeleteAllDialog = useCallback(() => {
    setIsDeleteAllDialogOpen(true);
  }, []);

  const onClickedCloseDeleteAllDialog = useCallback(() => {
    setIsDeleteAllDialogOpen(false);
  }, []);

  const notificationsArgs = useMemo(() => {
    if (userType === "ADMIN") {
      return {
        userId,
      };
    }

    return {
      organizationId,
      userId,
    };
  }, [organizationId, userId, userType]);

  const notificationsProps = useMemo(() => {
    if (userType === "ADMIN" && userId) {
      return {
        userId,
      };
    }

    if (organizationId) {
      return {
        organizationId,
        userId,
      };
    }

    return null;
  }, [organizationId, userId, userType]);

  const [, , , unreadNotificationsCount] = useNotifications({
    ...notificationsArgs,
    filters: [["hasBeenRead", "==", false]],
    limit: 1,
    withCount: true,
  });

  const [, , , notificationsWithoutTasksCount] = useNotifications({
    ...notificationsArgs,
    filters: [["type", "not-in", TASK_TYPES]],
    limit: 1,
    withCount: true,
  });

  const notificationsMarkAllAsReadButtonProps = useMemo(() => {
    if (userType === "ADMIN" && userId) {
      return {
        userId,
        isDisabled: !unreadNotificationsCount,
      };
    }

    if (organizationId) {
      return {
        organizationId,
        isAssigned: true,
        isDisabled: !unreadNotificationsCount,
      };
    }

    return null;
  }, [organizationId, unreadNotificationsCount, userId, userType]);

  const notificationsDeleteAllDialogProps = useMemo(() => {
    if (userType === "ADMIN" && userId) {
      return {
        userId,
        isDisabled: !notificationsWithoutTasksCount,
      };
    }

    if (organizationId) {
      return {
        organizationId,
        isDisabled: !notificationsWithoutTasksCount,
      };
    }

    return null;
  }, [organizationId, notificationsWithoutTasksCount, userId, userType]);

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer>
        <Stack py={5} spacing={3}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={3}
          >
            <Typography variant="h3">
              {t("NotificationsAllPage_TabLabel_AllNotifications")}
            </Typography>

            {notificationsMarkAllAsReadButtonProps && (
              <NotificationsMarkAllAsReadButton
                {...notificationsMarkAllAsReadButtonProps}
              />
            )}
            {notificationsDeleteAllDialogProps && (
              <Button
                variant="text"
                color="error"
                size="small"
                disabled={!notificationsWithoutTasksCount}
                startIcon={<DeleteIcon />}
                onClick={onClickedOpenDeleteAllDialog}
              >
                {t("NotificationsDeleteAllDialog_Button_DeleteAll")}
              </Button>
            )}
            {userRole === "USER_ADMIN" && <NotificationsAdminToggleButton />}
          </Stack>
          {notificationsProps && (
            <NotificationsCollectionListView {...notificationsProps} />
          )}
        </Stack>
        {notificationsDeleteAllDialogProps && (
          <NotificationsDeleteAllDialog
            {...notificationsDeleteAllDialogProps}
            isOpen={isDeleteAllDialogOpen}
            onClose={onClickedCloseDeleteAllDialog}
          />
        )}
      </FullHeightContainer>
    </>
  );
};
