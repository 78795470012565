import { superstructResolver } from "@hookform/resolvers/superstruct";
import { DeepPartial } from "@snubes/snubes-types";
import { useCallback, useEffect } from "react";
import { DefaultValues, FieldValues, useForm } from "react-hook-form";
import { Struct } from "superstruct";
import { WizardState } from "../types/WizardState";
import { WizardStore } from "../types/WizardStore";

interface Args<TFormValues extends FieldValues, TDoc> {
  store: WizardStore<WizardState<TDoc>>;
  formKey: string;
  FormValuesStruct: Struct<TFormValues>;
  toFormValues: (
    doc?: Partial<TDoc>,
    storeFormValues?: TFormValues,
  ) => TFormValues;
  toDoc: (
    formValues: DeepPartial<TFormValues>,
    doc?: Partial<TDoc>,
  ) => Partial<TDoc>;
}

export function useWizardForm<TFormValues extends FieldValues, TDoc>(
  args: Args<TFormValues, TDoc>,
) {
  const { formKey, FormValuesStruct, toFormValues, store } = args;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toDoc = useCallback(args.toDoc, []);

  const { doc } = store.getState();
  const storeFormValues = store.getState().getFormValues<TFormValues>(formKey);
  const form = useForm<TFormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: toFormValues(
      doc,
      storeFormValues,
    ) as DefaultValues<TFormValues>,
  });

  const onSubmit = useCallback(
    (formValues: DeepPartial<TFormValues>) => {
      store
        .getState()
        .setFormState(formKey, formValues, toDoc(formValues, doc));
    },
    [store, formKey, toDoc, doc],
  );

  useEffect(() => {
    return form.watch((formValues) => {
      onSubmit(formValues as unknown as DeepPartial<TFormValues>);
    }).unsubscribe;
  }, [onSubmit, form]);

  return {
    onSubmit,
    ...form,
  };
}
