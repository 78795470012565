import { superstructResolver } from "@hookform/resolvers/superstruct";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { EmailStruct } from "@snubes/snubes-types";
import { sendPasswordResetEmail } from "firebase/auth";
import { FC, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Infer, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { auth } from "../../Firebase/consts/FIREBASE";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useT } from "../../Translation/hooks/useT";
import { useAuthEmail } from "../hooks/useAuthEmail";
import { AuthLayoutView } from "./AuthLayoutView";

const FormValuesStruct = type({
  email: EmailStruct,
});

type FormValues = Infer<typeof FormValuesStruct>;

export const AuthForgotPasswordPage: FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const t = useT();
  const { control, handleSubmit, watch } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: { email: useAuthEmail.getState().email },
  });

  useEffect(() => {
    const subscription = watch(
      ({ email }) => email && useAuthEmail.getState().update(email),
    );
    return subscription.unsubscribe;
  }, [watch]);

  const onForgotPassword = useCallback(async (formValues: FormValues) => {
    try {
      setIsSubmitting(true);
      await sendPasswordResetEmail(auth, formValues.email);
      setResetLinkSent(true);
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsSubmitting(false);
    }
  }, []);

  const isLoading = isSubmitting;

  if (resetLinkSent) {
    return (
      <AuthLayoutView>
        <HeaderConfig variant="NONE" />
        <Stack
          spacing={3}
          p={4}
          bgcolor="background.paper"
          border={1}
          borderColor="divider"
        >
          <Typography variant="h3">
            {t("AuthForgotPasswordView_Success_Headline")}
          </Typography>
          <Box display="flex" gap=".25em" mt={2} mb={3}>
            <Typography>
              {t("AuthForgotPasswordView_Success_Subline")}
            </Typography>
          </Box>

          <Button variant="contained" href="/login">
            {t("AuthForgotPasswordView_Success_Button")}
          </Button>
        </Stack>
      </AuthLayoutView>
    );
  }

  return (
    <AuthLayoutView>
      <HeaderConfig variant="NONE" />
      <Stack
        spacing={3}
        p={4}
        bgcolor="background.paper"
        border={1}
        borderColor="divider"
      >
        <Box>
          <Typography variant="h3">
            {t("AuthForgotPasswordView_Headline_ForgotPassword")}
          </Typography>
          <Box display="flex" gap=".25em" mt={2}>
            <Typography>
              {t("AuthForgotPasswordView_Subline_EnterEmail")}
            </Typography>
          </Box>
        </Box>

        <form onSubmit={handleSubmit(onForgotPassword)}>
          <Stack spacing={3}>
            <ControlledTextField
              required
              fullWidth
              name="email"
              type="email"
              label="Email"
              control={control}
              disabled={isLoading}
            />
            <Button type="submit" variant="contained" disabled={isLoading}>
              {t("AuthForgotPasswordView_Button_Reset")}
            </Button>

            <Link href="/login">
              {t("AuthForgotPasswordView_Button_BackToLogin")}
            </Link>
          </Stack>
        </form>
      </Stack>
    </AuthLayoutView>
  );
};
