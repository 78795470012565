import { TenderApplication } from "@snubes/snubes-types";
import { TENDER_APPLICATION_DESCRIPTION_SEPARATOR } from "../consts/TENDER_APPLICATION_DESCRIPTION_SEPARATOR";

export function splitTenderApplicationDescription(
  tenderApplication: TenderApplication,
) {
  const splitDescription = tenderApplication.description?.split(
    TENDER_APPLICATION_DESCRIPTION_SEPARATOR,
  );

  if (!splitDescription) {
    return;
  }

  return {
    description: splitDescription[0],
    priceOffer: splitDescription[1],
  };
}
