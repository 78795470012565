import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import {
  isProjectCallCategory,
  ProjectCallCategory,
} from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import toast from "react-hot-toast";
import { v4 } from "uuid";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  isFormDirty: boolean;
  callCategories: ProjectCallCategory[];
  onSetCallCategories: (newCallCategories: ProjectCallCategory[]) => void;
}

export const ProjectCallCategoriesFormHeaderView: FC<Props> = (props) => {
  const t = useT();
  const { callCategories, isFormDirty, onSetCallCategories } = props;

  const onClickCopyCallCategoriesToClipboard = useCallback(async () => {
    if (!callCategories.length) return;

    const csv = callCategories
      .map((category) => JSON.stringify(category.name))
      .join("\n");

    try {
      await navigator.clipboard.writeText(csv);
      toast.success(
        t(
          "ProjectCallCategoriesFormHeaderView_Toast_CopiedCallCategoriesToClipboard",
        ),
      );
    } catch (error) {
      handleError(error).log();
    }
  }, [callCategories, t]);

  const onClickPasteCallCategoriesFromClipboard = useCallback(async () => {
    try {
      const csv = await navigator.clipboard.readText();
      const parsedCallCategories: ProjectCallCategory[] = csv
        .trim()
        .split("\n")
        .flatMap((name) => {
          if (!name) {
            return [];
          }
          const value: ProjectCallCategory = {
            id: v4(),
            name: JSON.parse(name) as string,
          };
          if (isProjectCallCategory(value)) {
            return [value];
          }
          return [];
        });

      const uniqueNewCallCategory = parsedCallCategories.filter(
        (parsedCallCategory) =>
          callCategories.every(
            (callCategory) => callCategory.name !== parsedCallCategory.name,
          ),
      );

      onSetCallCategories(uniqueNewCallCategory.concat(callCategories));
    } catch (error) {
      handleError(error).log();
    }
  }, [onSetCallCategories, callCategories]);

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between">
      <Typography variant="h3">
        {t("ProjectCallCategoriesFormHeaderView_Heading_CallCategories")}
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Box>
          <Tooltip
            title={t(
              "ProjectCallCategoriesFormHeaderView_Tooltip_PasteCallCategoriesFromClipboard",
            )}
          >
            <IconButton onClick={onClickPasteCallCategoriesFromClipboard}>
              <ContentPasteGoIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {!!callCategories.length && (
          <Tooltip
            title={t(
              isFormDirty
                ? "ProjectCallCategoriesFormHeaderView_Tooltip_DisabledCopyCallCategoriesToClipboard"
                : "ProjectCallCategoriesFormHeaderView_Tooltip_CopyCallCategoriesToClipboard",
            )}
          >
            <Box>
              <IconButton
                disabled={isFormDirty}
                onClick={onClickCopyCallCategoriesToClipboard}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};
