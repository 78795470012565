import { Autocomplete, TextField } from "@mui/material";
import { Call } from "@snubes/snubes-types";
import { FC, useCallback, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { useMembers } from "../../Members/hooks/useMembers";
import { useProjectContext } from "../../Projects/hooks/useProjectContext";
import { useT } from "../../Translation/hooks/useT";
import { getUserName } from "../../Users/helpers/getUserName";
import { assignCallCallable } from "../callables/assignCallCallable";

interface Props {
  call: Call;
}

export const CallAssignTaskView: FC<Props> = (props) => {
  const { call } = props;
  const { project } = useProjectContext();
  const [isAssigning, setIsAssigning] = useState(false);
  const [unsortedMembers, isLoading, error] = useMembers(
    project?.providerOrganizationId,
  );
  const members = unsortedMembers.sort((a, b) =>
    getUserName(a).localeCompare(getUserName(b)),
  );

  const t = useT();

  const onChange = useCallback(
    async (userId: string | null) => {
      try {
        setIsAssigning(true);
        await assignCallCallable({
          callId: call.id,
          userId,
        });
      } catch (error) {
        handleError(error).logAnd().toast();
      } finally {
        setIsAssigning(false);
      }
    },
    [call.id],
  );

  const isDisabled = isLoading || isAssigning;

  return (
    <Autocomplete
      filterSelectedOptions
      value={call.assigneeUserId || null}
      options={members.map((member) => member.id)}
      getOptionLabel={(memberId) => {
        const member = members.find((m) => m.id === memberId);
        return member ? getUserName(member) : "";
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ width: 180 }}
          label={t("CallAssignTaskView_Label_AssignedTo")}
          disabled={isDisabled}
          error={!!error}
          helperText={error?.message}
          size="small"
        />
      )}
      onChange={(_, value) => onChange(value)}
    />
  );
};
