import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Avatar,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { FC, Fragment } from "react";
import { CollapseExpandView } from "../../Common/views/CollapseExpandView";
import { useT } from "../../Translation/hooks/useT";
import { SFTP_INTERRUPTION_REASONS } from "../consts/SFTP_INTERRUPTION_REASONS";
import { ConnectorConfigInterruptionReason } from "../types/ConnectorConfigInterruptionReason";

interface Props {
  title: string;
  statusErrorMessage: string;
  pathPrefix?: string;
  interruptionReasons?: ConnectorConfigInterruptionReason[];
}

export const ConnectorConfigErrorView: FC<Props> = (props) => {
  const t = useT();
  const {
    title,
    statusErrorMessage,
    pathPrefix,
    interruptionReasons: interruptionReason,
  } = props;

  return (
    <Paper variant="outlined">
      <CollapseExpandView
        defaultExpanded
        iconColor="error"
        headerContent={
          <Stack
            color="error.main"
            direction="row"
            spacing={2}
            alignItems="center"
          >
            <WarningIcon />
            <Typography variant="h5">{title}</Typography>
          </Stack>
        }
      >
        <Stack py={5}>
          <Typography px={4} color="error.main" variant="subtitle2">
            {statusErrorMessage}
          </Typography>
        </Stack>
        {!!interruptionReason?.length && (
          <>
            <Divider />
            <Stack px={3} py={4} spacing={3}>
              {interruptionReason.map((reason, index) => (
                <Fragment key={reason.title}>
                  <Stack direction="row" spacing={3}>
                    <Avatar
                      sx={{
                        width: 30,
                        height: 30,
                        bgcolor: "primary.main",
                        fontSize: 30 / 2,
                      }}
                    >
                      {String(index + 1)}
                    </Avatar>
                    <Stack flex={1} spacing={3}>
                      <Typography mt={1} variant="h4">
                        {t(reason.title)}
                      </Typography>
                      <Typography color="text.600" variant="caption2">
                        {t(reason.description)}
                      </Typography>
                    </Stack>
                    {reason.to && pathPrefix && (
                      <Button
                        size="tiny"
                        endIcon={<ChevronRightIcon />}
                        href={`${pathPrefix}/${reason.to}`}
                      >
                        {t(
                          "ConnectorConfigSftpErrorView_Button_GotoProjectMappings",
                        )}
                      </Button>
                    )}
                  </Stack>
                  {index !== SFTP_INTERRUPTION_REASONS.length - 1 && (
                    <Divider />
                  )}
                </Fragment>
              ))}
            </Stack>
          </>
        )}
      </CollapseExpandView>
    </Paper>
  );
};
