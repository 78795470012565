import { isProvider, Provider } from "@snubes/snubes-types";
import { getDataFromDocumentSnapshot } from "../../Firebase/helpers/getDataFromDocumentSnapshot";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useDocumentsOnce } from "../../Firebase/hooks/useDocumentsOnce";

type Result = [
  providers: Provider[] | undefined,
  isLoading: boolean,
  error: Error | undefined,
];

export const useProvidersById = (providerIds: string[]): Result => {
  const refs = providerIds
    .slice()
    .sort()
    .map((providerId) => getDocRef("providers", providerId));
  const [snapshots, isLoading, error] = useDocumentsOnce(refs);
  const providers = snapshots?.flatMap((snapshot) => {
    const value = getDataFromDocumentSnapshot(isProvider, snapshot);

    return value ? [value] : [];
  });

  return [providers, isLoading, error];
};
