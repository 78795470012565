import { t } from "i18next";
import { TIME_UNIT_RECORD } from "../consts/TIME_UNIT_RECORD";
import { TimeDiffTranslationFormat } from "../types/TimeDiffTranslationFormat";

export const AVG_DAYS_IN_MONTH = 30.437;

interface Args {
  seconds: number;
  format?: TimeDiffTranslationFormat;
}

export function getDiffTranslation(args: Args): string {
  return args.format
    ? getDiffTranslationWithFormat(args.seconds, args.format)
    : getDefaultDiffTranslation(args.seconds);
}

function getDiffTranslationWithFormat(
  seconds: number,
  format: TimeDiffTranslationFormat,
): string {
  const translations = getDiffTranslations(seconds, format);
  return Object.values(translations).join(" • ");
}

function getDefaultDiffTranslation(s: number) {
  const { months, days, hours, minutes, seconds } = getDiffTranslations(s, {
    months: "long",
    days: "long",
    hours: "long",
    minutes: "long",
    seconds: "long",
  });

  if (months) return months;
  if (days) return days;
  if (hours) return hours;
  if (minutes) return minutes;
  return seconds || "";
}

type DiffTranslations = Partial<
  Record<keyof TimeDiffTranslationFormat, string>
>;

function getDiffTranslations<T extends TimeDiffTranslationFormat>(
  seconds: number,
  format: T,
): DiffTranslations {
  let restSeconds = seconds;
  const months = Math.floor(seconds / 60 / 60 / 24 / AVG_DAYS_IN_MONTH);
  if (format.months) {
    restSeconds -= months * Math.ceil(60 * 60 * 24 * AVG_DAYS_IN_MONTH);
  }
  const days = Math.floor(restSeconds / 60 / 60 / 24);
  if (format.days) {
    restSeconds -= days * (60 * 60 * 24);
  }
  const hours = Math.floor(restSeconds / 60 / 60);
  if (format.hours) {
    restSeconds -= hours * (60 * 60);
  }
  const minutes = Math.floor(restSeconds / 60);
  if (format.minutes) {
    restSeconds -= minutes * 60;
  }
  restSeconds = Math.floor(restSeconds);

  const translations: DiffTranslations = {};

  if (format.months && months > 0) {
    translations.months = formatTimeUnit("months", format.months, months);
  }
  if (format.days && (days > 0 || translations.months)) {
    translations.days = formatTimeUnit("days", format.days, days);
  }
  if (format.hours && (hours > 0 || translations.days)) {
    translations.hours = formatTimeUnit("hours", format.hours, hours);
  }
  if (format.minutes && (minutes > 0 || translations.hours)) {
    translations.minutes = formatTimeUnit("minutes", format.minutes, minutes);
  }
  if (format.seconds) {
    translations.seconds = formatTimeUnit(
      "seconds",
      format.seconds,
      restSeconds,
    );
  }
  // Always include "0 least_significant_unit" if no other unit is included.
  if (Object.keys(translations).length === 0) {
    const lastFormatKey = Object.keys(format).pop() as
      | keyof TimeDiffTranslationFormat
      | undefined;
    if (lastFormatKey) {
      const length = format[lastFormatKey];
      if (length) {
        translations[lastFormatKey] = formatTimeUnit(lastFormatKey, length, 0);
      }
    }
  }

  return translations;
}

function formatTimeUnit(
  name: keyof TimeDiffTranslationFormat,
  length: NonNullable<TimeDiffTranslationFormat["seconds"]>,
  value: number,
) {
  const record = TIME_UNIT_RECORD[name];
  return length === "short"
    ? `${value}${t(record.short)}`
    : t(record.long, { count: value });
}
