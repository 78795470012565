import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Organization, OrganizationType } from "@snubes/snubes-types";
import {
  FC,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useState,
} from "react";
import { ENVIRONMENT } from "../../Application/consts/ENVIRONMENT";
import { ExternalLinkView } from "../../Common/views/ExternalLinkView";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { useSetUserSelectedOrganizationId } from "../../Users/hooks/useSetUserSelectedOrganizationId";
import { useSetUserType } from "../../Users/hooks/useSetUserType";

interface Props {
  organization: Organization;
}

export const AdminOrganizationsCollectionTableActionsView: FC<Props> = (
  props,
) => {
  const { organization } = props;
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const t = useT();
  const [setUserType] = useSetUserType();
  const [setUserSelectedOrganizationId, isLoadingUserSelectedOrganizationId] =
    useSetUserSelectedOrganizationId();

  const onClickAssumeOrganization: MouseEventHandler<HTMLLIElement> =
    useCallback(
      async (event) => {
        event.preventDefault();

        await setUserSelectedOrganizationId(organization.id);

        const userType = useMyUser.getState().user?.userType;

        // If the user has a user type that is not included in the organization, set first organization type
        // as the user type. That way when navigating to home, the user will be redirected to the correct home page.
        if (
          userType &&
          organization.types?.length &&
          !organization.types.includes(userType as OrganizationType)
        ) {
          await setUserType(organization.types[0]);
        }
        setMenuAnchor(null);
      },
      [
        organization.id,
        organization.types,
        setUserSelectedOrganizationId,
        setUserType,
      ],
    );

  return (
    <>
      <IconButton
        onClick={(event) => {
          event.preventDefault();
          setMenuAnchor(event.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!menuAnchor}
        onClose={(event: MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          setMenuAnchor(null);
        }}
      >
        <MenuItem
          onClick={onClickAssumeOrganization}
          disabled={isLoadingUserSelectedOrganizationId}
        >
          {t(
            "AdminOrganizationsCollectionTableActionsView_Button_AssumeOrganization",
          )}
        </MenuItem>
        {organization.hubspotCompanyId && (
          <MenuItem
            sx={{
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            <ExternalLinkView
              variant="link"
              href={`${ENVIRONMENT.hubspot.companyBaseUrl}/${organization.hubspotCompanyId}`}
              tooltip={t("OrganizationDetailsPage_Tooltip_ViewInHubspot", {
                id: organization.hubspotCompanyId,
              })}
              sx={(theme) => ({
                fontSize: "inherit",
                "& .MuiSvgIcon-root": {
                  fontSize: theme.typography.h6.fontSize,
                },
              })}
            >
              {t("OrganizationDetailsPage_Button_ViewInHubspot")}
            </ExternalLinkView>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
