import { Container, Stack, Typography } from "@mui/material";
import { ChannelType, ServiceType } from "@snubes/snubes-types";
import { FC, useEffect, useRef } from "react";
import { number, optional, type } from "superstruct";
import { toLocaleMinutes } from "../../Common/helpers/toLocaleMinutes";
import { ControlledSlider } from "../../Form/views/ControlledSlider";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { TENDER_CHANNEL_TYPE } from "../consts/TENDER_CHANNEL_TYPE";
import { findTenderServiceChannel } from "../helpers/findTenderServiceChannel";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const SECOND_IN_MILLISECONDS = 1000;
const MINUTE_IN_SECONDS = 60;
const DEFAULT_HANDLING_TIME_SECONDS = 3 * MINUTE_IN_SECONDS;

const FormValuesStruct = type({
  avgHandlingTimeInSeconds: optional(number()),
});

type Props = WizardFormProps<{
  serviceType: ServiceType;
  channelType: ChannelType;
}>;

export const TenderWizardChannelAhtForm: FC<Props> = (props) => {
  const { serviceType, channelType } = props.formProps;
  const t = useT();
  const formRef = useRef<HTMLFormElement>(null);
  const channelTypeLabel = t(TENDER_CHANNEL_TYPE[channelType].label);
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => {
      const avgHandlingTimeInSeconds =
        findTenderServiceChannel(serviceType, channelType, tender)
          ?.avgHandlingTimeInSeconds ?? DEFAULT_HANDLING_TIME_SECONDS;

      return {
        avgHandlingTimeInSeconds,
      };
    },
    toDoc: (formValues, tender) => {
      const channel = findTenderServiceChannel(
        serviceType,
        channelType,
        tender,
      );

      if (channel) {
        channel.avgHandlingTimeInSeconds = formValues.avgHandlingTimeInSeconds;
      }

      return {
        services: tender?.services,
        isReviewed: {
          ...tender?.isReviewed,
          services: false,
        },
      };
    },
  });

  /**
   *  Submit the form initially to get the default value
   *  for avgHandlingTimeInSeconds saved into the firestore.
   */
  useEffect(() => {
    formRef.current?.requestSubmit();
  }, []);

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardChannelAhtForm_Title")}
        </Typography>
        <Typography variant="subtitle2" mb={4}>
          {t("TenderWizardChannelAhtForm_Subtitle")}
        </Typography>
        <Typography variant="body2" color="text.500" mb={6}>
          {t("TenderWizardChannelAhtForm_Label", {
            channelType: channelTypeLabel,
          })}
        </Typography>
        <Stack spacing={3}>
          <ControlledSlider
            control={control}
            name="avgHandlingTimeInSeconds"
            min={1 * MINUTE_IN_SECONDS}
            max={25 * MINUTE_IN_SECONDS}
            step={10}
            marks={[
              {
                label: t("TenderWizardChannelAhtForm_Slider_Label", { min: 1 }),
                value: MINUTE_IN_SECONDS,
              },
              {
                label: t("TenderWizardChannelAhtForm_Slider_Label", {
                  min: 25,
                }),
                value: 25 * MINUTE_IN_SECONDS,
              },
            ]}
            valueLabelDisplay="on"
            valueLabelFormat={(value) =>
              t("TenderWizardChannelAhtForm_Slider_Label", {
                min: toLocaleMinutes(value * SECOND_IN_MILLISECONDS),
              })
            }
            sx={{
              "& > .MuiSlider-markLabel": {
                transform: "translateX(0)",
              },
              "& > .MuiSlider-markLabel ~ .MuiSlider-markLabel": {
                transform: "translateX(-100%)",
              },
            }}
          />
        </Stack>
      </Container>
    </form>
  );
};
