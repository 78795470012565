import { ChannelType, ServiceType, Tender } from "@snubes/snubes-types";

export function findTenderServiceChannel(
  serviceType: ServiceType,
  channelType: ChannelType,
  tender?: Partial<Tender>,
) {
  return tender?.services
    ?.find((s) => s.type === serviceType)
    ?.channels?.find((c) => c.type === channelType);
}
