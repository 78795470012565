import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { BudgetType } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface TenderBudgetType {
  label: TranslationKey;
  caption?: TranslationKey;
  Icon?: OverridableComponent<SvgIconTypeMap>;
}

export const TENDER_BUDGET_TYPE: Record<BudgetType, TenderBudgetType> = {
  FIXED: {
    label: "TenderBudgetType_Label_Fixed",
    caption: "TenderBudgetType_Caption_Fixed",
    Icon: AttachMoneyIcon,
  },
  FLEXIBLE: {
    label: "TenderBudgetType_Label_Flexible",
    caption: "TenderBudgetType_Caption_Flexible",
    Icon: MoneyOffIcon,
  },
};
