import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, Paper, Stack, Typography } from "@mui/material";
import { FirebaseFilter, Project } from "@snubes/snubes-types";
import { FC } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useMyOrganizations } from "../../Organizations/hooks/useMyOrganizations";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";
import { AdminProjectsCollectionTableView } from "./AdminProjectsCollectionTableView";

interface Props {
  headline: TranslationKey;
  filters: FirebaseFilter<Project>[];
  hasCreateButton?: boolean;
}

export const AdminProjectsPage: FC<Props> = (props) => {
  const { headline, filters, hasCreateButton } = props;
  const t = useT();

  const hasPermission = useHasPermission();
  const hasOrganization = useMyOrganizations(
    (state) => !!state.organizations?.length,
  );

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer>
        <Stack py={5} spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ minHeight: 52 }}
          >
            <Typography variant="h2">{t(headline)}</Typography>
            {hasCreateButton &&
              hasOrganization &&
              hasPermission("CAN_CREATE_PROJECT") && (
                <Button
                  href="/projects/create"
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                >
                  {t("AdminProjectsPage_Button_CreateProject")}
                </Button>
              )}
          </Stack>
          <Paper>
            <AdminProjectsCollectionTableView filters={filters} />
          </Paper>
        </Stack>
      </FullHeightContainer>
    </>
  );
};
