import { Call, Project } from "@snubes/snubes-types";

export function isCallProcessing(
  call: Call,
  project?: Project,
  isFeatureFlagCallCategoriesEnabled?: boolean, // TODO: Remove this argument after removing feature flag!
) {
  const hasQuestions = !!project?.questions?.length;
  const hasCategories = !!project?.callCategories?.length;

  const processes = [
    call.voicefileProcess,
    call.transcriptsProcess,
    call.summaryProcess,
    ...(hasCategories && isFeatureFlagCallCategoriesEnabled
      ? [call.categoryProcess]
      : []),
    ...(hasQuestions ? [call.questionAnswersProcess] : []),
  ];

  return processes.some((process) => {
    if (process?.status === "PROCESSING") return true;
    if (process?.status === "REQUESTED") return true;
  });
}
