/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FC, useCallback } from "react";
import { useStore } from "zustand";
import { selectFirebaseTableFilter } from "../helpers/selectFirebaseTableFilter";
import { useFirebaseTableFilterStore } from "../hooks/useFirebaseTableFilters";

interface Props {
  filterName: string;
}

export const FirebaseTableFilterSelectView: FC<Props> = (props) => {
  const { filterName } = props;

  const filterStore = useFirebaseTableFilterStore();
  const filterLabel = useStore(
    filterStore,
    useCallback(
      selectFilter(filterName, (filter) => filter.label),
      [filterName],
    ),
  );
  const options = useStore(
    filterStore,
    useCallback(
      selectFilter(filterName, (filter) => filter.options),
      [filterName],
    ),
  );
  const value = useStore(
    filterStore,
    useCallback(
      selectFilter(filterName, (filter) => filter.value || null),
      [filterName],
    ),
  );

  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    filterStore.getState().setFilterValue("select", filterName, value || null);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`input-label-${filterName}`}>{filterLabel}</InputLabel>
      <Select
        value={value || ""}
        onChange={handleChange}
        label={filterLabel}
        labelId={`input-label-${filterName}`}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const selectFilter = selectFirebaseTableFilter("select");
