import { isInvitation } from "@snubes/snubes-types";
import { limit as limitFn, query, where } from "firebase/firestore";
import { useEffect, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { handleError } from "../../Common/helpers/handleError";
import { auth } from "../../Firebase/consts/FIREBASE";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useCollectionCountOnce } from "../../Firebase/hooks/useCollectionCountOnce";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

interface Args {
  limit?: number;
  withCount?: boolean;
}

export function useMyInvitations(args: Args = {}) {
  const { limit, withCount } = args;
  const [authState] = useAuthState(auth);
  const countRef = useRef<number | null>(null);

  const [invitations, isLoading, error] = useValidCollectionData(
    authState?.email
      ? query(
          getCollectionRef("invitations"),
          where("email", "==", authState?.email),
          where("acceptedAt", "==", null),
          ...(limit ? [limitFn(limit)] : []),
        )
      : null,
    isInvitation,
  );

  const [totalCount = 0, , , reload] = useCollectionCountOnce(
    withCount && authState?.email
      ? query(
          getCollectionRef("invitations"),
          where("email", "==", authState.email),
          where("acceptedAt", "==", null),
        )
      : null,
  );

  // The count query is reloaded whenever the data changes.
  useEffect(() => {
    if (
      (withCount && countRef.current !== null) ||
      countRef.current !== totalCount
    ) {
      reload().catch((error) => handleError(error).log());
    }
    countRef.current = totalCount;
  }, [totalCount, invitations, reload, withCount]);

  return [invitations, isLoading, error, totalCount] as const;
}
