import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  Tabs,
} from "@mui/material";
import {
  Call,
  CallTranscript,
  CallTranscriptSource,
} from "@snubes/snubes-types";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import type WaveSurfer from "wavesurfer.js";
import { handleError } from "../../Common/helpers/handleError";
import { VoteButtons } from "../../Common/views/VoteButtons";
import { CollectionEmptyView } from "../../Firebase/views/CollectionEmptyView";
import { useT } from "../../Translation/hooks/useT";
import { updateCallIsTranscriptHelpfulCallable } from "../callables/updateCallIsTranscriptHelpful";
import { CALL_PARTICIPANT_ROLE_RECORD } from "../consts/CALL_PARTICIPANT_ROLE_RECORD";
import { CALL_TRANSCRIPT_SOURCE_RECORD } from "../consts/CALL_TRANSCRIPT_SOURCE_RECORD";
import { getCallTranscripts } from "../helpers/getCallTranscripts";
import { getCallTranscriptSources } from "../helpers/getCallTranscriptSources";
import { hasSentiments } from "../helpers/hasSentiments";
import { CallAudioMetadataView } from "./CallAudioMetadataView";
import { CallAudioPlayerView } from "./CallAudioPlayerView";
import { CallSentimentView } from "./CallSentimentView";
import { CallTranscriptTableRow } from "./CallTranscriptTableRow";

const COLLAPSED_TRANSCRIPTS_COUNT = 4;

type TabValue = "TRANSCRIPT" | "METADATA";

interface Props {
  call: Call;
}

export const CallTranscriptView: FC<Props> = (props) => {
  const { call } = props;

  const transcriptSources = getCallTranscriptSources(call);
  const [selectedTranscriptSource, setSelectedTranscriptSource] =
    useState<CallTranscriptSource>(transcriptSources[0]);
  const transcripts = useMemo(
    () => getCallTranscripts(call, selectedTranscriptSource) || [],
    [call, selectedTranscriptSource],
  );

  const playerRef = useRef<WaveSurfer | null>(null);
  const t = useT();
  const [isLoadingAudio, setIsLoadingAudio] = useState(
    !!call.isVoicefileAvailable,
  );
  const [activeTranscriptId, setActiveTranscriptId] = useState<
    string | undefined
  >(undefined);
  const [tabValue, setTabValue] = useState<TabValue>("TRANSCRIPT");
  const [voicefileDurationInSeconds, setVoicefileDurationInSeconds] =
    useState<number>(0);
  const [isCollapsed, setIsCollapsed] = useState(
    transcripts.length > COLLAPSED_TRANSCRIPTS_COUNT,
  );
  const collapsedTranscripts = isCollapsed
    ? transcripts.slice(0, COLLAPSED_TRANSCRIPTS_COUNT)
    : transcripts;

  const setActiveTranscript = useCallback((transcript?: CallTranscript) => {
    setActiveTranscriptId(transcript?.id);
    if (!transcript) return;
    const seconds = Math.round(transcript.startOffsetMilliseconds / 1000);
    playerRef.current?.setTime(seconds);
  }, []);

  const updateIsTranscriptHelpful = useCallback(
    async (value: boolean) => {
      try {
        await updateCallIsTranscriptHelpfulCallable({
          callId: call.id,
          isTranscriptHelpful: value,
          transcriptSource: selectedTranscriptSource,
        });
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [call.id, selectedTranscriptSource],
  );

  const copyTranscriptsToClipboard = useCallback(
    async (transcripts: CallTranscript[]) => {
      const lines = transcripts.reduce<string[]>((result, transcript) => {
        result.push(
          `${t(
            CALL_PARTICIPANT_ROLE_RECORD[transcript.participantRole].label,
          )}: ${transcript.content}`,
        );

        return result;
      }, []);

      await navigator.clipboard.writeText(lines.join("\n"));
      toast.success(t("CallTranscriptView_Toast_CopiedToClipboard"));
    },
    [t],
  );

  // Automatically show all transcripts if the active transcript is not visible.
  useEffect(() => {
    if (
      isCollapsed &&
      activeTranscriptId &&
      !collapsedTranscripts.find(
        (transcript) => transcript.id === activeTranscriptId,
      )
    ) {
      setIsCollapsed(false);
    }
  }, [activeTranscriptId, collapsedTranscripts, isCollapsed]);

  return (
    <Paper>
      <CallAudioPlayerView
        playerRef={playerRef}
        call={call}
        isLoadingAudio={isLoadingAudio}
        voicefileDurationInSeconds={voicefileDurationInSeconds}
        setIsLoadingAudio={setIsLoadingAudio}
        setActiveTranscriptId={setActiveTranscriptId}
        setVoicefileDurationInSeconds={setVoicefileDurationInSeconds}
      />
      {hasSentiments(call) && (
        <Stack px={5} pb={5} spacing={3}>
          <CallSentimentView
            participantRole="AGENT"
            call={call}
            voicefileDurationInSeconds={voicefileDurationInSeconds}
            activeTranscriptId={activeTranscriptId}
            setActiveTranscript={setActiveTranscript}
          />
          <CallSentimentView
            participantRole="CUSTOMER"
            call={call}
            voicefileDurationInSeconds={voicefileDurationInSeconds}
            activeTranscriptId={activeTranscriptId}
            setActiveTranscript={setActiveTranscript}
          />
        </Stack>
      )}
      <Stack direction="row" justifyContent="space-between">
        <Stack justifyContent="flex-end">
          <Tabs
            value={tabValue}
            onChange={(_, next: TabValue) => setTabValue(next)}
          >
            <Tab
              value={"TRANSCRIPT" satisfies TabValue}
              label={t("CallTranscriptView_Tab_Transcript")}
            />
            <Tab
              value={"METADATA" satisfies TabValue}
              label={t("CallTranscriptView_Tab_Metadata")}
            />
          </Tabs>
        </Stack>
        {!!transcripts.length && (
          <Stack direction="row" spacing={2} px={3} py={2}>
            {transcriptSources.length > 1 && (
              <FormControl size="small">
                <InputLabel id={"transcript-source-select-label"}>
                  {t("CallTranscriptView_Select_Label_Source")}
                </InputLabel>
                <Select<CallTranscriptSource>
                  label={t("CallTranscriptView_Select_Label_Source")}
                  labelId={"transcript-source-select-label"}
                  value={selectedTranscriptSource}
                  sx={{ minWidth: 110 }}
                  onChange={(e) =>
                    setSelectedTranscriptSource(
                      e.target.value as CallTranscriptSource,
                    )
                  }
                >
                  {transcriptSources.map((source) => (
                    <MenuItem key={source} value={source}>
                      {t(CALL_TRANSCRIPT_SOURCE_RECORD[source].label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Button
              size="small"
              variant="outlined"
              startIcon={<ContentCopyIcon />}
              onClick={() => copyTranscriptsToClipboard(transcripts)}
            >
              {t("CallTranscriptView_Button_CopyTranscriptToClipboard")}
            </Button>
          </Stack>
        )}
      </Stack>
      <Divider />
      {tabValue === "TRANSCRIPT" && (
        <>
          {!!transcripts.length && (
            <Table>
              <TableBody>
                {collapsedTranscripts.map((transcript) => (
                  <CallTranscriptTableRow
                    key={transcript.id}
                    transcript={transcript}
                    activeTranscriptId={activeTranscriptId}
                    setActiveTranscript={setActiveTranscript}
                  />
                ))}
              </TableBody>
            </Table>
          )}
          {!transcripts.length && <CollectionEmptyView />}
          {!!transcripts.length && (
            <Stack
              direction="row"
              p={3}
              alignItems="flex-end"
              justifyContent="space-between"
            >
              <VoteButtons
                setValue={updateIsTranscriptHelpful}
                votes={call.isTranscriptHelpful}
              />
              {isCollapsed && (
                <Button
                  size="small"
                  endIcon={<ExpandMoreIcon />}
                  onClick={() => setIsCollapsed(false)}
                >
                  {t("CallTranscriptView_Button_ViewAll")}
                </Button>
              )}
            </Stack>
          )}
        </>
      )}
      {tabValue === "METADATA" && (
        <CallAudioMetadataView
          voicefileDurationInSeconds={voicefileDurationInSeconds}
          call={call}
          playerRef={playerRef}
        />
      )}
    </Paper>
  );
};
