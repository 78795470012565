import { Container, Stack, Typography } from "@mui/material";
import { StorageFile, StorageFileStruct } from "@snubes/snubes-types";
import {
  TENDER_SUPPORT_DOCUMENTS_TYPE,
  TenderSupportDocumentsTypeStruct,
} from "@snubes/snubes-types/types/TenderSupportDocumentsType";
import { FC, useState } from "react";
import { toast } from "react-hot-toast";
import { nullable, record, string, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { FileListView } from "../../Common/views/FileListView";
import { FileUploadView } from "../../Common/views/FileUploadView";
import { ControlledRadioButtons } from "../../Form/views/ControlledRadioButtons";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { deleteTenderSupportDocumentCallable } from "../callables/deleteTenderSupportDocumentCallable";
import { TENDER_SUPPORT_DOCUMENTS_TYPE_RECORD } from "../consts/TENDER_SUPPORT_DOCUMENTS_TYPE_RECORD";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  supportDocumentsType: nullable(TenderSupportDocumentsTypeStruct),
  supportDocuments: nullable(record(string(), StorageFileStruct)),
});

const SUPPORT_DOCUMENTS_TYPE_OPTIONS = TENDER_SUPPORT_DOCUMENTS_TYPE.map(
  (supportDocumentsType) => ({
    ...TENDER_SUPPORT_DOCUMENTS_TYPE_RECORD[supportDocumentsType],
    value: supportDocumentsType,
  }),
);

export const TenderWizardUploadDocumentsForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const userId = useMyUser((state) => state.user?.id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const tenderId = useTenderWizardStore((state) => state.doc?.id);
  const supportDocuments = useTenderWizardStore((state) => {
    if (state.doc?.supportDocuments) {
      return Object.values(state.doc?.supportDocuments);
    }
  });
  const { control, handleSubmit, onSubmit, watch } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => ({
      supportDocumentsType: tender?.supportDocumentsType || null,
      supportDocuments: tender?.supportDocuments || null,
    }),
    toDoc: (formValues, tender) => ({
      supportDocumentsType: formValues.supportDocumentsType || undefined,
      isReviewed: {
        ...tender?.isReviewed,
        uploadDocuments: false,
      },
    }),
  });

  const handleDeleteDocument = async (doc: StorageFile) => {
    if (!tenderId) {
      return;
    }
    try {
      setIsSubmitting(true);
      await deleteTenderSupportDocumentCallable({
        tenderId,
        fileHash: doc.fileHash,
      });
      toast.success(
        t("TenderMyApplicationPage_Toast_DeletedFile", { name: doc.fileName }),
      );
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsSubmitting(false);
    }
  };

  const withDocuments = watch("supportDocumentsType") === "WITH_DOCUMENTS";

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardUploadDocumentsForm_Title")}
        </Typography>
        <Typography variant="body2" color="text.500" mb={4}>
          {t("TenderWizardUploadDocumentsForm_Label")}
        </Typography>
        <Stack spacing={3}>
          <ControlledRadioButtons
            control={control}
            name="supportDocumentsType"
            options={SUPPORT_DOCUMENTS_TYPE_OPTIONS}
            gridSpacing={4}
            gridItemXs={6}
            disabled={isSubmitting}
          />

          {withDocuments && userId && tenderId && (
            <Stack spacing={3}>
              <FileUploadView
                storagePath={`/user-uploads/tender-support-documents/${tenderId}`}
                storageFileMetadata={{
                  userId,
                  tenderId,
                }}
                allowMultiple
                acceptedFileTypes={{
                  "application/pdf": [".pdf"],
                }}
                disabled={isSubmitting}
              />
              {supportDocuments && (
                <FileListView
                  showDeleteAction
                  files={supportDocuments}
                  onClickDeleteFile={handleDeleteDocument}
                  disabled={isSubmitting}
                />
              )}
            </Stack>
          )}
        </Stack>
      </Container>
    </form>
  );
};
