import { FieldValueStruct } from "@snubes/snubes-types";
import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import { object } from "superstruct";

export function deepReplaceLogUpdate(update?: unknown) {
  if (!object().is(update)) {
    return update;
  }

  const clonedObj: Record<string, unknown> = {};

  Object.entries(update)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .forEach(([key, value]) => {
      if (value instanceof Timestamp) {
        clonedObj[key] = DateTime.fromMillis(value.toMillis()).toLocaleString(
          DateTime.DATETIME_FULL_WITH_SECONDS,
        );
      } else if (FieldValueStruct.is(value)) {
        switch (value.type) {
          case "DELETE":
            clonedObj[key] = "DELETE()";
            break;
          case "INCREMENT":
            clonedObj[key] = `INCREMENT(${value.value})`;
            break;
          case "ARRAY_UNION":
            clonedObj[key] = `ARRAY_UNION(${value.values.join(", ")})`;
            break;
          case "ARRAY_REMOVE":
            clonedObj[key] = `ARRAY_REMOVE(${value.values.join(", ")})`;
            break;
        }
      } else {
        clonedObj[key] = deepReplaceLogUpdate(value);
      }
    });

  return clonedObj;
}
