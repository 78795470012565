import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton } from "@mui/material";
import { FC, useCallback } from "react";
import toast from "react-hot-toast";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  text: string;
}

export const CollectionTableCopyToClipboardCell: FC<Props> = (props) => {
  const t = useT();

  const onClickCopyToClipboard = useCallback(
    async (text: string) => {
      await navigator.clipboard.writeText(text);
      toast.success(
        t("CollectionTableCopyToClipboardCell_Toast_CopiedToClipboard"),
      );
    },
    [t],
  );

  return (
    <>
      {props.text}
      <IconButton
        size="small"
        sx={{ ml: 1 }}
        onClick={(event) => {
          event.preventDefault();
          if (props.text) {
            void onClickCopyToClipboard(props.text);
          }
        }}
      >
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </>
  );
};
