import { CallProcessRequestKind } from "@snubes/snubes-types";
import { CallProcessKey } from "../types/CallProcessKey";

interface CallProcessRequestKindRecordValue {
  processKey: CallProcessKey;
}

export const CALL_PROCESS_REQUEST_KIND_RECORD = {
  VOICEFILE: {
    processKey: "voicefileProcess",
  },
  TRANSCRIPTS: {
    processKey: "transcriptsProcess",
  },
  SUMMARY: {
    processKey: "summaryProcess",
  },
  CATEGORY: {
    processKey: "categoryProcess",
  },
  QUESTION_ANSWERS: {
    processKey: "questionAnswersProcess",
  },
} satisfies Partial<
  Record<CallProcessRequestKind, CallProcessRequestKindRecordValue>
>;
