import { Tender, TenderStatus } from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { useMemo } from "react";

/**
 * @deprecated TODO: Will be superfluous with correct tender end handling
 */
export const useTenderStatus = (tender?: Tender): TenderStatus | undefined => {
  return useMemo(() => {
    if (!tender) {
      return;
    }
    const nowMs = DateTime.utc().startOf("day").toMillis();
    const applicationDeadline = tender?.timetable?.applicationDeadlineAt;

    if (
      applicationDeadline &&
      DateTime.fromISO(applicationDeadline)?.toMillis() < nowMs
    ) {
      return "ENDED";
    }

    return tender.status;
  }, [tender]);
};
