import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import WorkIcon from "@mui/icons-material/Work";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ProviderClientFocus } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface ProviderClientFocusRecordValue {
  label: TranslationKey;
  caption: TranslationKey;
  Icon?: OverridableComponent<SvgIconTypeMap>;
}

export const PROVIDER_CLIENT_FOCUS_RECORD: Record<
  ProviderClientFocus,
  ProviderClientFocusRecordValue
> = {
  B2B: {
    label: "ProviderClientFocusRecord_Label_B2B",
    caption: "ProviderClientFocusRecord_Caption_B2B",
    Icon: WorkIcon,
  },
  B2C: {
    label: "ProviderClientFocusRecord_Label_B2C",
    caption: "ProviderClientFocusRecord_Caption_B2C",
    Icon: FaceOutlinedIcon,
  },
};
