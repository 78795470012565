import { Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { InvitationsView } from "../../Invitations/views/InvitationsView";
import { InviteButton } from "../../Invitations/views/InviteButton";
import { MembersCollectionTableView } from "../../Members/views/MembersCollectionTableView";
import { T } from "../../Translation/views/T";
import { useOrganizationContext } from "../hooks/useOrganizationContext";

export const OrganizationMembersPage: FC = () => {
  const { organizationId } = useParams<"organizationId">();
  const hasPermission = useHasPermission();
  const { organization } = useOrganizationContext();

  if (!organization) return null;

  return (
    <FullHeightContainer>
      <Stack py={5} spacing={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h2">
            <T k="OrganizationView_Members" />
          </Typography>
          {hasPermission("CAN_WRITE_INVITATION", {
            organizationId,
          }) &&
            !organization.isDeleted && (
              <InviteButton organization={organization} />
            )}
        </Stack>
        <Paper>
          <MembersCollectionTableView organizationId={organization.id} />
        </Paper>
        {hasPermission("CAN_READ_INVITATIONS", {
          organizationId: organization.id,
        }) && <InvitationsView organizationId={organization.id} />}
      </Stack>
    </FullHeightContainer>
  );
};
