import {
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  SvgIconTypeMap,
  SxProps,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { TranslationKey } from "../../Translation/types/TranslationKey";
import { RadioButton } from "./RadioButton";

type Option = {
  value: string | number | boolean;
  label: TranslationKey;
  caption?: TranslationKey;
  Icon?: OverridableComponent<SvgIconTypeMap>;
};

interface Props<TFields extends FieldValues, TData extends Option> {
  control: Control<TFields>;
  name: FieldPath<TFields>;
  label?: string;
  options: TData[];
  gridItemXs?: number;
  gridItemSm?: number;
  gridItemMd?: number;
  gridItemLg?: number;
  gridItemXl?: number;
  gridSpacing?: number;
  sx?: SxProps;
  disabled?: boolean;
  required?: boolean;
}

export function ControlledRadioButtons<
  TFields extends FieldValues,
  TData extends Option,
>(props: Props<TFields, TData>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field, fieldState }) => (
        <FormControl
          fullWidth
          error={!!fieldState.error}
          disabled={props.disabled}
        >
          <FormLabel component="legend">{props.label}</FormLabel>
          <FormGroup>
            <Grid container spacing={props.gridSpacing}>
              {props.options?.map((option: TData, index: number) => (
                <Grid
                  item
                  key={index}
                  xs={props.gridItemXs}
                  sm={props.gridItemSm}
                  md={props.gridItemMd}
                  lg={props.gridItemLg}
                  xl={props.gridItemXl}
                >
                  <RadioButton
                    {...option}
                    sx={props.sx}
                    disabled={props.disabled}
                    checked={field.value === option.value}
                    onChange={() => field.onChange(option.value)}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </FormControl>
      )}
    />
  );
}
