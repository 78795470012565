import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Tender } from "@snubes/snubes-types";
import { FC, MouseEvent, useCallback, useState } from "react";
import { toast } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { restoreTenderCallable } from "../callables/restoreTenderCallable";
import { TenderDeleteDialog } from "./TenderDeleteDialog";

interface Props {
  tender: Tender;
}

export const TendersCollectionTableActionsView: FC<Props> = (props) => {
  const { tender } = props;
  const t = useT();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const hasPermission = useHasPermission();

  const restoreTender = useCallback(async () => {
    try {
      if (isSubmitting) return;
      setIsSubmitting(true);
      await restoreTenderCallable({
        tenderId: tender.id,
      });

      toast.success(
        t("AdminTendersCollectionTableActionsView_Toast_RestoredTender"),
      );
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsSubmitting(false);
    }
  }, [isSubmitting, t, tender.id]);

  const showEditButton =
    !tender.isDeleted &&
    tender.status !== "ENDED" &&
    hasPermission("CAN_UPDATE_TENDER_PROVIDERS", {
      organizationId: tender.organizationId,
    });

  const showRestoreButton =
    tender.isDeleted &&
    hasPermission("CAN_RESTORE_TENDER", {
      organizationId: tender.organizationId,
    });

  const showDeleteButton =
    !tender.isDeleted &&
    hasPermission("CAN_DELETE_TENDER", {
      organizationId: tender.organizationId,
    });

  if (!showEditButton && !showRestoreButton && !showDeleteButton) {
    return null;
  }

  return (
    <>
      <IconButton
        disabled={isSubmitting}
        onClick={(event) => {
          event.preventDefault();
          setMenuAnchor(event.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!menuAnchor}
        onClose={(event: MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          setMenuAnchor(null);
        }}
      >
        {showEditButton && (
          <MenuItem component={NavLink} to={`/tenders/${tender.id}/create`}>
            {t("TendersCollectionTableActionsView_Button_EditTender")}
          </MenuItem>
        )}
        {showRestoreButton && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              void restoreTender();
            }}
          >
            {t("AdminTendersCollectionTableActionsView_Button_RestoreTender")}
          </MenuItem>
        )}
        {showDeleteButton && (
          <MenuItem
            onClick={(event) => {
              event.stopPropagation();
              setIsDeleteDialogOpen(true);
            }}
          >
            {t("AdminTendersCollectionTableActionsView_Button_DeleteTender")}
          </MenuItem>
        )}
      </Menu>
      {isDeleteDialogOpen && (
        <TenderDeleteDialog
          close={() => setIsDeleteDialogOpen(false)}
          tender={tender}
          isOpen
        />
      )}
    </>
  );
};
