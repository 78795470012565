import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CategoryIcon from "@mui/icons-material/Category";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import PhonePausedIcon from "@mui/icons-material/PhonePaused";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import { Stack } from "@mui/material";
import { Call } from "@snubes/snubes-types";
import { FC } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { toLocaleDecimal } from "../../Common/helpers/toLocaleDecimal";
import { toLocaleMinutes } from "../../Common/helpers/toLocaleMinutes";
import { SidebarView } from "../../Common/views/SidebarView";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useProjectContext } from "../../Projects/hooks/useProjectContext";
import { useT } from "../../Translation/hooks/useT";
import { CALL_DIRECTION_RECORD } from "../consts/CALL_DIRECTION_RECORD";
import { CALL_SIDEBAR_WIDTH } from "../consts/CALL_SIDEBAR_WIDTH";
import { getCallCategoryName } from "../helpers/getCallCategoryName";
import { CallCategorySelect } from "./CallCategorySelect";
import { CallSidebarAccordion } from "./CallSidebarAccordion";
import { CallSidebarInfoView } from "./CallSidebarInfoView";
import { CallSidebarItemView } from "./CallSidebarItemView";
import { CallSidebarItemWithChildrenView } from "./CallSidebarItemWithChildrenView";

interface Props {
  call: Call;
}

export const CallSidebarView: FC<Props> = (props) => {
  const { call } = props;
  const t = useT();
  const { project } = useProjectContext();
  const hasPermission = useHasPermission();
  const organizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );

  if (!project) return null;

  const callCategoryName = getCallCategoryName({ call, project });
  const canWriteCall = hasPermission("CAN_WRITE_CALL", { organizationId });

  const { SourcePhoneNumberIcon, DestinationPhoneNumberIcon } =
    call.sourcePhoneNumber && call.destinationPhoneNumber
      ? CALL_DIRECTION_RECORD[call.direction || "ANY"]
      : CALL_DIRECTION_RECORD.ANY;

  return (
    <SidebarView width={CALL_SIDEBAR_WIDTH}>
      <Stack bgcolor="background.paper" flex={1} mt="-1px">
        <CallSidebarAccordion
          label={t("CallSidebarView_Accordion_CallDetails")}
        >
          {canWriteCall && !!project.callCategories?.length && (
            <CallSidebarItemWithChildrenView Icon={CategoryIcon}>
              <CallCategorySelect
                call={call}
                callCategories={project.callCategories}
              />
            </CallSidebarItemWithChildrenView>
          )}
          {!canWriteCall && callCategoryName && (
            <CallSidebarItemView
              Icon={CategoryIcon}
              label={t("CallSidebarView_ItemLabel_Category")}
              value={callCategoryName}
            />
          )}
          <CallSidebarItemView
            Icon={TimelapseIcon}
            label={t("CallSidebarView_ItemLabel_Duration")}
            value={t("Common_Mins", {
              minutes: toLocaleMinutes(call.durationInSeconds * 1000),
            })}
          />
          {call.direction && (
            <CallSidebarItemView
              Icon={CALL_DIRECTION_RECORD[call.direction].Icon}
              label={t("CallSidebarView_ItemLabel_Direction")}
              value={t(CALL_DIRECTION_RECORD[call.direction].label)}
            />
          )}
          {call.sourcePhoneNumber && (
            <CallSidebarItemView
              Icon={SourcePhoneNumberIcon}
              label={t("CallSidebarView_ItemLabel_SourcePhoneNumber")}
              value={call.sourcePhoneNumber}
              tooltip={
                call.sourcePhoneNumber.length > 22
                  ? call.sourcePhoneNumber
                  : undefined
              }
              valueSx={{ textOverflow: "ellipsis", overflowX: "hidden" }}
            />
          )}
          {call.destinationPhoneNumber && (
            <CallSidebarItemView
              Icon={DestinationPhoneNumberIcon}
              label={t("CallSidebarView_ItemLabel_DestinationPhoneNumber")}
              value={call.destinationPhoneNumber}
              tooltip={
                call.destinationPhoneNumber.length > 22
                  ? call.destinationPhoneNumber
                  : undefined
              }
              valueSx={{ textOverflow: "ellipsis", overflowX: "hidden" }}
            />
          )}
          <CallSidebarItemView
            Icon={HourglassFullIcon}
            label={t("CallSidebarView_ItemLabel_WaitingTime")}
            value={t("Common_Seconds", {
              seconds: toLocaleDecimal(call.waitingTimeInSeconds),
            })}
          />
          <CallSidebarItemView
            Icon={PhonePausedIcon}
            label={t("CallSidebarView_ItemLabel_HoldTime")}
            value={t("Common_Seconds", {
              seconds: toLocaleDecimal(call.holdTimeInSeconds),
            })}
          />
          {call.preWorkTimeInSeconds !== undefined && (
            <CallSidebarItemView
              Icon={PlayCircleFilledIcon}
              label={t("CallSidebarView_ItemLabel_PreCallWorks")}
              value={t("Common_Seconds", {
                seconds: toLocaleDecimal(call.preWorkTimeInSeconds),
              })}
            />
          )}
          {call.postWorkTimeInSeconds !== undefined && (
            <CallSidebarItemView
              Icon={AssignmentTurnedInIcon}
              label={t("CallSidebarView_ItemLabel_PostCallWorks")}
              value={t("Common_Seconds", {
                seconds: toLocaleDecimal(call.postWorkTimeInSeconds),
              })}
            />
          )}
          {call.totalTimeInSeconds !== undefined && (
            <CallSidebarItemView
              Icon={AccessTimeFilledIcon}
              label={t("CallSidebarView_ItemLabel_TotalTime")}
              value={t("Common_Mins", {
                minutes: toLocaleMinutes(call.totalTimeInSeconds * 1000),
              })}
            />
          )}
        </CallSidebarAccordion>

        <CallSidebarAccordion
          label={t("CallSidebarView_Accordion_ProjectDetails")}
        >
          <CallSidebarInfoView
            label={t("CallSidebarView_InfoLabel_ProjectName")}
            value={project.name}
            href={`/projects/${project.id}`}
          />
          <CallSidebarInfoView
            label={t("CallSidebarView_InfoLabel_ProviderName")}
            value={project.providerOrganizationName}
            href={`/organizations/${project.providerOrganizationId}`}
          />
          <CallSidebarInfoView
            label={t("CallSidebarView_InfoLabel_ClientName")}
            value={project.clientOrganizationName || "-"}
            href={
              project.clientOrganizationId &&
              `/organizations/${project.clientOrganizationId}`
            }
          />
          <CallSidebarInfoView
            label={t("CallSidebarView_InfoLabel_SupportAgent")}
            value={call.agentName || "-"}
          />
          <CallSidebarInfoView
            label={t("CallSidebarView_InfoLabel_Assignee")}
            value={call.assigneeName || "-"}
          />
        </CallSidebarAccordion>
      </Stack>
    </SidebarView>
  );
};
