import {
  TENDER_APPLICATION_CLIENT_REJECTION_REASONS,
  TENDER_APPLICATION_PROVIDER_REJECTION_REASONS,
  UserType,
} from "@snubes/snubes-types";
import { TenderApplicationRejectionReason } from "@snubes/snubes-types/types/TenderApplicationRejectionReason";
import { useCallback, useMemo } from "react";
import { useT } from "../../Translation/hooks/useT";
import { TENDER_APPLICATION_REJECTION_REASON_RECORD } from "../consts/TENDER_APPLICATION_REJECTION_REASON_RECORD";

interface Args {
  userType: UserType;
}

export function useTenderApplicationRejectionReasonOptions(args: Args) {
  const t = useT();
  const { userType } = args;

  const getOptions = useCallback(
    (rejectionReasons: ReadonlyArray<TenderApplicationRejectionReason>) => {
      return rejectionReasons
        .map((rejectionReason) => ({
          label: t(
            TENDER_APPLICATION_REJECTION_REASON_RECORD[rejectionReason].label,
          ),
          value: rejectionReason,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    [t],
  );

  return useMemo(() => {
    switch (userType) {
      case "QM":
        return [];
      case "CLIENT":
        return getOptions(TENDER_APPLICATION_CLIENT_REJECTION_REASONS);
      case "PROVIDER":
        return getOptions(TENDER_APPLICATION_PROVIDER_REJECTION_REASONS);
      case "ADMIN":
        return getOptions([
          ...new Set([
            ...TENDER_APPLICATION_CLIENT_REJECTION_REASONS,
            ...TENDER_APPLICATION_PROVIDER_REJECTION_REASONS,
          ]),
        ]);
    }
  }, [getOptions, userType]);
}
