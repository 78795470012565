import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
} from "@mui/material";
import { Project } from "@snubes/snubes-types";
import { DateTime } from "luxon";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { requestReprocessCallsCallable } from "../../Calls/callables/requestReprocessCallsCallable";
import { CALL_REPROCESS_REQUEST_KIND_RECORD } from "../../Calls/consts/CALL_REPROCESS_REQUEST_KIND_RECORD";
import { CallReprocessRequestKind } from "../../Calls/types/CallReprocessRequestKind";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  anchorEl?: HTMLElement;
  project: Project;
  fromDate: DateTime | null;
  toDate: DateTime | null;
  closeMenu: () => void;
}

export const ProjectDetailsReprocessCallsMenuView: FC<Props> = (props) => {
  const t = useT();
  const hasPermission = useHasPermission();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isIncludeSuccessfulCallsSet, setIncludeSuccessfulCalls] =
    useState(false);

  const onClickHandler = useCallback(
    async (kind: CallReprocessRequestKind) => {
      try {
        setIsSubmitting(true);

        const { affectedCallsCount } = await requestReprocessCallsCallable({
          projectId: props.project.id,
          fromDate: props.fromDate?.toMillis() || null,
          toDate: props.toDate?.toMillis() || null,
          includeSuccessfulCalls: isIncludeSuccessfulCallsSet,
          kind,
        });

        setIsSubmitting(false);
        props.closeMenu();
        toast.success(
          t("ProjectDetailsRegenerateCallsMenuView_Toast_SuccessMessage", {
            count: affectedCallsCount,
          }),
        );
      } catch (error) {
        handleError(error).logAnd().toast();
        setIsSubmitting(false);
      }
    },
    [isIncludeSuccessfulCallsSet, props, t],
  );

  return (
    <Menu
      anchorEl={props.anchorEl}
      open={!!props.anchorEl}
      onClose={props.closeMenu}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      keepMounted={false}
    >
      <MenuList>
        {Object.values(CALL_REPROCESS_REQUEST_KIND_RECORD).map((kind) => (
          <MenuItem
            key={kind.type}
            disabled={isSubmitting}
            onClick={() => onClickHandler(kind.type)}
          >
            <ListItemIcon>
              <RestartAltIcon />
            </ListItemIcon>
            <ListItemText>{t(kind.label)}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>

      {hasPermission("CAN_REQUEST_PROCESS_ALL_CALLS", {
        organizationId: props.project.providerOrganizationId,
      }) && (
        <>
          <Divider />

          <Box px={3} pt={2}>
            <Tooltip
              title={t(
                "ProjectDetailsRegenerateCallsMenuView_Tooltip_IncludeSuccessful",
              )}
            >
              <FormControlLabel
                label={t(
                  "ProjectDetailsRegenerateCallsMenuView_Label_IncludeSuccessful",
                )}
                control={
                  <Checkbox
                    disabled={isSubmitting}
                    value={isIncludeSuccessfulCallsSet}
                    onChange={(e) =>
                      setIncludeSuccessfulCalls(e.target.checked)
                    }
                  />
                }
              />
            </Tooltip>
          </Box>
        </>
      )}
    </Menu>
  );
};
