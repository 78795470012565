import { FC } from "react";
import { Outlet } from "react-router-dom";
import { NavTab } from "../../Common/types/NavTab";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";

const TABS: NavTab[] = [
  {
    label: "AdminProjectsLayoutView_SecondaryHeaderLabel_Projects",
    route: "all",
  },
  {
    label: "AdminProjectsLayoutView_SecondaryHeaderLabel_ArchivedProjects",
    route: "archived",
  },
  {
    label: "AdminProjectsLayoutView_SecondaryHeaderLabel_DeletedProjects",
    route: "deleted",
  },
];

export const AdminProjectsLayoutView: FC = () => {
  return (
    <>
      <SecondaryHeaderView pathPrefix="/admin/projects" tabs={TABS} />
      <Outlet />
    </>
  );
};
