import { superstructResolver } from "@hookform/resolvers/superstruct";
import { Button, Divider, Paper, Stack, Typography } from "@mui/material";
import { ConnectorConfig } from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Infer, number, string, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { updateConnectorConfigCallable } from "../callables/updateConnectorConfigCallable";
import { DEFAULT_BACKFILL_DAYS } from "../consts/DEFAULT_BACKFILL_DAYS";
import { getBackfillDate } from "../helpers/getBackfillDate";
import { getDaysToBackfill } from "../helpers/getDaysToBackfill";
import { ConnectorConfigBackfillDaysFormFieldsView } from "./ConnectorConfigBackfillDaysFormFieldsView";
import { ConnectorConfigErrorView } from "./ConnectorConfigErrorView";
import { ConnectorConfigInoplaHelpView } from "./ConnectorConfigInoplaHelpView";

interface Props {
  connectorConfig: ConnectorConfig & { platform: "INOPLA" };
}

const FormValuesStruct = type({
  authKey: string(),
  name: string(),
  backfillDate: string(),
  backfillDays: number(),
});

type FormValues = Infer<typeof FormValuesStruct>;

export const ConnectorConfigInoplaEditForm: FC<Props> = (props) => {
  const { connectorConfig } = props;
  const t = useT();
  const { organizationId, connectorConfigId } = useParams<
    "organizationId" | "connectorConfigId"
  >();

  const form = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      name: connectorConfig.name,
      authKey: connectorConfig.authKey,
      backfillDate: getBackfillDate(DEFAULT_BACKFILL_DAYS),
      backfillDays: DEFAULT_BACKFILL_DAYS,
    },
  });

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (!organizationId) {
          throw new Error("No organization selected");
        }
        if (!connectorConfigId) {
          throw new Error("No connectorConfig selected");
        }
        await updateConnectorConfigCallable({
          connectorConfigId,
          organizationId,
          connectorConfig: {
            name: formValues.name,
            authKey: formValues.authKey,
            projectMappings: connectorConfig.projectMappings,
            backfillDays: getDaysToBackfill(formValues.backfillDate),
          },
        });
        toast.success(t("ConnectorConfigForm_Toast_Update_Success"));
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [organizationId, connectorConfigId, connectorConfig.projectMappings, t],
  );

  const { isSubmitting } = form.formState;

  return (
    <>
      {connectorConfig.status === "INTERRUPTED" &&
        connectorConfig.statusErrorMessage && (
          <ConnectorConfigErrorView
            statusErrorMessage={connectorConfig.statusErrorMessage}
            title={t(
              "ConnectorConfigInoplaEditForm_Title_ConnectionInterrupted",
            )}
          />
        )}
      <Stack component={Paper} variant="outlined" p={4} spacing={5}>
        <Typography variant="h3">
          {t("ConnectorConfigInoplaEditForm_Heading_UpdateConnection")}
        </Typography>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Stack spacing={5}>
            <ControlledTextField
              autoComplete="off"
              required
              fullWidth
              name="name"
              label={t("ConnectorConfigInoplaEditForm_InputLabel_Name")}
              control={form.control}
              disabled={isSubmitting}
            />
            <Divider />

            <Typography textTransform="uppercase" variant="subtitle1">
              {t("ConnectorConfigInoplaEditForm_SubHeading_Credentials")}
            </Typography>
            <ConnectorConfigInoplaHelpView
              title={t("ConnectorConfigInoplaEditForm_Title_HavingTrouble")}
            />

            <ControlledTextField
              required
              autoComplete="off"
              fullWidth
              name="authKey"
              label={t("ConnectorConfigInoplaEditForm_InputLabel_ApiKey")}
              control={form.control}
              disabled={isSubmitting}
            />

            <FormProvider {...form}>
              <ConnectorConfigBackfillDaysFormFieldsView />
            </FormProvider>

            <Button type="submit" variant="contained" disabled={isSubmitting}>
              {t("ConnectorConfigInoplaEditForm_Button_Update")}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};
