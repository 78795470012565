import { Button, Stack } from "@mui/material";
import { signOut } from "firebase/auth";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../Firebase/consts/FIREBASE";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { HEADER_HEIGHT } from "../consts/HEADER_HEIGHT";
import { HeaderLanguageMenuButton } from "./HeaderLanguageMenuButton";
import { HeaderLanguageMenuView } from "./HeaderLanguageMenuView";
import { HeaderSpacerView } from "./HeaderSpacerView";

export const HeaderWithCenteredLogoView: FC = () => {
  const isLoggedIn = useMyUser((state) => !!state.user);
  const t = useT();
  const navigate = useNavigate();
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] =
    useState<HTMLElement>();

  const onClickLogout = async () => {
    await signOut(auth);
    navigate("/");
  };

  return (
    <>
      <Stack
        position="fixed"
        direction="row"
        alignItems="center"
        justifyContent="center"
        top={0}
        left={0}
        right={0}
        height={HEADER_HEIGHT}
        bgcolor="background.paper"
        px={3}
        zIndex={5}
        borderBottom={1}
        borderColor="divider"
      >
        <Link to="/">
          <img src="/assets/logo.svg" alt="Snubes Logo" />
        </Link>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            position: "absolute",
            right: 24,
          }}
        >
          <HeaderLanguageMenuButton
            onSetLanguageMenuAnchorEl={setLanguageMenuAnchorEl}
          />
          {isLoggedIn && (
            <Button onClick={onClickLogout}>{t("Common_Logout")}</Button>
          )}
        </Stack>
      </Stack>
      <HeaderSpacerView />
      <HeaderLanguageMenuView
        anchorEl={languageMenuAnchorEl}
        closeMenu={() => setLanguageMenuAnchorEl(undefined)}
      />
    </>
  );
};
