import { Stack } from "@mui/material";
import { Tender } from "@snubes/snubes-types";
import { FC } from "react";
import { TenderApplicationsDeadlineRowView } from "./TenderApplicationsDeadlineRowView";

interface Props {
  tender: Tender;
}

export const TenderApplicationsDeadlineView: FC<Props> = (props) => {
  const { tender } = props;

  return (
    <Stack spacing={1} alignItems="end">
      {tender.timetable?.confirmationDeadlineAt && (
        <TenderApplicationsDeadlineRowView
          date={tender.timetable.confirmationDeadlineAt}
          translationKeyLabel="TenderApplicationsDeadlineView_ConfirmationDeadlineAt"
        />
      )}
      {tender.timetable?.applicationDeadlineAt && (
        <TenderApplicationsDeadlineRowView
          date={tender.timetable.applicationDeadlineAt}
          translationKeyLabel="TenderApplicationsDeadlineView_ApplicationDeadlineAt"
        />
      )}
      {tender.timetable?.estimatedProjectStartAt && (
        <TenderApplicationsDeadlineRowView
          date={tender.timetable.estimatedProjectStartAt}
          translationKeyLabel="TenderApplicationsDeadlineView_EstimatedProjectStartAt"
        />
      )}
    </Stack>
  );
};
