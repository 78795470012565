import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreError,
  getDoc,
} from "firebase/firestore";
import { useCallback, useEffect } from "react";
import type { LoadingHook } from "react-firebase-hooks/firestore/dist/util";
import { useAreFirestoreRefsEqual } from "./useAreFirestoreRefsEqual";
import { useIsMounted } from "./useIsMounted";
import { useLoadingValue } from "./useLoadingValue";

export type DocumentHook<T = DocumentData> = LoadingHook<
  DocumentSnapshot<T>[],
  FirestoreError
>;

export type DocumentOnceHook<T = DocumentData> = [
  ...DocumentHook<T>,
  () => Promise<void>,
];

export const useDocumentsOnce = <T = DocumentData>(
  docRefs: DocumentReference<T>[] | null,
  options?: {},
): DocumentOnceHook<T> => {
  const { error, loading, reset, setError, setValue, value } = useLoadingValue<
    DocumentSnapshot<T>[],
    FirestoreError
  >();
  const isMounted = useIsMounted();
  const refs = useAreFirestoreRefsEqual<DocumentReference<T>>(docRefs, reset);

  const loadData = useCallback(
    async (
      references: DocumentReference<T>[] | null | undefined,
      _options?: {},
    ) => {
      if (!references) {
        setValue(undefined);
        return;
      }

      // Here we can also use getDocFromCache or getDocFromServer
      const get = getDoc;

      try {
        const values = await Promise.all(
          references.map((reference) => get(reference)),
        );

        if (isMounted) {
          setValue(values);
        }
      } catch (error) {
        if (isMounted) {
          setError(error as FirestoreError);
        }
      }
    },
    [isMounted, setError, setValue],
  );

  const reloadData = useCallback(
    () => loadData(refs.current, options),
    [loadData, options, refs],
  );

  useEffect(() => {
    if (!refs.current) {
      setValue(undefined);
      return;
    }

    void loadData(refs.current, options);
  }, [loadData, options, refs, setValue]);

  return [value, loading, error, reloadData];
};
