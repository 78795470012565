import { Stack, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useTheme } from "@mui/material/styles";
import { FC, PropsWithChildren } from "react";
import { AvatarWithIconView } from "../../Common/views/AvatarWithIconView";

interface Props extends PropsWithChildren {
  Icon: OverridableComponent<SvgIconTypeMap>;
}

export const CallSidebarItemWithChildrenView: FC<Props> = (props) => {
  const { Icon, children } = props;
  const { palette } = useTheme();

  return (
    <Stack
      direction="row"
      spacing={3}
      p={3}
      sx={{
        ":not(:last-child)": {
          borderBottom: `1px solid ${palette.divider}}`,
        },
      }}
      alignItems={"center"}
    >
      <AvatarWithIconView
        color="primary"
        bgcolor="grey.100"
        Icon={Icon}
        size={42}
      />
      <Stack
        flex={1}
        justifyContent="center"
        spacing={1}
        sx={{
          overflowX: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};
