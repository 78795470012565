import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { Call, ProjectCallCategory } from "@snubes/snubes-types";
import { FC, useCallback, useMemo, useState } from "react";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { updateCallCategoryIdCallable } from "../callables/updateCallCategoryIdCallable";

interface Props {
  call: Call;
  callCategories: ProjectCallCategory[];
}

export const CallCategorySelect: FC<Props> = (props: Props) => {
  const { callCategories, call } = props;
  const t = useT();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const options = useMemo(
    () => [
      ...callCategories
        .map((callCategory) => ({
          value: callCategory.id,
          label: callCategory.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ],
    [callCategories],
  );

  const onChange = useCallback(
    async (value: string) => {
      try {
        setIsSubmitting(true);
        await updateCallCategoryIdCallable({
          callId: call.id,
          callCategoryId: value === NONE ? null : value,
        });
      } catch (error) {
        handleError(error).logAnd().toast();
      } finally {
        setIsSubmitting(false);
      }
    },
    [call.id],
  );

  const isDisabled = isSubmitting;

  return (
    <Stack pt={2}>
      <FormControl disabled={isDisabled}>
        <InputLabel>{t("CallCategorySelect_Label_CallCategory")}</InputLabel>
        <Select
          size="small"
          value={call.categoryId || NONE}
          label={t("CallCategorySelect_Label_CallCategory")}
          onChange={(event) => onChange(event.target.value)}
        >
          <MenuItem value={NONE}>
            <em>{t("Common_None")}</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

const NONE = "NONE";
