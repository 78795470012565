import { FC } from "react";
import { Outlet } from "react-router-dom";
import { NavTab } from "../../Common/types/NavTab";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";
import {
  ADMIN_ORGANIZATIONS_ROUTE_RECORD,
  AdminOrganizationRoute,
} from "../consts/ADMIN_ORGANIZATIONS_ROUTE_RECORD";

const TABS: NavTab<AdminOrganizationRoute>[] = [
  {
    label: ADMIN_ORGANIZATIONS_ROUTE_RECORD["all"].label,
    route: "all",
  },
  {
    label: ADMIN_ORGANIZATIONS_ROUTE_RECORD["clients"].label,
    route: "clients",
  },
  {
    label: ADMIN_ORGANIZATIONS_ROUTE_RECORD["providers"].label,
    route: "providers",
  },
  {
    label: ADMIN_ORGANIZATIONS_ROUTE_RECORD["deleted"].label,
    route: "deleted",
  },
];

export const AdminOrganizationsLayoutView: FC = () => {
  return (
    <>
      <SecondaryHeaderView pathPrefix="/admin/organizations" tabs={TABS} />
      <Outlet />
    </>
  );
};
