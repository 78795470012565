import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ConnectorStatusLogStatus } from "@snubes/snubes-types";
import { StatusColor } from "../../Common/types/StatusColor";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface StatusRecordValue {
  color: StatusColor;
  label: TranslationKey;
  Icon: OverridableComponent<SvgIconTypeMap>;
}

export const CONNECTOR_STATUS_LOG_STATUS_RECORD: Record<
  ConnectorStatusLogStatus,
  StatusRecordValue
> = {
  ERROR: {
    color: "error",
    label: "ConnectorConfigStatusLogStatusChip_Label_Error",
    Icon: ReportProblemIcon,
  },
  INFO: {
    color: "default",
    label: "ConnectorConfigStatusLogStatusChip_Label_Info",
    Icon: InfoIcon,
  },
  SUCCESS: {
    color: "secondary",
    label: "ConnectorConfigStatusLogStatusChip_Label_Success",
    Icon: CheckCircleIcon,
  },
};
