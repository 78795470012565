import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { FieldValueStruct, UserLog } from "@snubes/snubes-types";
import { useLogListItemProps } from "../helpers/useLogListItemProps";
import { LogListItem } from "../types/LogListItem";
import { LogListItemView } from "./LogListItemView";

export function LogListItemViewForUser<T extends UserLog>(
  props: LogListItem<T>,
) {
  const restProps = useLogListItemProps(props);
  const { update } = props.log;

  if (update.id) {
    return (
      <LogListItemView
        {...restProps}
        title="User created"
        iconColor="success.main"
        iconComponent={AddCircleIcon}
      />
    );
  }

  if (FieldValueStruct.is(update.organizationIds)) {
    if (update.organizationIds.type === "ARRAY_UNION") {
      return (
        <LogListItemView
          {...restProps}
          title="Organization added"
          iconColor="success.main"
          iconComponent={AddCircleIcon}
        />
      );
    }

    if (update.organizationIds.type === "ARRAY_REMOVE") {
      return (
        <LogListItemView
          {...restProps}
          title="Organization removed"
          iconColor="error.main"
          iconComponent={RemoveCircleIcon}
        />
      );
    }
  }

  return (
    <LogListItemView
      {...restProps}
      title="User edited"
      iconColor="primary.main"
      iconComponent={ChangeCircleIcon}
    />
  );
}
