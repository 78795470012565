import { useStore } from "zustand";
import { getWizardStepProgressSelector } from "../helpers/getWizardStepProgressSelector";
import { WizardStep } from "../types/WizardStep";
import { WizardStore } from "../types/WizardStore";

export function useWizardStepProgress<TState>(
  step: WizardStep<TState>,
  store: WizardStore<TState>,
): number {
  const progressSelector = getWizardStepProgressSelector(step);
  const progress = useStore(store, progressSelector);

  return progress;
}
