import { MenuItem } from "@mui/material";
import {
  DefaultComponentProps,
  OverridableTypeMap,
} from "@mui/material/OverridableComponent";
import { FC } from "react";
import { NavLink } from "react-router-dom";

interface Props extends DefaultComponentProps<OverridableTypeMap> {
  to?: string;
}

export const NotificationListItemMenuItemView: FC<Props> = (props) => {
  const { to, ...restProps } = props;
  return (
    <MenuItem {...restProps} component={to ? NavLink : "div"} to={to} divider />
  );
};
