import { Container, Stack, Typography } from "@mui/material";
import {
  ChannelType,
  ServiceType,
  TARGET_GROUP_AUDIENCES,
  TARGET_GROUPS,
  TargetGroupAudienceStruct,
  TargetGroupStruct,
} from "@snubes/snubes-types";
import { FC, useEffect, useRef } from "react";
import { array, type } from "superstruct";
import { ControlledAutocomplete } from "../../Form/views/ControlledAutocomplete";
import { ControlledCheckboxButtons } from "../../Form/views/ControlledCheckboxButtons";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { TENDER_TARGET_GROUP } from "../consts/TENDER_TARGET_GROUP";
import { TENDER_TARGET_GROUP_AUDIENCE } from "../consts/TENDER_TARGET_GROUP_AUDIENCE";
import { findTenderServiceChannel } from "../helpers/findTenderServiceChannel";
import { useTenderWizardStore } from "../hooks/useTenderWizardStore";

const FormValuesStruct = type({
  targetGroups: array(TargetGroupStruct),
  targetGroupAudiences: array(TargetGroupAudienceStruct),
});

type Props = WizardFormProps<{
  serviceType: ServiceType;
  channelType: ChannelType;
}>;

const TARGET_GROUP_OPTIONS = TARGET_GROUPS.map((targetGroup) => ({
  ...TENDER_TARGET_GROUP[targetGroup],
  value: targetGroup,
}));

export const TenderWizardChannelTargetGroupsForm: FC<Props> = (props) => {
  const { serviceType, channelType } = props.formProps;
  const t = useT();
  const formRef = useRef<HTMLFormElement>(null);
  const { control, handleSubmit, onSubmit, watch, setValue } = useWizardForm({
    store: useTenderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (tender) => {
      const channel = findTenderServiceChannel(
        serviceType,
        channelType,
        tender,
      );

      return {
        targetGroups: channel?.targetGroups ?? [],
        targetGroupAudiences: channel?.targetGroupAudiences ?? [],
      };
    },
    toDoc: (formValues, tender) => {
      const channel = findTenderServiceChannel(
        serviceType,
        channelType,
        tender,
      );

      const { targetGroups } = formValues;

      if (channel) {
        channel.targetGroups = targetGroups;
        channel.targetGroupAudiences = targetGroups?.includes("BUSINESS")
          ? formValues.targetGroupAudiences
          : [];
      }

      return {
        services: tender?.services,
        isReviewed: {
          ...tender?.isReviewed,
          services: false,
        },
      };
    },
  });

  const targetGroups = watch("targetGroups");
  const isBusinessTargetGroup = targetGroups.includes("BUSINESS");

  useEffect(() => {
    if (isBusinessTargetGroup) return;
    setValue("targetGroupAudiences", []);
  }, [isBusinessTargetGroup, setValue]);

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("TenderWizardChannelTargetGroupsForm_Title")}
        </Typography>
        <Typography variant="body2" color="text.500" mb={4}>
          {t("TenderWizardChannelTargetGroupsForm_Label")}
        </Typography>
        <Stack spacing={5}>
          <ControlledCheckboxButtons
            control={control}
            name="targetGroups"
            options={TARGET_GROUP_OPTIONS}
            gridSpacing={4}
            gridItemXs={6}
          />

          {isBusinessTargetGroup && (
            <>
              <Typography color="primary.main" variant="subtitle2">
                {t("TenderWizardChannelTargetGroupsForm_Label_Business")}
              </Typography>
              <ControlledAutocomplete
                control={control}
                name="targetGroupAudiences"
                label={t(
                  "TenderWizardChannelTargetGroupsForm_Label_Autocomplete",
                )}
                options={TARGET_GROUP_AUDIENCES.map((option) => option)}
                getOptionLabel={(option) =>
                  t(TENDER_TARGET_GROUP_AUDIENCE[option])
                }
                fullWidth
                multiple
              />
            </>
          )}
        </Stack>
      </Container>
    </form>
  );
};
