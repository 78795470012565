import { superstructResolver } from "@hookform/resolvers/superstruct";
import { Box, Button, Divider, Link, Stack, Typography } from "@mui/material";
import { EmailStruct, PasswordStruct } from "@snubes/snubes-types";
import { signInWithEmailAndPassword } from "firebase/auth";
import { FC, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Infer, type } from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { auth } from "../../Firebase/consts/FIREBASE";
import { logEvent } from "../../Firebase/helpers/logEvent";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { useAuthEmail } from "../hooks/useAuthEmail";
import { AuthGoogleSsoButton } from "./AuthGoogleSsoButton";

const FormValuesStruct = type({
  email: EmailStruct,
  password: PasswordStruct,
});

type FormValues = Infer<typeof FormValuesStruct>;

export const AuthLoginView: FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const t = useT();

  const { control, handleSubmit, watch } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      email: useAuthEmail.getState().email,
      password: "",
    },
  });

  useEffect(() => {
    const subscription = watch(
      ({ email }) => email && useAuthEmail.getState().update(email),
    );
    return subscription.unsubscribe;
  }, [watch]);

  const onLogin = useCallback(async (formValues: FormValues) => {
    try {
      setIsSubmitting(true);
      await signInWithEmailAndPassword(
        auth,
        formValues.email,
        formValues.password,
      );
      logEvent({ event: "login" });
    } catch (error) {
      handleError(error).logAnd().toast();
      setIsSubmitting(false);
    }
  }, []);

  const isLoading = isSubmitting;

  return (
    <Stack spacing={2}>
      <Box p={4} bgcolor="background.paper" border={1} borderColor="divider">
        <AuthGoogleSsoButton />
      </Box>
      <Stack
        spacing={3}
        p={4}
        bgcolor="background.paper"
        border={1}
        borderColor="divider"
      >
        <Typography variant="h3">
          {t("AuthLoginView_Headline_Login")}
        </Typography>

        {/* Login */}
        <form onSubmit={handleSubmit(onLogin)}>
          <Stack spacing={3}>
            <ControlledTextField
              required
              fullWidth
              name="email"
              type="email"
              label="Email"
              control={control}
              disabled={isLoading}
            />
            <ControlledTextField
              required
              fullWidth
              name="password"
              type="password"
              label="Password"
              control={control}
              disabled={isLoading}
            />
            <Button type="submit" variant="contained" disabled={isLoading}>
              {t("AuthLoginView_Button_Login")}
            </Button>
            <Link href="./forgot-password">
              {t("AuthLoginView_Button_ForgotPassword")}
            </Link>
          </Stack>
        </form>

        <Divider sx={{ color: "grey.500" }}>OR</Divider>

        {/* Signup */}
        <Typography variant="h3">
          {t("AuthLoginView_Subline_Question")}
        </Typography>
        <Button variant="outlined" href="/signup">
          {t("AuthLoginView_Subline_Cta")}
        </Button>
      </Stack>
    </Stack>
  );
};
