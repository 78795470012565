import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { MemberLog } from "@snubes/snubes-types";
import { useLogListItemProps } from "../helpers/useLogListItemProps";
import { LogListItem } from "../types/LogListItem";
import { LogListItemView } from "./LogListItemView";

export function LogListItemViewForMember<T extends MemberLog>(
  props: LogListItem<T>,
) {
  const restProps = useLogListItemProps(props);

  if (props.log.update.id) {
    return (
      <LogListItemView
        {...restProps}
        title="Member created"
        iconColor="secondary.main"
        iconComponent={AddCircleIcon}
      />
    );
  }

  return (
    <LogListItemView
      {...restProps}
      title="Member edited"
      iconColor="primary.main"
      iconComponent={ChangeCircleIcon}
    />
  );
}
