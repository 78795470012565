import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
} from "@mui/material";
import { CURRENCIES, Currency, isCurrency } from "@snubes/snubes-types";
import { FC } from "react";
import { useLocalStorage } from "../../Common/hooks/useLocalStorage";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  required?: boolean;
  disabled?: boolean;
  onChange?: (currency: Currency) => void;
  sx?: SxProps;
}

export const DefaultCurrencySelect: FC<Props> = (props) => {
  const t = useT();
  const currency = useLocalStorage((state) => state.data.defaultCurrency);

  return (
    <FormControl>
      <InputLabel>{t("ControlledMoneyField_Label_CurrencySelect")}</InputLabel>
      <Select
        label={t("ControlledMoneyField_Label_CurrencySelect")}
        disabled={props.disabled}
        required={props.required}
        sx={props.sx}
        value={currency}
        onChange={(e) => {
          const nextCurrency = e.target.value;
          if (!isCurrency(nextCurrency)) return;
          useLocalStorage.getState().setData({ defaultCurrency: nextCurrency });
          props.onChange?.(nextCurrency);
        }}
      >
        {CURRENCIES.map((currency) => {
          return (
            <MenuItem key={currency} value={currency}>
              {currency}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
