import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonIcon from "@mui/icons-material/Person";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { MemberRole } from "@snubes/snubes-types";
import { StatusColor } from "../../Common/types/StatusColor";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface MemberRoleRecordValue {
  Icon: OverridableComponent<SvgIconTypeMap>;
  label: TranslationKey;
  color: StatusColor;
}

export const MEMBER_ROLE_RECORD: Readonly<
  Record<MemberRole, MemberRoleRecordValue>
> = {
  MEMBER_OWNER: {
    Icon: AdminPanelSettingsIcon,
    label: "MemberRoleRecord_Label_Owner",
    color: "primary",
  },
  MEMBER_MANAGER: {
    Icon: ManageAccountsIcon,
    label: "MemberRoleRecord_Label_Manager",
    color: "warning",
  },
  MEMBER_REGULAR: {
    Icon: PersonIcon,
    label: "MemberRoleRecord_Label_Regular",
    color: "default",
  },
};
