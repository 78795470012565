import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Alert, Button, Paper, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { LoadingView } from "../../Common/views/LoadingView";
import { RelativeTimestampView } from "../../Common/views/RelativeTimestampView";
import { CONNECTOR_PLATFORM_RECORD } from "../../ConnectorConfigs/consts/CONNECTOR_PLATFORM_RECORD";
import { useConnectorConfig } from "../../ConnectorConfigs/hooks/useConnectorConfig";
import { ConnectorConfigStatusChip } from "../../ConnectorConfigs/views/ConnectorConfigStatusChip";
import { useT } from "../../Translation/hooks/useT";

interface Props {
  organizationId: string;
  connectorConfigId: string;
}

export const ProjectSettingsConnectorConfigView: FC<Props> = (props) => {
  const { connectorConfigId, organizationId } = props;
  const t = useT();
  const [connectorConfig, isLoading, error] = useConnectorConfig(
    organizationId,
    connectorConfigId,
  );
  const isLoaded = !isLoading && !error;

  if (!connectorConfig) return null;

  return (
    <Stack spacing={3}>
      <Typography variant="h3">
        {t("ProjectSettingsConnectorConfigView_Heading_Connection")}
      </Typography>
      {isLoading && <LoadingView />}
      {error && (
        <Alert sx={{ m: 3 }} severity="error">
          {error.message}
        </Alert>
      )}
      {isLoaded && (
        <Stack
          p={3}
          component={Paper}
          variant="outlined"
          spacing={3}
          direction="row"
        >
          <Stack spacing={2} flex={1}>
            <Stack spacing={2} direction="row" alignItems="center">
              <Typography variant="h4">{connectorConfig.name}</Typography>
              <ConnectorConfigStatusChip status={connectorConfig.status} />
            </Stack>
            <Stack spacing={2} direction="row">
              <Typography color="text.secondary" variant="h5">
                {t(CONNECTOR_PLATFORM_RECORD[connectorConfig.platform].label)}
              </Typography>
              <Typography color="text.secondary" variant="subtitle2">
                •
              </Typography>
              <Typography color="text.secondary" variant="subtitle2">
                {t("Common_UpdatedAt")}:{" "}
                <RelativeTimestampView
                  timestamp={connectorConfig.updatedAt}
                  shouldBeInThePast
                  translation
                />
              </Typography>
            </Stack>
          </Stack>
          <Stack justifyContent="center">
            <Button
              href={`/organizations/${organizationId}/connector-configs/${connectorConfigId}`}
              endIcon={<ArrowForwardIosIcon />}
              size="small"
              color="primary"
            >
              {t("ProjectSettingsConnectorConfigView_Label_ViewConnection")}
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
