import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { UserType } from "@snubes/snubes-types";
import { TenderApplicationRejectionReason } from "@snubes/snubes-types/types/TenderApplicationRejectionReason";
import { FC } from "react";
import { useT } from "../../Translation/hooks/useT";
import { useTenderApplicationRejectionReasonOptions } from "../hooks/useTenderApplicationRejectionReasonOptions";

interface Props {
  userType: UserType;
  rejectionReason: TenderApplicationRejectionReason | "";
  onSelectRejectionReason: (
    tenderRejectionReason: TenderApplicationRejectionReason,
  ) => void;
}

export const TenderApplicationRejectionReasonSelect: FC<Props> = (props) => {
  const { userType, rejectionReason, onSelectRejectionReason } = props;
  const t = useT();
  const tenderRejectionReasons = useTenderApplicationRejectionReasonOptions({
    userType,
  });

  return (
    <FormControl required fullWidth>
      <InputLabel id="rejection-reason-select-label">
        {t("TenderApplicationRejectionReasonSelect_Label_RejectionReason")}
      </InputLabel>
      <Select
        labelId="rejection-reason-select-label"
        label={t(
          "TenderApplicationRejectionReasonSelect_Label_RejectionReason",
        )}
        value={rejectionReason}
        onChange={(e) => {
          onSelectRejectionReason(
            e.target.value as TenderApplicationRejectionReason,
          );
        }}
      >
        {tenderRejectionReasons.map((reason) => (
          <MenuItem key={reason.value} value={reason.value}>
            {reason.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
