import {
  Call,
  isProjectReportFrame,
  ProjectReportFrame,
} from "@snubes/snubes-types";
import { query, where } from "firebase/firestore";
import { DateTime } from "luxon";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

interface Args {
  projectId?: string;
  fromDate: DateTime | null;
  toDate: DateTime | null;
  callDirection: Call["direction"];
}

export function useProjectReportFrames(args: Args) {
  const { projectId, fromDate, toDate, callDirection } = args;

  const [reportFrames, isLoading, error, snapshot] = useValidCollectionData(
    projectId && fromDate && toDate
      ? query(
          getCollectionRef(`projects/${projectId}/reportFramesByDay`),
          where("date", ">=", fromDate.toJSDate()),
          where("date", "<=", toDate.toJSDate()),
        )
      : null,
    isProjectReportFrame,
  );

  return [
    selectReportFramesByCallDirection(reportFrames, callDirection),
    isLoading,
    error,
    snapshot,
  ] as const;
}

function selectReportFramesByCallDirection(
  reportFrames: ProjectReportFrame[],
  callDirection: Call["direction"],
): ProjectReportFrame[] {
  if (!reportFrames?.length || !callDirection) {
    return reportFrames;
  }
  return reportFrames.map((frame): ProjectReportFrame => {
    if (callDirection === "INBOUND") {
      return {
        ...frame,
        totalCallsCount: frame.totalCallsIncomingCount || 0,
        answeredCallsCount: frame.answeredCallsIncomingCount || 0,
        averageHandlingTimeInSeconds:
          frame.averageHandlingTimeIncomingSeconds || 0,
        totalHandlingTimeInSeconds: frame.totalHandlingTimeIncomingSeconds || 0,
        reachabilityPercentage: frame.reachabilityIncomingPercentage || 0,
        serviceLevelPercentage: frame.serviceLevelPercentage || 0,
      };
    }
    if (callDirection === "OUTBOUND") {
      return {
        ...frame,
        totalCallsCount: frame.totalCallsOutgoingCount || 0,
        answeredCallsCount: frame.answeredCallsOutgoingCount || 0,
        averageHandlingTimeInSeconds:
          frame.averageHandlingTimeOutgoingSeconds || 0,
        totalHandlingTimeInSeconds: frame.totalHandlingTimeOutgoingSeconds || 0,
        reachabilityPercentage: frame.reachabilityOutgoingPercentage || 0,
        serviceLevelPercentage: frame.serviceLevelPercentage || 0,
      };
    }
    return frame;
  });
}
