import { superstructResolver } from "@hookform/resolvers/superstruct";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CreateConnectionRequest, ExternalProject } from "@snubes/snubes-types";
import { FC, useCallback, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  array,
  Infer,
  nullable,
  number,
  object,
  optional,
  string,
  type,
} from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { isProjectMappable } from "../../Common/helpers/isProjectMappable";
import { ControlledAutocomplete } from "../../Form/views/ControlledAutocomplete";
import { ControlledProjectIdAutocomplete } from "../../Form/views/ControlledProjectIdAutocomplete";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { createConnectorConfigCallable } from "../callables/createConnectorConfigCallable";
import { testConnectorConfigSftpCallable } from "../callables/testConnectorConfigSftpCallable";
import { DEFAULT_BACKFILL_DAYS } from "../consts/DEFAULT_BACKFILL_DAYS";
import { getBackfillDate } from "../helpers/getBackfillDate";
import { getDaysToBackfill } from "../helpers/getDaysToBackfill";
import { haveProjectMappingsDuplicates } from "../helpers/haveProjectMappingsDuplicates";
import { ConnectorConfigBackfillDaysFormFieldsView } from "./ConnectorConfigBackfillDaysFormFieldsView";
import { ConnectorConfigSftpHelpView } from "./ConnectorConfigSftpHelpView";

const FormValuesStruct = type({
  name: string(),
  host: string(),
  user: string(),
  password: string(),
  csvPath: string(),
  port: optional(number()),
  projectMappings: array(
    object({
      projectId: nullable(string()),
      externalProjectId: nullable(string()),
      projectName: string(),
    }),
  ),
  backfillDate: string(),
  backfillDays: number(),
});

type FormValues = Infer<typeof FormValuesStruct>;

export const ConnectorConfigSftpForm: FC = () => {
  const t = useT();
  const navigate = useNavigate();
  const { organizationId } = useParams<"organizationId">();
  const [externalProjects, setExternalProjects] = useState<
    ExternalProject[] | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<string>();
  const form = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      name: "",
      host: "",
      user: "",
      password: "",
      csvPath: "/index.csv",
      projectMappings: [
        { projectId: null, externalProjectId: null, projectName: "" },
      ],
      backfillDate: getBackfillDate(DEFAULT_BACKFILL_DAYS),
      backfillDays: DEFAULT_BACKFILL_DAYS,
    },
  });
  const csvPath = form.watch("csvPath");
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "projectMappings",
  });

  const handleProjectMappingRemove = (index: number) => {
    remove(index);
  };
  const handleProjectMappingAppend = () => {
    append({ projectId: null, externalProjectId: null, projectName: "" });
  };

  const testConnectorSftpConfig = useCallback(
    async (formValues: FormValues, organizationId: string) => {
      try {
        const { externalProjects } = await testConnectorConfigSftpCallable({
          user: formValues.user,
          password: formValues.password,
          organizationId,
          host: formValues.host,
          csvPath: formValues.csvPath,
          ...(formValues.port && { port: formValues.port }),
          platform: "SFTP",
        });

        setErrorMessage(undefined);
        setExternalProjects(externalProjects);
      } catch (error) {
        setErrorMessage(error instanceof Error ? error.message : String(error));
      }
    },
    [],
  );
  const createConnectorConfig = useCallback(
    async (formValues: FormValues, organizationId: string) => {
      try {
        const projectMappings = formValues.projectMappings.reduce<
          CreateConnectionRequest["projectMappings"]
        >((result, mapping) => {
          const { projectId, externalProjectId, projectName } = mapping;
          if (projectId && externalProjectId) {
            result.push({
              projectId,
              externalProjectId,
              projectName,
            });
          }
          return result;
        }, []);

        await createConnectorConfigCallable({
          name: formValues.name,
          organizationId,
          csvPath: formValues.csvPath,
          password: formValues.password,
          host: formValues.host,
          user: formValues.user,
          platform: "SFTP",
          ...(formValues.port && { port: formValues.port }),
          projectMappings,
          backfillDays: getDaysToBackfill(formValues.backfillDate),
        });
      } catch (error) {
        setErrorMessage(error instanceof Error ? error.message : String(error));
      }
    },
    [],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (haveProjectMappingsDuplicates(formValues.projectMappings)) {
          throw new Error(t("ConnectorConfigSftpForm_Toast_MultipleProjects"));
        }
        if (!organizationId) {
          throw new Error("No organization selected");
        }

        if (externalProjects) {
          await createConnectorConfig(formValues, organizationId);
          navigate(`/organizations/${organizationId}/connector-configs`);
        } else {
          await testConnectorSftpConfig(formValues, organizationId);
        }
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [
      createConnectorConfig,
      externalProjects,
      navigate,
      organizationId,
      t,
      testConnectorSftpConfig,
    ],
  );
  const { isSubmitting } = form.formState;

  const isConnecting = isSubmitting && !externalProjects;

  return (
    <Stack pt={3}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <ControlledTextField
            required
            fullWidth
            autoComplete="off"
            name="name"
            label={t("ConnectorConfigSftpForm_Inputlabel_Name")}
            control={form.control}
            disabled={isSubmitting}
          />

          <ConnectorConfigSftpHelpView
            title={t("ConnectorConfigSftpForm_Title_GetStarted")}
            csvPath={csvPath}
          />
          <Typography variant="subtitle1">
            {t("ConnectorConfigSftpForm_SubHeading_Credentials")}
          </Typography>
          <ControlledTextField
            required
            fullWidth
            autoComplete="off"
            name="host"
            label={t("ConnectorConfigSftpForm_InputLabel_Host")}
            control={form.control}
            disabled={isSubmitting}
          />

          <ControlledTextField
            required
            fullWidth
            autoComplete="off"
            name="user"
            label={t("ConnectorConfigSftpForm_InputLabel_User")}
            control={form.control}
            disabled={isSubmitting}
          />

          <ControlledTextField
            required
            fullWidth
            autoComplete="off"
            name="password"
            type="password"
            label={t("ConnectorConfigSftpForm_InputLabel_Password")}
            control={form.control}
            disabled={isSubmitting}
          />
          <ControlledTextField
            required
            fullWidth
            autoComplete="off"
            name="csvPath"
            label={t("ConnectorConfigSftpForm_InputLabel_CsvPath")}
            control={form.control}
            disabled={isSubmitting}
          />
          <ControlledTextField
            fullWidth
            autoComplete="off"
            type="number"
            name="port"
            label={t("ConnectorConfigSftpForm_InputLabel_Port")}
            control={form.control}
            disabled={isSubmitting}
          />

          <FormProvider {...form}>
            <ConnectorConfigBackfillDaysFormFieldsView />
          </FormProvider>

          {isConnecting && (
            <Stack spacing={3} alignItems="center" direction="row">
              <CircularProgress size={20} />
              <Typography variant="subtitle2">
                {t("ConnectorConfigSftpForm_SubHeading_ConnectingSftp")}
              </Typography>
            </Stack>
          )}
          {!!errorMessage && (
            <Stack color="error.main" spacing={3}>
              <Stack spacing={3} alignItems="center" direction="row">
                <InfoIcon />
                <Typography variant="subtitle2">
                  {t("ConnectorConfigSftpForm_SubHeading_ConnectedError")}
                </Typography>
              </Stack>
              <Stack
                p={5}
                bgcolor="background.default"
                justifyContent="center"
                alignContent="center"
              >
                {errorMessage}
              </Stack>
              <Divider />
            </Stack>
          )}
          {externalProjects && (
            <>
              <Stack spacing={3}>
                <Stack
                  color="success.main"
                  spacing={3}
                  alignItems="center"
                  direction="row"
                >
                  <CheckCircleIcon />
                  <Typography variant="subtitle2">
                    {t("ConnectorConfigSftpForm_SubHeading_Connected")}
                  </Typography>
                </Stack>
                <Divider />
                <Typography
                  py={3}
                  textTransform="uppercase"
                  variant="subtitle1"
                >
                  {t("ConnectorConfigSftpForm_SubHeading_ChooseProject")}
                </Typography>
                <Stack
                  justifyContent="space-between"
                  alignItems="center"
                  direction="row"
                  spacing={3}
                >
                  <Stack flex={1}>
                    <Typography variant="subtitle1">
                      {t("ConnectorConfigSftpForm_Label_SftpProject")}
                    </Typography>
                  </Stack>
                  <Stack flex={1}>
                    <Typography variant="subtitle1">
                      {t("ConnectorConfigSftpForm_Label_SnubesProject")}
                    </Typography>
                  </Stack>
                </Stack>
                <Divider />

                {fields.map((item, index) => (
                  <Stack
                    key={item.id}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    direction="row"
                    spacing={3}
                  >
                    {externalProjects.length > 0 && (
                      <Stack flex={1}>
                        <ControlledAutocomplete
                          fullWidth
                          required
                          name={`projectMappings.${index}.externalProjectId`}
                          control={form.control}
                          options={externalProjects.map((p) => p.id)}
                          onChange={(externalProjectId) => {
                            form.setValue(
                              `projectMappings.${index}.projectName`,
                              externalProjects.find(
                                (f) => f.id === externalProjectId,
                              )?.name || "",
                            );
                          }}
                          getOptionLabel={(projectId) =>
                            externalProjects.find(
                              (project) => project.id === projectId,
                            )?.name || ""
                          }
                          label={t(
                            "ConnectorConfigSftpForm_SelectLabel_ChooseSftpProject",
                          )}
                        />
                      </Stack>
                    )}
                    {organizationId && (
                      <Stack flex={1} spacing={3}>
                        <ControlledProjectIdAutocomplete
                          fullWidth
                          required
                          filter={(project) => isProjectMappable(project)}
                          name={`projectMappings.${index}.projectId`}
                          projectNameFieldName={`projectMappings.${index}.projectName`}
                          control={form.control}
                          label={t(
                            "ConnectorConfigSftpForm_SelectLabel_SnubesProject",
                          )}
                          organizationId={organizationId}
                        />
                      </Stack>
                    )}

                    {(index !== 0 || fields.length > 1) && (
                      <IconButton
                        sx={{ p: 0 }}
                        onClick={() => {
                          handleProjectMappingRemove(index);
                        }}
                      >
                        <DeleteForeverIcon sx={{ color: "error.main" }} />
                      </IconButton>
                    )}
                  </Stack>
                ))}
              </Stack>

              <Stack
                borderTop={1}
                borderBottom={1}
                py={3}
                px={2}
                bgcolor="background.default"
                borderColor="divider"
                alignItems="center"
                direction="row"
              >
                <Button
                  size="small"
                  variant="text"
                  onClick={handleProjectMappingAppend}
                  startIcon={<AddCircleIcon />}
                >
                  {t("ConnectorConfigSftpForm_Button_NewMapping")}
                </Button>
              </Stack>
            </>
          )}
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            {!externalProjects && t("ConnectorConfigSftpForm_Button_Connect")}
            {externalProjects &&
              t("ConnectorConfigSftpForm_Button_FinishSetUp")}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};
