import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { Button, Stack, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Votes } from "@snubes/snubes-types";
import { FC, useState } from "react";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { useMyUser } from "../../Users/hooks/useMyUser";
import { handleError } from "../helpers/handleError";

interface Props {
  setValue: (value: boolean) => Promise<void>;
  votes?: Votes;
}

export const VoteButtons: FC<Props> = (props) => {
  const { votes } = props;
  const [isLoading, setIsLoading] = useState(false);
  const userId = useMyUser((state) => state.user?.id);
  const hasPermission = useHasPermission();
  const organizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );
  const t = useT();

  const onClickButton = async (value: boolean) => {
    try {
      setIsLoading(true);
      await props.setValue(value);
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsLoading(false);
    }
  };

  const amountOfUpvotes = Object.values(votes || {}).filter((i) => i).length;
  const amountOfDownvotes = Object.values(votes || {}).filter((i) => !i).length;

  if (!userId) return null;
  if (!hasPermission("CAN_WRITE_CALL", { organizationId })) return null;

  const value = votes?.[userId];

  return (
    <Stack direction="row" spacing={2}>
      <Tooltip title={t("VoteButtons_TooltipTitle_HelpFul")} arrow>
        {/* We have to wrap it in a `div`. Otherwise the tooltip doesn't disappear after clicking. credits: https://github.com/mui/material-ui/issues/8416 */}
        <div>
          <VoteButton
            disabled={isLoading}
            size="small"
            onClick={() => onClickButton(true)}
            variant={value === true ? "contained" : "outlined"}
            startIcon={
              value === true ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />
            }
          >
            {amountOfUpvotes}
          </VoteButton>
        </div>
      </Tooltip>
      <Tooltip title={t("VoteButtons_TooltipTitle_NotHelpFul")} arrow>
        <div>
          <VoteButton
            disabled={isLoading}
            size="small"
            onClick={() => onClickButton(false)}
            variant={value === false ? "contained" : "outlined"}
            startIcon={
              value === false ? <ThumbDownAltIcon /> : <ThumbDownOffAltIcon />
            }
          >
            {amountOfDownvotes}
          </VoteButton>
        </div>
      </Tooltip>
    </Stack>
  );
};

const VoteButton = styled(Button)(({ theme }) => ({
  minWidth: "max-content",
  height: theme.spacing(4),
  padding: `0 ${theme.spacing(2)}`,
}));
