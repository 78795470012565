import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import WorkIcon from "@mui/icons-material/Work";
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { TargetGroup } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface TargetGroupType {
  label: TranslationKey;
  caption?: TranslationKey;
  Icon?: OverridableComponent<SvgIconTypeMap>;
}

export const TENDER_TARGET_GROUP: Record<TargetGroup, TargetGroupType> = {
  BUSINESS: {
    label: "TenderTargetGroup_Label_Business",
    caption: "TenderTargetGroup_Caption_Business",
    Icon: WorkIcon,
  },
  PRIVATE: {
    label: "TenderTargetGroup_Label_Private",
    caption: "TenderTargetGroup_Caption_Private",
    Icon: FaceOutlinedIcon,
  },
};
