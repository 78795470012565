import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC } from "react";
import { useT } from "../../Translation/hooks/useT";

export const CollectionEmptyView: FC = () => {
  const t = useT();

  return (
    <Stack color="grey.400" alignItems="center" p={3} spacing={1}>
      <NotInterestedIcon fontSize="large" />
      <Typography variant="h4">{t("Common_NoDataAvailable")}</Typography>
    </Stack>
  );
};
