import { safeDivide } from "../../Common/helpers/safeDivide";
import { ReportFrameByDay } from "../types/ReportFrameByDay";

interface Args {
  reportFrameToday: ReportFrameByDay;
  reportFrameReference: ReportFrameByDay;
}

export const calculateComparedPercentages = (args: Args) => {
  const { reportFrameToday, reportFrameReference } = args;
  return {
    totalCallsCount:
      safeDivide(
        reportFrameToday.totalCallsCount,
        reportFrameReference.totalCallsCount,
      ) - 1,
    answeredCallsCount:
      safeDivide(
        reportFrameToday.answeredCallsCount,
        reportFrameReference.answeredCallsCount,
      ) - 1,
    reachabilityPercentage:
      safeDivide(
        reportFrameToday.reachabilityPercentage,
        reportFrameReference.reachabilityPercentage,
      ) - 1,
    averageHandlingTimeInSeconds:
      safeDivide(
        reportFrameToday.averageHandlingTimeInSeconds,
        reportFrameReference.averageHandlingTimeInSeconds,
      ) - 1,
    totalHandlingTimeInSeconds:
      safeDivide(
        reportFrameToday.totalHandlingTimeInSeconds,
        reportFrameReference.totalHandlingTimeInSeconds,
      ) - 1,
    serviceLevelPercentage:
      safeDivide(
        reportFrameToday.serviceLevelPercentage,
        reportFrameReference.serviceLevelPercentage,
      ) - 1,
  };
};
