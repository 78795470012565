import {
  ADDITIONAL_SERVICES,
  ProviderTechnology,
  ProviderTechnologyCategory,
  SELF_SERVICES,
  TECHNOLOGIES,
} from "@snubes/snubes-types";

export const PROVIDER_TECHNOLOGY_BY_CATEGORY = new Map<
  ProviderTechnologyCategory,
  ReadonlyArray<ProviderTechnology>
>([
  ["TECHNOLOGIES", TECHNOLOGIES],
  ["SELF_SERVICES", SELF_SERVICES],
  ["ADDITIONAL_SERVICES", ADDITIONAL_SERVICES],
]);

function* technologyCategoryTuples() {
  for (const [
    category,
    technologies,
  ] of PROVIDER_TECHNOLOGY_BY_CATEGORY.entries()) {
    for (const technology of technologies) {
      yield [technology, category] as const;
    }
  }
}

export const PROVIDER_TECHNOLOGY_CATEGORIES_BY_TECHNOLOGY = new Map<
  ProviderTechnology,
  ProviderTechnologyCategory
>(technologyCategoryTuples());
