import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography,
} from "@mui/material";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  label: string;
}

export function CallSidebarAccordion(props: Props) {
  const { label, children } = props;

  return (
    <SAccordion square disableGutters defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ py: 2, px: 4 }}>
        <Typography variant="h5" color="text.secondary">
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
    </SAccordion>
  );
}

const SAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  borderTop: `1px solid ${theme.palette.divider}`,
  "&:last-child": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  ".MuiAccordionSummary-root": {
    backgroundColor: theme.palette.grey[50],
  },
  ".MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(-90deg)",
    "&.Mui-expanded": {
      transform: "rotate(0deg)",
    },
  },
  ".MuiAccordionDetails-root": {
    bgcolor: "background.paper",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));
