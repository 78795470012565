import { isTender } from "@snubes/snubes-types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useTender(tenderId?: string) {
  return useValidDocumentData(
    tenderId ? getDocRef("tenders", tenderId) : null,
    isTender,
  );
}
