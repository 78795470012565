import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  Box,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FC, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AppNavigationItem } from "../../Application/types/AppNavigationItem";

export const HeaderNavLink: FC<AppNavigationItem> = (props) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Box mx={0.5}>
      <ListItemButton
        {...(props.to && { component: NavLink, to: props.to })}
        sx={{
          ...theme.typography.subtitle2,
          p: 2,
          whiteSpace: "nowrap",
          borderRadius: 1,
          "&.active, &.Mui-selected": {
            color: "primary.main",
            bgcolor: "info.50",
          },
          "&:hover": {
            bgcolor: "info.50",
          },
        }}
        selected={props.items?.some((i) => i.to && pathname.startsWith(i.to))}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {props.title}
        {!!props.items?.length && <KeyboardArrowDownOutlinedIcon />}
      </ListItemButton>
      {!!props.items?.length && (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClick={() => setAnchorEl(null)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          keepMounted={false}
        >
          {props.items?.map((i) => (
            <MenuItem
              {...(i.to && { component: NavLink, to: i.to })}
              key={i.title}
              sx={{
                "&:hover": {
                  bgcolor: "info.50",
                },
              }}
            >
              <ListItemText>{i.title}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Box>
  );
};
