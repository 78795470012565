import { SxProps } from "@mui/material";

export const WIZARD_ACTIVE_PSEUDO_SX: SxProps = {
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    bottom: 0,
    right: -1,
    width: "8px",
    backgroundColor: "primary.main",
  },
};
