import { Locale } from "@snubes/snubes-types";
import { Locale as DateFnsLocale } from "date-fns";
import { de, enGB } from "date-fns/locale";

interface LocaleRecordValue {
  flag: string;
  dateFnsLocale: DateFnsLocale;
}

export const LOCALE_RECORD: Record<Locale, LocaleRecordValue> = {
  en: {
    flag: "🇬🇧",
    dateFnsLocale: enGB,
  },
  de: {
    flag: "🇩🇪",
    dateFnsLocale: de,
  },
};
