import { StatusColor } from "../types/StatusColor";

interface StatusColorRecordValue {
  backgroundColor: string;
  color: string;
}

export const STATUS_COLOR_RECORD: Record<StatusColor, StatusColorRecordValue> =
  {
    default: {
      color: "text.secondary",
      backgroundColor: "grey.200",
    },
    primary: {
      color: "primary.main",
      backgroundColor: "primary.50",
    },
    secondary: {
      color: "secondary.main",
      backgroundColor: "secondary.50",
    },
    success: {
      color: "success.main",
      backgroundColor: "success.50",
    },
    warning: {
      color: "warning.main",
      backgroundColor: "warning.50",
    },
    error: {
      color: "error.main",
      backgroundColor: "error.50",
    },
  };
