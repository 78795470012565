import { isConnectorConfig } from "@snubes/snubes-types";
import { query } from "firebase/firestore";
import { getCollectionRef } from "../../Firebase/helpers/getCollectionRef";
import { useValidCollectionData } from "../../Firebase/hooks/useValidCollectionData";

export function useConnectorConfigs(organizationId?: string) {
  return useValidCollectionData(
    organizationId
      ? query(
          getCollectionRef(`organizations/${organizationId}/connectorConfigs`),
        )
      : null,
    isConnectorConfig,
  );
}
