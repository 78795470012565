import { superstructResolver } from "@hookform/resolvers/superstruct";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InfoIcon from "@mui/icons-material/Info";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ConnectorConfig,
  CreateConnectionRequest,
  ExternalProject,
} from "@snubes/snubes-types";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  array,
  Infer,
  nullable,
  object,
  optional,
  string,
  type,
} from "superstruct";
import { handleError } from "../../Common/helpers/handleError";
import { isProjectMappable } from "../../Common/helpers/isProjectMappable";
import { ControlledAutocomplete } from "../../Form/views/ControlledAutocomplete";
import { ControlledProjectIdAutocomplete } from "../../Form/views/ControlledProjectIdAutocomplete";
import { useT } from "../../Translation/hooks/useT";
import { updateConnectorConfigCallable } from "../callables/updateConnectorConfigCallable";
import { haveProjectMappingsDuplicates } from "../helpers/haveProjectMappingsDuplicates";

interface Props {
  connectorConfig: ConnectorConfig;
  testConnectorConfig: () => Promise<ExternalProject[]>;
}

const FormValuesStruct = type({
  projectMappings: array(
    object({
      projectId: nullable(string()),
      externalProjectId: nullable(string()),
      projectName: optional(string()),
    }),
  ),
});

type FormValues = Infer<typeof FormValuesStruct>;

export const ConnectorConfigMultipleProjectMappingsEditForm: FC<Props> = (
  props,
) => {
  const hasRequestedExternalProjects = useRef(false);
  const { connectorConfig, testConnectorConfig } = props;
  const t = useT();
  const navigate = useNavigate();
  const { id: connectorConfigId, organizationId } = connectorConfig;

  const [errorMessage, setErrorMessage] = useState<string>();
  const [externalProjects, setExternalProjects] = useState<ExternalProject[]>();

  const projectMappings = useMemo(() => {
    return connectorConfig.projectMappings.map((mapping) => {
      const { projectId, externalProjectId } = mapping;
      const { name } =
        externalProjects?.find((project) => project.id === externalProjectId) ||
        {};
      return {
        projectId,
        externalProjectId,
        projectName: name,
      };
    });
  }, [connectorConfig.projectMappings, externalProjects]);

  const { control, formState, handleSubmit, setValue } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      projectMappings,
    },
  });

  useEffect(() => {
    setValue("projectMappings", projectMappings);
  }, [projectMappings, setValue]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "projectMappings",
  });

  const handleProjectMappingRemove = (index: number) => {
    remove(index);
  };
  const handleProjectMappingAppend = () => {
    append({ projectId: null, externalProjectId: null, projectName: "" });
  };

  useEffect(() => {
    if (hasRequestedExternalProjects.current) return;

    const requestExternalProjects = async () => {
      try {
        hasRequestedExternalProjects.current = true;
        const externalProjects = await testConnectorConfig();
        setErrorMessage(undefined);
        setExternalProjects(externalProjects);
      } catch (error) {
        setErrorMessage(error instanceof Error ? error.message : String(error));
      }
    };
    void requestExternalProjects();
  }, [testConnectorConfig]);

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        if (externalProjects === undefined) {
          throw new Error("No externalProjectsResponse selected");
        }

        if (haveProjectMappingsDuplicates(formValues.projectMappings)) {
          throw new Error(
            t(
              "ConnectorConfigMultipleProjectMappingsEditForm_Toast_MultipleProjects",
            ),
          );
        }

        const projectMappings = formValues.projectMappings.reduce<
          CreateConnectionRequest["projectMappings"]
        >((result, mapping) => {
          const { projectId, externalProjectId, projectName } = mapping;
          if (projectId && externalProjectId) {
            result.push({
              projectId,
              externalProjectId,
              projectName,
            });
          }
          return result;
        }, []);

        await updateConnectorConfigCallable({
          connectorConfigId,
          organizationId,
          connectorConfig: {
            projectMappings,
          },
        });
        navigate(`/organizations/${organizationId}/connector-configs`);
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [externalProjects, connectorConfigId, organizationId, navigate, t],
  );

  const { isSubmitting } = formState;

  const isConnecting = !errorMessage && !externalProjects;

  return (
    <Paper variant="outlined">
      {isConnecting && (
        <Stack
          borderBottom={1}
          borderColor="divider"
          spacing={3}
          bgcolor="background.default"
          p={3}
          alignItems="center"
          direction="row"
        >
          <CircularProgress size={20} />
          <Typography variant="subtitle2">
            {t(
              "ConnectorConfigMultipleProjectMappingsEditForm_SubHeading_CheckingUpdating",
            )}
          </Typography>
        </Stack>
      )}
      <Stack p={4} spacing={5}>
        {!!errorMessage && (
          <Stack color="error.main" spacing={3}>
            <Stack spacing={3} alignItems="center" direction="row">
              <InfoIcon />
              <Typography variant="subtitle2">
                {t(
                  "ConnectorConfigMultipleProjectMappingsEditForm_SubHeading_ConnectedError",
                )}
              </Typography>
            </Stack>
            <Stack
              p={5}
              bgcolor="background.default"
              justifyContent="center"
              alignContent="center"
            >
              {errorMessage}
            </Stack>
            <Divider />
          </Stack>
        )}

        <Typography variant="h3">
          {t(
            "ConnectorConfigMultipleProjectMappingsEditForm_Heading_UpdateMappings",
          )}
        </Typography>
        <Typography
          textTransform="uppercase"
          color="text.500"
          variant="subtitle2"
        >
          {t(
            "ConnectorConfigMultipleProjectMappingsEditForm_SubHeading_ChooseProject",
          )}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {fields.map((item, index) => {
              return (
                <Stack
                  key={item.id}
                  justifyContent="space-between"
                  alignItems="flex-start"
                  direction="row"
                  spacing={3}
                >
                  <Stack flex={1}>
                    <ControlledAutocomplete
                      fullWidth
                      required
                      disabled={isConnecting || isSubmitting}
                      name={`projectMappings.${index}.externalProjectId`}
                      control={control}
                      InputProps={
                        isConnecting
                          ? {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CircularProgress color="primary" size={20} />
                                </InputAdornment>
                              ),
                            }
                          : undefined
                      }
                      onChange={(externalProjectId) => {
                        setValue(
                          `projectMappings.${index}.projectName`,
                          externalProjects?.find(
                            (f) => f.id === externalProjectId,
                          )?.name || "",
                        );
                      }}
                      options={externalProjects?.map((p) => p.id) || []}
                      getOptionLabel={(projectId) =>
                        externalProjects?.find(
                          (project) => project.id === projectId,
                        )?.name || ""
                      }
                      label={t(
                        "ConnectorConfigMultipleProjectMappingsEditForm_SelectLabel_ChooseProject",
                      )}
                    />
                  </Stack>

                  {organizationId && (
                    <Stack spacing={3} flex={1}>
                      <ControlledProjectIdAutocomplete
                        fullWidth
                        required
                        disabled={isConnecting || isSubmitting}
                        filter={(project) =>
                          isProjectMappable(project, connectorConfig)
                        }
                        name={`projectMappings.${index}.projectId`}
                        projectNameFieldName={`projectMappings.${index}.projectName`}
                        control={control}
                        label={t(
                          "ConnectorConfigMultipleProjectMappingsEditForm_SelectLabel_SnubesProject",
                        )}
                        organizationId={organizationId}
                      />
                    </Stack>
                  )}

                  {(index !== 0 || fields.length > 1) && (
                    <Tooltip
                      title={t(
                        "ConnectorConfigMultipleProjectMappingsEditForm_Tooltip_DeleteMapping",
                      )}
                    >
                      <IconButton
                        size="small"
                        disabled={isConnecting || isSubmitting}
                        onClick={() => {
                          handleProjectMappingRemove(index);
                        }}
                      >
                        <DeleteForeverIcon sx={{ color: "error.main" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              );
            })}

            <Stack
              borderTop={1}
              borderBottom={1}
              py={3}
              px={2}
              bgcolor="background.default"
              borderColor="divider"
              alignItems="center"
              direction="row"
            >
              <Button
                size="small"
                variant="text"
                onClick={handleProjectMappingAppend}
                startIcon={<AddCircleIcon />}
                disabled={isSubmitting || isConnecting}
              >
                {t(
                  "ConnectorConfigMultipleProjectMappingsEditForm_Button_NewMapping",
                )}
              </Button>
            </Stack>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting || isConnecting}
            >
              {t("Common_Save")}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Paper>
  );
};
