import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { replyInvitationRequest } from "@snubes/snubes-types";
import { FC, useCallback } from "react";
import { toast } from "react-hot-toast";
import { handleError } from "../../Common/helpers/handleError";
import { RoundButton } from "../../Common/views/RoundButton";
import { useT } from "../../Translation/hooks/useT";
import { replyInvitationCallable } from "../callables/replyInvitationCallable";

interface Props {
  invitationId: string;
  reply: replyInvitationRequest["reply"];
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const InvitationReplyButton: FC<Props> = (props) => {
  const { invitationId, reply, isLoading, setIsLoading } = props;
  const t = useT();

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      await replyInvitationCallable({
        invitationId,
        reply,
      });
      if (reply === "accept") {
        toast.success(t("InvitationReplyButton_Toast_Accepted"));
      } else {
        toast.success(t("InvitationReplyButton_Toast_Declined"));
      }
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, invitationId, reply, t]);

  return (
    <RoundButton
      onClick={async (event) => {
        event.preventDefault();
        event.stopPropagation();
        await handleClick();
      }}
      variant="outlined"
      size="small"
      color={reply === "accept" ? "primary" : "error"}
      disabled={isLoading}
      startIcon={reply === "accept" ? <CheckCircleIcon /> : <CancelIcon />}
    >
      {reply === "accept"
        ? t("InvitationReplyButton_Button_Accept")
        : t("InvitationReplyButton_Button_Decline")}
    </RoundButton>
  );
};
