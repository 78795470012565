import { TextFieldProps } from "@mui/material";
import { DateView } from "@mui/x-date-pickers";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { DATE_VIEW_FORMAT_RECORD } from "../types/DATE_VIEW_FORMAT_RECORD";

type LuxonDatePickerProps = DatePickerProps<DateTime>;

interface Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> {
  control: Control<TFieldValues & Record<TName, string | number | null>>;
  name: TName;
  label?: LuxonDatePickerProps["label"];
  disabled?: LuxonDatePickerProps["disabled"];
  disablePast?: LuxonDatePickerProps["disablePast"];
  disableFuture?: LuxonDatePickerProps["disableFuture"];
  shouldDisableDate?: LuxonDatePickerProps["shouldDisableDate"];
  required?: TextFieldProps["required"];
  fullWidth?: TextFieldProps["fullWidth"];
  autoFocus?: TextFieldProps["autoFocus"];
  helperText?: TextFieldProps["helperText"];
  sx?: TextFieldProps["sx"];
  view?: DateView;
  minDate?: DateTime;
}

// TODO: consider using dayjs instead, placeholder does not work because of https://github.com/moment/luxon/issues/1197
export function ControlledDatePicker<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
  const view = useMemo(() => {
    return props.view && [props.view];
  }, [props.view]);

  const maxDate = useMemo(() => {
    return props.disableFuture && props.view
      ? DateTime.now().endOf("day")
      : undefined;
  }, [props.disableFuture, props.view]);

  const disablePastMinDate = useMemo(() => {
    return props.disablePast ? DateTime.now() : undefined;
  }, [props.disablePast]);

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field }) => {
        const currentDate =
          field.value && props.view
            ? DateTime.fromMillis(0).set({ [props.view]: field.value })
            : field.value
            ? DateTime.fromISO(field.value) ?? null
            : null;

        return (
          <DatePicker
            label={props.label}
            format={
              props.view ? DATE_VIEW_FORMAT_RECORD[props.view] : undefined
            }
            value={currentDate}
            disabled={props.disabled}
            views={view}
            disablePast={props.disablePast}
            disableFuture={props.disableFuture}
            shouldDisableDate={props.shouldDisableDate}
            minDate={props.minDate}
            maxDate={maxDate}
            onChange={(nextDate) => {
              if (nextDate === null || !nextDate?.isValid) {
                if (field.value) {
                  field.onChange(null);
                }
                return;
              }

              const isNextValuePastInvalid =
                (props.disablePast &&
                  disablePastMinDate &&
                  nextDate < disablePastMinDate) ||
                (props.minDate && nextDate < props.minDate);

              const isNextValueFutureInvalid =
                props.disableFuture && maxDate && nextDate > maxDate;

              if (isNextValuePastInvalid || isNextValueFutureInvalid) {
                if (field.value) {
                  field.onChange(null);
                }
                return;
              }

              if (props.view) {
                field.onChange(nextDate[props.view]);
              } else {
                const nextDateWithoutTime = nextDate.toFormat("yyyy-MM-dd");
                const nextDateUtcIsoFormat = `${nextDateWithoutTime}T00:00:00.000Z`;
                field.onChange(nextDateUtcIsoFormat);
              }
            }}
            slotProps={{
              textField: {
                sx: props.sx,
                fullWidth: props.fullWidth,
                required: props.required,
                autoFocus: props.autoFocus,
                helperText: props.helperText,
                autoComplete: "off",
              },
            }}
          />
        );
      }}
    />
  );
}
