import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { StorageFileMetadata, StoragePath } from "@snubes/snubes-types";
import { FC, useCallback, useEffect, useState } from "react";
import { Accept, DropzoneOptions, useDropzone } from "react-dropzone";
import { useUploadFile } from "react-firebase-hooks/storage";
import toast from "react-hot-toast";
import { handleError } from "../../Common/helpers/handleError";
import { CircularProgressView } from "../../Common/views/CircularProgressView";
import { getStorageRef } from "../../Firebase/helpers/getStorageRef";
import { useT } from "../../Translation/hooks/useT";
import { T } from "../../Translation/views/T";
import { UploadState } from "../types/UploadState";

const MAX_FILE_SIZE_BYTES = 10 * 1024 * 1024; // 10 MB

interface Props {
  disabled?: boolean;
  allowMultiple?: boolean;
  error?: boolean;
  storagePath: StoragePath;
  storageFileMetadata: StorageFileMetadata;
  acceptedFileTypes: Accept;
  onUploadStateChanged?: (state: UploadState) => void;
}

export const FileUploadView: FC<Props> = (props) => {
  const {
    disabled,
    allowMultiple = false,
    storagePath,
    storageFileMetadata,
    acceptedFileTypes,
    onUploadStateChanged,
  } = props;

  const t = useT();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadFile, isUploading, uploadSnapshot, uploadFileError] =
    useUploadFile();

  useEffect(() => {
    if (uploadSnapshot?.state === "running") {
      const unsubscribe = uploadSnapshot.task.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          );
          setUploadProgress(progress);
          onUploadStateChanged?.({
            status: "running",
            fileName: snapshot.ref.name,
            fileSizeBytes: snapshot.totalBytes,
            progress,
          });
        },
      );
      return () => {
        unsubscribe();
      };
    }
  }, [onUploadStateChanged, uploadSnapshot]);

  useEffect(() => {
    if (uploadFileError && onUploadStateChanged) {
      onUploadStateChanged?.({
        status: "error",
        fileName: "",
        fileSizeBytes: 0,
        progress: 0,
      });
    }
  }, [onUploadStateChanged, uploadFileError]);

  const handleDropFile = useCallback<NonNullable<DropzoneOptions["onDrop"]>>(
    async (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length > 0) {
        for (const file of acceptedFiles) {
          try {
            setUploadProgress(0);
            onUploadStateChanged?.({
              status: "started",
              fileName: file.name,
              fileSizeBytes: file.size,
              progress: 0,
            });
            const ref = getStorageRef(`${storagePath}/${file.name}`);
            const result = await uploadFile(ref, file, {
              customMetadata: storageFileMetadata,
            });
            if (result?.metadata) {
              toast.success(`Uploaded ${result.metadata.name}`);
              onUploadStateChanged?.({
                status: "success",
                fileName: result.metadata.name,
                fileSizeBytes: result.metadata.size,
                progress: 100,
              });
            }
          } catch (error) {
            handleError(error).logAnd().toast();
            onUploadStateChanged?.({
              status: "error",
              fileName: file.name,
              fileSizeBytes: file.size,
              progress: 0,
            });
          }
        }
      } else if (fileRejections.length > 0) {
        for (const fileRejection of fileRejections) {
          toast.error(
            t("FileUploadView_Toast_InvalidFile", {
              name: fileRejection.file.name,
            }),
          );
        }
      }
    },
    [onUploadStateChanged, storageFileMetadata, storagePath, t, uploadFile],
  );

  const acceptedFileExtensions = Object.values(acceptedFileTypes).flat();

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    disabled,
    onDrop: handleDropFile,
    noClick: true,
    maxFiles: allowMultiple ? undefined : 1,
    multiple: allowMultiple,
    maxSize: MAX_FILE_SIZE_BYTES,
    accept: acceptedFileTypes,
  });

  return (
    <Stack
      spacing={2}
      p={4}
      sx={{
        borderWidth: 2,
        borderRadius: 1,
        borderColor: props.error ? "error.main" : "divider",
        borderStyle: "dashed",
        backgroundColor: "background.paper",
      }}
      justifyContent="center"
      alignItems="center"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive && (
        <Typography variant="body2" textAlign="center" color="text.secondary">
          {t("FileUploadView_Label_DropHere")}
        </Typography>
      )}
      {!isDragActive && !isUploading && (
        <>
          <CloudUploadIcon color="primary" fontSize="large" />
          <Typography variant="body2" textAlign="center" color="text.secondary">
            {t("FileUploadView_Label_DragToUpload")}
          </Typography>
          <Typography variant="body1" textAlign="center" color="text.secondary">
            {t("Common_Or")}
          </Typography>
          <Button
            variant="outlined"
            size="small"
            onClick={open}
            sx={{
              minWidth: 200,
              margin: `1rem 0 !important`,
            }}
          >
            {t("FileUploadView_Button_BrowseFiles")}
          </Button>
          <Typography variant="body1" textAlign="center" color="text.secondary">
            {t("FileUploadView_Label_MaxFileSize")}{" "}
            <b>{MAX_FILE_SIZE_BYTES / 1024 / 1024} MB</b>
          </Typography>
          <Typography variant="body1" textAlign="center" color="text.secondary">
            <T
              k="FileUploadView_Label_AllowedFileTypes"
              options={{ types: acceptedFileExtensions.join(", ") }}
            />
          </Typography>
        </>
      )}
      {isUploading && (
        <Stack spacing={3}>
          <Box textAlign="center">
            <CircularProgressView
              value={uploadProgress}
              typographyVariant="body2"
              size={72}
            />
          </Box>
          {uploadSnapshot && (
            <Button
              variant="outlined"
              size="small"
              color="error"
              sx={{
                minWidth: 200,
              }}
              onClick={() => uploadSnapshot.task.cancel()}
            >
              {t("Common_Cancel")}
            </Button>
          )}
        </Stack>
      )}
      {uploadFileError && (
        <Alert severity="error">{uploadFileError.message}</Alert>
      )}
    </Stack>
  );
};
