import LockClockOutlinedIcon from "@mui/icons-material/LockClockOutlined";
import WarningIcon from "@mui/icons-material/Warning";
import { Collapse, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DateTime } from "luxon";
import { FC } from "react";
import { Control } from "react-hook-form";
import { ControlledDatePicker } from "../../Form/views/ControlledDatePicker";
import { ControlledSwitch } from "../../Form/views/ControlledSwitch";
import { useT } from "../../Translation/hooks/useT";
import { T } from "../../Translation/views/T";
import { ProjectQuestionsFormValues } from "./ProjectQuestionsForm";

interface Props {
  control: Control<ProjectQuestionsFormValues>;
  isOpen: boolean;
  isDisabled: boolean;
  isAppliedToHistoricData: boolean;
  isCallsUpdateInProgress: boolean;
  close: () => void;
  onExited: () => void;
}

export const ProjectQuestionsFormConfirmationDialog: FC<Props> = (props) => {
  const {
    control,
    isOpen,
    isDisabled,
    isAppliedToHistoricData,
    isCallsUpdateInProgress,
  } = props;
  const t = useT();

  return (
    <Dialog
      open={isOpen}
      onClose={props.close}
      TransitionProps={{
        onExited: props.onExited,
      }}
      maxWidth="sm"
      fullWidth
      disablePortal
    >
      <DialogTitle>
        {t("ProjectCallFlaggingConfigsFormConfirmationDialog_Title")}
      </DialogTitle>

      <DialogContent sx={{ pb: 3 }}>
        <DialogContentText>
          <T k={"ProjectQuestionsFormConfirmationDialog_Text"} />
        </DialogContentText>
      </DialogContent>

      <Stack
        px={4}
        py={3}
        bgcolor={isCallsUpdateInProgress ? "warning.50" : "background.default"}
      >
        <Stack direction="row" alignItems="center" spacing={3}>
          {isCallsUpdateInProgress && (
            <LockClockOutlinedIcon sx={{ color: "warning.700" }} />
          )}

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            flex={1}
          >
            <Stack spacing={2}>
              <Typography variant="h4">
                {t(
                  "ProjectCallFlaggingConfigsFormConfirmationDialog_Title_ApplyHistoricData",
                )}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {isCallsUpdateInProgress &&
                  t(
                    "ProjectCallFlaggingConfigsFormConfirmationDialog_Label_ToggleDisabled",
                  )}{" "}
                {!isCallsUpdateInProgress && (
                  <>
                    {t(
                      "ProjectCallFlaggingConfigsFormConfirmationDialog_Label_CurrentState",
                    )}{" "}
                    <Typography
                      component="span"
                      color={
                        isAppliedToHistoricData
                          ? "primary.main"
                          : "text.primary"
                      }
                      fontWeight={800}
                    >
                      {isAppliedToHistoricData
                        ? t(
                            "ProjectCallFlaggingConfigsFormConfirmationDialog_Label_ToggleOn",
                          )
                        : t(
                            "ProjectCallFlaggingConfigsFormConfirmationDialog_Label_ToggleOff",
                          )}
                    </Typography>
                  </>
                )}
              </Typography>
            </Stack>

            <Stack>
              <ControlledSwitch
                fullWidth
                name="isAppliedToHistoricData"
                label={
                  isAppliedToHistoricData ? t("Common_On") : t("Common_Off")
                }
                control={control}
                disabled={isDisabled || isCallsUpdateInProgress}
              />
            </Stack>
          </Stack>
        </Stack>

        <Collapse in={isAppliedToHistoricData && !isCallsUpdateInProgress}>
          <Stack spacing={3} pt={5}>
            <ControlledDatePicker
              control={control}
              name="checkExistingCallsFromDate"
              label={t(
                "ProjectCallFlaggingConfigsFormConfirmationDialog_DatePickerCallsFromDate_Label",
              )}
              disabled={isDisabled || !isAppliedToHistoricData}
              disableFuture
              fullWidth
              required
              minDate={DateTime.fromISO("1950-01-01")}
            />

            <Typography
              variant="subtitle1"
              color="error.main"
              display="flex"
              alignItems="center"
              pt={2}
            >
              <WarningIcon sx={{ mr: 2 }} />
              {t(
                "ProjectCallFlaggingConfigsFormConfirmationDialog_Title_Warnings",
              )}
            </Typography>

            <Typography
              variant="subtitle2"
              component="ul"
              sx={{
                paddingInlineStart: 4,
                "& li:not(:last-child)": {
                  mb: 1,
                },
              }}
            >
              <li>
                {t(
                  "ProjectCallFlaggingConfigsFormConfirmationDialog_Warning_1",
                )}
              </li>
              <li>
                {t(
                  "ProjectCallFlaggingConfigsFormConfirmationDialog_Warning_2",
                )}
              </li>
              <li>
                {t(
                  "ProjectCallFlaggingConfigsFormConfirmationDialog_Warning_3",
                )}
              </li>
            </Typography>
          </Stack>
        </Collapse>
      </Stack>

      {isCallsUpdateInProgress && (
        <Typography variant="subtitle2" p={4} bgcolor="background.default">
          {t(
            "ProjectCallFlaggingConfigsFormConfirmationDialog_Text_ToggleDisabled",
          )}
        </Typography>
      )}

      <DialogActions>
        <Button onClick={props.close} disabled={isDisabled}>
          {t("ProjectCallFlaggingConfigsFormConfirmationDialog_Button_Cancel")}
        </Button>
        <Button
          disabled={isDisabled}
          type="submit"
          variant="contained"
          autoFocus
        >
          {t("ProjectCallFlaggingConfigsFormConfirmationDialog_Button_Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
