import { FC } from "react";
import { useHeaderConfig } from "../hooks/useHeaderConfig";
import { HeaderWithCenteredLogoView } from "./HeaderWithCenteredLogoView";
import { HeaderWithCustomChildren } from "./HeaderWithCustomChildren";
import { HeaderWithNavigationView } from "./HeaderWithNavigationView";

export const HeaderView: FC = () => {
  const variant = useHeaderConfig((state) => state.variant);

  switch (variant) {
    case "NONE":
      return null;
    case "WITH_CENTERED_LOGO":
      return <HeaderWithCenteredLogoView />;
    case "WITH_NAVIGATION":
      return <HeaderWithNavigationView />;
    case "WITH_CUSTOM_CHILDREN":
      return <HeaderWithCustomChildren />;
  }
};
