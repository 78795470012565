import { isProjectReportFrame } from "@snubes/snubes-types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";
interface Args {
  projectId?: string;
  projectReportFrameId?: string;
}

export function useProjectReportFrameByDay(args: Args) {
  const { projectId, projectReportFrameId } = args;

  return useValidDocumentData(
    projectId && projectReportFrameId
      ? getDocRef(
          `projects/${projectId}/reportFramesByDay`,
          projectReportFrameId,
        )
      : null,
    isProjectReportFrame,
  );
}
