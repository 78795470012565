import { isProvider } from "@snubes/snubes-types";
import { getDocRef } from "../../Firebase/helpers/getDocRef";
import { useValidDocumentData } from "../../Firebase/hooks/useValidDocumentData";

export function useProvider(providerId?: string) {
  return useValidDocumentData(
    providerId ? getDocRef("providers", providerId) : null,
    isProvider,
  );
}
