import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { CallIssue } from "@snubes/snubes-types";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { handleError } from "../../Common/helpers/handleError";
import { useT } from "../../Translation/hooks/useT";
import { deleteCallIssueCallable } from "../callables/deleteCallIssueCallable";

interface Props {
  callIssue?: CallIssue;
  onClose: () => void;
}

export const CallIssueDeleteDialog: FC<Props> = (props) => {
  const { callIssue, onClose } = props;
  const t = useT();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onDeleteIssueClicked = useCallback(async () => {
    if (!callIssue) return;
    setIsSubmitting(true);
    try {
      await deleteCallIssueCallable({
        callId: callIssue.callId,
        callIssueId: callIssue.id,
      });
      onClose();
      toast.success(t("CallIssueDeleteDialog_Toast_Deleted"));
    } catch (error) {
      handleError(error).logAnd().toast();
    } finally {
      setIsSubmitting(false);
    }
  }, [t, callIssue, onClose]);

  return (
    <Dialog open={!!callIssue} onClose={onClose}>
      <DialogTitle>{t("CallIssueDeleteDialog_Title_DeleteIssue")}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t("CallIssueDeleteDialog_Text_Confirmation")}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button disabled={isSubmitting} onClick={onClose}>
          {t("CallIssueDeleteDialog_Button_Cancel")}
        </Button>
        <Button
          color="error"
          variant="contained"
          disabled={isSubmitting}
          onClick={onDeleteIssueClicked}
        >
          {t("CallIssueDeleteDialog_Button_Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
