import { Money } from "@snubes/snubes-types";

interface Args extends Money {
  fractionDigits?: number;
}

export function toLocaleMoney(args?: Args) {
  return args?.amount.toLocaleString(undefined, {
    minimumFractionDigits: args.fractionDigits,
    maximumFractionDigits: args.fractionDigits,
    style: "currency",
    currency: args.currency,
  });
}
