import {
  CallFlaggingConfigParameterOperator,
  CallQuestionAnswerType,
} from "@snubes/snubes-types";
import { CallFlaggingConfigParameterAvailableValue } from "../../Calls/types/CallFlaggingConfigParameterAvailableValue";
import { CallFlaggingConfigParameterValueFieldType } from "../../Calls/types/CallFlaggingConfigParameterValueFieldType";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface ProjectQuestionResponseTypeRecordValue {
  label: TranslationKey;
  callFlaggingParameterOperators: CallFlaggingConfigParameterOperator[];
  callFlaggingParameterValueFieldType: CallFlaggingConfigParameterValueFieldType;
  callFlaggingParameterAvailableValues?: CallFlaggingConfigParameterAvailableValue[];
}

export const PROJECT_QUESTION_RESPONSE_TYPE_RECORD: Readonly<
  Record<CallQuestionAnswerType, ProjectQuestionResponseTypeRecordValue>
> = {
  BOOLEAN: {
    label: "ProjectQuestionResponseType_Label_Boolean",
    callFlaggingParameterOperators: ["EQUALS", "NOT_EQUALS"],
    callFlaggingParameterValueFieldType: "select",
    callFlaggingParameterAvailableValues: [
      {
        value: true,
        label: "Common_Yes",
      },
      {
        value: false,
        label: "Common_No",
      },
    ],
  },
  TEXT: {
    label: "ProjectQuestionResponseType_Label_Text",
    callFlaggingParameterOperators: ["EQUALS", "NOT_EQUALS", "CONTAINS"],
    callFlaggingParameterValueFieldType: "text",
  },
  NUMBER: {
    label: "ProjectQuestionResponseType_Label_Number",
    callFlaggingParameterOperators: [
      "EQUALS",
      "NOT_EQUALS",
      "GREATER_THAN",
      "LESS_THAN",
    ],
    callFlaggingParameterValueFieldType: "number",
  },
};
