import { PriceModel } from "@snubes/snubes-types";
import { TranslationKey } from "../../Translation/types/TranslationKey";

interface PriceModelType {
  label: TranslationKey;
}

export const TENDER_PRICE_MODEL: Record<PriceModel, PriceModelType> = {
  TRANSACTION: {
    label: "TenderPriceModel_Label_Transaction",
  },
  LEAD: {
    label: "TenderPriceModel_Label_Lead",
  },
  CALL: {
    label: "TenderPriceModel_Label_Call",
  },
  MINUTE: {
    label: "TenderPriceModel_Label_Minute",
  },
  HOUR: {
    label: "TenderPriceModel_Label_Hour",
  },
  MONTH: {
    label: "TenderPriceModel_Label_Month",
  },
};
