import { Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useT } from "../../Translation/hooks/useT";
import { TranslationKey } from "../../Translation/types/TranslationKey";
import { HomePageInfoPanelCard } from "./HomePageInfoPanelCard";

interface Props {
  titleText: TranslationKey;
  subtitleText: TranslationKey;
  buttonText: TranslationKey;
  buttonHref?: string;
  buttonOnClick?: () => void;
  bgcolor?: string;
  imgSrc: string;
  imgAlt?: string;
  imgBgcolor?: string;
}

export const HomeApplicationCardView: FC<Props> = (props) => {
  const {
    titleText,
    subtitleText,
    buttonText,
    buttonHref,
    buttonOnClick,
    imgSrc,
    imgAlt,
    bgcolor = "primary.50",
    imgBgcolor = "primary.200",
  } = props;

  const t = useT();
  const navigate = useNavigate();

  const onClickButton = useCallback(() => {
    if (buttonOnClick) buttonOnClick();
    if (buttonHref) navigate(buttonHref);
  }, [buttonHref, buttonOnClick, navigate]);

  return (
    <HomePageInfoPanelCard elevation={0} bgcolor={bgcolor}>
      <Stack
        direction="row"
        spacing={3}
        flex={1}
        justifyContent="space-between"
        overflow="hidden"
      >
        <Stack justifyContent="space-between">
          <Typography variant="subtitle2" color="text.main">
            {t(subtitleText)}
          </Typography>
          <Typography mt={3} mb={5} variant="h3" color="text.main">
            {t(titleText)}
          </Typography>

          <Stack spacing={3} direction="row" justifyContent="flex-start">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="small"
              href={!buttonOnClick ? buttonHref : undefined}
              onClick={buttonOnClick ? onClickButton : undefined}
            >
              {t(buttonText)}
            </Button>
          </Stack>
        </Stack>

        <Img
          src={imgSrc}
          alt={imgAlt}
          width={200}
          height={200}
          sx={{
            bgcolor: imgBgcolor,
          }}
        />
      </Stack>
    </HomePageInfoPanelCard>
  );
};

const Img = styled("img")(() => ({
  objectFit: "cover",
  borderRadius: "100vh",
}));
