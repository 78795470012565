import LaunchIcon from "@mui/icons-material/Launch";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import { CollectionName, Log } from "@snubes/snubes-types";
import { FC } from "react";
import { useT } from "../../Translation/hooks/useT";
import { getFirestoreConsoleLink } from "../helpers/getFirestoreConsoleLink";
import { LogListItem } from "../types/LogListItem";
import { LogListView } from "./LogListView";

interface Props<T extends Log> {
  collectionName: CollectionName;
  LogListItemView: FC<LogListItem<T>>;
  isT: (obj: unknown) => obj is T;
  close: () => void;
}

export function LogsDialog<T extends Log>(props: Props<T>) {
  const t = useT();

  return (
    <Dialog onClose={props.close} open fullWidth>
      <DialogTitle
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={3}
      >
        Logs
        <Button
          size="small"
          href={getFirestoreConsoleLink(props.collectionName)}
          endIcon={<LaunchIcon />}
          {...{
            target: "_blank",
            rel: "noreferrer",
          }}
        >
          Open in Firebase
        </Button>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <LogListView {...props} limit={200} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={props.close}>
          {t("Common_Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
