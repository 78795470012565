import { create } from "zustand";

interface AuthInitState {
  isInitializing: boolean;
  setIsInitializing: (isInitializing: boolean) => void;
}

export const useAuthInit = create<AuthInitState>((set) => ({
  isInitializing: true,
  setIsInitializing: (isInitializing) => set({ isInitializing }),
}));
