import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Avatar, Button, Paper, Stack, Typography } from "@mui/material";
import { UserType } from "@snubes/snubes-types";
import { FC } from "react";
import { useT } from "../../Translation/hooks/useT";
import { USER_TYPE_RECORD } from "../../Users/consts/USER_TYPE_RECORD";

interface Props {
  userType: UserType;
  isDisabled?: boolean;
  onSelectUserType: (userType: UserType) => void;
}

export const OnboardingUserTypeCard: FC<Props> = (props) => {
  const t = useT();
  const userTypeValue = USER_TYPE_RECORD[props.userType];

  return (
    <Stack
      p={5}
      pt={3}
      spacing={3}
      component={Paper}
      variant="outlined"
      textAlign="center"
      maxWidth="335px"
      height="100%"
      justifyContent="space-between"
      alignItems="center"
      marginX="auto"
      sx={{
        "&.MuiPaper-root:hover": {
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        },
        "&.MuiPaper-root:hover > .OnboardingUserTypeCard-title": {
          color: "primary.main",
        },
      }}
    >
      <Typography
        variant="subtitle2"
        color="text.secondary"
        className="OnboardingUserTypeCard-title"
      >
        {t(userTypeValue.title)}
      </Typography>
      <Avatar
        alt={props.userType}
        src={userTypeValue.image}
        sx={{
          width: 240,
          height: 240,
          bgcolor: userTypeValue.backgroundColor,
        }}
      />
      <Typography variant="h3" color="secondary.main">
        {t(userTypeValue.caption)}
      </Typography>
      <Typography variant="subtitle1">{t(userTypeValue.subcaption)}</Typography>
      <Button
        fullWidth
        variant="outlined"
        size="small"
        onClick={() => props.onSelectUserType(props.userType)}
        disabled={props.isDisabled}
        endIcon={<ChevronRightIcon />}
      >
        {t("OnboardingUserTypeCard_Button_Choose")}
      </Button>
    </Stack>
  );
};
