import { Unstable_Grid2 as Grid } from "@mui/material";
import { Member } from "@snubes/snubes-types";
import { FC } from "react";
import { useIsFeatureFlagEnabled } from "../../Admin/hooks/useIsFeatureFlagEnabled";
import { FullHeightStack } from "../../Common/views/FullHeightStack";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useSetUserType } from "../../Users/hooks/useSetUserType";
import { HomeApplicationCardView } from "./HomeApplicationCardView";
import { HomeGetStartedCardView } from "./HomeGetStartedCardView";
import { HomeGetStartedView } from "./HomeGetStartedView";
import { HomeInviteMembersCardView } from "./HomeInviteMembersCardView";
import { HomeKeyFeaturesView } from "./HomeKeyFeaturesView";
import { HomeLaunchCardView } from "./HomeLaunchCardView";
import { HomeOtherApplicationsView } from "./HomeOtherApplicationsView";
import { HomeWelcomeCardView } from "./HomeWelcomeCardView";

interface Props {
  member: Member;
}

export const QmHomeOnboardingView: FC<Props> = (props) => {
  const { member } = props;
  const [setUserType] = useSetUserType();
  const organizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled();
  const isOnboardingDemosEnabled = isFeatureFlagEnabled("ONBOARDING_DEMOS");

  const isTeamMemberInvited = !!member?.tasks?.isTeamMemberInvited;
  const isPhoneSystemConnected = !!member?.tasks?.isPhoneSystemConnected;

  return (
    <FullHeightStack>
      <HomeGetStartedView>
        <Grid sm={12} md={12} lg={4}>
          <HomeWelcomeCardView
            titleText="QualityManagementHomePage_Started_Headline"
            subtitleText="QualityManagementHomePage_Started_Subheadline"
            steps={[
              {
                isChecked: true,
                text: "QualityManagementHomePage_Step_CreateAccount",
              },
              {
                isChecked: isPhoneSystemConnected,
                text: "QualityManagementHomePage_Step_ConnectPhoneSystem",
              },
              {
                isChecked: isTeamMemberInvited,
                text: "QualityManagementHomePage_Step_InviteTeam",
              },
            ]}
          />
        </Grid>
        <Grid sm={12} md={6} lg={4}>
          <HomeGetStartedCardView
            titleText="QualityManagementHomePage_Card_PhoneSystem_Title"
            subtitleText1="QualityManagementHomePage_Card_PhoneSystem_Subtitle1"
            subtitleText2="QualityManagementHomePage_Card_PhoneSystem_Subtitle2"
            buttonText="QualityManagementHomePage_Card_PhoneSystem_Button"
            buttonHref={
              organizationId
                ? `/organizations/${organizationId}/connector-configs/create`
                : undefined
            }
            buttonDisabled={isPhoneSystemConnected}
          />
        </Grid>
        <Grid sm={12} md={6} lg={4}>
          <HomeInviteMembersCardView
            member={member}
            titleText="QualityManagementHomePage_Card_Team_Title"
            subtitleText1="QualityManagementHomePage_Card_Team_Subtitle1"
            subtitleText2="QualityManagementHomePage_Card_Team_Subtitle2"
            buttonTextPrimary="QualityManagementHomePage_Card_Team_Button1"
            buttonTextSecondary="QualityManagementHomePage_Card_Team_Button2"
          />
        </Grid>
      </HomeGetStartedView>

      {isOnboardingDemosEnabled && (
        <HomeKeyFeaturesView>
          <HomeLaunchCardView
            titleText="QualityManagementHomePage_Card_Demo_Project_Title"
            subtitleText="QualityManagementHomePage_Card_Demo_Project_Subtitle"
            buttonText="QualityManagementHomePage_Card_Demo_Project_Button"
          />
        </HomeKeyFeaturesView>
      )}

      <HomeOtherApplicationsView sx={{ flex: 1 }}>
        <HomeApplicationCardView
          bgcolor="primary.50"
          subtitleText="QualityManagementHomePage_Subtitle_ForClients"
          titleText="QualityManagementHomePage_Heading_LookingToFindCallCenter"
          buttonText="QualityManagementHomePage_Button_LearnMore"
          buttonHref="/home/client"
          buttonOnClick={() => setUserType("CLIENT")}
          imgSrc="/assets/home-client.png"
        />
        <HomeApplicationCardView
          bgcolor="secondary.50"
          subtitleText="QualityManagementHomePage_Subtitle_ForCallCenters"
          titleText="QualityManagementHomePage_Heading_FindProjects"
          buttonText="QualityManagementHomePage_Button_LearnMore"
          buttonHref="/home/provider"
          buttonOnClick={() => setUserType("PROVIDER")}
          imgSrc="/assets/home-provider.png"
          imgBgcolor="error.50"
        />
      </HomeOtherApplicationsView>
    </FullHeightStack>
  );
};
