import WarningIcon from "@mui/icons-material/Warning";
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Organization } from "@snubes/snubes-types";
import { ChangeEvent, FC, useCallback, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../../Common/helpers/handleError";
import { useHasProjects } from "../../../Projects/hooks/useHasProjects";
import { useT } from "../../../Translation/hooks/useT";
import { deleteOrganizationCallable } from "../../callables/deleteOrganizationCallable";

interface Props {
  organization: Organization;
  isOpen: boolean;
  close: () => void;
}

export const OrganizationDeleteDialog: FC<Props> = (props) => {
  const t = useT();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleteProjectsConfirmed, setIsDeleteProjectsConfirmed] =
    useState(false);
  const [
    hasProviderProjects,
    isHasProviderProjectsLoading,
    hasProviderProjectsError,
  ] = useHasProjects({ providerOrganizationId: props.organization.id });

  const isDeleteAllowed = !hasProviderProjects || isDeleteProjectsConfirmed;

  const isDisabled =
    isSubmitting ||
    !isDeleteAllowed ||
    isHasProviderProjectsLoading ||
    !!hasProviderProjectsError;

  const deleteOrganization = useCallback(async () => {
    if (!props.organization) return;

    try {
      setIsSubmitting(true);

      await deleteOrganizationCallable({
        organizationId: props.organization.id,
      });

      navigate("/admin/organizations");
      toast.success(t("OrganizationDeleteDialog_Toast_SuccessMessage"));
    } catch (error) {
      handleError(error).logAnd().toast();
      setIsSubmitting(false);
    }
  }, [props.organization, navigate, t]);

  return (
    <Dialog open={props.isOpen} onClose={props.close} fullWidth>
      <DialogTitle>
        {t("OrganizationDeleteDialog_Title_DeleteOrganization")}
      </DialogTitle>
      <Divider />

      <DialogContent>
        <Stack spacing={2}>
          {(hasProviderProjects || hasProviderProjectsError) && (
            <>
              <Typography
                variant="subtitle1"
                color="warning.main"
                display="flex"
                alignItems="center"
                pt={2}
              >
                <WarningIcon sx={{ mr: 2 }} />
                {t("OrganizationDeleteDialog_Title_ExistingProjects")}
              </Typography>
              <FormControl>
                <FormControlLabel
                  label={t("OrganizationDeleteDialog_Label_DeleteProjects")}
                  disabled={!!hasProviderProjectsError}
                  checked={isDeleteProjectsConfirmed}
                  onChange={(e) =>
                    setIsDeleteProjectsConfirmed(
                      (e as ChangeEvent<HTMLInputElement>).target.checked,
                    )
                  }
                  control={<Switch />}
                />
                {hasProviderProjectsError && (
                  <FormHelperText>
                    {hasProviderProjectsError.message}
                  </FormHelperText>
                )}
              </FormControl>
            </>
          )}

          <Collapse in={isDeleteAllowed}>
            <DialogContentText>
              {hasProviderProjects
                ? t(
                    "OrganizationDeleteDialog_Content_DeleteOrganizationAndProjects",
                  )
                : t("OrganizationDeleteDialog_Content_DeleteOrganization")}
            </DialogContentText>
          </Collapse>
        </Stack>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button disabled={isSubmitting} onClick={props.close}>
          {t("OrganizationDeleteDialog_Button_Cancel")}
        </Button>
        <Button
          color="error"
          variant="contained"
          disabled={isDisabled}
          onClick={deleteOrganization}
        >
          {t("OrganizationDeleteDialog_Button_Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
