import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import { FC, isValidElement, PropsWithChildren, ReactNode } from "react";

interface Props extends PropsWithChildren {
  title: ReactNode;
}

export const DetailsAccordionContainerView: FC<Props> = (props) => {
  return (
    <SAccordion disableGutters elevation={0} square>
      <SAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: 14 }} />}
      >
        {isValidElement(props.title) ? (
          props.title
        ) : (
          <Typography variant="subtitle1">{props.title}</Typography>
        )}
      </SAccordionSummary>
      <Stack mx={3}>
        <Divider />
      </Stack>
      <SAccordionDetails>
        <Stack mx={3}>{props.children}</Stack>
      </SAccordionDetails>
    </SAccordion>
  );
};

const SAccordion = styled(MuiAccordion)(({ theme }) => ({
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: theme.palette.divider,
  marginBottom: theme.spacing(3),
  "&:last-child": {
    marginBottom: 0,
  },
  "::before": {
    display: "none",
  },
}));

const SAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
}));

const SAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  flexDirection: "row-reverse",
  padding: theme.spacing(3),
  color: theme.palette.text.primary,
  "&.Mui-expanded": {
    backgroundColor: theme.palette.background.paper,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.text.primary,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: theme.palette.primary.main,
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    marginLeft: theme.spacing(3),
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    color: theme.palette.primary.main,
  },
}));
