import { DateTime } from "luxon";

/**
 * Get a normalized UTC date from a Date object. The time is discarded
 * and only the year, month and day are kept.
 */
export function getNormalizedDate(date: Date): DateTime {
  return DateTime.fromObject(
    {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    },
    { zone: "utc" },
  );
}
