import { ToggleButtonGroup } from "@mui/material";
import { Call } from "@snubes/snubes-types";
import { FC, MouseEvent, useCallback } from "react";
import { TooltipToggleButton } from "../../Common/views/TooltipToggleButton";
import { useT } from "../../Translation/hooks/useT";
import {
  CALL_DIRECTION_RECORD,
  CALL_DIRECTIONS,
} from "../consts/CALL_DIRECTION_RECORD";

interface Props {
  callDirection: Call["direction"];
  onChangeCallDirection: (callDirection: Call["direction"]) => void;
}

export const CallDirectionToggleButton: FC<Props> = (props) => {
  const { callDirection, onChangeCallDirection } = props;
  const t = useT();

  const handleChangeCallDirection = useCallback(
    (e: MouseEvent<HTMLElement>, value: Call["direction"] | "") => {
      onChangeCallDirection(value || undefined);
    },
    [onChangeCallDirection],
  );

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      onChange={handleChangeCallDirection}
      value={callDirection || ""}
    >
      <TooltipToggleButton title={t(CALL_DIRECTION_RECORD.ANY.label)} value="">
        <CALL_DIRECTION_RECORD.ANY.Icon />
      </TooltipToggleButton>
      {CALL_DIRECTIONS.map((dir) => {
        const { Icon, label } = CALL_DIRECTION_RECORD[dir];
        return (
          <TooltipToggleButton
            key={dir}
            title={t(label)}
            value={dir}
            aria-label={t(label)}
          >
            <Icon />
          </TooltipToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};
