import { FC } from "react";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { OrganizationNotFoundView } from "../../Organizations/views/OrganizationNotFoundView";
import { TendersSetupView } from "./TendersSetupView";
import { TendersView } from "./TendersView";

export const TendersPage: FC = () => {
  const organizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );
  const isOrganizationTypeClient = useSelectedOrganization(
    (organization) => organization?.types?.includes("CLIENT"),
  );

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <FullHeightContainer>
        {organizationId ? (
          isOrganizationTypeClient ? (
            <TendersView organizationId={organizationId} />
          ) : (
            <TendersSetupView />
          )
        ) : (
          <OrganizationNotFoundView />
        )}
      </FullHeightContainer>
    </>
  );
};
