import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkIcon from "@mui/icons-material/Link";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import TwitterIcon from "@mui/icons-material/Twitter";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Container, Stack, Typography } from "@mui/material";
import { EmailStruct } from "@snubes/snubes-types";
import { FC } from "react";
import { string, type } from "superstruct";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { useWizardForm } from "../../Wizard/hooks/useWizardForm";
import { WizardFormProps } from "../../Wizard/types/WizardFormProps";
import { useProviderWizardStore } from "../hooks/useProviderWizardStore";

const FormValuesStruct = type({
  organizationName: string(),
  email: EmailStruct,
  phone: string(),
  website: string(),
  socialMediaFacebook: string(),
  socialMediaTwitter: string(),
  socialMediaLinkedIn: string(),
  socialMediaInstagram: string(),
  socialMediaYoutube: string(),
});

export const ProviderWizardContactInfoForm: FC<WizardFormProps> = (props) => {
  const t = useT();
  const { control, handleSubmit, onSubmit } = useWizardForm({
    store: useProviderWizardStore,
    formKey: props.formKey,
    FormValuesStruct,
    toFormValues: (provider) => ({
      organizationName: provider?.organizationName || "",
      email: provider?.email || "",
      phone: provider?.phone || "",
      website: provider?.website || "",
      socialMediaFacebook: provider?.socialMediaFacebook || "",
      socialMediaTwitter: provider?.socialMediaTwitter || "",
      socialMediaLinkedIn: provider?.socialMediaLinkedIn || "",
      socialMediaInstagram: provider?.socialMediaInstagram || "",
      socialMediaYoutube: provider?.socialMediaYoutube || "",
    }),
    toDoc: (formValues) => {
      return {
        organizationName: formValues.organizationName,
        email: formValues.email,
        phone: formValues.phone,
        website: formValues.website,
        ...(formValues.socialMediaFacebook && {
          socialMediaFacebook: formValues.socialMediaFacebook,
        }),
        ...(formValues.socialMediaTwitter && {
          socialMediaTwitter: formValues.socialMediaTwitter,
        }),
        ...(formValues.socialMediaLinkedIn && {
          socialMediaLinkedIn: formValues.socialMediaLinkedIn,
        }),
        ...(formValues.socialMediaInstagram && {
          socialMediaInstagram: formValues.socialMediaInstagram,
        }),
        ...(formValues.socialMediaYoutube && {
          socialMediaYoutube: formValues.socialMediaYoutube,
        }),
      };
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container sx={{ pb: 3 }} maxWidth="md">
        <Typography variant="h1" mb={4}>
          {t("ProviderWizardContactInfoForm_Title_ContactInfo")}
        </Typography>
        <Typography variant="body1" color="text.500" mb={4}>
          {t("ProviderWizardContactInfoForm_Description_ContactInfo")}
        </Typography>
        <Stack spacing={3}>
          <ControlledTextField
            required
            fullWidth
            name="organizationName"
            label={t("ProviderWizardContactInfoForm_Field_OrganizationName")}
            control={control}
            endAdornment={
              <WorkOutlineOutlinedIcon
                sx={{ color: "text.secondary", mr: 2 }}
              />
            }
          />
          <ControlledTextField
            required
            fullWidth
            name="email"
            type="email"
            label={t("ProviderWizardContactInfoForm_Field_Email")}
            control={control}
            endAdornment={
              <AlternateEmailIcon sx={{ color: "text.secondary", mr: 2 }} />
            }
          />
          <ControlledTextField
            required
            fullWidth
            name="phone"
            type="tel"
            label={t("ProviderWizardContactInfoForm_Field_Phone")}
            control={control}
            endAdornment={
              <LocalPhoneIcon sx={{ color: "text.secondary", mr: 2 }} />
            }
          />
          <ControlledTextField
            required
            fullWidth
            name="website"
            label={t("ProviderWizardContactInfoForm_Field_Website")}
            control={control}
            endAdornment={<LinkIcon sx={{ color: "text.secondary", mr: 2 }} />}
          />

          <Typography variant="body1" color="text.500" mb={4}>
            {t("ProviderWizardContactInfoForm_Description_SocialMediaLinks")}
          </Typography>
          <ControlledTextField
            fullWidth
            name="socialMediaFacebook"
            label={t("OrganizationEditForm_Field_Facebook")}
            control={control}
            endAdornment={
              <FacebookIcon sx={{ color: "text.secondary", mr: 2 }} />
            }
          />
          <ControlledTextField
            fullWidth
            name="socialMediaInstagram"
            label={t("OrganizationEditForm_Field_Instagram")}
            control={control}
            endAdornment={
              <InstagramIcon sx={{ color: "text.secondary", mr: 2 }} />
            }
          />
          <ControlledTextField
            fullWidth
            name="socialMediaLinkedIn"
            label={t("OrganizationEditForm_Field_LinkedIn")}
            control={control}
            endAdornment={
              <LinkedInIcon sx={{ color: "text.secondary", mr: 2 }} />
            }
          />
          <ControlledTextField
            fullWidth
            name="socialMediaTwitter"
            label={t("OrganizationEditForm_Field_Twitter")}
            control={control}
            endAdornment={
              <TwitterIcon sx={{ color: "text.secondary", mr: 2 }} />
            }
          />
          <ControlledTextField
            fullWidth
            name="socialMediaYoutube"
            label={t("OrganizationEditForm_Field_Youtube")}
            control={control}
            endAdornment={<YouTubeIcon sx={{ color: "text.secondary" }} />}
          />
        </Stack>
      </Container>
    </form>
  );
};
