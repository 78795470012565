import { Stack, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { Member, User } from "@snubes/snubes-types";
import { FC } from "react";
import { FullHeightStack } from "../../Common/views/FullHeightStack";
import { MaxWidthContainer } from "../../Common/views/MaxWidthContainer";
import { useSelectedOrganization } from "../../Organizations/hooks/useSelectedOrganization";
import { useT } from "../../Translation/hooks/useT";
import { getUserName } from "../../Users/helpers/getUserName";
import { useSetUserType } from "../../Users/hooks/useSetUserType";
import { getTimeOfDayGreeting } from "../helpers/getTimeOfDayGreeting";
import { HomeApplicationCardView } from "./HomeApplicationCardView";
import { HomeCardNotificationsView } from "./HomeCardNotificationsView";
import { HomeCardOrganizationView } from "./HomeCardOrganizationView";
import { HomeCardProjectsView } from "./HomeCardProjectsView";
import { HomeOtherApplicationsView } from "./HomeOtherApplicationsView";

interface Props {
  user: User;
  member?: Member;
}

export const QmHomeView: FC<Props> = (props) => {
  const { user } = props;
  const t = useT();

  const organizationId = useSelectedOrganization(
    (organization) => organization?.id,
  );
  const [setUserType] = useSetUserType();

  return (
    <FullHeightStack>
      <MaxWidthContainer>
        <Stack p={5} spacing={4}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h2">
              {t(getTimeOfDayGreeting())}, {getUserName(user)}
            </Typography>
          </Stack>

          <Grid container spacing={3}>
            <Grid xs={12} sm={6} md={4}>
              <HomeCardOrganizationView organizationId={organizationId} />
            </Grid>

            <Grid xs={12} sm={6} md={4}>
              <HomeCardNotificationsView userId={user.id} />
            </Grid>

            <Grid xs={12} sm={6} md={4}>
              <HomeCardProjectsView organizationId={organizationId} />
            </Grid>
          </Grid>
        </Stack>
      </MaxWidthContainer>

      <HomeOtherApplicationsView sx={{ flex: 1 }}>
        <HomeApplicationCardView
          bgcolor="primary.50"
          subtitleText="QualityManagementHomePage_Subtitle_ForClients"
          titleText="QualityManagementHomePage_Heading_LookingToFindCallCenter"
          buttonText="QualityManagementHomePage_Button_LearnMore"
          buttonHref="/home/client"
          buttonOnClick={() => setUserType("CLIENT")}
          imgSrc="/assets/home-client.png"
        />
        <HomeApplicationCardView
          bgcolor="secondary.50"
          subtitleText="QualityManagementHomePage_Subtitle_ForCallCenters"
          titleText="QualityManagementHomePage_Heading_FindProjects"
          buttonText="QualityManagementHomePage_Button_LearnMore"
          buttonHref="/home/provider"
          buttonOnClick={() => setUserType("PROVIDER")}
          imgSrc="/assets/home-provider.png"
          imgBgcolor="error.50"
        />
      </HomeOtherApplicationsView>
    </FullHeightStack>
  );
};
