import { Alert, Stack } from "@mui/material";
import { FC } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { NavTab } from "../../Common/types/NavTab";
import { LoadingView } from "../../Common/views/LoadingView";
import { HeaderConfig } from "../../Header/views/HeaderConfig";
import { SecondaryHeaderView } from "../../Header/views/SecondaryHeaderView";
import { useT } from "../../Translation/hooks/useT";
import { useOrganization } from "../hooks/useOrganization";
import { OrganizationContextProvider } from "../hooks/useOrganizationContext";

export const OrganizationsLayoutView: FC = () => {
  const { organizationId } = useParams<"organizationId">();
  const [organization, isLoading, error] = useOrganization(organizationId);
  const isLoaded = !isLoading && !error;
  const t = useT();
  const hasPermission = useHasPermission();

  const tabs: NavTab[] = [
    {
      label: "OrganizationsLayoutView_SecondaryHeaderLabel_Details",
      route: "details",
    },
    {
      label: "OrganizationsLayoutView_SecondaryHeaderLabel_Members",
      route: "members",
    },
    {
      label: "OrganizationsLayoutView_SecondaryHeaderLabel_Connections",
      route: "connector-configs",
      isHidden: !hasPermission("CAN_WRITE_ORGANIZATION_CONNECTOR_CONFIG", {
        organizationId,
      }),
    },
  ];

  if (!organizationId) return null;

  return (
    <>
      <HeaderConfig variant="WITH_NAVIGATION" />
      <SecondaryHeaderView
        tabs={tabs}
        pathPrefix={`/organizations/${organizationId}`}
      />
      <>
        {isLoading && <LoadingView />}
        {error && (
          <Alert sx={{ m: 3 }} severity="error">
            {error.message}
          </Alert>
        )}
        {isLoaded && !organization && (
          <Stack p={3}>
            <Alert severity="info">
              {t("OrganizationsLayoutView_Error_OrganizationNotFound")}
            </Alert>
          </Stack>
        )}
        {organization && (
          <OrganizationContextProvider value={{ organization }}>
            <Outlet />
          </OrganizationContextProvider>
        )}
      </>
    </>
  );
};
