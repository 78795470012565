import { superstructResolver } from "@hookform/resolvers/superstruct";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Button,
  Divider,
  FormHelperText,
  IconButton,
  Stack,
} from "@mui/material";
import { Project, ProjectIssueCategoryStruct } from "@snubes/snubes-types";
import { FC, Fragment, useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { array, Infer, type } from "superstruct";
import { v4 } from "uuid";
import { useHasPermission } from "../../Auth/hooks/useHasPermission";
import { handleError } from "../../Common/helpers/handleError";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useT } from "../../Translation/hooks/useT";
import { updateProjectIssueCategoriesCallable } from "../callables/updateProjectIssueCategoriesCallable";
import { getProjectOrganizationIds } from "../helpers/getProjectOrganizationIds";

const FormValuesStruct = type({
  issueCategories: array(ProjectIssueCategoryStruct),
});

type FormValues = Infer<typeof FormValuesStruct>;

interface Props {
  project: Project;
}

export const ProjectIssueCategoriesForm: FC<Props> = (props) => {
  const t = useT();
  const hasPermission = useHasPermission();
  const canUpdateProjectIssueCategories = hasPermission(
    "CAN_UPDATE_PROJECT_ISSUE_CATEGORIES",
    { organizationIds: getProjectOrganizationIds(props.project) },
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: superstructResolver(FormValuesStruct),
    defaultValues: {
      issueCategories: props.project.issueCategories || [],
    },
  });
  const isFormDisabled = isSubmitting || !canUpdateProjectIssueCategories;

  const { fields, append, remove } = useFieldArray({
    control,
    keyName: "fieldId", // TODO: remove in next major version of react-hook-form
    name: "issueCategories",
  });

  const addIssueCategory = useCallback(
    () => append({ id: v4(), name: "" }),
    [append],
  );

  const removeIssueCategory = (index: number) => remove(index);

  const getIsIssueCategoryUsedInFlaggingConfig = useCallback(
    (issueCategoryId: string) => {
      return props.project.callFlaggingConfigs?.some(
        (config) => config.issueCategoryId === issueCategoryId,
      );
    },
    [props.project.callFlaggingConfigs],
  );

  const onSubmit = useCallback(
    async (formValues: FormValues) => {
      if (isSubmitting) return;

      try {
        const { issueCategories } = formValues;

        await updateProjectIssueCategoriesCallable({
          projectId: props.project.id,
          issueCategories: issueCategories.length ? issueCategories : null,
        });

        toast.success(
          t("ProjectIssueCategoriesForm_Toast_SavedIssueCategories"),
        );
      } catch (error) {
        handleError(error).logAnd().toast();
      }
    },
    [isSubmitting, props.project.id, t],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} p={3}>
        {fields.map((field, index) => {
          const isIssueCategoryUsedInFlaggingConfig =
            getIsIssueCategoryUsedInFlaggingConfig(field.id);
          const isFieldDisabled =
            isFormDisabled || isIssueCategoryUsedInFlaggingConfig;

          return (
            <Fragment key={field.id}>
              <Stack direction="row" spacing={3} alignItems="center">
                <Stack flex={2}>
                  <ControlledTextField
                    control={control}
                    name={`issueCategories.${index}.name`}
                    label={t("ProjectIssueCategoriesForm_Label_Name")}
                    disabled={isFieldDisabled}
                    required
                    fullWidth
                  />
                </Stack>
                <IconButton
                  size="large"
                  color="error"
                  onClick={() => removeIssueCategory(index)}
                  disabled={isFieldDisabled}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </Stack>
              {isIssueCategoryUsedInFlaggingConfig && (
                <FormHelperText>
                  {t(
                    "ProjectIssueCategoriesForm_Label_IssueCategoryIsUsedInFlaggingConfig",
                  )}
                </FormHelperText>
              )}
              <Divider />
            </Fragment>
          );
        })}

        <Stack direction="row" bgcolor="grey.50" borderColor="divider">
          <Button
            variant="text"
            onClick={addIssueCategory}
            startIcon={<AddCircleIcon />}
            disabled={isFormDisabled}
            fullWidth
            sx={{
              fontSize: 14,
              fontWeight: "bold",
              justifyContent: "flex-start",
              pl: 3,
            }}
          >
            {t("ProjectIssueCategoriesForm_Button_NewIssueCategory")}
          </Button>
        </Stack>

        <Divider />

        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={isFormDisabled}
        >
          {t("Common_Save")}
        </Button>
      </Stack>
    </form>
  );
};
