import { FC } from "react";
import { FullHeightContainer } from "../../Common/views/FullHeightContainer";
import { NoDataView } from "../../Common/views/NoDataView";
import { useT } from "../../Translation/hooks/useT";

export const TendersSetupView: FC = () => {
  const t = useT();

  return (
    <FullHeightContainer maxWidth="sm" sx={{ display: "flex" }}>
      <NoDataView
        label={t("TendersMySetupView_Label_MissingTenders")}
        title={t(`TendersMyBrowseSetupView_Title_Main`)}
        subTitle={t(`TendersMySetupView_Title_Secondary`)}
        button={{
          href: `/tenders/create`,
          label: t("TendersMySetupView_Button_CreateTender"),
        }}
      />
    </FullHeightContainer>
  );
};
