import {
  Divider,
  ListItem,
  Stack,
  SvgIconTypeMap,
  Typography,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC, ReactNode } from "react";

interface Props {
  iconComponent?: OverridableComponent<SvgIconTypeMap>;
  iconColor?: string;
  icon?: ReactNode;
  title: string;
  message: ReactNode;
  isLastItem?: boolean;
  isFirstItem?: boolean;
  onClick?: () => void;
}

export const LogListItemView: FC<Props> = (props) => {
  return (
    <ListItem sx={{ py: 0 }} button onClick={props.onClick}>
      <Stack direction="row">
        <Stack width={24} spacing="2px">
          <Stack direction="row" justifyContent="center" height={8}>
            {!props.isFirstItem && (
              <Divider
                orientation="vertical"
                sx={{ borderColor: "grey.500" }}
              />
            )}
          </Stack>
          <Stack direction="row" justifyContent="center">
            {props.icon}
            {props.iconComponent && props.iconColor && (
              <props.iconComponent
                sx={{ color: props.iconColor, fontSize: 14 }}
              />
            )}
          </Stack>
          {!props.isLastItem && (
            <Stack direction="row" justifyContent="center" flex={1}>
              <Divider
                orientation="vertical"
                sx={{ borderColor: "grey.500" }}
              />
            </Stack>
          )}
        </Stack>
        <Stack ml={2} py={2}>
          <Typography variant="h5" gutterBottom>
            {props.title}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {props.message}
          </Typography>
        </Stack>
      </Stack>
    </ListItem>
  );
};
