import { Chip } from "@mui/material";
import { Tender } from "@snubes/snubes-types";
import { FC } from "react";
import { TimeDiffTranslationFormat } from "../../Common/types/TimeDiffTranslationFormat";
import { DateTimeView } from "../../Common/views/DateTimeView";
import { RelativeTimestampView } from "../../Common/views/RelativeTimestampView";
import { useT } from "../../Translation/hooks/useT";
import { TENDER_STATUS_RECORD } from "../consts/TENDER_STATUS_RECORD";

const TIME_DIFF_TRANSLATION_FORMAT: TimeDiffTranslationFormat = {
  months: "long",
  days: "long",
  hours: "short",
  minutes: "short",
};

interface Props {
  tender: Tender;
}

export const TenderDeadlineChip: FC<Props> = (props: Props) => {
  const { tender } = props;
  const t = useT();
  const deadlineTimestamp = tender.timetable?.applicationDeadlineAt;
  const endedAtTimestamp = tender.endedAt;
  const { Icon } = TENDER_STATUS_RECORD[tender.status];

  return (
    <Chip
      icon={<Icon />}
      label={
        <>
          {deadlineTimestamp && !endedAtTimestamp && (
            <>
              {t("TenderDeadlineChip_Label_EndsIn")}
              {": "}
              <RelativeTimestampView
                hasTooltip
                timestamp={deadlineTimestamp}
                shouldBeInThePast={false}
                format={TIME_DIFF_TRANSLATION_FORMAT}
              />
            </>
          )}
          {endedAtTimestamp && (
            <>
              {t("TenderDeadlineChip_Label_EndedAt")}
              {": "}
              <DateTimeView date={endedAtTimestamp} />
            </>
          )}
        </>
      }
    />
  );
};
